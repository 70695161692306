export const LeadsMortgagesPermissions = {
  ViewMortgageLeads:   
  {
    components: null , 
    componentsId: null  , 
    permissionsId: 'f2ff0924-c55a-4bc8-f5ba-08da8ab4c335' , 
    permissionsName: 'View Mortgage Leads' ,     
   }  , 

   SendToRotation :{
    permissionsId:'66ef17c5-1e15-4358-839b-08db0f594284',
    permissionsName :'Send To Rotation',
    componentsId:null , 
    components:null
  } , 
  AddNewActivity : {
    permissionsId: "51b0d7ea-5481-4eb6-2877-08dbaf79a1bb",
    permissionsName: "Add New Activity",
    description: null,
    componentsId: null,
    components: null
  }
};
