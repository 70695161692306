import React, { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { useSelector } from 'react-redux';
import '../CampRequestFormType1.scss';
import {
  CampaignArtworkSuggestionEnum,
  CampaignFinancialSourceEnum,
} from '../../../../../../../../Enums';
import {CampaignReqActionBtns} from '../CampaignRequestActionButtons'
import { GlobalHistory, showSuccess, showError } from '../../../../../../../../Helper';
import { TabsComponent } from '../../../../../../../../Components';
import { ApproveCampaignRequest, MarkCampaignRequestAsComplete } from '../../../../../../../../Services';
import { ViewRequestTabsData } from '../TabsData';
import { CampaignRequestRejectDialog } from '../Dialogs';
import { bottomBoxComponentUpdate } from '../../../../../../../../Helper/Middleware.Helper';

export function ViewRequestForm({ setState, isSubmitted, setIsSubmitted, activeItem }) {
  const parentTranslationPath = 'Campaign';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);
  const defaultFinancialSourceItem = {
    type: null,
    branch: null,
    user: null,
    percent: null,
  };
  const marketingCampaignsInfo = useSelector((state) => state.MarketingCampaignsReducer);

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selected, setSelected] = useReducer(reducer, {
    campaignRequester: null,
    campaignCreator: null,
    platformName: null,
    projectCategory: null,
    targetAudience: null,
    mediaRequirements: null,
    marketingMaterial: null,
    campaignReason: null,
    campaignUsp: null,
    campaignLanguage: null,
    relatedToType: null,
    relatedToValue: null,
    generalTypeText: null,
    campaignKeyMessages: null,
    campaignLength: null,
    files: [],
    links: [],
    financialSources: [defaultFinancialSourceItem],
  });

  const [allowedActions, setAllowedActions] = useState([]);

  const getAllowedActions = () => {
    let allowedActionsLocal = [];
    
    if (activeItem.canComplete === true)
    allowedActionsLocal = [CampaignReqActionBtns['MarkAsComplete']];
    else if (activeItem.canTakeAction === true)
      allowedActionsLocal = [CampaignReqActionBtns['Reject'],
      CampaignReqActionBtns['Approve']]
    else if (activeItem.canResubmit === true)
      allowedActionsLocal = [CampaignReqActionBtns['Resubmit']];

    setAllowedActions(allowedActionsLocal);
  }

  const actionButtonClickHandler = (actionKey) =>{

    const resubmitHandler = () => setIsSubmitted(true);
    const rejectHandler = () => setOpenRejectDialog(true);
    const approveHandler = () => approveCampaignRequest(marketingCampaignsInfo?.campaignRequestId);
    const markAsCompleteHandler = () => markCampaignRequestAsComplete(marketingCampaignsInfo?.campaignRequestId);

    if(actionKey === CampaignReqActionBtns.Resubmit.key) resubmitHandler();
    else if(actionKey === CampaignReqActionBtns.Reject.key) rejectHandler();
    else if(actionKey === CampaignReqActionBtns.Approve.key) approveHandler();
    else if(actionKey === CampaignReqActionBtns.MarkAsComplete.key) markAsCompleteHandler();
    
  }

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  const cancelHandler = () => {
    GlobalHistory.push(`/home/campaign-requests`);
  };

  const getStateRelatedTo = () => {
    let relatedToState = null;
    let localRelatedToValues = [];
    let mappedRelatedToNames = [];
    
    if (selected.relatedToValue) {

      localRelatedToValues = selected.relatedToType === 'Unit' ?
        selected.relatedToValue : [selected.relatedToValue];

      mappedRelatedToNames = localRelatedToValues.map(
        (item) =>
          item.lookupItemName ||
          item?.property?.property_name ||
          item.unitRefNo ||
          item?.contact?.company_name ||
          (item?.contact?.first_name &&
            `${item?.contact?.first_name} ${item?.contact?.last_name}`) ||
          item.name || ''
      );

    }

    if (selected.relatedToType === 'GeneralCampaign') {
      relatedToState = {
        type: selected.relatedToType,
        values: [],
        generalTypeText: selected.generalTypeText,
      };
    } else if (selected.relatedToType === 'Unit') {
      relatedToState = {
        type: selected.relatedToType,
        values: mappedRelatedToNames,
        generalTypeText: selected.generalTypeText,
      };
    } else {
      relatedToState = {
        type: selected.relatedToType,
        values: mappedRelatedToNames,
        generalTypeText: '',
      };
    }
    return relatedToState || null;
  };

  const getStateArtworkSuggestion = () => {
    const attachments = [...(selected.files || []), ...(selected.links || [])];
    const artworkSuggestionState = attachments.map((item) => ({
      type: item?.documentLink
        ? CampaignArtworkSuggestionEnum.Link.key
        : CampaignArtworkSuggestionEnum.File.key,
      value: item?.documentLink || item?.uuid,
      name: item?.fileName || null,
    }));

    return artworkSuggestionState || null;
  };

  const getStateFinancialSource = () => {

    const financialSourceState =
      selected.financialSources &&
      selected.financialSources.map((item) => ({
        type: item?.type,
        value: item?.user?.applicationUserId || item?.branch?.branchId,
        name: item?.user?.fullName || item?.branch?.branchName,
        percent: item?.percent,
      }));

    return financialSourceState || null;
  };

  const mapStateJSON = () => {
    const JSON = {
      CampaignRequesterId: selected.campaignRequester?.applicationUserId || null,
      PlatformName: selected.platformName || null,
      ProjectCategory: selected.projectCategory || null,
      TargetAudience: selected.targetAudience || null,
      CampaignLanguage: selected.campaignLanguage || null,
      MediaRequirements: selected.mediaRequirements || null,
      MarketingMaterial: selected.marketingMaterial || null,
      CampaignReason: selected.campaignReason || null,
      CampaignKeyMessages: selected.campaignKeyMessages || null,
      CampaignUsps: selected.campaignUsp || null,
      CampaignBudget: selected.campaignBudget || null,
      CampaignLength: selected.campaignLength || null,
      RelatedTo: getStateRelatedTo(),
      ArtworkSuggestion: getStateArtworkSuggestion(),
      FinancialSource: getStateFinancialSource(),
    };

    setState(JSON);
  };

  const getSelectedRelatedToType = () => {
    let relatedTypeSelected = activeItem.data?.RelatedTo?.type || null;
    return relatedTypeSelected;
  };
  const getSelectedRelatedToValue = () => {
    let relatedToValue = null;
     if (activeItem.data?.RelatedTo?.values) {
      const mappedRelatedToNameList = activeItem.data?.RelatedTo?.values.map((item) => ({
        name: item,
      }));
      const mappedRelatedToName = activeItem.data?.RelatedTo?.values.length > 0 && {
        name: activeItem.data?.RelatedTo?.values[0],
      };

      relatedToValue =
        activeItem.data?.RelatedTo?.type === 'Unit' ? mappedRelatedToNameList : mappedRelatedToName;
    }
    return relatedToValue || null;
  };

  const getSelectedGeneralTypeText = () => {
    let generalTypeText = '';
    if (activeItem.data?.RelatedTo?.type === 'GeneralCampaign' ||
      activeItem.data?.RelatedTo?.type === 'Unit') {
      generalTypeText = activeItem.data?.RelatedTo?.generalTypeText;
    }
    return generalTypeText || '';
  }

  const getSelectedFiles = () => {
    let selectedFiles = [];

    const localArtworkFiles =
      activeItem?.data?.ArtworkSuggestion &&
      activeItem.data.ArtworkSuggestion.filter(
        (item) => item.type === CampaignArtworkSuggestionEnum.File.key
      );
    selectedFiles =
      localArtworkFiles &&
      localArtworkFiles.map((item) => ({
        uuid: item.value,
        fileName: item.name || '',
      }));

    return selectedFiles || [];
  };

  const getSelectedLinks = () => {
    let selectedLinks = [];

    const localArtworkLinks =
      activeItem?.data?.ArtworkSuggestion &&
      activeItem.data.ArtworkSuggestion.filter(
        (item) => item.type === CampaignArtworkSuggestionEnum.Link.key
      );
    selectedLinks =
      localArtworkLinks &&
      localArtworkLinks.map((item) => ({
        documentLink: item.value,
        fileName: item.name || '',
      }));

    return selectedLinks || [];
  };

  const getSelectedFinancialSource = () => {
    const financialSourceSelected =
      activeItem?.data?.FinancialSource &&
      activeItem.data.FinancialSource.map((item) => ({
        type: item?.type,
        branch:
          item.type === CampaignFinancialSourceEnum.Company.name
            ? { branchId: item.value, branchName: item.name }
            : null,
        user:
          item.type === CampaignFinancialSourceEnum.AgentContribution.name
            ? { applicationUserId: item.value, fullName: item.name }
            : null,
        percent: item?.percent,
      })) || [];

    return (financialSourceSelected.length > 0) ? financialSourceSelected : [defaultFinancialSourceItem]
  };

  const mapSelectedJSON = () => {
    if (activeItem.data) {
      const JSON = {
        campaignRequester: {
          fullName: activeItem.campaignRequester,
          applicationUserId: activeItem.data?.CampaignRequesterId,
        },
        campaignCreator: {
          fullName: activeItem.campaignCreator,
        },
        platformName: activeItem.data?.PlatformName,
        projectCategory: activeItem.data?.ProjectCategory,
        targetAudience: activeItem.data?.TargetAudience,
        mediaRequirements: activeItem.data?.MediaRequirements,
        marketingMaterial: activeItem.data?.MarketingMaterial,
        campaignReason: activeItem.data?.CampaignReason,
        campaignUsp: activeItem.data?.CampaignUsps,
        campaignLanguage: activeItem.data?.CampaignLanguage,
        campaignKeyMessages: activeItem.data?.CampaignKeyMessages,
        campaignLength: activeItem.data?.CampaignLength,
        campaignBudget: activeItem.data?.CampaignBudget,
        relatedToType: getSelectedRelatedToType(),
        relatedToValue: getSelectedRelatedToValue(),
        generalTypeText: getSelectedGeneralTypeText(),
        files: getSelectedFiles(),
        links: getSelectedLinks(),
        financialSources: getSelectedFinancialSource(),
      };
      setSelected({ id: 'edit', value: JSON });
    }
  };

  const approveCampaignRequest = async (id) => {
    setIsLoading(true);
    const res = await ApproveCampaignRequest({id});
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}campaign-req-approved-successfully`));
      GlobalHistory.push(`/home/campaign-requests`);
    } else {
      showError(t(`${translationPath}campaign-req-approve-failed`));
    }
    setIsLoading(false);
  };

  const markCampaignRequestAsComplete = async (id) => {
    setIsLoading(true);
    const res = await MarkCampaignRequestAsComplete({id});
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}campaign-req-marked-as-complete-successfully`));
      GlobalHistory.push(`/home/campaign-requests`);
    } else {
      showError(t(`${translationPath}campaign-req-mark-as-complete-failed`));
    }
    setIsLoading(false);
  };

  useEffect(() => () => { bottomBoxComponentUpdate(null); }, []);
  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-between d-flex-row-reverse'>
        <div>
          {allowedActions.map(item => (
            <ButtonBase
              onClick={() => actionButtonClickHandler(item.key)}
              disabled={isLoading}
              className={item.buttonClasses}>
              <span className='px-2'>{t(`${translationPath}${item.key}`)}</span>
              <span className={`${item.icon}`}></span>
            </ButtonBase>))
          }
        </div>
        <div className='action-item'>
          <ButtonBase className='btns theme-transparent mx-2' onClick={cancelHandler}>
            <span>{t(`${translationPath}cancel`)}</span>
          </ButtonBase>
        </div>
      </div>
    );
  });

  useEffect(() => {
    if (isSubmitted) mapStateJSON();
  }, [isSubmitted]);

  useEffect(() => {
    if (activeItem) {
      mapSelectedJSON();
      getAllowedActions();
      setIsReadOnly(!(activeItem?.canResubmit));
    }
  }, [activeItem]);

  return (
    <div className='w-100 p-2'>
      <TabsComponent
        data={ViewRequestTabsData}
        labelInput='label'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses={activeItem?.showTabs? 'theme-curved' : 'theme-solid'}
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          activeItem,
          isReadOnly,
          selected,
          setSelected,
          parentTranslationPath,
          translationPath,
        }}
      />
      {openRejectDialog &&
        <CampaignRequestRejectDialog
          isOpen={openRejectDialog}
          close={() => {
            setOpenRejectDialog(false);
          }}
        />
      }
    </div>
  );
}
