import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalHistory, showWarn } from '../../../../../../../../Helper';
import { TableColumnsFilterComponent, Tables } from '../../../../../../../../Components';
import {
  ColumnsFilterPagesEnum,
  FormsIdsEnum,
  TableFilterTypesEnum,
  TableActions,
  UnitsOperationTypeEnum,
} from '../../../../../../../../Enums';
import { GetAllFormFieldsByFormId } from '../../../../../../../../Services';
import { TableColumnsFilterActions } from '../../../../../../../../store/TableColumnsFilter/TableColumnsFilterActions';
// import { LeadsUnitsTableHeader } from '../../../../../../UnitsSalesView/UnitsSalesUtilities/UnitsSalesTableComponent/LeadsUnitsTableHeader'
import { UnitMapper } from '../../../../../../UnitsView/UnitMapper';
import { LeadsUnitsTableHeader } from './LeadsUnitsTableHeader';

export const LeadUnitsTable = ({
  data,
  filter,
  totalCount,
  onPageIndexChanged,
  onPageSizeChanged,
  parentTranslationPath,
  selectedMatchesIndexes,
  selectedMatchesIds,
  setSelectedMatchesIds,
  setSelectedMatchesIndexes,
  leadEmail
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const pathName = window.location.pathname
    .split('/home/')[1]
    .split('/view')[0]
    .split('/')[0];

  const tableColumnsFilterResponse = useSelector((state) => state.TableColumnsFilterReducer);
  const [allFormFields, setAllFormFields] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    LeadsUnitsTableHeader.filter((item) => item.isDefaultFilterColumn).map(
      (column) => column.id
    )
  );

  const getAllFormFieldsByFormId = useCallback(async () => {
    if (data)
      setTableData(data.map((item) => UnitMapper(item)));

    setIsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.units.id);
    if (!((result && result.data && result.data.ErrorId) || !result) && Array.isArray(result))
      setAllFormFields(result);
    else setAllFormFields([]);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    if (
      tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum.unitsSales.key]
    ) {
      setSelectedTableFilterColumns(
        tableColumnsFilterResponse[ColumnsFilterPagesEnum.unitsSales.key]
      );
    }
  }, [tableColumnsFilterResponse]);

  useEffect(() => {
    setTableColumns([
      ...LeadsUnitsTableHeader.filter(
        (item) => selectedTableFilterColumns.findIndex((element) => element === item.id) !== -1
      ),
      ...allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns.findIndex((element) => element === item.formFieldId) !== -1
        )
        .map((field) => ({
          id: field.formFieldId,
          key: field.formFieldKey,
          isDate: field.uiWidgetType === 'alt-date',
          label: (field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
          input: field.displayPath || undefined,
        })),
    ]);
  }, [allFormFields, selectedTableFilterColumns]);

  useEffect(() => {
    tableColumns.map((column) => ({
      key: column.key || column.fieldKey || column.id,
      filterType:
        (column.isDate && TableFilterTypesEnum.datePicker.key) ||
        TableFilterTypesEnum.textInput.key,
      isHiddenFilter: column.isHiddenFilter,
      textInputType: column.textInputType,
      textInputMax: column.textInputMax,
      textInputMin: column.textInputMin,
      displayPath:
        (column.key && column.input) ||
        (column.fieldKey &&
          allFormFields &&
          allFormFields.findIndex((item) => item.formFieldKey === column.fieldKey) !== -1 &&
          allFormFields.find((item) => item.formFieldKey === column.fieldKey).displayPath) ||
        undefined,
    }));
  }, [allFormFields, tableColumns]);

  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      if ((leadEmail === null) || (leadEmail === 'N/A') || (leadEmail === ''))
        showWarn(`${t('Shared:email-address-missing')}`);
      else {
        setSelectedMatchesIds(() => {
          const cardIndex = selectedMatchesIds.findIndex((item) => item === row.id);
          if (cardIndex !== -1) selectedMatchesIds.splice(cardIndex, 1);
          else selectedMatchesIds.push(row.id);
          return [...selectedMatchesIds];
        });
        setSelectedMatchesIndexes(() => {
          const cardIndex = selectedMatchesIndexes.findIndex((item) => item === rowIndex);
          if (cardIndex !== -1) selectedMatchesIndexes.splice(cardIndex, 1);
          else selectedMatchesIndexes.push(rowIndex);
          return [...selectedMatchesIndexes];
        });
      }
    }
  );

  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      if (actionEnum) {
        if ((item.operationType === UnitsOperationTypeEnum.rentAndSale.key || item.operationType === UnitsOperationTypeEnum.rent.key) && pathName === 'lead-lease') {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${item.unit_type_id}&id=${item.id}`
          );
        } else if ((item.operationType === UnitsOperationTypeEnum.rentAndSale.key || item.operationType === UnitsOperationTypeEnum.sale.key) && pathName === 'lead-sales') {
          GlobalHistory.push(
            `/home/units-sales/unit-profile-edit?formType=${item.unit_type_id}&id=${item.id}`
          );
        } else if ((item.operationType === UnitsOperationTypeEnum.rent.key) && (pathName === 'leads' || pathName === 'mortgage-leads')) {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${item.unit_type_id}&id=${item.id}`
          );
        } else if ((item.operationType === UnitsOperationTypeEnum.sale.key || item.operationType === UnitsOperationTypeEnum.rentAndSale.key) && (pathName === 'leads' || pathName === 'mortgage-leads')) {
          GlobalHistory.push(
            `/home/units-sales/unit-profile-edit?formType=${item.unit_type_id}&id=${item.id}`
          );
        } else {
          GlobalHistory.push(
            `/home/units-sales/unit-profile-edit?formType=${item.unit_type_id}&id=${item.id}`
          );
        }
      }
    },
    [dispatch]
  );

  return (
    <div className='w-100 px-3'>
      <TableColumnsFilterComponent
        columns={LeadsUnitsTableHeader.concat(
          allFormFields.filter(
            (item) =>
              ((item.formFieldKey !== 'amenities')) &&
              ((item.formFieldKey !== 'fitting_and-fixtures')) &&
              LeadsUnitsTableHeader.findIndex(
                (element) => element.fieldKey === item.formFieldKey
              ) === -1
          )
        ).map((item) => ({
          key: item.formFieldId || item.id,
          value: (item.formFieldTitle && item.formFieldTitle.replace('*', '')) || item.label,
        }))}
        isLoading={isLoading}
        selectedColumns={selectedTableFilterColumns}
        onSelectedColumnsChanged={(newValue) => {
          setSelectedTableFilterColumns(newValue);
          let localTableColumnsFilterResponse = tableColumnsFilterResponse;
          if (localTableColumnsFilterResponse)
            localTableColumnsFilterResponse[ColumnsFilterPagesEnum.unitsSales.key] = newValue;
          else {
            localTableColumnsFilterResponse = {
              [ColumnsFilterPagesEnum.unitsSales.key]: newValue,
            };
          }
          dispatch(
            TableColumnsFilterActions.TableColumnsFilterRequest(localTableColumnsFilterResponse)
          );
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath=''
      />
      <Tables
        data={tableData || []}
        headerData={tableColumns}
        // onPageIndexChanged={onPageIndexChanged}
        // onPageSizeChanged={onPageSizeChanged}
        defaultActions={[
          {
            enum: TableActions.openFile.key,
            isDisabled: false,
            externalComponent: null,
          }]}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        parentTranslationPath={parentTranslationPath}
        // itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        totalItems={totalCount || 0}
        selectAllOptions={{
          selectedRows: selectedMatchesIndexes,
          onSelectClicked,
          disabledRows: [],
        }}
        isSellectAllDisabled
      />
    </div>
  );
};

LeadUnitsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
};
