import React from 'react';
import './KenbanBoardCard.scss';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import fileAttachmentsIcon from '../../../../../assets/images/icons/MarketingIcons/file-attachment.jpg';
import { getDownloadableLink, getFirstLastNameLetters } from '../../../../../Helper';

export function KenbanBoardCard({ taskData, cardClickHandler, activeTaskID }) {

  return (
    <div
      className={`kenban-card border-gray-secondary d-flex-column 
      d-flex-h-between radius-xl p-3 mb-2 ${taskData?.id === activeTaskID? 'active-kanban-card': ''}`}
      onClick={cardClickHandler}
      >
      <div>
        <span className='text-brand-secondary b-600 fz-10'>
          {taskData.createdOn ? moment(taskData.createdOn).format('LL') : ''}
        </span>
        <p className='text-primary b-600 fz-16 my-2'>{taskData.title}</p>
        <p className='text-tertiary fz-10 mb-3 word-break'>{taskData.description}</p>
      </div>
      <div>
        <div className='d-flex'>
          <div className='card-badge px-2 border-gray-secondary radius-full mr-2'>
            <span className='text-gray-dark b-600 fz-10'>{taskData.departmentName}</span>
          </div>
          <div className='card-badge px-2 border-gray-secondary radius-full'>
            <span className='text-gray-dark b-600 fz-10'>{taskData.campaignName}</span>
          </div>
        </div>
        <div className='d-flex-v-center-h-between default-d-flex pt-2 mt-2 b-top'>
          <div className='d-flex-v-center'>
            <div className='campaign-team-f1'>
              <Avatar className='avatars-wrapper team-avatar fz-12 sm-theme'
                src={
                  taskData.assignedToProfileImg
                    ? getDownloadableLink(taskData.assignedToProfileImg)
                    : ''
                }
              >
                {getFirstLastNameLetters(taskData.assignedTo || '')}
              </Avatar>
            </div>
            <div className='d-flex-column'>
              <span className='fz-12 b-600'>{taskData.assignedTo}</span>
              <span className='fz-10 text-tertiary'>{taskData.assignedToEmail}</span>
            </div>
          </div>
          <div className='attachments-wrap d-flex-center default-d-flex m-2'>
            <img src={fileAttachmentsIcon} alt='attachments-No' />
            <span className='text-tertiary fz-12 d-block ml-1'>{taskData.numberOfAttachments}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
