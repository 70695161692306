import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Joi from 'joi';
import { useLocation } from 'react-router-dom';
import {
  CreateCampaignRequest,
  ResubmitCampaignRequest,
  GetCampaignRequestDetails,
} from '../../../../../Services';
import { CreateRequestForm, ViewRequestForm } from './Forms';
import { GetParams, showError, showSuccess, GlobalHistory } from '../../../../../Helper';
import { Spinner } from '../../../../../Components';
import { MarketingApprovalForm } from './Forms/CampRequestFormType1/MarketingApprovalForm/MarketingApprovalForm';

export function CampaignRequestManagementView() {
  const parentTranslationPath = 'Campaign';
  const translationPath = '';
  const { pathname } = useLocation();
  const isCreateForm = pathname.includes('/home/campaign-requests/add');
  
    //::::::BUGS::::::::
      //todo:: Add error messages from api (ALL MARKETIG MODULE) 
      //todo:: fix breadcrubms in camp request module 
      //todo:: fix create form action buttons responsiveness (bottombox issue)


    //::::::::PERFORMANCE + CODE::::::::
      //todo:: check rerenders number all over the module
      //todo:: check errors and warnings

  const { t } = useTranslation(parentTranslationPath);
  const marketingCampaignsInfo = useSelector((state) => state.MarketingCampaignsReducer);
  const [state, setState] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const createCampaignRequest = async (createObj) => {
    setIsLoading(true);

    const res = await CreateCampaignRequest(createObj);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}capmaign-req-added-successfully`));
      GlobalHistory.push(`/home/campaign-requests`);
    } else {
      showError(t(`${translationPath}campaign-req-add-failed`));
      setIsSubmitted(false);
      setState(null);
    }
    setIsLoading(false);
  };
  const resubmitCampaignRequest = async (createObj) => {
    setIsLoading(true);

    const res = await ResubmitCampaignRequest(createObj);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}campaign-req-edit-successfully`));
      GlobalHistory.push(`/home/campaign-requests`);
    } else {
      showError(t(`${translationPath}campaign-req-edit-failed`));
      setIsSubmitted(false);
      setState(null);
    }
    setIsLoading(false);
  };
  const getCampaignRequestDetails = async (requestId) => {
    setIsLoading(true);

    const res = await GetCampaignRequestDetails(requestId);
    if (!(res && res.status && res.status !== 200)) {
      setActiveItem(res || null);
    }
    setIsLoading(false);
  };
  const schema = (state ? Joi.object({
    CampaignRequesterId: Joi.any()
      .custom((value, helpers) => {
        if (!value)
          return helpers.error('state.CampaignRequester');
        return value;
      })
      .messages({
        'state.CampaignRequester': t(`${translationPath}CampaignRequester-is-required`),
      }),
    RelatedTo:
      Joi.object({
        type: Joi.any(),
        values: Joi.any(),
        generalTypeText: Joi.any(),
      }).custom((value, helpers) => {
        if (!value.type)
          return helpers.error('state.relatedType');
        if ((value.type === 'Unit' || value.type === "GeneralCampaign")
          && !value.generalTypeText)
          return helpers.error('state.generalTypeText');

        if (value.type && value.type !== "GeneralCampaign" &&
          (!(value?.values) || (value?.values?.length == 0)))
          return helpers.error('state.relatedValues');
        return value;
      })
        .messages({
          'state.relatedType': t(`${translationPath}relatedType-is-required`),
          'state.generalTypeText': t(`${translationPath}generalTypeText-is-required`),
          'state.relatedValues': t(`${translationPath}relatedValues-is-required`),
        }),
    FinancialSource: Joi.array().items(
      Joi.object({
        type: Joi.any(),
        value: Joi.any(),
        name: Joi.any(),
        percent: Joi.any(),
      }).custom((stateValue, helpers) => {
        if ((!stateValue.type || !stateValue.value || !stateValue.name || !stateValue.percent) &&
          !(!stateValue.type && !stateValue.value && !stateValue.name && !stateValue.percent))
          return helpers.error('state.financialSource');

        return stateValue;
      })
        .messages({
          'state.financialSource': t(`${translationPath}fill-all-financial-source-required-fields`),
        })),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state) : null);


  const saveHandler = async () => {
    if (schema?.error) {
      showError(schema?.error?.message);
      setIsSubmitted(false);
      return;
    }
    const createObj = {
      departmentId: +GetParams('departmentId'),
      campaignData: state,
    };

    const updateObj = {
      id: marketingCampaignsInfo?.campaignRequestId,
      campaignData: state,
    };

    if (isCreateForm) createCampaignRequest(createObj);
    else resubmitCampaignRequest(updateObj);
  };

  useEffect(() => {
    if (state) {
      saveHandler();
    }
  }, [state]);

  useEffect(() => {
    if (!isCreateForm) getCampaignRequestDetails(marketingCampaignsInfo?.campaignRequestId);
  }, [marketingCampaignsInfo?.campaignRequestId]);

  return (
    <div className=' camp-req-f1 w-100'>
      <Spinner isActive={isLoading} />
      {!isCreateForm ? (
        activeItem?.showTabs? (
          <MarketingApprovalForm
            setState={setState}
            activeItem={activeItem}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
          />
        ) : (
          <ViewRequestForm
            setState={setState}
            activeItem={activeItem}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
          />
        )
      ) : (
        <CreateRequestForm
          setState={setState}
          activeItem={activeItem}
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
        />
      )}
    </div>
  );
}
