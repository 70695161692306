import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Joi from 'joi';
import { Button, Avatar } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showSuccess, showError, GlobalHistory, getPublicDownloadableLink, getErrorByName, getTranslate } from '../../../Helper';
import { config } from '../../../config';
import { LOGIN } from '../../../store/login/Actions';
import { Inputs } from '../../../Components';
import arrowLeft from '../../../assets/images/icons/arrow-left.png'
import { GetApplicationUserForgotPasswordOptions, ApplicationUserForgotPassword, VerifyApplicationUserCode, ApplicationUserResetPassword } from '../../../Services/LoginService';
import { atLeastOneNumber, charactersLength, containUpper, containSpecialCharacter } from '../../../Utils/Expressions';
import { LOGOUt } from '../../../store/login/Actions';
import { CheckIcon } from '../../../assets/images/pages/login/Check_Icon.svg'

const translationPath = '';
function LoginView({ loginResponse }) {
  const { t } = useTranslation('LoginView');
  const version = config.version;
  const dispatch = useDispatch(LOGIN);
  const [loginDto, setLoginDto] = useState({
    identity: '',
    password: '',
    rememberMe: false,
    organizationId: config.organizationsId,
    applicationId: config.applicationId,
  });
  const [animationStart, setAnimationStart] = useState(false);
  const [verificationCodeDigits, setVerificationCodeDigits] = useState({
    digit1: null,
    digit2: null,
    digit3: null,
    digit4: null,
  });
  const [isclick, setisclick] = useState(false);
  const [activeLoginForm, setActiveLoginForm] = useState(1);
  const [accountIdentity, setAccountIdentity] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [userVerificationInfo, setUserVerificationInfo] = useState({});
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswords, setNewPasswords] = useState({
    ViewNewassword: '',
    ViewconfirmPassword: ''
  });

  const schema = Joi.object({
    newPassword: Joi.string()
      .required()
      .regex(atLeastOneNumber).regex(containSpecialCharacter).regex(containUpper).regex(charactersLength)
      .messages({
        'string.empty': t(`${translationPath}password-is-required`),
        'string.pattern.base': t(`${translationPath}weak-password`),
      }),
    confirmPassword: Joi.string()
      .valid(Joi.ref('newPassword'))
      .required()
      .messages({
        'any.only': t(`${translationPath}password-not-matched`),
        'string.empty': t(`${translationPath}confirm-password-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ newPassword, confirmPassword });


  const validationHandler = () => {
    if (!loginDto.password || !loginDto.identity) return false;
    if (!loginDto.password) return false;
    return true;
  };

  const handleLogin = (event) => {
    event.preventDefault();
    if (validationHandler()) {
      dispatch(LOGIN(loginDto));
      setisclick(true);
    }
  };
  // eslint-disable-next-line prefer-destructuring
  useEffect(() => {
    if (loginResponse && !animationStart) {
      if (loginResponse.token) {
        localStorage.setItem('session', JSON.stringify(loginResponse));
        setAnimationStart(true);
        if (loginResponse.isComplex) {
          GlobalHistory.push('/home');

          showSuccess(t(`${translationPath}Login-Succssfuly`));
        } else {
          GlobalHistory.push(`/account/LoginChangePasswordView?userId=${loginResponse.userId}`);
        }
        setisclick(false);
      } else {
        showError(t(`${loginResponse.Message}`));
        setisclick(false);
      }
    }
    return () => { };
  }, [loginResponse, animationStart]);

  const controlsHandler = useCallback(
    (input, process) => (event) => {
      setLoginDto({ ...loginDto, [input]: event.target[process] });
    },
    [setLoginDto, loginDto]
  );


  const handleIdentitySubmit = async (event) => {
    event.preventDefault();
    const res = await GetApplicationUserForgotPasswordOptions(
      accountIdentity,
      config.applicationId,
      config.organizationsId
    );
    if (res) {
      setUserVerificationInfo({
        email: res.email,
        fullName: res.fullName,
        profileImg: res.profileImg,
        userId: res.userId,
      })
      setActiveLoginForm(3)
      setIsSubmitted(true);
    }
  };

  const sendOTPtoEmail = async (event) => {
    
    event.preventDefault();
    const response = await ApplicationUserForgotPassword({
      userId: userVerificationInfo.userId,
      identity: userVerificationInfo.email,
      organizationsId: config.organizationsId,
      applicationsId: config.applicationId,
    });
    if (response) {
      setUserVerificationInfo(prevState=> ({
        ...prevState, 
        identityVerificationId: response.identityVerificationId,
      }))
      setActiveLoginForm(4);
    }
    
  };

  const handleOTPSubmit = async (event) => {
    
    event.preventDefault();

    const verificationCode = Object.values(verificationCodeDigits).reduce(
      (accumulator, item) => accumulator + item,
      '',
    );
    const response = await VerifyApplicationUserCode({
      identityVerificationId: +userVerificationInfo.identityVerificationId,
      code: verificationCode,
    });
    if (response) {
      setActiveLoginForm(5);
    }
    
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    const res = await ApplicationUserResetPassword({
      identityVerificationId: +userVerificationInfo.identityVerificationId,
      newPassword,
    });
    if (!(res && res.status && res.status !== 200)) {

      // setIsSubmitted(true);
      localStorage.removeItem('session');
      dispatch(LOGOUt());
      setActiveLoginForm(1);
      showSuccess(t(`${translationPath}change-password-Succssfuly`));

    }
    else showError(t(`${translationPath}failed-change-password`));
  };

  return (
    <div className='login-view'>
      <div class='blurrd-bg'>
        <div className='login-view-wrapper login-content-wrapper'>
          <div className='login-form-wrapper d-flex-center'>
            <div className='login-content'>
              <div className='d-flex-column-center  px-4'>
                {activeLoginForm === 1 ? <div className='login-form-container'>
                  <div className='login-header'>
                    <svg
                      width='133'
                      height='33'
                      viewBox='0 0 133 33'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M3.08288 10.8608H13.2879C15.8039 10.8608 17.4314 12.5179 17.4314 15.0919C17.4314 17.6659 15.8039 19.3471 13.2879 19.3471H5.93323V22.1303H13.2879C17.3962 22.1303 19.9563 19.3873 19.9563 14.9712C19.9563 10.5551 17.4666 7.95697 13.2879 7.95697H0.126953V27.8495H2.70459V11.2147C2.70459 11.0217 2.87174 10.8689 3.08288 10.8689V10.8608Z'
                        fill='#101828'
                      />
                      <path
                        d='M39.1949 21.873C39.2213 21.7684 39.3005 21.6799 39.4149 21.6317C41.8341 20.6423 43.1625 18.3337 43.1625 15.1242C43.1625 10.6437 40.6905 7.96512 36.5469 7.96512H23.0957V27.8576H25.6733V11.2148C25.6733 11.0218 25.8405 10.869 26.0516 10.869H36.5469C39.1333 10.869 40.6113 12.4375 40.6113 15.1805C40.6113 17.9234 39.1333 19.6609 36.5469 19.6609H28.902V22.1304H35.7288C35.8519 22.1304 35.9575 22.1787 36.0279 22.2671L40.7873 27.8496H44.5437L39.2741 22.1626C39.1949 22.0821 39.1685 21.9615 39.1949 21.8569V21.873Z'
                        fill='#101828'
                      />
                      <path
                        d='M69.2029 17.6982C69.2029 11.971 65.4816 7.96512 60.1504 7.96512H55.4614C50.183 7.96512 46.4881 11.971 46.4881 17.6982C46.4881 23.4255 50.3413 27.8576 55.4614 27.8576H60.1504C65.3937 27.8576 69.2029 23.5863 69.2029 17.6982ZM60.1504 24.9538H55.4614C51.7665 24.9538 49.0921 21.9695 49.0921 17.8671C49.0921 13.7648 51.7753 10.8609 55.4614 10.8609H60.1504C63.8893 10.8609 66.5989 13.805 66.5989 17.8671C66.5989 21.9293 63.8893 24.9538 60.1504 24.9538Z'
                        fill='#101828'
                      />
                      <path
                        d='M75.2654 11.2148C75.2654 11.0217 75.4326 10.8689 75.6437 10.8689H85.8487C88.3648 10.8689 89.9923 12.5259 89.9923 15.1C89.9923 17.674 88.3648 19.3552 85.8487 19.3552H78.4941V22.1383H85.8487C89.9571 22.1383 92.5171 19.3954 92.5171 14.9793C92.5171 10.5632 90.0275 7.96503 85.8487 7.96503H72.6878V27.8575H75.2654V11.2148Z'
                        fill='#101828'
                      />
                      <path
                        d='M124.152 3.81033H117.844L111.167 12.4575L114.492 16.4955L124.152 3.81033Z'
                        fill='#101828'
                      />
                      <path
                        d='M111.124 22.8823L118.329 32.0041H125.129L114.625 18.5467L111.124 22.8823Z'
                        fill='#101828'
                      />
                      <path
                        d='M110.402 13.3906L102.52 3.81033H94.8834L105.581 17.3321L94.0037 32.0041H102.229L110.358 21.9412L113.868 17.5975L110.402 13.3906Z'
                        fill='#101828'
                      />
                      <path
                        d='M127.318 0.805668V0.5H129.535V0.805668H128.611V3.20274H128.242V0.805668H127.327H127.318Z'
                        fill='#101828'
                      />
                      <path
                        d='M129.809 3.20274V0.5H130.267L131.191 2.62359L132.114 0.5H132.572V3.20274H132.202V1.17569L131.314 3.20274H131.059L130.179 1.17569V3.20274H129.809Z'
                        fill='#101828'
                      />
                    </svg>
                  </div>
                  <h1 className='heading-1'>Log in</h1>
                  <h5 className='heading-2 mb-4'>Welcome back! Please enter your details.</h5>
                  <div className='login-form'>
                    <form noValidate className='form-wrapper' onSubmit={handleLogin}>
                      <div className='mb-1'>
                        <Inputs
                          idRef='identityRef'
                          labelClasses='login-label ml-0'
                          labelValue={t(`${translationPath}username`)}
                          inputPlaceholder={t(`${translationPath}Enter-your-username`)}
                          value={loginDto.identity}
                          onInputChanged={controlsHandler('identity', 'value')}
                        />
                      </div>
                      <div>
                        <Inputs
                          idRef='passwordRef'
                          labelClasses='login-label pb-1'
                          type={!viewPassword ? 'password' : ''}
                          labelValue={t(`${translationPath}password`)}
                          inputPlaceholder={t(`${translationPath}password`)}
                          value={loginDto.password}
                          onInputChanged={controlsHandler('password', 'value')}
                          endAdornment={(
                            <Button onClick={()=>setViewPassword(!viewPassword)} className='p-2'>
                              {viewPassword ? <span className='mdi mdi-eye-outline' /> : <span className='mdi mdi-eye-off-outline' />}
                            </Button>
                          )}
                        />
                      </div>
                      <div className='login-form-bottom  d-flex fj-end my-3P5' onClick={() => setActiveLoginForm(2)}>
                        <span className="login-text-btn">
                          {t(`${translationPath}forgot-password`)}
                        </span>
                      </div>
                      <div className='d-flex-column-center'>
                        <Button className='login-btn' type='submit' disabled={isclick}>
                          {<span>{t(`${translationPath}sign-in`)}</span>}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div> : null}
                {activeLoginForm === 2 ? <div className='login-form-container'>
                  <div className='login-header'>
                    <svg
                      width='133'
                      height='33'
                      viewBox='0 0 133 33'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M3.08288 10.8608H13.2879C15.8039 10.8608 17.4314 12.5179 17.4314 15.0919C17.4314 17.6659 15.8039 19.3471 13.2879 19.3471H5.93323V22.1303H13.2879C17.3962 22.1303 19.9563 19.3873 19.9563 14.9712C19.9563 10.5551 17.4666 7.95697 13.2879 7.95697H0.126953V27.8495H2.70459V11.2147C2.70459 11.0217 2.87174 10.8689 3.08288 10.8689V10.8608Z'
                        fill='#101828'
                      />
                      <path
                        d='M39.1949 21.873C39.2213 21.7684 39.3005 21.6799 39.4149 21.6317C41.8341 20.6423 43.1625 18.3337 43.1625 15.1242C43.1625 10.6437 40.6905 7.96512 36.5469 7.96512H23.0957V27.8576H25.6733V11.2148C25.6733 11.0218 25.8405 10.869 26.0516 10.869H36.5469C39.1333 10.869 40.6113 12.4375 40.6113 15.1805C40.6113 17.9234 39.1333 19.6609 36.5469 19.6609H28.902V22.1304H35.7288C35.8519 22.1304 35.9575 22.1787 36.0279 22.2671L40.7873 27.8496H44.5437L39.2741 22.1626C39.1949 22.0821 39.1685 21.9615 39.1949 21.8569V21.873Z'
                        fill='#101828'
                      />
                      <path
                        d='M69.2029 17.6982C69.2029 11.971 65.4816 7.96512 60.1504 7.96512H55.4614C50.183 7.96512 46.4881 11.971 46.4881 17.6982C46.4881 23.4255 50.3413 27.8576 55.4614 27.8576H60.1504C65.3937 27.8576 69.2029 23.5863 69.2029 17.6982ZM60.1504 24.9538H55.4614C51.7665 24.9538 49.0921 21.9695 49.0921 17.8671C49.0921 13.7648 51.7753 10.8609 55.4614 10.8609H60.1504C63.8893 10.8609 66.5989 13.805 66.5989 17.8671C66.5989 21.9293 63.8893 24.9538 60.1504 24.9538Z'
                        fill='#101828'
                      />
                      <path
                        d='M75.2654 11.2148C75.2654 11.0217 75.4326 10.8689 75.6437 10.8689H85.8487C88.3648 10.8689 89.9923 12.5259 89.9923 15.1C89.9923 17.674 88.3648 19.3552 85.8487 19.3552H78.4941V22.1383H85.8487C89.9571 22.1383 92.5171 19.3954 92.5171 14.9793C92.5171 10.5632 90.0275 7.96503 85.8487 7.96503H72.6878V27.8575H75.2654V11.2148Z'
                        fill='#101828'
                      />
                      <path
                        d='M124.152 3.81033H117.844L111.167 12.4575L114.492 16.4955L124.152 3.81033Z'
                        fill='#101828'
                      />
                      <path
                        d='M111.124 22.8823L118.329 32.0041H125.129L114.625 18.5467L111.124 22.8823Z'
                        fill='#101828'
                      />
                      <path
                        d='M110.402 13.3906L102.52 3.81033H94.8834L105.581 17.3321L94.0037 32.0041H102.229L110.358 21.9412L113.868 17.5975L110.402 13.3906Z'
                        fill='#101828'
                      />
                      <path
                        d='M127.318 0.805668V0.5H129.535V0.805668H128.611V3.20274H128.242V0.805668H127.327H127.318Z'
                        fill='#101828'
                      />
                      <path
                        d='M129.809 3.20274V0.5H130.267L131.191 2.62359L132.114 0.5H132.572V3.20274H132.202V1.17569L131.314 3.20274H131.059L130.179 1.17569V3.20274H129.809Z'
                        fill='#101828'
                      />
                    </svg>
                  </div>
                  <h1 className='heading-1'>Forgot Password?</h1>
                  <h5 className='heading-2 mb-4'>No worries, we’ll send you reset instructions.</h5>
                  <div className='login-form'>
                    <form noValidate className='form-wrapper' onSubmit={handleIdentitySubmit} >
                      <div className='mb-1'>
                        <Inputs
                          isSubmitted={isSubmitted}
                          labelClasses='login-label pb-1'
                          labelValue={t(`${translationPath}username`)}
                          inputPlaceholder={t(`${translationPath}Enter-your-username`)}
                          name='identity'
                          idRef='accountIdentity'
                          fieldClasses='inputs theme-underline mb-4'
                          value={accountIdentity}
                          onInputChanged={(e) => setAccountIdentity(e.target.value)}
                        />
                      </div>
                      <div className='d-flex-column-center'>
                        <Button className='login-btn' type='submit'>
                          {<span>{t(`${translationPath}Reset-password`)}</span>}
                        </Button>
                      </div>
                      <div className='back-btn d-flex-center my-3P5' onClick={() => setActiveLoginForm(1)}>
                        <img src={arrowLeft} className='mr-1' />
                        <span>
                          {t(`${translationPath}back-to-login`)}
                        </span>
                      </div>
                    </form>
                  </div>
                </div> : null}
                {activeLoginForm === 3 ? <div className='login-form-container'>
                  <div className='login-header'>
                    <svg
                      width='133'
                      height='33'
                      viewBox='0 0 133 33'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M3.08288 10.8608H13.2879C15.8039 10.8608 17.4314 12.5179 17.4314 15.0919C17.4314 17.6659 15.8039 19.3471 13.2879 19.3471H5.93323V22.1303H13.2879C17.3962 22.1303 19.9563 19.3873 19.9563 14.9712C19.9563 10.5551 17.4666 7.95697 13.2879 7.95697H0.126953V27.8495H2.70459V11.2147C2.70459 11.0217 2.87174 10.8689 3.08288 10.8689V10.8608Z'
                        fill='#101828'
                      />
                      <path
                        d='M39.1949 21.873C39.2213 21.7684 39.3005 21.6799 39.4149 21.6317C41.8341 20.6423 43.1625 18.3337 43.1625 15.1242C43.1625 10.6437 40.6905 7.96512 36.5469 7.96512H23.0957V27.8576H25.6733V11.2148C25.6733 11.0218 25.8405 10.869 26.0516 10.869H36.5469C39.1333 10.869 40.6113 12.4375 40.6113 15.1805C40.6113 17.9234 39.1333 19.6609 36.5469 19.6609H28.902V22.1304H35.7288C35.8519 22.1304 35.9575 22.1787 36.0279 22.2671L40.7873 27.8496H44.5437L39.2741 22.1626C39.1949 22.0821 39.1685 21.9615 39.1949 21.8569V21.873Z'
                        fill='#101828'
                      />
                      <path
                        d='M69.2029 17.6982C69.2029 11.971 65.4816 7.96512 60.1504 7.96512H55.4614C50.183 7.96512 46.4881 11.971 46.4881 17.6982C46.4881 23.4255 50.3413 27.8576 55.4614 27.8576H60.1504C65.3937 27.8576 69.2029 23.5863 69.2029 17.6982ZM60.1504 24.9538H55.4614C51.7665 24.9538 49.0921 21.9695 49.0921 17.8671C49.0921 13.7648 51.7753 10.8609 55.4614 10.8609H60.1504C63.8893 10.8609 66.5989 13.805 66.5989 17.8671C66.5989 21.9293 63.8893 24.9538 60.1504 24.9538Z'
                        fill='#101828'
                      />
                      <path
                        d='M75.2654 11.2148C75.2654 11.0217 75.4326 10.8689 75.6437 10.8689H85.8487C88.3648 10.8689 89.9923 12.5259 89.9923 15.1C89.9923 17.674 88.3648 19.3552 85.8487 19.3552H78.4941V22.1383H85.8487C89.9571 22.1383 92.5171 19.3954 92.5171 14.9793C92.5171 10.5632 90.0275 7.96503 85.8487 7.96503H72.6878V27.8575H75.2654V11.2148Z'
                        fill='#101828'
                      />
                      <path
                        d='M124.152 3.81033H117.844L111.167 12.4575L114.492 16.4955L124.152 3.81033Z'
                        fill='#101828'
                      />
                      <path
                        d='M111.124 22.8823L118.329 32.0041H125.129L114.625 18.5467L111.124 22.8823Z'
                        fill='#101828'
                      />
                      <path
                        d='M110.402 13.3906L102.52 3.81033H94.8834L105.581 17.3321L94.0037 32.0041H102.229L110.358 21.9412L113.868 17.5975L110.402 13.3906Z'
                        fill='#101828'
                      />
                      <path
                        d='M127.318 0.805668V0.5H129.535V0.805668H128.611V3.20274H128.242V0.805668H127.327H127.318Z'
                        fill='#101828'
                      />
                      <path
                        d='M129.809 3.20274V0.5H130.267L131.191 2.62359L132.114 0.5H132.572V3.20274H132.202V1.17569L131.314 3.20274H131.059L130.179 1.17569V3.20274H129.809Z'
                        fill='#101828'
                      />
                    </svg>
                  </div>
                  <h1 className='heading-1'>Verify Your Identity</h1>
                  <h5 className='heading-2 mb-4'>Welcome back! Just confirming it’s really you?</h5>
                  <div className='login-form'>
                    <form noValidate className='form-wrapper' onSubmit={sendOTPtoEmail} >
                      
                        <div className='forgot-user-banner d-flex'>
                        {userVerificationInfo.profileImg?
                          <div>
                            <Avatar
                              className='avatars-wrapper user-avatar user-img'
                              src={getPublicDownloadableLink(userVerificationInfo.profileImg)}
                            >
                            </Avatar>
                          </div> : null}
                            <div className='user-info'>
                              <p>{userVerificationInfo.fullName}</p>
                              <p>{userVerificationInfo.email}</p>
                            </div>
                        </div> 
                      <div className='d-flex-column-center'>
                        <Button className='login-btn' type='submit'>
                          {<span>{t(`${translationPath}its-me`)}</span>}
                        </Button>
                      </div>
                      <div className='back-btn d-flex-center my-3P5' onClick={() => setActiveLoginForm(1)}>
                        <img src={arrowLeft} className='mr-1' />
                        <span>
                          {t(`${translationPath}back-to-login`)}
                        </span>
                      </div>
                    </form>
                  </div>
                </div> : null}
                {activeLoginForm === 4 ? <div className='login-form-container'>
                  <div className='login-header'>
                    <svg
                      width='133'
                      height='33'
                      viewBox='0 0 133 33'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M3.08288 10.8608H13.2879C15.8039 10.8608 17.4314 12.5179 17.4314 15.0919C17.4314 17.6659 15.8039 19.3471 13.2879 19.3471H5.93323V22.1303H13.2879C17.3962 22.1303 19.9563 19.3873 19.9563 14.9712C19.9563 10.5551 17.4666 7.95697 13.2879 7.95697H0.126953V27.8495H2.70459V11.2147C2.70459 11.0217 2.87174 10.8689 3.08288 10.8689V10.8608Z'
                        fill='#101828'
                      />
                      <path
                        d='M39.1949 21.873C39.2213 21.7684 39.3005 21.6799 39.4149 21.6317C41.8341 20.6423 43.1625 18.3337 43.1625 15.1242C43.1625 10.6437 40.6905 7.96512 36.5469 7.96512H23.0957V27.8576H25.6733V11.2148C25.6733 11.0218 25.8405 10.869 26.0516 10.869H36.5469C39.1333 10.869 40.6113 12.4375 40.6113 15.1805C40.6113 17.9234 39.1333 19.6609 36.5469 19.6609H28.902V22.1304H35.7288C35.8519 22.1304 35.9575 22.1787 36.0279 22.2671L40.7873 27.8496H44.5437L39.2741 22.1626C39.1949 22.0821 39.1685 21.9615 39.1949 21.8569V21.873Z'
                        fill='#101828'
                      />
                      <path
                        d='M69.2029 17.6982C69.2029 11.971 65.4816 7.96512 60.1504 7.96512H55.4614C50.183 7.96512 46.4881 11.971 46.4881 17.6982C46.4881 23.4255 50.3413 27.8576 55.4614 27.8576H60.1504C65.3937 27.8576 69.2029 23.5863 69.2029 17.6982ZM60.1504 24.9538H55.4614C51.7665 24.9538 49.0921 21.9695 49.0921 17.8671C49.0921 13.7648 51.7753 10.8609 55.4614 10.8609H60.1504C63.8893 10.8609 66.5989 13.805 66.5989 17.8671C66.5989 21.9293 63.8893 24.9538 60.1504 24.9538Z'
                        fill='#101828'
                      />
                      <path
                        d='M75.2654 11.2148C75.2654 11.0217 75.4326 10.8689 75.6437 10.8689H85.8487C88.3648 10.8689 89.9923 12.5259 89.9923 15.1C89.9923 17.674 88.3648 19.3552 85.8487 19.3552H78.4941V22.1383H85.8487C89.9571 22.1383 92.5171 19.3954 92.5171 14.9793C92.5171 10.5632 90.0275 7.96503 85.8487 7.96503H72.6878V27.8575H75.2654V11.2148Z'
                        fill='#101828'
                      />
                      <path
                        d='M124.152 3.81033H117.844L111.167 12.4575L114.492 16.4955L124.152 3.81033Z'
                        fill='#101828'
                      />
                      <path
                        d='M111.124 22.8823L118.329 32.0041H125.129L114.625 18.5467L111.124 22.8823Z'
                        fill='#101828'
                      />
                      <path
                        d='M110.402 13.3906L102.52 3.81033H94.8834L105.581 17.3321L94.0037 32.0041H102.229L110.358 21.9412L113.868 17.5975L110.402 13.3906Z'
                        fill='#101828'
                      />
                      <path
                        d='M127.318 0.805668V0.5H129.535V0.805668H128.611V3.20274H128.242V0.805668H127.327H127.318Z'
                        fill='#101828'
                      />
                      <path
                        d='M129.809 3.20274V0.5H130.267L131.191 2.62359L132.114 0.5H132.572V3.20274H132.202V1.17569L131.314 3.20274H131.059L130.179 1.17569V3.20274H129.809Z'
                        fill='#101828'
                      />
                    </svg>
                  </div>
                  <h1 className='heading-1'>OTP Verification</h1>
                  <h5 className='heading-2 mb-4'><span>an OTP sent to your email </span>
                  <span className='login-text-btn'>{userVerificationInfo.email}</span></h5>
                  <div className='login-form'>
                    <form noValidate className='form-wrapper' onSubmit={handleOTPSubmit} >
                      <div className='otp-container d-flex-v-center-h-between'>
                        <Inputs
                          idRef='OTP1-Ref'
                          wrapperClasses={`OTP-input ${verificationCodeDigits.digit1? 'filled' : ''}`}
                          inputPlaceholder={t(`${translationPath}zero`)}
                          value={verificationCodeDigits.digit1 || ''}
                          onInputChanged={(event) => {
                            const digitValue = event.target.value;
                            if(!isNaN(digitValue) && (digitValue?.length <= 1)){
                              setVerificationCodeDigits(prevState => ({
                                ...prevState,
                                digit1: digitValue,
                              })
                              )
                          }}}
                        />
                        <Inputs
                          idRef='OTP2-Ref'
                          wrapperClasses={`OTP-input ${verificationCodeDigits.digit2? 'filled' : ''}`}
                          inputPlaceholder={t(`${translationPath}zero`)}
                          value={verificationCodeDigits.digit2 || ''}
                          onInputChanged={(event) => {
                            const digitValue = event.target.value;
                            if(!isNaN(digitValue) && (digitValue?.length <= 1)){
                              console.log('digitValue', digitValue);
                              setVerificationCodeDigits(prevState => ({
                                ...prevState,
                                digit2: digitValue,
                              })
                              )
                          }}}
                          isDisabled={!verificationCodeDigits.digit1}
                            />
                        <Inputs
                          idRef='OTP3-Ref'
                          wrapperClasses={`OTP-input ${verificationCodeDigits.digit3? 'filled' : ''}`}
                          inputPlaceholder={t(`${translationPath}zero`)}
                          value={verificationCodeDigits.digit3 || ''}
                          onInputChanged={(event) => {
                            const digitValue = event.target.value;
                            if(!isNaN(digitValue) && (digitValue?.length <= 1)){
                              setVerificationCodeDigits(prevState => ({
                                ...prevState,
                                digit3: digitValue,
                              })
                              )
                          }}}
                          isDisabled={!verificationCodeDigits.digit2}
                        />
                        <Inputs
                          idRef='OTP4-Ref'
                          wrapperClasses={`OTP-input ${verificationCodeDigits.digit4? 'filled' : ''}`}
                          inputPlaceholder={t(`${translationPath}zero`)}
                          value={verificationCodeDigits.digit4 || ''}
                          onInputChanged={(event) => {
                            const digitValue = event.target.value;
                            if(!isNaN(digitValue) && (digitValue?.length <= 1)){
                              setVerificationCodeDigits(prevState => ({
                                ...prevState,
                                digit4: digitValue,
                              })
                              )
                          }}}
                          isDisabled={!verificationCodeDigits.digit3}
                        />
                      </div>
                      <div className='d-flex-column-center'>
                        <Button className='login-btn' type='submit'>
                          {<span>{t(`${translationPath}Verify-OTP`)}</span>}
                        </Button>
                      </div>
                      <div className='back-btn d-flex-center my-3P5' onClick={() => setActiveLoginForm(1)}>
                        <img src={arrowLeft} className='mr-1' />
                        <span>
                          {t(`${translationPath}back-to-login`)}
                        </span>
                      </div>
                    </form>
                  </div>
                </div> : null}
                {activeLoginForm === 5 ? <div className='login-form-container'>
                  <div className='login-header'>
                    <svg
                      width='133'
                      height='33'
                      viewBox='0 0 133 33'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M3.08288 10.8608H13.2879C15.8039 10.8608 17.4314 12.5179 17.4314 15.0919C17.4314 17.6659 15.8039 19.3471 13.2879 19.3471H5.93323V22.1303H13.2879C17.3962 22.1303 19.9563 19.3873 19.9563 14.9712C19.9563 10.5551 17.4666 7.95697 13.2879 7.95697H0.126953V27.8495H2.70459V11.2147C2.70459 11.0217 2.87174 10.8689 3.08288 10.8689V10.8608Z'
                        fill='#101828'
                      />
                      <path
                        d='M39.1949 21.873C39.2213 21.7684 39.3005 21.6799 39.4149 21.6317C41.8341 20.6423 43.1625 18.3337 43.1625 15.1242C43.1625 10.6437 40.6905 7.96512 36.5469 7.96512H23.0957V27.8576H25.6733V11.2148C25.6733 11.0218 25.8405 10.869 26.0516 10.869H36.5469C39.1333 10.869 40.6113 12.4375 40.6113 15.1805C40.6113 17.9234 39.1333 19.6609 36.5469 19.6609H28.902V22.1304H35.7288C35.8519 22.1304 35.9575 22.1787 36.0279 22.2671L40.7873 27.8496H44.5437L39.2741 22.1626C39.1949 22.0821 39.1685 21.9615 39.1949 21.8569V21.873Z'
                        fill='#101828'
                      />
                      <path
                        d='M69.2029 17.6982C69.2029 11.971 65.4816 7.96512 60.1504 7.96512H55.4614C50.183 7.96512 46.4881 11.971 46.4881 17.6982C46.4881 23.4255 50.3413 27.8576 55.4614 27.8576H60.1504C65.3937 27.8576 69.2029 23.5863 69.2029 17.6982ZM60.1504 24.9538H55.4614C51.7665 24.9538 49.0921 21.9695 49.0921 17.8671C49.0921 13.7648 51.7753 10.8609 55.4614 10.8609H60.1504C63.8893 10.8609 66.5989 13.805 66.5989 17.8671C66.5989 21.9293 63.8893 24.9538 60.1504 24.9538Z'
                        fill='#101828'
                      />
                      <path
                        d='M75.2654 11.2148C75.2654 11.0217 75.4326 10.8689 75.6437 10.8689H85.8487C88.3648 10.8689 89.9923 12.5259 89.9923 15.1C89.9923 17.674 88.3648 19.3552 85.8487 19.3552H78.4941V22.1383H85.8487C89.9571 22.1383 92.5171 19.3954 92.5171 14.9793C92.5171 10.5632 90.0275 7.96503 85.8487 7.96503H72.6878V27.8575H75.2654V11.2148Z'
                        fill='#101828'
                      />
                      <path
                        d='M124.152 3.81033H117.844L111.167 12.4575L114.492 16.4955L124.152 3.81033Z'
                        fill='#101828'
                      />
                      <path
                        d='M111.124 22.8823L118.329 32.0041H125.129L114.625 18.5467L111.124 22.8823Z'
                        fill='#101828'
                      />
                      <path
                        d='M110.402 13.3906L102.52 3.81033H94.8834L105.581 17.3321L94.0037 32.0041H102.229L110.358 21.9412L113.868 17.5975L110.402 13.3906Z'
                        fill='#101828'
                      />
                      <path
                        d='M127.318 0.805668V0.5H129.535V0.805668H128.611V3.20274H128.242V0.805668H127.327H127.318Z'
                        fill='#101828'
                      />
                      <path
                        d='M129.809 3.20274V0.5H130.267L131.191 2.62359L132.114 0.5H132.572V3.20274H132.202V1.17569L131.314 3.20274H131.059L130.179 1.17569V3.20274H129.809Z'
                        fill='#101828'
                      />
                    </svg>
                  </div>
                  <h1 className='heading-1'>Change Password</h1>
                  <div className='login-form'>
                    <form noValidate className='form-wrapper' onSubmit={handleResetPassword} >
                      <div>
                        <Inputs
                          idRef='newPassword'
                          type={!newPasswords.ViewNewassword ? 'password' : ''}
                          labelValue={t(`${translationPath}new-password`)}
                          inputPlaceholder={t(`${translationPath}new-password`)}
                          helperText={getErrorByName(schema, 'newPassword').message}
                          error={getErrorByName(schema, 'newPassword').error}
                          isWithError
                          value={newPassword}
                          endAdornment={(
                            <Button
                              onClick={() => {
                                setNewPasswords({ ...newPasswords, ViewNewassword: !newPasswords.ViewNewassword });
                              }}
                              className='p-2'>
                              {newPasswords.ViewNewassword ? <span className='mdi mdi-eye-outline' /> : <span className='mdi mdi-eye-off-outline' />}
                            </Button>
                          )}
                          onInputChanged={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                      <div>
                        <Inputs
                          idRef='confirmPassword'
                          type={!newPasswords.ViewconfirmPassword ? 'password' : ''}
                          labelValue={t(`${translationPath}confirmPassword`)}
                          inputPlaceholder={t(`${translationPath}confirmPassword`)}
                          helperText={getErrorByName(schema, 'confirmPassword').message}
                          error={getErrorByName(schema, 'confirmPassword').error}
                          isWithError
                          value={confirmPassword}
                          endAdornment={(
                            <Button onClick={() => {
                              setNewPasswords({ ...newPasswords, ViewconfirmPassword: !newPasswords.ViewconfirmPassword });
                            }} className='p-2'>
                              {newPasswords.ViewconfirmPassword ? <span className='mdi mdi-eye-outline' /> : <span className='mdi mdi-eye-off-outline' />}
                            </Button>
                          )}
                          onInputChanged={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                      </div>
                      <div className='password-validation  my-3'>
                        <span className={atLeastOneNumber.test(newPassword) ? 'mdi mdi-check my-2' : 'mdi mdi-information-outline my-2'} >{t(`${translationPath}one-number`)}</span>
                        <span className={charactersLength.test(newPassword) ? 'mdi mdi-check my-2 ' : 'mdi mdi-information-outline my-2'} > {t(`${translationPath}8-20-characters`)}</span>
                        <span className={containUpper.test(newPassword) ? 'mdi mdi-check my-2' : 'mdi mdi-information-outline my-2'} >{t(`${translationPath}one-uppercase-letter`)}</span>
                        <span className={containSpecialCharacter.test(newPassword) ? 'mdi mdi-check my-2' : 'mdi mdi-information-outline my-2'} >{t(`${translationPath}special-character(!@#$%^&*)`)}</span>
                      </div>
                      <div className='d-flex-column-center'>
                        <Button className='login-btn' type='submit'>
                          {<span>{t(`${translationPath}change-password`)}</span>}
                        </Button>
                      </div>
                      <div className='back-btn d-flex-center my-3P5' onClick={() => setActiveLoginForm(1)}>
                        <img src={arrowLeft} className='mr-1' />
                        <span>
                          {t(`${translationPath}back-to-login`)}
                        </span>
                      </div>
                    </form>
                  </div>
                </div> : null}
              </div>
            </div>
            <div className='login-footer'>
              <p>Xeleration © 2024</p>
            </div>
          </div>
          <div className='slide-show-wrapper'></div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const {
    login: { loginResponse },
  } = state;
  return {
    loginResponse,
  };
};
LoginView.propTypes = {
  loginResponse: PropTypes.shape(undefined),
};
LoginView.defaultProps = {
  loginResponse: null,
};
const store = connect(mapStateToProps)(LoginView);

export { store as LoginView };
