import React, { useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import Joi from 'joi';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Inputs, SelectComponet, AutocompleteComponent } from '../../Components';
import { floatHandler, getErrorByName } from '../../Helper';
import { ReminderTypesEnum, ReminderForEnum } from '../../Enums';

export const ActivtityRemindersTab = ({
  schema,
  isWithReminder,
  defaultReminders,
  setRemindersWithoutEmptyItems,
  selected,
  isSubmitted,
  state,
  setState,
  setSelected,
  activeItem,
  parentTranslationPath,
  translationPath,
  loadings,
  data,
  isReplyDialog
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  const reminderAmountOfTimeTypes = [
    {
      key: 1,
      value: 'minutes',
      momentKey: 'minutes',
    },
    {
      key: 2,
      value: 'hours',
      momentKey: 'hours',
    },
    {
      key: 3,
      value: 'days',
      momentKey: 'days',
    },
  ];

  const defaultActivityReminderItem = useRef({
    reminderType: null,
    templateId: null,
    leadId: null,
    usersId: null,
    reminderTime: state.activityDate,
    reminderAmountOfTimeType: 1,
  });

  const getTimeValue = (item) => {
    if (!state.activityDate) return 0;
    const currentTimeAmountType = reminderAmountOfTimeTypes.find(
      (items) => items.key === (item.reminderAmountOfTimeType || 1)
    );

    return moment(state.activityDate).diff(item.reminderTime, currentTimeAmountType.momentKey);
  };

  const reminderTimeCalculateHandler = useCallback(
    (item, value, type) => {
      const currentTimeAmountType = reminderAmountOfTimeTypes.find(
        (items) => items.key === (type || item.reminderAmountOfTimeType || 1)
      );
      let newReminderTime = moment(state.activityDate)
        .add(-value, currentTimeAmountType.momentKey)
        .format('YYYY-MM-DDTHH:mm:ss');

      if (moment(newReminderTime).isBefore(moment().format('YYYY-MM-DDTHH:mm:ss'))){
        newReminderTime = null;
      }

      if(state.activityReminders){

        const remindersBeforeActivityDate = state.activityReminders.filter((item) =>
        newReminderTime
        );
        
        if(remindersBeforeActivityDate.length== 0) {
          setState({
            id: 'activityReminders',
            value: [{ ...defaultActivityReminderItem.current }],
        }); 
      }
      
      if(remindersBeforeActivityDate.length > 0) {
        setState({
          id: 'activityReminders',
          value: remindersBeforeActivityDate,
        });  
      }
    }
      return newReminderTime;
    },
    [reminderAmountOfTimeTypes, state.activityDate, state.activityReminders]
  );

  const reminderTimeChangeHandler = useCallback(
    (item) => (event) => {
      const value = floatHandler(event.target.value, 0);
      item.reminderTime = reminderTimeCalculateHandler(item, value);
    },
    [reminderTimeCalculateHandler]
  );

  const reminderDecrementHandler = useCallback(
    (index) => () => {
      const localActivityReminders = [...state.activityReminders];
      localActivityReminders.splice(index, 1);

      if (localActivityReminders.length == 0) {
        setState({
          id: 'activityReminders',
          value: [{ ...defaultActivityReminderItem.current }],
        });
      } else {
        setState({
          id: 'activityReminders',
          value: localActivityReminders,
        });
      }
    },
    [state.activityReminders]
  );
  const reminderIncrementHandler = useCallback(
    (index) => () => {
      const localActivityReminders = [...state.activityReminders];
      localActivityReminders.splice(index, 0, { ...defaultActivityReminderItem.current });
      setState({
        id: 'activityReminders',
        value: localActivityReminders,
      });
    },
    [state.activityReminders]
  );

  const getReminderTypeOptions = () => {
    let reminderTypeOptions = {
      agent: Object.values(ReminderTypesEnum),
      lead: [],
    };

    if (selected.relatedLead) {
      const leadOptions = [];
      const leadHasEmailAddress = selected.relatedLead.lead?.contact_name?.email_address;
      const leadHasPhoneNumber = selected.relatedLead.lead?.contact_name?.mobile ||
        selected?.relatedLead.lead?.contact_name?.phone;

      if (leadHasEmailAddress) leadOptions.push(ReminderTypesEnum.Email);
      if (leadHasPhoneNumber) leadOptions.push(ReminderTypesEnum.Sms);

      reminderTypeOptions.lead = leadOptions;
    }
    setSelected({ id: 'reminderTypeOptions', value: reminderTypeOptions });
  };


  const filterInvalidDefaultReminders = () => {
    const leadHasEmailAddress = selected.relatedLead?.lead?.contact_name?.email_address;
    const leadHasPhoneNumber = selected.relatedLead?.lead?.contact_name?.mobile ||
      selected.relatedLead?.lead?.contact_name?.phone;

    const remindersBeforeActivityDate = defaultReminders.filter((item) =>
      reminderTimeCalculateHandler(item, item.reminderTime || 0, 1)
    );

    const remindersWithValidReminderTypes = remindersBeforeActivityDate.filter((item) => {
      const isReminderForAgent = item.reminderTo === ReminderForEnum.Agent.key;
      const isValidLeadReminderType = ((item.reminderType == ReminderTypesEnum.Sms.key) && leadHasPhoneNumber) ||
        ((item.reminderType == ReminderTypesEnum.Email.key) && leadHasEmailAddress);

      return isReminderForAgent || isValidLeadReminderType;
    });

    return remindersWithValidReminderTypes;
  }

  useEffect(() => {
    let mappedDefaultReminders = [];

    if (!activeItem &&
        defaultReminders &&
        defaultReminders.length > 0) {

      const filteredDefaultReminders = filterInvalidDefaultReminders();

      const leadReminderForItem = selected.reminderForOptions&&selected.reminderForOptions.find((item) =>
      item.type==='lead'
      );
      const agentReminderForItem = selected.reminderForOptions&&selected.reminderForOptions.find((item) =>
      item.type==='agent'
      );
      mappedDefaultReminders = filteredDefaultReminders.map((item) => ({
        reminderType: item.reminderType,
        leadId: item.reminderTo == ReminderForEnum.Lead.key? leadReminderForItem?.id : null,
        usersId: item.reminderTo == ReminderForEnum.Agent.key? agentReminderForItem?.id : null,
        templateId: item.templateId,
        templateName: item.templateName,
        reminderTime: reminderTimeCalculateHandler(item, item.reminderTime || 0, 1),
        reminderAmountOfTimeType: 1,
      }));
    }

    if (!activeItem &&
        mappedDefaultReminders.length > 0 &&
        state.activityReminders.length == 0) {
      setState({
        id: 'activityReminders',
        value: mappedDefaultReminders,
      });
    }

    if (!activeItem &&
         mappedDefaultReminders.length === 0 &&
         state.activityReminders.length == 0) {
      setState({
        id: 'activityReminders',
        value: [{ ...defaultActivityReminderItem.current }],
      });
    }

    if (activeItem && state.activityReminders && !isReplyDialog) {

      const remindersWithLeadId = state.activityReminders.map(item=>({
        ...item,
        leadId: item.contactId? selected.relatedLead?.leadId : null,
      }))

      const filteredDefaultReminders = remindersWithLeadId.filter((item) =>
      reminderTimeCalculateHandler(item, getTimeValue(item) || 0, 1)
      );

      if(filteredDefaultReminders.length== 0) {
        setState({
          id: 'activityReminders',
          value: [{ ...defaultActivityReminderItem.current }],
        }); 
      }

      if(filteredDefaultReminders.length > 0) {
        setState({
          id: 'activityReminders',
          value: filteredDefaultReminders,
        });  
      }
    } 

    if (activeItem && state.activityReminders && isReplyDialog) {
      const filteredDefaultReminders = state.activityReminders.filter((item) =>
      reminderTimeCalculateHandler(item, getTimeValue(item) || 0, 1)
      );

      if(filteredDefaultReminders.length== 0) {
        setState({
          id: 'activityReminders',
          value: [{ ...defaultActivityReminderItem.current }],
        }); 
      }

      if(filteredDefaultReminders.length > 0) {
        setState({
          id: 'activityReminders',
          value: filteredDefaultReminders,
        });  
      }
    } 
  }, [defaultReminders, state.activityDate, selected.reminderForOptions, selected.relatedLead, selected.reminderTypeOptions]);
  
  useEffect(()=>{
    const filteredReminders = state.activityReminders.filter(
      (item) => (item.usersId || item.leadId) && item.reminderType && item.templateId && item.reminderTime )
      setRemindersWithoutEmptyItems(filteredReminders)

  }, [state.activityReminders])

  useEffect(()=>{
    getReminderTypeOptions()
  },[selected.relatedLead])

  return (
    <div className='dialog-content-wrapper'>
      {(isWithReminder && (
        <div className='reminder-wrapper'>
          {state.activityReminders &&
            state.activityReminders.map((item, index) => (
              <div className='reminder-item-wrapper' key={`remindersRef${index + 1}`}>
                <div className='mb-1 w-100 px-2'>
                  <span>{`${t(`${translationPath}reminder`)}# ${index + 1}`}</span>
                </div>
                <div className='reminder-inputs'>
                <div className='reminder-input'>
                  <SelectComponet
                    idRef={`reminderPersonRef${index + 1}`}
                    data={selected.reminderForOptions}
                    value={item.leadId || item.usersId}
                    placeholder='reminder-for'
                    valueInput='id'
                    textInput='value'
                    helperText={
                      getErrorByName(schema, `activityReminders.[${index}]`, 'state.userNotSet')
                        .message
                    }
                    error={
                      getErrorByName(schema, `activityReminders.[${index}]`, 'state.userNotSet')
                        .error
                    }
                    isSubmitted={isSubmitted}
                    onSelectChanged={(value) => {
                      if (value) {
                        const localReminderForValue = selected.reminderForOptions.find(
                          (element) => element.id === value
                        );

                        if (localReminderForValue.type === 'lead') {
                          item.leadId = value;
                          item.usersId = null;
                        }
                        if (localReminderForValue.type === 'agent') {
                          item.usersId = value;
                          item.leadId = null;
                        }
                      }
                      if (!value) {
                        item.leadId = null;
                        item.usersId = null;
                      }

                      item.reminderType = null;

                      setState({
                        id: 'activityReminders',
                        value: state.activityReminders,
                      });
                    }}
                    emptyItem={{
                      value: null,
                      text: 'select-reminder-for',
                      isDisabled: false,
                    }}
                    translationPathForData={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
                <div className='reminder-input'>
                  <SelectComponet
                    idRef={`reminderWayRef${index + 1}`}
                    data={(item.usersId&&selected?.reminderTypeOptions?.agent)||(item.leadId&&selected?.reminderTypeOptions?.lead)||[]}
                    value={item.reminderType}
                    valueInput='key'
                    textInput='value'
                    onSelectChanged={(value) => {
                      if(value) item.reminderType = +(value || 1);
                      else item.reminderType = null;
                        setState({
                          id: 'activityReminders',
                          value: state.activityReminders,
                        });
                    }}
                    translationPathForData={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    emptyItem={{
                      value: null,
                      text: 'reminder-type',
                      isDisabled: false,
                    }}
                  />
                </div>
                <div className='reminder-input'>
                    <AutocompleteComponent
                      idRef='remindertemplateIdRef'
                      selectedValues={{
                        templateId: item.templateId,
                        templateName: item.templateName,
                      } || []}
                      multiple={false}
                      data={data.reminderTemplates || []}
                      displayLabel={(option) => t(`${option.templateName || ''}`)}
                      getOptionSelected={(option) => option.templateId === item.templateId}
                      withoutSearchButton
                      inputPlaceholder={t(`${translationPath}Template`)}
                      isLoading={loadings.reminderTemplates}
                      isWithError
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        item.templateId = newValue?.templateId || null;
                        item.templateName = newValue?.templateName || null;

                        setState({
                          id: 'activityReminders',
                          value: state.activityReminders,
                        });
                      }}
                      />
                  </div>
                <div className='reminder-input'>
                  <div className='d-flex w-100'>
                    <Inputs
                      idRef='reminderTimeRef'
                      value={(item.reminderTime && getTimeValue(item)) || 0}
                      helperText={
                        getErrorByName(schema, `activityReminders.${index}`, 'state.timeIsZero')
                          .message
                      }
                      error={
                        getErrorByName(schema, `activityReminders.${index}`, 'state.timeIsZero')
                          .error
                      }
                      isDisabled={!state.activityDate || !state.assignAgentId}
                      wrapperClasses='mb-0'
                      endAdornment={
                        <SelectComponet
                          data={reminderAmountOfTimeTypes}
                          value={item.reminderAmountOfTimeType || 1}
                          valueInput='key'
                          textInput='value'
                          onSelectChanged={(value) => {
                            item.reminderAmountOfTimeType = +(value || 1);
                            reminderTimeCalculateHandler(
                              item,
                              getTimeValue(item) || 0,
                              +(value || 1)
                            );
                            setState({
                              id: 'activityReminders',
                              value: state.activityReminders,
                            });
                          }}
                          wrapperClasses='over-input-select w-auto'
                          idRef='timeAmountTypeRef'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      }
                      type='number'
                      min={0}
                      isWithError
                      isSubmitted={isSubmitted}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onInputChanged={reminderTimeChangeHandler(item)}
                    />
                  </div>
                </div>
                <div className='reminder-input'>
                  <ButtonBase
                    className='btns-icon theme-solid bg-secondary-light mt-1 mr-1'
                    onClick={reminderDecrementHandler(index)}>
                    <span className='mdi mdi-minus c-black-light' />
                  </ButtonBase>
                  {index === 0 && (
                    <ButtonBase
                      className='btns-icon theme-solid bg-secondary-light mt-1 mr-1'
                      disabled={!state.activityDate || !state.assignAgentId}
                      onClick={reminderIncrementHandler(index)}>
                      <span className='mdi mdi-plus c-black-light' />
                    </ButtonBase>
                  )}
                </div>
                </div>
              </div>
            ))}
        </div>
      )) ||
        'activity type is without reminders'}
    </div>
  );
};
