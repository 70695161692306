export const ActionsButtonsEnum = {
  1: {
    id: 'add',
    classes: 'bg-secondary',
    label: 'actions',
    buttonLabel: 'add',
  },
  2: {
    id: 'merge',
    classes: 'bg-secondary',
    label: 'merge',
    buttonLabel: 'merge',
  },
  3: {
    id: 'import',
    classes: 'bg-secondary',
    label: 'import',
    buttonLabel: 'import',
  },
  4: {
    id: 'bulk-update',
    classes: 'bg-secondary',
    label: 'bulk-update',
    buttonLabel: 'bulk-update',
  },
  5: {
    id: 'close-leads',
    classes: 'bg-secondary',
    label: 'close-leads',
    buttonLabel: 'close-leads',
  },
  6: {
    id: 'reassign-seeker-leads',
    classes: 'bg-secondary',
    label: 'reassign-seeker-leads',
    buttonLabel: 'reassign-seeker-leads',
  },
  7: {
    id: 'clone-leads',
    classes: 'bg-secondary',
    label: 'clone-leads',
    buttonLabel: 'clone-leads',
  },
  8: {
    id: 'send-to-rotation',
    classes: 'bg-secondary',
    label: 'send-to-rotation',
    buttonLabel: 'send-to-rotation',
  },
  9: {
    id: 'assign',
    classes: 'bg-secondary',
    label: 'assign',
    buttonLabel: 'assign',
  },
  10: {
    id: 'unAssign',
    classes: 'bg-secondary',
    label: 'unAssign',
    buttonLabel: 'unAssign',
  },
  11: {
    id: 'select',
    classes: 'bg-secondary',
    label: 'select',
    buttonLabel: 'select',
  },
  12: {
    id: 'qualify-lead',
    classes: 'bg-secondary',
    label: 'qualify-lead',
    buttonLabel: 'qualify-lead',
  },
  13:
  {
    id: 'unqualified-leads',
    classes: 'bg-secondary',
    label: 'unqualified-leads',
    buttonLabel: 'unqualified-leads',
  },
  14: {
    id: 'reassign-owner-leads',
    classes: 'bg-secondary',
    label: 'reassign-owner-leads',
    buttonLabel: 'reassign-owner-leads',
  },
  15: {
    id: 'make-transaction',
    classes: 'bg-secondary',
    label: 'make-transaction',
    buttonLabel: 'make-transaction',
  },
};
