import React, { useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import './../TaskDetailsView.scss';
import { useTranslation } from 'react-i18next';
import { Avatar, makeStyles } from '@material-ui/core';
import { PageHeading } from '../../../../../SharedComponents';
import { LoadableDocumentComponent } from '../../../../../Components';
import { TemplatesPreviewDialog } from '../../../../../Views/Home/TemplatesView/Dialogs';
import {
  getIsValidURL,
  getIsValidDataURL,
  getDownloadableLink,
  showError,
  getFirstLastNameLetters,
  GetParams
} from '../../../../../Helper';
import { GetCampaignRequestDetails } from '../../../../../Services';

export const CampaignDetailsTab = ({ parentTranslationPath, translationPath }) => {
  const { t } = useTranslation(parentTranslationPath);

  const useStyles = makeStyles((theme) => ({
    root: {
      height: 24,
      margin: 4,
    },
    label: {
      fontSize: 10,
    },
  }));

  const classes = useStyles();

  const [viewedFile, setViewedFile] = useState(null);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [campaignData, setCampaignData] = useState(null);

  const handleDownloadClick = (value) => () => {
    const link = document.createElement('a');
    link.setAttribute('download', value.name);
    link.href = getDownloadableLink(value.value);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const openLink = (url) => {
    if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
    if (getIsValidURL(url)) {
      window.open(url || '');
    } else if (getIsValidDataURL(url)) {
      let windowObj = window.open();
      const iframeElement = `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`;
      windowObj.document.write(iframeElement);
    }
  };

  const getCampaignRequestDetails = async () => {
    const campaignId = GetParams('campaignId');
    const res = await GetCampaignRequestDetails(campaignId);
    if (!(res && res.status && res.status !== 200)) {
      setCampaignData(res);
    }
  };

  useEffect(() => {
    getCampaignRequestDetails();
  }, []);

  return (
    <>
      <PageHeading
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerTitle={'Campaign-details'}
        subTitle={'Campaign-details-heading'}
      />

      <div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Campaign Name</span>
          </div>
          <div className='w-50'>
            <p className='text-tertiary'>{campaignData?.campaignName || '-'}</p>
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Campaign Creator</span>
          </div>
          <div className='w-50'>
            <p className='text-tertiary'>{campaignData?.campaignCreator || '-'}</p>
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Campaign Requestor</span>
          </div>
          <div className='w-50'>
            <p className='text-tertiary'>{campaignData?.campaignRequester || '-'}</p>
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Related To</span>
          </div>
          <div className='w-50'>
            <span className='text-tertiary b-600 mx-2'>{campaignData?.data?.RelatedTo?.type || ''}</span>
            <span className='text-tertiary mx-2'>{campaignData?.data?.RelatedTo?.generalTypeText || ''}</span>
            <p className='text-tertiary my-2'>
              {campaignData?.data?.RelatedTo?.values
                ? campaignData.data.RelatedTo.values.map((item) => (
                    <Chip classes={classes} label={item || ''} />
                  ))
                : ''}
            </p>
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Platform Name</span>
          </div>
          <div className='w-50'>
            {campaignData?.data?.PlatformName
              ? campaignData.data.PlatformName.map((item) => (
                  <Chip classes={classes} label={item.lookupItemName} />
                ))
              : '-'}
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Project Category</span>
          </div>
          <div className='w-50'>
            <p className='text-tertiary'>
              {campaignData?.data?.ProjectCategory?.lookupItemName || '-'}
            </p>
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Target Audience</span>
          </div>
          <div className='w-50'>
            {campaignData?.data?.TargetAudience
              ? campaignData.data.TargetAudience.map((item) => (
                  <Chip classes={classes} label={item.lookupItemName} />
                ))
              : '-'}
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Campaign Language</span>
          </div>
          <div className='w-50'>
            {campaignData?.data?.CampaignLanguage
              ? campaignData.data.CampaignLanguage.map((item) => (
                  <Chip classes={classes} label={item.lookupItemName} />
                ))
              : '-'}
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Media Requirements</span>
          </div>
          <div className='w-50'>
            {campaignData?.data?.MediaRequirements
              ? campaignData.data.MediaRequirements.map((item) => (
                  <Chip classes={classes} label={item.lookupItemName} />
                ))
              : '-'}
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Markcting Material</span>
          </div>
          <div className='w-50'>
            {campaignData?.data?.MarketingMaterial
              ? campaignData.data.MarketingMaterial.map((item) => (
                  <Chip classes={classes} label={item.lookupItemName} />
                ))
              : '-'}
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Campaign Key Messages</span>
          </div>
          <div className='w-50'>
            <p className='text-tertiary'>{campaignData?.data?.CampaignKeyMessages || '-'}</p>
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Campaign USPs</span>
          </div>
          <div className='w-50'>
            {campaignData?.data?.CampaignUsps
              ? campaignData.data.CampaignUsps.map((item) => (
                  <Chip classes={classes} label={item.lookupItemName} />
                ))
              : '-'}
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Campaign Reasons </span>
          </div>
          <div className='w-50'>
            {campaignData?.data?.CampaignReason
              ? campaignData.data.CampaignReason.map((item) => (
                  <Chip classes={classes} label={item.lookupItemName} />
                ))
              : '-'}
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'> Campaign Length</span>
          </div>
          <div className='w-50 default-d-flex'>
            <div className='w-50 mr-4 d-flex-column d-flex-center'>
              <span className='b-600 text-primary fz-10'> Days Count</span>
              <p className='text-tertiary'>{campaignData?.data?.CampaignLength || '-'}</p>
            </div>
          </div>
        </div>

        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Artwork Suggestion</span>
          </div>
          <div className='w-50'>
            {campaignData?.data?.ArtworkSuggestion?.length
              ? campaignData?.data?.ArtworkSuggestion.map((item) =>
                  item.type == 1 ? (
                    <div className='link-item-container py-3 px-2'>
                      <p className='text-tertiary ecilipse-text'>{item.value}</p>
                      <div className='d-flex-center'>
                        <span
                          className='b-600 text-primary pointer sec-color'
                          onClick={() => {
                            try {
                              openLink(item.value);
                            } catch (error) {
                              showError(t(`${translationPath}this-link-is-not-valid`));
                            }
                          }}>
                          Visit Link
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className='file-item-container  py-3'>
                      <div className='file-icon-wrapper d-flex-center'>
                        <span className='mr-1'>
                          <LoadableDocumentComponent
                            classes='file-icon'
                            fileName={item.name || ''}
                            alt={`cover-image`}
                          />
                        </span>
                        <span className='d-flex-column'>
                          <span className='file-title'>{item.name}</span>
                        </span>
                      </div>
                      <div className='d-flex-center'>
                        <span
                          className='b-600 text-primary pointer'
                          onClick={handleDownloadClick(item)}>
                          Download
                        </span>
                      </div>
                      <div className='d-flex-center'>
                        <span
                          className='b-600 text-primary pointer sec-color'
                          onClick={() => {
                            setIsOpenPreviewDialog(true);
                            setViewedFile({
                              templateFileId: item.value,
                              templateFileName: item.name,
                              templateText: '',
                            });
                          }}>
                          View
                        </span>
                      </div>
                    </div>
                  )
                )
              : '-'}
          </div>
        </div>
        {isOpenPreviewDialog && (
          <TemplatesPreviewDialog
            activeItem={viewedFile}
            maintitleText='FILE-VIEW'
            isOpen={isOpenPreviewDialog}
            isOpenChanged={() => {
              setIsOpenPreviewDialog(false);
              setViewedFile(null);
            }}
            parentTranslationPath='Shared'
            translationPath={translationPath}
          />
        )}
      </div>
    </>
  );
};
