import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent } from '../../../../../../Components';
import { RecipientTypeCoreEnum, ReminderTypeEnum } from '../../../../../../Enums';

export const AssignNotificationByComponent = ({
  parentTranslationPath,
  translationPath,
  setStateNotification,
  setAssigntoNOT,
  AssigntoNOT
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [selected, setSelected] = useState([]);

  
  useEffect(()=>{
  if(AssigntoNOT){

    setSelected(
      [...AssigntoNOT]
      )
    }
  
},[AssigntoNOT])


  return (
    <div>
      <AutocompleteComponent
        idRef='Assign-Notification-byRef'
        selectedValues={selected || []}
        multiple
        data={Object.values(ReminderTypeEnum) || []}
        chipsLabel={(option) => t(`${option.value || ''}`)}
        displayLabel={(option) => t(`${option.value || ''}`)}
        getOptionSelected={(option) =>
          selected.findIndex((item) => item.key === option.key) !== -1 || ''}
        withoutSearchButton
        inputPlaceholder={t(`${translationPath}notification-by`)}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setStateNotification(
            newValue &&
              newValue.map((x) => ({
                notifyBy: x.key,
              }))
          );
          setSelected(
            newValue &&
              newValue.map((x) => ({
                key: x.key,
                value: x.value,
              }))
          );
          setAssigntoNOT(
            newValue &&
              newValue.map((x) => ({
                key: x.key,
                value: x.value,
              }))
          );
        }}
      />
    </div>
  );
};

AssignNotificationByComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setStateNotification: PropTypes.func.isRequired,
};
