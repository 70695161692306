import React, {
  useState, useCallback, useEffect, useRef
} from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  GlobalHistory,
  bottomBoxComponentUpdate,
  showError,
  GlobalTranslate,
   showSuccess,
  returnPropsByPermissions,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from '../../../../Helper';
import {
  ViewTypes,
  AutocompleteComponent,
  SelectComponet,
  Spinner,
  PermissionsComponent,
  NoDataFoundComponent,
  NoContentComponent,
  NoSearchResultComponent,
} from '../../../../Components';
import {
  ActionsEnum,
  ViewTypesEnum,
  ActionsButtonsEnum,
  TableActions,
  AgentRoleEnum
} from '../../../../Enums';
import { OrganizationUserSearch, LeadsAdvanceSearchTest, GetAllInquiriesServices } from '../../../../Services';
import { ZeroMatchingSalePermissions } from '../../../../Permissions';
import { PaginationComponent } from '../../../../Components/PaginationComponent/PaginationComponent';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { useTitle } from '../../../../Hooks';
import { GlobalOrderFilterActions } from '../../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { ZeroMatchingCards, ZeroMatchingViewTable } from './ZeroMatchingUtilities';
import { InquiresCardComponent } from '../InquiresCardComponent/InquiresCardComponent';

const parentTranslationPath = 'Inquires';
const translationPath = '';

export const ZeroMatchingView = () => {
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}zero-matching-sale`));

  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const dispatch = useDispatch();
  const [list, setList] = useState([
    {
      enum: TableActions.openFile.key,
    }
  ]);

  // eslint-disable-next-line no-unused-vars
  const localStorageFilter = localStorage.getItem('GlobalFilter');
  const [activeItem, setActiveItem] = useState(null);
  const [inquiry, setInquiry] = useState(null);
  const [isLoading, setIsLoading] = useState({
        Inquiries: false,
        assignTo: false,
        createdBy: false,
        inquiry: false,
        allLeads: false
  });

  const [filterSectionLists, setFilterSectionLists] = useState({
   allAssignTo: [],
   allCreatrdBy: [],
   allleads: [],
  });
  const [sortBy, setSortBy] = useState(null);

  const [allZeroMatching, setAllZeroMatching] = useState({
    result: [],
    totalCount: 0,
  });

  const [timer, setTimer] = useState(null);
  const [searchedItem, setSearchedItem] = useState('');
  const searchTimer = useRef(null);
  const [viewTypes, setViewTypes] = useState(ViewTypesEnum.cards.key);
  // eslint-disable-next-line no-unused-vars

  const loginResponse = useSelector((state) => state.login.loginResponse);

  const [activeActionType, setActiveActionType] = useState(
    localStorage.getItem('ViewType') &&
    JSON.parse(localStorage.getItem('ViewType')).leadsLeaseView ||
    ViewTypesEnum.cards.key
);

  const [orderByToggler, setOrderByToggler] = useState(false);

  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.filterBy,
    orderBy: orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.orderBy,
  });

  const [filtersZeroMatching, setFiltersZeroMatching] = useState({
    lead: (orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.lead) || null,
    inquiryStatus: (orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.inquiryStatus) || null,
    assignTo: (orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.assignTo) || null,
    createdBy: (orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.createdBy) || null,
    activeActionType: (orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.activeActionType) || null,
    pageSize: (orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.pageSize) || null

  });
  const [orderBy, setOrderBy] = useState(selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: 'CreatedOn', orderBy: 2 });

  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    inquiryTypeId: 1,
    assignedTo: (orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.assignTo && orderFilter.zeroMatchingSale.assignTo.id) || null,
    inquieryStatusId: (orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.inquiryStatus) || null,
    leadId: ((orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.lead && orderFilter.zeroMatchingSale.lead.leadId) || null),
    filterBy: (orderBy.filterBy) || null,
    orderBy: (orderBy.orderBy) || null,
    withZeroMatching: true,
    createdBy: (orderFilter && orderFilter.zeroMatchingSale && orderFilter.zeroMatchingSale.createdBy && orderFilter.zeroMatchingSale.createdBy.id) || null
  });

  const onTypeChanged = useCallback(
    (activeType) => {
      setViewTypes(activeType);
    },
    [setViewTypes]
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        zeroMatchingSale: {
          ...orderFilter.zeroMatchingSale,
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };

  const getAllInquiries = useCallback(async () => {
    setIsLoading((item) => ({ ...item, Inquiries: true }));
    if (returnPropsByPermissions(ZeroMatchingSalePermissions.ViewInquiry.permissionsId)) {
      const body = {
        ...filter,
        filterBy: orderBy.filterBy,
        orderBy: orderBy.orderBy,
        pageIndex: filter.pageIndex + 1
      };
    const res = await GetAllInquiriesServices(body);
    if (res) {
      setAllZeroMatching({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllZeroMatching({
        result: [],
        totalCount: 0,
      });
    }
  }
    setIsLoading((item) => ({ ...item, Inquiries: false }));
  }, [filter, orderBy]);

  const GetUsers = useCallback(async (searchItem) => {
    setIsLoading((item) => ({ ...item, createdBy: true }));

      const res = await OrganizationUserSearch({
        pageSize: 25, pageIndex: 0, name: searchItem, userStatusId: 2,

      });
      if (!(res && res.status && res.status !== 200))
         setFilterSectionLists((item) => ({ ...item, allCreatrdBy: (res.result) }));
       else
        setFilterSectionLists((item) => ({ ...item, allCreatrdBy: [] }));

      setIsLoading((item) => ({ ...item, createdBy: false }));
  });

  const GetAssignTo = useCallback(async (searchItem) => {
    setIsLoading((item) => ({ ...item, assignTo: true }));

        const res = await OrganizationUserSearch({
 pageSize: 25,
pageIndex: 0,
name: searchItem,
userStatusId: 2,
          userTypeId: AgentRoleEnum.SaleListingAgent.value

        });
        if (!(res && res.status && res.status !== 200))
           setFilterSectionLists((item) => ({ ...item, allAssignTo: (res.result) }));
         else
          setFilterSectionLists((item) => ({ ...item, allAssignTo: [] }));

        setIsLoading((item) => ({ ...item, assignTo: false }));
      });

    const GetAllLeads = useCallback(async (searchItem) => {
         setIsLoading((item) => ({ ...item, allleads: true }));
         let criteria = null;
         if (!isNaN(searchItem)) {
          criteria = searchItem ?
           {
          lead_type_id: [{ searchType: 2, value: 2 }],
          'status.lookupItemName': [{ searchType: 1, value: 'open' }],
          Ids: [{ searchType: 2, value: searchItem }]
} :
          {
            lead_type_id: [{ searchType: 2, value: 2 }],
            'status.lookupItemName': [{ searchType: 1, value: 'open' }],
           };
         } else {
          criteria = searchItem ?
          {
         lead_type_id: [{ searchType: 2, value: 2 }],
         'status.lookupItemName': [{ searchType: 1, value: 'open' }],
         contact_name: [{ searchType: 2, value: searchItem }]
} :
          {
           lead_type_id: [{ searchType: 2, value: 2 }],
           'status.lookupItemName': [{ searchType: 1, value: 'open' }],
          };
         }

         const body = {
          criteria,
          orderBy: 2,
          filterBy: 'createdOn',
          OperationType: 'Sale'

      };

      const list = [];
      const res = await LeadsAdvanceSearchTest({ pageIndex: 0, pageSize: 10 }, body);
      if (!(res && res.status && res.status !== 200)) {
        res && res.result && res.result.map((value) => {
        const leadJson = JSON.parse(value.leadJson);
        list.push({
          leadId: value.leadId,
          name: (leadJson.lead && leadJson.lead.contact_name && leadJson.lead.contact_name.name),
          leadType: value.leadsType,
          leadClass: (leadJson.lead && leadJson.lead.leadClass),
          budgetFrom: (leadJson && leadJson.lead && leadJson.lead.budgetFrom) || null,
          budgetTo: (leadJson && leadJson.lead && leadJson.lead.budgetTo) || null,
          bathrooms: (leadJson.lead && leadJson.lead.bathrooms && leadJson.lead.bathrooms.length > 0 && leadJson.lead.bathrooms) || null,
          bedrooms: (leadJson.lead && leadJson.lead.bedrooms && leadJson.lead.bedrooms.length > 0 && leadJson.lead.bedrooms) || null,
          sizeAreaFrom: (leadJson.lead && leadJson.lead.sizeAreaFrom) || null,
          sizeAreaTo: (leadJson.lead && leadJson.lead.sizeAreaTo) || null,
          community: (leadJson.lead && leadJson.lead.community) || null,
          property: (leadJson.lead && leadJson.lead.property_name) || null,
         });
       });

       setFilterSectionLists((item) => ({ ...item, allleads: list }));
     } else
      setFilterSectionLists((item) => ({ ...item, allleads: [] }));

     setIsLoading((item) => ({ ...item, allLeads: false }));
  });

  useEffect(() => {
    GetUsers();
    GetAssignTo();
    GetAllLeads();
  }, []);

  useEffect(() => {
    getAllInquiries();
  }, [filter, orderBy]);

  useEffect(() => {
    if (returnPropsByPermissions(ZeroMatchingSalePermissions.ViewInquiry.permissionsId)) {
       bottomBoxComponentUpdate(
         <PaginationComponent
           pageIndex={filter.pageIndex}
           pageSize={filter.pageSize}
           totalCount={allZeroMatching.totalCount}
           onPageIndexChanged={onPageIndexChanged}
           onPageSizeChanged={onPageSizeChanged}
         />
    );
    }
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  useEffect(() => {
    if (sortBy) {
 setOrderBy((item) => ({
 ...item, filterBy: sortBy.filterBy && sortBy.filterBy, orderBy: sortBy.orderBy, fieldType: sortBy.fieldType
}));
}
  }, [sortBy]);

  return (
    <div className='view-wrapper'>
      <Spinner isActive={isLoading.Inquiries} isAbsolute />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section'>
              <PermissionsComponent
                permissionsList={Object.values(ZeroMatchingSalePermissions)}
                permissionsId={ZeroMatchingSalePermissions.AddNewSaleInquiry.permissionsId}
              >
                <ButtonBase
                  className='btns theme-solid'
                  idRef='AddNewZeroMatchingInquiry'
                  id='AddNewZeroMatchingInquiry'
                  onClick={() => {
                        GlobalHistory.push('/home/zero-matching-sale/add?formType=1');
                      }}
                >
                  <span>{t(`${translationPath}add-new-zero-matching-sale`)}</span>
                </ButtonBase>
              </PermissionsComponent>
            </div>
            <PermissionsComponent
              permissionsList={Object.values(ZeroMatchingSalePermissions)}
              permissionsId={ZeroMatchingSalePermissions.ViewInquiry.permissionsId}
            >
              <div className='section autocomplete-section'>
                <div className='d-flex-column px-2 w-100 p-relative mb-2'>
                  <div className='w-100 p-relative mb-2'>
                    <AutocompleteComponent
                      data={filterSectionLists && filterSectionLists.allleads}
                      wrapperClasses='autocomplete-with-btn'
                      isLoading={isLoading.allLeads}
                      withLoader
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                      selectedValues={filtersZeroMatching.lead}
                      filterOptions={(options) => options}
                      multiple={false}
                      displayLabel={(option) =>
                        (option && option.name) || ''}
                      chipsLabel={(option) => (option && option.name) || ''}
                      inputPlaceholder='search-leads'
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                       if (searchTimer.current) clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            GetAllLeads(value);
                        }, 700);
                       }}
                      onChange={(event, newValue) => {
                        setFiltersZeroMatching((item) => ({ ...item, lead: newValue }));
                        setFilter((item) => ({ ...item, leadId: (newValue && newValue.leadId) || null }));
                        dispatch(
                          GlobalOrderFilterActions.globalOrderFilterRequest({
                            ...orderFilter,
                            zeroMatchingSale: {
                              ...orderFilter.zeroMatchingSale,
                              lead: newValue,
                            },
                          })
                        );
                      }}
                    />
                  </div>
                  <div className='agentSection pl-5-reversed'>
                    <div className='mr-1-reversed'>
                      <SelectComponet
                        data={[
                          { id: 1, name: 'Open' },
                          { id: 2, name: 'Assigned' },
                          { id: 3, name: 'Canceled' },
                          { id: 4, name: 'Completed' },

                        ]}
                        emptyItem={{ value: null, text: 'select-status', isDisabled: false }}
                        valueInput='id'
                        textInput='name'
                        onSelectChanged={(value) => {
                          setFiltersZeroMatching((item) => ({ ...item, inquiryStatus: value }));
                          setFilter((item) => ({ ...item, inquieryStatusId: value }));
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              zeroMatchingSale: {
                                ...orderFilter.zeroMatchingSale,
                                inquiryStatus: value
                              },
                            })
                          );
                        }}
                        value={filtersZeroMatching.inquiryStatus}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='inquiry_status'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <div className='w-100 mr-1-reversed'>
                      <AutocompleteComponent
                        idRef='assignToRef'
                        isLoading={isLoading.assignTo}
                        inputPlaceholder={t(`${translationPath}assignTo`)}
                        selectedValues={filtersZeroMatching.assignTo}
                        data={filterSectionLists && filterSectionLists.allAssignTo}
                        multiple={false}
                        displayLabel={(option) =>
                          (option && option.fullName) || ''}
                        chipsLabel={(option) => (option && option.fullName) || ''}
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setFiltersZeroMatching((item) => ({ ...item, assignTo: newValue }));
                          setFilter((item) => ({ ...item, assignedTo: (newValue && newValue.id) || '' }));
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              zeroMatchingSale: {
                                ...orderFilter.zeroMatchingSale,
                                assignTo: newValue,
                              },
                            })
                          );
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                         if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              GetAssignTo(value);
                          }, 700);
                         }}
                      />

                    </div>
                    <div className='w-100 mr-1-reversed'>
                      <AutocompleteComponent
                        idRef='createdByRef'
                        inputPlaceholder={t(`${translationPath}createdBy`)}
                        data={filterSectionLists && filterSectionLists.allCreatrdBy}
                        selectedValues={filtersZeroMatching.createdBy}
                        isLoading={isLoading.createdBy}
                        withLoader
                        multiple={false}
                        displayLabel={(option) =>
                          (option && option.fullName) || ''}
                        chipsLabel={(option) => (option && option.fullName) || ''}
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setFiltersZeroMatching((item) => ({ ...item, createdBy: newValue }));
                          setFilter((item) => ({ ...item, createdBy: (newValue && newValue.id) || '' }));
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              zeroMatchingSale: {
                                ...orderFilter.zeroMatchingSale,
                                createdBy: newValue,
                              },
                            })
                          );
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                         if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              GetUsers(value);
                          }, 700);
                         }}
                      />
                    </div>
                  </div>
                </div>

                <div className='view-search-wrapper'>
                  <ViewTypes
                    onTypeChanged={onTypeChanged}
                    initialActiveType={activeActionType}
                    activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
                    className='mb-3'
                  />
                </div>
              </div>
            </PermissionsComponent>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(ZeroMatchingSalePermissions)}
            permissionsId={ZeroMatchingSalePermissions.ViewInquiry.permissionsId}
          >

            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}Inquires`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}
                  :
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='elect-filter-byfilterByRef'
                    data={[
                      { id: 'CreatedOn', filterBy: 'created-on' },
                      { id: 'UpdateOn', filterBy: 'last-updated' },
                    ]}
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={filterByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='filterBy'
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    emptyItem={{ value: null, text: 'select-sort-by', isDisabled: false }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    id='action_apply'
                    onClick={orderBySubmitted}
                    disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>
        <>
          <PermissionsComponent
            permissionsList={Object.values(ZeroMatchingSalePermissions)}
            permissionsId={ZeroMatchingSalePermissions.ViewInquiry.permissionsId}
          >

            {((allZeroMatching && allZeroMatching.result && allZeroMatching.result.length === 0) || (allZeroMatching && allZeroMatching.totalCount === 0)) && (filter.search !== '') ? (
              <NoSearchResultComponent />
        ) : (

          (allZeroMatching && allZeroMatching.totalCount === 0 && (
            <NoContentComponent />
          )) || (

            (viewTypes === ViewTypesEnum.tableView.key && (
              <>
                <div className='view-wrapers'>
                  <ZeroMatchingViewTable
                    translationPath={translationPath}
                    data={allZeroMatching}
                    filter={filter}
                    parentTranslationPath={parentTranslationPath}
                    setActiveItem={setActiveItem}
                    setFilter={() => setFilter((item) => ({ ...item, pageIndex: 0 }))}
                    activeItem={activeItem}
                    isLoading={isLoading}
                    setSortBy={setSortBy}
                  />
                </div>
              </>
            )) ||
            (viewTypes === ViewTypesEnum.cards.key && (
              <>
                <div className='view-wrapers'>
                  <InquiresCardComponent
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    data={allZeroMatching && allZeroMatching.result}
                    filter={filter}
                    setFilter={() => setFilter((item) => ({ ...item, pageIndex: 0 }))}
                    isLoading={isLoading}
                    inquiryType='Zero Matching Sale'
                  />
                </div>
              </>
              ))
            ))}
          </PermissionsComponent>
        </>

      </div>
    </div>
  );
};
