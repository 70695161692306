/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { ButtonBase, Button, Tooltip } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GlobalHistory, WhatsAppMessage, getDownloadableLink, showError, showinfo } from '../../../../Helper';
import { LoadableImageComponant, Spinner } from '../../../../Components';
import { ActionsEnum, LoadableImageEnum } from '../../../../Enums';
import { DefaultImagesEnum } from '../../../../Enums/DefaultImages.Enum';
import { GetActivityById, GetAllActivitiesByLeadId, GetAllActivities } from '../../../../Services';
import { TableActions } from '../../../../Enums/TableActions.Enum';
import { ReplyActivityDialog } from '../../ActivitiesView/ReplyActivitesView/ReplyActivityDialog';
import '../style/CardDetailsComponent.scss'
import moment from 'moment';
import { ActivitiesManagementDialog } from '../../ActivitiesSalesView/ActivitiesSalesViewUtilities/Dialogs/ActivitiesManagementDialog';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';
import { LeadsActionDialogsComponent } from '../../LeadsView/LeadsUtilities/LeadsActionDialogsComponent/LeadsActionDialogsComponent';

function UnqualifiedDetailsTab({
  activeData,
  cardDetailsActionClicked,
  translationPath,
  parentTranslationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [hide, sethide] = useState(false);
  const [edit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [openReply, setOpenReply] = useState(false);
  const [qaInfo, setQaInfo] = useState(true);
  const [activityBtn, setActivityBtn] = useState(false);
  const [activities, setActivities] = useState([]);
  const [isOpenContactsActionDialog, setisOpenContactsActionDialog] = useState(false);
  const [detailedCardAction, setdetailedCardAction] = useState(() => ({
    actionEnum: '',
    item: '',
  }));
  const handleActiveItem = useCallback(async (activityId, key) => {
    if (key === TableActions.replyText.key) {
      setOpenReply(true);
      setIsLoading(true);
      const res = await GetActivityById(activityId);
      if (!(res && res.status && res.status !== 200)) {
        setActiveItem(res);
        setIsLoading(false);
      }
    } else {
      setOpen(true);
      setIsEdit(true);
      setIsLoading(true);
      const res = await GetActivityById(activityId);
      if (!(res && res.status && res.status !== 200)) setActiveItem(res);
      setIsLoading(false);
    }
  }, []);

  const getActivities = useCallback(async () => {
    setIsLoading(true);
    const body = {
      pageIndex: 1,
      pageSize: 25,
      relatedLeadId: activeData?.leadId
    }
    const result = await GetAllActivities(body);
    if (!(result && result.status && result.status !== 200)) setActivities(result.result);
    else setActivities([]);
    setIsLoading(false);
  }, [activeData && activeData.leadId]);

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  useEffect(() => {
    sethide(false);
  }, [activeData && (activeData.leadId)]);



  const onActionClicked = useCallback(
    (actionEnum, item) => (event) => {
      setdetailedCardAction({
        actionEnum,
        item,
      });
      event.stopPropagation();
      setisOpenContactsActionDialog(true);

      if (actionEnum === 'whatsapp') {
        const el = document.createElement('a');
        if (item?.contact_name?.mobile) {
          el.href = WhatsAppMessage(item?.contact_name?.mobile);
          el.target = 'blank';
          el.click();
        } else
          showError(t(`${translationPath}Failure-Open-WhatsApp`));
      }

    },
    []
  );


  return (
    <div className="Unqualified-details-wrapper">
      <div className='leads-card-detaild-wrapper'>

        <div className='side-menu-wrapper mb-3'>
          <div className='d-flex-center mb-3 '>
            <div className='data-schedule-button'>
              <div className={`data-file-button ${qaInfo ? 'selected' : ''}`}>
                <Button
                  id='qa-Info-ref'
                  onClick={() => {
                    setActivityBtn(false);
                    setQaInfo(true);
                  }}
                  className={`btns ${qaInfo ? 'theme-outline' : ''}`}
                >
                  {t(`${translationPath}Contacts-Info`)}
                </Button>
              </div>
              <div className={`schedule-button ${activityBtn ? 'selected' : ''}`}>
                <Button
                  id='activity-details-ref'
                  onClick={() => {
                    setActivityBtn(true);
                    setQaInfo(false);
                  }}
                  className={`btns ${activityBtn ? 'theme-outline' : ''}`}
                >
                  {t(`${translationPath}activity-details`)}
                </Button>
              </div>
            </div>
          </div>
          {qaInfo && (
            <div className='px-3 mb-3 slider-data'>
              <div className='UnqualifiedDetailsTab childs-wrapper w-100 '>
                <div>
                  <div className='main-title'>{t(`${translationPath}Contacts-profile`)}</div>
                  <div className='back'>
                    <div className='merge-wrapper '>
                      <LoadableImageComponant
                        classes='details-img'
                        alt={t(`${translationPath}contact-image`)}
                        src={
                          (activeData && activeData.contactImage && activeData.contactImage.fileId) ?
                            getDownloadableLink(activeData && activeData.contactImage.fileId) :
                            DefaultImagesEnum.man.defaultImg
                        }
                      />
                    </div>

                  </div>
                  <div className='leadName-title'>
                    {' '}
                    {activeData.leadName}
                    {' '}
                  </div>
                  <div className='contact-Id'>
                    <>
                      {' '}
                      <div className='contact'>{t(`${translationPath}leadId`)}</div>
                      <div className='id'>
                        {(
                          activeData && activeData.leadId && (
                            <CopyToClipboardComponents
                              data={activeData.leadId}
                              childrenData={activeData.leadId}
                              CustomizationClassName='block-container low-space'
                            />
                          )) ||
                          'N/A'}
                        {' '}
                      </div>
                      <div className='contact mt-2'>{t(`${translationPath}leadType`)} </div>
                      <div className='id'>
                        {activeData.leadType || 'N/A'}
                        {' '}
                      </div>

                      <div className='contact mt-2'>{t(`${translationPath}leadName`)}</div>
                      <div className='id'>
                        {activeData.leadName || 'N/A'}
                        {' '}
                      </div>
                      <div className='contact mt-2'>{t(`${translationPath}unqualifiedBy`)}</div>
                      <div className='id'>
                        {activeData.unqualifiedBy || 'N/A'}
                        {' '}
                      </div>
                      <div className='contact mt-2'>{t(`${translationPath}Numberofunqualified`)}</div>
                      <div className='id'>
                        {activeData.numberOfUnqualified || 'N/A'}
                        {' '}
                      </div>
                      <div className='contact mt-2'>{t(`${translationPath}creationDate`)}</div>
                      <div className='id'>
                        {`${moment(activeData.creationDate).format("DD/MM/YYYY")}` || 'N/A'}
                        {' '}
                      </div>
                      <div className='mb-2  mt-2'>
                        <span className='mr-1 texts gray-primary-bold'>
                          {t(`${translationPath}reasonName`)}
                          :
                        </span>
                        <span className='body-side texts s-gray-primary'>{`${activeData.reasonName || 'N/A'}`}</span>
                      </div>
                      <div className='contact mt-2'>{t(`${translationPath}contactPreferences`)}</div>
                      <div className='actions-wrapper'>
                        {(activeData && activeData.contactPreferences && activeData.contactPreferences.includes("SMS") && (
                          <ButtonBase
                            className={ActionsEnum.smsSolid.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.smsSolid.key, JSON.parse(activeData.leadJson).lead)}
                          >
                            <span className={ActionsEnum.smsSolid.icon} />
                          </ButtonBase>
                        )) || ''}
                        {(activeData && activeData.contactPreferences && activeData.contactPreferences.includes("WhatsApp") &&
                          <ButtonBase
                            className={ActionsEnum.whatsappSolid.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.whatsapp.key, JSON.parse(activeData.leadJson).lead)}
                          >
                            <span className={ActionsEnum.whatsappSolid.icon} />
                          </ButtonBase>
                        )}
                        {(activeData && activeData.contactPreferences && activeData.contactPreferences.includes("Email") &&
                          <ButtonBase
                            className={ActionsEnum.emailSolid.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.emailSolid.key, JSON.parse(activeData.leadJson).lead)}
                          >
                            <span className={ActionsEnum.emailSolid.icon} />
                          </ButtonBase>
                        )}
                        {(activeData && activeData.contactPreferences && activeData.contactPreferences.includes("Call") &&
                          <ButtonBase
                            className={ActionsEnum.call.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.email.key, JSON.parse(activeData.leadJson).lead)}
                          >
                            <span className={ActionsEnum.call.icon} />
                          </ButtonBase>
                        )}
                      </div>


                      <div className='contact mt-2'>{t(`${translationPath}unqualifiedDate`)}</div>
                      <div className='id'>
                        {`${moment(activeData.unqualifiedDate).format("DD/MM/YYYY")}` || 'N/A'}
                        {' '}
                      </div>

                      {' '}
                    </>
                  </div>
                </div>


              </div>
            </div>
          )}
          {activityBtn && (
            <div className="px-3 mb-3 slider-data">
              <Spinner isActive={isLoading} isAbsolute={true} />
              <div className='mb-3'>
                {activities && activities.map((element) =>
                  <div className='cards-wrapper'>
                    <div>{element.isOpen ? <span className='open-status'>{t(`${translationPath}open`)}</span> : <span className='close-status'>{t(`${translationPath}close`)}</span>}</div>
                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}assigned-to `)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.assignedTo || ''}`}</span>
                    </div>

                    <div className='date mb-3'>
                      <span className='date mr-1 texts gray-primary-bold'>
                        <span className='details-icon mdi mdi-calendar mdi-18px mr-1 ' />
                      </span>
                      <span className='date texts s-gray-primary'>{`${moment(element.activityDate).format("DD/MM/YYYY")}`}</span>
                      <span className='date mr-1 texts gray-primary-bold'>
                        <span className='date mdi mdi-clock-time-four-outline ml-4 mr-1' />
                      </span>
                      <span className='date texts s-gray-primary'>{`${moment(element.activityDate).format('LT')}`}</span>
                    </div>

                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}created-by`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.createdByName || ''}`}</span>
                    </div>
                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}category`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.categoryName || ''}`}</span>
                    </div>
                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}activityName`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.activityTypeName || ''}`}</span>
                    </div>
                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}comments`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.comments || ''}`}</span>
                    </div>

                  </div>
                )}
                {activities && activities.length === 0 &&
                  <div class="MuiGrid-root no-content-text MuiGrid-item flex-d-inline-flex-center"><h1 class="no-content-title">
                    {t(`${translationPath}NoContent`)}
                  </h1> </div>}
              </div>
            </div>
          )}
        </div>
        {qaInfo && (<div className='side-menu-actions'>
          <div className='d-flex-center'>
            <ButtonBase
              id='open-file-ref'
              className='btns theme-solid mx-2 mb-0 w-50'
              onClick={() =>
                GlobalHistory.push(
                  `/home/leads/lead-profile-edit?formType=${activeData.leadType === 'Buyer' || activeData.leadType === 'Tenant' ? 2 : activeData.leadType === 'Landlord' || activeData.leadType === 'Seller' ? 1 : 0}&id=${activeData.leadId}&leadClass=${activeData.leadType}`
                )}
            >
              <span className='icons i-folder-white' />
              <span className='mx-2'>{t(`${translationPath}open-file`)}</span>
            </ButtonBase>
            {/* <div className='cards-footer-wrapper'>
              <ButtonBase
                id='reply-ref'
                className='btns theme-transparent w-50 mx-2'
                disabled={!activeData.isReplyAble}
                onClick={() => {
                }}
              >
                <span className='mdi mdi-reply-outline' />
                <span className='px-1'>{t('reply')}</span>
              </ButtonBase>
            </div> */}
          </div>
        </div>)}

      </div>

      {openReply && (
        <ReplyActivityDialog
          isLoading={isLoading}
          open={openReply}
          close={() => {
            setActiveItem(null);
            setOpenReply(false);
          }}
          activeItem={activeItem}
          onSave={() => {
            setOpenReply(false);
            setActiveItem(null);
          }}
          translationPath={translationPath}
          parentTranslationPath='ActivitiesView'
        />
      )}
      {open && (
        <ActivitiesManagementDialog
          open={open}
          activeItem={activeItem}
          isLoading={isLoading}
          isEdit={edit}
          onSave={() => {
            setOpen(false);
            setActiveItem(null);
            setIsEdit(false);
          }}
          close={() => {
            setActiveItem(null);
            setOpen(false);
            setIsEdit(false);
          }}
          translationPath={translationPath}
          parentTranslationPath='ActivitiesView'
        />
      )}

      <LeadsActionDialogsComponent
        isOpen={isOpenContactsActionDialog}
        isOpenChanged={() => setisOpenContactsActionDialog(false)}
        actionEnum={detailedCardAction.actionEnum}
        item={detailedCardAction.item}
        translationPath={translationPath}
        parentTranslationPath='ContactsView'
      />
    </div>

  );
}

UnqualifiedDetailsTab.propTypes = {
  activeData: PropTypes.instanceOf(Object),
  relodedata: PropTypes.func,
};
UnqualifiedDetailsTab.defaultProps = {
  activeData: null,
  cardDetailsActionClicked: () => { },
};

export { UnqualifiedDetailsTab };
