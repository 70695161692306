import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { ActionsButtonsEnum, LeadActionsButtonsEnum } from '../../../Enums';
import { SelectComponet } from '../SelectComponent/SelectComponent';
import { PermissionsComponent, CheckboxesComponent } from '../..';

const translationPath = 'Shared:actions-buttons.';
const ActionsButtonsComponent = ({
  onActionButtonChanged,
  onActionsButtonClicked,
  onFormTypeSelectChanged,
  withType,
  typeData,
  isDisabled,
  wrapperClasses,
  enableMerge,
  enableBulk,
  enableImport,
  permissionsList,
  addPermissionsId,
  selectPermissionsId,
  checkDisable,
  withText,
  enableCloseLeads,
  enableQualifyLead,
  closeAction,
  withCheckbox,
  onSelectAllClicked,
  enablereassignOwnerLeads,
  enablereassignSeekerLeads,
  enableCloneLeads,
  leadActiveAction,
  enableToSendToRotation,
  enableUnAssign,
  enableAssign,
  assignmentAction,
  selectedAction,
  enableUnQualifyLead,
  enableMakeTransaction
}) => {
  const { t } = useTranslation('Shared');
  const [activeAction, setActiveAction] = useState(() => ActionsButtonsEnum[1]);
  const [actionData, setActionData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const onSelectChanged = useCallback(
    (selectedValue) => {
      setActiveAction(selectedValue);
      if (onActionButtonChanged) onActionButtonChanged(selectedValue.id);
    },
    [onActionButtonChanged, setActiveAction]
  );
  const getActionData = useCallback(() => {
    const actions = [ActionsButtonsEnum[1]];
    if (enableMerge) actions.push(ActionsButtonsEnum[2]);
    if (enableImport) actions.push(ActionsButtonsEnum[3]);
    if (enableBulk) actions.push(ActionsButtonsEnum[4]);
    if (enableCloseLeads) actions.push(ActionsButtonsEnum[5]);
    if (enablereassignSeekerLeads) actions.push(ActionsButtonsEnum[6]);
    if (enablereassignOwnerLeads) actions.push(ActionsButtonsEnum[14]);
    if (enableCloneLeads) actions.push(ActionsButtonsEnum[7]);
    if (enableToSendToRotation) actions.push(ActionsButtonsEnum[8]);
    if (enableQualifyLead) actions.push(ActionsButtonsEnum[12]);
    if (enableAssign) actions.push(ActionsButtonsEnum[9]);
    if (enableUnAssign) actions.push(ActionsButtonsEnum[10]);
    if (enableUnQualifyLead) actions.push(ActionsButtonsEnum[13]);
    if (enableMakeTransaction) actions.push(ActionsButtonsEnum[15]);
    setActionData(actions);
  }, [enableBulk, enableImport, enableMerge, enableCloseLeads, enablereassignOwnerLeads, enablereassignSeekerLeads, enableCloneLeads, enableQualifyLead, enableAssign, enableUnAssign]);
  useEffect(() => {
    getActionData();
  }, [getActionData]);

  useEffect(() => {
    if (closeAction) {
      setActiveAction(ActionsButtonsEnum[1]);
      if (onActionButtonChanged)
        onActionButtonChanged(ActionsButtonsEnum[1].id);
    }
  }, [closeAction]);

  useEffect(() => {
    if (leadActiveAction)
      onSelectChanged(LeadActionsButtonsEnum[leadActiveAction]);
  }, [leadActiveAction]);

  useEffect(() => {
    if (assignmentAction && !selectedAction) setActiveAction(ActionsButtonsEnum[11]);
  }, [assignmentAction, onActionButtonChanged]);
  
  console.log('activeAction' , activeAction)

  return (
    <div className={`actions-buttons-wrapper ${wrapperClasses}`}>
      <div className='d-inline-flex'>
        {(!withType || activeAction.id !== ActionsButtonsEnum[1].id) && (
          <PermissionsComponent
            permissionsList={permissionsList}
            permissionsId={selectPermissionsId}
            allowEmptyRoles
          >
            <Button
              disabled={isDisabled || (checkDisable && checkDisable(activeAction.id))}
              type='button'
              id={`${t(translationPath + activeAction.buttonLabel)}_ref`}
              onClick={() => onActionsButtonClicked(activeAction.id)}
              className={`btns theme-solid ${activeAction.classes}`}
            >
              <span>{t(translationPath + activeAction.buttonLabel)}</span>
            </Button>
          </PermissionsComponent>
        )}
        {withType && activeAction.id === ActionsButtonsEnum[1].id && (
          <PermissionsComponent
            permissionsList={permissionsList}
            permissionsId={addPermissionsId}
            allowEmptyRoles
          >
            <SelectComponet
              data={typeData}
              defaultValue={-1}
              emptyItem={{ value: -1, text: withText || 'add', isHiddenOnOpen: true }}
              valueInput='id'
              translationPath={translationPath}
              onSelectChanged={onFormTypeSelectChanged}
              wrapperClasses='bg-secondary c-white mx-2'
              themeClass='theme-action-buttons'
              idRef='AddActionsRef'
              keyValue='actionsbuttons'
              keyLoopBy='id'
              translationPathForData={translationPath}
              textInput='name'
            />
          </PermissionsComponent>
        )}
      </div>
      {onActionButtonChanged && (
        <div className='d-inline-flex'>
          <PermissionsComponent
            permissionsList={permissionsList}
            permissionsId={selectPermissionsId}
            allowEmptyRoles
          >
            {!closeAction && (enableImport || enableMerge || enableBulk || enableCloseLeads || enablereassignOwnerLeads || enablereassignSeekerLeads || enableCloneLeads || enableToSendToRotation || enableQualifyLead || enableMakeTransaction ) && (
              <SelectComponet
                data={actionData}
                value={(leadActiveAction || activeAction.id)}
                valueInput='id'
                defaultValue={ActionsButtonsEnum[1].id}
                onSelectChanged={(value) => {
                  onSelectChanged(LeadActionsButtonsEnum[value]);
                }}
                themeClass='theme-action-buttons'
                idRef='ActionsRef'
                keyValue='actionsbuttons'
                keyLoopBy='id'
                translationPathForData={translationPath}
                textInput='label'
              />
            )}
            {closeAction && (enableImport || enableMerge || enableBulk || enableCloseLeads || enablereassignOwnerLeads || enablereassignSeekerLeads || enableCloneLeads || enableToSendToRotation || enableAssign || enableUnAssign ) && (

              <SelectComponet
                data={actionData}
                value={ActionsButtonsEnum[1]}
                defaultValue={ActionsButtonsEnum[1]}
                onSelectChanged={onSelectChanged}
                themeClass='theme-action-buttons'
                idRef='AllActionsRef'
                menuClasses='action-select'
                keyValue='actionsbuttons'
                keyLoopBy='id'
                translationPathForData={translationPath}
                textInput='label'
              />
            )}
          </PermissionsComponent>
        </div>

      )}
      {/* {withCheckbox && (
        <div className='d-inline-flex'>
          <PermissionsComponent
            permissionsList={permissionsList}
            permissionsId={selectPermissionsId}
            allowEmptyRoles
          >
            <CheckboxesComponent
              idRef="reSSef"
              translationPathForData={translationPath}
              translationPath={translationPath}
              label="select-all"
              singleChecked={isChecked}
              onSelectedCheckboxClicked={(e) => {
                onSelectAllClicked();
                setIsChecked(!isChecked);

              }}
            />

          </PermissionsComponent>
        </div>

      )} */}
    </div>
  );
};
ActionsButtonsComponent.propTypes = {
  onActionButtonChanged: PropTypes.func,
  onFormTypeSelectChanged: PropTypes.func,
  onActionsButtonClicked: PropTypes.func,
  wrapperClasses: PropTypes.string,
  withType: PropTypes.bool,
  typeData: PropTypes.instanceOf(Array),
  isDisabled: PropTypes.bool,
  enableMerge: PropTypes.bool,
  enableBulk: PropTypes.bool,
  assign: PropTypes.bool,
  unAssign: PropTypes.bool,
  enableImport: PropTypes.bool,
  permissionsList: PropTypes.instanceOf(Array),
  addPermissionsId: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(String)]),
  selectPermissionsId: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(String)]),
  checkDisable: PropTypes.func,
  withText: PropTypes.string,
  assignmentAction: PropTypes.string,
  enableCloseLeads: PropTypes.bool,
  enableQualifyLead: PropTypes.bool,
  withCheckbox: PropTypes.bool,
  onSelectAllClicked: PropTypes.func,
  isSelectAllFromTable: PropTypes.bool,
  enablereassignOwnerLeads: PropTypes.bool,
  enablereassignSeekerLeads: PropTypes.bool,
  value: PropTypes.string,
  enableToSendToRotation: PropTypes.bool,

};

ActionsButtonsComponent.defaultProps = {
  onActionButtonChanged: undefined,
  onFormTypeSelectChanged: undefined,
  onActionsButtonClicked: undefined,
  permissionsList: undefined,
  addPermissionsId: undefined,
  selectPermissionsId: undefined,
  withType: false,
  wrapperClasses: '',
  typeData: [],
  isDisabled: false,
  enableMerge: false,
  enableBulk: false,
  assign: false,
  unAssign: false,
  enableImport: false,
  checkDisable: undefined,
  withText: '',
  assignmentAction: '',
  enableCloseLeads: false,
  enableQualifyLead: false,
  closeAction: false,
  withCheckbox: false,
  onSelectAllClicked: undefined,
  isSelectAllFromTable: false,
  enablereassignOwnerLeads: false,
  enablereassignSeekerLeads: false,
  value: '',
  enableToSendToRotation: false,
};

export { ActionsButtonsComponent };
