


import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { useLocation } from "react-router-dom";
import {ConvertJsonV2Component  ,  Spinner ,TabsComponent }  from  '../../../../../../Components' ; 
import {
  GetAllFormFieldTabsByFormId,
  GetInquiryDetailsByIdService,
  UpdateInquiryServices , 
  AssignInquiryToAgentServices , 
  UnAssignInquiryFromAgentServices , 
} from '../../../../../../Services';

import { InquiryTypeIdEnum   } from '../../../../../../Enums' ;  
import {

  bottomBoxComponentUpdate,
  FormErrorsHandler,
  formItemsBuilder,
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import { LookupsRules } from '../../../../../../Rule';

export const ZeroMatchingLeaseInformationComponent = ({
  viewType,
  parentTranslationPath,
  translationPath,
  isActiveToSave ,
  onSave,
  save,
   isDetailsDialog,
   isOpen,
  isOpenChanged,
  typeOf  , 
  activeInquiryData ,
  setReloadInquiryData  ,
  inquiryEmptyFields , 
}) => {

  const { t } = useTranslation(parentTranslationPath, 'Shared');
  const location = useLocation();
  const [activeItem, setActiveItem] = useState({
    id: null,
    formType :null 
  });

  const [assignToInquiry , setAssignToInquiry ] =  useState(null) ; 
   

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [formData, setFormData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const pathName = window.location.pathname
  .split('/home/')[1]
  .split('/view')[0]
  .split('/')[0]; 

   
  const [enumsInitFinished, setEnumsInitFinished] = useState(false);
  const [errors, setErrors] = useState([]);
  const [InquiryInitDetails, setInquiryInitDetails] = useState({});
  const [inquiryDetails, setInquiryDetails] = useState(null);
  const [formAndTabs, setFormAndTabs] = useState([]);
  const [inquiryDetailsObj , setInquiryDetailsObj] = useState(null) ; 


  const onTabChanged = (e, newTap) => {
    setEnumsInitFinished(false);
    setActiveTab(newTap);
  };
  const getAllFormFieldTabsByFormId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllFormFieldTabsByFormId({ formId: activeItem.formType });
    if (!(result && result.status && result.status !== 200)) setFormAndTabs(result[0] || []);
    else setFormAndTabs([]);
    setIsLoading(false);
  }, [activeItem]);
  
  const getInquiryDetails = useCallback(async () => {
    setIsLoading(true);
    const inquiryDetailsRes = await GetInquiryDetailsByIdService(activeItem.id);
    if (!(inquiryDetailsRes && inquiryDetailsRes.status && inquiryDetailsRes.status !== 200)) {
      const  convertInquiryToJson =  inquiryDetailsRes && JSON.parse(inquiryDetailsRes.inquiryJson) ; 
      const inquiry  = {... inquiryDetailsRes  ,  inquiryJson : convertInquiryToJson}
      setAssignToInquiry((convertInquiryToJson.inquiry && convertInquiryToJson.inquiry.assign_to && convertInquiryToJson.inquiry.assign_to.id)|| null ) ; 
      setInquiryInitDetails(JSON.parse(JSON.stringify(inquiry)));
      setInquiryDetails({...inquiryDetailsRes  });
    } else {
      setInquiryInitDetails({});
      setInquiryDetails({});
    }
    setIsLoading(false);
  }, [activeItem]);


  const dataHandler = useCallback(() => {
    if (formData.length === 0) setFormData(formItemsBuilder((inquiryDetails && inquiryDetails.inquiryJson &&  inquiryDetails.inquiryJson.inquiry), formAndTabs));
  }, [inquiryDetails, formAndTabs, formData.length]);

  const cancelHandler = () => {
    GlobalHistory.goBack();
  };


  const assignInquiryToAgent = useCallback(
    async (inquiryId , userId ) => 
    {
      const assignInquiryToUser = await AssignInquiryToAgentServices({inquiryId , userId});
      if ((assignInquiryToUser && assignInquiryToUser.status && assignInquiryToUser.status !== 200))
      showError(t(`${translationPath}AssignInquiryToAgentFailed`));
    },
    []
  );

  const  unAssignInquiryFromAgent = useCallback(
    async (inquiryId , userId ) => 
    {
      const unAssignInquiryToUser = await UnAssignInquiryFromAgentServices({ inquiryId , userId });
      if ((unAssignInquiryToUser && unAssignInquiryToUser.status && unAssignInquiryToUser.status !== 200))
      showError(t(`${translationPath}unAssignInquiryToAgentFailed`));
    },
    []
  );


  const saveHandler = async () => {
     setIsSubmitted(true);
    if (errors && errors.length > 0) {
      const firstErrorTapIndex = formData && formData.findIndex(
        (item) => item.findIndex((element) => element.field.id === errors[0].key) !== -1
      );
      if (firstErrorTapIndex !== -1) setActiveTab(firstErrorTapIndex);
      showError(errors[0].message);
      return;
    }

    let  body  = { inquiryJson : (inquiryDetailsObj && inquiryDetails.inquiryJson && JSON.stringify(inquiryDetailsObj.inquiryJson )) , 
                    inquiryTypeId : InquiryTypeIdEnum.ZeroMatchingLease.InquiryTypeId  , 
                    leadId : ( inquiryDetailsObj  && inquiryDetailsObj.inquiryJson &&  inquiryDetailsObj.inquiryJson.inquiry && 
                      inquiryDetailsObj.inquiryJson.inquiry.lead
                      &&   inquiryDetailsObj.inquiryJson.inquiry.lead.leadId )
                   
                 } ; 
     setIsLoading(true);
    const putResponse = await UpdateInquiryServices(  activeItem && activeItem.id , body) ;  
    if (!(putResponse && putResponse.status && putResponse.status !== 200)) {
       const  assignToId  =  
      ( inquiryDetailsObj &&  inquiryDetailsObj.inquiryJson && inquiryDetailsObj.inquiryJson.inquiry && inquiryDetailsObj.inquiryJson.inquiry.assign_to  &&  inquiryDetailsObj.inquiryJson.inquiry.assign_to.id) ;

      if(putResponse.inquiryId  &&  assignToId  &&  assignToInquiry  &&  assignToId !== assignToInquiry)
           assignInquiryToAgent(putResponse.inquiryId  ,  assignToId ) ; 
       else if( putResponse.inquiryId  &&  !assignToInquiry   && assignToId )  
          assignInquiryToAgent(putResponse.inquiryId  , assignToId ) ; 
      else if( putResponse.inquiryId  &&  assignToInquiry && !assignToId)
            unAssignInquiryFromAgent(putResponse.inquiryId  , assignToInquiry  ) ; 

      showSuccess(t(`${translationPath}edit-zero-matching-lease-successfully`));
      setIsLoading(false);
      setReloadInquiryData(putResponse);
      if (isOpenChanged) {
        isOpenChanged();
      }
    } else {
      setIsLoading(false);
      showError(t(`${translationPath}edit-zero-matching-lease-failed`));
    }
  };
  const onLoadingsChanged = (value, key) => {
    setLoadings((items) => {
      const itemIndex = items.findIndex((item) => item.key === key);
      if (value) {
        const addItem = {
          key,
          value,
        };
        if (itemIndex !== -1) items[itemIndex] = addItem;
        else items.push(addItem);
      } else if (itemIndex !== -1) items.splice(itemIndex, 1);
      return [...items];
    });
  };

  const onItemChanged = (item, index) => (newValue, itemIndex, itemKey, parentItemKey) => {
    setFormData((elements) => {
      if (parentItemKey) {
        if (itemIndex !== undefined)
          elements[activeTab][itemIndex][parentItemKey][itemKey] = newValue;
        else elements[activeTab][index][parentItemKey][itemKey] = newValue;
      } else if (itemIndex) elements[activeTab][itemIndex][itemKey] = newValue;
      else elements[activeTab][index][itemKey] = newValue;
      return [...elements];
    });
  };

  const onValueChanged = (item) => (newValue, itemIndex, id) => {
    setInquiryDetailsObj((items) => {
      if ((itemIndex || itemIndex === 0) && itemIndex !== -1) {
        if (id || (formData[activeTab][itemIndex] && formData[activeTab][itemIndex].field && formData[activeTab][itemIndex].field.id))
          items.inquiryJson.inquiry[id || formData[activeTab][itemIndex].field.id] = newValue;

      }
      else if (item && item.field && item.field.id) items.inquiryJson.inquiry[item.field.id] = newValue;
      return { ...items };
    });

  };

  const lookupInit = useCallback(() => {
    setIsLoading(true);
    const result = LookupsRules(formData[activeTab], ( inquiryDetailsObj && inquiryDetailsObj.inquiryJson && inquiryDetailsObj.inquiryJson.inquiry ), onLoadingsChanged);
    setFormData((items) => {
      items.splice(activeTab, 1, result);
      return [...items];
    });
    setIsLoading(false);
  }, [activeTab, inquiryDetailsObj , formData]);

  useEffect(() => {
    if (formData.length > 0 &&  inquiryDetailsObj)
      setErrors(FormErrorsHandler(formData, inquiryDetailsObj && inquiryDetailsObj.inquiryJson &&  inquiryDetailsObj.inquiryJson.inquiry));
  }, [inquiryDetailsObj, formData]);

  useEffect(() => {
    if (!enumsInitFinished && formData.length > 0) {
      setEnumsInitFinished(true);
      lookupInit();
    }
  }, [activeTab, enumsInitFinished, formData.length, lookupInit]);
  useEffect(() => {
    if ( inquiryDetailsObj && inquiryDetailsObj.inquiryJson && inquiryDetailsObj.inquiryJson.inquiry ) dataHandler();
  }, [inquiryDetailsObj, dataHandler]);
  useEffect(() => {
    if (formAndTabs.length > 0 && activeItem.id  )
    getInquiryDetails();
  }, [formAndTabs, getInquiryDetails]);
  useEffect(() => {
    if (activeItem && activeItem.formType) getAllFormFieldTabsByFormId();
  }, [activeItem, getAllFormFieldTabsByFormId]);

  useEffect(() => {
    if (isDetailsDialog) {
      const obj = JSON.parse(localStorage.getItem('current'));
      setActiveItem({
        id: obj.id,
      });
    }
    else {
      setActiveItem({
        id: +GetParams('id'),
        formType :+GetParams('formType'),
      });
    }
  }, [location]);

  
  useEffect(() => {
    if(inquiryDetails)
    { 
       const InqObj =  { ...inquiryDetails , inquiryJson : JSON.parse(inquiryDetails.inquiryJson)} ;
      setInquiryDetailsObj(InqObj)
    }
    
  }, [inquiryDetails]);


  useEffect(() => {
    if (!isOpen) {
      bottomBoxComponentUpdate(
        <div className='d-flex-v-center-h-end flex-wrap'>
          <ButtonBase className='btns theme-transparent mb-2' onClick={cancelHandler}>
            <span>{t('Shared:cancel')}</span>
          </ButtonBase>

            <ButtonBase 
              className='btns theme-solid mb-2'
              onClick={saveHandler}
              disabled={!(activeInquiryData && activeInquiryData.inquiryStatus === 'Open') }
              >
              <span>{t('Shared:save')}</span>
            </ButtonBase>
        </div>
      );

    }


  });

  return (
    <div className='contacts-information-wrapper childs-wrapper b-0'>
      <Spinner isActive={isLoading} isAbsolute />
      <TabsComponent
        data={formAndTabs}
        labelInput='tab'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-curved'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
      />
      <div className='tabs-content-wrapper'>
        {formData &&
          formData[activeTab] &&
          formData[activeTab]
            .filter(
              (item) =>
                viewType === 1 ||
                (viewType === 2 &&
                  ((inquiryEmptyFields && inquiryEmptyFields.inquiry && inquiryEmptyFields.inquiry[item.field.id] === null) ||
                   (inquiryEmptyFields && inquiryEmptyFields.inquiry && inquiryEmptyFields.inquiry[item.field.id] === undefined) ||
                   ( inquiryEmptyFields && inquiryEmptyFields.inquiry && inquiryEmptyFields.inquiry[item.field.id] === '')))
            )
            .map((item, index) => (
              <ConvertJsonV2Component
                key={`form${index + 1}-${activeTab}`}
                item={item}
                typeOf={typeOf}
                allItems={formData[activeTab]}
                allItemsValues={inquiryDetailsObj &&  inquiryDetailsObj.inquiryJson && inquiryDetailsObj.inquiryJson.inquiry}
                itemValue={inquiryDetailsObj &&  inquiryDetailsObj.inquiryJson  && inquiryDetailsObj.inquiryJson.inquiry[item.field.id]}
                isSubmitted={isSubmitted}
                onItemChanged={onItemChanged(item, index)}
                onValueChanged={onValueChanged(item, index)}
                helperText={
                  (errors.find((element) => element.key === item.field.id) &&
                    errors.find((element) => element.key === item.field.id).message) ||
                  ''
                }
                error={errors.findIndex((element) => element.key === item.field.id) !== -1}
                isLoading={
                  loadings.findIndex(
                    (element) => element.key === item.field.id && element.value
                  ) !== -1
                }
                onLoadingsChanged={onLoadingsChanged}
              />
            ))}
      </div>
    </div>
  );
};

ZeroMatchingLeaseInformationComponent.propTypes = {
  viewType: PropTypes.oneOf([1, 2]).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  save: PropTypes.bool,
  isDetailsDialog: PropTypes.bool,
  isActiveToSave: PropTypes.bool,
  isOpen: PropTypes.bool,
  isOpenChanged: PropTypes.func,
  onSave: PropTypes.func,

};

ZeroMatchingLeaseInformationComponent.defaultProps = {
  save: false,
  isDetailsDialog: false,
  isActiveToSave: false,
  isOpen: false,
  isOpenChanged: undefined,
  onSave: undefined,
};

