import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  TabsComponent,
  RadiosGroupComponent,
  Spinner,
  CompletedDataComponent,
  SelectComponet, CollapseComponent,
} from '../../../../Components';
import {
  ActionsEnum, UnitsOperationTypeEnum, UnitsStatusEnum
} from '../../../../Enums';
import {
  getIsAllowedPermission,
  GetParams,
  GlobalHistory,
  sideMenuComponentUpdate,
} from '../../../../Helper';
import {
  GetAllTemplatesByUnitId, GetSuitableTemplateIdForUnit, unitDetailsGet, UnitsAdvanceSearchTest
} from '../../../../Services';
import { UnitsVerticalTabsData } from '../../Common/OpenFileView/OpenFileUtilities/OpenFileData';
import { ContactsActionDialogsComponent } from '../../ContactsView/ContactsUtilities/ContactsActionDialogsComponent/ContactsActionDialogsComponent';
import { UnitMapper } from '../UnitSalesMapper';
import { CardDetailsComponent } from '../UnitsSalesUtilities';
import { NotFoundLayout } from '../../../../Layouts/NotFoundLayout/NotFoundLayout';
import { PermissionsComponent } from '../../../../Components/PermissionsComponent/PermissionsComponent';
import { LeadOwnerUnitSalePermissions } from '../../../../Permissions';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { UnitTemplateDialogPreviewDialog } from '../../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog';

const parentTranslationPath = 'UnitsProfileManagementView';
const translationPath = '';
export const UnitsSalesProfileManagementView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [displayDetailsToUserDependsOnPolicy, setDisplayDetailsToUserDependsOnPolicy] = useState(null);
  const location = useLocation();
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [unitData, setUnitData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [viewType, setViewType] = useState(1);
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [activeTemplateId, setActiveTemplateId] = useState(-1);
  const [activeTemplateName, setActiveTemplateName] = useState(null);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const [isOpenContactsActionDialog, setIsOpenContactsActionDialog] = useState(false);
  const [templateFile, setTemplateFile] = useState(null);
  const [dialogEnum, setDialogEnum] = useState(null);
  const [showPopover, setshowPopover] = useState(false);
  const [unitEmptyFields, setUnitEmptyFields] = useState(null);
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 9999,
  });

  const [filterBy, setFilterBy] = useState({
    formType: GetParams('formType'),
    id: +GetParams('id'),
  });
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  useEffect(() => {
    setActiveTab(GetParams('matching') === 'true' ? 14 : 0);
  }, []);

  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };
  useEffect(() => {
    setFilterBy({
      formType: GetParams('formType'),
      id: (GetParams('id') && +GetParams('id')) || null,
    });
  }, [location]);

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      event.stopPropagation();
      if (actionEnum === ActionsEnum.reportEdit.key) {
        GlobalHistory.push(
          `/home/lead-owner-units-sale/edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
        );
      } else if (actionEnum === ActionsEnum.folder.key) {
        GlobalHistory.push(
          `/home/lead-owner-units-sale/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
        );
      }
    },
    []
  );

  const getUnitById = useCallback(async (isForLog) => {
    setIsLoading(true);
    const res = await unitDetailsGet({ id: +filterBy.id, isForLog });

    if (!(res && res.status && res.status !== 200)) {
      const unitDatails = UnitMapper(res);
      localStorage.setItem('unitModelRelatedData', JSON.stringify(unitDatails));
      dispatch(ActiveItemActions.activeItemRequest(unitDatails));
      setUnitEmptyFields(UnitMapper(res));

      setUnitData(unitDatails);
    } else {
      setUnitData(null);
      setUnitEmptyFields(null);
    }
    setIsLoading(false);
  }, [dispatch, displayDetailsToUserDependsOnPolicy]);

  const reloadData = () => {
    getUnitById(true);
  };

  const getAllTemplatesByCategoriesId = useCallback(async () => {
    const res = await GetAllTemplatesByUnitId(filterBy.id, UnitsOperationTypeEnum.sale.key);
    if (!((res && res.data && res.data.ErrorId) || !res)) setTemplates(res || []);
    else setTemplates([]);
  }, [activeItem, filter]);

  useEffect(() => {
    if (unitData !== null) {
      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={unitData}
          from={2}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          parentTranslationPath='UnitsView'
          translationPath={translationPath}
          reloadData={() => getUnitById(true)}
        />
      );
      // sideMenuIsOpenUpdate(true);
    } else sideMenuComponentUpdate(null);
  }, [unitData, detailedCardSideActionClicked]);
  useEffect(() => {
    if (
      activeItem &&
      activeItem.unitStatusDetails &&
      activeItem.unitStatusDetails.key &&
      Object.values(UnitsStatusEnum).filter(
        (item) => item.key === activeItem.unitStatusDetails.key && (item.category || item.saleCategory)
      ).length > 0
    )
      getAllTemplatesByCategoriesId();
  }, [activeItem, getAllTemplatesByCategoriesId]);

  useEffect(() => {
    if (filterBy.id)
      getUnitById(true);
  }, [filterBy.id]);

  const GetSuitableTemplateIdForUnitApi = useCallback(async () => {
    const res = await GetSuitableTemplateIdForUnit(filterBy.id, 1, 10000, UnitsOperationTypeEnum.sale.key);
    if (!((res && res.data && res.data.ErrorId) || !res)) setTemplatesProposal(res && res.result || []);
    else setTemplatesProposal([]);
  }, [filterBy.id]);

  useEffect(() => {
    GetSuitableTemplateIdForUnitApi();
  }, [GetSuitableTemplateIdForUnitApi]);
  return (
    <div className='units-profile-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='d-flex-v-center-h-between flex-wrap'>
        <div className='d-inline-flex-v-center flex-wrap'>
          <div className='d-inline-flex-v-center'>
            <PermissionsComponent
              permissionsList={Object.values(LeadOwnerUnitSalePermissions)}
              permissionsId={LeadOwnerUnitSalePermissions.ViewUnitTemplates.permissionsId}
            >
              <SelectComponet
                data={templates}
                keyLoopBy='systemTemplateId'
                valueInput='systemTemplateId'
                textInput='systemTemplateName'
                value={activeTemplateId}
                keyValue='actionsbuttons'
                idRef='contactsActionsRef'
                onSelectChanged={(value) => {
                  if (
                    !getIsAllowedPermission(
                      Object.values(LeadOwnerUnitSalePermissions),
                      loginResponse,
                      LeadOwnerUnitSalePermissions.OpenUnitTemplates.permissionsId
                    )
                  )
                    return;
                  setActiveTemplateId(value);
                  if (value !== -1) {
                    const itemIndex = templates.findIndex((item) => item.systemTemplateId === value);
                    if (itemIndex !== -1) setActiveTemplateName(templates[itemIndex].systemTemplateName);
                    setIsOpenTemplateDialog(true);
                  }
                }}
                themeClass='theme-action-buttons'
                translationPath={translationPath}
                translationPathForData={translationPath}
                wrapperClasses='bg-secondary c-white mx-2'
                parentTranslationPath={parentTranslationPath}
                emptyItem={{ value: -1, text: 'template', isHiddenOnOpen: true }}
              />
              <SelectComponet
                data={templatesProposal}
                keyLoopBy='systemTemplateId'
                valueInput='systemTemplateId'
                textInput='systemTemplateName'
                value={activeTemplateId}
                keyValue='actionsbuttons'
                idRef='contactsActionsRef'
                onSelectChanged={(value) => {
                  if (
                    !getIsAllowedPermission(
                      Object.values(LeadOwnerUnitSalePermissions),
                      loginResponse,
                      LeadOwnerUnitSalePermissions.OpenUnitTemplates.permissionsId
                    )
                  )
                    return;
                  setActiveTemplateId(value);
                  if (value !== -1) {
                    const itemIndex = templates.findIndex((item) => item.systemTemplateId === value);
                    if (itemIndex !== -1) setActiveTemplateName(templates[itemIndex].systemTemplateName);
                    setIsOpenTemplateDialog(true);
                  }
                }}
                themeClass='theme-action-buttons'
                translationPath={translationPath}
                translationPathForData={translationPath}
                wrapperClasses='bg-secondary c-white mx-2'
                parentTranslationPath={parentTranslationPath}
                emptyItem={{ value: -1, text: 'templateProposal', isHiddenOnOpen: true }}
              />
            </PermissionsComponent>

            <ButtonBase
              disabled={
                activeItem &&
                activeItem.unitStatusDetails &&
                activeItem.unitStatusDetails.value &&
                (activeItem.unitStatusDetails.value.toLowerCase() === 'draft' ||
                  activeItem.unitStatusDetails.value.toLowerCase() === 'available')
              }
              onClick={() =>
                GlobalHistory.push(`/home/lead-owner-units-sale/unit-profile-reservation?id=${activeItem.id}`)}
              className={`body-status btns ${(activeItem.unitStatusDetails && activeItem.unitStatusDetails.classes) ||
                (activeItem.unitStatus) && 'bg-waiting' ||
                'bg-warning'
                } bg-secondary-light px-4`}
            >
              {(activeItem.unitStatusDetails &&
                activeItem.unitStatusDetails.value &&
                t(`${translationPath}${activeItem.unitStatusDetails.value}`)) ||
                (t(`Shared:${activeItem.unitStatus}`)) ||
                'N/A'}
            </ButtonBase>
          </div>
          {activeTab === 0 && (
            <div className='d-inline-flex'>
              <RadiosGroupComponent
                idRef='viewDataRef'
                data={[
                  {
                    key: 1,
                    value: 'all-data',
                  },
                  {
                    key: 2,
                    value: 'missing-data',
                  },
                ]}
                value={viewType}
                labelValue='view'
                labelInput='value'
                valueInput='key'
                themeClass='theme-line'
                translationPath={translationPath}
                translationPathForData={translationPath}
                parentTranslationPath={parentTranslationPath}
                onSelectedRadioChanged={onViewTypeChangedHandler}
              />
            </div>
          )}

        </div>
        <div className='d-inline-flex'>
          <PermissionsComponent
            permissionsList={Object.values(LeadOwnerUnitSalePermissions)}
            permissionsId={LeadOwnerUnitSalePermissions.ShareUnit.permissionsId}
          >
            <ButtonBase className='btns c-black-light' onClick={() => { setshowPopover(true); }}>
              <span className='mdi mdi-share-outline px-1' />
              <span className='px-1'>{t(`${translationPath}share`)}</span>
              <CollapseComponent
                onClickOutside={() => setshowPopover(false)}
                isOpen={showPopover}
                classes='popover-unit-share'
                top={30}
                component={(
                  <div className='unit-model-items-wrapper'>
                    <ButtonBase
                      key='sms'
                      className='btns theme-transparent unit-model-item'
                      onClick={() => { setshowPopover(false); setDialogEnum('smsSolid'); }}
                    >
                      <span>sms</span>
                    </ButtonBase>
                    <ButtonBase
                      key='email'
                      className='btns theme-transparent unit-model-item'
                      onClick={() => { setshowPopover(false); setDialogEnum('emailSolid'); }}
                    >
                      <span>email</span>
                    </ButtonBase>

                    <ButtonBase
                      key='email'
                      className='btns theme-transparent unit-model-item'
                      onClick={() => {
                        setshowPopover(false); setDialogEnum('whatsappSolid');
                      }}
                    >
                      <span>Whatsapp</span>
                    </ButtonBase>

                  </div>
                )}
              />
            </ButtonBase>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(LeadOwnerUnitSalePermissions)}
            permissionsId={LeadOwnerUnitSalePermissions.ViewUnitHistory.permissionsId}
          >
            <CompletedDataComponent
              typeId='leadOwnerUnitSale'
              operationType='sale'
              completedData={activeItem && activeItem.progress}
              formType='sale'
              activeItem={activeItem}
            />
          </PermissionsComponent>
        </div>
      </div>

      <TabsComponent
        data={UnitsVerticalTabsData.unitSaleLeadOwner}
        labelInput='label'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-solid'
        dynamicComponentProps={{
          propertyId: unitData && unitData.property_name.id,
          activeItem,
          viewType,
          unitId: filterBy.id,
          parentTranslationPath,
          translationPath,
          unitType: 'leadOwnerUnitSale',
          unitEmptyFields,
          reloadData,
        }}
        currentTab={activeTab}
        onTabChanged={onTabChanged}
      />

      <div className='tabs-content-wrapper' />
      {isOpenTemplateDialog && (
        <UnitTemplateDialogPreviewDialog
          activeItem={activeTemplateId}
          isOpen={isOpenTemplateDialog}
          unitID={+GetParams('id')}
          maintitleText={activeTemplateName}
          isOpenChanged={() => {
            setIsOpenTemplateDialog(false);
            setActiveTemplateId(-1);
          }}
          templateName={activeTemplateName}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenContactsActionDialog && templateFile && (
        <ContactsActionDialogsComponent
          isOpen={isOpenContactsActionDialog}
          unitTemplateFile={templateFile}
          isOpenChanged={() => {
            setIsOpenContactsActionDialog(false);
            setTemplateFile(null);
          }}
          showlink
          actionEnum='emailSolid'
          item={null}
          translationPath=''
          parentTranslationPath='ContactsView'
        />
      )}
      <ContactsActionDialogsComponent
        isOpenChanged={() => setDialogEnum(null)}
        isOpen={dialogEnum !== null}
        translationPath=''
        parentTranslationPath='ContactsView'
        actionEnum={dialogEnum}
        unitItem={activeItem}
        showlink
      />

    </div>
  );
};
