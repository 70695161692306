

import React , { useState } from 'react';
import {ActivityFilesUploader  } from '../../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader' ;


export const UnitsModelsImageSectionComponent = ({ setState , state , translationPath , parentTranslationPath }) => {

  return (
    <>
      <div className='dialog-content-item w-100 mt-2'>
        <ActivityFilesUploader
              state={state}
              setState={setState}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              canSelectOneDocument={true}
              accept={'image/*'}
           />
    
      </div>
     </>
  );
};
