import React, { useEffect, useState } from 'react';
import './TaskDetailsView.scss';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../Hooks';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { sideMenuComponentUpdate, sideMenuIsOpenUpdate } from '../../../../Helper';
import { TaskDetailsTab, CampaignDetailsTab, UploadedTaskDetailsTab } from './TaskDetailsTabs';

export const TaskDetailsView = () => {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'campaign-task.';
  const { t } = useTranslation(parentTranslationPath);

  useTitle(t(`${translationPath}task-details`));

  const useStyles = makeStyles((theme) => ({
    tabsRoot: {
      height: '40px',
      minHeight: '40px',
      textTransform: 'capitalize',
      marginBottom: '1.25rem',
    },
    indicator: {
      backgroundColor: '#498a8b',
    },
    selectedTab: {
      color: '#498a8b !important',
      fontWeight: 600,
    },
    tabRoot: {
      minWidth: 'fit-content',
      padding: 0,
      marginRight: 16,
      fontSize: '12px',
      textTransform: 'capitalize',
    },
  }));

  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabClick = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  }, []);

  return (
    <div className='marketing-module-view TaskDetailsView px-4 py-3'>
      <p className='b-600 fz-30'>{t(`${translationPath}Task`)}</p>
      <div className='mx-2'>
        <Tabs
          value={tabIndex}
          onChange={handleTabClick}
          aria-label='icon label tabs'
          textColor='primary'
          classes={{
            root: classes.tabsRoot,
            indicator: classes.indicator,
          }}>
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
            }}
            label={t(`${translationPath}Task`)}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
            }}
            label={t(`${translationPath}Campaign-details`)}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
            }}
            label={t(`${translationPath}Task-deliverable`)}
          />
        </Tabs>

        {tabIndex === 0 && (
          <TaskDetailsTab
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        {tabIndex === 1 && (
          <CampaignDetailsTab
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        {tabIndex === 2 && (
          <UploadedTaskDetailsTab
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
      </div>
    </div>
  );
};
