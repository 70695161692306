import React, { useCallback, useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { GetBranchInfoById, MicrosoftToken } from '../../../../Services/DashboardService';
import { PowerBiEnum } from '../../../../Enums';
import { GlobalTranslate, showError } from '../../../../Helper';
import { GetApplicationUserById } from '../../../../Services/userServices';
import { useTitle } from '../../../../Hooks';
import { SpinnerDashboards } from '../SpinnerDashboards/SpinnerDashboards';

export const RotationSchemesBranch = () => {
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [brancid, setBranchid] = useState(null);
  const [branchCityid, setBranchCityid] = useState(null);
  const [ShowReport, setShowReport] = useState(false);

  useTitle(GlobalTranslate.t('Dashboard:RotationSchemesBranch-dashboard'));
  const userid = JSON.parse(localStorage.getItem('session')).userId;

  
  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport('');
      showError('Contact Your Bi Admin For Help');
    }
  };

  const getUserById = useCallback(async () => {
    const res = await GetApplicationUserById(userid);
    if (!(res && res.status && res.status !== 200))
      setBranchid(res.branchId); else setBranchid(null);
  }, [userid]);

  const getBranchInfoById = useCallback(
    async () => {
      const res = await GetBranchInfoById(brancid);
      if (!(res && res.status && res.status !== 200))
      setBranchCityid(res.branchCityId); else setBranchCityid(null);
     
    }, [brancid]);

  const Filter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'Branches',
      column: 'BranchId',
    },
    operator: 'In',
    values: [brancid],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  useEffect(() => {
    Microsoft(false);
    getUserById(userid);
    getBranchInfoById(brancid)
  }, [brancid ,branchCityid]);


  return (
    <div className='dashboardMain-PowerBIEmbed'>

      {render && brancid !== null && (
        <div className='dashboardMain'>
                                  <SpinnerDashboards isActive={!ShowReport} isAbsolute />

          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: PowerBiEnum.RotationScheme.reportid,
              embedUrl: PowerBiEnum.RotationScheme.url,
              accessToken: report,
              pageView: 'fitToWidth',
              filters: [Filter],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  () => {
                    setShowReport(true);

                    console.log('Report loaded');
                  },
                ],
                [
                  'rendered',
                  () => {
                    console.log('Report rendered');
                  },
                ],
                [
                  'error',
                  (event, embed) => {
                    const error = event.detail;

                    if (
                      error.detailedMessage === 'Get report failed' ||
                      error.detailedMessage ===
                      'Access token has expired, resubmit with a new access token' ||
                      error.detailedMessage ===
                      'Fail to initialize - Could not resolve cluster'
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport
                ? "report-style-class-Show"
                : "report-style-class-hide"
            }            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      )}
    </div>
  );
};