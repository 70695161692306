import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import copyImage from '../../../../assets/images/copy-01@2x.png';
import editIcon from '../../../../assets/images/icons/okok.png';
import CancelInquiry from '../../../../assets/images/icons/x-close.png';
import DeleteInquiry from '../../../../assets/images/icons/trash-01.png';
import { returnPropsByPermissions3 } from '../../../../Helper';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { GlobalHistory, showinfo } from '../../../../Helper';
import { PermissionsComponent, PopoverComponent } from '../../../../Components';
import { InquiryTypeIdEnum } from '../../../../Enums';
import { ZeroMatchingSalePermissions, ZeroMatchingLeasePermissions, ListingShortagePermissions } from '../../../../Permissions';
import { DeleteInquiryDialog } from '../DeleteInquiry/DeleteInquiryDialog';
import { CancelInquiryDialog } from '../CancelInquiry/CancelInquiryDialog';
import dotsVertical from '../../../../assets/images/dots-vertical.svg';
import dotsVertical1 from '../../../../assets/images/icons/dots-vertical1.svg';
import SaleZeroMatching from '../../../../assets/images/icons/InquiriesIcons/SaleZeroMatching.svg';
import LeaseZeroMatching from '../../../../assets/images/icons/InquiriesIcons/LeaseZeroMatching.svg';
import SaleListingShortage from '../../../../assets/images/icons/InquiriesIcons/SaleListingShortage.svg';
import LeaseListingShortage from '../../../../assets/images/icons/InquiriesIcons/LeaseListingShortage.svg';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';




export const InquiresCardComponent = ({
  data,
  translationPath,
  parentTranslationPath,
  setFilter,
  isLoading,
  inquiryType,
}) => {

  const { t } = useTranslation(parentTranslationPath);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [itemIndex, setItemIndex] = useState(-1);

  const [isOpenCancelInquiryDialog, setIsOpenCancelInquiryDialog] = useState(false);
  const dispatch = useDispatch();
  const [inquiryTypeIdValues, setInquiryTypeIdValues] = useState(Object.values(InquiryTypeIdEnum));

  const [selectedPopoverAction, setSelectedPopoverAction] = useState(null);

  const [permission, setPrmission] = useState({
    permissionsList: [],
    permissionEditId: null,
    permissionDeleteId: null,
    permissionCancelId: null,
  });

  const [activeItem, setActiveItem] = useState(null);



  const handlePopoverOpen = useCallback((event, item, index) => {
    setItemIndex(index);
    setActiveItem(item);
    dispatch(ActiveItemActions.activeItemRequest(item));
    event.stopPropagation();
    event.preventDefault();
    setSelectedPopoverAction(event.currentTarget);
  }, []);

  const handlePopoverClose = () => {
    setSelectedPopoverAction(null);
    setItemIndex(-1);
  }

  const displayWithPermissions = () => {
    if (inquiryType === 'Zero Matching Sale')
      return returnPropsByPermissions3(ZeroMatchingSalePermissions.EditInquiry.permissionsId,
        ZeroMatchingSalePermissions.DeleteInquiry.permissionsId, ZeroMatchingSalePermissions.CancelInquiry.permissionsId);

    else if (inquiryType === 'Zero Matching Lease')
      return returnPropsByPermissions3(ZeroMatchingLeasePermissions.EditInquiry.permissionsId,
        ZeroMatchingLeasePermissions.DeleteInquiry.permissionsId, ZeroMatchingLeasePermissions.CancelInquiry.permissionsId);

    else if (inquiryType === 'Listing Shortage')
      return returnPropsByPermissions3(ListingShortagePermissions.EditInquiry.permissionsId,
        ListingShortagePermissions.DeleteInquiry.permissionsId, ListingShortagePermissions.CancelInquiry.permissionsId);
  };

  const editClickHandler = () => {
    if (inquiryType === 'Zero Matching Sale')
      GlobalHistory.push(`/home/zero-matching-sale/zero-matching-sale-profile-edit?formType=${44}&id=${activeItem && activeItem.inquiryId}&activeFormType=${1}`);

    else if (inquiryType === 'Zero Matching Lease')
      GlobalHistory.push(`/home/zero-matching-lease/zero-matching-lease-profile-edit?formType=${47}&id=${activeItem && activeItem.inquiryId}&activeFormType=${2}`);


    else if (inquiryType === 'Listing Shortage') {
      const inquiryTypeId = activeItem.inquiryTypeId === 3 ? 1 : 2;
      GlobalHistory.push(`/home/listing-shortage/listing-shortage-profile-edit?formType=${45}&id=${activeItem && activeItem.inquiryId}&activeFormType=${inquiryTypeId}`);
    }
    localStorage.setItem('inquiryStatus', JSON.stringify(activeItem.inquiryStatus));
    handlePopoverClose();

  };

  const deleteClickHandler = () => {
    handlePopoverClose();
    setIsOpenDeleteDialog(true);
  };

  const cardOnClick = (item) => {
    setActiveItem(item);
  };


  const cancelClickHandler = () => {
    handlePopoverClose();
    setIsOpenCancelInquiryDialog(true);

  }

  const getInquiryTypeImage = (inquiryTypeId) => {
    if (inquiryTypeId === InquiryTypeIdEnum.ZeroMatchingSale.InquiryTypeId)
      return SaleZeroMatching;

    else if (inquiryTypeId === InquiryTypeIdEnum.ZeroMatchingLease.InquiryTypeId)
      return LeaseZeroMatching;

    else if (inquiryTypeId === InquiryTypeIdEnum.ListingShortageSale.InquiryTypeId)
      return SaleListingShortage;

    else if (inquiryTypeId === InquiryTypeIdEnum.ListingShortageLease.InquiryTypeId)
      return LeaseListingShortage;

    return SaleZeroMatching;

  }

  useEffect(() => {
    if (inquiryType && inquiryType === 'Zero Matching Sale') {
      setPrmission({
        permissionsList: (Object.values(ZeroMatchingSalePermissions)),
        permissionEditId: ZeroMatchingSalePermissions.EditInquiry.permissionsId,
        permissionDeleteId: ZeroMatchingSalePermissions.DeleteInquiry.permissionsId,
        permissionCancelId: ZeroMatchingSalePermissions.CancelInquiry.permissionsId,
      });
    } else if (inquiryType && inquiryType === 'Zero Matching Lease') {
      setPrmission({
        permissionsList: (Object.values(ZeroMatchingLeasePermissions)),
        permissionEditId: ZeroMatchingLeasePermissions.EditInquiry.permissionsId,
        permissionDeleteId: ZeroMatchingLeasePermissions.DeleteInquiry.permissionsId,
        permissionCancelId: ZeroMatchingLeasePermissions.CancelInquiry.permissionsId,
      });
    } else if (inquiryType && inquiryType === 'Listing Shortage') {
      setPrmission({
        permissionsList: (Object.values(ListingShortagePermissions)),
        permissionEditId: ListingShortagePermissions.EditInquiry.permissionsId,
        permissionDeleteId: ListingShortagePermissions.DeleteInquiry.permissionsId,
        permissionCancelId: ListingShortagePermissions.CancelInquiry.permissionsId,
      });
    }
  }, [inquiryType]);



  return (
    <div className='Inquiries-card-component-wrapper'>
      {data &&
        data.map((item, index) => (
          <div className='Inquiries-card-wrapper' key={`inquiryCardRef${index + 1}`} onClick={() => {
            if (selectedPopoverAction == null) cardOnClick(item)
          }
          }>

            <div
              className={`cards-wrapper  childs-wrapper ${inquiryType === 'Listing Shortage' ? 'Listing-Shortage' : ''} `}
            >

              <div className='cards-header'>

                <span className='information-icon' style={{ position: 'absolute' }}
                  onClick={(event) => handlePopoverOpen(event, item, index)}>
                  {
                    selectedPopoverAction && itemIndex === index && (
                      <img key={index} src={dotsVertical} />
                    )
                  }
                  {
                    itemIndex !== index && (
                      <img src={dotsVertical1} key={index} />
                    )
                  }

                </span>

                <div className='header-part1'>
                  {/* <div className='inquiries-id-part'>
                  {t(`${translationPath}inquiryId`)} 
                  
                                   
                    {' '} 
                    {' '} 
                    {' '} 
                    {' '}
                    {' '}
                    {' '} 
                    : 
                    {item.inquiryId} 
                </div> */}
                  <div className='headerItem1'>
                    <div className={`inquiry-icon-part ${inquiryType === 'Listing Shortage' ? 'Listing-Shortage' : ''} `}
                    >

                      <img src={item.inquiryTypeId && getInquiryTypeImage(item.inquiryTypeId)} key={index} />

                    </div>


                  </div>

                </div>
              </div>
              <div className='card-header2 mt-2'>
                {t(`${translationPath}inquiryId`)}
                {' '}
                {' '}
                {' '}
                {' '}
                {' '}
                {' '}
                {' '}
                {' '}
                {' '}
                {' '}
                {' '}
                {'  '}

                :
                &nbsp;
                {item.inquiryId}
              </div>

              <div className={`cards-body ${inquiryType === 'Listing Shortage' ? 'listing-shortage-body' : ''}`}  >

                <div className='body-item'>
                  <span className='mb-2'>
                    {t(`${translationPath}inquiry-type-card`)}
                  </span>
                  <span
                    className={`item-value ${inquiryType === 'Listing Shortage' ? 'Listing-Shortage' : ''}`}>
                    {item.inquiryTypeId && inquiryTypeIdValues.map((i) => {
                      if (item.inquiryTypeId === i.InquiryTypeId)
                        return i.type
                    })}
                  </span>

                </div>
                <div className='body-item'>
                  <span className='mb-2'>
                    {t(`${translationPath}status`)}
                  </span>
                  <span
                    className={`status${item.inquiryStatus === 'Canceled' ? ' cancel' : (item.inquiryStatus === 'Open' ? ' open' : '')}`}>
                    {item.inquiryStatus} </span>

                </div>
                {
                  item.leadId && (
                    <div className='body-item'>
                      <span className='mb-2'>
                        {t(`${translationPath}leadId`)}
                      </span>
                      <span className='item-value'>
                        <span className='leadId-part'>
                          {(
                            item && item.leadId && (
                              <CopyToClipboardComponents
                                data={item.leadId}
                                childrenData={item.leadId}
                                CustomizationClassName='block-container low-space'
                              />
                            )) ||
                            'N/A'}



                          <span>

                          </span>
                        </span>
                      </span>

                    </div>

                  )
                }

                {
                  displayWithPermissions() &&
                  (
                    <PopoverComponent
                      idRef={`productRelease${item?.inquiryId}Ref`}
                      attachedWith={selectedPopoverAction}
                      popoverClasses='release-note-popover'
                      handleClose={() => handlePopoverClose()}
                      component={
                        <>
                          <PermissionsComponent
                            permissionsList={permission.permissionsList}
                            permissionsId={permission.permissionEditId}>

                            <div className='popover-item' onClick={() => editClickHandler()}>
                              <img src={editIcon} />
                              <span>  {t(`${translationPath}edit`)}</span>
                            </div>
                          </PermissionsComponent>

                          <PermissionsComponent
                            permissionsList={permission.permissionsList}
                            permissionsId={permission.DeleteInquiry}>

                            <div className='popover-item' onClick={() => deleteClickHandler()}>
                              <img src={DeleteInquiry} />
                              <span> {t(`${translationPath}delete`)}  </span>
                            </div>

                          </PermissionsComponent>
                          {
                            activeItem && activeItem.inquiryStatus &&
                            activeItem.inquiryStatus !== 'Canceled'
                            && (
                              <PermissionsComponent
                                permissionsList={permission.permissionsList}
                                permissionsId={permission.CancelInquiry}>
                                <div className='popover-item' onClick={() => cancelClickHandler()}>
                                  <img src={CancelInquiry} />
                                  <span>  {t(`${translationPath}cancel`)} </span>
                                </div>
                              </PermissionsComponent>
                            )
                          }
                        </>
                      }
                    />)}
              </div>
            </div>
          </div>

        ))}
      {isOpenDeleteDialog && (
        <DeleteInquiryDialog
          open={isOpenDeleteDialog}
          close={() => {
            setIsOpenDeleteDialog(false);
            setActiveItem(null);

          }}
          onSave={() => {
            setIsOpenDeleteDialog(false);
            setFilter();
            setActiveItem(null);

          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )}

      {isOpenCancelInquiryDialog && (
        <CancelInquiryDialog
          open={isOpenCancelInquiryDialog}
          close={() => {
            setIsOpenCancelInquiryDialog(false);
            setActiveItem(null);
          }}
          onSave={() => {
            setIsOpenCancelInquiryDialog(false);
            setFilter();
            setActiveItem(null);

          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )}
    </div>)

}

