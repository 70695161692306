import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, Button } from '@material-ui/core';
import { AgentsTabelView } from './AgentsTabelView/AgentsTabelView';
import { Inputs, PermissionsComponent, SelectComponet } from '../../../Components';
import { useTitle } from '../../../Hooks';
import { AgentsPermissions } from '../../../Permissions';

const parentTranslationPath = 'Agents';
const translationPath = '';

export const AgentsView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [searchedItem, setSearchedItem] = useState('');
  const [searchedemailItem, setSearchedemailItem] = useState('');
  const [searchedbranchNameItem, setSearchedbranchNameItem] = useState('');
  const [searchedRotationSchemaItem, setSearchedRotationSchemaItem] = useState('');
  const searchTimer = useRef(null);
  const [orderBy, setOrderBy] = useState(null);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: null,
    orderBy: null,
  });
  const [actionType, setActionType] = useState({ id: null, value: null});
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);
  const [isStatusBulkUpdateDialogOpen, setIsStatusBulkUpdateDialogOpen] = useState(false);

  useTitle(t(`${translationPath}Agents`));

  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    filterBy: null,
    orderBy: null,
    agentName: '',
    email: '',
    isInRotation: null,
    branchName: '',

  });

  const searchHandler = () => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({
        ...item, agentName: searchedItem,
      }));
    }, 700);
  };

  const searchemailHandler = () => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({
        ...item, email: searchedemailItem,
      }));
    }, 700);
  };

  const searchbranchNameHandler = () => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({
        ...item, branchName: searchedbranchNameItem,
      }));
    }, 700);
  };

  const searchRotationSchemaHandler = () => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({
        ...item, schemaName: searchedRotationSchemaItem || null,
      }));
    }, 700);
  };

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }

    setFilter((item) => ({
      ...item,
      orderBy: selectedOrderBy.orderBy,
      filterBy: selectedOrderBy.filterBy

    }));
  };

  const actionTypeChanged = (newValue) => {
    setActionType((item) => ({ ...item, id: newValue }));
  }

  const onActionsButtonClicked = (newValue) => {

    if (newValue?.id === 'bulk-status-update') {
      setIsStatusBulkUpdateDialogOpen(true);
    }
    setActionType((item) => ({ ...item, id: null }));
  };    

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );

  const ChangerotaionstateActions = (value) => {
    setFilter((item) => ({ ...item, isInRotation: value }));
  };

  return (
    <div className='Agents-wrapper view-wrapper'>
      <div className='w-100 px-2'>
        <PermissionsComponent
          permissionsList={Object.values(AgentsPermissions)}
          permissionsId={AgentsPermissions.ViewAllAgents.permissionsId}
        >
          <div className='d-flex-v-center-h-between'>
            <div className='w-50'>
              <div className='d-flex-v-center w-50 mx-3'>
                {!actionType.id && <span className='fw-simi-bold'>
                  {t(`${translationPath}agent-actions`)}
                  :
                </span>}
                {actionType.id && <Button
                  disabled={!selectedAgentIds || (selectedAgentIds && selectedAgentIds.length === 0)}
                  type='button'
                  onClick={() => onActionsButtonClicked(actionType)}
                  className="btns theme-solid action-button"
                >
                  <span>{t(`${translationPath}${actionType.id}`)}</span>
                </Button>}
                <SelectComponet
                  idRef='filterByRefbulk-status-update"'
                  data={[
                    { id: "bulk-status-update", value: "bulk-status-update" },
                  ]}
                  valueInput='id'
                  textInput='value'
                  value={actionType.id}
                  onSelectChanged={actionTypeChanged}
                  wrapperClasses=' w-50 mx-2'
                  emptyItem={{ value: null, text: 'select', isDisabled: false }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>

            </div>
            <div className='w-50'>
              <div className='px-2'>
                <Inputs
                  value={searchedItem}
                  onKeyUp={searchHandler}
                  onInputChanged={(e) => setSearchedItem(e.target.value)}
                  idRef='AgentsRef'
                  label={t(`${translationPath}search-in-Agents`)}
                  inputPlaceholder={t(`${translationPath}search-Agents`)}
                  beforeIconClasses='mdi mdi-account-search-outline mdi-24px c-gray-primary'
                />
              </div>
              <div className='d-flex ml-4'>
                <div className='w-50 mr-1'>
                  <Inputs
                    value={searchedemailItem}
                    onKeyUp={searchemailHandler}
                    onInputChanged={(e) => setSearchedemailItem(e.target.value)}
                    idRef='AsssstsemailRef'
                    label={t(`${translationPath}Agents-email`)}
                    inputPlaceholder={t(`${translationPath}Agents-email`)}
                  />
                </div>
                <div className='w-50 mr-1'>
                  <Inputs
                    value={searchedbranchNameItem}
                    onKeyUp={searchbranchNameHandler}
                    onInputChanged={(e) => setSearchedbranchNameItem(e.target.value)}
                    idRef='AsearchedbranchNameItemRef'
                    label={t(`${translationPath}branchName`)}
                    inputPlaceholder={t(`${translationPath}branchName`)}
                  />
                </div>
                <div className='w-50 mr-1'>
                  <Inputs
                    value={searchedRotationSchemaItem}
                    onKeyUp={searchRotationSchemaHandler}
                    onInputChanged={(e) => setSearchedRotationSchemaItem(e.target.value)}
                    idRef='RotationSchemaRef'
                    label={t(`${translationPath}RotationSchema`)}
                    inputPlaceholder={t(`${translationPath}RotationSchema`)}
                  />
                </div>
                <div className='mr-2'>
                  <SelectComponet
                    idRef='ONfilterByRefOff'
                    data={[
                      { id: true, filterBy: 'ON' },
                      { id: false, filterBy: 'Off' },
                    ]}
                    wrapperClasses='mb-3'
                    isRequired
                    value={filter.isInRotation}
                    onSelectChanged={ChangerotaionstateActions}
                    valueInput='id'
                    textInput='filterBy'
                    emptyItem={{
                      value: null,
                      text: 'all-Rotation',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex px-2'>
            <span className='mx-2 mt-1'>{t(`${translationPath}select`)}</span>
            <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
            <span className='px-2 d-flex'>
              <span className='texts-large mt-1'>
                {t(`${translationPath}order-by`)}
                :
              </span>
              <div className='px-2'>
                <SelectComponet
                  idRef='select-filter-by-filterByRef'
                  data={[
                    { id: 'CreatedOn', filterBy: 'created-on' },
                    { id: 'UpdateOn', filterBy: 'last-updated' },
                  ]}
                  wrapperClasses='mb-3'
                  isRequired
                  value={selectedOrderBy.filterBy}
                  onSelectChanged={filterByChanged}
                  valueInput='id'
                  textInput='filterBy'
                  emptyItem={{
                    value: null,
                    text: 'select-filter-by',
                    isDisabled: false,
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className='px-2'>
                <SelectComponet
                  idRef='orderByRef'
                  data={[
                    { id: 1, orderBy: 'ascending' },
                    { id: 2, orderBy: 'descending' },
                  ]}
                  value={selectedOrderBy.orderBy}
                  onSelectChanged={orderByChanged}
                  emptyItem={{ value: null, text: 'select-sort-by', isDisabled: false }}
                  wrapperClasses='mb-3'
                  isRequired
                  valueInput='id'
                  textInput='orderBy'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className='mt-1'>
                <ButtonBase
                  className='btns theme-solid'
                  onClick={orderBySubmitted}
                  id='action_apply'
                  disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                >
                  <span>{t(`${translationPath}apply`)}</span>
                </ButtonBase>
              </div>
            </span>

          </div>
          <AgentsTabelView
            filter={filter}
            setFilter={setFilter}
            actionType={actionType}
            isStatusBulkUpdateDialogOpen={isStatusBulkUpdateDialogOpen}
            setIsStatusBulkUpdateDialogOpen={setIsStatusBulkUpdateDialogOpen}
            selectedAgentIds={selectedAgentIds}
            setSelectedAgentIds={setSelectedAgentIds}
            setSearchedItem={setSearchedItem}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </PermissionsComponent>
      </div>
    </div>
  );
};
