import React from 'react';
import { ActivitiesRelatedToActivitiesTabelType } from './ActivitiesRelatedToActivitiesTabelType.Enum';

export const ActivitiesTabelHeaderData = [
  {
    id: 1,
    label: 'activity-name',
    input: 'activityTypeName',
    isSortable: true,
    isDefaultFilterColumn: true,
  },
  {
    id: 2,
    label: 'category',
    input: 'categoryName',
    isSortable: true,
    isDefaultFilterColumn: true,
  },
  {
    id: 3,
    label: 'RelatedtoUnit',
    isDefaultFilterColumn: true,
    component: (item) => (
      <>
        {((item && item.relatedTo.findIndex(((data) => (+data.relatedToId === ActivitiesRelatedToActivitiesTabelType.unit.key &&
          <span className='mdi mdi-check-circle-outline  check-true' />)
        )) === -1 && (<span className='mdi mdi-alpha-x-circle-outline check-false' />) || <span className='mdi mdi-check-circle-outline  check-true' />))}
      </>
    ),
  },
  {
    id: 4,
    label: 'RelatedtoLead',
    isDefaultFilterColumn: true,
    component: (item) => (
      <>
        {((item && item.relatedTo.findIndex(((data) => (+data.relatedToId === ActivitiesRelatedToActivitiesTabelType.lead.key &&
          <span className='mdi mdi-check-circle-outline  check-true' />)
        )) === -1 && (<span className='mdi mdi-alpha-x-circle-outline check-false' />) || <span className='mdi mdi-check-circle-outline  check-true' />))}
      </>
    ),
  },
  {
    id: 5,
    label: 'RelatedtoContact',
    isDefaultFilterColumn: true,
    component: (item) => (
      <>
        {((item && item.relatedTo.findIndex(((data) => (+data.relatedToId === ActivitiesRelatedToActivitiesTabelType.contact.key &&
          <span className='mdi mdi-check-circle-outline  check-true' />)
        )) === -1 && (<span className='mdi mdi-alpha-x-circle-outline check-false' />) || <span className='mdi mdi-check-circle-outline  check-true' />))}
      </>
    ),
  },
  {
    id: 6,
    label: 'RelatedtoWorkOrder',
    isDefaultFilterColumn: true,
    component: (item) => (
      <>
        {((item && item.relatedTo.findIndex(((data) => (+data.relatedToId === ActivitiesRelatedToActivitiesTabelType.workOrder.key &&
          <span className='mdi mdi-check-circle-outline  check-true' />)
        )) === -1 && (<span className='mdi mdi-alpha-x-circle-outline check-false' />) || <span className='mdi mdi-check-circle-outline  check-true' />))}
      </>
    ),
  },
  {
    id: 7,
    label: 'RelatedtoPortfolio',
    isDefaultFilterColumn: true,
    component: (item) => (
      <>
        {((item && item.relatedTo.findIndex(((data) => (+data.relatedToId === ActivitiesRelatedToActivitiesTabelType.portfolio.key &&
          <span className='mdi mdi-check-circle-outline  check-true' />)
        )) === -1 && (<span className='mdi mdi-alpha-x-circle-outline check-false' />) || <span className='mdi mdi-check-circle-outline  check-true' />))}
      </>
    ),
  },
  {
    id: 8,
    label: 'RelatedtoMaintenanceContract',
    isDefaultFilterColumn: true,
    component: (item) => (
      <>
        {((item && item.relatedTo.findIndex(((data) => (+data.relatedToId === ActivitiesRelatedToActivitiesTabelType.maintenanceContract.key &&
          <span className='mdi mdi-check-circle-outline  check-true' />)
        )) === -1 && (<span className='mdi mdi-alpha-x-circle-outline check-false' />) || <span className='mdi mdi-check-circle-outline  check-true' />))}
      </>
    ),
  },
  {
    id: 9,
    label: 'Expired-Period',
    isDefaultFilterColumn: true,
    input: 'expiredPeriod',
    isSortable: true
  },
  {
    id: 10,
    label: 'stage',
    input: 'leadStageName',
  },
  {
    id: 11,
    label: 'rating',
    input: 'activityRateName',
  },
  {
    id: 12,
    label: 'isForMobile',
    component: (item) => (
      <>
        {((item && item.isForMobile && (<span className='mdi mdi-check-circle-outline  check-true' />) || <span className='mdi mdi-alpha-x-circle-outline check-false' />))}
      </>
    )
  },
  {
    id: 13,
    label: 'withReminder',
    component: (item) => (
      <>
        {((item && item.withReminder && (<span className='mdi mdi-check-circle-outline  check-true' />) || <span className='mdi mdi-alpha-x-circle-outline check-false' />))}
      </>
    )
  },
  {
    id: 14,
    label: 'withDuration',
    component: (item) => (
      <>
        {((item && item.withDuration && (<span className='mdi mdi-check-circle-outline  check-true' />) || <span className='mdi mdi-alpha-x-circle-outline check-false' />))}
      </>
    )
  },
  {
    id: 15,
    label: 'withDateTime',
    isDefaultFilterColumn: false,
    component: (item) => (
      <>
        {item && item.withDateTime &&
          <span className='mdi mdi-check-circle-outline  check-true' /> || <span className='mdi mdi-alpha-x-circle-outline check-false' />
        }
      </>
    ),
  },
  {
    id: 16,
    label: 'followUpRequired',
    isDefaultFilterColumn: false,
    component: (item) => (
      <>
        {item && item.followUpRequired &&
          <span className='mdi mdi-check-circle-outline  check-true' /> || <span className='mdi mdi-alpha-x-circle-outline check-false' />
        }
      </>
    ),
  },
  {
    id: 17,
    label: 'createdByRoles',
    component: (item) => <span>{item.createActivityTypeRoles.map((el) => `${el.roleName} , `)}</span> || <span />
  },
  {
    id: 18,
    label: 'assignedByRoles',
    component: (item) => <span>{item.assignedToActivityTypeRoles.map((el) => `${el.roleName} , `)}</span> || <span />
  },
  {
    id: 19,
    label: 'prerequisiteActivities',
    component: (item) => <span>{item.activityTypeActivityTypePrerequisites.map((el) => `${el.activityTypePrerequisiteName} , `)}</span> || <span />
  },
  {
    id: 20,
    label: 'createdByNotificationBy',
    component: (item) => <span>
      {item.activityTypeTemplateRecipientTypes
        .map((el) => {
          if (el.recipientTypeName === 'CreatedBy')
            return `${el.activityTypeTemplateRecipientTypeSources.map((e) => `${e.notifyByName}  `)}`;
        })}

    </span> || <span />
  },
  {
    id: 21,
    label: 'createdByTemplate',
    component: (item) => <span>
      {item.activityTypeTemplateRecipientTypes
        .map((el) => {
          if (el.recipientTypeName === 'CreatedBy')
            return `${el.templateName}  `;
        })}

    </span> || <span />
  },
  {
    id: 22,
    label: 'assignToNotificationBy',
    component: (item) => <span>
      {item.activityTypeTemplateRecipientTypes
        .map((el) => {
          if (el.recipientTypeName === 'Assign to')
            return `${el.activityTypeTemplateRecipientTypeSources.map((e) => `${e.notifyByName}  `)}`;
        })}

    </span> || <span />
  },
  {
    id: 23,
    label: 'assignToTemplate',
    component: (item) => <span>
      {item.activityTypeTemplateRecipientTypes
        .map((el) => {
          if (el.recipientTypeName === 'Assign to')
            return `${el.templateName} `;
        })}

    </span> || <span />
  },
  {
    id: 24,
    label: 'lineManagerNotificationBy',
    component: (item) => <span>
      {item.activityTypeTemplateRecipientTypes
        .map((el) => {
          if (el.recipientTypeName === 'LineManager')
            return `${el.activityTypeTemplateRecipientTypeSources.map((e) => `${e.notifyByName} `)}`;
        })}

    </span> || <span />
  },
  {
    id: 25,
    label: 'lineManagerTemplate',
    component: (item) => <span>
      {item.activityTypeTemplateRecipientTypes
        .map((el) => {
          if (el.recipientTypeName === 'LineManager')
            return `${el.templateName} `;
        })}

    </span> || <span />
  },
  {
    id: 26,
    label: 'teamManagerNotificationBy',
    component: (item) => <span>
      {item.activityTypeTemplateRecipientTypes
        .map((el) => {
          if (el.recipientTypeName === 'TeamManager')
            return `${el.activityTypeTemplateRecipientTypeSources.map((e) => `${e.notifyByName}  `)}`;
        })}

    </span> || <span />
  },
  {
    id: 27,
    label: 'teamManagerTemplate',
    component: (item) => <span>
      {item.activityTypeTemplateRecipientTypes
        .map((el) => {
          if (el.recipientTypeName === 'TeamManager')
            return `${el.templateName}  `;
        })}

    </span> || <span />
  },
  {
    id: 28,
    label: 'teamLeadNotificationBy',
    component: (item) => <span>
      {item.activityTypeTemplateRecipientTypes
        .map((el) => {
          if (el.recipientTypeName === 'TeamLead')
            return `${el.activityTypeTemplateRecipientTypeSources.map((e) => `${e.notifyByName} `)}`;
        })}

    </span> || <span />
  },
  {
    id: 29,
    label: 'teamLeadTemplate',
    component: (item) => <span>
      {item.activityTypeTemplateRecipientTypes
        .map((el) => {
          if (el.recipientTypeName === 'TeamLead')
            return `${el.templateName} `;
        })}

    </span> || <span />
  },

];
