import { SystemConfigrationView } from '../../Views/Home/Administration/SystemConfigrationView/SystemConfigrationView'

export const SystemConfigrationRoutes = [
  {
    path: '/view',
    name: 'rotation-configration',
    component: SystemConfigrationView,
    layout: '/home/rotation-configration',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'rotation-configration',
        isDisabled: true,
        route: '/home/rotation-configration/view',
        groupName: 'system-configuration',
      },
    ],
  },
];
