import React, {
   useState, useReducer, useCallback, useEffect, useRef
} from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getErrorByName } from '../../../../../Helper';
import { SearchUsersByName, GetAllRoles, OrganizationUserSearch } from '../../../../../Services';
import {
 Inputs, Spinner, AutocompleteComponent, CheckboxesComponent, SelectComponet
} from '../../../../../Components';

export const SaveClientsSegmentation = ({
  open,
  close,
  parentTranslationPath,
  translationPath,
  onSave,
  localSchema,
  state,
  setState,
  setIsSubmitted,
  isSubmitted,
  isLoading,
  clientSegmentation,

}) => {
  const { t } = useTranslation([parentTranslationPath]);
  const searchTimer = useRef(null);

  const [selected, setSelected] = useState({
    Owner: null,
    User: [],
    Roles: [],
  });

  const [lists, setLists] = useState({
    owners: null,
    users: null,
    roles: null,
  });

  const [isLoadingOnSave, setIsLoadingOnSave] = useState({
    Owner: null,
    Users: null,
    Roles: null,
  });

  const getRoles = useCallback(async (value) => {
    setIsLoadingOnSave((item) => ({ ...item, Roles: true }));
  const res = await GetAllRoles(1, 25, value);
    if (!(res && res.status && res.status !== 200))
        setLists((item) => ({ ...item, roles: (res && res.result) || [] }));
      else
        setLists((item) => ({ ...item, roles: [] }));

   setIsLoadingOnSave((item) => ({ ...item, Roles: false }));
}, []);

const getAgents = useCallback(async (value) => {
  setIsLoadingOnSave((item) => ({ ...item, Users: true }));
const res = await OrganizationUserSearch({
 pageIndex: 1, pageSize: 25, name: value, userStatusId: 2
});
  if (!(res && res.status && res.status !== 200))
      setLists((item) => ({ ...item, users: (res && res.result) || [] }));
    else
      setLists((item) => ({ ...item, users: [] }));

 setIsLoadingOnSave((item) => ({ ...item, Users: false }));
}, []);

const getOwners = useCallback(async (name) => {
  setIsLoadingOnSave((item) => ({ ...item, Owner: true }));
   const res = await SearchUsersByName(name || '', 0, 10);
if (!(res && res.status && res.status !== 200))
      setLists((item) => ({ ...item, owners: (res && res.result) || [] }));
    else
      setLists((item) => ({ ...item, owners: [] }));

 setIsLoadingOnSave((item) => ({ ...item, Owner: false }));
}, []);

  useEffect(() => {
    getRoles();
    getAgents();
    getOwners();
  }, []);

  useEffect(() => {
    if (!state.isUsersPermission) {
      setState({ id: 'clientSegmentationUserDtos', value: [] });
      setSelected((item) => ({ ...item, User: [] }));
    }

    if (!state.isRolesPermission) {
      setState({ id: 'clientSegmentationRoleDtos', value: [] });
      setSelected((item) => ({ ...item, Roles: [] }));
    }
  }, [state.isRolesPermission, state.isUsersPermission]);

  useEffect(() => {
    if (clientSegmentation) {
       if (clientSegmentation && clientSegmentation.clientSegmentationUserDtos && clientSegmentation.clientSegmentationUserDtos.length) {
        const selectedAgents = clientSegmentation.clientSegmentationUserDtos.map((item) => ({
          applicationUserId: item.userId,
          firstName: item.user,
          lastName: ''
        }));

        const selectedRoles = clientSegmentation.clientSegmentationRoleDtos.map((item) => ({
          roleId: item.roleId,
          rolesName: item.role,
        }));

        setSelected((item) => ({
 ...item,
             Owner: {
 firstName: clientSegmentation.owner,
             lastName: '',
applicationUserId: clientSegmentation.ownerId
},
             User: selectedAgents,
             Roles: selectedRoles
             }));
       }
       if (clientSegmentation && clientSegmentation.ownerId)

        setSelected((item) => ({ ...item, Owner: { firstName: clientSegmentation.owner, lastName: '', applicationUserId: clientSegmentation.ownerId } }));
    }
  }, [clientSegmentation]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          close();
        }}
        className='activities-management-dialog-wrapper'
        disableBackdropClick
      >
        <form noValidate>
          <DialogTitle id='alert-dialog-slide-title'>
            {
              clientSegmentation ? t(`${translationPath}edit-client-segmantation`) : t(`${translationPath}add-client-segmantation`)
            }
          </DialogTitle>
          <DialogContent>
            <div className='dialog-content-wrapper'>
              <Spinner isActive={isLoading} isAbsolute />
              <div className='dialog-content-item w-50'>
                <Inputs
                  labelClasses='c-red'
                  idRef='clientSegmantationName'
                  labelValue={t(`${translationPath}clients-segmentation-name`)}
                  value={state.clientSegmantationName}
                  onInputChanged={(e) => {
                  setState({ id: 'clientSegmantationName', value: e.target.value });
                 }}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(localSchema, 'clientSegmantationName').message}
                  error={getErrorByName(localSchema, 'clientSegmantationName').error}
                />
              </div>
              <div className='dialog-content-item w-100'>
                <Inputs
                  idRef='description'
                  labelValue={t(`${translationPath}description`)}
                  value={state.description}
                  onInputChanged={(e) => {
                  setState({ id: 'description', value: e.target.value });
                 }}
                  multiline
                  rows={4}
                />
              </div>
              <div className='dialog-content-item'>
                <SelectComponet
                  idRef='privacyTypeRef'
                  data={[
                  { id: 0, value: t(`${translationPath}Public`) },
                  { id: 1, value: t(`${translationPath}Private`) },
                ] || []}
                  value={state.privacyType}
                  labelValue={t(`${translationPath}privacy-type`)}
                  valueInput='id'
                  textInput='value'
                  onSelectChanged={(value) => {
                  setState({ id: 'privacyType', value });
                }}
                  translationPathForData={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
              <div className='dialog-content-item'>
                <AutocompleteComponent
                  idRef='OwnerRef'
                  labelValue={t(`${translationPath}Owner`)}
                  labelClasses='c-red'
                  selectedValues={selected && selected.Owner}
                  value={selected && selected.Owner}
                  isLoading={isLoadingOnSave.Owner}
                  data={(lists && lists.owners) || []}
                  multiple={false}
                  chipsLabel={(option) => ((option && `${option.firstName} ${option.lastName || ''}`) || '')}
                  displayLabel={(option) => ((option && `${option.firstName} ${option.lastName || ''}`) || '')}
                  withoutSearchButton
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(localSchema, 'ownerId').message}
                  error={getErrorByName(localSchema, 'ownerId').error}
                  onChange={(event, newValue) => {
                   setSelected((item) => ({ ...item, Owner: newValue || null }));

                 setState({ id: 'ownerId', value: (newValue && newValue.applicationUserId) || null });
                }}
                  onInputKeyUp={(e) => {
                  const newValue = e.target.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getOwners(newValue);
                  }, 700);
                }}
                />
              </div>
              {
                state.privacyType === 1 && (
                <div className='dialog-content-item w-100'>

                  <CheckboxesComponent
                    idRef='isUsersPermissionRef'
                    label={t(`${translationPath}WithUsersPermission`)}
                    themeClass='theme-secondary'
                    singleChecked={state.isUsersPermission}
                    onSelectedCheckboxClicked={() =>
                    setState({ id: 'isUsersPermission', value: !(state.isUsersPermission) })}
                  />
                  <CheckboxesComponent
                    idRef='isRolesPermissionRef'
                    label={t(`${translationPath}WithRolesPermission`)}
                    themeClass='theme-secondary'
                    singleChecked={state.isRolesPermission}
                    onSelectedCheckboxClicked={() =>
                    setState({ id: 'isRolesPermission', value: !(state.isRolesPermission) })}
                  />

                </div>
                )
              }
              {
                state.isUsersPermission && (
                <div className='dialog-content-item'>
                  <AutocompleteComponent
                    idRef='UsersRef'
                    labelValue={t(`${translationPath}users`)}
                    selectedValues={selected && selected.User}
                    value={selected && selected.User}
                    isLoading={isLoadingOnSave.Users}
                    data={(lists && lists.owners) || []}
                    multiple
                    chipsLabel={(option) => ((option && `${option.firstName} ${option.lastName || ''}`) || '')}
                    displayLabel={(option) => ((option && `${option.firstName} ${option.lastName || ''}`) || '')}
                    withoutSearchButton
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                  const usersIds = [];
                   setSelected((item) => ({ ...item, User: newValue }));
                  if (newValue) {
                    newValue.map((item) => {
                      usersIds.push({ userId: item.applicationUserId });
                    });
                  }

                 setState({ id: 'clientSegmentationUserDtos', value: usersIds || [] });
                }}
                    onInputKeyUp={(e) => {
                  const newValue = e.target.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAgents(newValue);
                  }, 700);
                }}
                  />
                </div>
)
}

              {
               state.isRolesPermission && (
               <div className='dialog-content-item'>
                 <AutocompleteComponent
                   idRef='RolesRef'
                   labelValue={t(`${translationPath}Roles`)}
                   isLoading={isLoadingOnSave.Roles}
                   data={(lists && lists.roles) || []}
                   selectedValues={selected && selected.Roles}
                   multiple
                   displayLabel={(option) => (option && option.rolesName) || ''}
                   chipsLabel={(option) => (option && option.rolesName) || ''}
                   withoutSearchButton
                   parentTranslationPath={parentTranslationPath}
                   translationPath={translationPath}
                   onChange={(event, newValue) => {
                  const rolesIds = [];
                   setSelected((item) => ({ ...item, Roles: newValue }));
                  if (newValue) {
                    newValue.map((item) => {
                      rolesIds.push({ roleId: item.rolesId });
                    });
                  }

                 setState({ id: 'clientSegmentationRoleDtos', value: rolesIds || [] });
                }}
                   onInputKeyUp={(e) => {
                  const newValue = e.target.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getRoles(newValue);
                  }, 700);
                }}
                 />
               </div>
               )
}
            </div>

          </DialogContent>
          <DialogActions>
            <div className='form-builder-wrapper'>
              <div className='form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between'>
                <div className='MuiGrid-root-left'>
                  <ButtonBase
                    className='MuiButtonBase-root MuiButton-root MuiButton-text btns c-danger'
                    onClick={() => close()}
                  >
                    <span className='MuiButton-label'>
                      <span className='mx-2'>
                        {t(`${translationPath}cancel`)}
                      </span>
                    </span>
                    <span className='MuiTouchRipple-root' />
                  </ButtonBase>
                </div>
                <div className='MuiGrid-root-right'>
                  <ButtonBase
                    className='MuiButtonBase-root MuiButton-root MuiButton-text btns theme-solid bg-primary'
                    id='Submit-bbt'
                    onClick={() => onSave()}
                  >
                    <span className='MuiButton-label'>
                      <span className='mx-2'>
                        {t(`${translationPath}save`)}
                      </span>
                    </span>
                    <span className='MuiTouchRipple-root' />
                  </ButtonBase>
                </div>
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
SaveClientsSegmentation.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  localSchema: PropTypes.instanceOf(Object).isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  setIsSubmitted: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  clientSegmentation: PropTypes.instanceOf(Object),

};

SaveClientsSegmentation.defaultProps =
{
  clientSegmentation: undefined,

};
