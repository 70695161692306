import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  TabsComponent,
  RadiosGroupComponent,
  CompletedDataComponent,
} from '../../../../Components';
import { NotFoundLayout } from '../../../../Layouts/NotFoundLayout/NotFoundLayout' ; 
import { PermissionDeniedLayout } from '../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout' ; 

import { LeadsAdvanceSearchTest  , GetSensitiveFieldsForUser  } from '../../../../Services' ; 

import { GetParams } from '../../../../Helper';
import { LeadTypeIdEnum , FormsIdsEnum  } from '../../../../Enums';
import { LeadsVerticalTabsData } from '../../Common/OpenFileView/OpenFileUtilities/OpenFileData';
const parentTranslationPath = 'LeadsProfileManagementView';
const translationPath = '';
export const LeadsProfileManagementView = () => {
  const [pathName, setPathName] = useState('');
  const [list, setList] = useState([]);
  const [isPropertyManagementView, setIsPropertyManagementView] = useState(false);

  const [leadWithPolicy ,setLeadWithPolicy ] = useState(null) ; 
  const [sensitiveFieldsForUser , setSensitiveFieldsForUser  ] = useState([]) ; 
  const [ isLoading  , setIsLoading ] =  useState(false);
  const [ SensitiveLoading  , setSensitiveLoading ] =  useState(false);
  const [leadEmptyFields , setLeadEmptyFields] = useState(null) ; 
  const [displayDetailsToUserDependsOnPolicy , setDisplayDetailsToUserDependsOnPolicy] = useState(null);
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [activeTab, setActiveTab] = useState(0);
  const localActiveItem = localStorage.getItem('activeItem');
  const [contactLead, setContactLead] = useState(false);
  const [viewType, setViewType] = useState(1);
  const [filter, setFilterBy] = useState({
    id: null,
    formType: null,
  });
  const [isSensitiveLoading , setIsSensitiveLoading ] = useState(0)  ; 

  const userHasPolicyToDisplayData = useCallback(async (leadId) => 
  {
    setIsLoading(true); 
    const isForLog = true;

    let body = {
      criteria: {
        Ids: [{searchType: 1, value: leadId}]
      } , 
      filterBy : 'createdOn' , 
      orderBy : 2 ,
    };

    const res = await LeadsAdvanceSearchTest({pageIndex : 0, pageSize :2, isForLog}, body);
    if (!(res && res.status && res.status !== 200)) 
    {
      if(res && res.totalCount > 0)
      {
        if(res && res.result && res.result.length  && res.result[0].leadJson)
        {
          const LeadJson =JSON.parse(res && res.result && res.result.length  && res.result[0].leadJson);
          setLeadEmptyFields(JSON.parse(res && res.result &&  res.result[0] && res.result[0].leadJson)) ; 
           const lead = {...LeadJson.lead  ,
            bedrooms  : ((LeadJson.lead  && LeadJson.lead.bedrooms && LeadJson.lead.bedrooms.length === 0) || !(LeadJson && LeadJson.lead && LeadJson.lead.bedrooms) ? ['Any'] : LeadJson.lead.bedrooms ) ,
            bathrooms  : ((LeadJson.lead  && LeadJson.lead.bathrooms && LeadJson.lead.bathrooms.length === 0) || !(LeadJson && LeadJson.lead && LeadJson.lead.bathrooms) ? ['Any'] : LeadJson.lead.bathrooms) ,
           }
          setLeadWithPolicy({...LeadJson , lead :lead }) ; 
          localStorage.setItem('leadStatus', JSON.stringify(LeadJson && LeadJson.lead  &&  LeadJson.lead.status));
        } 
         setDisplayDetailsToUserDependsOnPolicy('allowed');

      }
        
      else { 
          setLeadWithPolicy(null) ; 
          setLeadEmptyFields(null) ; 
          setDisplayDetailsToUserDependsOnPolicy('notAllowed');
        }      
    }
    else {
        setDisplayDetailsToUserDependsOnPolicy('notAllowed') ;
        setLeadEmptyFields(null) ; 
        setLeadWithPolicy(null) ; 
     } 
     setIsLoading(false) ; 
  });

  
  const GetSensitiveFieldsForUserAPI  = useCallback(async () => {
    setSensitiveLoading(true);
    const allSensitiveFields  = await GetSensitiveFieldsForUser(leadWithPolicy && leadWithPolicy.lead && leadWithPolicy.lead.lead_type_id === 1  ?  FormsIdsEnum.leadsOwner.id :FormsIdsEnum.leadsSeeker.id  );
     
    if (!(allSensitiveFields && allSensitiveFields.status && allSensitiveFields.status !== 200)) {
      setSensitiveFieldsForUser(allSensitiveFields);
      setIsSensitiveLoading(1);
    } else {
      setSensitiveFieldsForUser([]);
      setIsSensitiveLoading(2);
    }
    
    setSensitiveLoading(false);
  }, []); 


  useEffect(() => {
    const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];
    if (pathName.includes('Contacts-CRM')) {
      setContactLead(true);
      setActiveTab(0);
    }
    else if (pathName === 'leads/lead-profile-edit' && !GetParams('matching'))
      setActiveTab(0);
    else
      setActiveTab(GetParams('matching') === 'true' ? 2 : 1);
  }, []);

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };
  useEffect(() => {
    setIsPropertyManagementView(pathName === 'properties' || pathName === 'properties/property-profile-edit');
  }, [pathName]);

  useEffect(() => {
    if (isPropertyManagementView && !contactLead) {
      setList([...LeadsVerticalTabsData.LeadsProprtyManagement]);
    }
    else if (contactLead && !isPropertyManagementView) {
      if (GetParams('formType') == LeadTypeIdEnum.Owner.leadTypeId)
        setList([...LeadsVerticalTabsData.leadFromContact && LeadsVerticalTabsData.leadFromContact.filter((tab) => tab.label !== 'matching' && tab.label !== 'assign-agents-tracking')]);
      else
        setList([...LeadsVerticalTabsData.leadFromContact]);

    }
    else if (!isPropertyManagementView && !contactLead) {
      if (GetParams('formType') == LeadTypeIdEnum.Owner.leadTypeId)
        setList([...LeadsVerticalTabsData.callCenter && LeadsVerticalTabsData.callCenter.filter((tab) => tab.label !== 'matching' && tab.label !== 'assign-agents-tracking')]);
      else
        setList([...LeadsVerticalTabsData.callCenter]);
    }
  }, [isPropertyManagementView, contactLead, pathName]);


  useEffect(() => {
    setFilterBy({
      formType: GetParams('formType'),
      id: GetParams('id'),
    });
    setPathName(window.location.pathname.split('/home/')[1].split('/view')[0]);
  }, []);




  const reloadData = () => 
  {
    userHasPolicyToDisplayData(+GetParams('id')) ; 
    GetSensitiveFieldsForUserAPI() ; 
  };

  useEffect(() => {
    const leadId  = +GetParams('id');
    if (leadId && activeTab === 0 )
      userHasPolicyToDisplayData(leadId);
  }, [activeTab]);

  useEffect(() => {
    const leadId = +GetParams('id');
    if (leadId && displayDetailsToUserDependsOnPolicy === 'allowed'  && activeTab === 0  )
        GetSensitiveFieldsForUserAPI() ; 

  }, [activeTab , displayDetailsToUserDependsOnPolicy , activeTab ]);


  return (
    <div className='leads-profile-wrapper view-wrapper'>
      {
        ( displayDetailsToUserDependsOnPolicy === 'allowed'  &&  activeTab === 0 && list && list.length && list[0].label === 'lead-information') &&
        (
          <div className='d-flex'>

            <RadiosGroupComponent
              idRef='viewDataRef'
              data={[
                {
                  key: 1,
                  value: 'all-data',
                },
                {
                  key: 2,
                  value: 'missing-data',
                },
              ]}
              value={viewType}
              labelValue='view'
              labelInput='value'
              valueInput='key'
              themeClass='theme-line'
              parentTranslationPath={parentTranslationPath}
              translationPathForData={translationPath}
              translationPath={translationPath}
              onSelectedRadioChanged={onViewTypeChangedHandler}
            />
            <CompletedDataComponent
              completedData={
                activeItem && activeItem.progress ?
                  activeItem.progress :
                  JSON.parse(localActiveItem) && JSON.parse(localActiveItem).progress
              }
              typeId='lead'
              formType={filter.formType}
              operationType={!isPropertyManagementView && !contactLead ? 'callCenter' : (isPropertyManagementView && !contactLead) ? 'leadPropertyManagment' : 'leadFromContact'}
              activeItem={activeItem}
            />
          </div>

        )}
        {
           displayDetailsToUserDependsOnPolicy === 'allowed'  && (
            <div className='m3'>
             <TabsComponent
               data={list}
               labelInput='label'
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               themeClasses='theme-solid'
               currentTab={activeTab}
               onTabChanged={onTabChanged}
               dynamicComponentProps={{
               viewType: (viewType || 1),
               parentTranslationPath,
               translationPath,
               sensitiveFieldsForUser , 
               leadWithPolicy,
               leadDataIsLoading:isLoading , 
               isSensitiveLoading  , 
               isLoading , 
               leadEmptyFields , 
               reloadData , 
               }}
            />
           </div>
         )}
         {
          displayDetailsToUserDependsOnPolicy === 'allowed'  && 
          (
            <div className='tabs-content-wrapper' />
          )
         } 
         { 
          displayDetailsToUserDependsOnPolicy === 'notAllowed'  && (<PermissionDeniedLayout/>)

         }
         
    </div>
  );
};
