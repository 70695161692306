import React, {
 useCallback, useEffect, useRef, useState
} from 'react';
import { ButtonBase, Button, Tooltip } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ContactTypeEnum, ActionsEnum, ContactPreferenceEnum } from '../../../../../Enums';
import { ActivitiesManagementDialog } from '../../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import {
 getDownloadableLink, GetParams, showinfo, showSuccess, sideMenuIsOpenUpdate , returnPropsByPermissions
} from '../../../../../Helper';
// import { ContactsPermissions } from '../../../../../Permissions';
import {
  Calendar,
  DfmSideMenuInformationComponent,
  DialogComponent,
  LoadableImageComponant,
  Spinner,
} from '../../../../../Components';
import { archivecontactsPut, GetAllActivitiesByContactId } from '../../../../../Services';
import { ArchiveState } from '../../../../../assets/json/StaticValue.json';
import {  ContactsPermissions  } from '../../../../../Permissions' ; 
import {CopyToClipboardComponents} from '../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';

const translationPath = 'utilities.cardDetailsComponent.';
function CardDetailsComponent({
  activeData, cardDetailsActionClicked, relodedata, onActionClicked, displyOpenFileButton, displyEditeButton,
  checkIfSensitiveField , 
}) {
  const getDefaultContactImage = (contactType) => ContactTypeEnum[contactType].defaultImg;
  const { t } = useTranslation('ContactsView');
  const [schedule, setSchedule] = useState(false);
  const [dataFile, setDataFile] = useState(true);
 const [addActivity, setAddActivity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setopen] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [selectedDates, setSelectedDates] = React.useState([]);
  const [date, setDate] = React.useState([]);
  const [currentMonth, setCurrentMonth] = React.useState([]);
  const [contactActivities, setContactActivities] = React.useState({
    result: [],
    totalCount: 0
  });
  const textArea = useRef(null);
  const archiveContacts = useCallback(async () => {
    await archivecontactsPut(activeData.id);
    showSuccess(t(`${translationPath}Successarchive`));
    setopen(false);
    sideMenuIsOpenUpdate(false);
    relodedata();
  }, [activeData.id, relodedata, t]);

  const copyTextToClipboard = (id) => {
    const context = textArea.current;
    if (id && context) {
      context.value = id;
      context.select();
      document.execCommand('copy');
      showinfo(`${t('Shared:Copy-id-successfully')}  (${id})`);
    } else
      showinfo(`${t('Shared:Copy-id-successfully')}  (${id})`);
  };
  const getAllActivitiesByContactId = useCallback(async () => {
    setIsLoading(true);
    const body = {
      pageIndex: 1,
      pageSize: 25,
      contactId: activeData && activeData.id,
    };
    const res = await GetAllActivitiesByContactId(body);
    if (!(res && res.status && res.status !== 200)) {
      setContactActivities({
        result: res.result,
        totalCount: res.totalCount
      });
      const dates = [];
      const newEvents = [];
      res.result.map((item) => {
        dates.push(+moment(item.activityDate).format('D'));
        newEvents.push({
          hour: moment(item.activityDate).format('hh:mm'),
          date: item.activityDate,
          subject: item.subject,
          activity: item,
          month: moment(item.activityDate).format('M')
        });
        setSelectedDates(dates);
        setEvents(newEvents);
      });
    } else {
 setContactActivities({
      result: [],
      totalCount: 0
    });
}
    setIsLoading(false);
  }, [schedule, activeData]);

  const onMonthChange = useCallback((value) => {
    if (moment(value).format('M') === moment(date).format('M')) setCurrentMonth(moment(value).format('M'));
  }, [date]);
  useEffect(() => {
    getAllActivitiesByContactId();
  }, [getAllActivitiesByContactId]);
  useEffect(() => {
    onMonthChange();
  }, [onMonthChange]);

  const onDateChange = useCallback((newDate) => {
    setDate(newDate);
  }, []);
  useEffect(() => {
    onDateChange();
  }, [onDateChange]);
  return (
    <div className='contacts-card-detaild-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='archive-bbt'>
        <Button
          onClick={() => setopen(true)}
          disabled={ArchiveState}
          className='MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid mx-2 mb-2'
          title={t(`${translationPath}Archivecontacts`)}
        >
          <span className='MuiButton-label'>
            <span className='mdi mdi-inbox-multiple' />
          </span>
          <span className='MuiTouchRipple-root' />
        </Button>
      </div>
      {activeData && (
        <div className='side-menu-wrapper'>
          <div>

            <div className='d-flex-center mb-3'>
              <LoadableImageComponant
                classes='details-img'
                alt={t(`${translationPath}user-image`)}
                src={
                  (activeData.imagePath && getDownloadableLink(activeData.imagePath)) ||
                  getDefaultContactImage(activeData.type)
                }
              />
            </div>
            <div className='d-flex-center flex-wrap mb-2'>
              <div className='id-right-side'>
                <span className='texts gray-primary-bold'>
                  {t(`${translationPath}id`)}
                  :
                </span>
                <span className='texts s-gray-primary' />
                <Tooltip title={t(`${translationPath}copy`)}>
                  <CopyToClipboardComponents
                    data={activeData.id}
                    childrenData={activeData.id}
                  />
                </Tooltip>

              </div>
              {activeData.contactPreference && (
                <div className='actions-wrapper'>
                  {activeData.contactPreference.findIndex(
                    (el) => ContactPreferenceEnum.call.key === el.lookupItemId
                  ) !== -1 && checkIfSensitiveField &&   checkIfSensitiveField('Call' , activeData ,  activeData && activeData.contact_type_id) && (
                  <ButtonBase
                    className={`${ActionsEnum.phoneSolid.buttonClasses} mx-2 mb-2`}
                    onClick={onActionClicked(ActionsEnum.phoneSolid.key, activeData)}
                  >
                    <span className={ActionsEnum.phoneSolid.icon} />
                  </ButtonBase>
                    )}
                  {activeData.contactPreference.findIndex(
                    (el) => ContactPreferenceEnum.sms.key === el.lookupItemId
                  ) !== -1 && checkIfSensitiveField &&   checkIfSensitiveField('SMS' , activeData ,  activeData && activeData.contact_type_id) && (
                  <ButtonBase
                    className={`${ActionsEnum.smsSolid.buttonClasses} mx-2 mb-2`}
                    onClick={onActionClicked(ActionsEnum.smsSolid.key, activeData)}
                  >
                    <span className={ActionsEnum.smsSolid.icon} />
                  </ButtonBase>
                    )}
                  {activeData.contactPreference.findIndex(
                    (el) => ContactPreferenceEnum.email.key === el.lookupItemId
                  ) !== -1 && checkIfSensitiveField &&   checkIfSensitiveField('Email' , activeData ,  activeData && activeData.contact_type_id)  &&  (
                  <ButtonBase
                    className={`${ActionsEnum.emailSolid.buttonClasses} mx-2 mb-2`}
                    onClick={onActionClicked(ActionsEnum.emailSolid.key, activeData)}
                  >
                    <span className={ActionsEnum.emailSolid.icon} />
                  </ButtonBase>
                    )}
                  {activeData.contactPreference.findIndex(
                    (el) => ContactPreferenceEnum.whatsapp.key === el.lookupItemId
                  ) !== -1 && checkIfSensitiveField &&   checkIfSensitiveField('WhatsApp' , activeData ,  activeData && activeData.contact_type_id) &&  (
                  <ButtonBase
                    className={`${ActionsEnum.whatsappSolid.buttonClasses} mx-2 mb-2`}
                    onClick={onActionClicked(ActionsEnum.whatsappSolid.key, activeData)}
                  >
                    <span className={ActionsEnum.whatsappSolid.icon} />
                  </ButtonBase>
                    )}
                </div>
              )}
            </div>
            <div className='d-flex-center mb-3 '>
              <div className='data-schedule-button'>
                <div className={`data-file-button ${dataFile ? 'selected' : ''}`}>
                  <Button
                    onClick={() => {
                      setDataFile(true);
                      setSchedule(false);
                      setAddActivity(false) ; 

                    }}
                    className={`btns ${dataFile ? 'theme-outline' : ''}`}
                  >
                    {t(`${translationPath}data-file`)}
                  </Button>
                </div>
                <div className={`schedule-button ${schedule ? 'selected' : ''}`}>
                  <Button
                    onClick={() => {
                      setSchedule(true);
                      setDataFile(false);
                      setAddActivity(false) ; 
                    }}
                    className={`btns ${schedule ? 'theme-outline' : ''}`}
                  >
                    {t(`${translationPath}schedule`)}
                  </Button>
                </div>
              { returnPropsByPermissions(ContactsPermissions.AddNewActivity.permissionsId) 
                && <div className={`add-activity-button ${addActivity ? 'selected' : ''}`}>
                  <Button
                    onClick={() => {
                      setSchedule(false);
                      setDataFile(false);
                      setAddActivity(true) ; 

                    }}
                    className={`btns ${addActivity ? 'theme-outline' : ''}`}
                  >
                    {t(`${translationPath}add-activity`)}
                  </Button>
                </div>
                }
              </div>
            </div>
            <div className='details-content-wrapper'>
              {dataFile && (
                <div className='px-3 mb-3 slider-data'>
                  <DfmSideMenuInformationComponent
                    activeData={activeData}
                    setIsLoading={setIsLoading}
                    translationPath={translationPath}
                    t={t}
                  />
                </div>
              )}
              {schedule && (
                <div className='schedual-wrapper'>
                  <div className='priority-types mb-3'>
                    <div className='priority-type ml-2 mr-2'>
                      <div className='high-badge ml-2 mr-2' />
                      {t(`${translationPath}high`)}
                    </div>
                    <div className='priority-type ml-2 mr-2'>
                      <div className='medium-badge ml-2 mr-2' />
                      {t(`${translationPath}medium`)}
                    </div>
                    <div className='priority-type ml-2 mr-2'>
                      <div className='low-badge ml-2 mr-2' />
                      {t(`${translationPath}low`)}
                    </div>
                  </div>
                  <div className='calender-wrapper'>
                    <Calendar
                      selectedDateChanged={onDateChange}
                      selectedDate={date}
                      activities={events}
                      events={{ selectedDays: selectedDates }}
                      wrapperClasses='transparent-calender w-100'
                      onMonthChange={onMonthChange}
                      contactsDetails
                    />
                  </div>
                  {contactActivities.totalCount && (
                    <div className='contact-Activities'>
                      {contactActivities.result.filter((e) => moment(e.activityDate).format('D/M') === moment(date).format('D/M'))
                        .map((item) => (
                          <>
                            <div className='activity-item mb-2'>
                              <div>
                                <span className='dot' />
                                <div className='activity-item-details'>
                                  <div>{item.agentName}</div>
                                  <div className='lead-ref'>
                                    {t(`${translationPath}lead-ref`)}
                                    {item.relatedLeadNumberId}
                                  </div>
                                </div>
                              </div>

                              <span className='activity-hour'>
                                {' '}
                                {moment(item.activityDate).format('hh:mm A')}
                              </span>
                            </div>
                          </>
                        ))}
                    </div>
                  )}
                  {/* </div> */}
                </div>
              )}
            </div>
            <div className='d-flex-center side-menu-actions'>
              {
                displyOpenFileButton && (
                  <ButtonBase
                    // disabled={
                    //   loginResponse &&
                    //   !loginResponse.permissions
                    //     .map(
                    //       (item) =>
                    //         item.permissionsId === ContactsPermissions.ReadContacts.permissionsId
                    //     )
                    //     .includes(true)
                    // }
                    className='btns theme-solid mx-2 mb-2'
                    onClick={cardDetailsActionClicked(ActionsEnum.folder.key, activeData)}
                  >
                    <span className='icons i-folder-white' />
                    <span className='mx-2'>{t(`${translationPath}open-file`)}</span>
                  </ButtonBase>
                )
              }
              {displyEditeButton && (
                <ButtonBase
                  // disabled={
                  //   loginResponse &&
                  //   !loginResponse.permissions
                  //     .map(
                  //       (item) =>
                  //         item.permissionsId === ContactsPermissions.CreateContacts.permissionsId
                  //     )
                  //     .includes(true)
                  // }
                  id='edit-file-ref'
                  onClick={cardDetailsActionClicked(ActionsEnum.reportEdit.key, activeData)}
                  className='btns mx-2 mb-2'
                >
                  <span className='mdi mdi-pencil-outline' />
                  <span className='mx-2'>{t(`${translationPath}edit`)}</span>
                </ButtonBase>
              )}
            </div>
          </div>
        </div>
      )}
      <DialogComponent
        isOpen={open}
        onCancelClicked={() => setopen(false)}
        onCloseClicked={() => setopen(false)}
        translationPath={translationPath}
        parentTranslationPath='ContactsView'
        titleText='Archivecontacts'
        onSubmit={(e) => {
          e.preventDefault();
          archiveContacts();
        }}
        maxWidth='sm'
        dialogContent={<span>{t(`${translationPath}MassageArchiveContacts`)}</span>}
      />
      {
        addActivity && (
          <ActivitiesManagementDialog
            open={addActivity}
            onSave={() => {
              setAddActivity(false) ; 
              setDataFile(true);
            }}
            close={() => {
              setAddActivity(false) ; 
              setDataFile(true);

            }}
            actionItemId={activeData?.id}
            translationPath={''}
            parentTranslationPath={'ContactProfileManagementView'}
          />
        )
      } 
    </div>
  );
}

CardDetailsComponent.propTypes = {
  activeData: PropTypes.instanceOf(Object),
  cardDetailsActionClicked: PropTypes.func,
  onActionClicked: PropTypes.func,
  relodedata: PropTypes.func,
  displyOpenFileButton: PropTypes.bool,
  displyEditeButton: PropTypes.bool,
  checkIfSensitiveField : PropTypes.func, 
};
CardDetailsComponent.defaultProps = {
  activeData: null,
  cardDetailsActionClicked: () => { },
  relodedata: () => { },
  onActionClicked: () => { },
  displyOpenFileButton: false,
  displyEditeButton: false,
  checkIfSensitiveField : () => { } , 
};

export { CardDetailsComponent };
