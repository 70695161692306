import React from 'react';
import clsx from 'clsx';
import './style/Copy.Style.scss';
import { PropTypes } from 'prop-types';
import { useCopy } from './CopyHelper/useCopy';

const CopyToClipboardComponents = ({
  childrenData,
  data,
  CustomizationClassName,
  MessageTitle,
  iconCopy,
  CopyDoneShowTime,
  iconCopyDone
}) => {
  const { CopiedTheValue, CopyFunctionBehavior } = useCopy();

  return (
    <div className={`copy-container ${CustomizationClassName} mx-1`}>
      {childrenData || ''}
      {data && (
        <div className='icon-container' id={`id-CopyToClipboardComponents ${data || 1} `}>
          <span
            className={clsx('mdi mdi-', (CopiedTheValue && `copied  ${iconCopyDone}`) || `${iconCopy}`)}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              CopyFunctionBehavior(data, MessageTitle, CopyDoneShowTime);
            }}
          />
        </div>
      )}
    </div>
  );
};

export { CopyToClipboardComponents };

CopyToClipboardComponents.propTypes = {
  iconCopy: PropTypes.string,
  iconCopyDone: PropTypes.string,
  CopyDoneShowTime: PropTypes.number,
  childrenData: PropTypes.string,
  MessageTitle: PropTypes.string,
  data: PropTypes.string,
  CustomizationClassName: PropTypes.string,
};
CopyToClipboardComponents.defaultProps = {
  iconCopyDone: 'mdi-check-all',
  iconCopy: 'mdi-content-copy',
  CopyDoneShowTime: 2000, // Time in Milliseconds
  childrenData: undefined,
  MessageTitle: undefined,
  data: undefined,
  CustomizationClassName: '',
};
// Refactoring Done 2023
