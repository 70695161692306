export const ClientsSegmentationPermissions = {

  SearchAndViewClientsSegmentation: {
    permissionsId: 'a7b613e6-c098-4730-2891-08dab0da5710',
    permissionsName: 'Search And View Clients Segmentation',
    componentsId: null,
    components: null
  },
  AddNewClientsSegmentation: {
     permissionsId: 'd107eacd-e5aa-4ac4-2892-08dab0da5710',
     permissionsName: ' Add New Clients Segmentation',
     componentsId: null,
     components: null
  },
  AddCustomClientSegmentation: {
    permissionsId: '9309d344-3543-4949-2893-08dab0da5710',
    permissionsName: 'Add Custom Client Segmentation',
    componentsId: null,
    components: null
  },
  ClientSegmentationDashboard: {
    permissionsId: '3b21c4d1-9350-43d2-2895-08dab0da5710',
    permissionsName: 'Client Segmentation Dashboard',
    componentsId: '8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7',
    components: null
  },

  ExportClientSegmentationAsXML: {
    permissionsId: '5c720268-d163-418f-ecc3-08dad1f1b889',
    permissionsName: 'Export Client Segmentation As XML',
    componentsId: null,
    components: null
  },
  ExportClientSegmentationAsJSON: {
    permissionsId: '1118574b-5487-4a8c-ecc4-08dad1f1b889',
    permissionsName: 'Export Client Segmentation As JSON',
    componentsId: null,
    components: null
  },
  ExportClientSegmentationAsPDF: {
    permissionsId: 'bfb724b6-cc41-43af-ecc5-08dad1f1b889',
    permissionsName: 'Export Client Segmentation As PDF',
    componentsId: null,
    components: null
  },
  ExportClientSegmentationAsCSV: {
    permissionsId: 'a4dc32e9-ff46-40cb-ecc6-08dad1f1b889',
    permissionsName: 'Export Client Segmentation As CSV',
    componentsId: null,
    components: null
  },
  ExportClientSegmentationAsExcel: {
    permissionsId: '1220d2aa-d338-4b83-ecc7-08dad1f1b889',
    permissionsName: 'Export Client Segmentation As Excel',
    componentsId: null,
    components: null
  },

  EditClientSegmentation: {
    permissionsId: 'a7caf6d4-d8f9-4086-077b-08dad1f91be5',
    permissionsName: 'Edit Client Segmentation ',
    componentsId: null,
     components: null
  },
  RunClientSegmentation: {
    permissionsId: 'c4424f22-d5d2-45ab-077c-08dad1f91be5',
    permissionsName: 'Run Client Segmentation ',
    componentsId: null,
    components: null
  },

  QuickClientSegmentationBranchDashboard :
   {
    permissionsId: 'fa20e807-a0de-40ba-f946-08dc0f48c1d2',
    permissionsName: 'Quick Client Segmentation Branch Dashboard',
    componentsId: null,
    components: null
  }
};
