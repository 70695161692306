import { CampaignRequestManagementView } from '../../Views/Home/MarketingCampaignRequests/Utilities';
import { CampaignRequestsView } from '../../Views/Home/MarketingCampaignRequests';

export const CampaignRequestsRoutes = [
    {
        path: '/add',
        name: 'Campaign:add-new-campaign-req',
        component: CampaignRequestManagementView,
        layout: '/home/campaign-requests',
        default: false,
        isRoute: true,
        authorize: true,
        roles: [],
        isDisabled: false,
        isExact: true,
        breadcrumbs: [
            {
                name: 'Campaign:campaign-requests',
                isDisabled: false,
                route: '/home/campaign-requests/view',
                groupName: 'marketing',
            },
            {
                name: 'Campaign:add-new-campaign-req',
                isDisabled: true,
                route: '/home/campaign-requests/add',
            },
        ],
    },
    {
        path: '/view-campaign-request',
        name: 'Campaign:edit-campaign-req',
        component: CampaignRequestManagementView,
        layout: '/home/campaign-requests',
        default: false,
        isRoute: true,
        authorize: true,
        roles: [],
        isDisabled: false,
        isExact: true,
        breadcrumbs: [
            {
                name: 'Campaign:campaign-requests',
                isDisabled: false,
                route: '/home/campaign-requests/view',
                groupName: 'marketing',
            },
            {
                name: 'Campaign:edit-campaign-req',
                isDisabled: true,
                route: '/home/campaign-requests/view-campaign-request',
            },
        ],
    },
    {
      path: '/view',
      name: 'Campaign:campaign-requests',
      component: CampaignRequestsView,
      layout: '/home/campaign-requests',
      default: true,
      isRoute: true,
      authorize: true,
      roles: [],
      isDisabled: false,
      isExact: true,
      breadcrumbs: [
        {
            name: 'Campaign:campaign-requests',
            isDisabled: false,
            route: '/home/campaign-requests/view',
            groupName: 'marketing',
        },
      ],
    },
];
