import React, { useState, useRef, useEffect } from 'react';
import './GroupedAvatarComponent.scss';
import { AvatarGroup } from '@material-ui/lab';
import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItem,
  List,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { getDownloadableLink, getFirstLastNameLetters } from '../../Helper';

export const GroupedAvatarComponent = ({
  data,
  selectedUserId,
  userItemClickHandler,
  maxAvatarsNumber,
  wrapperClasses
}) => {
  const showMoreRef = useRef(null);

  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: '#FFFFFF',
      borderRadius: 5,
      boxShadow: '0 4px 8px -2px rgba(9, 30, 66, 0.25)',
      overflow: 'auto',
      marginTop: '0.5rem',
      position: 'absolute',
      maxHeight: '500px',
      width: '230px',
    },
  }));
  const classes = useStyles();

  const [isUsersListOpen, setIsUsersListOpen] = useState(false);
  const [usersWithoutFirstFive, setUsersWithoutFirstFive] = useState([]);

  const showMoreClickHandler = (event) => {
    const isShowMoreClicked = event?.target?.outerText?.includes('+');
    if (isShowMoreClicked) {
      setIsUsersListOpen((prevState) => !prevState);
    }
    if (isShowMoreClicked && !showMoreRef.current) showMoreRef.current = event.target;
  };

  const usersListClickAwayHandler = (event) => {
    const isShowMoreClicked = showMoreRef.current === event.target;

    if (!isShowMoreClicked) setIsUsersListOpen(false);
  };

  const localUserItemClickHandler = (userId) => {

    if(userId === selectedUserId) userItemClickHandler(null);
    else userItemClickHandler(userId);
  }

  useEffect(() => {
    document.addEventListener('click', usersListClickAwayHandler);
    return () => document.removeEventListener('click', usersListClickAwayHandler);
  }, []);

  useEffect(() => {
    if (data?.length > maxAvatarsNumber) {
      setUsersWithoutFirstFive(data.slice(maxAvatarsNumber));
    }
  }, [data]);

  return (
    <div className={`grouped-avatar-component ${wrapperClasses}`}>
      <AvatarGroup
        classes={{
          root: `pointer`,
          avatar: `fz-16`,
        }}
        onClick={showMoreClickHandler}
        max={(maxAvatarsNumber || 0) + 1}>
        {data &&
          data.map((item) => (
            <Tooltip title={item.fullName} placement="center">
              <Avatar
                classes={{
                  root: `${item.applicationUserId === selectedUserId ? 'active-avatar' : ''}`,
                }}
                key={`${item.applicationUserId}`}
                onClick={()=>localUserItemClickHandler(item.applicationUserId)}
                src={item.profileImg ? getDownloadableLink(item.profileImg) : ''}>
                {getFirstLastNameLetters(item.fullName || '')}
              </Avatar>
            </Tooltip>
          ))}
      </AvatarGroup>
      {isUsersListOpen && (
        <List className={classes.root} dense>
          {usersWithoutFirstFive &&
            usersWithoutFirstFive.map((item) => (
              <ListItem
                key={`${item.applicationUserId}`}
                onClick={()=>localUserItemClickHandler(item.applicationUserId)}
                classes={{ root: `${item.applicationUserId === selectedUserId ? 'active-listItem' : ''}`, }}
                button>
                <ListItemAvatar classes={{ root: `min-width-unset py-1` }}>
                  <Avatar
                    classes={{ root: `fz-12 team-avatar xs-theme` }}
                    src={item.profileImg ? getDownloadableLink(item.profileImg) : ''}>
                    {getFirstLastNameLetters(item.fullName || '')}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${item.fullName || ''}`} />
              </ListItem>
            ))}
        </List>
      )}
    </div>
  );
};
