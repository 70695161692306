import React, {
  useCallback, useEffect, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { GetAllCampaigns, getProperties } from '../../../Services';
import { returnPropsByPermissions } from '../../../Helper';
import {
  Tables, Spinner, PermissionsComponent, AutocompleteComponent, Inputs
} from '../../../Components';
import { TableActions } from '../../../Enums';
import { CampaignsHeaderData } from './CampaignsHeaderData';
import { DeleteDialog } from './DeleteDialog';
import { CampaignMarketingDialog } from './CampaignMarketingDialog';
import { PermissionDeniedLayout } from '../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout';
import { CampaignsPermissions } from '../../../Permissions';

const parentTranslationPath = 'Campaign';
const translationPath = '';

export const CampaignView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
    propertyId: null,
    searchItem: ''
  });
  const [displayDetailsToUser, setDisplayDetailsToUser] = useState(null);
  const [marketingCampaigns, setMarketingCampaigns] = useState([]);
  const [campaognNameSearch, setCampaognNameSearch] = useState('');
  const [propertySearch, setPropertySearch] = useState(null);
  const [allProperteis, setAllProperteis] = useState([]);
  const [marketingCampaignsTotal, setMarketingCampaignsTotal] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setIsDialogOpen(true);
    } else if (actionEnum === TableActions.deleteText.key) {
      setActiveItem(item);
      setIsDeleteDialogOpen(true);
    }
  }, []);

  const getActionTableWithPermissions = () => {
    const list = [];
    if (returnPropsByPermissions(CampaignsPermissions.EditCampaign.permissionsId)) {
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    }
    if (returnPropsByPermissions(CampaignsPermissions.DeleteCampaign.permissionsId)) {
      list.push({
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      });
    }
    return list;
  };

  const addNewHandler = () => {
    setIsDialogOpen(true);
  };
  const getAllCampaigns = useCallback(async () => {
    setIsLoading(true);
    if (returnPropsByPermissions(CampaignsPermissions.ViewAllCampaigns.permissionsId)) {
      const res = await GetAllCampaigns(filter.pageIndex + 1, filter.pageSize, filter.searchItem, filter.propertyId);
      if (!(res && res.status && res.status !== 200)) {
        setMarketingCampaigns(res.result || []);
        setMarketingCampaignsTotal(res.totalCount || 0);
      } else
        setMarketingCampaigns([]);

      setDisplayDetailsToUser('allowed');
    } else
      setDisplayDetailsToUser('notAllowed');

    setIsLoading(false);
  }, [filter]);

  const getPropertiesOption = useCallback(async () => {
    setIsPropertyLoading(true);
    const res = await getProperties({ pageSize: 10, pageIndex: 0, search: propertySearch });
    if (!(res && res.status && res.status !== 200))
      setAllProperteis(res.result || []);
    else
      setAllProperteis([]);

    setIsPropertyLoading(false);
  }, [propertySearch]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    getAllCampaigns();
  }, [getAllCampaigns]);

  useEffect(() => {
    getPropertiesOption();
  }, [getPropertiesOption]);

  return (
    <div className='campain-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='d-flex-v-center-h-between'>
        <PermissionsComponent
          permissionsList={Object.values(CampaignsPermissions)}
          permissionsId={CampaignsPermissions.AddCampaign.permissionsId}
        >
          <Button className='btns mx-3 mb-2 theme-solid' onClick={addNewHandler} id='action_add-new_plus'>
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-new`)}
          </Button>
        </PermissionsComponent>
        {
          displayDetailsToUser && displayDetailsToUser === 'allowed' && (
            <div className='w-50 d-inline-flex'>
              <AutocompleteComponent
                idRef='propertyIdRef'
                wrapperClasses='m-2 w-50'
                multiple={false}
                data={allProperteis || []}
                displayLabel={(option) =>
                  (option && option.property.property_name) || ''}
                chipsLabel={(option) => (option && option.property.property_name) || ''}
                withoutSearchButton
                inputPlaceholder={t(`${translationPath}filter-by-property`)}
                isLoading={isPropertyLoading}
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputKeyUp={(event) => {
                  const { value } = event.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    setPropertySearch(value);
                  }, 700);
                }}
                onChange={(event, newValue) => {
                  setFilter((f) => ({ ...f, propertyId: newValue && newValue.propertyId }));
                }}
              />
              <Inputs
                idRef='DescriptionRef'
                inputPlaceholder={t(`${translationPath}filter-by-campain-name`)}
                value={campaognNameSearch}
                wrapperClasses='m-2 w-50'
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setCampaognNameSearch(value);
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    setFilter((f) => ({ ...f, searchItem: value }));
                  }, 700);
                }}
              />
            </div>
          )
        }

      </div>

      {displayDetailsToUser && displayDetailsToUser === 'allowed' && (
        <div className='p-3 w-100'>
          <Tables
            data={marketingCampaigns}
            headerData={CampaignsHeaderData}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            defaultActions={getActionTableWithPermissions()}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isLoading={isLoading}
            totalItems={marketingCampaignsTotal}
          />
        </div>
      )}
      {
        displayDetailsToUser === 'notAllowed' && (<PermissionDeniedLayout />)
      }

      {isDeleteDialogOpen && (
        <DeleteDialog
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={() => { getAllCampaigns(); }}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isDialogOpen && (
        <CampaignMarketingDialog
          isDialogOpen={isDialogOpen}
          activeItem={activeItem}
          onSave={() => {
            getAllCampaigns();
            setIsDialogOpen(false);
            setActiveItem(null);
          }}
          onClose={() => {
            setActiveItem(null);
            setIsDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
