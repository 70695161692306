import { TableFilterOperatorsEnum } from './TableFilterOperators.Enum';

export const TableFilterTypesEnum = {
  textInput: {
    key: 1,
    defaultOperators: [
      {
        key: TableFilterOperatorsEnum.equal.key,
      },
      {
        key: TableFilterOperatorsEnum.notEqual.key,
      },
      {
        key: TableFilterOperatorsEnum.contains.key,
      },
      {
        key: TableFilterOperatorsEnum.notContains.key,
      },
      {
        key: TableFilterOperatorsEnum.startWith.key,
      },
      {
        key: TableFilterOperatorsEnum.endWith.key,
      },
      {
        key: TableFilterOperatorsEnum.isBlank.key,
      },
      {
        key: TableFilterOperatorsEnum.isNotBlank.key,
      },
    ],
    defaultSelectedOperator: TableFilterOperatorsEnum.contains.key,
  },
  datePicker: {
    key: 2,
    defaultOperators: [
      {
        key: TableFilterOperatorsEnum.equalData.key,
      },
      // {
      //   key: TableFilterOperatorsEnum.notEqual.key,
      // },
      {
        key: TableFilterOperatorsEnum.lessThanDate.key,
      },
      {
        key: TableFilterOperatorsEnum.greaterThanDate.key,
      },
      {
        key: TableFilterOperatorsEnum.isBlank.key,
      },
      {
        key: TableFilterOperatorsEnum.isNotBlank.key,
      },
      {
        key: TableFilterOperatorsEnum.notEqualDate.key,
      },
    ],
    defaultSelectedOperator: TableFilterOperatorsEnum.equalData.key,
  },
  numberInput: {
    key: 3,
    defaultOperators: [
      {
        key: TableFilterOperatorsEnum.equal.key,
      },
      {
        key: TableFilterOperatorsEnum.notEqual.key,
      },
      {
        key: TableFilterOperatorsEnum.lessThan.key,
      },
      {
        key: TableFilterOperatorsEnum.greaterThan.key,
      },
      {
        key: TableFilterOperatorsEnum.isBlank.key,
      },
      {
        key: TableFilterOperatorsEnum.isNotBlank.key,
      },
    ],
    defaultSelectedOperator: TableFilterOperatorsEnum.equal.key,
  },
 selectOption: {
  key: 4,
  defaultOperators: [
    {
      key: TableFilterOperatorsEnum.equal.key,
    },
    {
      key: TableFilterOperatorsEnum.isBlank.key,
    },
    {
      key: TableFilterOperatorsEnum.isNotBlank.key,
    },
  ],
  defaultSelectedOperator: TableFilterOperatorsEnum.equal.key,
  } , 

  searchOption: {
    key: 5,
    defaultOperators: [
      
      {
        key: TableFilterOperatorsEnum.equal.key,
      },
      {
        key: TableFilterOperatorsEnum.contains.key,
      },
      {
        key: TableFilterOperatorsEnum.isBlank.key,
      },
      {
        key: TableFilterOperatorsEnum.isNotBlank.key,
      },
    ],
    defaultSelectedOperator: TableFilterOperatorsEnum.contains.key,
    } , 
};

export const TableFilterTypesEnumUpdated = {
  textInput: {
    key: 2,
    defaultOperators: [
      {
        key: TableFilterOperatorsEnum.equal.key,
      },
      {
        key: TableFilterOperatorsEnum.notEqual.key,
      },
      {
        key: TableFilterOperatorsEnum.contains.key,
      },
      {
        key: TableFilterOperatorsEnum.notContains.key,
      },
      {
        key: TableFilterOperatorsEnum.startWith.key,
      },
      {
        key: TableFilterOperatorsEnum.endWith.key,
      },
      {
        key: TableFilterOperatorsEnum.isBlank.key,
      },
      {
        key: TableFilterOperatorsEnum.isNotBlank.key,
      },
    ],
    defaultSelectedOperator: TableFilterOperatorsEnum.contains.key,

  },
  datePicker: {
    key: 2,
    defaultOperators: [
      {
        key: TableFilterOperatorsEnum.equalData.key,
      },
      {
        key: TableFilterOperatorsEnum.lessThanDate.key,
      },
      {
        key: TableFilterOperatorsEnum.greaterThanDate.key,
      },
      {
        key: TableFilterOperatorsEnum.isBlank.key,
      },
      {
        key: TableFilterOperatorsEnum.isNotBlank.key,
      },
      {
        key: TableFilterOperatorsEnum.notEqualDate.key,
      },
    ],
    defaultSelectedOperator: TableFilterOperatorsEnum.equalData.key,
  },
  numberInput: {
    key: 3,
    defaultOperators: [
      {
        key: TableFilterOperatorsEnum.equal.key,
      },
      {
        key: TableFilterOperatorsEnum.notEqual.key,
      },
      {
        key: TableFilterOperatorsEnum.lessThan.key,
      },
      {
        key: TableFilterOperatorsEnum.greaterThan.key,
      },
      {
        key: TableFilterOperatorsEnum.isBlank.key,
      },
      {
        key: TableFilterOperatorsEnum.isNotBlank.key,
      },
    ],
    defaultSelectedOperator: TableFilterOperatorsEnum.equal.key,
  },
 selectOption1: {
  key: 4,
  defaultOperators: [
    {
      key: TableFilterOperatorsEnum.equal.key,
    },
    {
      key: TableFilterOperatorsEnum.notEqual.key,
    },
    {
      key: TableFilterOperatorsEnum.contains.key,
    },
    {
      key: TableFilterOperatorsEnum.notContains.key,
    },
  ],
  defaultSelectedOperator: TableFilterOperatorsEnum.equal.key,

  } , 
};
