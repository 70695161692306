import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Spinner, Tables } from '../../../../../Components';
import { GetReservationInvoices } from '../../../../../Services';
import { TemplateViewDialog } from '../../../../Share/TemplateViewDialog/TemplateViewDialog';
import { TableActions } from '../../../../../Enums';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';

const parentTranslationPath = 'UnitsProfileManagementView';
const translationPath = '';

export const UnitInvoicePaymentDue = ({ unitTransactionId }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const [templateId, settemplateId] = useState(null);
  const [list, setList] = useState([
    {
      enum: TableActions.addPrimaryText.key,
    },
    {
      enum: TableActions.edit.key
    },
    {
      enum: TableActions.transactionText.key,
    },
  ]);
  const [unitId, setunitId] = useState(null);
  const [invoicePayments, setInvoicePayments] = useState([]);
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const getAllInvoices = useCallback(async () => {
    setIsLoading(true);
    const res = await GetReservationInvoices(unitTransactionId);
    if (!((res && res.data && res.data.ErrorId) || !res)) setInvoicePayments(res);
    else setInvoicePayments([]);
    setIsLoading(false);
  }, [unitTransactionId]);

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.PrintInvoice.key) {
        setIsOpenTemplateDialog(true);
        setunitId(item.invoiceId || null);
        settemplateId(StaticLookupsIds.invoiceId);
      } else if (actionEnum === TableActions.PrintReceipt.key) {
        setIsOpenTemplateDialog(true);
        setunitId(item.receiptId || null);
        settemplateId(StaticLookupsIds.receiptId);
      }
    },
    []
  );

  const focusedRowChanged = (rowIndex, item) => {
    if (rowIndex !== -1) {
      if (item && item.invoiceStatus === true) {
        list.splice(0, list.length);
        list.push(
          {
            enum: TableActions.PrintReceipt.key,
            title: t(`${translationPath}Shared:PrintReceipt`),
          },
          {
            enum: TableActions.PrintInvoice.key,
            title: t(`${translationPath}Shared:PrintInvoice`),
          },
        );
      } else if (item && item.invoiceStatus === false) {
        list.splice(0, list.length);
        list.push(
          {
            enum: TableActions.PrintInvoice.key,
            title: t(`${translationPath}Shared:PrintInvoice`),
          },
        );
      }
    }
  };
  useEffect(() => {
    if (unitTransactionId) getAllInvoices();
  }, [getAllInvoices, unitTransactionId]);
  return (
    <div className='view-wrapper activities-view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='d-flex-column'>
        <div className='w-100 px-3'>
          <div className='mb-2'>
            <Tables
              data={invoicePayments}
              headerData={[
                {
                  id: 1,
                  label: 'payment-no',
                  input: 'paymentNo',
                },
                {
                  id: 2,
                  label: 'amount-due',
                  input: 'amountDue',
                },
                {
                  id: 3,
                  label: 'type',
                  input: 'paymentTypeName',
                },
                {
                  id: 4,
                  label: 'mode',
                  input: 'paymentModeName',
                },
                {
                  id: 4,
                  label: 'ref-no',
                  input: 'unitReferenceNo',
                },
                {
                  id: 5,
                  label: 'status',
                  component: (item) => (
                    <span>
                      {t(`${translationPath}${(item.invoiceStatus && 'paid') || 'unpaid'}`)}
                    </span>
                  ),
                },
                {
                  id: 6,
                  label: 'net-amount',
                  input: 'amountDue',
                },
                {
                  id: 7,
                  label: 'balance',
                  input: 'amountDue',
                },
              ]}
              defaultActions={(list && list) || [
                {
                  enum: TableActions.PrintInvoice.key,
                  title: t(`${translationPath}Shared:PrintInvoice`),
                },
              ]}
              actionsOptions={{
                onActionClicked: tableActionClicked,
              }}
              activePage={filter.pageIndex}
              parentTranslationPath={parentTranslationPath}
              focusedRowChanged={focusedRowChanged}
              translationPath={translationPath}
              totalItems={0}
            />
          </div>
        </div>
      </div>
      {isOpenTemplateDialog && (
        <TemplateViewDialog
          templateName='activeTemplateName'
          isOpen={isOpenTemplateDialog}
          unitId={unitId}
          templateId={templateId}
          isOpenChanged={() => {
            setIsOpenTemplateDialog(false);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
UnitInvoicePaymentDue.propTypes = {
  unitTransactionId: PropTypes.number,
};
UnitInvoicePaymentDue.defaultProps = {
  unitTransactionId: null,
};
