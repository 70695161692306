import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent } from '../../../../../../Components';
import { lookupItemsGetId } from '../../../../../../Services';
import { LeadRatingEnum } from '../../../../../../Enums';

export const LeadRatingComponent = ({
  parentTranslationPath,
  translationPath,
  helperText,
  setState,
  state,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [LeadRatings, setLeadRatings] = useState([]);
  const [loadings, setloadings] = useState(false);
  const [selected, setSelected] = useState(null);

  const lookupActivityRating = useCallback(async () => {
    setloadings(true);
    const result = await lookupItemsGetId({
      lookupTypeId: LeadRatingEnum.lookupTypeId,
    });
    setloadings(false);
    setLeadRatings(result);
  }, []);
  useEffect(() => {
    lookupActivityRating();
  }, []);

    useEffect(() => {
      if(state && state.leadRatingId){
        setSelected({
          lookupItemId: state.leadRatingId,
          lookupItemName: state.leadRatingName,
        });
      }
  }, [state]);

  return (
    <div>
      <AutocompleteComponent
        idRef='lead-ratingRef'
        labelValue='lead-rating'
        selectedValues={selected}
        multiple={false}
        data={LeadRatings || []}
        displayLabel={(option) => t(`${option.lookupItemName || ''}`)}
        getOptionSelected={(option) => option.lookupItemId === selected.lookupItemId}
        withoutSearchButton
        inputPlaceholder={t(`${translationPath}lead-rating`)}
        isLoading={loadings}
        helperText={helperText}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setState({
            leadRatingId: (newValue && +newValue.lookupItemId) || '',
            leadRatingName: (newValue && newValue.lookupItemName) || '',
          });
          setSelected({
            lookupItemId: (newValue && +newValue.lookupItemId) || '',
            lookupItemName: (newValue && newValue.lookupItemName) || '',
          });
        }}
      />
    </div>
  );
};
LeadRatingComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
};
