import React, { useEffect, useState, useReducer, useCallback } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError, showSuccess } from "../../../../Helper";
import { MicrosoftToken, lookupItemsGetId } from "../../../../Services";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
} from "../../../../Components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { CellsSpinner } from "../../../../SharedComponents/CellSpinnerComponent/CellsSpinner";

const parentTranslationPath = "LeadsView";
const translationPath = "";

export const UsersDetails = ({ dynamicComponentProps }) => {
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();

  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [datefilters, setdatefilter] = useState(true);
  const [ShowReport2, setShowReport2] = useState(false);
  const [datepicker, setdatepicker] = useState();

  const [monthsAgo, setMonthsAgo] = useState(1);

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate()));
  const count2 = new Date(new Date().setDate(today.getDate()));
  const datetimes = moment(count)
    .startOf("month")
    .format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "Users",
      column: datefilters ? "CreatedOn" : "UpdateOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);

  const loadBookmarks = async () => {
    const report = window.report;

    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
        // console.log("Bookmarks:", fetchedBookmarks);
      } catch (error) {
        console.error("Error loading bookmarks:", error);
        setError(error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
        setError(error);
      }
    }
  };

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    applyBookmark("Bookmarkfd7a28cf006045a8885a");

    setdatepicker(0);
    setdatefilter(true);
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <SpinnerDashboards isActive={!ShowReport} isAbsolute />

      {DateAppaer && (
        <div>
          <div className="MainFiltersBi">
            <CellsSpinner isActive={ShowReport2} isAbsolute />

            <div className="selecterbi">
              <div>
                <SelectComponet
                  data={[
                    {
                      id: 1,
                      datepicker: "Update On",
                    },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: `${translationPath}created-date`,
                    isDisabled: false,
                  }}
                  value={datepicker || 0}
                  defaultValue={0}
                  valueInput="id"
                  textInput="datepicker"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setdatepicker(0);
                        setdatefilter(true);
                     
                        break;
                      default:
                        setdatepicker(1);
                        setdatefilter(false);
                        break;
                    }
                  }}
                  themeClass="theme-transparent"
                  wrapperClasses="w-auto"
                  idRef="Date_Select"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>

              <div>
                <DateRangePickerComponent
                  onClearClicked={() => {
                    setDateFilter(dateRangeDefault);
                  }}
                  ranges={[dateFilter]}
                  onDateChanged={(selectedDate) => {
                    const newStartDate =
                      selectedDate.selection &&
                      selectedDate.selection.startDate;
                    const newEndDate = new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day")
                    );

                    setDateFilter({
                      startDate: newStartDate,
                      endDate: newEndDate,
                      key: "selection",
                    });
                  }}
                />
              </div>
            </div>
            <div className="DashboardFilter">
              <div className="DivDashboardFilterbtn">
                <div className="DashboardFilterbtn">
                  <Button
                    variant="contained"
                    className="btns theme-solid bg-primary"
                    style={{ color: "white" }}
                    onClick={onClearedAllFiltersClick}
                  >
                    <span className="mdi mdi-filter-remove m-1" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {render && (
        <div className="dashboardMaindate">
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id:
                dynamicComponentProps &&
                dynamicComponentProps.config &&
                dynamicComponentProps.config.reportId,
              embedUrl:
                dynamicComponentProps &&
                dynamicComponentProps.config &&
                dynamicComponentProps.config.Url,
              accessToken: report,
              filters: [advancedFilter],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  (event) => {
                    setShowReport(true);
                    setDateAppaer(true);
                  },
                ],
                [
                  "dataSelected",

                  (event) => {
                    setShowReport2(true);
                  },
                ],

                [
                  "rendered",
                  (event) => {
                    setShowReport2(false);
                    loadBookmarks();
                  },
                ],
                ["bookmarkApplied", (event) => {}],

                [
                  "pageChanged",

                  (event) => {
                    const newPageName = event.detail.newPage.displayName;

                    // console.log(newPageName);
                    switch (newPageName) {
                      case "Leads Activity":
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);
                        break;
                      default:
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);
                        break;
                    }
                  },
                ],

                [
                  "error",
                  (event) => {
                    const error = event.detail;
                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      )}
    </div>
  );
};
