import React, { useReducer, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import './SchoolDetailsForm.scss';
import { Inputs, AutocompleteComponent } from '../../../../../../Components';
import { lookupItemsGet } from '../../../../../../Services';


const SchoolDetailsForm = ({
  parentTranslationPath,
  translationPath,
  selected,
  setSelected,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);


  const [data, setData] = useReducer(reducer, {
    schoolNationalities: [],
  });

  const getLookupsByName = async (lookupTypeName, searchedItem) => {
    const response = await lookupItemsGet({
      pageIndex: 1,
      pageSize: 25,
      lookupTypeName,
      searchedItem,
    });
    if (!(response && response.status && response.status !== 200)) {
      if (lookupTypeName === 'Country')
        setData({ id: 'schoolNationalities', value: response?.result || [] });
    }
  };


  return (
    <div className='landmark-school-form mt-3 px-3 py-4'>
      <h3 className='mb-3 mt-0'>{t(`school-details`)}</h3>
      <div className='d-flex'>
        <div className='filter-item w-50 mr-2'>
          <AutocompleteComponent
            idRef='filterschoolNationalityIdRef'
            labelValue='schoolNationality'
            wrapperClasses='w-min-unset'
            selectedValues={selected.schoolNationality || null}
            multiple={false}
            data={data.schoolNationalities || []}
            onOpen={() => {
              if (data.schoolNationalities?.length == 0)
                getLookupsByName('Country');
            }}
            displayLabel={(option) => option.lookupItemName || ''}
            withoutSearchButton
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
              setSelected({ id: 'schoolNationality', value: newValue })
            }}
          />
        </div>
        <Inputs
          idRef='student-Percentage-Ref'
          labelValue='student-Percentage'
          wrapperClasses='w-50 mr-2'
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          value={selected.studentPercentage || ''}
          onInputChanged={(event) => setSelected({ id: 'studentPercentage', value: event.target.value })}
        />
      </div>
      <div className='d-flex'>
        <Inputs
          idRef='student-Rate-Ref'
          labelValue='student-Rate'
          wrapperClasses='w-50 mr-2'
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          value={selected.studentRate || ''}
          onInputChanged={(event) => setSelected({ id: 'studentRate', value: event.target.value })}
        />
        <Inputs
          idRef='student-Count-Ref'
          labelValue='student-Count'
          wrapperClasses='w-50 mr-2'
          type='number'
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          value={selected.studentCount || 0}
          onInputChanged={(event) => setSelected({ id: 'studentCount', value: event.target.value || 0 })}
        />
      </div>
    </div>
  )
}

export default SchoolDetailsForm
