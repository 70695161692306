import React, { useState, useEffect, useReducer, useCallback } from 'react';
import moment from 'moment';
import './../TaskDetailsView.scss';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import uploadIcon from '../../../../../assets/images/icons/upload-01.svg';
import { PageHeading } from '../../../../../SharedComponents';
import { TaskUploadDialog } from './../TaskUploadDialog';
import { LoadableDocumentComponent } from '../../../../../Components';
import { TemplatesPreviewDialog } from '../../../../../Views/Home/TemplatesView/Dialogs';
import { getIsValidURL, getIsValidDataURL, getDownloadableLink, GetParams, showError } from '../../../../../Helper';
import { GetTaskUploadedData } from '../../../../../Services';

export const UploadedTaskDetailsTab = ({ parentTranslationPath, translationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [viewedFile, setViewedFile] = useState(null);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [uploadedTaskData, setUploadedTaskData] = useState(null);

  const taskUploadHandler = () => {
    setIsUploadDialogOpen(true);
  };

  const handleDownloadClick = (value) => () => {
    const link = document.createElement('a');
    link.setAttribute('download', value.urlDescription);
    link.href = getDownloadableLink(value.fileId);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const openLink = (url) => {
    if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
    if (getIsValidURL(url)) {
      window.open(url || '');
    } else if (getIsValidDataURL(url)) {
      let windowObj = window.open();
      const iframeElement = `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
      windowObj.document.write(iframeElement);
    }
  }


  const getTaskUploadedData = async () => {
    const res = await GetTaskUploadedData({
      taskId: +GetParams('id') || null,
    });
    if (!(res && res.status && res.status !== 200)) {
      setUploadedTaskData(res);
    }
  };

  useEffect(() => {
    getTaskUploadedData();
  }, []);

  return (
    <>
      <div className='mb-2 d-flex-h-between fa-start'>
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={'Task-deliverable'}
          subTitle={'Task-deliverable-heading'}
        />
        <ButtonBase className='marketing-buttons solid' onClick={taskUploadHandler}>
          <img src={uploadIcon} className='mr-1' />
          {t(`${translationPath}Upload`)}
        </ButtonBase>
      </div>

      <div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Content</span>
          </div>
          <div className='w-50'>
            <p className='text-tertiary'>{uploadedTaskData?.content || '-'}</p>
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Note</span>
          </div>
          <div className='w-50'>
            <p className='text-tertiary'>{uploadedTaskData?.note || '-'}</p>
          </div>
        </div>
        <div className='default-d-flex py-3 b-top'>
          <div className='w-25'>
            <span className='b-600 text-primary'>Attached files</span>
          </div>
          <div className='w-50'>
            {uploadedTaskData?.taskAttachments?.length ?
              uploadedTaskData?.taskAttachments.map((item) =>
                item.url ? (
                  <div className='link-item-container py-3'>
                    <p className='text-tertiary ecilipse-text'>{item.url}</p>
                    <div className='d-flex-center'>
                      <span
                        className='b-600 text-primary pointer sec-color'
                        onClick={() => {
                          try {
                            openLink(item.url)
                          } catch (error) {
                            showError(t(`${translationPath}this-link-is-not-valid`));
                          }
                        }}>
                        Visit Link
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className='file-item-container  py-3'>
                    <div className='file-icon-wrapper d-flex-center'>
                      <span className='mr-1'>
                        <LoadableDocumentComponent
                          classes='file-icon'
                          fileName={item.urlDescription || ''}
                          alt={`cover-image`}
                        />
                      </span>
                      <span className='d-flex-column'>
                        <span className='file-title'>{item.urlDescription}</span>
                      </span>
                    </div>
                    <div className='d-flex-center'>
                      <span
                        className='b-600 text-primary pointer'
                        onClick={handleDownloadClick(item)}>
                        Download
                      </span>
                    </div>
                    <div className='d-flex-center'>
                      <span
                        className='b-600 text-primary pointer sec-color'
                        onClick={() => {
                          setIsOpenPreviewDialog(true);
                          setViewedFile({
                            templateFileId: item.fileId,
                            templateFileName: item.urlDescription,
                            templateText: '',
                          });
                        }}
                      >
                        View
                      </span>
                    </div>
                  </div>
                )
              ) : '-'}
          </div>
        </div>
      </div>

      {isUploadDialogOpen && (
        <TaskUploadDialog
          isDialogOpen={isUploadDialogOpen}
          onSave={() => {
            getTaskUploadedData();
            setIsUploadDialogOpen(false);
          }}
          onClose={() => {
            setIsUploadDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isOpenPreviewDialog && (
        <TemplatesPreviewDialog
          activeItem={viewedFile}
          maintitleText='FILE-VIEW'
          isOpen={isOpenPreviewDialog}
          isOpenChanged={() => {
            setIsOpenPreviewDialog(false);
            setViewedFile(null);
          }}
          parentTranslationPath='Shared'
          translationPath={translationPath}
        />
      )}
    </>
  );
};
