export const LeadOwnerDataKeys = {
  1: [{
    id: 1,
    fieldkey: 'contact_name',
    name: 'contactName'
  },
  {
    id: 2,
    fieldkey: 'email_address',
    name: 'email',
  },
  {
    id: 3,
    fieldkey: 'mobile',
    name: 'phone',
  },
  {
    id: 4,
    fieldkey: 'leadId',
    name: 'leadId',
  }],

  2: [{
    id: 1,
    fieldkey: 'contact_name',
    name: 'contactName'
  },
  {
    id: 2,
    fieldkey: 'general_email',
    name: 'email',
  },
  {
    id: 3,
    fieldkey: 'landline_number',
    name: 'phone',
  },
  {
    id: 4,
    fieldkey: 'leadId',
    name: 'leadId',
  }],
};
