/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ButtonBase, Tooltip } from '@material-ui/core';
import moment from 'moment-timezone';
import {
  CheckboxesComponent,
  LoadableImageComponant,
  PopoverComponent,
  ProgressComponet,
} from '../../../../../Components';
import {
  ActionsEnum,
  ContactTypeEnum,
  LoadableImageEnum,
  UserAccountTypeEnum,
  ContactPreferenceEnum,
  LeadsClassTypesEnum,
} from '../../../../../Enums';
import { getDownloadableLink } from '../../../../../Helper';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';

export const ContactsCardsComponent = ({
  data,
  activeCard,
  isExpanded,
  onCardClicked,
  onFooterActionsClicked,
  parentTranslationPath,
  translationPath,
  notExpandedMax,
  withCheckbox,
  onCardCheckboxClick,
  selectedDetailsContactItem,
  onActionClicked,
  isCheckBoxDisabled,
  selectedCards,
  displyOpenFileButton,
  checkIfSensitiveField,
  contactPreferenceFieldThanFour,



}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [actionsAttachedWith, setActionsMenuAttachedWith] = useState(null);
  const [Activeitem, setActiveitem] = useState(null);
  const [otherContactPreference, setOtherContactPreference] = useState(
    [{ key: 'whatsapp', value: 'WhatsApp' }]
  );

  const getDefaultContactImage = useCallback(
    (contactType) => ContactTypeEnum[contactType] && ContactTypeEnum[contactType].defaultImg,
    []
  );
  const getIsSelectedCard = useCallback(
    (row) => selectedCards && selectedCards.findIndex((item) => item.id === row.id) !== -1,
    [selectedCards]
  );
  const handleClose = useCallback(() => {
    setActionsMenuAttachedWith(null);
  }, []);
  const menuOpenHandler = useCallback((event, index) => {
    setActiveitem(index);
    event.stopPropagation();
    event.preventDefault();
    setActionsMenuAttachedWith(event.currentTarget);
  }, []);

  const convertContactLeadTypes = (leadTypesValue) => {
    const output = leadTypesValue.split(',');
    const list = [];
    if (output) {
      output.forEach((element) => {
        if (+element === LeadsClassTypesEnum.seller.key)
          list.push(LeadsClassTypesEnum.seller.name);
        if (+element === LeadsClassTypesEnum.landlord.key)
          list.push(LeadsClassTypesEnum.landlord.name);
        if (+element === LeadsClassTypesEnum.buyer.key)
          list.push(LeadsClassTypesEnum.buyer.name);
        if (+element === LeadsClassTypesEnum.tenant.key)
          list.push(LeadsClassTypesEnum.tenant.name);
      });
    }
    return list;
  };



  const openfile = (folderkey, activeData, index, e) => {
    let rightclick;
    if (!e) var e = window.event;
    if (e.which) rightclick = (e.which === 3);
    else if (e.button) rightclick = (e.button === 2);
    const el = document.createElement('a');
    el.href = `/home/Contacts-CRM/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`;
    el.target = '_blank';
    el.click();
  };

  return (
    <div className='contacts-cards-wrapper childs-wrapper'>
      {
        data && data.result &&
        data.result.map((item, index) => (
          <div
            className={`contacts-card-wrapper${isExpanded ? ' is-expanded' : ''}${(((activeCard && activeCard.id === item && item.id) ||
              (selectedDetailsContactItem && selectedDetailsContactItem.id === item && item.id)) &&
              ' is-open') ||
              ''
              }`}
            key={`contactsCardItemRef${index + 1}}`}
          >
            {withCheckbox && (
              <div className='card-checkbox-wrapper'>
                <CheckboxesComponent
                  idRef={`contactsCheckboxItemRef${index + 1}`}
                  singleChecked={getIsSelectedCard(item)}
                  onSelectedCheckboxChanged={(event) => {
                    const isObsoleteContact = selectedCards && selectedCards.length !== 0;
                    const isContactWithTransaction = (isObsoleteContact &&
                      item.isWithTransaction === 1 && !getIsSelectedCard(item));

                    onCardCheckboxClick(index, item, isContactWithTransaction);
                  }}
                  isDisabled={((isCheckBoxDisabled && !getIsSelectedCard(item)) ||
                    (selectedCards &&
                      selectedCards[0] &&
                      selectedCards[0].userTypeId !== item?.userTypeId))}
                />
              </div>
            )}
            {item && item.isBulkUpload && (
              <div className={`ribbon blue ${((item.accountType !== 'normal') || withCheckbox) && 'top'}`} >Bulk Upload</div>
            )}
            <a onClick={onCardClicked && onCardClicked(item, index)} className='cards-wrapper'>
              {item.accountType && UserAccountTypeEnum[item.accountType].curvedImg && (
                <Tooltip
                  title={t(`${translationPath}${UserAccountTypeEnum[item.accountType].value}`)}
                  placement='top-end'
                >
                  <div className='tag-curve-wrapper'>
                    <img
                      src={UserAccountTypeEnum[item.accountType].curvedImg}
                      alt={t(`${translationPath}account-type`)}
                      className='tag-curve-img'
                    />
                  </div>
                </Tooltip>
              )}
              <div className='cards-body-wrapper'>
                <div className='card-body-section'>
                  <div className='merge-wrapper'>
                    <LoadableImageComponant
                      classes='cover-image'
                      type={LoadableImageEnum.div.key}
                      alt={t(`${translationPath}contact-image`)}
                      src={
                        (item.imagePath && item.imagePath !== '' && getDownloadableLink(item.imagePath)) ||
                        getDefaultContactImage(item.type)
                      }
                    />
                    {item && item.isMerge && (
                      <Tooltip title={t(`${translationPath}merged`)}>
                        <div className='merged-contact'>
                          <span className='mdi mdi-arrow-collapse-all' />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  {item.contactPreference && item.contactPreference.length > 0 && onActionClicked && (
                    <div className='actions-wrapper'>
                      {item.contactPreference.findIndex(
                        (el) => ContactPreferenceEnum.call.key === el.lookupItemId
                      ) !== -1 && checkIfSensitiveField('Call', item, item.contact_type_id) && (
                          <ButtonBase
                            className={ActionsEnum.phoneSolid.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.phoneSolid.key, item)}
                          >
                            <span className={ActionsEnum.phoneSolid.icon} />
                          </ButtonBase>
                        )}
                      {item.contactPreference.findIndex(
                        (el) => ContactPreferenceEnum.sms.key === el.lookupItemId
                      ) !== -1 && checkIfSensitiveField('SMS', item, item.contact_type_id) && (
                          <ButtonBase
                            className={ActionsEnum.smsSolid.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.smsSolid.key, item)}
                          >
                            <span className={ActionsEnum.smsSolid.icon} />
                          </ButtonBase>
                        )}
                      {item.contactPreference.findIndex(
                        (el) => ContactPreferenceEnum.email.key === el.lookupItemId
                      ) !== -1 && checkIfSensitiveField('Email', item, item.contact_type_id) && (
                          <ButtonBase
                            className={ActionsEnum.emailSolid.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.emailSolid.key, item)}
                          >
                            <span className={ActionsEnum.emailSolid.icon} />
                          </ButtonBase>
                        )}
                      {item.contactPreference.length < 4 &&
                        item.contactPreference.findIndex(
                          (el) => ContactPreferenceEnum.whatsapp.key === el.lookupItemId
                        ) !== -1 && checkIfSensitiveField('WhatsApp', item, item.contact_type_id) && (
                          <ButtonBase
                            className={ActionsEnum.whatsappSolid.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.whatsappSolid.key, item)}
                          >
                            <span className={ActionsEnum.whatsappSolid.icon} />
                          </ButtonBase>
                        )}
                      {item.contactPreference.length > 3 && contactPreferenceFieldThanFour(otherContactPreference, item, item.contact_type_id) && (
                        <>
                          <ButtonBase
                            className={ActionsEnum.dotsHorizontal.buttonClasses}
                            onClick={(event) => menuOpenHandler(event, index)}
                          >
                            <span className={ActionsEnum.dotsHorizontal.icon} />
                          </ButtonBase>
                          {Activeitem === index && (
                            <PopoverComponent
                              idRef={`actionsMenuRef${index + 1}`}
                              handleClose={handleClose}
                              attachedWith={actionsAttachedWith}
                              popoverClasses='popover-contact-prefernces'
                              component={(
                                <div key={`divMenuRef${index + 1}`}>
                                  {item.contactPreference && item.contactPreference.length > 0 &&
                                    item.contactPreference.findIndex(
                                      (el) => ContactPreferenceEnum.whatsapp.key === el.lookupItemId
                                    ) !== -1 && checkIfSensitiveField('WhatsApp', item, item.contact_type_id) && (
                                      <ButtonBase className='w-100'
                                        onClick={onActionClicked(ActionsEnum.whatsappSolid.key, item)}
                                      >
                                        <ButtonBase
                                          className={ActionsEnum.whatsappSolid.buttonClasses}
                                        >
                                          <span className={ActionsEnum.whatsappSolid.icon} />
                                        </ButtonBase>
                                        <span>
                                          {' '}
                                          {t(`${translationPath}whatsapp`)}
                                        </span>
                                      </ButtonBase>
                                    )}
                                </div>
                              )}
                            />
                          ) ||
                            ''}
                        </>
                      )}

                    </div>
                  )}

                </div>
                <div className={`card-body-section${isExpanded ? ' is-expanded' : ''}`}>
                  <div className='body-title-wrapper'>
                    <span className='body-title'>{item && item.name}</span>
                    <div className='hedar-card'>
                      <div className='created-on'>
                        <span className='details-icon mdi mdi-calendar mdi-16px' />
                        <span>
                          <span className='details-text'>
                            {t(`${translationPath}created`)}
                            :
                          </span>
                          <span className='px-1'>
                            {(item.createdOn &&
                              moment(item.createdOn)
                                .locale(i18next.language)
                                .format('DD/MM/YYYY')) ||
                              'N/A'}
                          </span>
                        </span>
                      </div>

                    </div>
                    {item && item.leadTypes && (
                      <div className='type-wrapper'>
                        {convertContactLeadTypes(item.leadTypes).map((w, subIndex) => (
                          <div
                            key={`leadTypesRef${subIndex + 1}-${item && item.id}`}
                            className={`type-item ${w.toLowerCase()}`}
                          >
                            {t(`${translationPath}${w.toLowerCase()}`)}
                          </div>
                        ))}
                      </div>
                    )}

                    {item.relationship && (
                      <div className='w-100'>
                        <span>{t(`${translationPath}${item.relationship}`)}</span>
                      </div>
                    )}
                  </div>
                  <div className='details-item-wrapper'>
                    <span className='details-text contacts-text-wrap'>
                      {t(`${translationPath}id`)}
                    </span>
                    {' '}
                    :
                    <Tooltip title={t(`${translationPath}copy`)}>
                      <CopyToClipboardComponents
                        data={item && item.id}
                        childrenData={item && item.id}
                      />
                    </Tooltip>
                  </div>
                  {item && item.details && item.details.length > 0 && item.details
                    .filter(
                      (filterItem, filterIndex) =>
                        (!isExpanded && filterIndex < notExpandedMax) || isExpanded
                    )
                    .map((subItem, subIndex) => (
                      <div
                        key={`contactsCardItemRef${subIndex + 1}`}
                        className='details-item-wrapper'
                      >
                        {/* <span className={`details-icon ${subItem.iconClasses}`} /> */}
                        <span className='details-item details-item-flex'>
                          <span className='details-text contacts-text-wrap'>
                            {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                            {t(`${translationPath}${subItem.title || ''}`)}
                            {(subItem && subItem.title) ? ' :' : ''}
                          </span>
                          <span className='px-1 contacts-card-text' title={subItem.value}>
                            {subItem.value}
                          </span>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div className='cards-progress-wrapper'>
                <ProgressComponet
                  value={item && item.progress}
                  progressText={`${item.progress}%`}
                  themeClasses='theme-gradient'
                />
              </div>
              {
                (displyOpenFileButton) && (
                  <div className='cards-footer-wrapper'>
                    {
                      displyOpenFileButton && (

                        <ButtonBase
                          className={`btns theme-transparent mx-0 ${displyOpenFileButton ? 'w-50 ' : 'w-100'}`}
                          onClick={onFooterActionsClicked(ActionsEnum.folder.key, item, index)} id='ActionsClickedfolder'
                          onKeyUp={(e) => openfile(ActionsEnum.folder.key, item, index, e)}
                        >
                          <span className='mdi mdi-folder-outline' />

                          {' '}
                          <span className='px-1'>{t('open-file')}</span>

                        </ButtonBase>
                      )
                    }
                  </div>
                )
              }
            </a>

          </div>
        ))
      }
    </div>
  );
};

ContactsCardsComponent.propTypes = {
  data: PropTypes.shape({ result: PropTypes.instanceOf(Array), totalCount: PropTypes.number })
    .isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  activeCard: PropTypes.instanceOf(Object),
  selectedDetailsContactItem: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool,
  withCheckbox: PropTypes.bool,
  onCardClicked: PropTypes.func,
  onActionClicked: PropTypes.func,
  onCardCheckboxClick: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  notExpandedMax: PropTypes.number,
  isCheckBoxDisabled: PropTypes.bool,
  selectedCards: PropTypes.instanceOf(Array),
  displyOpenFileButton: PropTypes.bool,
};
ContactsCardsComponent.defaultProps = {
  notExpandedMax: 6,
  isExpanded: false,
  withCheckbox: false,
  onCardClicked: undefined,
  onActionClicked: undefined,
  selectedDetailsContactItem: undefined,
  activeCard: undefined,
  isCheckBoxDisabled: false,
  selectedCards: undefined,
  displyOpenFileButton: false,
};
