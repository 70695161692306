import React  , {  useState , useEffect , useCallback   } from 'react';
import { DialogComponent, Spinner , AutocompleteComponent  } from '../../../../../Components';
import { CancelInquiryServices  , GetLookupItems    } from '../../../../../Services' ; 
import { InquiryClosedReasonEnum    } from '../../../../../Enums' ; 
import { showSuccess  , showError  } from '../../../../../Helper' ; 
import { useTranslation } from 'react-i18next';

export const CancelInquiryDialog = ({
  parentTranslationPath,
  translationPath,
  close,
  onSave , 
  activeItem, 
  open
}) => {

  const { t } = useTranslation('Inquires');
  const [isLoading , setIsLoading ] = useState(false) ; 
  const [cancelReason , setCancelReason ] = useState(null) ; 
  const [allCancelReason , setAllCancelReason] = useState([]) ; 


  const cancelHandler = async () => {
    setIsLoading(true);
    if(!cancelReason)
    {
      showError(t(`${translationPath}cancel-inquiry-required`));
      setIsLoading(false);
      return ; 

    }
    const cancelBody = {
      inquiryId : (activeItem && activeItem.inquiryId) ,
      cancelReasonId : (cancelReason && cancelReason.lookupItemId)
    }
    const res = await CancelInquiryServices(cancelBody);
  
    if (!(res && res.status && res.status !== 200)) {
        showSuccess(t(`${translationPath}cancel-inquiry-succes`));
        close() ;
        onSave();
    } else showError(t(`${translationPath}cancel-inquiry-failed`));
    setIsLoading(false);
};


  const getAllLookups = useCallback(async () => {
    setIsLoading(true) ; 
    const res = await GetLookupItems( 1, 10000,InquiryClosedReasonEnum.lookupItemName);
      if (!(res && res.status && res.status !== 200)) 
       setAllCancelReason(res.result) ; 

      else 
      setAllCancelReason([]) ; 

    setIsLoading(false) ; 

    }, []);
  
    useEffect(() => {
      getAllLookups();
    }, []);

   
  return (
    <DialogComponent
        titleText='cancel-inquiry'
        saveText={t(`${translationPath}confirm`)}
        SmothMove
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <Spinner isActive={isLoading} isAbsolute />
            <AutocompleteComponent
              idRef='inquiryClosedReasonRef'
              labelValue={t(`${translationPath}inquiryClosedReason`)}
              data={  allCancelReason ||  []} 
              selectedValues={cancelReason}
              multiple={false}
              displayLabel={(option) =>
              (option && option.lookupItemName) || ''}
              chipsLabel={(option) => (option && option.lookupItemName) || ''}
              isLoading={isLoading}
              withLoader
              withoutSearchButton
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             value={cancelReason}
             onChange={(e, newValues) => 
            {
              setCancelReason(newValues) ;
               
            }}
          />       

            
          </div>
        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={open}
        onSaveClicked={()=> cancelHandler()}
        onCloseClicked={() =>  close()}
        onCancelClicked={() => close() }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />

  );
};
