import React, { useEffect, useState, useReducer, useCallback } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError, showSuccess } from "../../../../Helper";
import { MicrosoftToken, lookupItemsGetId } from "../../../../Services";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
} from "../../../../Components";
import { ActionsButtonsEnum, MediaEnum, Status } from "../../../../Enums";
import { useTranslation } from "react-i18next";
import { OrganizationUserSearch } from "../../../../Services/userServices";
import { useSelector, useDispatch } from "react-redux";
import { GlobalOrderFilterActions } from "../../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { ButtonBase } from "@material-ui/core";
import { CellsSpinner } from "../../../../SharedComponents/CellSpinnerComponent/CellsSpinner";

const parentTranslationPath = "LeadsView";
const translationPath = "";

export const LeadAutomationFilter = ({ dynamicComponentProps }) => {
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);

  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();

  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [Datetime, setDatetime] = useState(30);
  const [Leadtype, setLeadtype] = useState();
  const [Leadtypestatus, setLeadtypestatus] = useState(true);
  const [OperatorLeadType, setOperatorLeadType] = useState("All");
  const [LeadClass, setLeadClass] = useState();
  const [LeadClassstatus, setLeadClassstatus] = useState(true);
  const [OperatorLeadClass, setOperatorLeadClass] = useState("All");
  const [Leadstatus, setLeadstatus] = useState();
  const [Leadstatusstatus, setLeadstatusstatus] = useState(true);
  const [OperatorLeadstatus, setOperatorLeadstatus] = useState("All");
  const [datefilters, setdatefilter] = useState(true);
  const [vlueapper, setvlueapper] = useState(false);
  const [datefiltersActivity, setdatefilteractivity] = useState(false);
  const [ShowReport2, setShowReport2] = useState(false);
  const [datepicker, setdatepicker] = useState();

  const [salestransactions, setsalestransactions] = useState(false);
  const [Activity, setActivity] = useState(false);
  const [Leads, setLeads] = useState(false);
  const [leasetransaction, setleasetransaction] = useState(false);

  const [monthsAgo, setMonthsAgo] = useState(1);

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate() - 7));
  const count2 = new Date(new Date().setDate(today.getDate()));

  const datetimes = moment(count).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: startDate || null,
    endDate: endDate || null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes2,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: datefilters ? "LeadsActivityViews" : "LeadAssignmentHistories",
      column: datefilters ? "LeadsActivityViewsCreatedOn" : "LeadAssignmentHistoriesCreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedFilterActivity = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "LeadsActivityViews",
      column: "Activity Update on",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  let selectedFilter;

  switch (true) {
    case Leads:
      selectedFilter = advancedFilter;
      break;
    case Activity:
      selectedFilter = advancedFilterActivity;
      break;
    default:
      selectedFilter = advancedFilter;
  }

  const FilterLeadClass = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsActivityViews",
      column: "LeadClass",
    },
    operator: OperatorLeadClass,
    values: [LeadClass],
    filterType: models.FilterType.BasicFilter,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    setLeadClass(0);
    setLeadClassstatus(true);
    setdatepicker(0);
    setdatefilter(true);
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
        console.log(true);
      })
      .catch((error) => {
        console.log(false);
      });
  }, []);

  useEffect(() => {
    Microsoft(false);
  }, []);
  useEffect(() => {
    if (LeadClassstatus) setOperatorLeadClass("All");
    else setOperatorLeadClass("In");
  }, [LeadClassstatus, LeadClass]);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <SpinnerDashboards isActive={!ShowReport} isAbsolute />

      {DateAppaer && (
        <div>
          <div className="MainFiltersBi">
            <CellsSpinner isActive={!ShowReport2} isAbsolute />
            <div className="selecterbi">
              <div>
                <SelectComponet
                  data={[
                    { id: 1, LeadClass: "seller" },
                    { id: 2, LeadClass: "landlord" },
                    { id: 3, LeadClass: "buyer" },
                    { id: 4, LeadClass: "tenant" },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: "select-lead-type",
                    isDisabled: false,
                  }}
                  value={LeadClass || 0}
                  defaultValue={0}
                  valueInput="id"
                  textInput="LeadClass"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setLeadClassstatus(true);
                        setLeadClass(0);
                        break;
                      default:
                        setLeadClass(value);
                        setLeadClassstatus(false);
                        setShowReport2(false);
                        break;
                    }
                  }}
                  wrapperClasses="w-auto"
                  themeClass="theme-transparent"
                  idRef="Lead_Type"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
            </div>

            <div className="selecterbi">
              {!Leads ? (
                <div>
                  <SelectComponet
                    emptyItem={{
                      text: "Activity update on",
                      isDisabled: true,
                    }}
                    valueInput="key"
                    textInput="value"
                    themeClass="theme-transparent"
                    wrapperClasses="w-auto"
                    idRef="Date_Select"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
              ) : (
                <div>
                  <SelectComponet
                    data={[
                      {
                        id: 1,
                        datepicker: `${translationPath}last-assign-date`,
                      },
                    ]}
                    emptyItem={{
                      value: 0,
                      text: `${translationPath}created-date`,
                      isDisabled: false,
                    }}
                    value={datepicker || 0}
                    defaultValue={0}
                    valueInput="id"
                    textInput="datepicker"
                    onSelectChanged={(value) => {
                      switch (value) {
                        case 0:
                          setdatepicker(0);
                          setdatefilter(true);
                          setShowReport2(false);
                          break;
                        default:
                          setdatepicker(1);
                          setdatefilter(false);
                          setShowReport2(false);
                          break;
                      }
                    }}
                    themeClass="theme-transparent"
                    wrapperClasses="w-auto"
                    idRef="Date_Select"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
              )}

              <div>
                <DateRangePickerComponent
                  onClearClicked={() => {
                    setShowReport2(false);
                    setDateFilter({
                      startDate: startDate,
                      endDate: endDate,
                      key: "selection",
                    });
                  }}
                  ranges={[dateFilter]}
                  onDateChanged={(selectedDate) => {
                    const newStartDate =
                      selectedDate.selection &&
                      selectedDate.selection.startDate;
                    const newEndDate = new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day")
                    );

                    if (
                      newStartDate.getTime() !==
                        dateFilter.startDate.getTime() ||
                      newEndDate.getTime() !== dateFilter.endDate.getTime()
                    ) {
                      setShowReport2(false);
                    } else {
                      setShowReport2(true);
                    }

                    setDateFilter({
                      startDate: newStartDate,
                      endDate: newEndDate,
                      key: "selection",
                    });
                  }}
                />
              </div>
            </div>
            <div className="clearDashboardFilter">
              <ButtonBase
                onClick={onClearedAllFiltersClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary clear-all-btn"
              >
                <span className="mdi mdi-filter-remove m-1" />
                {t(`${translationPath}clear-filters`)}
              </ButtonBase>
            </div>
            <div className="clearDashboardFilter">
              <ButtonBase
                onClick={handleRefreshClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-success clear-all-btn"
              >
                <span className="mdi mdi-chart-arc m-1" />
                Refresh
              </ButtonBase>
            </div>
          </div>
        </div>
      )}
      {render && (
        <div className="dashboardMain">
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id:
                dynamicComponentProps &&
                dynamicComponentProps.config &&
                dynamicComponentProps.config.reportId,
              embedUrl:
                dynamicComponentProps &&
                dynamicComponentProps.config &&
                dynamicComponentProps.config.Url,
              accessToken: report,
              filters: [selectedFilter, FilterLeadClass],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  (event) => {
                    setShowReport(true);
                    setDateAppaer(true);
                  },
                ],
                [
                  "dataSelected",

                  (event) => {
                    setShowReport2(false);
                  },
                ],

                [
                  "rendered",
                  (event) => {
                    setShowReport2(true);
                  },
                ],

                [
                  "pageChanged",

                  (event) => {
                    const newPageName = event.detail.newPage.displayName;

                    console.log(newPageName);
                    switch (newPageName) {
                      case "Leads Activity":
                        setActivity(true);
                        setLeads(false);
                        // setShowReport2(false);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);

                        break;
                      default:
                        setActivity(false);
                        setLeads(true);
                        // setShowReport2(false);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);
                        break;
                    }
                  },
                ],

                [
                  "error",
                  (event) => {
                    const error = event.detail;
                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      )}
    </div>
  );
};
