import React, { useState, useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { TableActions } from '../../../../Enums/TableActions.Enum';
// eslint-disable-next-line import/named
import { Tables } from '../../../../Components';
import { ActivitiesManagementDialog } from '../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { GetActivityById } from '../../../../Services/ActivitiesServices';
import { ReplyActivityDialog } from '../../ActivitiesView/ReplyActivitesView/ReplyActivityDialog';
import { GlobalHistory, sideMenuComponentUpdate, sideMenuIsOpenUpdate } from '../../../../Helper';
import { UnqualifiedDetailsTab } from './UnqualifiedDetailsTab';
import { AgentTypesEnum, TableFilterTypesEnum } from '../../../../Enums';
import { HeaderUnqualifiedTable } from './HeaderUnqualifiedTable';

export const UnqualifiedLeadTableComponent = ({
  filter,
  data,
  setIsOpenReassign,
  activeItem,
  setActiveItem,
  setselectedLeadIds,
  selectedLeadIds,
  translationPath,
  parentTranslationPath,
  SelectedAction,
  setIsOpen,
  setselectedLeadItem,
  setFilter,
  defaultState
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openReply, setOpenReply] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [currentActions, setCurrentActions] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setisEdit] = useState(false);
  const [activeitem, setactiveitem] = useState(false);

  const ActionsTabel = [{
    enum: TableActions.openFile.key,
  },
  {
    enum: TableActions.reassignAgent.key,
  }, {
    enum: TableActions.close.key,
  }];

  const ActionsOpen = [{
    enum: TableActions.openFile.key,
  }
  ];
  const ActionsTabelWithOutreassignA = [{
    enum: TableActions.openFile.key,
  },
  {
    enum: TableActions.close.key,
  }];
  const pathName = window.location.pathname.split('/home/')[1];
  const handleActiveItem = useCallback(async (activityId, key) => {
    if (key === TableActions.replyText.key) {
      setOpenReply(true);
      setIsLoading(true);
      const res = await GetActivityById(activityId);
      if (!(res && res.status && res.status !== 200)) {
        setActiveItem(res);
        setIsLoading(false);
      }
    } else {
      setOpen(true);
      setisEdit(true);
      setIsLoading(true);
      const res = await GetActivityById(activityId);
      if (!(res && res.status && res.status !== 200)) setActiveItem(res);
      setIsLoading(false);
    }
  }, []);

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.preventDefault();

      if (actionEnum === TableActions.openFile.key) {
        const leadTypeToFormType = {
          Buyer: 2,
          Tenant: 2,
          Landlord: 1,
          Seller: 1,
        };
        const formType = leadTypeToFormType[item.leadType] || 0;
        GlobalHistory.push(
          `/home/leads/lead-profile-edit?formType=${formType}&id=${item.leadId}&leadClass=${item.leadType}`
        );
      } else if (actionEnum === TableActions.close.key) {
        setIsOpen(true);
        setselectedLeadIds([item.leadId]);
      } else if (actionEnum === TableActions.replyText.key) {
        handleActiveItem(item.activityId, TableActions.replyText.key);
        setOpenReply(true);
      } else if (actionEnum === TableActions.reassignAgent.key) {
        setselectedLeadItem(item);
        setIsOpenReassign(true);
        setActiveItem(item);
      }
    },
    [handleActiveItem, pathName]
  );

  const focusedRowChanged = useCallback((activeRow, item) => {
    setactiveitem(item);

    if (item === undefined)
      sideMenuIsOpenUpdate(false);
    else
      sideMenuIsOpenUpdate(true);

    const sideMenuContent = (
      <UnqualifiedDetailsTab
        activeData={item || activeitem || []}
        cardDetailsActionClicked=''
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    );
    sideMenuComponentUpdate(sideMenuContent);

    if (item && item.leadStatus) {
      if (item.leadStatus === 'Closed')
        setCurrentActions(ActionsOpen);
      else if (item.leadStatus !== 'Closed' && !['Landlord', 'Seller'].includes(item.leadType))
        setCurrentActions(ActionsTabel);
      else if (item.leadStatus !== 'Closed')
        setCurrentActions(ActionsTabelWithOutreassignA);
    }
  }, [activeitem, parentTranslationPath, translationPath]);

  const getIsSelectedAll = useCallback(
    () => {
      const returnSelect = (selectedLeadIds &&
        data && data.findIndex((item) => !selectedLeadIds.includes(item.leadId)) === -1) || false;
      return returnSelect;
    }
  );

  const getIsSelected = useCallback(
    (row) =>
      selectedLeadIds && selectedLeadIds.findIndex((item) => item === row.leadId) !== -1,
    [selectedLeadIds]
  );

  const onSelectAllClicked = () => {
    const cardItmes = [];
    if (!getIsSelectedAll()) {
      data.map((item) => {
        selectedLeadIds.push(item);
        cardItmes.push({ ...item });
      });
    } else {
      data.map((item) => {
        if (getIsSelected(item)) {
          const isSelectedIndex = selectedLeadIds.findIndex(
            (element) => element === item.leadId
          );
          if (isSelectedIndex !== -1) selectedLeadIds.splice(isSelectedIndex, 1);
        }
      });
    }
    const uniqueLeadIds = [...new Set(cardItmes.map((tea) => tea.leadId))];
    setselectedLeadIds(uniqueLeadIds);
  };

  const onSelectClicked = useCallback(
    (row) => {
      if (row) {
        setselectedLeadItem((items) => {
          const index = items.findIndex((item) => item.leadId === row.leadId);
          if (index !== -1) items.splice(index, 1);
          else items.push(row);
          return [...items];
        });
        setselectedLeadIds((items) => {
          const index = items.findIndex((item) => item === row.leadId);
          if (index !== -1) items.splice(index, 1);
          else items.push(row.leadId);
          return [...items];
        });
      }
    },
    []
  );

  const onFilterValuesChanged = (newValue) => {
    const localFilterDto = filter || {};
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.displayPath || item.searchableKey)
        .map((item) => {
          if (item.displayPath === 'leadName')
            localFilterDto.contactName = item.value || null;
          if (item.displayPath === 'leadId')
            localFilterDto.leadId = item.value || null;
          if (item.displayPath === 'remarks')
            localFilterDto.remarks = item.value || null;
          if (item.displayPath === 'reasonName')
            localFilterDto.reasonName = item.value || null;
          if (item.displayPath === 'reasonName')
            localFilterDto.reasonName = item.value || null;
          if (item.displayPath === 'leadType')
            localFilterDto.leadClassId = item.value || null;
          if (item.displayPath === 'unqualifiedBy')
            localFilterDto.unqualifiedLeadBy = item.value || null;
          if (item.displayPath === 'contactPreferences')
            localFilterDto.contactPreference = item.value || null;
          if (item.displayPath === 'numberOfUnqualified')
            localFilterDto.numberOfUnqualifiedLead = item.value || null;
          if (item.displayPath === 'creationDate')
            localFilterDto.creationDate = moment(item.value).add(1, 'days').toISOString();
          if (item.displayPath === 'unqualifiedDate') 
            localFilterDto.unqualifiedDate = moment(item.value).add(1, 'days').toISOString();
          if (item.value)
            localFilterDto[item.displayPath] = item.value || null;
          return undefined;
        });
    }
    setFilter(() => ({ ...defaultState, ...localFilterDto }));
  };

  useEffect(() => {
    if (sortBy) {
      setFilter((item) => ({
        ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy, pageIndex: 0
      }));
    }
  }, [sortBy]);

  useEffect(() => {
    if (SelectedAction.action !== null)
      setCurrentActions([]);
    else
      setCurrentActions(ActionsTabel);
  }, [SelectedAction]);

  useEffect(() => {
    setTableFilterData(
      HeaderUnqualifiedTable.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType: (column.isDate && TableFilterTypesEnum.datePicker.key) || (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) || TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input
      }))
    );
  }, []);

  return (
    <div>
      <Tables
        data={data}
        headerData={HeaderUnqualifiedTable}
        defaultActions={currentActions}
        itemsPerPage={filter.pageSize}
        isWithFilter
        filterData={tableFilterData}
        FilterDisabledButton
        onFilterValuesChanged={onFilterValuesChanged}
        activePage={filter.pageIndex}
        selectAllOptions={SelectedAction.action !== null &&
        {
          getIsSelected,
          withCheckAll: true,
          onSelectClicked,
          onSelectAllClicked,
          isSelectAll: getIsSelectedAll(),
          selectedRows: selectedLeadIds,
          disabledRows: [],
        } ||
          undefined}
        translationPath={translationPath}
        setSortBy={setSortBy}
        optionFilterName={
          Object.values(AgentTypesEnum)
        }
        focusedRowChanged={focusedRowChanged}
        parentTranslationPath={parentTranslationPath}
        actionsOptions={{ onActionClicked: tableActionClicked, }}
      />

      {open && (
        <ActivitiesManagementDialog
          open={open}
          isGeneralDialog
          activeItem={activeItem}
          isLoading={isLoading}
          isEdit={edit}
          onSave={() => {
            setOpen(false);
            setActiveItem(null);
            setisEdit(false);
          }}
          close={() => {
            setActiveItem(null);
            setOpen(false);
            setisEdit(false);
          }}
          translationPath={translationPath}
          parentTranslationPath='ActivitiesView'
        />
      )}
      {openReply && (
        <ReplyActivityDialog
          isLoading={isLoading}
          open={openReply}
          close={() => {
            setActiveItem(null);
            setOpenReply(false);
          }}
          activeItem={activeItem}
          onSave={() => {
            setOpenReply(false);
            setActiveItem(null);
          }}
          translationPath={translationPath}
          parentTranslationPath='ActivitiesView'
        />
      )}
    </div>
  );
};

UnqualifiedLeadTableComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  filter: PropTypes.shape({
    contactName: PropTypes.string,
    leadId: PropTypes.number,
    remarks: PropTypes.string,
    reasonName: PropTypes.string,
    unqualifiedLeadBy: PropTypes.string,
    contactPreference: PropTypes.string,
    numberOfUnqualifiedLead: PropTypes.number,
    creationDate: PropTypes.string,
    unqualifiedDate: PropTypes.string,
    pageSize: PropTypes.number,

  }).isRequired,
  setIsOpenReassign: PropTypes.func.isRequired,
  activeItem: PropTypes.string.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  setselectedLeadIds: PropTypes.func.isRequired,
  selectedLeadIds: PropTypes.instanceOf(Array).isRequired,
  translationPath: PropTypes.string.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  SelectedAction: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setselectedLeadItem: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  defaultState: PropTypes.shape({
    contactName: PropTypes.string,
    leadId: PropTypes.number,
    remarks: PropTypes.string,
    reasonName: PropTypes.string,
    unqualifiedLeadBy: PropTypes.string,
    contactPreference: PropTypes.string,
    numberOfUnqualifiedLead: PropTypes.number,
    creationDate: PropTypes.string,
    unqualifiedDate: PropTypes.string,
    pageSize: PropTypes.number,
  }).isRequired,
};
