import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent } from '../../../../../../Components';
import { ReminderTypeEnum, } from '../../../../../../Enums';

export const NotificationClientComponent = ({
  parentTranslationPath,
  translationPath,
  setClientNOT,
  setStateNotification,
  ClientNOT
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [selected, setSelected] = useState([]);
  const clientNotificationOptions = Object.values(ReminderTypeEnum).filter(item=>item.value !== 'Notification') || []
  useEffect(()=>{
    if(ClientNOT){

      setSelected(
        [...ClientNOT]
        )
      }
    
  },[ClientNOT])


  return (
    <div>
      <AutocompleteComponent
        idRef='Notification-by-Created-fromRef'
        selectedValues={selected || []}
        multiple
        data={clientNotificationOptions}
        chipsLabel={(option) => t(`${option.value || ''}`)}
        displayLabel={(option) => t(`${option.value || ''}`)}
        getOptionSelected={(option) =>
          selected.findIndex((item) => item.key === option.key) !== -1 || ''}
        withoutSearchButton
        inputPlaceholder={t(`${translationPath}notification-by`)}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setStateNotification(
            newValue &&
            newValue.map((x) => ({
              notifyBy: x.key,
            }))
          );
          setSelected(
            newValue &&
            newValue.map((x) => ({
              key: x.key,
              value: x.value,
            }))
          );
          setClientNOT(
            newValue &&
            newValue.map((x) => ({
              key: x.key,
              value: x.value,
            }))
          );
        }}
      />
    </div>
  );
};

NotificationClientComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setClientNOT: PropTypes.func.isRequired,
  setStateNotification: PropTypes.func.isRequired,
  Data: PropTypes.func.isRequired,
};
