import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { useLocation } from "react-router-dom";
import { ConvertJsonV2Component, Spinner, TabsComponent } from '../../../../../../Components';
import {
  unitDetailsPut,
  unitDetailsGet,
  GetAllFormFieldTabsByFormId,
  GetAllUnitLeadOwners
} from '../../../../../../Services';
import {
  bottomBoxComponentUpdate,
  FormErrorsHandler,
  formItemsBuilder,
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import { LookupsRules } from '../../../../../../Rule';
import { UnitsOperationTypeEnum } from '../../../../../../Enums';
import { PermissionsComponent } from '../../../../../../Components/PermissionsComponent/PermissionsComponent';
import { UnitsLeasePermissions } from '../../../../../../Permissions/Lease/UnitsLeasePermissions';
import { UnitPermissions } from '../../../../../../Permissions';
import { LandlordInfo } from './LandlordInfo/LandlordInfo';
import { PropertyInfo } from './PropertyInfo/PropertyInfo';

export const UnitInformationComponent = ({ viewType, parentTranslationPath, translationPath, save, isDetailsDialog, isActiveToSave, isOpen, isOpenChanged,
  onSave,
  sensitiveFieldsForUser,
  unitWithPolicy,
  isLoadingUnitData,
  isSensitiveLoading,
  unitEmptyFields , 
}) => {
  const { t } = useTranslation(parentTranslationPath, 'Shared');
  const location = useLocation();
  const [activeItem, setActiveItem] = useState({
    id: null,
    userTypeId: null,
  });
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenTabByOperationType, setHiddenTabByOperationType] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [formData, setFormData] = useState([]);
  const [enumsInitFinished, setEnumsInitFinished] = useState(false);
  const [errors, setErrors] = useState([]);
  const [unitInitDetails, setUnitInitDetails] = useState({});
  const [unitDetails, setUnitDetails] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [formAndTabs, setFormAndTabs] = useState([]);
  const [leaseLeadOwner, setLeaseLeadOwner] = useState(null);
  const [leadOwner, setLeadOwner] = useState(null);
  const [unitPropertyId, setUnitPropertyId] = useState(null);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [unitLeadReferredById] = useState(loginResponse && loginResponse.userId)

  const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];
  const isPropertyManagementView = (pathName === 'units-property-management/unit-profile-edit');

  const onTabChanged = (e, newTap) => {
    setEnumsInitFinished(false);
    setActiveTab(newTap);
  };
  const getAllFormFieldTabsByFormId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllFormFieldTabsByFormId({ formId: 14 });
    if (!(result && result.status && result.status !== 200)) setFormAndTabs(result[0] || []);
    else setFormAndTabs([]);
    setIsLoading(false);
  }, []);
  const getUnitDetails = useCallback(async () => {
    // setIsLoading(true);
    // const unitDetailsRes = await unitDetailsGet({ id: activeItem.id });
    // if (!(unitDetailsRes && unitDetailsRes.status && unitDetailsRes.status !== 200)) {
    //   if (unitDetailsRes && unitDetailsRes.unit)
    //     setUnitInitDetails(JSON.parse(JSON.stringify(unitDetailsRes)));
    //   setUnitDetails(unitDetailsRes);
    // } else {
    //   setUnitInitDetails({});
    //   setUnitDetails({});
    // }
    // setIsLoading(false);
  }, [activeItem]);

  const getUnitLeadOwner = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetAllUnitLeadOwners(id);
    if (!(res && res.status && res.status !== 200)) {
      setLeaseLeadOwner(res.leaseLeadOwner);
      setLeadOwner(res.leadOwner);
    } else {
      setLeaseLeadOwner(null);
      setLeadOwner(null);
    }
    setIsLoading(false);
  }, []);

  const dataHandler = useCallback(() => {
    if (formData.length === 0 && isSensitiveLoading)
      setFormData(formItemsBuilder(unitDetails.unit, formAndTabs, sensitiveFieldsForUser));
  }, [unitDetails.unit, formAndTabs, formData.length, sensitiveFieldsForUser, sensitiveFieldsForUser, isSensitiveLoading]);
  const cancelHandler = () => {
    GlobalHistory.goBack();
    // GlobalHistory.push(`view`);
  };
  const saveHandler = async () => {
    setIsSubmitted(true);
    if (onSave)
      onSave(false);
    if (errors && errors.length > 0) {
      const firstErrorTapIndex = formData.findIndex(
        (item) => item.findIndex((element) => element.field.id === errors[0].key) !== -1
      );
      if (firstErrorTapIndex !== -1 && firstErrorTapIndex !== 5) {
        setActiveTab(
          (hiddenTabByOperationType !== null &&
            firstErrorTapIndex >= hiddenTabByOperationType[0] && firstErrorTapIndex >= hiddenTabByOperationType[1] &&
            firstErrorTapIndex + 1) ||
          firstErrorTapIndex
        );
      }
      else if (unitDetails && unitDetails.unit && unitDetails.unit && (unitDetails.unit.operation_type.lookupItemName === 'SaleAndRent')) {
        if (unitDetails.unit && unitDetails.unit && !(unitDetails.unit.sale_type))
          setActiveTab(unitDetails.unit.operation_type.lookupItemName === 'SaleAndRent' ? 5 : 0);
      }
      showError(errors[0].message);
      return;
    }
    else if (unitDetails && unitDetails.unit && unitDetails.unit && (unitDetails.unit.operation_type.lookupItemName === 'SaleAndRent')) {
      if (unitDetails.unit && unitDetails.unit && !(unitDetails.unit.sale_type)) {
        setActiveTab(unitDetails.unit.operation_type.lookupItemName === 'SaleAndRent' ? 5 : 0);
        showError(t(`${translationPath}please-fill-all-required-field`));
        return;

      }
    }
    const totalItems = formData.reduce((total, items) => total + items.length, 0);
    const totalValues = Object.values(unitDetails.unit).filter(
      (item) => item !== null && item !== '' && item !== undefined && item !== ' ' && !(Object.keys(item).length === 0 && item.constructor === Object)
    ).length;
    const totalValues2 = totalValues - 2;
    const total = (totalValues2 / totalItems) * 100;
    const dataCompleted = Math.round(total);
    unitDetails.unit.data_completed = dataCompleted > 100 ? 100 : dataCompleted;
    setIsLoading(true);
    const newOwners = [];
    if (unitDetails && unitDetails.unit && (!unitDetails.unit.owner || !unitDetails.unit.owner.length) && (unitDetails.unit.lease_lead_owner || unitDetails.unit.lead_owner))
      newOwners.push((unitDetails && unitDetails.unit.lead_owner) || (unitDetails.unit.lease_lead_owner));
    const unitUpdated = { ...unitDetails.unit, owner: newOwners };
    const unitUpdateWithNewOwners = { ...unitDetails, unit: unitUpdated };

    const updatedUnitLeadOwnerId = unitDetails && unitDetails.unit && unitDetails.unit.lead_owner && unitDetails.unit.lead_owner.id;
    const updatedUnitLeaseLeadOwnerId = unitDetails && unitDetails.unit && unitDetails.unit.lease_lead_owner && unitDetails.unit.lease_lead_owner.id;
    const unitLeadOwnerId = leadOwner && leadOwner.contactId;
    const unitLeaseLeadOwnerId = leaseLeadOwner && leaseLeadOwner.contactId;

    const referredById = !((updatedUnitLeadOwnerId === unitLeadOwnerId) || !updatedUnitLeadOwnerId) || !((updatedUnitLeaseLeadOwnerId === unitLeaseLeadOwnerId) || !updatedUnitLeaseLeadOwnerId) ? unitLeadReferredById : null;

    const putResponse = await unitDetailsPut({
      id: activeItem.id,
      body: { unitJson: (newOwners.length ? unitUpdateWithNewOwners : unitDetails), rowVersion: unitDetails.rowVersion },
      referredById
    });
    if (!(putResponse && putResponse.status && putResponse.status !== 200)) {
      showSuccess(t(`${translationPath}unit-updated-successfully`));
      setReload(true);
      setIsLoading(false);
      // cancelHandler();
    } else {
      setIsLoading(false);
      let errorMsg = putResponse &&
        putResponse.data &&
        putResponse.data.Message &&
        putResponse.data.Message.split(':') &&
        putResponse.data.Message.split(':').pop()
      showError(`${t(
        `${translationPath}${(errorMsg.substring(0, errorMsg.lastIndexOf(' ')))}`
      )}${errorMsg.substring(errorMsg.lastIndexOf(' '), errorMsg.length)}`);
    }
    if (isOpenChanged) {
      isOpenChanged();

    }
  };
  const onLoadingsChanged = (value, key) => {
    setLoadings((items) => {
      const itemIndex = items.findIndex((item) => item.key === key);
      if (value) {
        const addItem = {
          key,
          value,
        };
        if (itemIndex !== -1) items[itemIndex] = addItem;
        else items.push(addItem);
      } else if (itemIndex !== -1) items.splice(itemIndex, 1);
      return [...items];
    });
  };
  const onItemChanged = (item, index) => (newValue, itemIndex, itemKey, parentItemKey) => {
    setFormData((elements) => {
      if (parentItemKey) {
        if (itemIndex !== undefined) {
          elements[
            (hiddenTabByOperationType !== null &&
              activeTab >= hiddenTabByOperationType[0] && activeTab >= hiddenTabByOperationType[1] &&
              activeTab + 1) ||
            activeTab
          ][itemIndex][parentItemKey][itemKey] = newValue;
        } else {
          elements[
            (hiddenTabByOperationType !== null &&
              activeTab >= hiddenTabByOperationType[0] && activeTab >= hiddenTabByOperationType[1] &&
              activeTab + 1) ||
            activeTab
          ][index][parentItemKey][itemKey] = newValue;
        }
      } else if (itemIndex) {
        elements[
          (hiddenTabByOperationType !== null &&
            activeTab >= hiddenTabByOperationType[0] && activeTab >= hiddenTabByOperationType[1] &&
            activeTab + 1) ||
          activeTab
        ][itemIndex][itemKey] = newValue;
      } else {
        elements[
          (hiddenTabByOperationType !== null &&
            activeTab >= hiddenTabByOperationType[0] && activeTab >= hiddenTabByOperationType[1] &&
            activeTab + 1) ||
          activeTab
        ][index][itemKey] = newValue;
      }
      return [...elements];
    });
  };
  const onValueChanged = (item) => (newValue, itemIndex, itemKey) => {
    setUnitDetails((items) => {
      if (itemKey) items.unit[itemKey] = newValue;
      else if ((itemIndex || itemIndex === 0) && itemIndex !== -1) {
        items.unit[
          formData[
            (hiddenTabByOperationType !== null &&
              activeTab >= hiddenTabByOperationType[0] && activeTab >= hiddenTabByOperationType[1] &&
              activeTab + 1) ||
            activeTab
          ][itemIndex].field.id
        ] = newValue;
      } else items.unit[item.field.id] = newValue;
      return { ...items };
    });
  };
  const lookupInit = useCallback(() => {
    setIsLoading(true);
    const result = LookupsRules(
      formData[
      (hiddenTabByOperationType !== null &&
        activeTab >= hiddenTabByOperationType[0] && activeTab >= hiddenTabByOperationType[1] &&
        activeTab + 1) ||
      activeTab
      ],
      unitDetails.unit,
      onLoadingsChanged,
      sensitiveFieldsForUser
    );
    setFormData((items) => {
      items.splice(
        (hiddenTabByOperationType !== null &&
          activeTab >= hiddenTabByOperationType[0] && activeTab >= hiddenTabByOperationType[1] &&
          activeTab + 1) ||
        activeTab,
        1,
        result
      );
      return [...items];
    });
    setIsLoading(false);
  }, [formData, hiddenTabByOperationType, activeTab, unitDetails.unit]);
  useEffect(() => {
    if (formData.length > 0 && unitDetails.unit)
      setErrors(FormErrorsHandler(formData, unitDetails.unit));
  }, [unitDetails, formData]);
  useEffect(() => {
    if (!enumsInitFinished && formData.length > 0) {
      setEnumsInitFinished(true);
      lookupInit();
    }
  }, [activeTab, enumsInitFinished, formData.length, lookupInit]);
  useEffect(() => {
    if (unitDetails.unit) dataHandler();
  }, [unitDetails.unit, dataHandler, sensitiveFieldsForUser, isSensitiveLoading]);
  useEffect(() => {
    if (formAndTabs.length > 0) getUnitDetails();
  }, [formAndTabs, getUnitDetails]);
  useEffect(() => {
    if (activeItem && activeItem.userTypeId) getAllFormFieldTabsByFormId();
  }, [activeItem, getAllFormFieldTabsByFormId, sensitiveFieldsForUser]);
  useEffect(() => {
    if (isDetailsDialog) {
      const obj = JSON.parse(localStorage.getItem('current'));
      setActiveItem({
        id: obj.id,
        userTypeId: obj.type
      });
    } else {
      setActiveItem({
        id: GetParams('id'),
        userTypeId: GetParams('formType'),
      });

    }

  }, [location]);

  useEffect(() => {
    if (save && isActiveToSave)
      saveHandler();


  }, [save]);

  useEffect(() => {
    getUnitLeadOwner(+GetParams('id'));
  }, [getUnitLeadOwner]);
  useEffect(() => {
    if (!isOpen)
      bottomBoxComponentUpdate(
        <div className='d-flex-v-center-h-end flex-wrap'>
          <ButtonBase className='btns theme-transparent mb-2' onClick={cancelHandler}>
            <span>{t('Shared:cancel')}</span>
          </ButtonBase>
          <PermissionsComponent
            permissionsList={!isPropertyManagementView ? Object.values(UnitsLeasePermissions) : Object.values(UnitPermissions)}
            permissionsId={!isPropertyManagementView ? UnitsLeasePermissions.EditUnitDetails.permissionsId : UnitPermissions.EditUnitDetails.permissionsId}
          >
            <ButtonBase className='btns theme-solid mb-2' onClick={saveHandler}>
              <span>
                {' '}
                {t('Shared:save')}
              </span>
            </ButtonBase>
          </PermissionsComponent>
        </div>
      );
  });
  useEffect(() => {
    if (
      unitDetails.unit &&
      unitDetails.unit.operation_type &&
      unitDetails.unit.operation_type.lookupItemId === UnitsOperationTypeEnum.sale.key
    )
      setHiddenTabByOperationType(6);
    else if (
      unitDetails.unit &&
      unitDetails.unit.operation_type &&
      unitDetails.unit.operation_type.lookupItemId === UnitsOperationTypeEnum.rent.key
    )
      setHiddenTabByOperationType([3, 5]);
    else setHiddenTabByOperationType(null);
  }, [unitDetails]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    if (unitWithPolicy && unitWithPolicy.unit) {
      setUnitInitDetails(JSON.parse(JSON.stringify(unitWithPolicy)));
      setUnitDetails(unitWithPolicy);
      setUnitPropertyId(unitWithPolicy.unit?.property_name?.id);
    }
    else {
      setUnitInitDetails({});
      setUnitDetails({});
    }

  }, [unitWithPolicy]);

  return (
    <div className='units-information-wrapper childs-wrapper b-0'>
      <Spinner isActive={(isLoading|| isLoadingUnitData)} isAbsolute />
      <TabsComponent
        data={formAndTabs}
        labelInput='tab'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-curved'
        currentTab={activeTab}
        hiddenTabIndexes={(hiddenTabByOperationType !== null && hiddenTabByOperationType) || []}
        onTabChanged={onTabChanged}
      />
      <div className='tabs-content-wrapper'>
        {formData && formData &&
          formData[activeTab] &&
          formData[
            (hiddenTabByOperationType && activeTab === 4 && activeTab + 2) || (hiddenTabByOperationType !== null &&
              activeTab >= hiddenTabByOperationType[0] &&
              activeTab + 1) ||
            activeTab
          ]
            .filter(
              (item) =>
                viewType === 1 ||
                (viewType === 2 &&
                  ((unitEmptyFields && unitEmptyFields.unit && unitEmptyFields.unit[item.field.id] === null) ||
                  (unitEmptyFields && unitEmptyFields.unit && unitEmptyFields.unit[item.field.id] === undefined )||
                  (unitEmptyFields && unitEmptyFields.unit && unitEmptyFields.unit[item.field.id] === '')))
            )
            .map((item, index) => (
              <ConvertJsonV2Component
                key={`form${index + 1}-${activeTab}`}
                item={item}
                allItems={
                  formData[
                  (hiddenTabByOperationType && activeTab === 4 && activeTab + 2) || (hiddenTabByOperationType !== null &&
                    activeTab >= hiddenTabByOperationType[0] &&
                    activeTab + 1) ||
                  activeTab
                  ]
                }
                allItemsValues={unitDetails.unit}
                itemValue={unitDetails.unit[item.field.id] === 0 ? '0' : unitDetails.unit[item.field.id]}
                isSubmitted={isSubmitted}
                onItemChanged={onItemChanged(item, index)}
                onValueChanged={onValueChanged(item, index)}
                helperText={
                  (errors.find((element) => element.key === item.field.id) &&
                    errors.find((element) => element.key === item.field.id).message) ||
                  ''
                }
                error={errors.findIndex((element) => element.key === item.field.id) !== -1}
                isLoading={
                  loadings.findIndex(
                    (element) => element.key === item.field.id && element.value
                  ) !== -1
                }
                onLoadingsChanged={onLoadingsChanged}
                reload={reload}
                sensitiveFieldsForUser={sensitiveFieldsForUser}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            ))}
      </div>
      <div className='information-container'>
        {leaseLeadOwner && (
          <LandlordInfo
            leaseLeadOwnerData={leaseLeadOwner}
            unitDetails={unitInitDetails}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        {unitPropertyId && (
          <PropertyInfo
            propertyId={unitPropertyId}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
      </div>
    </div>
  );
};

UnitInformationComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  viewType: PropTypes.number.isRequired,
  save: PropTypes.bool,
  isDetailsDialog: PropTypes.bool,
  isActiveToSave: PropTypes.bool,
  isOpen: PropTypes.bool,
  isOpenChanged: PropTypes.func,
  onSave: PropTypes.func,
};

UnitInformationComponent.defaultProps = {
  save: false,
  isDetailsDialog: false,
  isActiveToSave: false,
  isOpen: false,
  isOpenChanged: undefined,
  onSave: undefined,

};

