import * as signalR from '@microsoft/signalr';
import { config } from '../config/config';

const getSessionToken = () => {
  const sessionData = JSON.parse(localStorage.getItem('session'));
  return sessionData ? sessionData.token : '';
};

const url = '/WsCrmDfm/NotificationHub';

export const CrmDfmNotification = 'NotificationActivityListener';

export const CrmDfmNotificationHub = (() => {
  let hubConnectionInstance = null;

  return () => {
    if (!hubConnectionInstance) {
      const token = getSessionToken();

      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${config.server_address}${url}?authorization=${token}&source=${config.source}`, {
          transport: signalR.HttpTransportType.WebSockets,
          withCredentials: false,
        })
        .configureLogging(signalR.LogLevel.None)
        .build();

      hubConnection.start()
        .catch((err) => {
          console.error(err.toString());
        });

      hubConnection.on('CrmDfmNotification', () => {
        // Handle your event here
      });

      hubConnectionInstance = hubConnection;
    }

    return hubConnectionInstance;
  };
})();

// Refactoring Done 2023
