import React, {
 useCallback, useEffect, useState, useReducer
} from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import '../MasterStyleDashborads/MasterStyleDashboards.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { showError, GlobalTranslate } from '../../../../Helper';
import {
  GetUserPolicyService,
  MicrosoftToken,
  UsersIdsForPolicy,
} from '../../../../Services';
import {
  GetApplicationUserById,
  GetUserTeamsInfo,
} from '../../../../Services/userServices';
import { SpinnerDashboards } from '../SpinnerDashboards/SpinnerDashboards';
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
} from '../../../../Components';

import { useTitle } from '../../../../Hooks';
import { DateRangePickerComponent } from '../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { PermissionDeniedLayout } from '../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout';
import { CellsSpinner } from '../../../../SharedComponents/CellSpinnerComponent/CellsSpinner';
import { ButtonBase } from '@material-ui/core';

const parentTranslationPath = 'LeadsView';
const translationPath = '';

export const MainDashboardFilterDate = ({ id, embedUrl }) => {
  useTitle(GlobalTranslate.t('Dashboard:contact-center'));

  const [userid] = useState(
    JSON.parse(localStorage.getItem('session')).userId || null
  );
  const [Cityid, setCityid] = useState([]);
  const [communityid, setcommunityid] = useState([]);
  const [Subcommunityid, setsubcommunityid] = useState([]);
  const [districtid, setdistrictid] = useState([]);
  const [Propertyid, setPropertyid] = useState([]);
  const [MediaDetail, setMediaDetail] = useState([]);

  const [Operatormethodofcontact, setOperatormethodofcontact] = useState('All');
  const [Methodofcontact, setMethodofcontact] = useState([]);

  const [ShowReport, setShowReport] = useState(false);
  const [report, setReport] = useState();

  const [OperatorMyTeam, setOperatorMyTeam] = useState('All');

  const [OperatorMediadetail, setOperatorMediadetail] = useState('All');

  const [teamid, setTeamid] = useState([]);

  const [userForPolicy, setuserForPolicy] = useState([]);

  const [
    userForPolicymyBusinessCreatedBy,
    setuserForPolicymyBusinessGroupUsersCreatedBy,
  ] = useState([]);
  const [userForPolicyMyUserCreatedBy, setuserForPolicyMyUserCreatedBy] =
    useState([]);
  const [
    userForPolicymyTeamUsersCreatedBy,
    setuserForPolicymyTeamUsersCreatedBy,
  ] = useState([]);
  const [
    userForPolicymyBranchUsersCreatedBy,
    setuserForPolicymyBranchUsersCreatedBy,
  ] = useState([]);
  const [
    userForPolicymyManageUsersCreatedBy,
    setuserForPolicymyManageUsersCreatedBy,
  ] = useState([]);
  const [
    userForPolicyspecificUsersCreatedBy,
    setuserForPolicyspecificUsersCreatedBy,
  ] = useState([]);
  const [OperatorCreatedBy, setOperatorCreatedBy] = useState('All');

  const [
    userForPolicymyBusinessGroupUsersReferredBy,
    setuserForPolicymyBusinessGroupUsersReferredBy,
  ] = useState([]);
  const [
    userForPolicymyBranchUsersReferredBy,
    setuserForPolicymyBranchUsersReferredBy,
  ] = useState([]);

  const [
    userForPolicymyBranchUsersReferredByisEmpty,
    setuserForPolicymyBranchUsersReferredByisEmpty,
  ] = useState([]);

  const [
    userForPolicymyManageUsersReferredBy,
    setuserForPolicymyManageUsersReferredBy,
  ] = useState([]);
  const [userForPolicyMyUserReferredBy, setuserForPolicyMyUserReferredBy] =
    useState([]);
  const [
    userForPolicyspecificUsersReferredBy,
    setuserForPolicyspecificUsersReferredBy,
  ] = useState([]);
  const [
    userForPolicymyTeamUsersReferredBy,
    setuserForPolicymyTeamUsersReferredBy,
  ] = useState([]);
  const [OperatorReferredBy, setOperatorReferredBy] = useState('All');

  const [userForPolicyMyUserReferredTo, setuserForPolicyMyUserReferredTo] =
    useState([]);
  const [
    userForPolicyspecificUsersReferredTo,
    setuserForPolicyspecificUsersReferredTo,
  ] = useState([]);
  const [
    userForPolicymyTeamUsersReferredTo,
    setuserForPolicymyTeamUsersReferredTo,
  ] = useState([]);

  const [
    userForPolicyBlankValueReferredTo,
    setuserForPolicyBlankValueReferredTo,
  ] = useState(false);
  const [
    userForPolicyisEmptyAndReferredByMyBranch,
    setuserForPolicyisEmptyAndReferredByMyBranch,
  ] = useState(false);

  const [BlankValueReferredTo, setBlankValueReferredTo] = useState('');

  const [
    userForPolicymyBusinessGroupUsersReferredTo,
    setuserForPolicymyBusinessGroupUsersReferredTo,
  ] = useState([]);
  const [
    userForPolicymyManageUsersReferredTo,
    setuserForPolicymyManageUsersReferredTo,
  ] = useState([]);
  const [
    userForPolicymyBranchUsersReferredTo,
    setuserForPolicymyBranchUsersReferredTo,
  ] = useState([]);
  const [
    userForPolicymyTeamLeadUsersReferredTo,
    setuserForPolicymyTeamLeadUsersReferredTo,
  ] = useState([]);
  const [
    userForPolicymyTeamLeadUsersReferredBy,
    setuserForPolicymyTeamLeadUsersReferredBy,
  ] = useState([]);
  const [
    userForPolicymyTeamLeadUsersCreatedBy,
    setuserForPolicymyTeamLeadUsersCreatedBy,
  ] = useState([]);

  const [OperatorReferredto, setOperatorReferredto] = useState('All');
  const [OperatorReferredbybtanch, setOperatorReferredbybtanch] =
    useState('All');

  const [render, setRender] = useState(false);
  const [policyrener, setpolicyrener] = useState(null);

  const [LeadstatusOperator, setLeadstatusOperator] = useState('All');
  const [leadsStatus, setStatus] = useState('');

  const [brancid, setBranchid] = useState(null);
  const [datefilters, setdatefilter] = useState(true);
  const [LeadClass, setLeadClass] = useState();

  const [Datetime, setDatetime] = useState(30);

  const [monthsAgo, setMonthsAgo] = useState(1);

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate() - 7));
  const count2 = new Date(new Date().setDate(today.getDate()));

  const datetimes = moment(count).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
  const datetimes2 = moment(count2).format('YYYY-MM-DDTHH:mm:ss');

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: startDate || null,
    endDate: endDate || null,
    key: 'selection',
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const [vlueapper, setvlueapper] = useState(false);
  const [datefiltersActivity, setdatefilteractivity] = useState(false);
  const [ShowReport2, setShowReport2] = useState(false);
  const [datepicker, setdatepicker] = useState();

  const [salestransactions, setsalestransactions] = useState(false);
  const [Activity, setActivity] = useState(false);
  const [Leads, setLeads] = useState(false);
  const [leasetransaction, setleasetransaction] = useState(false);

  const [DateAppaer, setDateAppaer] = useState(false);

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss')) ||
        datetimes,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss')) ||
        datetimes2,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const getUserById = useCallback(async () => {
    const res = await GetApplicationUserById(userid);
    if (!(res && res.status && res.status !== 200)) setBranchid(res.branchId);
    else setBranchid(null);
  }, [userid]);

  const advancedFilter = {
    $schema: 'http://powerbi.com/product/schema#advanced',
    target: {
      table: 'LeadsView',
      column: datefilters ? 'CreatedOn' : 'LeadAssignDate',
    },
    filterType: 0,
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: data.fromDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const advancedFilterActivity = {
    $schema: 'http://powerbi.com/product/schema#advanced',
    target: {
      table: 'LM_QA_Activity_Category_Leads',
      column: 'ActivityUpdateonDate',
    },
    filterType: 0,
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: data.fromDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedFilterLeaseTransaction = {
    $schema: 'http://powerbi.com/product/schema#advanced',
    target: {
      table: 'View_LeaseTransactionsTenantLandLord',
      column: 'LeaseTransactionDate',
    },
    filterType: 0,
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: data.fromDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedFilterSalesTransaction = {
    $schema: 'http://powerbi.com/product/schema#advanced',
    target: {
      table: 'View_SalesTransactionsSellerBuyer',
      column: 'SalesTransactionDate',
    },
    filterType: 0,
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: data.fromDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const Filterbrancid = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'LeadsView',
      column: 'referredby_BranchId',
    },
    operator: OperatorReferredbybtanch,
    values: [brancid],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  const AddressIds = {
    $schema: 'http://powerbi.com/product/schema#advanced',
    target: {
      table: 'LeadsView',
      column: 'addressIds',
    },
    logicalOperator: 'Or',
    conditions: [
      {
        operator: 'Contains',
        value: Cityid[0] || null,
      },
      {
        operator: 'Contains',
        value: Cityid[1] || null,
      },
      {
        operator: 'Contains',
        value: Cityid[2] || null,
      },
      {
        operator: 'Contains',
        value: Cityid[3] || null,
      },
      {
        operator: 'Contains',
        value: Cityid[4] || null,
      },
      {
        operator: 'Contains',
        value: communityid[0] || null,
      },
      {
        operator: 'Contains',
        value: communityid[1] || null,
      },
      {
        operator: 'Contains',
        value: communityid[2] || null,
      },
      {
        operator: 'Contains',
        value: communityid[3] || null,
      },
      {
        operator: 'Contains',
        value: communityid[4] || null,
      },
      {
        operator: 'Contains',
        value: Subcommunityid[0] || null,
      },
      {
        operator: 'Contains',
        value: Subcommunityid[1] || null,
      },
      {
        operator: 'Contains',
        value: Subcommunityid[2] || null,
      },
      {
        operator: 'Contains',
        value: Subcommunityid[3] || null,
      },
      {
        operator: 'Contains',
        value: Subcommunityid[4] || null,
      },
      {
        operator: 'Contains',
        value: districtid[0] || null,
      },
      {
        operator: 'Contains',
        value: districtid[1] || null,
      },
      {
        operator: 'Contains',
        value: districtid[2] || null,
      },
      {
        operator: 'Contains',
        value: districtid[3] || null,
      },
      {
        operator: 'Contains',
        value: districtid[4] || null,
      },
      {
        operator: 'Contains',
        value: Propertyid[0] || null,
      },
      {
        operator: 'Contains',
        value: Propertyid[1] || null,
      },
      {
        operator: 'Contains',
        value: Propertyid[2] || null,
      },
      {
        operator: 'Contains',
        value: Propertyid[3] || null,
      },
      {
        operator: 'Contains',
        value: Propertyid[4] || null,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const FilterMediadetail = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'LeadsView',
      column: 'Media detail',
    },
    operator: OperatorMediadetail,
    values: MediaDetail,
    filterType: models.FilterType.BasicFilter,
  };
  const Filtermethodofcontact = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'LeadsView',
      column: 'method of contact',
    },
    operator: Operatormethodofcontact,
    values: Methodofcontact,
    filterType: models.FilterType.BasicFilter,
  };
  const Filterreferredto = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'LeadsView',
      column: 'referredtoID',
    },
    operator: OperatorReferredto,
    values: [
      ...userForPolicymyTeamUsersReferredTo,
      ...userForPolicymyBusinessGroupUsersReferredTo,
      ...userForPolicymyBranchUsersReferredTo,
      ...userForPolicymyManageUsersReferredTo,
      ...userForPolicyMyUserReferredTo,
      ...userForPolicyspecificUsersReferredTo,
      ...userForPolicymyTeamLeadUsersReferredTo,
      // BlankValueReferredTo,
    ],

    filterType: models.FilterType.BasicFilter,
  };
  const FilteCreatedBy = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'LeadsView',
      column: 'CreatedByID',
    },
    operator: OperatorCreatedBy,
    values: [
      ...userForPolicyMyUserCreatedBy,
      ...userForPolicyspecificUsersCreatedBy,
      ...userForPolicymyBusinessCreatedBy,
      ...userForPolicymyTeamUsersCreatedBy,
      ...userForPolicymyTeamLeadUsersCreatedBy,
      ...userForPolicymyManageUsersCreatedBy,
      ...userForPolicymyBranchUsersCreatedBy,
    ],

    filterType: models.FilterType.BasicFilter,
  };
  const Filtereferredby = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'LeadsView',
      column: 'referredbyID',
    },
    operator: OperatorReferredBy,
    values: [
      ...userForPolicymyBusinessGroupUsersReferredBy,
      ...userForPolicymyBranchUsersReferredBy,
      ...userForPolicymyManageUsersReferredBy,
      ...userForPolicyMyUserReferredBy,
      ...userForPolicyspecificUsersReferredBy,
      ...userForPolicymyTeamUsersReferredBy,
      ...userForPolicymyTeamLeadUsersReferredBy,
      // ...userForPolicymyBranchUsersReferredByisEmpty,
    ],

    filterType: models.FilterType.BasicFilter,
  };

  // const FilterStatus = {
  //   $schema: "http://powerbi.com/product/schema#basic",
  //   target: {
  //     table: "LeadsView",
  //     column: "Lead Status Name",
  //   },
  //   operator: LeadstatusOperator,
  //   values: [leadsStatus],
  //   filterType: models.FilterType.BasicFilter,
  // };

  const IdsForPolicy = useCallback(async () => {
    const res = await UsersIdsForPolicy(userid);
    if (!(res && res.status && res.status !== 200)) {
      setuserForPolicy(res && res.map((List) => List.tabName || null));

      setuserForPolicyMyUserCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'MyUserCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );
      setuserForPolicyspecificUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'specificUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyBusinessGroupUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myBusinessGroupUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyManageUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myManageUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyTeamUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myTeamUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyTeamLeadUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myTeamLeadUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyBranchUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myBranchUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyBusinessGroupUsersReferredBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myBusinessGroupUsersReferredBy')
            .map((tab) => tab.usersGUID || [])
      );
      setuserForPolicymyBranchUsersReferredBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myBranchUsersReferredBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyBranchUsersReferredByisEmpty(
        res &&
          res
            .filter((tab) => tab.tabName === 'isEmptymyBranchUsersReferredBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyManageUsersReferredBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myManageUsersReferredBy')
            .map((tab) => tab.usersGUID || [])
      );
      setuserForPolicyMyUserReferredBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'MyUserReferredBy')
            .map((tab) => tab.usersGUID || [])
      );
      setuserForPolicyspecificUsersReferredBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'specificUsersReferredBy')
            .map((tab) => tab.usersGUID || [])
      );
      setuserForPolicymyTeamUsersReferredBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myTeamUsersReferredBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyTeamLeadUsersReferredBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myTeamLeadUsersReferredBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicyMyUserReferredTo(
        res &&
          res
            .filter((tab) => tab.tabName === 'MyUserReferredTo')
            .map((tab) => tab.usersGUID || [])
      );
      setuserForPolicyspecificUsersReferredTo(
        res &&
          res
            .filter((tab) => tab.tabName === 'specificUsersReferredTo')
            .map((tab) => tab.usersGUID || [])
      );
      setuserForPolicymyTeamUsersReferredTo(
        res &&
          res
            .filter((tab) => tab.tabName === 'myTeamUsersReferredTo')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyTeamLeadUsersReferredTo(
        res &&
          res
            .filter((tab) => tab.tabName === 'myTeamLeadUsersReferredTo')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyBusinessGroupUsersReferredTo(
        res &&
          res
            .filter((tab) => tab.tabName === 'myBusinessGroupUsersReferredTo')
            .map((tab) => tab.usersGUID || [])
      );
      setuserForPolicymyManageUsersReferredTo(
        res &&
          res
            .filter((tab) => tab.tabName === 'myManageUsersReferredTo')
            .map((tab) => tab.usersGUID || [])
      );
      setuserForPolicymyBranchUsersReferredTo(
        res &&
          res
            .filter((tab) => tab.tabName === 'myBranchUsersReferredTo')
            .map((tab) => tab.usersGUID || [])
      );
    } else {
      setuserForPolicymyBusinessGroupUsersReferredBy([]);
      setuserForPolicymyBranchUsersReferredBy([]);
      setuserForPolicymyManageUsersReferredBy([]);
      setuserForPolicyMyUserReferredBy([]);
      setuserForPolicyspecificUsersReferredBy([]);
      setuserForPolicymyTeamUsersReferredBy([]);
      setuserForPolicymyTeamLeadUsersReferredBy([]);
      setuserForPolicymyBranchUsersReferredByisEmpty([]);

      setuserForPolicymyBusinessGroupUsersCreatedBy([]);
      setuserForPolicyMyUserCreatedBy([]);
      setuserForPolicyspecificUsersCreatedBy([]);
      setuserForPolicymyTeamUsersCreatedBy([]);
      setuserForPolicymyTeamLeadUsersCreatedBy([]);
      setuserForPolicymyManageUsersCreatedBy([]);
      setuserForPolicymyBranchUsersCreatedBy([]);

      setuserForPolicyMyUserReferredTo([]);
      setuserForPolicyspecificUsersReferredTo([]);
      setuserForPolicymyTeamUsersReferredTo([]);
      setuserForPolicymyBusinessGroupUsersReferredTo([]);
      setuserForPolicymyManageUsersReferredTo([]);
      setuserForPolicymyBranchUsersReferredTo([]);
      setuserForPolicymyTeamLeadUsersReferredTo([]);
    }
  }, [userid]);

  const getUserTemasById = useCallback(async () => {
    const res = await GetUserTeamsInfo(userid, false);
    if (!(res && res.status && res.status !== 200))
      setTeamid(res && res.map((List) => List.teamsId || null));
    else setTeamid([]);
  }, [userid]);

  const getUserPolicyById = useCallback(async () => {
    const response = await GetUserPolicyService(userid);
    if (!(response && response.status && response.status !== 200)) {
      setpolicyrener(response.policyJson);

      setuserForPolicyBlankValueReferredTo(
        (response &&
          response &&
          response.policyJson.leads.referredTo.isEmpty) ||
          false
      );
      setuserForPolicyisEmptyAndReferredByMyBranch(
        (response &&
          response &&
          response.policyJson.leads.referredTo.isEmptyAndReferredByMyBranch) ||
          false
      );
      setsubcommunityid(
        response &&
          response &&
          response.policyJson.leads.address.subCommunity.map(
            (List) => List.lookupItemId || ''
          )
      );
      setPropertyid(
        response &&
          response &&
          response.policyJson.leads.address.property.map(
            (List) => List.propertyId || ''
          )
      );
      setMediaDetail(
        response &&
          response &&
          response.policyJson.leads.mediaDetails.map(
            (List) => List.lookupItemName || ''
          )
      );
      setMethodofcontact(
        response &&
          response &&
          response.policyJson.leads.methodOfContact.map(
            (List) => List.lookupItemName || ''
          )
      );

      setCityid(
        response &&
          response &&
          response.policyJson.leads.address.city.map(
            (List) => List.lookupItemId || null
          )
      );
      setcommunityid(
        response &&
          response &&
          response.policyJson.leads.address.community.map(
            (List) => List.lookupItemId || null
          )
      );
      setdistrictid(
        response &&
          response &&
          response.policyJson.leads.address.district.map(
            (List) => List.lookupItemId || null
          )
      );

      setStatus(
        (response &&
          response &&
          response.policyJson.leads.dataDetails.leadStatus) ||
          ''
      );
    } else
      setpolicyrener(null);
  }, [userid]);

  let selectedFilter;

  switch (true) {
    case Leads:
      selectedFilter = advancedFilter;
      break;
    case Activity:
      selectedFilter = advancedFilterActivity;
      break;
    case leasetransaction:
      selectedFilter = advancedFilterLeaseTransaction;
      break;
    case salestransactions:
      selectedFilter = advancedFilterSalesTransaction;
      break;
    default:
      selectedFilter = advancedFilter;
  }

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport('');
      showError('Contact Your Bi Admin For Help');
    }
  };

  useEffect(() => {
    getUserPolicyById();
    IdsForPolicy();
    getUserTemasById();
    getUserById();
  }, []);

  useEffect(() => {
    if (MediaDetail && MediaDetail.length === 0)
      setOperatorMediadetail('All');
     else setOperatorMediadetail('In');
    if (Methodofcontact && Methodofcontact.length === 0)
      setOperatormethodofcontact('All');
     else setOperatormethodofcontact('In');

    if (
      // userForPolicyBlankValueReferredTo ||
      userForPolicyisEmptyAndReferredByMyBranch
    )
      setBlankValueReferredTo(null);

    if (
      (userForPolicy && userForPolicy.includes('MyUserReferredTo')) ||
      (userForPolicy && userForPolicy.includes('specificUsersReferredTo')) ||
      (userForPolicy && userForPolicy.includes('myTeamUsersReferredTo')) ||
      (userForPolicy &&
        userForPolicy.includes('myBusinessGroupUsersReferredTo')) ||
      (userForPolicy && userForPolicy.includes('myManageUsersReferredTo')) ||
      (userForPolicy && userForPolicy.includes('myBranchUsersReferredTo')) ||
      (userForPolicy && userForPolicy.includes('myTeamLeadUsersReferredTo')) ||
      userForPolicyisEmptyAndReferredByMyBranch
    )
      setOperatorReferredto('In');
     else
      setOperatorReferredto('All');

    if (userForPolicyisEmptyAndReferredByMyBranch)
      setOperatorReferredbybtanch('In');
     else
      setOperatorReferredbybtanch('All');

    if (
      (userForPolicy && userForPolicy.includes('MyUserCreatedBy')) ||
      (userForPolicy && userForPolicy.includes('specificUsersCreatedBy')) ||
      (userForPolicy && userForPolicy.includes('myTeamUsersCreatedBy')) ||
      (userForPolicy &&
        userForPolicy.includes('myBusinessGroupUsersCreatedBy')) ||
      (userForPolicy && userForPolicy.includes('myManageUsersCreatedBy')) ||
      (userForPolicy && userForPolicy.includes('myBranchUsersCreatedBy')) ||
      (userForPolicy && userForPolicy.includes('myTeamLeadUsersCreatedBy'))
    )
      setOperatorCreatedBy('In');
     else
      setOperatorCreatedBy('All');

    if (
      (userForPolicy && userForPolicy.includes('MyUserReferredBy')) ||
      (userForPolicy && userForPolicy.includes('specificUsersReferredBy')) ||
      (userForPolicy && userForPolicy.includes('myTeamUsersReferredBy')) ||
      (userForPolicy &&
        userForPolicy.includes('myBusinessGroupUsersReferredBy')) ||
      (userForPolicy && userForPolicy.includes('myManageUsersReferredBy')) ||
      (userForPolicy && userForPolicy.includes('myBranchUsersReferredBy')) ||
      (userForPolicy && userForPolicy.includes('myTeamLeadUsersReferredBy'))

      // ||
      // (userForPolicy &&
      //   userForPolicy.includes("isEmptymyBranchUsersReferredBy"))
    )
      setOperatorReferredBy('In');
     else
      setOperatorReferredBy('All');

    if (leadsStatus === 'close')
      setStatus('Closed');

    if (leadsStatus === '' || leadsStatus === 'both')
      setLeadstatusOperator('All');
     else
      setLeadstatusOperator('In');
  }, [MediaDetail, Methodofcontact, userForPolicy]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  
  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
        console.log(true);
      })
      .catch((error) => {
        console.log(false);
      });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      Microsoft(false);
    }, 1500);
  }, []);

  return (
    <div className='dashboardMain-PowerBIEmbed'>
      {render && policyrener ? (
        <div>
          <SpinnerDashboards isActive={!ShowReport} isAbsolute />

          <div className='MainFiltersBi2'>
            {DateAppaer && (
              <div>
                <CellsSpinner isActive={!ShowReport2} isAbsolute />
                <div className='selecterbi2'>
                  {!Leads ? (
                    <div>
                      {salestransactions || leasetransaction ? (
                        <SelectComponet
                          emptyItem={{
                            text: salestransactions ?
                              'Sales Transactions' :
                              'Lease Transactions',
                            isDisabled: true,
                          }}
                          valueInput='key'
                          textInput='value'
                          themeClass='theme-transparent'
                          wrapperClasses='w-auto'
                          idRef='Date_Select'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      ) : (
                        <SelectComponet
                          emptyItem={{
                            text: 'Activity update on',
                            isDisabled: true,
                          }}
                          valueInput='key'
                          textInput='value'
                          themeClass='theme-transparent'
                          wrapperClasses='w-auto'
                          idRef='Date_Select'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      <SelectComponet
                        data={[
                          {
                            id: 1,
                            datepicker: `${translationPath}last-assign-date`,
                          },
                        ]}
                        emptyItem={{
                          value: 0,
                          text: `${translationPath}created-date`,
                          isDisabled: false,
                        }}
                        value={datepicker || 0}
                        defaultValue={0}
                        valueInput='id'
                        textInput='datepicker'
                        onSelectChanged={(value) => {
                          switch (value) {
                            case 0:
                              setdatepicker(0);
                              setdatefilter(true);
                              setShowReport2(false);
                              break;
                            default:
                              setdatepicker(1);
                              setdatefilter(false);
                              setShowReport2(false);
                              break;
                          }
                        }}
                        themeClass='theme-transparent'
                        wrapperClasses='w-auto'
                        idRef='Date_Select'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                  )}
                  <div>
                    <DateRangePickerComponent
                      onClearClicked={() => {
                        setShowReport2(false);
                        setDateFilter({
                          startDate,
                          endDate,
                          key: 'selection',
                        });
                      }}
                      ranges={[dateFilter]}
                      onDateChanged={(selectedDate) => {
                        const newStartDate =
                          selectedDate.selection &&
                          selectedDate.selection.startDate;
                        const newEndDate = new Date(
                          moment(
                            selectedDate.selection &&
                              selectedDate.selection.endDate
                          ).endOf('day')
                        );

                        if (
                          newStartDate.getTime() !==
                            dateFilter.startDate.getTime() ||
                          newEndDate.getTime() !== dateFilter.endDate.getTime()
                        )
                          setShowReport2(false);
                         else
                          setShowReport2(true);

                        setDateFilter({
                          startDate: newStartDate,
                          endDate: newEndDate,
                          key: 'selection',
                        });
                      }}
                    />
                  </div>
                  <div >
            <ButtonBase
                onClick={handleRefreshClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-success clear-all-btn"
              >
                <span className="mdi mdi-chart-arc m-1" />
                Refresh
              </ButtonBase>
            </div>
                </div>
              </div>
            )}
            <div className='dashboardMain'>
              <PowerBIEmbed
                embedConfig={{
                  type: 'report',
                  id,
                  embedUrl,
                  accessToken: report,
                  filters: [
                    FilterMediadetail,
                    Filtermethodofcontact,
                    Filterreferredto,
                    AddressIds,
                    FilteCreatedBy,
                    Filtereferredby,
                    Filterbrancid,
                    selectedFilter,
                  ],

                  settings: {
                    customLayout: {
                      displayOption: models.DisplayOption.FitToWidth,
                    },
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false,
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false,
                      },
                      bookmarks: {
                        visible: false,
                      },
                      pageNavigation: {
                        visible: false,
                      },
                      visualizations: {
                        expanded: false,
                      },
                      fields: {
                        expanded: false,
                      },
                    },
                    background: models.BackgroundType.Transparent,
                  },
                }}
                eventHandlers={
                  new Map([
                    [
                      'loaded',
                      (event) => {
                        setShowReport(true);
                        setDateAppaer(true);
                      },
                    ],
                    [
                      'dataSelected',

                      (event) => {
                        setShowReport2(false);
                      },
                    ],

                    [
                      'rendered',
                      (event) => {
                        setShowReport2(true);
                      },
                    ],

                    [
                      'pageChanged',

                      (event) => {
                        const newPageName = event.detail.newPage.displayName;
                        switch (newPageName) {
                          case 'Sale Transaction ':
                            setsalestransactions(true);
                            setActivity(false);
                            setLeads(false);
                            setleasetransaction(false);
                            setShowReport2(false);
                            setTimeout(() => {
                              scrollToTop();
                            }, 5);

                            break;
                          case 'Lease Transaction ':
                            setsalestransactions(false);
                            setActivity(false);
                            setLeads(false);
                            setleasetransaction(true);
                            setShowReport2(false);
                            setTimeout(() => {
                              scrollToTop();
                            }, 5);

                            break;
                          case 'Lead Activity':
                            setsalestransactions(false);
                            setActivity(true);
                            setLeads(false);
                            setleasetransaction(false);
                            setShowReport2(false);
                            setTimeout(() => {
                              scrollToTop();
                            }, 5);
                            break;

                          default:
                            setsalestransactions(false);
                            setActivity(false);
                            setLeads(true);
                            setleasetransaction(false);
                            setShowReport2(false);
                            setTimeout(() => {
                              scrollToTop();
                            }, 5);
                            break;
                        }
                      },
                    ],
                    [
                      'error',
                      (event) => {
                        const error = event.detail;

                        if (
                          error.detailedMessage === 'Get report failed' ||
                          error.detailedMessage ===
                            'Access token has expired, resubmit with a new access token' ||
                          error.detailedMessage ===
                            'Fail to initialize - Could not resolve cluster'
                        ) {
                          Microsoft(true);
                          setRender(false);
                        } else console.log(error.detailedMessage);
                        showError(error.detailedMessage);
                      },
                    ],
                  ])
                }
                cssClassName={
                  ShowReport ?
                    'report-style-class-Show' :
                    'report-style-class-hide'
                }
                getEmbeddedComponent={(embeddedReport) => {
                  window.report = embeddedReport;
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>{render && <PermissionDeniedLayout />}</div>
      )}
    </div>
  );
};
