import React, {
    useRef, useState, useEffect, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { Inputs } from '../../Components'
import { getIsValidURL, getIsValidDataURL, showError } from '../../Helper';
import { Chip, makeStyles } from '@material-ui/core';
import '../DocumentsUploader/DocumentsUploader.scss'

export const LinkUploader = ({
    translationPath,
    parentTranslationPath,
    uploadedChanged,
    initUploaded,
    isDisabled
}) => {
    const { t } = useTranslation([parentTranslationPath, 'Shared']);
    const useStyles = makeStyles(() => ({
        clickable: {
            width: "fit-content",
            maxWidth: '400px',
            margin: '3px',

            "&:hover": {
                background: "#efefef",
                textDecoration: "underline",
                color: "#2153b1",
            },
        }
    }));
    const classes = useStyles();
    const [allUploadedLinks, setAllUploadedLinks] = useState(initUploaded || []);
    const [currentUploadedLink, setCurrentUploadedLink] = useState('')

    const removeLink = (removedItem) => {
        const filterdUploadedLinks = allUploadedLinks.filter((item) => item.documentLink !== removedItem);
        setAllUploadedLinks(filterdUploadedLinks);
    };

    const getLinksCount = () => {
        let linksCount = 0;
        if (allUploadedLinks && allUploadedLinks.length > 0) {
            linksCount = allUploadedLinks.filter(item => item.documentLink).length
        }
        return linksCount;
    }

    const openLink = (url) => {
        if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
        if (getIsValidURL(url)) {
              window.open(url || '');
        } else if (getIsValidDataURL(url)) {
            let windowObj = window.open();
            const iframeElement = `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
            windowObj.document.write(iframeElement);
        }
    }

    useEffect(() => {
        uploadedChanged(allUploadedLinks)
    }, [allUploadedLinks])

    return (
        <div>
            <Inputs
                inputPlaceholder={t(`${parentTranslationPath}links-uploaded`)}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                translationPathShared={'Shared:uploaderComponent.'}
                value={currentUploadedLink}
                onInputChanged={(event) => {
                    const value = event.target.value;
                    setCurrentUploadedLink(value);
                }}
                wrapperClasses={'link-input mb-0'}
                endAdornment={(
                    <ButtonBase
                        disabled={!currentUploadedLink || isDisabled}
                        onClick={() => {
                            const isLinkExistBefore = allUploadedLinks.find(item => item.documentLink === currentUploadedLink)
                            const isValidLink = (getIsValidURL(currentUploadedLink) || getIsValidDataURL(currentUploadedLink))
                            if (!isLinkExistBefore && isValidLink) {
                                const currentUploadedLinkInfo = {
                                    fileName: null,
                                    uuid: null,
                                    documentLink: currentUploadedLink,
                                }
                                setAllUploadedLinks(prevState => [...prevState, currentUploadedLinkInfo])
                            } else showError(t(`${parentTranslationPath}inValidLink`));
                            setCurrentUploadedLink('');
                        }}
                        className='add-btn btns theme-transparent  '>
                        <span className='mdi mdi-link-variant-plus' />
                    </ButtonBase>

                )
                }
            />
            < div className='uploader-wrapper' >
                <p className='counter-text'>{`${getLinksCount()} ${t(`${parentTranslationPath}links-uploaded`)}`}</p>
            </div >
            <div className='d-flex-column'>
                {
                    allUploadedLinks.map(item => (
                        item.documentLink &&
                        (<Chip
                            classes={{ clickable: classes.clickable }}
                            className='message-chip'
                            label={item.documentLink}
                            clickable
                            onDelete={() => {
                               if(!isDisabled) removeLink(item.documentLink)
                            }}
                            onClick={() => {
                                try {
                                    openLink(item.documentLink)
                                } catch (error) {
                                    showError(t(`${translationPath}this-link-is-not-valid`));
                                }
                            }}
                        />)
                    ))
                }
            </div>
        </div >
    );
};

LinkUploader.propTypes = {
    translationPath: PropTypes.string,
    parentTranslationPath: PropTypes.string,
    translationPathShared: PropTypes.string,
};
LinkUploader.defaultProps = {
    isDisabled: false,
    translationPath: '',
    parentTranslationPath: 'Shared:uploaderComponent.',
    translationPathShared: 'Shared:uploaderComponent.',
};

