

import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Spinner, Tables , SwitchComponent  } from '../../../../../Components'; 
import { GlobalHistory, showError , returnPropsByPermissions } from '../../../../../Helper' ; 
import { TableActions } from '../../../../../Enums';
import {  ZeroMatchingSalePermissions } from '../../../../../Permissions' ; 
import { ActiveItemActions } from '../../../../../store/ActiveItem/ActiveItemActions';
import {  DeleteInquiryDialog } from '../../DeleteInquiry/DeleteInquiryDialog' ;  
import { CancelInquiryDialog } from '../../CancelInquiry/CancelInquiryDialog' ; 

export const ZeroMatchingViewTable = ({
   parentTranslationPath,
   translationPath,
   filter,
   data,
   isLoading,
   activeItem ,
   setActiveItem ,
   editZeroMatching , 
   onSave , 
   setFilter , 
   setSortBy ,

}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [ isOpenDeleteDialog , setIsOpenDeleteDialog ] = useState(false) ; 
  const [isOpenCancelInquiryDialog , setIsOpenCancelInquiryDialog ] = useState(false) ;

  const tableActionClicked = useCallback((actionEnum, item) => {
   setActiveItem(item) ; 
    dispatch(ActiveItemActions.activeItemRequest(item)); 
    if (actionEnum === TableActions.edit.key)
    {
          GlobalHistory.push(`/home/zero-matching-sale/zero-matching-sale-profile-edit?formType=${44}&id=${item && item.inquiryId}&activeFormType=${1}`) ;
    }   
     else if (actionEnum === TableActions.delete.key)
             setIsOpenDeleteDialog(true) ; 
      else if (actionEnum === TableActions.close.key)
            { 
              if(item.inquiryStatus === 'Canceled')
              showError(t(`${translationPath}inquiryStatusIsCanceled`));
              else  setIsOpenCancelInquiryDialog(true) ; 
         } 
  }, []);

  const getActionTableWithPermissions = () => {
    const list = [];
    if (returnPropsByPermissions(ZeroMatchingSalePermissions.EditInquiry.permissionsId)) 
        list.push({ enum: TableActions.edit.key , title: t(`${translationPath}Shared:edit`)});
    if (returnPropsByPermissions(ZeroMatchingSalePermissions.DeleteInquiry.permissionsId)) 
        list.push({ enum: TableActions.delete.key , title: t(`${translationPath}Shared:delete`)});
   if (returnPropsByPermissions(ZeroMatchingSalePermissions.CancelInquiry.permissionsId)) 
        list.push({ enum: TableActions.close.key ,title: t(`${translationPath}Shared:cancel`) });
       
    return list;
  };


  return (
    <div className='ZeroMatchingViewTable'>
      <Spinner isActive={isLoading.Inquiries} isAbsolute />
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <Tables
          data={(data && data.result) || []}
          headerData={[
            {
              id: 1,
              isSortable: true,
              input: 'leadId',
              label: t(`${translationPath}leadId`),
              component: (item) => (
                <div>
                  {item && item.leadId}
                </div>
              ),
            },
            {
                id: 2,
                isSortable: true,
                input: 'inquiryStatus',
                label: t(`${translationPath}inquiry-status`),
                component: (item) => (
                  <div>
                    {item && item.inquiryStatus}
                  </div>
                ),
              },
            {
              id: 3,
              isSortable: true,
              input: 'createdOn',
              isDate : true , 
              label: t(`${translationPath}createdOn`),
              component: (item) => (
                <div>
                  {item && item.createdOn}
                </div>
              ),
            },
            {
              id: 4,
              isSortable: true,
              input: 'updateOn',
              label: t(`${translationPath}updateOn`),
              isDate : true , 
              component: (item) => (
                <div>
                  {item && item.updateOn}
                </div>
              ),
            },
            {
              id: 5,
              isSortable: true,
              input: 'createdByName',
              isDate : false , 
              label: t(`${translationPath}createdBy`),
              component: (item) => (
                <div>
                  {item && item.createdByName}
                </div>
              ),
            },
            {
              id: 6,
              isSortable: true,
              input: 'assignedTo',
              isDate : false , 
              label: t(`${translationPath}assignedTo`),
              component: (item) => (
                <div>
                  {item && item.assignedTo}
                </div>
              ),
            },
            {
              id: 7 ,
              isSortable: true,
              input: 'updatedByName',
              isDate : false , 
              label: t(`${translationPath}updatedBy`),
              component: (item) => (
                <div>
                  {item && item.updatedByName}
                </div>
              ),
            },
            {
              id: 8 ,
              isSortable: true,
              input: 'closeReason',
              isDate : false , 
              label: t(`${translationPath}cancelReason`),
              component: (item) => (
                <div>
                  {item && item.closeReason || ''}
                </div>
              ),
            },
            
          ]}
          defaultActions={getActionTableWithPermissions()}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(data && data.totalCount) || 0}
          setSortBy={setSortBy}

        />
      </div>
      {isOpenDeleteDialog && (
        <DeleteInquiryDialog
          open={isOpenDeleteDialog}
          close={() => {
            setIsOpenDeleteDialog(false);
          }}
          onSave={() => {
            setIsOpenDeleteDialog(false);
            setFilter();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )}

    { isOpenCancelInquiryDialog && (
        <CancelInquiryDialog
          open={isOpenCancelInquiryDialog}
          close={() => {
            setIsOpenCancelInquiryDialog(false);
          }}
          onSave={() => {
            setIsOpenCancelInquiryDialog(false);
            setFilter();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )} 

    </div>
  );
};

ZeroMatchingViewTable.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  filter: PropTypes.number.isRequired,
  data:PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
