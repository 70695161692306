/* eslint-disable max-len */
import i18next from 'i18next';
import moment from 'moment-timezone/moment-timezone-utils';
import { GlobalRerender } from './Middleware.Helper';
import { config } from '../config';

import UnitsProfileManagementViewEn from '../Views/Home/UnitsView/UnitsProfileManagementView/I18n/en.json';
import UnitsProfileManagementViewAr from '../Views/Home/UnitsView/UnitsProfileManagementView/I18n/ar.json';
import UnitsProfileManagementViewCn from '../Views/Home/UnitsView/UnitsProfileManagementView/I18n/cn.json';
import UnitsProfileManagementViewRu from '../Views/Home/UnitsView/UnitsProfileManagementView/I18n/ru.json';

import SharedEn from '../Layouts/Common/I18n/en.json';
import SharedAr from '../Layouts/Common/I18n/ar.json';
import SharedRu from '../Layouts/Common/I18n/ru.json';
import SharedCn from '../Layouts/Common/I18n/cn.json';

import UsersViewEn from '../Views/Home/Administration/UsersView/I18n/en.json';
import UsersViewCn from '../Views/Home/Administration/UsersView/I18n/cn.json';
import UsersViewRu from '../Views/Home/Administration/UsersView/I18n/ru.json';
import UsersViewAr from '../Views/Home/Administration/UsersView/I18n/ar.json';

import PropertiesProfileManagementViewEn from '../Views/Home/PropertiesView/PropertiesProfileManagementView/I18n/en.json';
import PropertiesProfileManagementViewAr from '../Views/Home/PropertiesView/PropertiesProfileManagementView/I18n/ar.json';
import PropertiesProfileManagementViewCn from '../Views/Home/PropertiesView/PropertiesProfileManagementView/I18n/cn.json';
import PropertiesProfileManagementViewRu from '../Views/Home/PropertiesView/PropertiesProfileManagementView/I18n/ru.json';

import LeadsViewEn from '../Views/Home/LeadsView/I18n/en.json';
import LeadsViewAr from '../Views/Home/LeadsView/I18n/ar.json';
import LeadsViewCn from '../Views/Home/LeadsView/I18n/cn.json';
import LeadsViewRu from '../Views/Home/LeadsView/I18n/ru.json';

import AgentsEn from '../Views/Home/AgentsView/I18n/en.json';
import AgentsAr from '../Views/Home/AgentsView/I18n/ar.json';
import AgentsCn from '../Views/Home/AgentsView/I18n/cn.json';
import AgentsRu from '../Views/Home/AgentsView/I18n/ru.json';

import ContactsViewEn from '../Views/Home/ContactsView/I18n/en.json';
import ContactsViewAr from '../Views/Home/ContactsView/I18n/ar.json';
import ContactsViewCn from '../Views/Home/ContactsView/I18n/cn.json';
import ContactsViewRu from '../Views/Home/ContactsView/I18n/ru.json';

import UnitsStatusManagementViewEn from '../Views/Home/UnitsView/UnitsStatusManagementView/I18n/en.json';
import UnitsStatusManagementViewAr from '../Views/Home/UnitsView/UnitsStatusManagementView/I18n/ar.json';
import UnitsStatusManagementViewCn from '../Views/Home/UnitsView/UnitsStatusManagementView/I18n/cn.json';
import UnitsStatusManagementViewRu from '../Views/Home/UnitsView/UnitsStatusManagementView/I18n/ru.json';

import UnitsViewEn from '../Views/Home/UnitsView/I18n/en.json';
import UnitsViewCn from '../Views/Home/UnitsView/I18n/cn.json';
import UnitsViewRu from '../Views/Home/UnitsView/I18n/ru.json';
import UnitsViewAr from '../Views/Home/UnitsView/I18n/ar.json';

import ActivitiesViewCn from '../Views/Home/ActivitiesView/I18n/cn.json';
import ActivitiesViewRu from '../Views/Home/ActivitiesView/I18n/ru.json';
import ActivitiesViewEn from '../Views/Home/ActivitiesView/I18n/en.json';
import ActivitiesViewAr from '../Views/Home/ActivitiesView/I18n/ar.json';

import LeasingTransactionsViewCn from '../Views/Home/LeasingTransactionsView/I18n/cn.json';
import LeasingTransactionsViewRu from '../Views/Home/LeasingTransactionsView/I18n/ru.json';
import LeasingTransactionsViewEn from '../Views/Home/LeasingTransactionsView/I18n/en.json';
import LeasingTransactionsViewAr from '../Views/Home/LeasingTransactionsView/I18n/ar.json';

import SalesTransactionsViewCn from '../Views/Home/SalesTransactionsView/I18n/cn.json';
import SalesTransactionsViewRu from '../Views/Home/SalesTransactionsView/I18n/ru.json';
import SalesTransactionsViewEn from '../Views/Home/SalesTransactionsView/I18n/en.json';
import SalesTransactionsViewAr from '../Views/Home/SalesTransactionsView/I18n/ar.json';

import PortfolioViewCn from '../Views/Home/PortfolioView/I18n/cn.json';
import PortfolioViewRu from '../Views/Home/PortfolioView/I18n/ru.json';
import PortfolioViewEn from '../Views/Home/PortfolioView/I18n/en.json';
import PortfolioViewAr from '../Views/Home/PortfolioView/I18n/ar.json';

import UnitsDialogsManagementViewCn from '../Views/Home/UnitsView/UnitsAddDialogsView/UnitsDialogsManagementView/I18n/cn.json';
import UnitsDialogsManagementViewRu from '../Views/Home/UnitsView/UnitsAddDialogsView/UnitsDialogsManagementView/I18n/ru.json';
import UnitsDialogsManagementViewEn from '../Views/Home/UnitsView/UnitsAddDialogsView/UnitsDialogsManagementView/I18n/en.json';
import UnitsDialogsManagementViewAr from '../Views/Home/UnitsView/UnitsAddDialogsView/UnitsDialogsManagementView/I18n/ar.json';

import CampaignCn from '../Views/Home/CampaignView/I18n/cn.json';
import CampaignRu from '../Views/Home/CampaignView/I18n/ru.json';
import CampaignEn from '../Views/Home/CampaignView/I18n/en.json';
import CampaignAr from '../Views/Home/CampaignView/I18n/ar.json';

import LeadAssignAgentsTrackingCn from '../Views/Home/LeadsSalesView/I18n/cn.json';
import LeadAssignAgentsTrackingRu from '../Views/Home/LeadsSalesView/I18n/ru.json';
import LeadAssignAgentsTrackingEn from '../Views/Home/LeadsSalesView/I18n/en.json';
import LeadAssignAgentsTrackingAr from '../Views/Home/LeadsSalesView/I18n/ar.json';

import SalesTransactionsProfileViewCn from '../Views/Home/SalesTransactionsView/SalesTransactionsProfile/I18n/cn.json';
import SalesTransactionsProfileViewRu from '../Views/Home/SalesTransactionsView/SalesTransactionsProfile/I18n/ru.json';
import SalesTransactionsProfileViewEn from '../Views/Home/SalesTransactionsView/SalesTransactionsProfile/I18n/en.json';
import SalesTransactionsProfileViewAr from '../Views/Home/SalesTransactionsView/SalesTransactionsProfile/I18n/ar.json';

import PropertiesViewCn from '../Views/Home/PropertiesView/I18n/cn.json';
import PropertiesViewRu from '../Views/Home/PropertiesView/I18n/ru.json';
import PropertiesViewEn from '../Views/Home/PropertiesView/I18n/en.json';
import PropertiesViewAr from '../Views/Home/PropertiesView/I18n/ar.json';

// Start DFM Views
import DataFilesRu from '../Views/dfmAddEditAndDelete/I18n/ru.json';
import DataFilesCn from '../Views/dfmAddEditAndDelete/I18n/cn.json';
import DataFilesEn from '../Views/dfmAddEditAndDelete/I18n/en.json';
import DataFilesAr from '../Views/dfmAddEditAndDelete/I18n/ar.json';
// End DFM Views

import LeasingTransactionsProfileViewRu from '../Views/Home/LeasingTransactionsView/LeasingTransactionsProfile/I18n/ru.json';
import LeasingTransactionsProfileViewCn from '../Views/Home/LeasingTransactionsView/LeasingTransactionsProfile/I18n/cn.json';
import LeasingTransactionsProfileViewEn from '../Views/Home/LeasingTransactionsView/LeasingTransactionsProfile/I18n/en.json';
import LeasingTransactionsProfileViewAr from '../Views/Home/LeasingTransactionsView/LeasingTransactionsProfile/I18n/ar.json';

// Start My Referrals View
import MyReferralsRu from '../Views/Home/MyReferralsView/I18n/ru.json';
import MyReferralsCn from '../Views/Home/MyReferralsView/I18n/cn.json';
import MyReferralsEn from '../Views/Home/MyReferralsView/I18n/en.json';
import MyReferralsAr from '../Views/Home/MyReferralsView/I18n/ar.json';
// End My Referrals View
// Start Images Gallery Views
import ImagesGalleryGroupRu from '../Views/Home/ImagesGallery/I18n/ru.json';
import ImagesGalleryGroupCn from '../Views/Home/ImagesGallery/I18n/cn.json';
import ImagesGalleryGroupEn from '../Views/Home/ImagesGallery/I18n/en.json';
import ImagesGalleryGroupAr from '../Views/Home/ImagesGallery/I18n/ar.json';
// End Images Gallery Views
// Start Assets Views
import AssetsViewCn from '../Views/Home/AssetsView/I18n/cn.json';
import AssetsViewRu from '../Views/Home/AssetsView/I18n/ru.json';
import AssetsViewEn from '../Views/Home/AssetsView/I18n/en.json';
import AssetsViewAr from '../Views/Home/AssetsView/I18n/ar.json';
// End Assets Views

// Start ActivitiesType Views
import ActivitiesTypeCn from '../Views/Home/ActivitiesTypeView/ActivitiesTypeManagementView/I18n/cn.json';
import ActivitiesTypeRu from '../Views/Home/ActivitiesTypeView/ActivitiesTypeManagementView/I18n/ru.json';
import ActivitiesTypeEn from '../Views/Home/ActivitiesTypeView/ActivitiesTypeManagementView/I18n/en.json';
import ActivitiesTypeAr from '../Views/Home/ActivitiesTypeView/ActivitiesTypeManagementView/I18n/ar.json';
// End  ActivitiesType Views

// Start Templates View
import TemplatesViewRu from '../Views/Home/TemplatesView/I18n/ru.json';
import TemplatesViewCn from '../Views/Home/TemplatesView/I18n/cn.json';
import TemplatesViewEn from '../Views/Home/TemplatesView/I18n/en.json';
import TemplatesViewAr from '../Views/Home/TemplatesView/I18n/ar.json';
// End Templates View

import WorkOrdersManagementViewRu from '../Views/Home/WorkOrdersView/WorkOrdersManagementView/I18n/ru.json';
import WorkOrdersManagementViewCn from '../Views/Home/WorkOrdersView/WorkOrdersManagementView/I18n/cn.json';
import WorkOrdersManagementViewEn from '../Views/Home/WorkOrdersView/WorkOrdersManagementView/I18n/en.json';
import WorkOrdersManagementViewAr from '../Views/Home/WorkOrdersView/WorkOrdersManagementView/I18n/ar.json';

import ImportDetailsViewCn from '../Views/Home/Common/ImportDetailsView/I18n/cn.json';
import ImportDetailsViewRu from '../Views/Home/Common/ImportDetailsView/I18n/ru.json';
import ImportDetailsViewEn from '../Views/Home/Common/ImportDetailsView/I18n/en.json';
import ImportDetailsViewAr from '../Views/Home/Common/ImportDetailsView/I18n/ar.json';

import ContactProfileManagementViewCn from '../Views/Home/ContactsView/ContactProfileManagementView/I18n/cn.json';
import ContactProfileManagementViewRu from '../Views/Home/ContactsView/ContactProfileManagementView/I18n/ru.json';
import ContactProfileManagementViewEn from '../Views/Home/ContactsView/ContactProfileManagementView/I18n/en.json';
import ContactProfileManagementViewAr from '../Views/Home/ContactsView/ContactProfileManagementView/I18n/ar.json';

// Start My Referrals View
import SalesAvailabilityCn from '../Views/Home/SalesAvailabilityView/I18n/cn.json';
import SalesAvailabilityRu from '../Views/Home/SalesAvailabilityView/I18n/ru.json';
import SalesAvailabilityEn from '../Views/Home/SalesAvailabilityView/I18n/en.json';
import SalesAvailabilityAr from '../Views/Home/SalesAvailabilityView/I18n/ar.json';
// End My Referrals View

import PoliciesCn from '../Views/Home/PoliciesView/I18n/cn.json';
import PoliciesRu from '../Views/Home/PoliciesView/I18n/ru.json';
import PoliciesEn from '../Views/Home/PoliciesView/I18n/en.json';
import PoliciesAr from '../Views/Home/PoliciesView/I18n/ar.json';

import LeadsProfileManagementViewRu from '../Views/Home/LeadsView/LeadsProfileManagementView/I18n/ru.json';
import LeadsProfileManagementViewCn from '../Views/Home/LeadsView/LeadsProfileManagementView/I18n/cn.json';
import LeadsProfileManagementViewEn from '../Views/Home/LeadsView/LeadsProfileManagementView/I18n/en.json';
import LeadsProfileManagementViewAr from '../Views/Home/LeadsView/LeadsProfileManagementView/I18n/ar.json';

// Start Reports Builder View
import ReportsBuildergRu from '../Views/Home/ReportsBuilderView/I18n/ru.json';
import ReportsBuildergCn from '../Views/Home/ReportsBuilderView/I18n/cn.json';
import ReportsBuildergEn from '../Views/Home/ReportsBuilderView/I18n/en.json';
import ReportsBuildergAr from '../Views/Home/ReportsBuilderView/I18n/ar.json';

import InquiresRu from '../Views/Home/Inquires/I18n/ru.json';
import InquiresCn from '../Views/Home/Inquires/I18n/cn.json';
import InquiresEn from '../Views/Home/Inquires/I18n/en.json';
import InquiresAr from '../Views/Home/Inquires/I18n/ar.json';

import MarketingClientsSegmentationCn from '../Views/Home/MarketingClientsSegmentation/I18n/cn.json';
import MarketingClientsSegmentationRu from '../Views/Home/MarketingClientsSegmentation/I18n/ru.json';
import MarketingClientsSegmentationEn from '../Views/Home/MarketingClientsSegmentation/I18n/en.json';
import MarketingClientsSegmentationAr from '../Views/Home/MarketingClientsSegmentation/I18n/ar.json';

// Start Agent Rotation Log View
import AgentRotationLogEn from '../Views/Home/AgentRotationLog/I18n/en.json';
import AgentRotationLogCn from '../Views/Home/AgentRotationLog/I18n/cn.json';
import AgentRotationLogRu from '../Views/Home/AgentRotationLog/I18n/ru.json';
import AgentRotationLogAr from '../Views/Home/AgentRotationLog/I18n/ar.json';
// End   Agent Rotation Log View

// Start Maintenance Contracts View
import MaintenanceContractsEn from '../Views/Home/MaintenanceContractsView/I18n/en.json';
import MaintenanceContractsCn from '../Views/Home/MaintenanceContractsView/I18n/cn.json';
import MaintenanceContractsRu from '../Views/Home/MaintenanceContractsView/I18n/ru.json';
import MaintenanceContractsAr from '../Views/Home/MaintenanceContractsView/I18n/ar.json';
// End Maintenance Contracts View

import RolesManagementViewAr from '../Views/Home/Administration/RolesView/RolesManagement/I18n/ar.json';
import RolesManagementViewEn from '../Views/Home/Administration/RolesView/RolesManagement/I18n/en.json';
import RolesManagementViewCn from '../Views/Home/Administration/RolesView/RolesManagement/I18n/cn.json';
import RolesManagementViewRu from '../Views/Home/Administration/RolesView/RolesManagement/I18n/ru.json';

import WorkOrdersViewEn from '../Views/Home/WorkOrdersView/I18n/en.json';
import WorkOrdersViewRu from '../Views/Home/WorkOrdersView/I18n/ru.json';
import WorkOrdersViewCn from '../Views/Home/WorkOrdersView/I18n/cn.json';
import WorkOrdersViewAr from '../Views/Home/WorkOrdersView/I18n/ar.json';

import RolesViewCn from '../Views/Home/Administration/RolesView/I18n/cn.json';
import RolesViewRu from '../Views/Home/Administration/RolesView/I18n/ru.json';
import RolesViewEn from '../Views/Home/Administration/RolesView/I18n/en.json';
import RolesViewAr from '../Views/Home/Administration/RolesView/I18n/ar.json';

// Start My Referrals View
import LeasingAvailabilityCn from '../Views/Home/LeasingAvailabilityView/I18n/cn.json';
import LeasingAvailabilityRu from '../Views/Home/LeasingAvailabilityView/I18n/ru.json';
import LeasingAvailabilityEn from '../Views/Home/LeasingAvailabilityView/I18n/en.json';
import LeasingAvailabilityAr from '../Views/Home/LeasingAvailabilityView/I18n/ar.json';
// End My Referrals View

// Start Contacts Info Public Views
import ContactsInfoPublicRu from '../Views/Home/ContactsView/ContactsInfoPublic/I18n/ru.json';
import ContactsInfoPublicCn from '../Views/Home/ContactsView/ContactsInfoPublic/I18n/cn.json';
import ContactsInfoPublicEn from '../Views/Home/ContactsView/ContactsInfoPublic/I18n/en.json';
import ContactsInfoPublicAr from '../Views/Home/ContactsView/ContactsInfoPublic/I18n/ar.json';
// End  Contacts Info Public Views

import IncidentsViewAr from '../Views/Home/IncidentsView/I18n/ar.json';
import IncidentsViewEn from '../Views/Home/IncidentsView/I18n/en.json';
import IncidentsViewRu from '../Views/Home/IncidentsView/I18n/ru.json';
import IncidentsViewCn from '../Views/Home/IncidentsView/I18n/cn.json';

import UserDataViewRu from '../Views/Home/Administration/UserDataView/I18n/ru.json';
import UserDataViewCn from '../Views/Home/Administration/UserDataView/I18n/cn.json';
import UserDataViewEn from '../Views/Home/Administration/UserDataView/I18n/en.json';
import UserDataViewAr from '../Views/Home/Administration/UserDataView/I18n/ar.json';

import DashboardRu from '../Views/Home/Dashboard/I18n/ru.json';
import DashboardCn from '../Views/Home/Dashboard/I18n/cn.json';
import DashboardEn from '../Views/Home/Dashboard/I18n/en.json';
import DashboardAr from '../Views/Home/Dashboard/I18n/ar.json';

import QaActivityEn from '../Views/Home/QAView/I18n/en.json';
import QaActivityAr from '../Views/Home/QAView/I18n/ar.json';
import QaActivityRu from '../Views/Home/QAView/I18n/ru.json';
import QaActivityCn from '../Views/Home/QAView/I18n/cn.json';

// Start Account Views
import LoginViewRu from '../Views/Account/LoginView/I18n/ru.json';
import LoginViewCn from '../Views/Account/LoginView/I18n/cn.json';
import LoginViewEn from '../Views/Account/LoginView/I18n/en.json';
import LoginViewAr from '../Views/Account/LoginView/I18n/ar.json';
// End Account Views

import InvoicesViewRu from '../Views/Home/InvoicesView/I18n/ru.json';
import InvoicesViewCn from '../Views/Home/InvoicesView/I18n/cn.json';
import InvoicesViewEn from '../Views/Home/InvoicesView/I18n/en.json';
import InvoicesViewAr from '../Views/Home/InvoicesView/I18n/ar.json';

import FormBuilderRu from '../Views/Home/FormBuilder/I18n/ru.json';
import FormBuilderCn from '../Views/Home/FormBuilder/I18n/cn.json';
import FormBuilderEn from '../Views/Home/FormBuilder/I18n/en.json';
import FormBuilderAr from '../Views/Home/FormBuilder/I18n/ar.json';

// Start system configration
import SystemConfigrationCn from '../Views/Home/Administration/SystemConfigrationView/I18n/cn.json';
import SystemConfigrationRu from '../Views/Home/Administration/SystemConfigrationView/I18n/ru.json';
import SystemConfigrationEn from '../Views/Home/Administration/SystemConfigrationView/I18n/en.json';
import SystemConfigrationAr from '../Views/Home/Administration/SystemConfigrationView/I18n/ar.json';
// End   system configration

// Start Form Builder Views
import FormBuilderViewCn from '../Views/FormBuilder/I18n/cn.json';
import FormBuilderViewRu from '../Views/FormBuilder/I18n/ru.json';
import FormBuilderViewEn from '../Views/FormBuilder/I18n/en.json';
import FormBuilderViewAr from '../Views/FormBuilder/I18n/ar.json';
// End Form Builder Views

import TeamViewCn from '../Views/Home/Administration/TeamView/I18n/cn.json';
import TeamViewRu from '../Views/Home/Administration/TeamView/I18n/ru.json';
import TeamViewEn from '../Views/Home/Administration/TeamView/I18n/en.json';
import TeamViewAr from '../Views/Home/Administration/TeamView/I18n/ar.json';

import LookupsViewEn from '../Views/Home/LookupsView/I18n/en.json';
import LookupsViewAr from '../Views/Home/LookupsView/I18n/ar.json';
import LookupsViewRu from '../Views/Home/LookupsView/I18n/ru.json';
import LookupsViewCn from '../Views/Home/LookupsView/I18n/cn.json';
// Start Bulk Assign View
import MonthlyCalendarViewRu from '../Views/Home/MonthlyCalendarView/I18n/ru.json';
import MonthlyCalendarViewCn from '../Views/Home/MonthlyCalendarView/I18n/cn.json';
import MonthlyCalendarViewEn from '../Views/Home/MonthlyCalendarView/I18n/en.json';
import MonthlyCalendarViewAr from '../Views/Home/MonthlyCalendarView/I18n/ar.json';
// End Bulk Assign View

// Start System Notifications Views
import SystemNotificationsRu from '../Views/Home/SystemNotifications/I18n/ru.json';
import SystemNotificationsCn from '../Views/Home/SystemNotifications/I18n/cn.json';
import SystemNotificationsEn from '../Views/Home/SystemNotifications/I18n/en.json';
import SystemNotificationsAr from '../Views/Home/SystemNotifications/I18n/ar.json';
// End   System Notifications Views

import BranchViewEn from '../Views/Home/Administration/BranchesView/I18n/en.json';
import BranchViewRu from '../Views/Home/Administration/BranchesView/I18n/ru.json';
import BranchViewCn from '../Views/Home/Administration/BranchesView/I18n/cn.json';
import BranchViewAr from '../Views/Home/Administration/BranchesView/I18n/ar.json';

// Start Country Views
import LocationEn from '../Views/Home/LocationViews/LocationSharingUtilities/I18n/en.json';
import LocationRu from '../Views/Home/LocationViews/LocationSharingUtilities/I18n/ru.json';
import LocationCn from '../Views/Home/LocationViews/LocationSharingUtilities/I18n/cn.json';
import LocationAr from '../Views/Home/LocationViews/LocationSharingUtilities/I18n/ar.json';
// End   Country Views

// Start My Lead View
import MyLeadViewEn from '../Views/Home/MyLeadsView/I18n/en.json';
import MyLeadViewCn from '../Views/Home/MyLeadsView/I18n/cn.json';
import MyLeadViewRu from '../Views/Home/MyLeadsView/I18n/ru.json';
import MyLeadViewAr from '../Views/Home/MyLeadsView/I18n/ar.json';
// End My Lead View

import BusinessGroupsViewCn from '../Views/Home/Administration/BusinessGroupsView/I18n/cn.json';
import BusinessGroupsViewRu from '../Views/Home/Administration/BusinessGroupsView/I18n/ru.json';
import BusinessGroupsViewEn from '../Views/Home/Administration/BusinessGroupsView/I18n/en.json';
import BusinessGroupsViewAr from '../Views/Home/Administration/BusinessGroupsView/I18n/ar.json';

import HeaderViewCn from '../Views/Home/Common/HeaderView/I18n/cn.json';
import HeaderViewRu from '../Views/Home/Common/HeaderView/I18n/ru.json';
import HeaderViewEn from '../Views/Home/Common/HeaderView/I18n/en.json';
import HeaderViewAr from '../Views/Home/Common/HeaderView/I18n/ar.json';

import OpenFileViewRu from '../Views/Home/Common/OpenFileView/I18n/ru.json';
import OpenFileViewCn from '../Views/Home/Common/OpenFileView/I18n/cn.json';
import OpenFileViewEn from '../Views/Home/Common/OpenFileView/I18n/en.json';
import OpenFileViewAr from '../Views/Home/Common/OpenFileView/I18n/ar.json';

import ReportsEn from '../Views/Home/ReportsView/I18n/en.json';
import ReportsCn from '../Views/Home/ReportsView/I18n/cn.json';
import ReportsRu from '../Views/Home/ReportsView/I18n/ru.json';
import ReportsAr from '../Views/Home/ReportsView/I18n/ar.json';

// Start Operating costs Views
import OperatingCostsEn from '../Views/Home/OperatingCosts/I18n/en.json';
import OperatingCostsAr from '../Views/Home/OperatingCosts/I18n/ar.json';
import OperatingCostsCn from '../Views/Home/OperatingCosts/I18n/cn.json';
import OperatingCostsRu from '../Views/Home/OperatingCosts/I18n/ru.json';
// End Operating costs Views

// Start History component Views
import HistoryEn from '../Components/HistoryComponent/I18n/en.json';
import HistoryAr from '../Components/HistoryComponent/I18n/ar.json';
import HistoryCn from '../Components/HistoryComponent/I18n/cn.json';
import HistoryRu from '../Components/HistoryComponent/I18n/ru.json';
// End History component Views

// Start Bulk Assign View
import BulkAssignEn from '../Views/Home/UnitsBulkAssignView/I18n/en.json';
import BulkAssignAr from '../Views/Home/UnitsBulkAssignView/I18n/ar.json';
import BulkAssignCn from '../Views/Home/UnitsBulkAssignView/I18n/cn.json';
import BulkAssignRu from '../Views/Home/UnitsBulkAssignView/I18n/ru.json';
// End Bulk Assign View

import UsersLoginHistoryViewEn from '../Views/Home/UsersLoginHistoryView/I18n/en.json';
import UsersLoginHistoryViewCn from '../Views/Home/UsersLoginHistoryView/I18n/cn.json';
import UsersLoginHistoryViewRu from '../Views/Home/UsersLoginHistoryView/I18n/ru.json';
import UsersLoginHistoryViewAr from '../Views/Home/UsersLoginHistoryView/I18n/ar.json';

import LostLeadsViewEn from '../Views/Home/LostLeadsView/I18n/en.json';
import LostLeadsViewCn from '../Views/Home/LostLeadsView/I18n/cn.json';
import LostLeadsViewAr from '../Views/Home/LostLeadsView/I18n/ar.json';
import LostLeadsViewRu from '../Views/Home/LostLeadsView/I18n/ru.json';

// End  Reports Builder View Agent Rotation Log View

// Start Rotation Log View
import RotationLogViewEn from '../Views/Home/RotationLogView/I18n/en.json';
import RotationLogViewAr from '../Views/Home/RotationLogView/I18n/ar.json';
import RotationLogViewCn from '../Views/Home/RotationLogView/I18n/cn.json';
import RotationLogViewRu from '../Views/Home/RotationLogView/I18n/ru.json';
// End Rotation Log View

import MergeLogsAr from '../Views/Home/MergeLogsView/I18n/ar.json';
import MergeLogsEn from '../Views/Home/MergeLogsView/I18n/en.json';
import MergeLogsCn from '../Views/Home/MergeLogsView/I18n/cn.json';
import MergeLogsRu from '../Views/Home/MergeLogsView/I18n/ru.json';

import TemplateShareLogAr from '../Views/Home/TemplateShareLogView/I18n/ar.json';
import TemplateShareLogEn from '../Views/Home/TemplateShareLogView/I18n/en.json';
import TemplateShareLogCn from '../Views/Home/TemplateShareLogView/I18n/cn.json';
import TemplateShareLogRu from '../Views/Home/TemplateShareLogView/I18n/ru.json';

import AssignmentEn from '../Views/Home/AdminAssignView/I18n/en.json';
import AssignmentAr from '../Views/Home/AdminAssignView/I18n/ar.json';
import AssignmentCn from '../Views/Home/AdminAssignView/I18n/cn.json';
import AssignmentRu from '../Views/Home/AdminAssignView/I18n/ru.json';

import AMLAr from '../Views/Home/AMLView/I18n/ar.json';
import AMLEn from '../Views/Home/AMLView/I18n/en.json';
import AMLCn from '../Views/Home/AMLView/I18n/cn.json';
import AMLRu from '../Views/Home/AMLView/I18n/ru.json';

import ReportBuilderLogViewEn from '../Views/Home/ReportBuilderLogView/I18n/en.json';
import ReportBuilderLogViewAr from '../Views/Home/ReportBuilderLogView/I18n/ar.json';
import ReportBuilderLogViewCn from '../Views/Home/ReportBuilderLogView/I18n/cn.json';
import ReportBuilderLogViewRu from '../Views/Home/ReportBuilderLogView/I18n/ru.json';

import ExternalLinksViewEn from '../Views/Home/ExternalLinks/I18n/en.json';
import ExternalLinksViewAr from '../Views/Home/ExternalLinks/I18n/ar.json';
import ExternalLinksViewCn from '../Views/Home/ExternalLinks/I18n/cn.json';
import ExternalLinksViewRu from '../Views/Home/ExternalLinks/I18n/ru.json';

import ActivitiesCommunicationLogsEn from '../Views/Home/ActivitiesCommunicationLogsView/I18n/en.json';
import ActivitiesCommunicationLogsAr from '../Views/Home/ActivitiesCommunicationLogsView/I18n/ar.json';
import ActivitiesCommunicationLogsCn from '../Views/Home/ActivitiesCommunicationLogsView/I18n/cn.json';
import ActivitiesCommunicationLogsRu from '../Views/Home/ActivitiesCommunicationLogsView/I18n/ru.json';

import PortalsViewEn from '../Views/Home/PortalView/I18n/en.json';
import PortalsViewAr from '../Views/Home/PortalView/I18n/ar.json';
import PortalsViewCn from '../Views/Home/PortalView/I18n/cn.json';
import PortalsViewRu from '../Views/Home/PortalView/I18n/ru.json';

import UnqualifiedLeadViewEn from '../Views/Home/UnqualifiedLeadView/I18n/en.json';
import UnqualifiedLeadViewAr from '../Views/Home/UnqualifiedLeadView/I18n/ar.json';
import UnqualifiedLeadViewCn from '../Views/Home/UnqualifiedLeadView/I18n/cn.json';
import UnqualifiedLeadViewRu from '../Views/Home/UnqualifiedLeadView/I18n/ru.json';

import TransactionConfigurationViewEn from '../Views/Home/TransactionConfiguration/I18n/en.json';
import TransactionConfigurationViewAr from '../Views/Home/TransactionConfiguration/I18n/ar.json';
import TransactionConfigurationViewCn from '../Views/Home/TransactionConfiguration/I18n/cn.json';
import TransactionConfigurationViewRu from '../Views/Home/TransactionConfiguration/I18n/ru.json';

// Start Rotation Log View
import OwnerRotationViewEn from '../Views/Home/OwnerRotationView/I18n/en.json';
import OwnerRotationViewAr from '../Views/Home/OwnerRotationView/I18n/ar.json';
import OwnerRotationViewCn from '../Views/Home/OwnerRotationView/I18n/cn.json';
import OwnerRotationViewRu from '../Views/Home/OwnerRotationView/I18n/ru.json';
// End Rotation Log View

export function localizationInit() {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: ['en', 'ar', 'ru', 'cn'],
    lng: 'en', // language to use
    resources: {
      en: {
        QAActivitiesView: QaActivityEn,
        Shared: SharedEn,
        BusinessGroupsView: BusinessGroupsViewEn,
        TeamView: TeamViewEn,
        UserDataView: UserDataViewEn,
        UsersView: UsersViewEn,
        BranchView: BranchViewEn,
        RolesView: RolesViewEn,
        HeaderView: HeaderViewEn,
        ImportDetailsView: ImportDetailsViewEn,
        OpenFileView: OpenFileViewEn,
        ContactsView: ContactsViewEn,
        FormBuilder: FormBuilderEn,
        LeadsView: LeadsViewEn,
        LookupsView: LookupsViewEn,
        PropertiesView: PropertiesViewEn,
        UnitsView: UnitsViewEn,
        LoginView: LoginViewEn,
        DataFiles: DataFilesEn,
        FormBuilderView: FormBuilderViewEn,
        ContactProfileManagementView: ContactProfileManagementViewEn,
        PropertiesProfileManagementView: PropertiesProfileManagementViewEn,
        UnitsProfileManagementView: UnitsProfileManagementViewEn,
        LeadsProfileManagementView: LeadsProfileManagementViewEn,
        UnitsStatusManagementView: UnitsStatusManagementViewEn,
        ActivitiesView: ActivitiesViewEn,
        PortfolioView: PortfolioViewEn,
        HistoryView: HistoryEn,
        WorkOrdersView: WorkOrdersViewEn,
        WorkOrdersManagementView: WorkOrdersManagementViewEn,
        IncidentsView: IncidentsViewEn,
        OperatingCostsView: OperatingCostsEn,
        AssetsView: AssetsViewEn,
        MaintenanceContracts: MaintenanceContractsEn,
        SalesTransactionsView: SalesTransactionsViewEn,
        SalesTransactionsProfileView: SalesTransactionsProfileViewEn,
        LeasingTransactionsProfileView: LeasingTransactionsProfileViewEn,
        LeasingTransactionsView: LeasingTransactionsViewEn,
        InvoicesView: InvoicesViewEn,
        BulkAssign: BulkAssignEn,
        MonthlyCalendarView: MonthlyCalendarViewEn,
        MyLeadView: MyLeadViewEn,
        MyReferralsView: MyReferralsEn,
        SalesAvailabilityView: SalesAvailabilityEn,
        LeasingAvailabilityView: LeasingAvailabilityEn,
        TemplatesView: TemplatesViewEn,
        UnitsDialogsManagementView: UnitsDialogsManagementViewEn,
        ImagesGalleryGroup: ImagesGalleryGroupEn,
        RolesManagementView: RolesManagementViewEn,
        ContactsInfoPublic: ContactsInfoPublicEn,
        Agents: AgentsEn,
        Campaign: CampaignEn,
        ActivitiesType: ActivitiesTypeEn,
        Reports: ReportsEn,
        LocationView: LocationEn,
        Dashboard: DashboardEn,
        ReportsBuilderg: ReportsBuildergEn,
        SystemNotifications: SystemNotificationsEn,
        LeadAssignAgentsTracking: LeadAssignAgentsTrackingEn,
        AgentRotationLog: AgentRotationLogEn,
        SystemConfigration: SystemConfigrationEn,
        ClientsSegmentation: MarketingClientsSegmentationEn,
        Policies: PoliciesEn,
        Inquires: InquiresEn,
        UsersLoginHistoryView: UsersLoginHistoryViewEn,
        LostLeadsView: LostLeadsViewEn,
        RotationLogView: RotationLogViewEn,
        ReportBuilderLogView: ReportBuilderLogViewEn,
        ExternalLinksView: ExternalLinksViewEn,
        MergeLogs: MergeLogsEn,
        TemplateShareLog: TemplateShareLogEn,
        leadOwnerView: AssignmentEn,
        AML: AMLEn,
        ActivitiesCommunicationLogs: ActivitiesCommunicationLogsEn,
        Portals: PortalsViewEn,
        UnqualifiedLeadView: UnqualifiedLeadViewEn,
        TransactionConfigurationView: TransactionConfigurationViewEn,
        OwnerRotationView: OwnerRotationViewEn
      },
      ar: {
        QAActivitiesView: QaActivityAr,
        Shared: SharedAr,
        BusinessGroupsView: BusinessGroupsViewAr,
        TeamView: TeamViewAr,
        UserDataView: UserDataViewAr,
        UsersView: UsersViewAr,
        BranchView: BranchViewAr,
        RolesView: RolesViewAr,
        HeaderView: HeaderViewAr,
        ImportDetailsView: ImportDetailsViewAr,
        OpenFileView: OpenFileViewAr,
        ContactsView: ContactsViewAr,
        FormBuilder: FormBuilderAr,
        LeadsView: LeadsViewAr,
        LookupsView: LookupsViewAr,
        PropertiesView: PropertiesViewAr,
        UnitsView: UnitsViewAr,
        LoginView: LoginViewAr,
        DataFiles: DataFilesAr,
        FormBuilderView: FormBuilderViewAr,
        ContactProfileManagementView: ContactProfileManagementViewAr,
        PropertiesProfileManagementView: PropertiesProfileManagementViewAr,
        UnitsProfileManagementView: UnitsProfileManagementViewAr,
        LeadsProfileManagementView: LeadsProfileManagementViewAr,
        UnitsStatusManagementView: UnitsStatusManagementViewAr,
        ActivitiesView: ActivitiesViewAr,
        PortfolioView: PortfolioViewAr,
        HistoryView: HistoryAr,
        WorkOrdersView: WorkOrdersViewAr,
        WorkOrdersManagementView: WorkOrdersManagementViewAr,
        IncidentsView: IncidentsViewAr,
        OperatingCostsView: OperatingCostsAr,
        AssetsView: AssetsViewAr,
        MaintenanceContracts: MaintenanceContractsAr,
        SalesTransactionsView: SalesTransactionsViewAr,
        SalesTransactionsProfileView: SalesTransactionsProfileViewAr,
        LeasingTransactionsProfileView: LeasingTransactionsProfileViewAr,
        LeasingTransactionsView: LeasingTransactionsViewAr,
        InvoicesView: InvoicesViewAr,
        BulkAssign: BulkAssignAr,
        MonthlyCalendarView: MonthlyCalendarViewAr,
        MyLeadView: MyLeadViewAr,
        MyReferralsView: MyReferralsAr,
        SalesAvailabilityView: SalesAvailabilityAr,
        LeasingAvailabilityView: LeasingAvailabilityAr,
        TemplatesView: TemplatesViewAr,
        UnitsDialogsManagementView: UnitsDialogsManagementViewAr,
        ImagesGalleryGroup: ImagesGalleryGroupAr,
        RolesManagementView: RolesManagementViewAr,
        ContactsInfoPublic: ContactsInfoPublicAr,
        Agents: AgentsAr,
        Campaign: CampaignAr,
        ActivitiesType: ActivitiesTypeAr,
        Reports: ReportsAr,
        LocationView: LocationAr,
        Dashboard: DashboardAr,
        ReportsBuilderg: ReportsBuildergAr,
        SystemNotifications: SystemNotificationsAr,
        LeadAssignAgentsTracking: LeadAssignAgentsTrackingAr,
        AgentRotationLog: AgentRotationLogAr,
        SystemConfigration: SystemConfigrationAr,
        ClientsSegmentation: MarketingClientsSegmentationAr,
        Policies: PoliciesAr,
        Inquires: InquiresAr,
        UsersLoginHistoryView: UsersLoginHistoryViewAr,
        LostLeadsView: LostLeadsViewAr,
        RotationLogView: RotationLogViewAr,
        ReportBuilderLogView: ReportBuilderLogViewAr,
        ExternalLinksView: ExternalLinksViewAr,
        MergeLogs: MergeLogsAr,
        TemplateShareLog: TemplateShareLogAr,
        leadOwnerView: AssignmentAr,
        AML: AMLAr,
        ActivitiesCommunicationLogs: ActivitiesCommunicationLogsAr,
        Portals: PortalsViewAr,
        UnqualifiedLeadView: UnqualifiedLeadViewAr,
        TransactionConfigurationView: TransactionConfigurationViewAr,
        OwnerRotationView: OwnerRotationViewAr
      },
      ru: {
        QAActivitiesView: QaActivityRu,
        Shared: SharedRu,
        BusinessGroupsView: BusinessGroupsViewRu,
        TeamView: TeamViewRu,
        UserDataView: UserDataViewRu,
        UsersView: UsersViewRu,
        BranchView: BranchViewRu,
        RolesView: RolesViewRu,
        HeaderView: HeaderViewRu,
        ImportDetailsView: ImportDetailsViewRu,
        OpenFileView: OpenFileViewRu,
        ContactsView: ContactsViewRu,
        FormBuilder: FormBuilderRu,
        LeadsView: LeadsViewRu,
        LookupsView: LookupsViewRu,
        PropertiesView: PropertiesViewRu,
        UnitsView: UnitsViewRu,
        LoginView: LoginViewRu,
        DataFiles: DataFilesRu,
        FormBuilderView: FormBuilderViewRu,
        ContactProfileManagementView: ContactProfileManagementViewRu,
        PropertiesProfileManagementView: PropertiesProfileManagementViewRu,
        UnitsProfileManagementView: UnitsProfileManagementViewRu,
        LeadsProfileManagementView: LeadsProfileManagementViewRu,
        UnitsStatusManagementView: UnitsStatusManagementViewRu,
        ActivitiesView: ActivitiesViewRu,
        PortfolioView: PortfolioViewRu,
        HistoryView: HistoryRu,
        WorkOrdersView: WorkOrdersViewRu,
        WorkOrdersManagementView: WorkOrdersManagementViewRu,
        IncidentsView: IncidentsViewRu,
        OperatingCostsView: OperatingCostsRu,
        AssetsView: AssetsViewRu,
        MaintenanceContracts: MaintenanceContractsRu,
        SalesTransactionsView: SalesTransactionsViewRu,
        SalesTransactionsProfileView: SalesTransactionsProfileViewRu,
        LeasingTransactionsProfileView: LeasingTransactionsProfileViewRu,
        LeasingTransactionsView: LeasingTransactionsViewRu,
        InvoicesView: InvoicesViewRu,
        BulkAssign: BulkAssignRu,
        MonthlyCalendarView: MonthlyCalendarViewRu,
        MyLeadView: MyLeadViewRu,
        MyReferralsView: MyReferralsRu,
        SalesAvailabilityView: SalesAvailabilityRu,
        LeasingAvailabilityView: LeasingAvailabilityRu,
        TemplatesView: TemplatesViewRu,
        UnitsDialogsManagementView: UnitsDialogsManagementViewRu,
        ImagesGalleryGroup: ImagesGalleryGroupRu,
        RolesManagementView: RolesManagementViewRu,
        ContactsInfoPublic: ContactsInfoPublicRu,
        Agents: AgentsRu,
        Campaign: CampaignRu,
        ActivitiesType: ActivitiesTypeRu,
        Reports: ReportsRu,
        LocationView: LocationRu,
        Dashboard: DashboardRu,
        ReportsBuilderg: ReportsBuildergRu,
        SystemNotifications: SystemNotificationsRu,
        LeadAssignAgentsTracking: LeadAssignAgentsTrackingRu,
        AgentRotationLog: AgentRotationLogRu,
        SystemConfigration: SystemConfigrationRu,
        ClientsSegmentation: MarketingClientsSegmentationRu,
        Policies: PoliciesRu,
        Inquires: InquiresRu,
        UsersLoginHistoryView: UsersLoginHistoryViewRu,
        LostLeadsView: LostLeadsViewRu,
        RotationLogView: RotationLogViewRu,
        ReportBuilderLogView: ReportBuilderLogViewRu,
        ExternalLinksView: ExternalLinksViewRu,
        MergeLogs: MergeLogsRu,
        TemplateShareLog: TemplateShareLogRu,
        leadOwnerView: AssignmentRu,
        AML: AMLRu,
        ActivitiesCommunicationLogs: ActivitiesCommunicationLogsRu,
        Portals: PortalsViewRu,
        UnqualifiedLeadView: UnqualifiedLeadViewRu,
        TransactionConfigurationView: TransactionConfigurationViewRu,
        OwnerRotationView: OwnerRotationViewRu
      },
      cn: {
        QAActivitiesView: QaActivityCn,
        Shared: SharedCn,
        BusinessGroupsView: BusinessGroupsViewCn,
        TeamView: TeamViewCn,
        UserDataView: UserDataViewCn,
        UsersView: UsersViewCn,
        BranchView: BranchViewCn,
        RolesView: RolesViewCn,
        HeaderView: HeaderViewCn,
        ImportDetailsView: ImportDetailsViewCn,
        OpenFileView: OpenFileViewCn,
        ContactsView: ContactsViewCn,
        FormBuilder: FormBuilderCn,
        LeadsView: LeadsViewCn,
        LookupsView: LookupsViewCn,
        PropertiesView: PropertiesViewCn,
        UnitsView: UnitsViewCn,
        LoginView: LoginViewCn,
        DataFiles: DataFilesCn,
        FormBuilderView: FormBuilderViewCn,
        ContactProfileManagementView: ContactProfileManagementViewCn,
        PropertiesProfileManagementView: PropertiesProfileManagementViewCn,
        UnitsProfileManagementView: UnitsProfileManagementViewCn,
        LeadsProfileManagementView: LeadsProfileManagementViewCn,
        UnitsStatusManagementView: UnitsStatusManagementViewCn,
        ActivitiesView: ActivitiesViewCn,
        PortfolioView: PortfolioViewCn,
        HistoryView: HistoryCn,
        WorkOrdersView: WorkOrdersViewCn,
        WorkOrdersManagementView: WorkOrdersManagementViewCn,
        IncidentsView: IncidentsViewCn,
        OperatingCostsView: OperatingCostsCn,
        AssetsView: AssetsViewCn,
        MaintenanceContracts: MaintenanceContractsCn,
        SalesTransactionsView: SalesTransactionsViewCn,
        SalesTransactionsProfileView: SalesTransactionsProfileViewCn,
        LeasingTransactionsProfileView: LeasingTransactionsProfileViewCn,
        LeasingTransactionsView: LeasingTransactionsViewCn,
        InvoicesView: InvoicesViewCn,
        BulkAssign: BulkAssignCn,
        MonthlyCalendarView: MonthlyCalendarViewCn,
        MyLeadView: MyLeadViewCn,
        MyReferralsView: MyReferralsCn,
        SalesAvailabilityView: SalesAvailabilityCn,
        LeasingAvailabilityView: LeasingAvailabilityCn,
        TemplatesView: TemplatesViewCn,
        UnitsDialogsManagementView: UnitsDialogsManagementViewCn,
        ImagesGalleryGroup: ImagesGalleryGroupCn,
        RolesManagementView: RolesManagementViewCn,
        ContactsInfoPublic: ContactsInfoPublicCn,
        Agents: AgentsCn,
        Campaign: CampaignCn,
        ActivitiesType: ActivitiesTypeCn,
        Reports: ReportsCn,
        LocationView: LocationCn,
        Dashboard: DashboardCn,
        ReportsBuilderg: ReportsBuildergCn,
        SystemNotifications: SystemNotificationsCn,
        LeadAssignAgentsTracking: LeadAssignAgentsTrackingCn,
        AgentRotationLog: AgentRotationLogCn,
        SystemConfigration: SystemConfigrationCn,
        ClientsSegmentation: MarketingClientsSegmentationCn,
        Policies: PoliciesCn,
        Inquires: InquiresCn,
        UsersLoginHistoryView: UsersLoginHistoryViewCn,
        LostLeadsView: LostLeadsViewCn,
        RotationLogView: RotationLogViewCn,
        ReportBuilderLogView: ReportBuilderLogViewCn,
        ExternalLinksView: ExternalLinksViewCn,
        MergeLogs: MergeLogsCn,
        TemplateShareLog: TemplateShareLogCn,
        leadOwnerView: AssignmentCn,
        AML: AMLCn,
        ActivitiesCommunicationLogs: ActivitiesCommunicationLogsCn,
        Portals: PortalsViewCn,
        UnqualifiedLeadView: UnqualifiedLeadViewCn,
        TransactionConfigurationView: TransactionConfigurationViewCn,
        OwnerRotationView: OwnerRotationViewCn
      }
    },
  });
  if (localStorage.getItem('localization')) {
    moment.tz.setDefault(config.timeZone);
    i18next.changeLanguage(JSON.parse(localStorage.getItem('localization')).currentLanguage);
    const isRtl = JSON.parse(localStorage.getItem('localization')).currentLanguage === 'ar';
    if (isRtl) {
      const direction =
        JSON.parse(localStorage.getItem('localization')).currentLanguage === 'ar' ? 'rtl' : '';
      document.body.setAttribute('class', direction);
      document.body.setAttribute('dir', direction);
      document.documentElement.lang = JSON.parse(
        localStorage.getItem('localization')
      ).currentLanguage;
    }
  } else {
    localStorage.setItem('localization', JSON.stringify({ currentLanguage: 'en', isRtl: false }));
    i18next.changeLanguage('en');
  }
}
export const languageChange = (currentLanguage) => {
  const isRtl = currentLanguage === 'ar';
  const direction = currentLanguage === 'ar' ? 'rtl' : '';
  localStorage.setItem('localization', JSON.stringify({ currentLanguage, isRtl }));
  document.body.setAttribute('class', direction);
  document.body.setAttribute('dir', direction);
  document.documentElement.lang = currentLanguage;
  i18next.changeLanguage(currentLanguage);
  GlobalRerender();
};
localizationInit();
