
export const DateFilterTypesEnum = {
    "CreatedOnDate":{
        key: 1,
    },
    "UpdatedOnDate":{
        key: 2,
    },
    "LastAssignDate":{
        key: 3,
    },
    "ListingDate":{
        key: 4,
    },
    "PublishDate":{ 
        key: 5,
    },
    "ExpiredListingDate":{ 
        key: 6,
    },
    "SaleTransactedDate":{ 
        key: 7,
    },
    "SaleReservedTransactionDate":{ 
        key: 8,
    },
    "LeaseTransactedDate":{ 
        key: 9,
    },
    "LeaseReservedTransactionDate":{ 
        key: 10,
    },
}
