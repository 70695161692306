import React, { useReducer, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './UnitTransactionView.scss';
import { PageHeading } from '../../../SharedComponents';
import { AutocompleteComponent, SelectComponet } from '../../../Components';
import {
  lookupItemsGetId,
  getProperties,
  UnitsAdvanceSearchTest,
  GetAllUnitLeadOwners,
  ValidateTransaction,
} from '../../../Services';
import { ButtonBase } from '@material-ui/core';
import { StaticLookupsIds } from '../../../assets/json/StaticLookupsIds.jsx';
import { PropertyInfo } from '../UnitsSalesView/UnitsSalesProfileManagement/Sections/UnitInformationComponent/PropertyInfo/PropertyInfo.jsx';
import { bottomBoxComponentUpdate, GlobalHistory, showError } from '../../../Helper';
import { UnitsStatusEnum } from '../../../Enums';
import { UnitMapper } from '../UnitsSalesView/UnitMapper/UnitMapper.jsx';
import { ContactsDialog as UnitCreateDialog} from '../../Home/FormBuilder/Dialogs/ContactsDialog.jsx';

export const UnitTransactionParametersView = ({ isEditView }) => {
  const parentTranslationPath = 'UnitsProfileManagementView';
  const translationPath = 'unit-transaction.';
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [selected, setSelected] = useReducer(reducer, {
    transactionType: "reserve",
    city: null,
    district: null,
    community: null,
    subCommunity: null,
    property: null,
    unit: null,
  });
  const [data, setData] = useReducer(reducer, {
    cities: [],
    districts: [],
    communities: [],
    subCommunities: [],
    properties: [],
    units: [],
  });
  const [isOpenUnitCreateDialog, setIsOpenUnitCreateDialog] = useState(false);
  const [unitSearchType , setUnitSearchType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const getAddressLookupsById = async (lookupTypeId, lookupParentId) => {
    const response = await lookupItemsGetId({
      lookupTypeId,
      lookupParentId,
    });
    if (!(response && response.status && response.status !== 200)) {
      if (lookupTypeId === StaticLookupsIds.Cities)
        setData({ id: 'cities', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.District)
        setData({ id: 'districts', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.Community)
        setData({ id: 'communities', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.SubCommunity)
        setData({ id: 'subCommunities', value: response || [] });
    }
  };

  const getPropertiesOptions = async (searchValue) => {
    const res = await getProperties({ pageSize: 10, pageIndex: 0, search: searchValue || '' });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'properties', value: res?.result || [] });
    else setData({ id: 'properties', value: [] });
  };


  const getUnitsOptions = async ({ propertyName, unitName, transactionType, unitRefno, unitNumber }) => {

    setIsLoading((prevState) => ({ ...prevState, units: true }));

    const criteria = {};

    if (propertyName)
      criteria['property_name'] = [
        {
          searchType: 2,
          value: propertyName,
        },
      ];
    if (unitName)
      criteria['UnitName'] = [
        {
          searchType: 2,
          value: unitName
        }
      ];
    if (unitRefno)
      criteria['unit_ref_no'] = [
        {
          searchType: 2,
          value: unitRefno
        }
      ];
    if (unitNumber)
      criteria['unit_number'] = [
        {
          searchType: 2,
          value: unitNumber
        }
      ];

    if (transactionType? transactionType === "reserve" : selected.transactionType === "reserve" )
      criteria['status'] = [
        {
          searchType: 1,
          value: 'draft',
        },
        {
          searchType: 1,
          value: 'available',
        },
      ]; else if (transactionType? transactionType === "sale" : selected.transactionType === "sale" )
      criteria['status'] = [
        {
          searchType: 1,
          value: 'draft',
        },
        {
          searchType: 1,
          value: 'available',
        },
        {
          searchType: 1,
          value: 'reservedsale',
        },
      ];

    const body = {
      criteria,
      filterBy: 'createdOn',
      orderBy: 2,
      OperationType: 'Sale',
    };

    const res = await UnitsAdvanceSearchTest({ pageIndex: 0, pageSize: 10 }, body);
    if (!(res && res.status && res.status !== 200)) {

        const units = ((res && res.result) || []).map((item) => item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit));
        setData({ id: 'units', value: units || [] });
      } else setData({ id: 'units', value: [] });

    setIsLoading((prevState) => ({ ...prevState, units: false }));

  };

  const validateTransaction = async () => {
    const unitId = selected.unit?.id || selected.unit?.unitId || null;
    const res = await ValidateTransaction(unitId);
    if (!(res && res.status && res.status !== 200)) {
      const transactionStatus = selected.transactionType === "reserve"? UnitsStatusEnum.ReservedSale.key : UnitsStatusEnum.Sale.key;
      if(res==true) GlobalHistory.push(`/home/units-sales/unit-transaction?status=${transactionStatus}&id=${unitId}&from=1`);
    } else {
      const errorMSG = res?.data?.Message?.split(':')[1];
      const formattedMSG = errorMSG
        ? errorMSG.split('_').join(' ')
        : t(`${translationPath}something-went-wrong`);
      showError(formattedMSG);
    }
  };

  const cancelHandler = () => {
    GlobalHistory.push(`/home/units-sales/view`);
  };

  const startHandler = () => {
    validateTransaction();
  };

  const unitRefSaveHandler = (createdUnit) => {
    const unitJSONRes = createdUnit?.unitJson && JSON.parse(createdUnit.unitJson);
    const unit = {
      unitId: createdUnit?.unitId || null,
      ...(unitJSONRes?.unit || {}),
    }

    setSelected({
      id: 'edit',
      value: {
        ...selected,
        city: unit?.city,
        district: unit?.district,
        community: unit?.community,
        subCommunity: unit?.sub_community,
        property: {
          propertyId: unit?.property_name?.id,
          name: unit?.property_name?.name,
        },
        unit: unit,
      },
    });

  }


  useEffect(() => {
    getAddressLookupsById(StaticLookupsIds.Cities, StaticLookupsIds.UAECities);
  }, []);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-between d-flex-row-reverse'>
        <div>
          <ButtonBase
            onClick={startHandler}
            disabled={!selected.unit}
            className='btns theme-solid mx-2'>
            <span className='px-2'>{t(`${translationPath}start`)}</span>
          </ButtonBase>
        </div>
        <div className='action-item'>
          <ButtonBase className='btns theme-transparent mx-2' onClick={cancelHandler}>
            <span>{t(`${translationPath}cancel`)}</span>
          </ButtonBase>
        </div>
      </div>
    );
  });

  useEffect(() => {
    getPropertiesOptions();
    getUnitsOptions({});
  }, []);

  return (
    <div className='UnitTransactionsView px-4 py-3'>
      <PageHeading
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerTitle={'unit-transaction'}
        subTitle={'unit-transaction-heading'}
      />

      <div>
        <p className='b-600'>{t(`${translationPath}select-transaction-type`)}</p>

        <div className='Active-btt-menu mt-2'>
          <ButtonBase
            className={`d-flex-column my-2 mr-2 option-button ${selected.transactionType === "reserve" ? 'is-active' : ''
              }`}
            onClick={() => {
              const propertyName = selected.property?.property?.property_name || null;

              setSelected({ id: 'unit', value: null });
              getUnitsOptions({ propertyName, transactionType: "reserve" });
              setSelected({ id: 'transactionType', value: "reserve" });
            }}>
            <span className='mdi mdi-bookmark-check mb-2' />
            <span className='b-600'>{t(`${translationPath}Reserve-for-sale`)}</span>
          </ButtonBase>
          <ButtonBase
            className={`d-flex-column my-2 ml-2 option-button ${selected.transactionType === "sale" ? 'is-active' : ''
              }`}
            onClick={() => {
              const propertyName = selected.property?.property?.property_name || null;
              
              setSelected({ id: 'unit', value: null });
              getUnitsOptions({ propertyName, transactionType: "sale" });
              setSelected({ id: 'transactionType', value: "sale" });
            }}>
            <span className='mdi mdi-home-currency-usd mb-2' />
            <span className='b-600'>{t(`${translationPath}sale`)}</span>
          </ButtonBase>
        </div>
      </div>

      <div className='devider my-3'></div>

      <div className=''>
        <p className='b-600'>{t(`${translationPath}unit-info`)}</p>

        <div className='d-flex'>
          <AutocompleteComponent
            idRef='cityfilterRef'
            labelValue={`city`}
            selectedValues={selected.city}
            wrapperClasses='w-min-unset m-2'
            multiple={false}
            data={data.cities || []}
            displayLabel={(option) => option.lookupItemName || ''}
            withoutSearchButton
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
              const cityId = newValue && newValue.lookupItemId;
              setSelected({
                id: 'edit',
                value: {
                  ...selected,
                  city: newValue,
                  district: null,
                  community: null,
                  subCommunity: null,
                  property: null,
                  unit: null,
                },
              });
              if (cityId) getAddressLookupsById(StaticLookupsIds.District, cityId);
            }}
          />
          <AutocompleteComponent
            idRef='lfilterDistricteIdRef'
            labelValue={`district`}
            wrapperClasses='w-min-unset m-2'
            selectedValues={selected.district || null}
            multiple={false}
            data={data.districts || []}
            onOpen={() => {
              const cityId = selected.city?.lookupItemId;

              if (isEditView && cityId && data.districts?.length == 0)
                getAddressLookupsById(StaticLookupsIds.District, cityId);
            }}
            displayLabel={(option) => option.lookupItemName || ''}
            withoutSearchButton
            isWithError
            isDisabled={!selected.city}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
              const districtId = newValue && newValue.lookupItemId;

              setSelected({
                id: 'edit',
                value: {
                  ...selected,
                  district: newValue,
                  community: null,
                  subCommunity: null,
                  property: null,
                  unit: null,
                },
              });
              if (districtId) getAddressLookupsById(StaticLookupsIds.Community, districtId);
            }}
          />
          <AutocompleteComponent
            idRef='lfilterCommunitiesIdRef'
            labelValue={`community`}
            wrapperClasses='w-min-unset m-2'
            selectedValues={selected.community || null}
            multiple={false}
            data={data.communities || []}
            onOpen={() => {
              const districtId = selected.district?.lookupItemId;

              if (isEditView && districtId && data.communities?.length == 0)
                getAddressLookupsById(StaticLookupsIds.Community, districtId);
            }}
            displayLabel={(option) => option.lookupItemName || ''}
            withoutSearchButton
            isWithError
            isDisabled={!selected.district}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
              const communityId = newValue && newValue.lookupItemId;

              setSelected({
                id: 'edit',
                value: {
                  ...selected,
                  community: newValue,
                  subCommunity: null,
                  property: null,
                  unit: null,
                },
              });
              if (communityId) getAddressLookupsById(StaticLookupsIds.SubCommunity, communityId);
            }}
          />
        </div>
        <div className='d-flex'>
          <AutocompleteComponent
            idRef='lfilterSubCommunitiesIdRef'
            labelValue={'SubCommunity'}
            wrapperClasses='w-min-unset m-2'
            selectedValues={selected.subCommunity || null}
            multiple={false}
            data={data.subCommunities || []}
            onOpen={() => {
              const communityId = selected.community?.lookupItemId;

              if (isEditView && communityId && data.subCommunities?.length == 0)
                getAddressLookupsById(StaticLookupsIds.SubCommunity, communityId);
            }}
            displayLabel={(option) => option.lookupItemName || ''}
            withoutSearchButton
            isWithError
            isDisabled={!selected.community}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
              const subCommunityName = newValue && newValue.lookupItemName;

              setSelected({
                id: 'edit',
                value: {
                  ...selected,
                  subCommunity: newValue,
                  property: null,
                  unit: null,
                },
              });

              if (subCommunityName) getPropertiesOptions(subCommunityName);
            }}
          />
          <AutocompleteComponent
            idRef='propertiesRef'
            labelValue={`property`}
            wrapperClasses='w-min-unset m-2'
            selectedValues={selected.property || null}
            multiple={false}
            data={data.properties || []}
            displayLabel={(option) => option?.property?.property_name || option?.name || ''}
            withoutSearchButton
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
              const propertyName = newValue?.property?.property_name || null;

              if (!selected.subCommunity) {
                setSelected({
                  id: 'edit',
                  value: {
                    ...selected,
                    city: newValue?.property?.city,
                    district: newValue?.property?.district,
                    community: newValue?.property?.community,
                    subCommunity: newValue?.property?.sub_community,
                    property: newValue,
                    unit: null,
                  },
                });
              } else {
                setSelected({
                  id: 'edit',
                  value: {
                    ...selected,
                    property: newValue,
                    unit: null,
                  },
                });
              }
              if (propertyName) getUnitsOptions({ propertyName });
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getPropertiesOptions(value);
              }, 1200);
            }}
          />
          <AutocompleteComponent
            idRef='unitRef'
            labelValue={`Unit`}
            wrapperClasses='w-min-unset m-2'
            selectedValues={selected.unit}
            multiple={false}
            data={data.units || []}
            displayLabel={(option) => option?.refNo || option?.unit_ref_no || ''}
            withoutSearchButton
            isWithError
            isLoading={isLoading.units}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {

                setSelected({
                  id: 'edit',
                  value: {
                    ...selected,
                    city: newValue?.city,
                    district: newValue?.district,
                    community: newValue?.community,
                    subCommunity: newValue?.sub_community,
                    property: {
                      propertyId: newValue?.property_name?.id,
                      name: newValue?.property_name?.name,
                    },
                    unit: newValue,
                  },
                });

            }}
            onInputChange={(e) => {
              const  value  = e?.target?.value;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                const unitRefno = unitSearchType == 0? value : null;
                const unitNumber = unitSearchType == 1? value : null;

                getUnitsOptions({ unitRefno, unitNumber });
              }, 1200);
            }}
            buttonOptions={{
              className: 'btns-icon theme-solid bg-blue-lighter',
              iconClasses: 'mdi mdi-plus',
              isDisabled: false,
              isRequired: false,
              onActionClicked: () => {
                const unitDialogSettings = JSON.stringify({itemId:"units", type:"1"}) 
                localStorage.setItem('current', unitDialogSettings);

                setIsOpenUnitCreateDialog(true);

              },
            }}
            inputStartAdornment={(
              <SelectComponet
                data={[
                  {
                    key: 0,
                    value: 'RefNumber',
                  },
                  {
                    key: 1,
                    value: 'unitNumber',
                  },
                ]}
                displayEmpty={false}
                value={unitSearchType}
                onSelectChanged={(val)=> setUnitSearchType(val)}
                inputPlaceholder={t(`${translationPath}Agent`)}
                valueInput='key'
                textInput='value'
                wrapperClasses='over-input-select w-70'
                idRef='relatedToTypeRef'
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            )}
            filterOptions={(options) => options}
          />
        </div>
      </div>
      {selected.property && (
        <div className='information-container my-3'>
          {selected.property?.propertyId && (
            <PropertyInfo
              propertyId={selected.property.propertyId}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          )}
        </div>
      )}
      <UnitCreateDialog
        open={isOpenUnitCreateDialog}
        onSave={() => { }}
        closeDialog={() => {
          setIsOpenUnitCreateDialog(false);
        }}
        isOpenInsideForm={isOpenUnitCreateDialog}
        isWithUnitRef
        unitRefSaveHandler={unitRefSaveHandler}
      />
    </div>
  );
};
