import React, { useState, useEffect, useRef, useCallback } from 'react';
import { KenbanBoardCard, TaskDetailsSideMenu } from '../../Utilities';
import { TaskStatusEnum } from '../../../../../Enums';
import { sideMenuComponentUpdate, sideMenuIsOpenUpdate } from '../../../../../Helper';
import './KenbanCardsLayout.scss';
import { useTranslation } from 'react-i18next';

export function KenbanCardsLayout({ data, paginationChangeHandler, paginationFilter }) {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'kenban-Board.';

  const { t } = useTranslation(parentTranslationPath);
  const kenbanBoardCols = useRef({});
  const [activeTaskID, setActiveTaskID] = useState(null);
//add flag for when a case of : user reachs the end of the board --> calls API --> All data is empty 
    // --> prevent calling apai again --> 
    //--> until filter is provoked 
  const checkIsScrollReachEnd = () => {
    const scrollTopCeil = Math.ceil(kenbanBoardCols.current.scrollTop);
    const scrollTopFloor = Math.floor(kenbanBoardCols.current.scrollTop);

    const scrollHeight = kenbanBoardCols.current.scrollHeight;
    const offsetHeight = kenbanBoardCols.current.offsetHeight;
    const heightDiff = scrollHeight - offsetHeight;

    const isScrollReachEnd = (scrollTopCeil === heightDiff) ||
      (scrollTopFloor === heightDiff) ||
      (scrollTopCeil + 1 === heightDiff);

    return isScrollReachEnd;
  };
  const resetScrollPosition = () => { kenbanBoardCols.current.scrollTop = 0 }

  const boardScrollHandler = () => {

    if (checkIsScrollReachEnd()) {
      const newPageIndex = paginationFilter?.pageIndex + 1;
      paginationChangeHandler(newPageIndex)
    }
  }

  const cardClickHandler = useCallback((event, item, index) => {
    event.stopPropagation();
    event.preventDefault();
    setActiveTaskID(item?.id || null);

    sideMenuComponentUpdate(
      <TaskDetailsSideMenu
        taskId={item?.id}
        campaignId={item?.campaignId}
        buttonsGroup={"KanbanView"}
        closeSideMenu={() => {
          sideMenuIsOpenUpdate(false);
          setActiveTaskID(null);
        }}
        reloadData={() => paginationChangeHandler(1)}
      />
    );
    sideMenuIsOpenUpdate(true);
  }, [])

  useEffect(() => {
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  }, []);

  useEffect(() => {
    if (checkIsScrollReachEnd() && paginationFilter.pageIndex === 1)
      resetScrollPosition()
  }, [paginationFilter])


  return (
    <div className='kenban-cards-layout board-overflow'>
      <div className='kenban-cols-headrs mt-4'>
        {Object.values(TaskStatusEnum).map((item) => (
          <div
            className={`kenban-col-header d-flex-v-center-h-between 
           default-d-flex border-gray-tertiary radius-xl p-3 mb-3 ${item.classes}`}>
            <span className='text-primary b-600 fz-16'>{t(`${translationPath}${item.label}`)}</span>
            {data && data[item.key] && data[item.key].totalCount ? (
              <div className='col-count px-2 bg-col-gray-light border-gray-secondary radius-full px-1 ml-1'>
                <span className='text-tertiary fz-12 d-block px-1'>
                  {data[item.key].totalCount}
                </span>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div ref={kenbanBoardCols} className='kenban-cols' onScroll={boardScrollHandler}>
        {data &&
          Object.values(data).map((column, index) => (
            <div ref={kenbanBoardCols} key={`column-number-${index}`} className='kenban-column p-2 radius-md'>
              {column.result.map((item, index) => (
                <KenbanBoardCard key={`task-${item.title}-${index}`} activeTaskID={activeTaskID} taskData={item} 
                cardClickHandler={(event) => cardClickHandler(event, item, index)} />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
}
