import React, { useState, useCallback, useReducer, useEffect } from 'react';
import { DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next'; 
import Joi from 'joi';
import { showError, showSuccess, GetParams , getErrorByName  } from '../../../../../../Helper';
import {
  Spinner,
  Inputs,
  DatePickerComponent,
  AutocompleteComponent,
  SelectComponet,
} from '../../../../../../Components';
import {
  PropertyPaymentPlan,
  PaymentPlanStatus,
} from '../../../../../../Enums';

import { GetLookupItemsByLookupTypeName, CreatePaymentPlan, UpdatePaymentPlan } from '../../../../../../Services';
import { Tabs, Tab, makeStyles, InputAdornment } from '@material-ui/core';
export const PaymentPlanManangementDialog = ({
  parentTranslationPath,
  translationPath,
  activeItem,
  isDialogOpen,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '0px',
      paddingBottom: '5px',
      textTransform: 'initial',
    },
    indicator: {
      backgroundColor: '#3b72d9',
    },
    labelIcon: {
      minHeight: 'unset',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
    fixed: {
      width: 'fit-content',
      margin: 'auto',
    },
    scroller: {
      flex: 'unset',
    },
  }));

  const classes = useStyles();
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const [filter, setFilter] = useState({
    pageSize: 100,
    pageIndex: 0,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useReducer(reducer, {
    paymentType: false,
    planType: false,
    amountNature: false,
    frequency: false,
  });
  const [selected, setSelected] = useReducer(reducer, {
    paymentType: null,
    planType: null,
    amountNature: null,
    frequency: null,
    installmentsTotal: 0,
  });
  const [data, setData] = useReducer(reducer, {
    paymentTypes: [],
    planTypes: [],
    amountNature: [],
    frequency: [],
  });

  const [state, setState] = useReducer(reducer, {
    propertyId: +GetParams('id'),
    paymentTypeId: null,
    planTypeId: null,
    amountNatureId: null,
    frequencyId: null,
    propertyPlanStatusId: null,
    downPayment: 0,
    remainPayment: 0,
    numberOfInstallment: 0,
    paymentPlanInstallmentDtos: [],
    description: '',
  });

  const schema = Joi.object({
    planTypeId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}plan-type-is-required`),
        'number.empty': t(`${translationPath}plan-type-is-required`),
      }),
      paymentTypeId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}paymentType-is-required`),
        'number.empty': t(`${translationPath}paymentType-is-required`),
      }),
      propertyPlanStatusId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}plan-status-is-required`),
        'number.empty': t(`${translationPath}plan-status-is-required`),
      }),
      frequencyId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}frequency-is-required`),
        'number.empty': t(`${translationPath}frequency-is-required`),
      }),
      amountNatureId : 
       Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}amount-nature-is-required`),
        'number.empty': t(`${translationPath}amount-nature-is-required`),
      }),
  
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);


  const getPaymentTypeLookups = useCallback(async () => {
    setIsLoading({ id: 'paymentType', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: PropertyPaymentPlan.PaymentType.lookupTypeName,
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'paymentTypes', value: res.result || [] });
    } else setData({ id: 'paymentTypes', value: [] });
    setIsLoading({ id: 'paymentType', value: false });
  }, []);

  const getPlanTypeLookups = useCallback(async () => {
    setIsLoading({ id: 'planType', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: PropertyPaymentPlan.PlanType.lookupTypeName,
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'planTypes', value: res.result || [] });
    } else setData({ id: 'planTypes', value: [] });
    setIsLoading({ id: 'planType', value: false });
  }, []);

  const getAmountNatureLookups = useCallback(async () => {
    setIsLoading({ id: 'amountNature', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: PropertyPaymentPlan.AmountNature.lookupTypeName,
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'amountNature', value: res.result || [] });
    } else setData({ id: 'amountNature', value: [] });
    setIsLoading({ id: 'amountNature', value: false });
  }, []);

  const getFrequencyLookups = useCallback(async () => {
    setIsLoading({ id: 'frequency', value: true });

    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: PropertyPaymentPlan.Frequency.lookupTypeName,
      pageSize: 100,
      pageIndex: filter.pageIndex
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'frequency', value: res.result || [] });
    } else setData({ id: 'frequency', value: [] });
    setIsLoading({ id: 'frequency', value: false });
  }, []);

  const handleTabClick = (event, newValue) => {
    setTabIndex(newValue);
  };

  const fillInitialInstallments = () => {
    const paymentInstallments = [];

    for (let index = 1; index <= state.numberOfInstallment; index++) {
      paymentInstallments.push({
        installmentNumber: index,
        instalmentDate: '',
        amountPercentage: 0,
        frequency : selected.frequency , 

      });
    }

    setState({ id: 'paymentPlanInstallmentDtos', value: paymentInstallments });
  };

  const saveHandler = async (event) => {
    event.preventDefault();

    setIsSaving(true);
    setIsSubmitted(true);

    if (schema.error) {
      showError(t('please-fill-all-Required-fields'));
      setIsSubmitted(false);
      setIsSaving(false);
      return;
    }
    const body = { ...state };
    const res =
      (activeItem &&
        (await UpdatePaymentPlan({
          paymentPlanId: state.propertyPaymentPlanId,
          body,
        }))) ||
      (await CreatePaymentPlan(body));
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t(
          `${translationPath}${(activeItem && `payment-plan-updated-successfully`) ||
          `payment-plan-created-successfully`
          }`
        )
      );
      if (onSave) onSave();
    } else {
      showError(
        t(
          `${translationPath}${(activeItem && `payment-plan-updated-failed`) || `payment-plan-created-failed`
          }`
        )
      );
    }
    setIsSubmitted(false);
    setIsSaving(false);
  };

  const calculateInstallmentsTotal = useCallback( () => {
    if(state.paymentPlanInstallmentDtos&&state.paymentPlanInstallmentDtos.length>0){
      const installmentsTotal =  state.paymentPlanInstallmentDtos.reduce((total, item)=> total + item.amountPercentage, 0)
      setSelected({ id: 'installmentsTotal', value: installmentsTotal })
    }
  }, [state.paymentPlanInstallmentDtos]);

  const checkIsValidInstallmentsTotal = (newInstallmentNumber, newInstallmentAmount) =>{
    let newInstallmentTotal = 0;
    state.paymentPlanInstallmentDtos.forEach(item => {
      if(item.installmentNumber !== newInstallmentNumber)
      newInstallmentTotal = newInstallmentTotal + item.amountPercentage;
    });
    newInstallmentTotal = newInstallmentTotal + newInstallmentAmount;

    const isNewInstallmentTotalValid = (newInstallmentTotal <= state.remainPayment);
    return isNewInstallmentTotalValid;
  }

  useEffect(()=>{
    calculateInstallmentsTotal()
  },[state.paymentPlanInstallmentDtos]);
  
  useEffect(() => {
    getPaymentTypeLookups();
    getPlanTypeLookups();
    getAmountNatureLookups();
    getFrequencyLookups();
  }, []);

  useEffect(() => {
    if (activeItem) {
      setState({
        id: 'edit',
        value: {
          propertyId: activeItem.propertyId,
          propertyPaymentPlanId: activeItem.propertyPaymentPlanId,
          paymentTypeId: activeItem.paymentTypeLookupDto?.paymentTypeLookupId,
          planTypeId: activeItem.planTypeLookupDto?.planTypeLookupId,
          amountNatureId: activeItem.amountNatureLookup?.amountNatureLookupId,
          frequencyId: activeItem.frequencyLookupDto?.frequencyLookupId,
          propertyPlanStatusId: activeItem.propertyPlanStatusId,
          downPayment: activeItem.downPayment,
          remainPayment: activeItem.remainPayment,
          numberOfInstallment: activeItem.numberOfInstallment,
          paymentPlanInstallmentDtos: activeItem.paymentPlanInstallmentDtos || [],
          description: activeItem.description || '',
        },
      });
      if (activeItem.paymentTypeLookupDto) {
        setSelected({
          id: 'paymentType',
          value: {
            lookupItemId: activeItem.paymentTypeLookupDto.paymentTypeLookupId,
            lookupItemName: activeItem.paymentTypeLookupDto.paymentTypeLookupName,
          },
        });
      }

      if (activeItem.planTypeLookupDto) {
        setSelected({
          id: 'planType',
          value: {
            lookupItemId: activeItem.planTypeLookupDto.planTypeLookupId,
            lookupItemName: activeItem.planTypeLookupDto.planTypeLookupName,
          },
        });
      }
      if (activeItem.frequencyLookupDto) {
        setSelected({
          id: 'frequency',
          value: {
            lookupItemId: activeItem.frequencyLookupDto.frequencyLookupId,
            lookupItemName: activeItem.frequencyLookupDto.frequencyLookupName,
          },
        });
      }
      if (activeItem.amountNatureLookup) {
        setSelected({
          id: 'amountNature',
          value: {
            lookupItemId: activeItem.amountNatureLookup.amountNatureLookupId,
            lookupItemName: activeItem.amountNatureLookup.amountNatureLookupName,
          },
        });
      }
    }
  }, [activeItem, data]);

  useEffect(() => {
    fillInitialInstallments();
  }, [state.numberOfInstallment, state.downPayment, state.frequencyId]);

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        className='activities-management-dialog-wrapper'>
        <Spinner isActive={isSaving} isAbsolute />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(
              `${translationPath}${(activeItem && 'edit-payment-plan') || 'add-new-payment-plan'}`
            )}
          </DialogTitle>
          <DialogContent>
            <Tabs
              value={tabIndex}
              onChange={handleTabClick}
              aria-label='icon label tabs'
              textColor='primary'
              classes={{
                indicator: classes.indicator,
                scroller: classes.scroller,
                fixed: classes.fixed,
              }}>
              <Tab
                classes={{
                  root: classes.root,
                  wrapper: classes.wrapper,
                  labelIcon: classes.labelIcon,
                }}
                icon={<span className='mr-2 mdi mdi-format-list-checks ' />}
                label={t(`${translationPath}details`)}
              />
              {state.paymentPlanInstallmentDtos && state.paymentPlanInstallmentDtos.length > 0 && state.frequencyId && (
                <Tab
                  classes={{
                    root: classes.root,
                    wrapper: classes.wrapper,
                    labelIcon: classes.labelIcon,
                  }}
                  icon={<span className='mr-2 mdi mdi-file-document' />}
                  label={t(`${translationPath}installments`)}
                />
              )}
            </Tabs>

            {tabIndex === 0 && (
              <div className='d-flex p-3'>
                <div className='w-50 mx-2'>
                  <div className='my-1'></div>
                  <div className='my-2'>
                    <AutocompleteComponent
                      idRef='planTypeIdRef'
                      labelValue='plan-type'
                      labelClasses='Requierd-Color'
                      selectedValues={selected.planType}
                      multiple={false}
                      data={data.planTypes}
                      displayLabel={(option) => option.lookupItemName || ''}
                      renderOption={(option) => option.lookupItemName || ''}
                      getOptionSelected={(option) => option.lookupItemId === state.planTypeId}
                      withoutSearchButton
                      isLoading={isLoading.planType}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        setSelected({ id: 'planType', value: newValue });
                        setState({
                          id: 'planTypeId',
                          value: (newValue && newValue.lookupItemId) || null,
                        });
                      }}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, 'planTypeId').message}
                      error={getErrorByName(schema, 'planTypeId').error}
                    />
                  </div>
                  <div className='my-1'>
                    <AutocompleteComponent
                      idRef='paymentTypeIdRef'
                      labelValue='payment-type'
                      labelClasses='Requierd-Color'
                      selectedValues={selected.paymentType}
                      multiple={false}
                      data={data.paymentTypes}
                      displayLabel={(option) => option.lookupItemName || ''}
                      renderOption={(option) => option.lookupItemName || ''}
                      getOptionSelected={(option) => option.lookupItemId === state.paymentTypeId}
                      withoutSearchButton
                      isLoading={isLoading.paymentType}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        setSelected({ id: 'paymentType', value: newValue });
                        setState({
                          id: 'paymentTypeId',
                          value: (newValue && newValue.lookupItemId) || null,
                        });
                      }}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, 'paymentTypeId').message}
                      error={getErrorByName(schema, 'paymentTypeId').error}
                    />
                  </div>
                  <div className='my-3'>
                    <AutocompleteComponent
                      idRef='amountNatureIdRef'
                      labelValue='amount-nature'
                      labelClasses='Requierd-Color'
                      selectedValues={selected.amountNature}
                      multiple={false}
                      data={data.amountNature}
                      displayLabel={(option) => option.lookupItemName || ''}
                      renderOption={(option) => option.lookupItemName || ''}
                      getOptionSelected={(option) => option.lookupItemId === state.amountNatureId}
                      withoutSearchButton
                      isLoading={isLoading.amountNature}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        setSelected({ id: 'amountNature', value: newValue });
                        setState({
                          id: 'amountNatureId',
                          value: (newValue && newValue.lookupItemId) || null,
                        });
                      }}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, 'amountNatureId').message}
                      error={getErrorByName(schema, 'amountNatureId').error}
                    />
                  </div>
                  <div className='my-3'>
                    <Inputs
                      idRef='descriptionIdRef'
                      labelValue='description'
                      value={state.description || ''}
                      multiline
                      rows={4}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onInputChanged={(event) => {
                        setState({ id: 'description', value: event.target.value });
                      }}
                      
                    />
                  </div>
                </div>

                <div className='w-50 mx-2'>
                  <div className='my-2'>
                    <SelectComponet
                      idRef='planStatusIdRef'
                      labelValue='plan-status'
                      labelClasses='Requierd-Color'
                      data={Object.values(PaymentPlanStatus)}
                      value={state.propertyPlanStatusId}
                      valueInput='key'
                      textInput='value'
                      onSelectChanged={(value) => setState({ id: 'propertyPlanStatusId', value })}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, 'propertyPlanStatusId').message}
                      error={getErrorByName(schema, 'propertyPlanStatusId').error}
                    />
                  </div>
                  <div className='d-flex'>
                    <div className='w-50 mr-2'>
                      <Inputs
                        idRef='downPaymentRef'
                        labelValue={t(`${translationPath}down-payment`)}
                        value={state.downPayment}
                        type={'number'}
                        onInputChanged={(event) => {
                          const { value } = event.target;

                          if (+value <= 100 && +value >= 0) {
                            setState({
                              id: 'downPayment',
                              value: +value,
                            });
                            const downPaymentComplement = 100 - +value;

                            setState({
                              id: 'remainPayment',
                              value: downPaymentComplement,
                            });
                          }
                        }}
                        endAdornment={
                          <InputAdornment position='end' className='px-2'>
                            %
                          </InputAdornment>
                        }
                        max={100}
                        min={0}
                      />
                    </div>
                    <div className='w-50 ml-2'>
                      <Inputs
                        idRef='remainPaymentRef'
                        labelValue={t(`${translationPath}remain-payment`)}
                        value={state.remainPayment}
                        type={'number'}
                        onInputChanged={(event) => {
                          const { value } = event.target;

                          if (+value <= 100 && +value >= 0) {
                            setState({
                              id: 'remainPayment',
                              value: +value,
                            });
                            const remainPaymentComplement = 100 - +value;
                            setState({
                              id: 'downPayment',
                              value: remainPaymentComplement,
                            });
                          }
                        }}
                        endAdornment={
                          <InputAdornment position='end' className='px-2'>
                            %
                          </InputAdornment>
                        }
                        max={100}
                        min={0}
                      />
                    </div>
                  </div>
                  <div className='my-2'>
                    <AutocompleteComponent
                      idRef='frequencyIdRef'
                      labelValue='frequency'
                      labelClasses='Requierd-Color'
                      selectedValues={selected.frequency}
                      multiple={false}
                      data={data.frequency}
                      displayLabel={(option) => option.lookupItemName || ''}
                      renderOption={(option) => option.lookupItemName || ''}
                      getOptionSelected={(option) => option.lookupItemId === state.frequencyId}
                      withoutSearchButton
                      isLoading={isLoading.frequency}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        setSelected({ id: 'frequency', value: newValue });
                        setState({
                          id: 'frequencyId',
                          value: (newValue && newValue.lookupItemId) || null,
                        });
                      }}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, 'frequencyId').message}
                      error={getErrorByName(schema, 'frequencyId').error}
                    />
                  </div>
                  <div className='my-3'>
                    <Inputs
                      idRef='noInstallmentsRef'
                      labelValue={t(`${translationPath}number-of-installments`)}
                      value={state.numberOfInstallment}
                      type={'number'}
                      onInputChanged={(event) => {
                        const { value } = event.target;
                        setState({
                          id: 'numberOfInstallment',
                          value: +value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {tabIndex == 1 && (
              <div className='px-4 py-2'>
                <div className='d-flex'>
                <div className='payment-payment-display blue-display'>
                  {`Down Payment ${state.downPayment}%`} 
                </div>
                <div className={`payment-payment-display ${selected.installmentsTotal<state.remainPayment?'red-display':'green-display'}`}>
                  {`Intallments Total ${selected.installmentsTotal}%`} 
                </div>
                </div>

                <div className='installment-container'>
                  {state.paymentPlanInstallmentDtos.map((item, index) => (
                    <div className='d-flex-v-center w-100 mx-2'>
                      <div className='w-50 mr-2'>
                        <Inputs
                          idRef='installmentAmountPercentageIdRef'
                          labelValue={t(`${translationPath}amount-percentage`)}
                          value={item.amountPercentage}
                          type={'number'}
                          onInputChanged={(event) => {
                            const { value } = event.target;

                            if(checkIsValidInstallmentsTotal(item.installmentNumber, +value)){

                              const newInstallmentItem = {
                                ...item,
                                amountPercentage: +value,
                              };
                              
                              const newPaymentInstallments = [...state.paymentPlanInstallmentDtos];
                              newPaymentInstallments[index] = newInstallmentItem;
                              
                              if (+value <= 100 && +value >= 0) {
                                setState({
                                  id: 'paymentPlanInstallmentDtos',
                                  value: newPaymentInstallments,
                                });
                              }
                            }
                            }}
                          endAdornment={
                            <InputAdornment position='end' className='px-2'>
                              %
                            </InputAdornment>
                          }
                          max={100}
                          min={0}
                        />
                      </div>
                      <div className='w-50 mr-2'>
                        <div className='mx-2'>
                          {(item && item.frequency?.lookupItemName === "Date") && (
                            <DatePickerComponent
                              idRef='installmentDateRef'
                              labelValue='date'
                              placeholder='DD/MM/YYYY'
                              value={item.instalmentDate}
                              parentTranslationPath={parentTranslationPath}
                              translationPath={translationPath}
                              onDateChanged={(newValue) => {
                                const newInstallmentItem = {
                                  ...item,
                                  instalmentDate: newValue,
                                };

                                const newPaymentInstallments = [
                                  ...state.paymentPlanInstallmentDtos,
                                ];
                                newPaymentInstallments[index] = newInstallmentItem;

                                setState({
                                  id: 'paymentPlanInstallmentDtos',
                                  value: newPaymentInstallments,
                                });
                              }}
                            />
                          )}
                          {(item && item.frequency?.lookupItemName === "Completion") && (
                            <Inputs
                              idRef='installmentCompletionIdRef'
                              labelValue='completion'
                              value={item.instalmentDate || ''}
                              parentTranslationPath={parentTranslationPath}
                              translationPath={translationPath}
                              onInputChanged={(event) => {
                                const { value } = event.target;
                                const newInstallmentItem = {
                                  ...item,
                                  instalmentDate: value,
                                };

                                const newPaymentInstallments = [
                                  ...state.paymentPlanInstallmentDtos,
                                ];
                                newPaymentInstallments[index] = newInstallmentItem;

                                setState({
                                  id: 'paymentPlanInstallmentDtos',
                                  value: newPaymentInstallments,
                                });
                              }}
                            />
                          )}
                          {((item && item.frequency?.lookupItemName === "Day") ||
                            (item && item.frequency?.lookupItemName === "Month")) && (
                              <Inputs
                                idRef='installmentDayMonthIdRef'
                                labelValue={
                                  ((item && item.frequency?.lookupItemName === "Month") &&
                                    'months') ||
                                  ((item && item.frequency?.lookupItemName === "Day") && 'days')
                                }
                                value={item.instalmentDate || '0'}
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                type={'number'}
                                onInputChanged={(event) => {
                                  const { value } = event.target;

                                  const newInstallmentItem = {
                                    ...item,
                                    instalmentDate: value,
                                  };

                                  const newPaymentInstallments = [
                                    ...state.paymentPlanInstallmentDtos,
                                  ];
                                  newPaymentInstallments[index] = newInstallmentItem;

                                  setState({
                                    id: 'paymentPlanInstallmentDtos',
                                    value: newPaymentInstallments,
                                  });
                                }}
                              />
                            )}
                        </div>
                      </div>

                      <div className='w-50 mr-2'>
                        <div className='mx-2'>
                        <AutocompleteComponent
                           multiple={false}
                           data={(data && data.frequency) ||[]}
                           labelValue='frequency'
                           value={item && item.frequency }
                           idRef={`itemfrequencyRef${index}`}
                           selectedValues={(item && item.frequency)}
                           chipsLabel={(option) => (option && option.lookupItemName) || ''}
                           displayLabel={(option) => (option && option.lookupItemName) || ''}
                           withoutSearchButton
                           parentTranslationPath={parentTranslationPath}
                           translationPath={translationPath}
                           onChange={(event, newValue) => 
                           {  
                        
                            const newInstallmentItem = {
                              ...item,
                              frequency: (newValue) ||(selected &&  selected.frequency ) ,
                              instalmentDate : null , 
                            };

                            const newPaymentInstallments = [
                              ...state.paymentPlanInstallmentDtos,
                            ];
                            newPaymentInstallments[index] = newInstallmentItem;

                            setState({
                              id: 'paymentPlanInstallmentDtos',
                              value: newPaymentInstallments,
                            });
                           }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <ButtonBase onClick={onClose} className='btns theme-solid bg-cancel'>
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase className='btns theme-solid' onClick={saveHandler} type='submit'>
              {t(`${translationPath}save`)}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
