import { config } from '../config/config';
import { HttpServices } from '../Helper';

export const CreateSystemReport = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/SystemReport/CreateSystemReport`, body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const ExportSystemReportWithEmailAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/SystemReport/ExportSystemReportWithEmail`, body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateSystemReportCategoryType = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/SystemReport/CreateSystemReportCategoryType`, body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateSystemReport = async (SystemReportId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/SystemReport/UpdateSystemReport?systemReportId=${SystemReportId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const ExportSystemReportFromJson = async (reportExportType, body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/SystemReport/ExportSystemReportFromJson?reportExportType=${reportExportType}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllSystemReports = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/SystemReport/GetAllSystemReports`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetSystemReportCategoryTypeById = async (
  id
) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/SystemReport/GetSystemReportCategoryTypeById?id=${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetSystemReportCategoryTypesByCategoryId = async ({
  PageIndex, PageSize, FromDate, FilterBy, Order, ToDate, categoryId
}) => {
  const QueryStringParameters = [];
  if (PageIndex || PageIndex === 0) QueryStringParameters.push(`PageIndex=${PageIndex + 1}`);
  if (PageSize || PageSize === 0) QueryStringParameters.push(`PageSize=${PageSize}`);
  if (FromDate) QueryStringParameters.push(`FromDate=${FromDate}`);
  if (ToDate) QueryStringParameters.push(`ToDate=${ToDate}`);
  if (categoryId) QueryStringParameters.push(`categoryId=${categoryId}`);
  if (FilterBy) QueryStringParameters.push(`FilterBy=${FilterBy}`);
  if (Order) QueryStringParameters.push(`Order=${Order}`);
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/SystemReport/GetSystemReportCategoryTypesByCategoryId?${QueryStringParameters.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteReport = async (reportId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/SystemReport/DeleteSystemReport/${reportId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetSystemReportsResult = async (pageIndex, pageSize, body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/SystemReport/GetSystemReportsResult?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetSystemReportsResultReportBulider = async (pageIndex, pageSize, body, isForLog) => {
  const QueryStringParameters = [];
  if (pageIndex) QueryStringParameters.push(`pageIndex=${pageIndex}`);
  if (pageSize || pageSize === 0) QueryStringParameters.push(`pageSize=${pageSize}`);
  if (isForLog) QueryStringParameters.push(`isForLog=${true}`);

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/SystemReport/GetSystemReportsResult?${QueryStringParameters.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const RunSystemReportById = async (reportId, fromDate, toDate, orderBy, orderType, pageIndex, pageSize) => {
  const QueryStringParameters = [];
  if (pageIndex) QueryStringParameters.push(`pageIndex=${pageIndex + 1}`);
  else
    QueryStringParameters.push(`pageIndex=${1}`);

  if (pageSize) QueryStringParameters.push(`pageSize=${pageSize}`);
  if (fromDate) QueryStringParameters.push(`fromDate=${fromDate}`);
  if (toDate) QueryStringParameters.push(`toDate=${toDate}`);
  if (orderBy) QueryStringParameters.push(`orderBy=${orderBy}`);
  if (orderType) QueryStringParameters.push(`orderType=${orderType}`);
  QueryStringParameters.push(`isForLog=${true}`);

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/SystemReport/RunSystemReportById/${reportId}?${QueryStringParameters.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

  export const ExportSystemReport = async (reportId, fromDate, toDate, orderBy, orderType, exportType, pageIndex, pageSize) => {
    const QueryStringParameters = [];
    if (pageIndex) QueryStringParameters.push(`pageIndex=${pageIndex}`);
    else
      QueryStringParameters.push(`pageIndex=${1}`);

    if (fromDate) QueryStringParameters.push(`fromDate=${fromDate}`);
    if (toDate) QueryStringParameters.push(`orderBy=${toDate}`);
    if (orderBy) QueryStringParameters.push(`orderBy=${orderBy}`);
    if (orderType) QueryStringParameters.push(`orderType=${orderType}`);
    if (exportType) QueryStringParameters.push(`exportType=${exportType}`);
    if (pageSize) QueryStringParameters.push(`pageSize=${pageSize}`);
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/SystemReport/ExportSystemReport?reportId=${reportId}&${QueryStringParameters.join('&')}`
    )
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

  export const GetAllSystemReportBuilderLogs = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/AuditTrail/GetAllSystemReportBuilderLogs`,
      body
)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

  export const GetAllSystemReportCategoryType = async () => {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/SystemReport/GetAllSystemReportCategoryType`
)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

  export const RunLogReport = async ({
 reportId, pageIndex, pageSize, orderBy, orderType
}) => {
    const queryString = [];
    if (pageIndex) queryString.push(`pageIndex=${pageIndex + 1}`);
    else queryString.push(`pageIndex=${1}`);
    if (pageSize) queryString.push(`pageSize=${pageSize}`);
    if (orderBy) queryString.push(`orderBy=${orderBy}`);
    if (orderType) queryString.push(`orderType=${orderType}`);

    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/SystemReport/RunSystemReportById/${reportId}?${queryString.join('&')}`
    )
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

  export const GetCriteriaByReportBuilderLogId = async ({ logId }) => {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/AuditTrail/GetCriteriaByReportBuilderLogId/${logId}`
    )
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };
