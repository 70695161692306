
import { UnitsCreatedBy , UnitsAddress   } from '.' ; 
import { UnitsRentListedBy    } from './UnitsRentListedBy' ;
import { UnitsSaleListedBy } from './UnitsSaleListedBy' ;  
import { UnitStatus } from './UnitStatus' ;




export const UnitsPoliciesTabs = [
     {   
      label: 'createdBy',
      component: UnitsCreatedBy  ,
     } , 
     {   
        label: 'address',
        component: UnitsAddress  ,
     } ,
     {   
        label: 'saleListing',
        component: UnitsSaleListedBy  ,
     } , 
     {   
        label: 'leaseListing',
        component: UnitsRentListedBy  ,
     } ,
     {   
        label: 'unitStatus',
        component: UnitStatus  ,
     } ,
]
  

