import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AssignNotificationByComponent,
  NotificationSuperAdminComponent,
  NotificationClientComponent,
  AssignNotificationTemplateComponent,
  NotificationTemplateSuperAdminComponent,
  NotificationTemplateClientComponent,
  NotificationTemplatelineManagerComponent,
  NotificationTemplateTeamLeadComponent,
  NotificationTemplateCreatedFromComponent,
  NotificationLineManagerComponent,
  NotificationTeamLeadComponent,
  CreatedFromNotificationByComponent,
  NotificationLeadAuditorComponent,
  NotificationTemplateLeadAuditorComponent
} from '../DialogManagementConrtolComponent';
import {ActivitiesRelatedToActivitiesTypeEnum} from '../../../../../../Enums'
import { CheckboxesComponent } from "../../../../../../Components";

export const NotificationsTabComponent = ({
  parentTranslationPath,
  translationPath,
  setState,
  state,
  Data,
  stateNotification,
  setStateNotification,
  setTypePrerequisites,
  TypePrerequisites,
  valueDisabled,
  setSuperAdminnNOT,
  SuperAdminnNOT,
  setSuperAdminData,
  SuperAdminData,
  setClientNOT,
  ClientNOT,
  setClientData,
  ClientData,
  setAssigntoNOT,
  AssigntoNOT,
  setAssigntoData,
  setlineManagerTemplatData,
  setlineManagerNOT,
  lineManagerNOT,
  setTeamLeadNOT,
  teamLeadNOT,
  setTeamLeadTemplatData,
  CreatedFromNotificationByNOT,
  setCreatedFromNotificationByData,
  setCreatedFromNotificationByNOT,
  CreatedFromNotificationByData,
  setLeadAuditorNOT,
  LeadAuditorNOT,
  setLeadAuditorData,
  LeadAuditorData
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [isRelatedToLead, setIsRelatedToLead] = useState(false);

  useEffect(()=>{
    if(state.relatedTo&&state.relatedTo.length>0){
      const result = state.relatedTo.filter(item=>item.relatedToId===ActivitiesRelatedToActivitiesTypeEnum.lead.key)
      if(result.length>0){
        setIsRelatedToLead(true);
      }else {
        setIsRelatedToLead(false);
      }
    }
  },[state])

  return (
    <div className='DialogManagementViewComponent-wrapper'>
     <div className='w-100'>
        <div className='activity-dialog-item'>
          <CheckboxesComponent
            idRef="IsImportantRef"
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            label="Important Notification"
            singleChecked={state.isImportant}
            themeClass="theme-secondary"
            onSelectedCheckboxClicked={() => {
              setState({
                id: 'isImportant',
                value: !state.isImportant
              });
            }}
          />
        </div>
      <div className='d-flex'>
      <div className='w-50 px-3'>
        <fieldset className='activityType-fieldset mb-3'>
          <legend>{t(`${translationPath}created-from`)}</legend>
          <div className='activity-dialog-item'>
            <CreatedFromNotificationByComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              stateNotification={stateNotification}
              setCreatedFromNotificationByNOT={setCreatedFromNotificationByNOT}
              CreatedFromNotificationByNOT={CreatedFromNotificationByNOT}
              setStateNotification={(item) =>
                setStateNotification({
                  id: 'CreatedFrom',
                  value: item,
                })
              }
            />
          </div>
          <div className='activity-dialog-item'>
            <NotificationTemplateCreatedFromComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              Data={Data}
              TypePrerequisites={TypePrerequisites}
              setTypePrerequisites={setTypePrerequisites}
              CreatedFromNotificationByNOT={CreatedFromNotificationByNOT}
              CreatedFromNotificationByData={CreatedFromNotificationByData}
              setCreatedFromNotificationByData={setCreatedFromNotificationByData}
              stateNotification={stateNotification}
              setState={(item) =>
                setState({
                  id: 'activityTypeTemplateRecipientTypes',
                  value: item,
                })
              }
            />
          </div>
        </fieldset>

        <fieldset className='activityType-fieldset mb-3'>
          <legend>{t(`${translationPath}line-manager`)}</legend>
          <div className='activity-dialog-item'>
            <NotificationLineManagerComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              stateNotification={stateNotification}
              TypePrerequisites={TypePrerequisites}
              setlineManagerNOT={setlineManagerNOT}
              lineManagerNOT={lineManagerNOT}
              setTypePrerequisites={setTypePrerequisites}
              state={state}
              Data={Data}
              setStateNotification={(item) =>
                setStateNotification({
                  id: 'LineManager',
                  value: item,
                })
              }
            />
          </div>
          <div className='activity-dialog-item'>
            <NotificationTemplatelineManagerComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setlineManagerTemplatData={setlineManagerTemplatData}
              Data={Data}
              lineManagerNOT={lineManagerNOT}
              TypePrerequisites={TypePrerequisites}
              setTypePrerequisites={setTypePrerequisites}
              stateNotification={stateNotification}
              setState={(item) =>
                setState({
                  id: 'activityTypeTemplateRecipientTypes',
                  value: item,
                })
              }
            />
          </div>
        </fieldset>

        <fieldset className='activityType-fieldset mb-3'>
          <legend>{t(`${translationPath}team-lead`)}</legend>
          <div className='activity-dialog-item'>
            <NotificationTeamLeadComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              stateNotification={stateNotification}
              TypePrerequisites={TypePrerequisites}
              setTeamLeadNOT={setTeamLeadNOT}
              teamLeadNOT={teamLeadNOT}
              setTypePrerequisites={setTypePrerequisites}
              state={state}
              Data={Data}
              setStateNotification={(item) =>
                setStateNotification({
                  id: 'TeamLead',
                  value: item,
                })
              }
            />
          </div>
          <div className='activity-dialog-item'>
            <NotificationTemplateTeamLeadComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setTeamLeadTemplatData={setTeamLeadTemplatData}
              Data={Data}
              teamLeadNOT={teamLeadNOT}
              TypePrerequisites={TypePrerequisites}
              setTypePrerequisites={setTypePrerequisites}
              stateNotification={stateNotification}
              setState={(item) =>
                setState({
                  id: 'activityTypeTemplateRecipientTypes',
                  value: item,
                })
              }
            />
          </div>
        </fieldset>

        {isRelatedToLead&&
        <fieldset className='activityType-fieldset mb-3'>
          <legend>{t(`${translationPath}client`)}</legend>
          <div className='activity-dialog-item'>
            <NotificationClientComponent
              translationPath={translationPath}
              Data={Data}
              parentTranslationPath={parentTranslationPath}
              state={state}
              valueDisabled={valueDisabled}
              setClientNOT={setClientNOT}
              ClientNOT={ClientNOT}
              stateNotification={stateNotification}
              setStateNotification={(item) =>
                setStateNotification({
                  id: 'Client',
                  value: item,
                })
              }
            />
          </div>
           <div className='activity-dialog-item'>
            <NotificationTemplateClientComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              Data={Data}
              ClientNOT={ClientNOT}
              TypePrerequisites={TypePrerequisites}
              setClientData={setClientData}
              ClientData={ClientData}
              setTypePrerequisites={setTypePrerequisites}
              stateNotification={stateNotification}
              setState={(item) =>
                setState({
                  id: 'activityTypeTemplateRecipientTypes',
                  value: item,
                })
              }
            />
          </div> 
        </fieldset>}
      </div>

      <div className='w-50 px-2'>
        <fieldset className='activityType-fieldset mb-3'>
          <legend>{t(`${translationPath}assign-to`)}</legend>
          <div className='activity-dialog-item'>
            <AssignNotificationByComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setAssigntoNOT={setAssigntoNOT}
              AssigntoNOT={AssigntoNOT}
              Data={Data}
              stateNotification={stateNotification}
              setStateNotification={(item) =>
                setStateNotification({
                  id: 'AssignTo',
                  value: item,
                })
              }
            />
          </div>
          <div className='activity-dialog-item'>
            <AssignNotificationTemplateComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              TypePrerequisites={TypePrerequisites}
              setTypePrerequisites={setTypePrerequisites}
              Data={Data}
              state={state}
              AssigntoNOT={AssigntoNOT}
              setAssigntoData={setAssigntoData}
              stateNotification={stateNotification}
              setState={(item) =>
                setState({
                  id: 'activityTypeTemplateRecipientTypes',
                  value: item,
                })
              }
            />
          </div>
        </fieldset>

        <fieldset className='activityType-fieldset mb-3'>
          <legend>{t(`${translationPath}team-manager`)}</legend>
          <div className='activity-dialog-item'>
            <NotificationSuperAdminComponent
              translationPath={translationPath}
              Data={Data}
              parentTranslationPath={parentTranslationPath}
              state={state}
              valueDisabled={valueDisabled}
              setSuperAdminnNOT={setSuperAdminnNOT}
              SuperAdminnNOT={SuperAdminnNOT}
              stateNotification={stateNotification}
              setStateNotification={(item) =>
                setStateNotification({
                  id: 'SuperAdmin',
                  value: item,
                })
              }
            />
          </div>
          <div className='activity-dialog-item'>
            <NotificationTemplateSuperAdminComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              Data={Data}
              SuperAdminnNOT={SuperAdminnNOT}
              TypePrerequisites={TypePrerequisites}
              setSuperAdminData={setSuperAdminData}
              SuperAdminData={SuperAdminData}
              setTypePrerequisites={setTypePrerequisites}
              stateNotification={stateNotification}
              setState={(item) =>
                setState({
                  id: 'activityTypeTemplateRecipientTypes',
                  value: item,
                })
              }
            />
          </div>
        </fieldset>

        <fieldset className='activityType-fieldset mb-3'>
          <legend>{t(`${translationPath}lead-auditor`)}</legend>
          <div className='activity-dialog-item'>
            <NotificationLeadAuditorComponent
              translationPath={translationPath}
              Data={Data}
              parentTranslationPath={parentTranslationPath}
              state={state}
              valueDisabled={valueDisabled}
              setLeadAuditorNOT={setLeadAuditorNOT}
              LeadAuditorNOT={LeadAuditorNOT}
              stateNotification={stateNotification}
              setStateNotification={(item) =>
                setStateNotification({
                  id: 'LeadAuditor',
                  value: item,
                })
              }
            />
          </div>
          <div className='activity-dialog-item'>
            <NotificationTemplateLeadAuditorComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              Data={Data}
              LeadAuditorNOT={LeadAuditorNOT}
              TypePrerequisites={TypePrerequisites}
              setLeadAuditorData={setLeadAuditorData}
              LeadAuditorData={LeadAuditorData}
              setTypePrerequisites={setTypePrerequisites}
              stateNotification={stateNotification}
              setState={(item) =>
                setState({
                  id: 'activityTypeTemplateRecipientTypes',
                  value: item,
                })
              }
            />
          </div>
        </fieldset>

      </div>
    </div>
    </div>
    </div>)

}

NotificationsTabComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  setStateNotification: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  stateNotification: PropTypes.string.isRequired,
};
