import React, { useEffect, useState , useCallback  } from 'react';
import { useSelector } from 'react-redux';
import {
  TabsComponent,
  RadiosGroupComponent,
  CompletedDataComponent,
  PermissionsComponent
} from '../../../../Components';
import { NotFoundLayout } from '../../../../Layouts/NotFoundLayout/NotFoundLayout' ; 
import { GetParams, returnPropsByPermissions } from '../../../../Helper';
import { LeadTypeIdEnum , FormsIdsEnum  } from '../../../../Enums';
import { LeadsAdvanceSearchTest  , GetSensitiveFieldsForUser  } from '../../../../Services' ; 
import { LeadsVerticalTabsData } from '../../Common/OpenFileView/OpenFileUtilities/OpenFileData';
import { LeadsSalesPermissions } from '../../../../Permissions/Sales/LeadsSalesPermissions';
import { PermissionDeniedLayout } from '../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout' ; 

const parentTranslationPath = 'LeadsProfileManagementView';
const translationPath = '';
export const LeadsSalesProfileManagementView = () => {
  const activeItem = useSelector((state) => state.ActiveItemReducer);


  const [leadWithPolicy ,setLeadWithPolicy ] = useState(null) ; 
  const [sensitiveFieldsForUser , setSensitiveFieldsForUser  ] = useState([]) ; 
  const [displayDetailsToUserDependsOnPolicy , setDisplayDetailsToUserDependsOnPolicy] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [tabsArray, setTabsArray] = useState(LeadsVerticalTabsData.sale);
  const localActiveItem = localStorage.getItem('activeItem');
  const [viewType, setViewType] = useState(1);
  const [ isLoading  , setIsLoading ] =  useState(false);
  const [isSensitiveLoading , setIsSensitiveLoading ] = useState(0)  ; 
  const [SensitiveLoading , setSensitiveLoading ] = useState(false)  ; 
  const [leadEmptyFields , setLeadEmptyFields] = useState(null) ; 

  // filterBy
  const [filter, setFilterBy] = useState({
    id: null,
    formType: null,
  });

  

  useEffect(() => {
    if (GetParams('formType') == LeadTypeIdEnum.Owner.leadTypeId)
      setTabsArray(LeadsVerticalTabsData.sale && LeadsVerticalTabsData.sale.filter((tab) => tab.label !== 'matching' && tab.label !== 'assign-agents-tracking'));

    const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];
    if (pathName === 'lead-sales/lead-profile-edit' && !GetParams('matching')) setActiveTab(0);
    else setActiveTab(GetParams('matching') === 'true' ? 2 : 1);
  }, []);

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };
  useEffect(() => {
    setFilterBy({
      formType: GetParams('formType'),
      id: GetParams('id'),
    });
  }, []); 
   
  const GetSensitiveFieldsForUserAPI  = useCallback(async () => {
    setSensitiveLoading(true);
    const allSensitiveFields  = await GetSensitiveFieldsForUser(leadWithPolicy && leadWithPolicy.lead && leadWithPolicy.lead.lead_type_id === 1  ?  FormsIdsEnum.leadsOwner.id :FormsIdsEnum.leadsSeeker.id  );
     
    if (!(allSensitiveFields && allSensitiveFields.status && allSensitiveFields.status !== 200)) {
      setSensitiveFieldsForUser(allSensitiveFields);
      setIsSensitiveLoading(1);

    } else {
      setSensitiveFieldsForUser([]);
      setIsSensitiveLoading(2);

    }
    
    setSensitiveLoading(false);
  }, []); 

  const userHasPolicyToDisplayData = useCallback(async (leadId) => 
  {
    setIsLoading(true);
    const isForLog = true;

    let body = {
      criteria: {
        Ids: [{searchType: 1, value: leadId}]
      } , 
      filterBy : 'createdOn' , 
      orderBy : 2 ,
    };

    const res = await LeadsAdvanceSearchTest({pageIndex : 0, pageSize :2, isForLog}, body);
    if (!(res && res.status && res.status !== 200)) 
    {
      if(res && res.totalCount > 0)
      {
        if(res && res.result && res.result.length  && res.result[0].leadJson)
        {
          const LeadJson =JSON.parse(res && res.result && res.result.length  && res.result[0].leadJson);
          setLeadEmptyFields(JSON.parse(res && res.result &&  res.result[0] && res.result[0].leadJson)) ; 
          localStorage.setItem('leadStatus', JSON.stringify(LeadJson && LeadJson.lead  &&  LeadJson.lead.status));
          const lead = {...LeadJson.lead  ,
            bedrooms  : ((LeadJson.lead  && LeadJson.lead.bedrooms && LeadJson.lead.bedrooms.length === 0) || !(LeadJson && LeadJson.lead && LeadJson.lead.bedrooms) ? ['Any'] : LeadJson.lead.bedrooms ) ,
            bathrooms  : ((LeadJson.lead  && LeadJson.lead.bathrooms && LeadJson.lead.bathrooms.length === 0) || !(LeadJson && LeadJson.lead && LeadJson.lead.bathrooms) ? ['Any'] : LeadJson.lead.bathrooms) ,
           }
          setLeadWithPolicy({...LeadJson , lead :lead }) ; 

        } 
        setDisplayDetailsToUserDependsOnPolicy('allowed');

      }
        
      else { 
          setLeadWithPolicy(null) ;
          setLeadEmptyFields(null) ;  
          setDisplayDetailsToUserDependsOnPolicy('notAllowed');
        }      
    }
    else {
        setDisplayDetailsToUserDependsOnPolicy('notAllowed') ;
        setLeadWithPolicy(null) ; 
        setLeadEmptyFields(null) ;  

     } 
     setIsLoading(false);
  });

  const reloadData = () => 
  {
    userHasPolicyToDisplayData(+GetParams('id')) ; 
    GetSensitiveFieldsForUserAPI() ; 
  };

  useEffect(() => {
    const leadId  = +GetParams('id');
    if (leadId && activeTab === 0 )
      userHasPolicyToDisplayData(leadId);
  }, [activeTab]);

  useEffect(() => {
    const leadId = +GetParams('id');
    if (leadId && displayDetailsToUserDependsOnPolicy === 'allowed'  && activeTab === 0  )
        GetSensitiveFieldsForUserAPI() ; 

  }, [activeTab , displayDetailsToUserDependsOnPolicy , activeTab ]);


  return (
    <div className='leads-profile-wrapper view-wrapper'>
      <PermissionsComponent
        permissionsList={Object.values(LeadsSalesPermissions)}
        permissionsId={[
          LeadsSalesPermissions.ViewMatchingUnits.permissionsId,
          LeadsSalesPermissions.ViewLeadDetails.permissionsId]
        }
      >
        {
          displayDetailsToUserDependsOnPolicy === 'allowed' &&  activeTab === 0 &&  tabsArray && tabsArray.length && tabsArray[0].label === 'lead-information' && 
          ( returnPropsByPermissions(LeadsSalesPermissions.ViewLeadDetails.permissionsId)) &&  (
            <div className='d-flex'>
            <RadiosGroupComponent
              idRef='viewDataRef'
              data={[
                {
                  key: 1,
                  value: 'all-data',
                },
                {
                  key: 2,
                  value: 'missing-data',
                },
              ]}
              value={viewType}
              labelValue='view'
              labelInput='value'
              valueInput='key'
              themeClass='theme-line'
              parentTranslationPath={parentTranslationPath}
              translationPathForData={translationPath}
              translationPath={translationPath}
              onSelectedRadioChanged={onViewTypeChangedHandler}
            />
            <CompletedDataComponent
              completedData={
                activeItem && activeItem.progress ?
                  activeItem.progress :
                  JSON.parse(localActiveItem) && JSON.parse(localActiveItem).progress
              }
              typeId='lead'
              formType={filter.formType}
              operationType='sale'
              activeItem={activeItem}  
            />
          </div>

          )
         
        }
       
      </PermissionsComponent>
      {
        displayDetailsToUserDependsOnPolicy === 'allowed' &&  
        (
          <TabsComponent
           data={tabsArray}
           labelInput='label'
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           themeClasses='theme-solid'
           currentTab={activeTab}
           onTabChanged={onTabChanged}
           dynamicComponentProps={{
             viewType,
             parentTranslationPath,
             translationPath,
             setActiveTab,
             sensitiveFieldsForUser , 
             leadWithPolicy , 
             leadDataIsLoading:isLoading , 
             isSensitiveLoading  , 
             isLoading  , 
             leadEmptyFields  , 
             reloadData , 
             
            }}
        />
        )}
        
        {/* { 
         displayDetailsToUserDependsOnPolicy === 'notAllowed'  && (<NotFoundLayout/>)
        } */}

{ 
         displayDetailsToUserDependsOnPolicy === 'notAllowed'  && (<PermissionDeniedLayout/>)
        }
    </div>
  );
};
