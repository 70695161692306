import React, { useState, useEffect } from 'react';
import { TabsComponent } from '../../../Components';
import { DashboardTabs } from './DashboardTabs/DashboardTabs';
import { tabsPermissionsHelper } from '../../../Helper/ExceptionPermissions.Helper';

export const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [dashboardTabsList, setDashboardTabsList] = useState([]);

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  useEffect(() => {
    const list = tabsPermissionsHelper(DashboardTabs).map((field, fieldIndex) => ({
      ...field,
      index: fieldIndex,
    }));
    setDashboardTabsList(list);
  }, [DashboardTabs]);

  return (
    <div>
      <TabsComponent
        data={dashboardTabsList}
        labelInput='label'
        themeClasses='theme-curvedpowerbi'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          setActiveTab,
          dynamicComponentProps: Object.values(dashboardTabsList).find(
            (element) => element.index === activeTab
          ),
        }}
      />
    </div>
  );
};
