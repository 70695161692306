
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';
import {  SelectComponet , PermissionsComponent  } from '../../../../../Components'; 
import {  getErrorByName  } from '../../../../../Helper' ; 
import {  ClientsSegmentationPermissions } from '../../../../../Permissions' ; 
import { FormCategotyType , FieldName , FilterTypes , FieldValue   } from '../../AddClientsSegmentation/Components' ; 

export const QeuriesComponents = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
    schema , 
    setIfHaveAnyErrors , 
    ifHaveAnyErrors , 
    fields , 
    setFields , 
    searchHandler , 
    setIsOpenSaveDialog , 
 }) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  return (
    <div>  
      {state.conditions &&
            state.conditions.map((item, index) => (
              <div className='client-segmentation-item-wrapper' key={`segmentation${index + 1}`}>
                <div className='client-segmentation-section'>
                  <div className='d-flex w-100'>
                    <SelectComponet
                      labelValue={ !index ? t(`${translationPath}select_type`) : ''}
                      emptyItem={{ value: null, text: t(`${translationPath}select_type`), isHiddenOnOpen: true }}
                      idRef={`selectForm${index + 1}`}
                      data={[
                        { key: 'contacts', value: 'Contact' },
                        { key: 'leads', value: 'Lead' } , 
                        { key: 'activities', value: 'Activity' }
                      ]}
                      value={item.category}
                      valueInput='key'
                      textInput='value'
                      onSelectChanged={(value) => {
                        let updatelist = state.conditions;
                        updatelist[index] = {
                          ...updatelist[index],
                          category: value,
                          categoryType: null,
                          searchKey: null,
                          fieldName: null,
                          value: null,
                          operator: null,
                        }
                        setState({ id: 'conditions', value: updatelist });

                      }}
                      isWithError
                      error={getErrorByName(schema, `conditions.${index}`, 'state.userNotSet'
                      ).error}
                      translationPathForData={translationPath}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>
                </div>
                {
                  item.category && (
                    <div className='client-segmentation-section'>
                      <div className='d-flex w-100'>
                        <FormCategotyType
                          item={item}
                          index={index}
                          state={state}
                          setState={setState}
                          translationPathForData={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          schema={schema}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  item.categoryType && (
                    <div className='client-segmentation-section'>
                      <div className='d-flex w-100'>
                        <FieldName
                          item={item}
                          index={index}
                          state={state}
                          setState={setState}
                          translationPathForData={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          schema={schema}
                          fields={fields}
                          setFields={setFields}
                        />
                      </div>
                    </div>

                  )}

                {
                  item.fieldName && (
                    <div className='client-segmentation-section'>
                      <div className='d-flex w-100'>
                        <FilterTypes
                          item={item}
                          index={index}
                          state={state}
                          setState={setState}
                          translationPathForData={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          schema={schema}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  item.operator && (
                    <div className='client-segmentation-section'>
                      <div className='d-flex w-100'>
                        <FieldValue
                          item={item}
                          index={index}
                          state={state}
                          setState={setState}
                          translationPathForData={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          schema={schema}
                          setIfHaveAnyErrors={setIfHaveAnyErrors}
                        />
                      </div>
                    </div>
                  )

                }
                {
                  ((index + 1) !== state.conditions.length) &&
                  (
                    <div className='client-segmentation-section'>
                      <div className='d-flex w-100'>
                        <SelectComponet
                          labelValue={ !index ? t(`${translationPath}Operator`) : ''}
                          idRef={`operatorRef${index + 1}`}
                          value={item.operand}
                          data={[
                            { key: 'AND', value: 'AND' },
                            { key: 'OR', value: 'OR' }
                          ]}
                          emptyItem={{ value: null, text:  t(`${translationPath}select-operator`), isHiddenOnOpen: true }}
                          valueInput='key'
                          textInput='value'
                          onSelectChanged={(value) => {
                            let updatelist = state.conditions;
                            updatelist[index] = { ...updatelist[index], operand: value }
                            setState({ id: 'conditions', value: updatelist });
                          }}
                          translationPathForData={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                        />
                      </div>
                    </div>
                  )
                }

                <div className='client-segmentation-operator-section' >
                  {(index + 1) === state.conditions.length && (
                    <div>
                      <ButtonBase
                        className='btns-icon theme-solid bg-secondary-light mt-1 mx-2'
                        onClick={() => {
                          let queryList = state.conditions;
                          queryList[index] = { ...queryList[index], operand: 'AND' }
                          queryList.push(
                            {
                              category: null,
                              categoryType: null,
                              searchKey: null,
                              fieldName: null,
                              value: null,
                              operator: null,
                              operand: 'AND'
                            });
                          setState({ id: 'conditions', value: queryList });

                        }}
                        disabled={ !item.category || !item.categoryType || !item.fieldName || (item.value === null || item.value === undefined || item.value === 0) || !item.operator || ifHaveAnyErrors}

                      >
                        <span className='mdi mdi-plus c-black-light' />
                      </ButtonBase>


                    </div>
                  )}

                  <ButtonBase
                    className='btns-icon theme-solid bg-secondary-light mt-1 mx-2'
                    disabled={(index + 1 === state.conditions.length && index === 0)}
                    onClick={() => {
                      let queryList = state.conditions;
                      queryList.splice(index, 1);
                      queryList[state.conditions.length - 1] = { ...queryList[state.conditions.length - 1],
                         }
                      setState({ id: 'conditions', value: queryList });

                    }}
                  >
                    <span className='mdi mdi-minus c-black-light' />
                  </ButtonBase>
                </div>
              </div>
      ))}   
      <div className='searchButtonPart'>
      <ButtonBase
            className='btns theme-solid'
            onClick={() => searchHandler()}
            disabled={!state.conditions.length  || (schema.error) || ifHaveAnyErrors  }
          >
            <span className='MuiButton-label'>
              <span> 
              {t(`${translationPath}search`)}
              </span>
            </span>
            <span className='MuiTouchRipple-root' />
          </ButtonBase>
          <PermissionsComponent
                permissionsList={Object.values(ClientsSegmentationPermissions)}
                permissionsId={ClientsSegmentationPermissions.AddNewClientsSegmentation.permissionsId}
              >
                <ButtonBase
                  className='btns'
                  tabIndex='0'
                  onClick={() => setIsOpenSaveDialog(true)}
                  disabled={!state.conditions.length  || (schema.error) || ifHaveAnyErrors  }
                  >
            <span className='MuiButton-label'>
              <span> 
                 {t(`${translationPath}save`)}
              </span>
            </span>
            <span className='MuiTouchRipple-root' />
          </ButtonBase>
        </PermissionsComponent>  
      </div>  
   </div>
  );

}


QeuriesComponents.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setState :PropTypes.func.isRequired , 
  state : PropTypes.instanceOf(Object).isRequired,
  schema : PropTypes.instanceOf(Object).isRequired, 
  setIfHaveAnyErrors  :PropTypes.func.isRequired  , 
  ifHaveAnyErrors : PropTypes.bool.isRequired , 
  fields : PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setFields : PropTypes.func.isRequired  , 
};
