import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
 DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase
} from '@material-ui/core';
import { showError, showSuccess, getErrorByName } from '../../../../Helper';
import { SelectComponet, Spinner } from '../../../../Components';
import { UpdateBulkAgentsInRotationStatus } from '../../../../Services';

export const BulkStatusUpdateDialog = ({
  isOpen,
  onClose,
  parentTranslationPath,
  translationPath,
  onSave,
  agentsId,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [isLoading, setIsLoading] = useState(false);
  const [statusValue, setStatusValue] = useState(0);

  const onSubmit = async () => {
    let isInRotation = null;
    if (statusValue === 'ON') isInRotation = true;
    if (statusValue === 'OFF') isInRotation = false;

    if (agentsId && (isInRotation !== null)) updateBulkInRotationStatus(isInRotation);
  };

  const updateBulkInRotationStatus = useCallback(async (isInRotation) => {
    setIsLoading(true);
    const result = await UpdateBulkAgentsInRotationStatus({ agentsId, isInRotation });
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t(`${translationPath}in-rotation-status-bulk-updated-successfully`));
      onSave();
    } else
      showError(t(`${translationPath}bulk-updating-in-rotation-status-has-failed`));

    setIsLoading(false);
  }, [agentsId]);

  return (
    <div>
      <Dialog
        open={isOpen}
        maxWidth='sm'
        onClose={() => onClose()}
        className='activities-management-dialog-wrapper'
        disableBackdropClick
      >
        <DialogTitle id='alert-dialog-slide-title'>{t(`${translationPath}bulk-status-update`)}</DialogTitle>
        <DialogContent>
          <div className='dialog-content-wrapper'>
            <Spinner isActive={isLoading} isAbsolute />
            <div className='dialog-content-item w-100'>
              <SelectComponet
                data={[
                  { id: 'ON', name: 'on' },
                  { id: 'OFF', name: 'off' },
                ]}
                emptyItem={{ value: 0, text: 'select-status', isDisabled: false }}
                value={statusValue}
                valueInput='id'
                textInput='name'
                labelValue='in-rotation-status'
                onSelectChanged={(value) => {
                  setStatusValue(value);
                }}
                wrapperClasses='w-auto'
                themeClass='theme-default test'
                idRef='Lead_Status'
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <>
            <div className='cancel-wrapper d-inline-flex-center'>
              <ButtonBase
                className='MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary'
                tabIndex='0'
                id='cancel-ref-action'
                onClick={() => onClose()}
              >
                <span className='MuiButton-label'>
                  <span>{t(`${translationPath}cancel`)}</span>
                </span>
                <span className='MuiTouchRipple-root' />
              </ButtonBase>
            </div>
            <div className='save-wrapper d-inline-flex-center'>
              <ButtonBase
                className='MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-primary w-100 mx-2 mb-2'
                tabIndex='0'
                onClick={() => {
                  onSubmit();
                }}
                disabled={!(statusValue === 'ON' || statusValue == 'OFF')}
              >
                <span className='MuiButton-label'>
                  <span>{t(`${translationPath}save`)}</span>
                </span>
                <span className='MuiTouchRipple-root' />
              </ButtonBase>
            </div>
          </>
        </DialogActions>
      </Dialog>
    </div>
  );
};

BulkStatusUpdateDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
