export const UserAMLViewPermissions = 
{
    ViewAMLCheckerModule :  {
        permissionsId: "e630a178-53c2-4efa-44f6-08db5f99228b",
        permissionsName: "View AML Checker Module",
        description: null,
        componentsId: null,
        components: null
      }  , 

      CancelACheckRequest :  {
        permissionsId: "2a986dc9-3035-4451-287a-08dbaf79a1bb",
        permissionsName: "Cancel A Check Request",
        description: null,
        componentsId: null,
        components: null
    }
    
  

};
  