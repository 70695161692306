
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from '@material-ui/core';
import { Spinner } from '../../../../Components'  ; 
import { PROPERTIES, UNITS , CONTACTS } from '../../../../config/pagesName';
import { ContactsInformationComponent } from '../../ContactsView/ContactProfileManagementView/Sections/ContactsInformationComponent/ContactsInformationComponent';
import { UnitInformationComponent } from '../../UnitsSalesView/UnitsSalesProfileManagement/Sections/UnitInformationComponent/UnitInformationComponent';
import  UnitInformationComponent2  from '../../UnitsLeaseView/UnitsLeaseProfileManagementView/Sections/UnitInformationComponent/UnitInformationComponent';
import { PropertiesInformationComponent } from '../../PropertiesView/PropertiesProfileManagementView/Sections/PropertiesInformationComponent/PropertiesInformationComponent';
import {
  UnitInformationComponent as LeaseUnitInformationComponent,
} from '../../UnitsLeaseView/UnitsLeaseProfileManagementView/Sections';

const translationPath = 'dialogs.detailsDialog.';
export const DetailsDialog = ({ isOpen, isOpenChanged , isOpenInsideForm , dataInSideViewButton  , sensitiveFieldsForUser   , isLoadingDetailsDialog , isSensitiveLoading , isSensitiveLoading2 }) => {
  
  const { t } = useTranslation('FormBuilder');
  const [save , onSave]= useState(false);
  const [activeFormType, setActiveFormType] = useState(null);
  const [id, setId] = useState(null);
  const [activeItem  , setActiveItem] = useState(null) ; 
  const [pageName, setPageName] = useState(null);
  const [isDetailsDialog , setIsDetailsDialog] = useState(true);
  const [isActiveToSave , setIsActiveToSave] = useState(false);



  useEffect(() => {
    if (isOpen) {
      const obj = JSON.parse(localStorage.getItem('current'));
      setActiveFormType(obj.type ||  1);
      setId(obj.id);
      setActiveItem(obj) ;
      setPageName(obj.itemId.toLowerCase());
    }
  }, []);

  return (
    <Dialog className='dialog detailsDialog'
          disableBackdropClick 
          open={isOpen}
          fullWidth 
          maxWidth='lg'>
        <DialogTitle>
          <>
            <ButtonBase
              className='btns-icon theme-solid dialog-btn-close'
              onClick={() => {
                setIsDetailsDialog(false);
                isOpenChanged();
              }}
            >
              <span className='mdi mdi-close' />
            </ButtonBase>
            {t(`${translationPath}view-${pageName}`)}

          </>
        </DialogTitle>
        <DialogContent>
        {/* <Spinner isActive={isLoadingDetailsDialog} /> */}
        { pageName === UNITS 
        &&  activeItem && (activeItem.operationType && activeItem.operationType  !== 'Rent') 
        && (
            <UnitInformationComponent
                         viewType ={1} 
                         parentTranslationPath='UnitsProfileManagementView'
                         translationPath=''
                         save={save}
                         isDetailsDialog={isDetailsDialog}
                         isActiveToSave={pageName === UNITS}
                         isOpen={isOpen}
                         isOpenChanged={isOpenChanged}
                         onSave={onSave}
                         isOpenInsideForm={isOpenInsideForm}
                         unitWithPolicy={dataInSideViewButton}
                         sensitiveFieldsForUser={sensitiveFieldsForUser}
                         isLoadingDetailsDialog={isLoadingDetailsDialog}
                         isSensitiveLoading={isSensitiveLoading}

              />
         )}
          { pageName === UNITS &&  activeItem && activeItem.operationType  && activeItem.operationType === 'Rent' &&  (
            <LeaseUnitInformationComponent
                         viewType ={1} 
                         parentTranslationPath='UnitsProfileManagementView'
                         translationPath=''
                         save={save}
                         isDetailsDialog={isDetailsDialog}
                         isActiveToSave={pageName === UNITS}
                         isOpen={isOpen}
                         isOpenChanged={isOpenChanged}
                         onSave={onSave}
                         isOpenInsideForm={isOpenInsideForm}
                         unitWithPolicy={dataInSideViewButton}
                         sensitiveFieldsForUser={sensitiveFieldsForUser}
                         isSensitiveLoading={isSensitiveLoading}


                         
              />
         )}

           { pageName === PROPERTIES && (
              <PropertiesInformationComponent
               viewType ={1} 
               parentTranslationPath='PropertiesProfileManagementView'
               translationPath=''
               save={save}
               onSave={onSave}
               isDetailsDialog={isDetailsDialog}
               isActiveToSave={pageName === PROPERTIES}
               isOpen={isOpen}
               isOpenChanged={isOpenChanged}
               isOpenInsideForm={isOpenInsideForm}
               propertyWithPolicy={dataInSideViewButton}
               sensitiveFieldsForUser={sensitiveFieldsForUser}
               isSensitiveLoading={isSensitiveLoading}
               />

           )
           }

          { pageName === CONTACTS && (
            <ContactsInformationComponent
                         viewType ={1} 
                         parentTranslationPath='ContactProfileManagementView'
                         translationPath=''
                         save={save}
                         isDetailsDialog={isDetailsDialog}
                         isActiveToSave={pageName === CONTACTS}
                         isOpen={isOpen}
                         isOpenChanged={isOpenChanged}
                         onSave={onSave}
                         isOpenInsideForm={isOpenInsideForm}
                         contactWithPolicy={dataInSideViewButton}
                         sensitiveFieldsForUser={sensitiveFieldsForUser}
                         isSensitiveLoading={isSensitiveLoading}
                         isSensitiveLoading2={isSensitiveLoading2}
                         
              />
         )}
        </DialogContent>
        
        <DialogActions>
          <ButtonBase
            className="btns theme-solid bg-cancel"
            onClick={() => {
              isOpenChanged();
              setIsDetailsDialog(false);
            }}
          >
            {t(`${translationPath}cancel`)}

          </ButtonBase>
          <ButtonBase className="btns theme-solid" onClick={()=> 
          {
            onSave(true) ;
          }} >
            {t(`${translationPath}save`)}
          </ButtonBase>
        </DialogActions>
    </Dialog>
  );
};

DetailsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  isOpenInsideForm : PropTypes.bool.isRequired,
};
