import React, {
  useCallback, useEffect, useReducer, useState, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { Button, ButtonBase } from '@material-ui/core';
import { DetailsTabComponent } from './DialogTabComponents/DetailsTabComponent';
import { NotificationsTabComponent } from './DialogTabComponents/NotificationsTabComponent';
import { RemindersTabComponent } from './DialogTabComponents/RemindersTabComponent';
import { RotationTabComponent } from './DialogTabComponents/RotationTabComponent';
import {
  CreateActivityTypes,
  UpdateActivityTypes,
  UpdateActivityTypeReminder,
  CreateActivityTypeReminder,
  GetActivityTypeReminders,
} from '../../../../../Services/ActivitiesTypesServices';
import { showError, showSuccess } from '../../../../../Helper';
import { ReminderTypeEnum, RecipientTypeCoreEnum, NotificationByEnum } from '../../../../../Enums';
import { Spinner } from '../../../../../Components';
import { DisabledFiledValidation } from './DisabledFiledValidation';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { ActivityTypeHistoryDialog } from './DialogTabComponents/ActivityTypeHistoryDialog';

export const ActivityTypeManagementDialog = ({
  parentTranslationPath,
  translationPath,
  onCancelClicked,
  edit,
  Data,
  activeItem,
  setReloading,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [IsLoading, setIsLoading] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '0px',
      paddingBottom: '5px',
      textTransform: 'initial',
    },
    indicator: {
      backgroundColor: '#3b72d9',
    },
    labelIcon: {
      minHeight: 'unset',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
    fixed: {
      width: 'fit-content',
      margin: 'auto',
    },
    scroller: {
      flex: "unset",
    }
  }));
  const classes = useStyles();
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const [SuperAdminnNOT, setSuperAdminnNOT] = useState([]);
  const [SuperAdminData, setSuperAdminData] = useState([]);
  const [statusHistoryDialog, setStatusHistoryDialog] = useState(false);
  const [ClientNOT, setClientNOT] = useState([]);
  const [ClientData, setClientData] = useState([]);
  const [lineManagerTemplatData, setlineManagerTemplatData] = useState([]);
  const [lineManagerNOT, setlineManagerNOT] = useState([]);
  const [teamLeadTemplatData, setTeamLeadTemplatData] = useState([]);
  const [teamLeadNOT, setTeamLeadNOT] = useState([]);
  const [CreatedFromNotificationByData, setCreatedFromNotificationByData] = useState([]);
  const [CreatedFromNotificationByNOT, setCreatedFromNotificationByNOT] = useState([]);
  const [AssigntoNOT, setAssigntoNOT] = useState([]);
  const [LeadAuditorData, setLeadAuditorData] = useState([]);
  const [LeadAuditorNOT, setLeadAuditorNOT] = useState([]);
  const [AssigntoData, setAssigntoData] = useState([]);
  const [activityTypeReminders, setActivityTypeReminders] = useState([]);
  const [expirationPeriodTimeType, setExpirationPeriodTimeType] = useState(1)
  const defaultActivityReminderItem = useRef({
    reminderType: null,
    reminderTo: null,
    reminderTime: 0,
  });

  const [state, setState] = useReducer(reducer, {
    activityTypeName: '',
    leadStageId: null,
    categoryId: null,
    description: '',
    activityRate: null,
    withDateTime: false,
    withReminder: false,
    withDuration: false,
    followUpRequired: false,
    isForMobile: false,
    isImportant: false,
    slaExpirationPeriod: 0,
    isWithslaExpirationPeriod: false,
    slaExpirationPeriodComparisonDate: null,
    slaExpirationPeriodFreqency: null,
    slaAppliedFor: 3,
    expiredPeriod: 0,
    relatedTo: [],
    createActivityTypeRoles: [],
    activityTypeCreatedByBranches: [],
    assignedToActivityTypeRoles: [],
    activityTypeTemplateRecipientTypes: [],
    activityTypeActivityTypePrerequisites: [],
    activityTypeRotationRoles: [],
    activityTypeRotationUsers: [],
  });
  const [selected, setSelected] = useReducer(reducer, {
    activityTypeRotationRoles: [],
    activityTypeRotationUsers: [],
  });
  const [stateNotification, setStateNotification] = useReducer(reducer, {
    AssignTo: [],
    SuperAdmin: [],
    LineManager: [],
    TeamLead: [],
    CreatedFrom: [],
    Client: [],
    LeadAuditor: [],
  });

  const [TypePrerequisites, setTypePrerequisites] = useState({
    AssignToTemplat: '',
    SuperAdminTemplat: '',
    ClientTemplat: '',
    LeadAuditorTemplat: '',
    lineManagerTemplat: '',
    TeamLeadTemplat: '',
    CreatedFromNotificationByTemplat: '',
  });

  const TabClicked = (event, newValue) => {
    setTabIndex(newValue);
  };

  const getActivityTypeReminders = useCallback(async () => {

    setIsLoading(true);
    const activityTypeId = activeItem && activeItem.activityTypeId;
    const res = await GetActivityTypeReminders(activityTypeId
    );
    if (!(res && res.status && res.status !== 200)) {

      if (res.length > 0) {
        const mappedReminders = res.map(item => ({
          reminderType: item.reminderType,
          reminderTo: item.reminderTo,
          reminderTime: item.reminderTime,
          templateId: item.templateId,
          templateName: item.templateName
        }))

        setActivityTypeReminders(mappedReminders)
      } else {
        setActivityTypeReminders([{ ...defaultActivityReminderItem.current }]);
      }

    } else {
      setActivityTypeReminders([{ ...defaultActivityReminderItem.current }]);
    }
    setIsLoading(false);
  });

  const createActivityTypeReminder = useCallback(async (activityTypeId) => {
    setIsLoading(true);

    let remindersWithoutDefaultItem = [];
    if (activityTypeReminders && activityTypeReminders.length > 0) {
      remindersWithoutDefaultItem = activityTypeReminders.filter(item => item.reminderTo != null && item.reminderType != null && item.templateId != null && item.reminderTime != 0) || [];
    }
    const body = state.withReminder ? remindersWithoutDefaultItem : [];

    const res = await CreateActivityTypeReminder(activityTypeId,
      body
    );
    setIsLoading(false);
  })


  const updateActivityTypeReminder = useCallback(async () => {
    setIsLoading(true);
    const activityTypeId = activeItem && activeItem.activityTypeId;

    let remindersWithoutDefaultItem = [];
    if (activityTypeReminders && activityTypeReminders.length > 0) {
      remindersWithoutDefaultItem = activityTypeReminders.filter(item => item.reminderTo != null && item.reminderType != null && item.templateId != null && item.reminderTime != 0) || [];

    }
    const body = state.withReminder ? remindersWithoutDefaultItem : [];
    const res = await UpdateActivityTypeReminder(activityTypeId,
      body
    );
    setIsLoading(false);
  });


  const saveHandler = async () => {

    let remindersWithoutDefaultItem = activityTypeReminders && activityTypeReminders.filter(item => item.reminderTo != null || item.reminderType != null || item.templateId != null || item.reminderTime != 0) || [];

    const schema = (Joi.array().items(
      Joi.object({
        reminderType: Joi.any(),
        reminderTime: Joi.any(),
        reminderTo: Joi.any(),
        templateId: Joi.any(),
      })
        .custom((value, helpers) => {
          if ((state.withReminder && !value.reminderType))
            return helpers.error('state.reminderType');
          if ((state.withReminder && value.reminderTo === null))
            return helpers.error('state.reminderTo');
          if ((state.withReminder && !value.templateId))
            return helpers.error('state.emptyTemplateId');
          if ((state.withReminder && value.reminderTime <= 0))
            return helpers.error('state.reminderTime');
          return value;
        })
        .messages({
          'state.reminderType': t(`${translationPath}reminder-type-is-required`),
          'state.reminderTo': t(`${translationPath}reminder-to-is-required`),
          'state.emptyTemplateId': t(`${translationPath}reminder-template-is-required`),
          'state.reminderTime': t(`${translationPath}reminder-time-must-be-greater-than-zero`),
        })
    ))
      .options({
        abortEarly: false,
        allowUnknown: true,
      })
      .validate(remindersWithoutDefaultItem);

    if (schema?.error?.message) {
      showError(schema.error.message);
      return;
    }
    setIsLoading(true);
    const res =
      (edit && edit && (await UpdateActivityTypes(activeItem.activityTypeId, state))) ||
      (await CreateActivityTypes(state));

    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      const activityTypeId = res.activityTypeId;
      if (activeItem && activeItem.activityTypeId) {
        showSuccess(t`${translationPath}activity-Type-updated-successfully`);
        updateActivityTypeReminder();
        setReloading(true);
        onCancelClicked();
      } else {
        showSuccess(t`${translationPath}activity-Type-created-successfully`);
        createActivityTypeReminder(activityTypeId);
        setReloading(true);
        onCancelClicked();
      }
    } else if (edit) showError(t(`${translationPath}activity-Type-update-failed`));
    else showError(t`${translationPath}activity-Type-create-failed`);

  };

  useEffect(() => {
    if (edit && Data) {

      const activityTypeRotationRoles = Data.activityTypeRotationRoles && Data.activityTypeRotationRoles.map(item => ({
        rolesId: item.rolesId,
      }))
      const activityTypeRotationUsers = Data.activityTypeRotationUsers && Data.activityTypeRotationUsers.map(item => ({
        usersId: item.usersId,
      }))
      setState({
        id: 'edit',
        value: {
          activityTypeName: Data.activityTypeName,
          leadStageId: Data.leadStageId,
          leadStageName: Data.leadStageName,
          leadRatingId: Data.leadRatingId,
          leadRatingName: Data.leadRatingName,
          leadQualificationId: Data.leadQualificationId,
          leadQualificationName: Data.leadQualificationName,
          categoryId: Data.categoryId,
          categoryName: Data.categoryName,
          description: Data.description,
          activityRate: Data.activityRate,
          activityRateName: Data.activityRateName,
          withDateTime: Data.withDateTime,
          withReminder: Data.withReminder,
          withDuration: Data.withDuration,
          followUpRequired: Data.followUpRequired,
          isForMobile: Data.isForMobile,
          slaAppliedFor: Data.slaAppliedFor,
          isImportant: Data.isImportant,
          isWithslaExpirationPeriod: Data.slaExpirationPeriod ? true : false,
          slaExpirationPeriod: Data.slaExpirationPeriod,
          slaExpirationPeriodFreqency: Data.slaExpirationPeriodFreqency,
          slaExpirationPeriodComparisonDate: Data.slaExpirationPeriodComparisonDate,
          expiredPeriod: Data.expiredPeriod,
          relatedTo: Data.relatedTo,
          createActivityTypeRoles: Data.createActivityTypeRoles,
          activityTypeCreatedByBranches: Data.activityTypeCreatedByBranches,
          assignedToActivityTypeRoles: Data.assignedToActivityTypeRoles,
          activityTypeTemplateRecipientTypes: Data.activityTypeTemplateRecipientTypes,
          activities: Data.activities,
          activityTypeActivityTypePrerequisites: Data.activityTypeActivityTypePrerequisites,
          activityTypeRotationRoles,
          activityTypeRotationUsers,
        },
      });

      setSelected({
        id: 'edit',
        value: {
          activityTypeRotationRoles: Data.activityTypeRotationRoles,
          activityTypeRotationUsers: Data.activityTypeRotationUsers,
        }
      });
    }
  }, [Data, edit]);


  useEffect(() => {

    if (edit) {
      getActivityTypeReminders();
    } else {
      setActivityTypeReminders([{ ...defaultActivityReminderItem.current }]);
    }

  }, [edit])

  useEffect(() => {
    if (edit && Data) {
      Data.activityTypeTemplateRecipientTypes && Data.activityTypeTemplateRecipientTypes.forEach((item, index) => {

        if (item.recipientTypeId === RecipientTypeCoreEnum.AssignTo.key) {
          setAssigntoNOT(
            (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                key: x.notifyById,
                value:
                  Object.values(ReminderTypeEnum).findIndex(
                    (element) => element.key === x.notifyById
                  ) !== -1 &&
                  Object.values(ReminderTypeEnum).find((element) => element.key === x.notifyById).value,
              })
              )) || []
          )
          const filterData =
            Data &&
            Data.activityTypeTemplateRecipientTypes &&
            Data.activityTypeTemplateRecipientTypes.filter(
              (obj) => obj.recipientTypeId === RecipientTypeCoreEnum.AssignTo.key
            );

          setTypePrerequisites((item) => ({
            ...item,
            AssignToTemplat: {
              templateId: filterData && filterData[0] && filterData[0].templateId,
              templateName: filterData && filterData[0] && filterData[0].templateName,
              recipientTypeId: RecipientTypeCoreEnum.AssignTo.key,
              activityTypeTemplateRecipientTypeSources:
                (item.activityTypeTemplateRecipientTypeSources &&
                  item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                    notifyBy: x.notifyById,
                  }))) || [],
            },
          }));
          setStateNotification({
            id: 'AssignTo',
            value: (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                notifyBy: x.notifyById,
              }))) || [],
          })
        }

        if (item.recipientTypeId === RecipientTypeCoreEnum.CreatedBy.key) {
          setCreatedFromNotificationByNOT(
            (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                key: x.notifyById,
                value:
                  Object.values(ReminderTypeEnum).findIndex(
                    (element) => element.key === x.notifyById
                  ) !== -1 &&
                  Object.values(ReminderTypeEnum).find((element) => element.key === x.notifyById).value,
              })
              )) || []
          )

          const filterData =
            Data &&
            Data.activityTypeTemplateRecipientTypes &&
            Data.activityTypeTemplateRecipientTypes.filter(
              (obj) => obj.recipientTypeId === RecipientTypeCoreEnum.CreatedBy.key
            );

          setTypePrerequisites((item) => ({
            ...item,
            CreatedFromNotificationByTemplat: {
              templateId: filterData && filterData[0] && filterData[0].templateId,
              templateName: filterData && filterData[0] && filterData[0].templateName,
              recipientTypeId: RecipientTypeCoreEnum.CreatedBy.key,
              activityTypeTemplateRecipientTypeSources:
                (item.activityTypeTemplateRecipientTypeSources &&
                  item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                    notifyBy: x.notifyById,
                  }))) || [],
            },
          }));

          setStateNotification({
            id: 'CreatedFrom',
            value: (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                notifyBy: x.notifyById,
              }))) || [],
          })
        }

        if (item.recipientTypeId === RecipientTypeCoreEnum.TeamLead.key) {
          setTeamLeadNOT(
            (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                key: x.notifyById,
                value:
                  Object.values(ReminderTypeEnum).findIndex(
                    (element) => element.key === x.notifyById
                  ) !== -1 &&
                  Object.values(ReminderTypeEnum).find((element) => element.key === x.notifyById).value,
              })
              )) || []
          )

          const filterData =
            Data &&
            Data.activityTypeTemplateRecipientTypes &&
            Data.activityTypeTemplateRecipientTypes.filter(
              (obj) => obj.recipientTypeId === RecipientTypeCoreEnum.TeamLead.key
            );

          setTypePrerequisites((item) => ({
            ...item,
            TeamLeadTemplat: {
              templateId: filterData && filterData[0] && filterData[0].templateId,
              templateName: filterData && filterData[0] && filterData[0].templateName,
              recipientTypeId: RecipientTypeCoreEnum.TeamLead.key,
              activityTypeTemplateRecipientTypeSources:
                (item.activityTypeTemplateRecipientTypeSources &&
                  item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                    notifyBy: x.notifyById,
                  }))) || [],
            },
          }));

          setStateNotification({
            id: 'TeamLead',
            value: (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                notifyBy: x.notifyById,
              }))) || [],
          })
        }

        if (item.recipientTypeId === RecipientTypeCoreEnum.LineManager.key) {
          setlineManagerNOT(
            (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                key: x.notifyById,
                value:
                  Object.values(ReminderTypeEnum).findIndex(
                    (element) => element.key === x.notifyById
                  ) !== -1 &&
                  Object.values(ReminderTypeEnum).find((element) => element.key === x.notifyById).value,
              })
              )) || []
          )

          const filterData =
            Data &&
            Data.activityTypeTemplateRecipientTypes &&
            Data.activityTypeTemplateRecipientTypes.filter(
              (obj) => obj.recipientTypeId === RecipientTypeCoreEnum.LineManager.key
            );

          setTypePrerequisites((item) => ({
            ...item,
            lineManagerTemplat: {
              templateId: filterData && filterData[0] && filterData[0].templateId,
              templateName: filterData && filterData[0] && filterData[0].templateName,
              recipientTypeId: RecipientTypeCoreEnum.LineManager.key,
              activityTypeTemplateRecipientTypeSources:
                (item.activityTypeTemplateRecipientTypeSources &&
                  item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                    notifyBy: x.notifyById,
                  }))) || [],
            },
          }));

          setStateNotification({
            id: 'LineManager',
            value: (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                notifyBy: x.notifyById,
              }))) || [],
          })
        }

        if (item.recipientTypeId === RecipientTypeCoreEnum.Client.key) {
          setClientNOT(
            (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                key: x.notifyById,
                value:
                  Object.values(ReminderTypeEnum).findIndex(
                    (element) => element.key === x.notifyById
                  ) !== -1 &&
                  Object.values(ReminderTypeEnum).find((element) => element.key === x.notifyById).value,
              })
              )) || []
          )

          const filterData =
            Data &&
            Data.activityTypeTemplateRecipientTypes &&
            Data.activityTypeTemplateRecipientTypes.filter(
              (obj) => obj.recipientTypeId === RecipientTypeCoreEnum.Client.key
            );

          setTypePrerequisites((item) => ({
            ...item,
            ClientTemplat: {
              templateId: filterData && filterData[0] && filterData[0].templateId,
              templateName: filterData && filterData[0] && filterData[0].templateName,
              recipientTypeId: RecipientTypeCoreEnum.Client.key,
              activityTypeTemplateRecipientTypeSources:
                (item.activityTypeTemplateRecipientTypeSources &&
                  item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                    notifyBy: x.notifyById,
                  }))) || [],
            },
          }));
          setStateNotification({
            id: 'Client',
            value: (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                notifyBy: x.notifyById,
              }))) || [],
          })
        }

        if (item.recipientTypeId === RecipientTypeCoreEnum.SuperAdmin.key) {
          setSuperAdminnNOT(
            (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                key: x.notifyById,
                value:
                  Object.values(ReminderTypeEnum).findIndex(
                    (element) => element.key === x.notifyById
                  ) !== -1 &&
                  Object.values(ReminderTypeEnum).find((element) => element.key === x.notifyById).value,
              })
              )) || []
          )

          const filterData =
            Data &&
            Data.activityTypeTemplateRecipientTypes &&
            Data.activityTypeTemplateRecipientTypes.filter(
              (obj) => obj.recipientTypeId === RecipientTypeCoreEnum.SuperAdmin.key
            );

          setTypePrerequisites((item) => ({
            ...item,
            SuperAdminTemplat: {
              templateId: filterData && filterData[0] && filterData[0].templateId,
              templateName: filterData && filterData[0] && filterData[0].templateName,
              recipientTypeId: RecipientTypeCoreEnum.SuperAdmin.key,
              activityTypeTemplateRecipientTypeSources:
                (item.activityTypeTemplateRecipientTypeSources &&
                  item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                    notifyBy: x.notifyById,
                  }))) || [],
            },
          }));

          setStateNotification({
            id: 'SuperAdmin',
            value: (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                notifyBy: x.notifyById,
              }))) || [],
          })
        }

        if (item.recipientTypeId === RecipientTypeCoreEnum.LeadAuditor.key) {
          setLeadAuditorNOT(
            (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                key: x.notifyById,
                value:
                  Object.values(ReminderTypeEnum).findIndex(
                    (element) => element.key === x.notifyById
                  ) !== -1 &&
                  Object.values(ReminderTypeEnum).find((element) => element.key === x.notifyById).value,
              })
              )) || []
          )

          const filterData =
            Data &&
            Data.activityTypeTemplateRecipientTypes &&
            Data.activityTypeTemplateRecipientTypes.filter(
              (obj) => obj.recipientTypeId === RecipientTypeCoreEnum.LeadAuditor.key
            );

          setTypePrerequisites((item) => ({
            ...item,
            LeadAuditorTemplat: {
              templateId: filterData && filterData[0] && filterData[0].templateId,
              templateName: filterData && filterData[0] && filterData[0].templateName,
              recipientTypeId: RecipientTypeCoreEnum.LeadAuditor.key,
              activityTypeTemplateRecipientTypeSources:
                (item.activityTypeTemplateRecipientTypeSources &&
                  item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                    notifyBy: x.notifyById,
                  }))) || [],
            },
          }));
          setStateNotification({
            id: 'LeadAuditor',
            value: (item.activityTypeTemplateRecipientTypeSources &&
              item.activityTypeTemplateRecipientTypeSources.map((x) => ({
                notifyBy: x.notifyById,
              }))) || [],
          })
        }
      })
    }


  }, [edit, Data])


  useEffect(() => {
    const filteredRecipientTypes = Object.values(TypePrerequisites).filter((item) => (item.templateId !== undefined && item.templateId !== ''));

    const mappedRecipientTypes = filteredRecipientTypes && filteredRecipientTypes.map(item => {
      const stateNotificationKey = NotificationByEnum[item.recipientTypeId].value;
      return ({
        ...item,
        activityTypeTemplateRecipientTypeSources: stateNotification[stateNotificationKey]
      })
    }
    )

    setState({
      id: 'activityTypeTemplateRecipientTypes',
      value: mappedRecipientTypes,
    })

  }, [Data, edit, stateNotification, TypePrerequisites])

  return (
    <>
      {(edit && Data) && <ButtonBase style={{ float: 'inline-end' }}
        className='btns theme-transparent c-black-light'
        onClick={() => {
          setStatusHistoryDialog(true);
        }}>
        <span className='mdi mdi-clock-time-four-outline' />
        <span className='px-2'>{t(`${translationPath}history`)}</span>
      </ButtonBase>}
      <div>
        <Spinner isActive={IsLoading} />
        <Tabs
          value={tabIndex}
          onChange={TabClicked}
          aria-label='icon label tabs'
          textColor='primary'
          classes={{
            indicator: classes.indicator,
            scroller: classes.scroller,
            fixed: classes.fixed,
          }}>
          <Tab
            classes={{
              root: classes.root,
              wrapper: classes.wrapper,
              labelIcon: classes.labelIcon,
            }}
            icon={<span className='mr-2 mdi mdi-format-list-checks ' />}
            label={t(`${translationPath}details`)}
          />
          <Tab
            classes={{
              root: classes.root,
              wrapper: classes.wrapper,
              labelIcon: classes.labelIcon,
            }}
            icon={<span className='mr-2 mdi mdi-bell' />}
            label={t(`${translationPath}notifications`)}
          />
          <Tab
            classes={{
              root: classes.root,
              wrapper: classes.wrapper,
              labelIcon: classes.labelIcon,
            }}
            icon={<span className='mr-2 mdi mdi-alarm-check' />}
            label={t(`${translationPath}reminders`)}
          />
          <Tab
            classes={{
              root: classes.root,
              wrapper: classes.wrapper,
              labelIcon: classes.labelIcon,
            }}
            icon={<span className='mr-2 mdi mdi-account-convert' />}
            label={t(`${translationPath}rotation`)}
          />
        </Tabs>

        {tabIndex === 0 && (
          <div className='px-5 pt-3 w-100 '>
            <DetailsTabComponent
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              setState={setState}
              state={state}
              Data={Data}
              expirationPeriodTimeType={expirationPeriodTimeType}
              setExpirationPeriodTimeType={setExpirationPeriodTimeType}
            />
          </div>
        )}

        {tabIndex === 1 && (
          <div className='px-5 pt-3 w-100 '>
            <NotificationsTabComponent
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              setState={setState}
              state={state}
              Data={Data}
              setStateNotification={setStateNotification}
              stateNotification={stateNotification}
              setTypePrerequisites={setTypePrerequisites}
              TypePrerequisites={TypePrerequisites}
              setSuperAdminnNOT={setSuperAdminnNOT}
              SuperAdminnNOT={SuperAdminnNOT}
              setSuperAdminData={setSuperAdminData}
              SuperAdminData={SuperAdminData}
              setClientData={setClientData}
              ClientData={ClientData}
              setClientNOT={setClientNOT}
              ClientNOT={ClientNOT}
              setAssigntoNOT={setAssigntoNOT}
              AssigntoNOT={AssigntoNOT}
              setAssigntoData={setAssigntoData}
              setlineManagerTemplatData={setlineManagerTemplatData}
              setlineManagerNOT={setlineManagerNOT}
              lineManagerNOT={lineManagerNOT}
              setTeamLeadTemplatData={setTeamLeadTemplatData}
              setTeamLeadNOT={setTeamLeadNOT}
              teamLeadNOT={teamLeadNOT}
              setCreatedFromNotificationByData={setCreatedFromNotificationByData}
              CreatedFromNotificationByData={CreatedFromNotificationByData}
              setCreatedFromNotificationByNOT={setCreatedFromNotificationByNOT}
              CreatedFromNotificationByNOT={CreatedFromNotificationByNOT}
              setLeadAuditorNOT={setLeadAuditorNOT}
              LeadAuditorNOT={LeadAuditorNOT}
              setLeadAuditorData={setLeadAuditorData}
              LeadAuditorData={LeadAuditorData}
            />
          </div>
        )}
        {tabIndex === 2 && (
          <div className='px-5 pt-3 w-100 '>
            <RemindersTabComponent
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state}
              activeItem={activeItem}
              edit={edit}
              setActivityTypeReminders={setActivityTypeReminders}
              activityTypeReminders={activityTypeReminders}
            />
          </div>
        )}
        {tabIndex === 3 && (
          <div className='px-5 pt-3 w-100 '>
            <RotationTabComponent
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state}
              setState={setState}
              selected={selected}
              setSelected={setSelected}
              activeItem={activeItem}
              edit={edit}
            />
          </div>
        )}
      </div>
      <div className='MuiDialogActions-root dialog-footer-wrapper  MuiDialogActions-spacing'>
        <div className='save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2'>
          <div className='cancel-wrapper d-inline-flex-center'>
            <Button
              className='MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary'
              onClick={onCancelClicked}
              id='cancel-ref-action'
            >
              <span className='MuiButton-label'>
                <span>{t('Shared:cancel')}</span>
              </span>
              <span className='MuiTouchRipple-root' />
            </Button>
          </div>
          <div className='save-wrapper d-inline-flex-center'>
            <Button
              onClick={saveHandler}
              disabled={DisabledFiledValidation(state, TypePrerequisites,
                SuperAdminnNOT,
                ClientNOT,
                LeadAuditorNOT,
                lineManagerNOT,
                teamLeadNOT,
                CreatedFromNotificationByNOT,
                AssigntoNOT)}
              className='MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-primary'
            >
              <span className='MuiButton-label'>
                <span>{t('Shared:save')}</span>
              </span>
              <span className='MuiTouchRipple-root' />
            </Button>
          </div>
        </div>
      </div>
      {statusHistoryDialog && (
        <ActivityTypeHistoryDialog
          id={activeItem && activeItem.activityTypeId || null}
          isOpen={statusHistoryDialog}
          isOpenChanged={() => {
            setStatusHistoryDialog(false);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </>
  );
};

ActivityTypeManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  activeItem: PropTypes.instanceOf(Object).isRequired,
  Data: PropTypes.instanceOf(Object).isRequired,
  edit: PropTypes.bool.isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  GetAllActivityTypesAPI: PropTypes.func.isRequired,
  setSearchedItem: PropTypes.func.isRequired,
  setReloading: PropTypes.func,
};
ActivityTypeManagementDialog.defaultProps =
{
  setReloading: () => { },

};
