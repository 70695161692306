import React, { useState, useCallback, useEffect, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  bottomBoxComponentUpdate,
  GlobalHistory,
} from '../../../../Helper';
import {
  ViewTypes,
  AutocompleteComponent,
  SelectComponet,
  Spinner,
  Inputs,
  Tables,
} from '../../../../Components';
import { PaginationComponent } from '../../../../Components/PaginationComponent/PaginationComponent';
import { ViewTypesEnum, TableActions } from '../../../../Enums';
import { CampaignRequestCard, RequestSideDetails } from '../Utilities/';
import {
  GetAllCampaignForms,
  GetAllCampaignDepartments,
  GetAllCampaignsRequests,
} from '../../../../Services';
import { ApplicationUserSearch } from '../../../../Services/userServices';
import { useTitle } from '../../../../Hooks';
import { CampaignStatusEnum } from '../../../../Enums';
import {CampaignRequestHeaderData} from './CampaignRequestHeaderData'
import { DateRangePickerComponent } from '../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { useDispatch } from 'react-redux';
import { MarketingCampaignsActions } from '../../../../store/MarketingCampaigns/Actions';

const parentTranslationPath = 'Campaign';
const translationPath = '';
export const CampaignRequestsView = () => {
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}campaign-requests`));

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: 1,
  };
  const dispatch = useDispatch();

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [state, setState] = useReducer(reducer, {
    departmentId: null,
    userId: null,
    statusId: null,
    fromDate: null,
    toDate: null,
    search: null,
  });
  const [selected, setSelected] = useReducer(reducer, {
    department: null,
    user: null,
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    departments: false,
    users: false,
    campaignRequests: false,
  });
  const [data, setData] = useReducer(reducer, {
    departments: [],
    users: [],
    campaignRequests: {
      result: [],
      totalCount: 0,
    },
  });
  const [actionsList, setActionsList] = useState([
    {
      enum: TableActions.openFile.key,
    },
  ]);
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
    selectedDateType: dateRangeDefault,
  });
  const [activeActionType, setActiveActionType] = useState(ViewTypesEnum.cards.key);
  const searchTimer = useRef(null);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
  });

  const getAllCampaignsRequests = async () => {
    setIsLoading((item) => ({ ...item, campaignRequests: true }));

    const body = {
      ...state,
    };
    const res = await GetAllCampaignsRequests({
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
      body,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: 'campaignRequests',
        value: {
          result: res?.result || [],
          totalCount: res?.totalCount || 0,
        },
      });
    } else {
      setData({
        id: 'campaignRequests',
        value: {
          result: [],
          totalCount: 0,
        },
      });
    }
    setIsLoading((item) => ({ ...item, campaignRequests: false }));
  };

  const getAllUsers = async (searchValue) => {
    setIsLoading({ id: 'users', value: true });
    const res = await ApplicationUserSearch({
      pageIndex: 0,
      pageSize: 25,
      name: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({
          id: 'users',
          value: localValue,
        });
      } else {
        setData({
          id: 'users',
          value: [],
        });
      }
      setIsLoading({ id: 'users', value: false });
    }
  };

  const getAllCampaignForms = async (searchValue) => {
    setIsLoading({ id: 'forms', value: true });
    const res = await GetAllCampaignForms({
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'forms', value: res || [] });
    } else setData({ id: 'forms', value: [] });

    setIsLoading({ id: 'forms', value: false });
  };

  const getAllCampaignDepartments = async (searchValue) => {
    setIsLoading({ id: 'departments', value: true });
    const res = await GetAllCampaignDepartments({
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'departments', value: res || [] });
    } else setData({ id: 'departments', value: [] });

    setIsLoading({ id: 'departments', value: false });
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const onTypeChanged = useCallback((activeType) => {
    setActiveActionType(activeType);
  }, []);

  const focusedRowChanged = useCallback((rowIndex, item) => {
    if (rowIndex !== -1) campaignClickHandler(item);
  }, []);

  const dateFilterHandler = (selectedDate) => {
    setDateFilter((item) => ({
      ...item,
      startDate: selectedDate?.selection?.startDate,
      endDate: selectedDate?.selection?.endDate,
      key: 'selection',
    }));
    setState({
      id: 'edit',
      value: {
        ...state,
        fromDate: selectedDate?.selection?.startDate
          ? new Date(moment(selectedDate.selection.startDate).endOf('day'))
          : null,
        toDate: selectedDate?.selection?.endDate
          ? new Date(moment(selectedDate.selection.endDate).endOf('day'))
          : null,
      },
    });
  };

  const actionClickHandler = useCallback(async (actionEnum, item) => {
    if (actionEnum === TableActions.openFile.key) {
      const campaignRequestId = item.id;
      if(campaignRequestId) await dispatch(MarketingCampaignsActions.marketingCampaignsRequest({
        campaignRequestId,
      }));
      GlobalHistory.push(`/home/campaign-requests/view-campaign-request`);
    }
  }, []);

  const campaignClickHandler = useCallback((item) => {
    sideMenuComponentUpdate(<RequestSideDetails activeData={item} />);
    sideMenuIsOpenUpdate(true);
  }, []);

  const departmentSelectHandler = (departmentId) => {
    GlobalHistory.push(`/home/campaign-requests/add?departmentId=${departmentId}`);
  };

  useEffect(() => {
    getAllUsers();
    getAllCampaignDepartments();
    getAllCampaignForms();
  }, []);

  useEffect(() => {
    getAllCampaignsRequests();
  }, [state, filter]);


  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  useEffect(() => {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={data?.campaignRequests?.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
  });

  return (
    <div className='view-wrapper leads leads-wrapper'>
      <Spinner isActive={isLoading.campaignRequests} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section'>
              <div className='actions-buttons-wrapper'>
                <SelectComponet
                  data={data.departments || []}
                  defaultValue={-1}
                  emptyItem={{ value: -1, text: 'add', isHiddenOnOpen: true }}
                  valueInput='departmentId'
                  textInput='name'
                  onSelectChanged={(value) => departmentSelectHandler(value)}
                  wrapperClasses='bg-secondary c-white mx-2 px-2'
                  themeClass='theme-action-buttons'
                  idRef='AddActionsRef'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
            </div>
            <div className='section autocomplete-section'>
              <div className='d-flex-column px-2 w-100 p-relative'>
                <div className='w-100 p-relative'>
                  <Inputs
                    onKeyUp={(event) => {
                      const { value } = event.target;
                      if (searchTimer.current) clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        setState({ id: 'search', value: value || null });
                      }, 700);
                    }}
                    idRef='activitiesSearchRef'
                    label={t(`${translationPath}search`)}
                    beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                  />
                </div>
                <div className='d-flex-v-center-h-between pl-4 mb-2'>
                  <div className='mr-2'>
                    <AutocompleteComponent
                      idRef='departmentApprovalUserRef'
                      inputPlaceholder={t(`${translationPath}user`)}
                      selectedValues={selected.user}
                      isDisabled={isLoading.users}
                      data={data.users || []}
                      displayLabel={(option) => option.fullName || ''}
                      multiple={false}
                      withoutSearchButton
                      onChange={(event, newValue) => {
                        setState({
                          id: 'userId',
                          value: newValue && newValue.applicationUserId,
                        });
                        setSelected({
                          id: 'user',
                          value: newValue,
                        });
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getAllUsers(value);
                        }, 1200);
                      }}
                      onKeyDown={() => {
                        setState({
                          id: 'userId',
                          value: null,
                        });
                        setSelected({
                          id: 'user',
                          value: null,
                        });
                      }}
                      isLoading={isLoading.users}
                    />
                  </div>
                  <div className='mr-2 w-100'>
                    <AutocompleteComponent
                      idRef='departmentRef'
                      inputPlaceholder={t(`${translationPath}department`)}
                      selectedValues={selected.department}
                      isDisabled={isLoading.departments}
                      data={data.departments || []}
                      displayLabel={(option) => option.name || option.departmentName || ''}
                      multiple={false}
                      withoutSearchButton
                      onChange={(event, newValue) => {
                        setState({
                          id: 'departmentId',
                          value: newValue && newValue.departmentId,
                        });
                        setSelected({
                          id: 'department',
                          value: newValue,
                        });
                      }}
                      isLoading={isLoading.departments}
                    />
                  </div>
                  <div className='w-100'>
                    <SelectComponet
                      data={Object.values(CampaignStatusEnum)}
                      emptyItem={{
                        value: 0,
                        text: `${t(`${translationPath}status`)}`,
                        isDisabled: false,
                      }}
                      value={state.statusId || 0}
                      valueInput='id'
                      textInput='name'
                      onSelectChanged={(value) => {
                        setState({ id: 'statusId', value: value || null });
                      }}
                      wrapperClasses='w-auto'
                      themeClass='theme-default'
                      idRef='Status'
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  </div>
                  <div className='w-100 customDatePicker'>
                    <div className='date-type-select'>
                      <SelectComponet
                        data={[{ key: 1, value: `${translationPath}created-date` }]}
                        defaultValue={{ key: 1, value: `${translationPath}created-date` }}
                        value={1}
                        valueInput='key'
                        textInput='value'
                        isDisabled={isLoading.campaignRequests}
                        wrapperClasses='w-auto'
                        themeClass='theme-transparent'
                        idRef='Date_Select'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <DateRangePickerComponent
                      onClearClicked={() => {
                        setDateFilter(dateRangeDefault);
                        dateFilterHandler(dateRangeDefault);
                      }}
                      ranges={[dateFilter]}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onDateChanged={(selectedDate) => {

                        dateFilterHandler(selectedDate);
                      }}
                    />
                  </div>
                </div>
                <div className='d-flex-center pl-4'></div>
              </div>

              <div className='view-search-wrapper'>
                <ViewTypes
                  activeTypes={[ViewTypesEnum.cards.key, ViewTypesEnum.tableView.key]}
                  onTypeChanged={onTypeChanged}
                  initialActiveType={activeActionType}
                  className='mb-3'
                />
              </div>
            </div>
          </div>
        </div>
        {activeActionType !== ViewTypesEnum.tableView.key && (
          <div className='body-section'>
            <CampaignRequestCard
              data={data.campaignRequests}
              onCardClicked={campaignClickHandler}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              actionsList={actionsList}
              actionClickHandler={actionClickHandler}
            />
          </div>
        )}

        {activeActionType === ViewTypesEnum.tableView.key && (
          <div className='p-3 w-100'>
            <Tables
              data={data.campaignRequests.result}
              headerData={CampaignRequestHeaderData}
              actionsOptions={{
                onActionClicked: actionClickHandler,
              }}
              itemsPerPage={filter.pageSize}
              activePage={filter.pageIndex}
              defaultActions={actionsList}
              focusedRowChanged={focusedRowChanged}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              isLoading={isLoading.campaignRequests}
            />
          </div>
        )}
      </div>
    </div>
  );
};
