import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import moment from 'moment';
import { showError, GlobalTranslate } from '../../../../Helper';
import {
  GetUserPolicyService,
  MicrosoftToken,
  UsersIdsForPolicyUnits,
} from '../../../../Services';
import { GetUserTeamsInfo } from '../../../../Services/userServices';
import { SpinnerDashboards } from '../SpinnerDashboards/SpinnerDashboards';
import { useTitle } from '../../../../Hooks';

export const LeaseListingUnitMainPolicy = ({ dynamicComponentProps }) => {
  useTitle(GlobalTranslate.t('Dashboard:admin-dashboard'));

  const [userid] = useState(
    JSON.parse(localStorage.getItem('session')).userId || null
  );
  const [Cityid, setCityid] = useState([]);
  const [communityid, setcommunityid] = useState([]);
  const [Subcommunityid, setsubcommunityid] = useState([]);
  const [districtid, setdistrictid] = useState([]);
  const [Propertyid, setPropertyid] = useState([]);
  const [ShowReport, setShowReport] = useState(false);
  const [report, setReport] = useState();
  const [teamid, setTeamid] = useState([]);
  const [userForPolicy, setuserForPolicy] = useState([]);
  const [OperatorCreatedBy, setOperatorCreatedBy] = useState('All');

  const [
    userForPolicymyBusinessCreatedBy,
    setuserForPolicymyBusinessGroupUsersCreatedBy,
  ] = useState([]);
  const [userForPolicyMyUserCreatedBy, setuserForPolicyMyUserCreatedBy] =
    useState([]);
  const [
    userForPolicymyTeamUsersCreatedBy,
    setuserForPolicymyTeamUsersCreatedBy,
  ] = useState([]);
  const [
    userForPolicymyBranchUsersCreatedBy,
    setuserForPolicymyBranchUsersCreatedBy,
  ] = useState([]);
  const [
    userForPolicymyManageUsersCreatedBy,
    setuserForPolicymyManageUsersCreatedBy,
  ] = useState([]);
  const [
    userForPolicyspecificUsersCreatedBy,
    setuserForPolicyspecificUsersCreatedBy,
  ] = useState([]);

  const [
    userForPolicymyTeamLeadUsersCreatedBy,
    setuserForPolicymyTeamLeadUsersCreatedBy,
  ] = useState([]);

  const [OperatorRentListing, setOperatorRentListing] = useState('All');

  const [myBusinessGroupUsersRentlisting, setmyBusinessGroupUsersRentlisting] =
    useState([]);
  const [MyUserleaseListing, setMyUserleaseListing] = useState([]);
  const [myTeamUsersRentlisting, setmyTeamUsersRentlisting] = useState([]);
  const [myBranchUsersRentlisting, setmymyBranchUsersRentlisting] = useState(
    []
  );
  const [myManageUsersRentlisting, setmymyManageUsersRentlisting] = useState(
    []
  );
  const [specificUsersRentlisting, setspecificUsersRentlisting] = useState([]);

  const [myTeamLeadUsersRentlisting, setmyTeamLeadUsersRentlisting] = useState(
    []
  );

  const [render, setRender] = useState(false);
  const [policyrener, setpolicyrener] = useState(null);

  const [LeadstatusOperator, setLeadstatusOperator] = useState('All');
  const [leadsStatus, setStatus] = useState('');
  const [Available, setAvailable] = useState('');
  const [ReservedLeased, setReservedLeased] = useState('');
  const [Leased, setLeased] = useState('');
  const [ReservedSale, setReservedSale] = useState('');
  const [Sale, setSale] = useState('');
  const [Draft, setDraft] = useState('');
  const [PendingReservedLease, setPendingReservedLease] = useState('');
  const [PendingLeased, setPendingLeased] = useState('');
  const [PendingReservedSale, setPendingReservedSale] = useState('');
  const [PendingSale, setPendingSale] = useState('');
  const [unitStatus, setunitStatus] = useState(null);
  const [Datetime, setDatetime] = useState(null);

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  const today = new Date();
  const date =
    `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

  const count = new Date(new Date().setDate(today.getDate() - Datetime));
  const count2 = new Date(new Date().setDate(today.getDate()));

  const datetimes = moment(count).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
  const datetimes2 = moment(count2).endOf('day').format('YYYY-MM-DDTHH:mm:ss');

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss')) ||
        null,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss')) ||
        null,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const AddressIds = {
    $schema: 'http://powerbi.com/product/schema#advanced',
    target: {
      table: 'ListingUnitsViews',
      column: 'addressIds',
    },
    logicalOperator: 'Or',
    conditions: [
      {
        operator: 'Contains',
        value: Cityid[0] || null,
      },
      {
        operator: 'Contains',
        value: Cityid[1] || null,
      },
      {
        operator: 'Contains',
        value: Cityid[2] || null,
      },
      {
        operator: 'Contains',
        value: Cityid[3] || null,
      },
      {
        operator: 'Contains',
        value: Cityid[4] || null,
      },
      {
        operator: 'Contains',
        value: communityid[0] || null,
      },
      {
        operator: 'Contains',
        value: communityid[1] || null,
      },
      {
        operator: 'Contains',
        value: communityid[2] || null,
      },
      {
        operator: 'Contains',
        value: communityid[3] || null,
      },
      {
        operator: 'Contains',
        value: communityid[4] || null,
      },
      {
        operator: 'Contains',
        value: Subcommunityid[0] || null,
      },
      {
        operator: 'Contains',
        value: Subcommunityid[1] || null,
      },
      {
        operator: 'Contains',
        value: Subcommunityid[2] || null,
      },
      {
        operator: 'Contains',
        value: Subcommunityid[3] || null,
      },
      {
        operator: 'Contains',
        value: Subcommunityid[4] || null,
      },
      {
        operator: 'Contains',
        value: districtid[0] || null,
      },
      {
        operator: 'Contains',
        value: districtid[1] || null,
      },
      {
        operator: 'Contains',
        value: districtid[2] || null,
      },
      {
        operator: 'Contains',
        value: districtid[3] || null,
      },
      {
        operator: 'Contains',
        value: districtid[4] || null,
      },
      {
        operator: 'Contains',
        value: Propertyid[0] || null,
      },
      {
        operator: 'Contains',
        value: Propertyid[1] || null,
      },
      {
        operator: 'Contains',
        value: Propertyid[2] || null,
      },
      {
        operator: 'Contains',
        value: Propertyid[3] || null,
      },
      {
        operator: 'Contains',
        value: Propertyid[4] || null,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const FilteCreatedBy = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'ListingUnitsViews',
      column: 'CreatedBy',
    },
    operator: OperatorCreatedBy,
    values: [
      ...userForPolicyMyUserCreatedBy,
      ...userForPolicyspecificUsersCreatedBy,
      ...userForPolicymyBusinessCreatedBy,
      ...userForPolicymyTeamUsersCreatedBy,
      ...userForPolicymyTeamLeadUsersCreatedBy,
      ...userForPolicymyManageUsersCreatedBy,
      ...userForPolicymyBranchUsersCreatedBy,
    ],

    filterType: models.FilterType.BasicFilter,
  };
  const FilteListingAgent = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'ListingUnitsViews',
      column: 'rent listing agent id',
    },
    operator: OperatorRentListing,
    values: [
      ...MyUserleaseListing,
      ...specificUsersRentlisting,
      ...myBusinessGroupUsersRentlisting,
      ...myTeamUsersRentlisting,
      ...myTeamLeadUsersRentlisting,
      ...myManageUsersRentlisting,
      ...myBranchUsersRentlisting,
    ],

    filterType: models.FilterType.BasicFilter,
  };

  const FilterStatus = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'ListingUnitsViews',
      column: 'Lease Unit status',
    },
    operator: LeadstatusOperator,
    values: [
      Available,
      Draft,
      PendingReservedLease,
      PendingLeased,
      ReservedLeased,
      Leased,
    ],
    filterType: models.FilterType.BasicFilter,
  };

  const IdsForPolicy = useCallback(async () => {
    const res = await UsersIdsForPolicyUnits(userid);
    if (!(res && res.status && res.status !== 200)) {
      setuserForPolicy(res && res.map((List) => List.tabName || null));

      setuserForPolicyMyUserCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'MyUserCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );
      setuserForPolicyspecificUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'specificUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyBusinessGroupUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myBusinessGroupUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyManageUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myManageUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyTeamUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myTeamUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyTeamLeadUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myTeamLeadUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      setuserForPolicymyBranchUsersCreatedBy(
        res &&
          res
            .filter((tab) => tab.tabName === 'myBranchUsersCreatedBy')
            .map((tab) => tab.usersGUID || [])
      );

      // Lease listing

      setMyUserleaseListing(
        res &&
          res
            .filter((tab) => tab.tabName === 'MyUserleaseListing')
            .map((tab) => tab.usersGUID || [])
      );

      setmyTeamUsersRentlisting(
        res &&
          res
            .filter((tab) => tab.tabName === 'myTeamUsersRentlisting')
            .map((tab) => tab.usersGUID || [])
      );

      setmymyBranchUsersRentlisting(
        res &&
          res
            .filter((tab) => tab.tabName === 'myBranchUsersRentlisting')
            .map((tab) => tab.usersGUID || [])
      );

      setmymyManageUsersRentlisting(
        res &&
          res
            .filter((tab) => tab.tabName === 'myManageUsersRentlisting')
            .map((tab) => tab.usersGUID || [])
      );

      setmyTeamLeadUsersRentlisting(
        res &&
          res
            .filter((tab) => tab.tabName === 'myTeamLeadUsersRentlisting')
            .map((tab) => tab.usersGUID || [])
      );
      setspecificUsersRentlisting(
        res &&
          res
            .filter((tab) => tab.tabName === 'specificUsersRentlisting')
            .map((tab) => tab.usersGUID || [])
      );

      setmyBusinessGroupUsersRentlisting(
        res &&
          res
            .filter((tab) => tab.tabName === 'myBusinessGroupUsersRentlisting')
            .map((tab) => tab.usersGUID || [])
      );
    } else {
      setuserForPolicymyBusinessGroupUsersCreatedBy([]);
      setuserForPolicyMyUserCreatedBy([]);
      setuserForPolicyspecificUsersCreatedBy([]);
      setuserForPolicymyTeamUsersCreatedBy([]);
      setuserForPolicymyTeamLeadUsersCreatedBy([]);
      setuserForPolicymyManageUsersCreatedBy([]);
      setuserForPolicymyBranchUsersCreatedBy([]);

      setMyUserleaseListing([]);
      setmyTeamUsersRentlisting([]);
      setmymyBranchUsersRentlisting([]);
      setmymyManageUsersRentlisting([]);
      setmyTeamLeadUsersRentlisting([]);
      setspecificUsersRentlisting([]);
      setmyBusinessGroupUsersRentlisting([]);
    }
  }, [userid]);

  const getUserTemasById = useCallback(async () => {
    const res = await GetUserTeamsInfo(userid, false);
    if (!(res && res.status && res.status !== 200))
      setTeamid(res && res.map((List) => List.teamsId || null));
    else setTeamid([]);
  }, [userid]);

  const getUserPolicyById = useCallback(async () => {
    const response = await GetUserPolicyService(userid);
    if (!(response && response.status && response.status !== 200)) {
      setpolicyrener(response.policyJson);

      setsubcommunityid(
        response &&
          response &&
          response.policyJson.units.address.subCommunity.map(
            (List) => List.lookupItemId || ''
          )
      );
      setPropertyid(
        response &&
          response &&
          response.policyJson.units.address.property.map(
            (List) => List.propertyId || ''
          )
      );

      setCityid(
        response &&
          response &&
          response.policyJson.units.address.city.map(
            (List) => List.lookupItemId || null
          )
      );
      setcommunityid(
        response &&
          response &&
          response.policyJson.units.address.community.map(
            (List) => List.lookupItemId || null
          )
      );
      setdistrictid(
        response &&
          response &&
          response.policyJson.units.address.district.map(
            (List) => List.lookupItemId || null
          )
      );

      if (
        (response &&
          response &&
          response.policyJson.units.unitStatus.available) == true
      ) {
        setAvailable('Available');
        setunitStatus('unitStatus');
      } else
        setAvailable('');

      if (
        (response && response && response.policyJson.units.unitStatus.draft) ==
        true
      ) {
        setDraft('Draft');
        setunitStatus('unitStatus');
      } else
        setDraft('');

      if (
        (response && response && response.policyJson.units.unitStatus.leased) ==
        true
      ) {
        setLeased('Leased');
        setunitStatus('unitStatus');
      } else
        setLeased('');

      if (
        (response &&
          response &&
          response.policyJson.units.unitStatus.pendingLeased) == true
      ) {
        setPendingLeased('Pending Leased');
        setunitStatus('unitStatus');
      } else
        setPendingLeased('');

      if (
        (response &&
          response &&
          response.policyJson.units.unitStatus.pendingReservedLease) == true
      ) {
        setPendingReservedLease('Pending Reserved Lease');
        setunitStatus('unitStatus');
      } else
        setPendingReservedLease('');

      if (
        (response &&
          response &&
          response.policyJson.units.unitStatus.pendingReservedSale) == true
      ) {
        setPendingReservedSale('Pending Reserved Sale');
        setunitStatus('unitStatus');
      } else
        setPendingReservedSale('');

      if (
        (response &&
          response &&
          response.policyJson.units.unitStatus.pendingSale) == true
      ) {
        setPendingSale('Pending Sale');
        setunitStatus('unitStatus');
      } else
        setPendingSale('');

      if (
        (response &&
          response &&
          response.policyJson.units.unitStatus.reservedLeased) == true
      ) {
        setReservedLeased('Reserved Leased');
        setunitStatus('unitStatus');
      } else
        setReservedLeased('');

      if (
        (response &&
          response &&
          response.policyJson.units.unitStatus.reservedSale) == true
      ) {
        setReservedSale('ReservedSale');
        setunitStatus('unitStatus');
      } else
        setReservedSale('');

      if (
        (response && response && response.policyJson.units.unitStatus.sale) ==
        true
      ) {
        setSale('Sale');
        setunitStatus('unitStatus');
      } else
        setSale('');
    } else
      setpolicyrener(null);
  }, [userid]);

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport('');
      showError('Contact Your Bi Admin For Help');
    }
  };

  useEffect(() => {
    getUserPolicyById();
    IdsForPolicy();
    getUserTemasById();
  }, []);

  useEffect(() => {
    if (
      (userForPolicy && userForPolicy.includes('MyUserCreatedBy')) ||
      (userForPolicy && userForPolicy.includes('specificUsersCreatedBy')) ||
      (userForPolicy && userForPolicy.includes('myTeamUsersCreatedBy')) ||
      (userForPolicy &&
        userForPolicy.includes('myBusinessGroupUsersCreatedBy')) ||
      (userForPolicy && userForPolicy.includes('myManageUsersCreatedBy')) ||
      (userForPolicy && userForPolicy.includes('myBranchUsersCreatedBy')) ||
      (userForPolicy && userForPolicy.includes('myTeamLeadUsersCreatedBy'))
    )
      setOperatorCreatedBy('In');
     else
      setOperatorCreatedBy('All');

    if (
      (userForPolicy && userForPolicy.includes('MyUserleaseListing')) ||
      (userForPolicy && userForPolicy.includes('specificUsersRentlisting')) ||
      (userForPolicy && userForPolicy.includes('myTeamUsersRentlisting')) ||
      (userForPolicy &&
        userForPolicy.includes('myBusinessGroupUsersRentlisting')) ||
      (userForPolicy && userForPolicy.includes('myManageUsersRentlisting')) ||
      (userForPolicy && userForPolicy.includes('myBranchUsersRentlisting')) ||
      (userForPolicy && userForPolicy.includes('myTeamLeadUsersRentlisting'))
    )
      setOperatorRentListing('In');
     else
      setOperatorRentListing('All');

    if (unitStatus == null)
      setLeadstatusOperator('All');
     else
      setLeadstatusOperator('In');
  }, [userForPolicy, unitStatus]);

  useEffect(() => {
    setTimeout(() => {
      Microsoft(false);
    }, 1500);
  }, []);

  return (
    <div className='dashboardMain-PowerBIEmbed'>
      {render && (
        <div>
          <div className='dashboardMain'>
            <SpinnerDashboards isActive={!ShowReport} isAbsolute />
            <PowerBIEmbed
              embedConfig={{
                type: 'report',
                id:
                  dynamicComponentProps &&
                  dynamicComponentProps.config &&
                  dynamicComponentProps.config.reportId,
                embedUrl:
                  dynamicComponentProps &&
                  dynamicComponentProps.config &&
                  dynamicComponentProps.config.Url,
                accessToken: report,
                filters: [
                  AddressIds,
                  FilteCreatedBy,
                  FilterStatus,
                  FilteListingAgent,
                ],

                settings: {
                  customLayout: {
                    displayOption: models.DisplayOption.FitToWidth,
                  },
                  filterPaneEnabled: false,
                  navContentPaneEnabled: false,
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                    bookmarks: {
                      visible: false,
                    },
                    pageNavigation: {
                      visible: false,
                    },
                    visualizations: {
                      expanded: false,
                    },
                    fields: {
                      expanded: false,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    'loaded',
                    () => {
                      setShowReport(true);

                      console.log('Report loaded');
                    },
                  ],
                  [
                    'rendered',
                    (event) => {
                      // const rendered = event.type;
                      // if(rendered){
                      // }
                      console.log('Report rendered');
                    },
                  ],
                  [
                    'error',
                    (event) => {
                      const error = event.detail;

                      if (
                        error.detailedMessage === 'Get report failed' ||
                        error.detailedMessage ===
                          'Access token has expired, resubmit with a new access token' ||
                        error.detailedMessage ===
                          'Fail to initialize - Could not resolve cluster'
                      ) {
                        Microsoft(true);
                        setRender(false);
                      } else console.log(error.detailedMessage);
                      showError(error.detailedMessage);
                    },
                  ],
                ])
              }
              cssClassName={
                ShowReport ?
                  'report-style-class-Show' :
                  'report-style-class-hide'
              }
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
