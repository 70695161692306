import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { useDispatch } from 'react-redux';
import {
  showError,
  GlobalHistory,
  getErrorByName,
  languageChange,
  GetParams,
  showSuccess,
} from '../../../Helper';
import { Inputs, SelectComponet, Spinner } from '../../../Components';
import defaultLogo from '../../../assets/images/Propx/Propx-Logo-Black.svg';
import { AdminChangePasswordWithoutVerification } from '../../../Services/userServices';
import logoPSI from '../../../assets/images/logoWithSlogen.png';
import logoPSIar from '../../../assets/images/logoWithSlogenAR.png';
import XelerationLogo from '../../../assets/images/Xeleration_logo.png';
import { LOGOUt } from '../../../store/login/Actions';
import { atLeastOneNumber, charactersLength, containUpper, containSpecialCharacter } from '../../../Utils/Expressions';


const translationPath = 'changePassWordView.';
const LoginChangePassword = () => {
  const { t } = useTranslation('HeaderView');
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Passwords, setPasswords] = useState({
    ViewNewassword: '',
    ViewconfirmPassword: ''
  });

  const schema = Joi.object({
    newPassword: Joi.string()
      .required()
      .regex(atLeastOneNumber).regex(containSpecialCharacter).regex(containUpper).regex(charactersLength)
      .messages({
        'string.empty': t(`${translationPath}password-is-required`),
        'string.pattern.base': t(`${translationPath}weak-password`),
      }),
    confirmPassword: Joi.string()
      .valid(Joi.ref('newPassword'))
      .required()
      .valid(Joi.ref('newPassword'))
      .messages({
        'any.only': t(`${translationPath}password-not-matched`),
        'string.empty': t(`${translationPath}confirm-password-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ newPassword, confirmPassword });


  const logOut = () => {
    localStorage.removeItem('session');
    dispatch(LOGOUt());
    setTimeout(() => {
      GlobalHistory.push('/account/login');
    }, 100);
  }

  const handleResetPassword = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (schema.error) {
      showError(t(`${translationPath}please-fix-all-errors`));
      setLoading(false);
      return;
    }
    const res = await AdminChangePasswordWithoutVerification({
      userId: GetParams('userId'),
      newPassword,
    });
    if (!(res && res.status && res.status !== 200)) {

      setIsSubmitted(true);
      localStorage.removeItem('session');
      dispatch(LOGOUt());
      setTimeout(() => {
        GlobalHistory.push('/account/login');
      }, 100);
      showSuccess(t(`${translationPath}change-password-Succssfuly`));

    } else showError(t(`${translationPath}failed-change-password`));

    setLoading(false);
  };

  return (
    <>
      <div className='login-wrapper'>
        <Spinner isActive={loading} />
        <div className='login-content-wrapper'>
          <div className='text-section-wrapper'>
            <div className='text-section-content'>
              <img src={((i18next.language === 'ar' && logoPSIar) || logoPSI)} className='logo-PSI' alt='logo-PSI' />
            </div>
          </div>
          <div className='box-section-wrapper'>
            <div className='box-content'>
              <div className='d-flex-v-center-h-between'>
                <div className='logo-wrapper'>
                  <img
                    src={defaultLogo}
                    className='logo'
                    alt={t(`${translationPath}company-logo`)}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    data={i18next.languages}
                    value={i18next.language}
                    onSelectChanged={languageChange}
                    themeClass='theme-underline'
                  />
                </div>
              </div>
              <form noValidate className='form-wrapper' onSubmit={handleResetPassword}>
                <div className='forget-password-text'>
                  <p className='new-password'>{t(`${translationPath}Change-password`)}</p>
                </div>
                <div className='View-new-password'>
                  <Inputs
                    helperText={getErrorByName(schema, 'newPassword').message}
                    error={getErrorByName(schema, 'newPassword').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    type={!Passwords.ViewNewassword ? 'password' : ''}
                    fieldClasses='inputs theme-underline mb-4'
                    label={t(`${translationPath}new-password`)}
                    name='Code'
                    idRef='newPassword'
                    wrapperClasses='theme-underline'
                    value={newPassword}
                    endAdornment={(
                      <Button
                        onClick={() => {
                          setPasswords({ ...Passwords, ViewNewassword: !Passwords.ViewNewassword });
                        }} className='p-2'>
                        {!Passwords.ViewNewassword ? <span className='mdi mdi-eye-outline' /> : <span className='mdi mdi-eye-off-outline' />}
                      </Button>
                    )}
                    onInputChanged={(e) => setNewPassword(e.target.value)}
                  />
                  <Inputs
                    helperText={getErrorByName(schema, 'confirmPassword').message}
                    error={getErrorByName(schema, 'confirmPassword').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    type={!Passwords.ViewconfirmPassword ? 'password' : ''}
                    fieldClasses='inputs theme-underline mb-4'
                    label={t(`${translationPath}confirmPassword`)}
                    name='Code'
                    idRef='confirmPassword'
                    wrapperClasses='theme-underline'
                    value={confirmPassword}
                    endAdornment={(
                      <Button onClick={() => {
                        setPasswords({ ...Passwords, ViewconfirmPassword: !Passwords.ViewconfirmPassword });
                      }} className='p-2'>
                        {Passwords.ViewconfirmPassword ? <span className='mdi mdi-eye-outline' /> : <span className='mdi mdi-eye-off-outline' />}
                      </Button>
                    )}
                    onInputChanged={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </div>
                <div className='password-validation  my-3'>
                  <span className={atLeastOneNumber.test(newPassword) ? 'mdi mdi-check my-2' : 'mdi mdi-information-outline my-2'} >{t(`${translationPath}one-number`)}</span>
                  <span className={charactersLength.test(newPassword) ? 'mdi mdi-check my-2 ' : 'mdi mdi-information-outline my-2'} > {t(`${translationPath}8-20-characters`)}</span>
                  <span className={containUpper.test(newPassword) ? 'mdi mdi-check my-2' : 'mdi mdi-information-outline my-2'} >{t(`${translationPath}one-uppercase-letter`)}</span>
                  <span className={containSpecialCharacter.test(newPassword) ? 'mdi mdi-check my-2' : 'mdi mdi-information-outline my-2'} >{t(`${translationPath}special-character(!@#$%^&*)`)}</span>

                </div>
                <div className='d-flex-v-center-h-between '>
                  <Button
                    onClick={logOut}
                    className='btns cancel-forget-passwrod'
                  >
                    {t(`${translationPath}cancel`)}
                  </Button>
                  <Button className='btns submit-forget-password' type='submit'>
                    {t(`${translationPath}login`)}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='light-shadow' />
      </div>
      <div className='powered-by'>
        <p className='textPoweredBy'>Powered by</p>
        <img alt='XelerationLogo' className='Xeleration-logo' src={XelerationLogo} />
      </div>
    </>
  );
};

export { LoginChangePassword };
