export const CampaignRequestsPermissions = {

  ViewCampaignRequests: {
    permissionsId: '737c04ee-3373-4e55-db08-08dbf16cf01d',
    permissionsName: 'View Campaign Requests',
    componentsId: null,
    components: null
  },
  
};
