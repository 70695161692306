import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalToasterGenerator, Middleware, SetGlobalRerender } from './Helper';
import { SwitchRoute } from './Components/Route/SwitchRoute';
import { AppRoutes } from './routes/AppRoutes/AppRoute';
import { preventSelectAll } from './Helper/PreventSelectAll.Helper';

const App = () => {
  const [render, setRender] = useState(false);
  preventSelectAll();
  SetGlobalRerender(setRender, render);
  return (
    <Router>
      <GlobalToasterGenerator />
      <Middleware />
      <SwitchRoute routes={AppRoutes} />
    </Router>
  );
};

export default App;
