import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent } from '../../../../../../Components';
import { GetLookupItemsByLookupTypeName } from '../../../../../../Services';

export const LeadQualificationComponent = ({
  parentTranslationPath,
  translationPath,
  helperText,
  setState,
  state,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [leadQualifications, setLeadQualifications] = useState([]);
  const [loadings, setloadings] = useState(false);
  const [selected, setSelected] = useState(null);

  const getLeadQualificationLookups = useCallback(async () => {
    setloadings(true);
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "LeadQualification",
      pageSize: 25,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setLeadQualifications(res?.result || []);
    } else  setLeadQualifications([]);
    setloadings(false);
  }, []);

  useEffect(() => {
    getLeadQualificationLookups();
  }, []);

    useEffect(() => {
      if(state && state.leadQualificationId){
        setSelected({
          lookupItemId: state.leadQualificationId,
          lookupItemName: state.leadQualificationName,
        });
      }
  }, [state]);

  return (
    <div>
      <AutocompleteComponent
        idRef='lead-qualificationRef'
        labelValue='lead-qualification'
        selectedValues={selected}
        multiple={false}
        data={leadQualifications || []}
        displayLabel={(option) => t(`${option.lookupItemName || ''}`)}
        getOptionSelected={(option) => option.lookupItemId === selected.lookupItemId}
        withoutSearchButton
        inputPlaceholder={t(`${translationPath}lead-qualification`)}
        isLoading={loadings}
        helperText={helperText}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setState({
            leadQualificationId: (newValue && +newValue.lookupItemId) || '',
            leadQualificationName: (newValue && newValue.lookupItemName) || '',
          });
          setSelected({
            lookupItemId: (newValue && +newValue.lookupItemId) || '',
            lookupItemName: (newValue && newValue.lookupItemName) || '',
          });
        }}
      />
    </div>
  );
};
LeadQualificationComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
};
