export const LeadActionsButtonsEnum = {
  add: {
    id: 'add',
    classes: 'bg-secondary',
    label: 'actions',
    buttonLabel: 'add',
  },
  'close-leads': {
    id: 'close-leads',
    classes: 'bg-secondary',
    label: 'close-leads',
    buttonLabel: 'close-leads',
  },
  'unqualified-leads': {
    id: 'unqualified-leads',
    classes: 'bg-secondary',
    label: 'unqualified-leads',
    buttonLabel: 'unqualified-leads',
  },
  'reassign-seeker-leads': {
    id: 'reassign-seeker-leads',
    classes: 'bg-secondary',
    label: 'reassign-seeker-leads',
    buttonLabel: 'reassign-seeker-leads',
  },
  'clone-leads': {
    id: 'clone-leads',
    classes: 'bg-secondary',
    label: 'clone-leads',
    buttonLabel: 'clone-leads',
  },
  merge: {
    id: 'merge',
    classes: 'bg-secondary',
    label: 'merge',
    buttonLabel: 'merge',
  },
  import: {
    id: 'import',
    classes: 'bg-secondary',
    label: 'import',
    buttonLabel: 'import',
  },
  'bulk-update': {
    id: 'bulk-update',
    classes: 'bg-secondary',
    label: 'bulk-update',
    buttonLabel: 'bulk-update',
  },
  'send-to-rotation': {
    id: 'send-to-rotation',
    classes: 'bg-secondary',
    label: 'send-to-rotation',
    buttonLabel: 'send-to-rotation',
  },
  'qualify-lead': {
    id: 'qualify-lead',
    classes: 'bg-secondary',
    label: 'qualify-lead',
    buttonLabel: 'qualify-lead',
  },
  'unqualified-lead': {
    id: 'unqualified-lead',
    classes: 'bg-secondary',
    label: 'unqualified-lead',
    buttonLabel: 'unqualified-lead',
  },
  'reassign-owner-leads': {
    id: 'reassign-owner-leads',
    classes: 'bg-secondary',
    label: 'reassign-owner-leads',
    buttonLabel: 'reassign-owner-leads',
  },
  'make-transaction' : {
    id: 'make-transaction',
    classes: 'bg-secondary',
    label: 'make-transaction',
    buttonLabel: 'make-transaction',
  },
};
