/* eslint-disable brace-style */
import React, {
  useEffect, useCallback, useState, useRef
} from 'react';
import { Button, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from '../../../../Hooks';
import { Inputs } from '../../../../Components/Controls';
import {
  NoDataFoundComponent,
  NoSearchResultComponent,
  PaginationComponent,
  Spinner,
  ViewTypes,
  PermissionsComponent,
  SelectComponet,
  PhonesComponent,
  AutocompleteComponent
} from '../../../../Components';
import {
  AdvanceSearchUser,
  UserCardsComponent,
  UserDeleteDialog,
  UserCancelDialog,
  UserListView,
  CanDeactiveUserDialog,
} from './UserUtilties';
import '.';
import { ViewTypesEnum, ActionsEnum, TableActions } from '../../../../Enums';
import { ActiveOrganizationUser, OrganizationUserSearch, CanDeActiveUser } from '../../../../Services/userServices';
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalHistory,
  bottomBoxComponentUpdate,
} from '../../../../Helper/Middleware.Helper';
import { showError, showSuccess, returnPropsByPermissions } from '../../../../Helper';
import { UserLoginPermissions } from '../../../../Permissions';
import { getIsAllowedPermission } from '../../../../Helper/Permissions.Helper';

import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { GetAllRoleTypes, GetAllRoles } from '../../../../Services';
import { GlobalOrderFilterActions } from '../../../../store/GlobalOrderFilter/GlobalOrderFilterActions';

const translationPath = 'Users.';

const UserView = () => {
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const dispatch = useDispatch();
  const [viewTypes, setViewTypes] = useState('');
  const [isLoading, setIsLoading] = useState({
    all: false,
    getAllRoleLoading: false,
    userType: false
  });
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [branchInput, setBranchInput] = useState('');
  const [operatingSystemInput, setOperatingSystemInput] = useState('');
  const [staffCodeValue, setStaffCodeValue] = useState('');
  const [userNameInput, setUserNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [status, setStatus] = useState();
  const [userTypeValue, setUserTypesValue] = useState();
  const [users, setUsers] = useState({ result: [], totalCount: 0 });
  const [activeUserItem, setActiveUserItem] = useState(null);
  const [usersTableFilter, setUsersTableFilter] = useState(null);
  const [allRoles, setAllRoles] = useState({
    result: [],
    totalCount: 0
  });
  const [searchRole, setSearchRole] = useState(null);
  const { t } = useTranslation('UsersView');
  useTitle(t('Users.users'));
  const [sortBy, setSortBy] = useState(null);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter && orderFilter.usersFilter && orderFilter.usersFilter.filterBy,
    orderBy: orderFilter && orderFilter.usersFilter && orderFilter.usersFilter.orderBy,
  });
  const [orderBy, setOrderBy] = useState(selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: 'CreatedOn', orderBy: 2 });
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    name: null,
    UserStatus: null,
    userName: null,
    phoneNumber: null,
    email: null,
    userTypeId: null,
    branch: null,
    filterBy: orderBy.filterBy,
    orderBy: orderBy.orderBy,
    searchedItem: null,
    rolesIds: []
  });
  const searchTimer = useRef(null);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenDeactive, setIsOpenDeactive] = useState(false);
  const [isOpenCanDeactive, setIsOpenCanDeactive] = useState(false);
  // const [isOpenRole, setIsOpenRole] = useState(false);
  // const [isOpenManagement, setIsOpenManagement] = useState(false);
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        usersFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      ...orderBy,
      filterBy: selectedOrderBy && selectedOrderBy.filterBy,
      orderBy: selectedOrderBy && selectedOrderBy.orderBy,
    });
    getUsers({
      ...orderBy,
filterBy: selectedOrderBy && selectedOrderBy.filterBy,
      orderBy: selectedOrderBy && selectedOrderBy.orderBy,
    });
  };

  const getUsers = useCallback(async (f) => {
    // setIsLoading(true);
    setIsLoading((loading) => ({ ...loading, all: true }));
    if (getIsAllowedPermission(
      Object.values(UserLoginPermissions),
      loginResponse,
      UserLoginPermissions.ViewUsers.permissionsId
    )) {
      const localFilterDto = f || filter || {};
      if (usersTableFilter) {
        Object.values(usersTableFilter)
          .map((item) => {
            if (localFilterDto[item.displayPath]) {
              // localFilterDto.push(localFilterDto[item.displayPath]);
              localFilterDto[item.displayPath] = item.value;
            }
            else
              localFilterDto[item.displayPath] = item.value;

            return undefined;
          });
      }

      const res = await OrganizationUserSearch({ ...filter, ...localFilterDto, pageIndex: filter.pageIndex + 1 });
      if (res && res.totalCount === 0) setisFirstLoad(false);

      if (filter.pageIndex === 0 || viewTypes === ViewTypesEnum.tableView.key) setUsers(res);
      else {
        setUsers({
          result: res.result,
          totalCount: res.totalCount,
        });
      }
    }
    // setIsLoading(false);
    setIsLoading((loading) => ({ ...loading, all: false }));
  }, [usersTableFilter, filter, loginResponse, viewTypes]);
  const itemsPerPageChanged = useCallback((event, newItemsPerPage) => {
    setFilter((items) => ({ ...items, pageIndex: 0, pageSize: newItemsPerPage.props.value }));
  }, []);
  const addNewUser = () => {
    GlobalHistory.push('/home/Users/add');
  };
  const activateUser = useCallback(
    async (id) => {
      // setIsLoading(true);
      setIsLoading((loading) => ({ ...loading, all: true }));
      const res = await ActiveOrganizationUser(id);
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t(`${translationPath}user-activated-successfully`));
        setFilter((item) => ({ ...item, pageIndex: 0 }));
        setActiveUserItem(null);
      } else showError(t(`${translationPath}user-activate-failed`));
      // setIsLoading(false);
      setIsLoading((loading) => ({ ...loading, all: false }));
    },
    [t]
  );

  // const DactivateUser = useCallback(
  //   async (id) => {
  //     setIsLoading(true);
  //     const res = await DeActivedUser(id);
  //     if (!(res && res.status && res.status !== 200)) {
  //       showSuccess(t(`${translationPath}user-Deactivated-successfully`));
  //       setFilter((item) => ({ ...item, pageIndex: 0 }));
  //       setActiveUserItem(null);
  //     } else showError(t(`${translationPath}user-Deactivate-failed`));
  //     setIsLoading(false);
  //   },
  // );

  const CanDactivateUser = useCallback(
    async (id) => {
      // setIsLoading(true);
      setIsLoading((loading) => ({ ...loading, all: true }));
      const res = await CanDactivateUser(id);
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t(`${translationPath}user-Deactivated-successfully`));
        setFilter((item) => ({ ...item, pageIndex: 0 }));
        setActiveUserItem(null);
      } else showError(t(`${translationPath}user-activate-failed`));
      // setIsLoading(false);
      setIsLoading((loading) => ({ ...loading, all: false }));
    },
    [t]
  );

  const onSearchChanged = useCallback((searchDto) => {
    setFilter(() => { });
    setFilter((items) => ({
      ...items,
      ...searchDto,
      pageIndex: 0,
      userTypeId: searchDto.userTypeId || null,
    }));
  }, []);

  // const advancedSearchClicked = useCallback(() => {
  //   sideMenuComponentUpdate(<AdvanceSearchUser onSearchChanged={onSearchChanged} />);
  //   sideMenuIsOpenUpdate(true);
  // }, [onSearchChanged]);

  const searchHandler = (input) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    if (input.target.id == 'usersNameSearchRef')
      { searchTimer.current = setTimeout(() => {
        setFilter((items) => ({ ...items, name: nameInput, pageIndex: 0 }));
      }, 700); }
    if (input.target.id == 'usersBranchSearchRef')
      { searchTimer.current = setTimeout(() => {
        setFilter((items) => ({ ...items, branch: branchInput, pageIndex: 0 }));
      }, 700); }
    if (input.target.id == 'staffCodeRef')
      { searchTimer.current = setTimeout(() => {
        setFilter((items) => ({ ...items, staffCode: staffCodeValue, pageIndex: 0 }));
      }, 700); }
    if (input.target.id == 'usersEmailSearchRef')
      { searchTimer.current = setTimeout(() => {
        setFilter((items) => ({ ...items, email: emailInput, pageIndex: 0 }));
      }, 700); }
    if (input.target.id == 'userNameSearchRef')
      { searchTimer.current = setTimeout(() => {
        setFilter((items) => ({ ...items, userName: userNameInput, pageIndex: 0 }));
      }, 700); }
    if (input.target.id == 'usersPhoneSearchRef')
      { searchTimer.current = setTimeout(() => {
        setIsSubmitted(true);
        setFilter((items) => ({ ...items, phoneNumber: phoneInput, pageIndex: 0 }));
      }, 1200); }
    if (input.target.id == 'mobileOperatingSystemNameRef')
      { searchTimer.current = setTimeout(() => {
        setIsSubmitted(true);
        setFilter((items) => ({ ...items, mobileOperatingSystemName: operatingSystemInput, pageIndex: 0 }));
      }, 1200); }
  };

  const getUserTypes = async () => {
    // setIsLoading(true)
    setIsLoading((loading) => ({ ...loading, userType: true }));
    const response = await GetAllRoleTypes();
    setUserTypes((response && response) || []);
    // setIsLoading(false);
    setIsLoading((loading) => ({ ...loading, userType: false }));
  };
  useEffect(() => {
    getUserTypes();
  }, []);

  const onTypeChanged = (activeType) => {
    localStorage.setItem('UserViewType', JSON.stringify(activeType));
    setViewTypes(activeType);
    setStaffCodeValue(null);
    setUsersTableFilter(null);
  };

  const actionClicked = useCallback(
    (actionEnum, item) => async (event) => {
      event.stopPropagation();
      setActiveUserItem(item);
      localStorage.setItem('activeUserItem', JSON.stringify(item));
      if (actionEnum === ActionsEnum.delete.key || actionEnum === TableActions.delete.key)
        setIsOpenConfirm(true);
      else if (actionEnum === ActionsEnum.edit.key || actionEnum === TableActions.edit.key) {
        dispatch(ActiveItemActions.activeItemRequest(item));
        GlobalHistory.push(`/home/Users/edit?id=${item.id}`);
      }
      // else if (actionEnum === ActionsEnum.add.key || actionEnum === TableActions.add.key)
      //   setIsOpenRole(true);
      else if (actionEnum === ActionsEnum.check.key || actionEnum === TableActions.check.key)
        activateUser(item.id);
      else if ((actionEnum === ActionsEnum.close.key || actionEnum === TableActions.close.key)) {
        // setIsLoading(true);
        setIsLoading((loading) => ({ ...loading, all: true }));
        const canActivate = await CanDeActiveUser(item.id);
        // setIsLoading(false);
        setIsLoading((loading) => ({ ...loading, all: false }));

        if (!(canActivate && canActivate.status && canActivate.status !== 200)) {
          if (canActivate)
            setIsOpenDeactive(true);
          else
            setIsOpenCanDeactive(true);
        }
      }
    },
    [activateUser]
  );
  const activePageChanged = useCallback((e, newPage) => {
    setFilter((item) => ({ ...item, pageIndex: newPage }));
  }, []);

  const getAllRoles = useCallback(async () => {
    // setIsLoading(true);
    setIsLoading((loading) => ({ ...loading, getAllRoleLoading: true }));

    const res = await GetAllRoles(filter.pageIndex, filter.pageSize, searchRole);
    if (!(res && res.status && res.status !== 200)) {
      setAllRoles({
        result: res.result,
        totalCount: res.totalCount
      });
    } else {
      setAllRoles({
        result: [],
        totalCount: 0
      });
    }
    setIsLoading((loading) => ({ ...loading, getAllRoleLoading: false }));

    // setIsLoading(false);
  }, [searchRole]);
  useEffect(() => {
    getAllRoles();
  }, [getAllRoles]);
  // const onLoadMoreHandler = useCallback(() => {
  //   setFilter((item) => ({ ...item, pageIndex: item.pageIndex + 1 }));
  // }, []);
  useEffect(() => {
    if (filter)
      getUsers(filter);
  }, [getUsers]);

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const onFilterValuesChanged = (newValue) => {
    if (newValue && Object.values(newValue).length) setUsersTableFilter(newValue);
    // setNameInput('');
    // setFilter((items) => ({ ...items, name: '',  branch: '', pageIndex: 0 }));
    // setBranchInput('');
  };
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  // useEffect(() => {
  //   const data = localStorage.getItem('UserViewType');
  //   if (data === null)
  //   {
  //     localStorage.setItem('UserViewType', JSON.stringify(ViewTypesEnum.cards.key));
  //     setViewTypes(ViewTypesEnum.cards.key);
  //   } else
  //     setViewTypes(JSON.parse(data));
  // }, [onTypeChanged]);

  useEffect(() => {
    if (getIsAllowedPermission(
      Object.values(UserLoginPermissions),
      loginResponse,
      UserLoginPermissions.ViewUsers.permissionsId
    )) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={users && users.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    if (sortBy)
      { if (sortBy.filterBy == 'UserStatus') {
        sortBy.filterBy = 'UserStatusId';
        setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
      } else
        setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy })); }
  }, [sortBy]);

  return (
    <>
      <div className='view-wrapper'>
        <Spinner isActive={isLoading.all} />
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section'>
              <PermissionsComponent
                permissionsList={Object.values(UserLoginPermissions)}
                permissionsId={UserLoginPermissions.AddNewUser.permissionsId}
              >
                <Button className='btns theme-solid' onClick={addNewUser}>
                  <span className='mdi mdi-plus' />
                  {t('Users.add-new-user')}
                </Button>
              </PermissionsComponent>
            </div>
            <div className='d-flex-v-center-h-end mb-3'>
              <PermissionsComponent
                permissionsList={Object.values(UserLoginPermissions)}
                permissionsId={UserLoginPermissions.ViewUsers.permissionsId}
              >
                <div className='d-flex-column mxw-60 field-wrapper'>
                  <span className='m-2'>{t('Users.filter-By')}</span>
                  <div className='d-flex'>
                    <div className='w-50 mr-1-reversed'>
                      <Inputs
                        idRef='usersNameSearchRef'
                        // variant='outlined'
                        fieldClasses='inputs theme-solid'
                        label={t('Users.filter-by-name')}
                        value={nameInput}
                        onInputChanged={(event) => setNameInput(event.target.value)}
                        onKeyUp={searchHandler}
                        inputPlaceholder={t('Users.search-users-by-name')}
                      />
                    </div>

                    <div className='w-50 mr-1-reversed'>
                      <Inputs
                        idRef='userNameSearchRef'
                        // variant='outlined'
                        fieldClasses='inputs theme-solid'
                        value={userNameInput}
                        onInputChanged={(event) => setUserNameInput(event.target.value)}
                        inputPlaceholder={t('Users.search-users-by-full-name')}
                        onKeyUp={searchHandler}
                        label={t('Users.filter-by-fullname')}
                      />
                    </div>
                    <div className='w-50 mr-1-reversed'>
                      <Inputs
                        idRef='usersBranchSearchRef'
                        // variant='outlined'
                        fieldClasses='inputs theme-solid'
                        label={t('Users.filter-by-branch')}
                        value={branchInput}
                        onInputChanged={(event) => setBranchInput(event.target.value)}
                        onKeyUp={searchHandler}
                        inputPlaceholder={t('Users.search-users-by-branch')}
                      />
                    </div>
                    <div className='w-50 mr-1-reversed'>
                      <Inputs
                        idRef='usersEmailSearchRef'
                        // variant='outlined'
                        fieldClasses='inputs theme-solid'
                        value={emailInput}
                        label={t('Users.filter-by-email')}
                        // isWithError
                        // helperText={getErrorByName(schema, 'email').message}
                        // error={getErrorByName(schema, 'email').error}
                        inputPlaceholder={t('Users.search-users-by-email')}
                        onInputChanged={(event) => setEmailInput(event.target.value)}
                        onKeyUp={searchHandler}
                      />
                    </div>
                    <div className=' w-50 mr-1-reversed '>
                      <Inputs
                        idRef='staffCodeRef'
                        style={{ fontSize: '14' }}
                        // variant='outlined'
                        fieldClasses='inputs theme-solid'
                        label={t('Users.StaffId')}
                        labelClasses='staffCode-label'
                        value={staffCodeValue}
                        onInputChanged={(event) => setStaffCodeValue(event.target.value)}
                        onKeyUp={searchHandler}
                      // inputPlaceholder={t('')}
                      />
                    </div>
                  </div>

                  <div className='d-flex '>
                    <div className='w-50 mr-1-reversed'>
                      <SelectComponet
                        data={[
                          { id: 1, status: t('Users.pending') },
                          { id: 2, status: t('Users.active') },
                          { id: 3, status: t('Users.deactive') },
                        ]}
                        value={status || 0}
                        emptyItem={{
                          value: 0,
                          text: t('Users.select-status'),
                          isDisabled: false,
                        }}
                        valueInput='id'
                        textInput='status'
                        onSelectChanged={(value) => {
                          setStatus(value);
                          return setFilter((items) => ({ ...items, UserStatusId: value, pageIndex: 0 }));
                        }}
                      />
                    </div>
                    {/* <div className='w-25 mr-1-reversed'>
                      <SelectComponet
                        data={userTypes}
                        emptyItem={{
                          value: 0,
                          text: t('Users.select-user-type'),
                          isDisabled: false,
                        }}
                        value={userTypeValue || 0}
                        valueInput='rolesId'
                        textInput='rolesName'
                        isLoading={isLoading.userType}
                        onSelectChanged={(value) => {
                          setUserTypesValue(value)
                          return setFilter((items) => ({ ...items, userTypeId: (value == 0 ? null : value), pageIndex: 0 }))
                        }}
                      />
                    </div> */}
                    <div className='mr-1-reversed'>
                      <AutocompleteComponent
                        idRef='rolesSearchRef'
                        multiple
                        data={allRoles.result || []}
                        chipsLabel={(option) => option.rolesName || ''}
                        displayLabel={(option) => option.rolesName || ''}
                        withoutSearchButton
                        isLoading={isLoading.getAllRoleLoading}
                        inputPlaceholder={t('Users.roles-filter')}
                        onInputChange={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current) clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            setSearchRole(value);
                          }, 700);
                        }}
                        onChange={(e, newValue) => {
                          const list = [];
                          newValue && newValue.map((element) => list.push(element.rolesId));
                          setFilter((f) => ({ ...f, rolesIds: list }));
                        }}
                      />
                    </div>
                    <div className='w-50 mr-1-reversed'>
                      <PhonesComponent
                        idRef='usersPhoneSearchRef'
                        value={phoneInput}
                        // helperText={getErrorByName(schema, 'phoneNumber').message}
                        // error={getErrorByName(schema, 'phoneNumber').error}
                        inputPlaceholder='Phone'
                        isSubmitted={isSubmitted}
                        onInputChanged={(event) => setPhoneInput(event)}
                        onKeyUp={searchHandler}
                      />
                    </div>
                    <div className=' w-50 mr-1-reversed '>
                      <Inputs
                        idRef='mobileOperatingSystemNameRef'
                        style={{ fontSize: '14' }}
                        fieldClasses='inputs theme-solid'
                        label={t('Users.mobile-operating-system')}
                        labelClasses='staffCode-label'
                        value={operatingSystemInput}
                        onInputChanged={(event) => setOperatingSystemInput(event.target.value)}
                        onKeyUp={searchHandler}
                      />
                    </div>
                  </div>
                </div>
              </PermissionsComponent>
              <PermissionsComponent
                permissionsList={Object.values(UserLoginPermissions)}
                permissionsId={UserLoginPermissions.ViewUsers.permissionsId}
              >
                <div>
                  <ViewTypes
                    onTypeChanged={onTypeChanged}
                    initialActiveType={viewTypes}
                    activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
                    className='mb-3'
                  />
                </div>
              </PermissionsComponent>
            </div>
          </div>
        </div>
        <PermissionsComponent
          permissionsList={Object.values(UserLoginPermissions)}
          permissionsId={UserLoginPermissions.ViewUsers.permissionsId}
        >
          <NoDataFoundComponent />
          {/* {users && users.totalCount === 0 && !isFirstLoad && <NoSearchResultComponent />} */}
          {/* {viewTypes !== ViewTypesEnum.tableView.key && ( */}
          <div className='d-flex px-2'>
            <span className='mx-2 mt-1'>{t('Users.Users')}</span>
            <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
            <span className='px-2 d-flex'>
              <span className='texts-large mt-1'>
                {t('Users.order-by')}
                :
              </span>
              <div className='px-2'>
                <SelectComponet
                  idRef='filterByRef'
                  data={[
                    { id: 'CreatedOn', filterBy: t('Users.created-on') },
                    { id: 'UpdateOn', filterBy: t('Users.last-updated') },
                  ]}
                  value={selectedOrderBy.filterBy}
                  onSelectChanged={filterByChanged}
                  wrapperClasses='mb-3'
                  isRequired
                  valueInput='id'
                  textInput='filterBy'
                  emptyItem={{
                    value: null,
                    text: t('Users.select-filter-by'),
                    isDisabled: false,
                  }}
                />
              </div>
              <div className='px-2'>
                <SelectComponet
                  idRef='orderByRef'
                  data={[
                    { id: 1, orderBy: t('Users.ascending') },
                    { id: 2, orderBy: t('Users.descending') },
                  ]}
                  value={selectedOrderBy.orderBy} //
                  onSelectChanged={orderByChanged}
                  wrapperClasses='mb-3'
                  isRequired
                  valueInput='id'
                  textInput='orderBy'
                  emptyItem={{
                    value: null,
                    text: t('Users.select-filter-by'),
                    isDisabled: false,
                  }}
                />
              </div>
              <div className='mt-1'>
                <ButtonBase
                  className='btns theme-solid'
                  onClick={orderBySubmitted}
                  disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                >
                  <span>{t('Users.apply')}</span>
                </ButtonBase>
              </div>
            </span>
          </div>
          {/* )} */}
        </PermissionsComponent>
        <PermissionsComponent
          permissionsList={Object.values(UserLoginPermissions)}
          permissionsId={UserLoginPermissions.ViewUsers.permissionsId}
        >
          {viewTypes === ViewTypesEnum.tableView.key && (
            <UserListView
              data={users}
              actionClicked={(actionEnum, item, focusedRow, event) =>
                actionClicked(actionEnum, item)(event)}
              activePageChanged={activePageChanged}
              itemsPerPageChanged={itemsPerPageChanged}
              filter={filter}
              setSortBy={setSortBy}
              onFilterValuesChanged={onFilterValuesChanged}
            />
          )}
        </PermissionsComponent>
        <PermissionsComponent
          permissionsList={Object.values(UserLoginPermissions)}
          permissionsId={UserLoginPermissions.ViewUsers.permissionsId}
        >

          {viewTypes !== ViewTypesEnum.tableView.key && (
            <UserCardsComponent
              data={users}
              // onLoadMore={onLoadMoreHandler}
              // isLoading={isLoading}
              actionClicked={actionClicked}
              translationPath={translationPath}
            />
          )}
        </PermissionsComponent>
      </div>
      {/* {isOpenManagement && (
        <UserManagementDialog
          activeUserItem={activeUserItem}
          isOpen={isOpenManagement}
          isOpenChanged={() => {
            setIsOpenManagement(false);
            setActiveUserItem(null);
          }}
          reloadData={() => {
            setFilter((item) => ({ ...item, pageIndex: 0 }));
            setActiveUserItem(null);
            setIsOpenManagement(false);
          }}
        />
      )} */}
      {activeUserItem && (
        <>
          {/* <AssignRoleDialog
            activeUserItem={activeUserItem}
            isOpen={isOpenRole}
            isOpenChanged={() => {
              setIsOpenRole(false);
              setActiveUserItem(null);
            }}
            reloadData={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setActiveUserItem(null);
              setIsOpenRole(false);
            }}
          /> */}
          <UserDeleteDialog
            activeUserItem={activeUserItem}
            isOpen={isOpenConfirm}
            isOpenChanged={() => {
              setIsOpenConfirm(false);
              setActiveUserItem(null);
            }}
            reloadData={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setActiveUserItem(null);
              setIsOpenConfirm(false);
            }}
          />
          <UserCancelDialog
            activeUserItem={activeUserItem}
            isOpen={isOpenDeactive}
            isOpenChanged={() => {
              setIsOpenDeactive(false);
              setActiveUserItem(null);
            }}
            reloadData={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setActiveUserItem(null);
              setIsOpenDeactive(false);
            }}
          />
          {
            isOpenCanDeactive && (
              <CanDeactiveUserDialog
                activeUserItem={activeUserItem}
                isOpen={isOpenCanDeactive}
                isOpenChanged={() => {
                  setActiveUserItem(null);
                  setIsOpenCanDeactive(false);
                }}
                reloadData={() => {
                  setFilter((item) => ({ ...item, pageIndex: 0 }));
                  setActiveUserItem(null);
                  setIsOpenCanDeactive(false);
                }}
              />

            )
          }

        </>
      )}
    </>
  );
};
export { UserView };
