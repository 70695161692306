import React, { useState, useCallback, useReducer, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { ButtonBase, DialogActions } from '@material-ui/core';
import { config } from '../../../../../../config';
import {
  DialogComponent, Spinner, SelectComponet, AutocompleteComponent,
  CheckboxesComponent
} from '../../../../../../Components';
import { ApplicationUserSearch } from '../../../../../../Services/userServices';
import { GetAdvanceSearchContacts, GetLeads, SendTemplateByEmail } from '../../../../../../Services';
import { showError, showSuccess } from '../../../../../../Helper';
import {
  ContactsMapper,
} from '../../../../../../Views/Home/ContactsView/ContactsUtilities/ContactsMapper';

export const ShareTemplateDialog = ({
  templateId,
  unitId,
  unitOwner,
  leaseUnitOwner,
  isOpen,
  closeHandler,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const pathName = window.location.pathname.split('/home/')[1].split('/')[0];
  const isSaleModule = (pathName === 'lead-owner-units-sale') || (pathName === 'units-sales');
  const isLeaseModule = (pathName === 'lead-owner-units-lease') || (pathName === 'units-lease');

  const TemplateSharingMethods = [
    {
      key: 1,
      value: "Email"
    }
  ]
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isUnitOwnerChecked, setIsUnitOwnerChecked] = useState(false);
  const [unitOwnerId, setUnitOwnerId] = useState(null);
  const [sharingMethod, setSharingMethod] = useState(null);
  const [loading, setloading] = useState(false);
  const [state, setState] = useReducer(reducer, {
    systemUsersId: [],
    contactsId: [],
  });
  const [selected, setSelected] = useReducer(reducer, {
    systemUsersId: [],
    contactsId: [],
  });

  const [isLoading, setIsLoading] = useReducer(reducer, {
    sending: false,
    users: false,
    contacts: false,
  });

  const [data, setData] = useReducer(reducer, {
    users: [],
    contacts: [],
  });

  const schema = Joi.object({
    contactsId: Joi.required()
      .custom((value, helpers) => {
        if (
          state.contactsId &&
          state.contactsId.length === 0 &&
          state.systemUsersId &&
          state.systemUsersId.length === 0 &&
          !isUnitOwnerChecked
        )
          return helpers.error('send-to-error-message-value');
        return value;
      })
      .messages({
        'send-to-error-message-value': t(`${translationPath}send-to-is-required`),
      }),
    systemUsersId: Joi.required()
      .custom((value, helpers) => {
        if (
          state.contactsId &&
          state.contactsId.length === 0 &&
          state.systemUsersId &&
          state.systemUsersId.length === 0 &&
          !isUnitOwnerChecked
        )
          return helpers.error('send-to-error-message-value');
        return value;
      })
      .messages({
        'send-to-error-message-value': t(`${translationPath}send-to-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const sharingMethodSchema = Joi.number()
    .required()
    .messages({
      'number.base': t(`${translationPath}sharing-method-is-required`),
    })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(sharingMethod);

  const getAllUsers = useCallback(
    async (searchValue) => {
      setIsLoading({ id: 'users', value: true });
      const res = await ApplicationUserSearch({
        pageIndex: 0,
        pageSize: 25,
        name: searchValue || '',
      });
      if (!(res && res.status && res.status !== 200)) {
        const localValue = (res && res.result) || [];
        if (localValue.length > 0) {
          setData({
            id: 'users',
            value: localValue,
          });
        } else {
          setData({
            id: 'users',
            value: [],
          });
        }

        setIsLoading({ id: 'users', value: false });
      }
    },
    []
  );

  const getAllRelatedLeads = useCallback(
    async (value) => {

      setloading(true);
      const response = await GetLeads({
        pageSize: 25,
        pageIndex: 0,
        search: value
      });
      if (!(response && response.status && response.status !== 200))
        setData({ id: 'contacts', value: (response && response.result) || [] });

      else setData({ id: 'contacts', value: [] });
      setloading(false);

    }, []
  );




  const getAllContacts = useCallback(
    async (searchValue) => {

      var emailValidRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      let body = {
        criteria: {},
        filterBy: "createdOn",
      }
      if (searchValue && searchValue.match(emailValidRegex)) {
        body = {
          ...body,
          criteria: {
            Email: [{ searchType: 2, value: searchValue }]
          },
          filterBy: "createdOn",
        }

      }
      else if (searchValue && !isNaN(searchValue)) {
        body = {
          ...body,
          criteria: {
            Ids: [{ searchType: 2, value: searchValue }]
          },
          filterBy: "createdOn",
        }
      }
      else if (searchValue) {
        body = {
          ...body,
          criteria: {
            FullName: [{
              searchType: 2,
              value: searchValue
            }]
          },
        }
      }
      setloading(true);
      const res = await GetAdvanceSearchContacts({
        pageIndex: 0,
        pageSize: 25,
      }, body);
      if (!(res && res.status && res.status !== 200)) {
        setData({
          id: 'contacts',
          value: ((res && res.result) || []).map((item) => item.contactJson && ContactsMapper(item, JSON.parse(item.contactJson).contact)),
        });
      } else {
        setData({
          id: 'contacts',
          value: [],
        });
      }
      setloading(false);
    }, []);

  const getUnitOwnerName = () => {

    if (isSaleModule && unitOwner) return unitOwner.name || '';
    else if (isLeaseModule && leaseUnitOwner) return leaseUnitOwner.name || '';
    else return '';
  }


  const sendTemplateByEmail = useCallback(async () => {
    setIsLoading({ id: 'sending', value: true });

    let contactsIDsLocal = state.contactsId ? [...state.contactsId] : [];
    if (isUnitOwnerChecked) contactsIDsLocal = [...contactsIDsLocal, unitOwnerId];

    const body = {
      unitId: unitId,
      systemTemplateId: templateId,
      systemUsersId: state.systemUsersId,
      leadsId: contactsIDsLocal,
      serverName: config.server_name,
      apiKey: config.SendKey,
    };
    const response = await SendTemplateByEmail(body);
    if (response === true) {
      showSuccess(t('template-email-sent-successfully'));
      closeHandler();
    } else {
      showError(t('template-email-sent-failed'));
    }

    setIsLoading({ id: 'sending', value: false });
  }, [unitOwnerId, templateId, unitId, state.contactsId,
    state.systemUsersId, isUnitOwnerChecked]);


  const saveHandler = async () => {

    if (sharingMethodSchema?.error?.message) {
      showError(sharingMethodSchema.error.message);
      return;
    } else if (schema?.error?.message) {
      showError(schema.error.message);
      return;
    }

    if (sharingMethod == 1) sendTemplateByEmail();
  };



  useEffect(() => {
    getAllUsers();
    getAllRelatedLeads();
  }, []);

  useEffect(() => {

    if (isSaleModule && unitOwner) setUnitOwnerId(unitOwner.id)
    else if (isLeaseModule && leaseUnitOwner) setUnitOwnerId(leaseUnitOwner.id)

  }, [unitOwner, leaseUnitOwner])


  return (
    <DialogComponent
      titleText='share-template'
      saveText='send'
      maxWidth='lg'
      dialogContent={(
        <div className='share-template-dialog'>
          <Spinner isActive={isLoading.sending} isAbsolute />
          <div>
            <SelectComponet
              idRef={`send-viaIdRef`}
              labelValue='send-via'
              data={TemplateSharingMethods || []}
              value={sharingMethod}
              valueInput='key'
              textInput='value'
              onSelectChanged={(value) => {
                const sharingMethod = +value || null;
                setSharingMethod(sharingMethod);
              }}
              translationPathForData={translationPath}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              emptyItem={{
                value: null,
                text: 'choose-sharing-method',
                isDisabled: false,
              }}
            />
          </div>
          <fieldset className='send-to-fieldset my-3'>
            <legend className='send-to-legend'>{t(`${translationPath}send-to`)}</legend>
            <div className='my-2'>
              <AutocompleteComponent
                idRef='systemContactIdRef'
                labelValue='lead'
                isLoading={loading}
                selectedValues={selected.contactsId}
                multiple={true}
                data={data && data.contacts || []}
                displayLabel={(option) =>
                  (option.lead && option.lead.contact_name && option.lead.contact_name.name + `  ( ${option.leadId} )` || '') ||
                  (option.lead && option.lead.company_name) + `  ( ${option.leadId} )` ||
                  ''}
                chipsLabel={(option) =>
                  (option && option.lead && option.lead.contact_name && option.lead.contact_name.name + `  ( ${option.leadId} )` || '') ||
                  (option && option.lead && option.lead.company_name) + `  ( ${option.leadId} )` ||
                  ''}
                renderOption={(option) =>
                  (option && option.lead && option.lead.contact_name && option.lead.contact_name.name + `  ( ${option.leadId} )` || '') ||
                  (option && option.lead && option.lead.company_name) + `  ( ${option.leadId} )` ||
                  ''}
                getOptionSelected={(option) =>
                  selected.contactsId.findIndex((item) => item.leadId === option.leadId) !== -1 || false}
                withoutSearchButton
                onKeyDown={() => {
                  setSelected({ id: 'contactsId', value: null });
                }}
                onInputKeyUp={(e) => {
                  const searchText = e?.target?.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllRelatedLeads(searchText);
                  }, 700);
                }}
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const contactIds = newValue && newValue.map(item => item?.leadId) || [];
                  setState({
                    id: 'contactsId',
                    value: contactIds,
                  });
                  setSelected({ id: 'contactsId', value: newValue });

                }}
              />
            </div>
            <div className='my-2'>
              <AutocompleteComponent
                idRef='systemUsersIdRef'
                labelValue='system-users'
                selectedValues={selected.systemUsersId}
                multiple={true}
                data={data.users || []}
                displayLabel={(option) => option.fullName || ''}
                chipsLabel={(option) => option.fullName || ''}
                renderOption={(option) =>
                  ((option.userName || option.fullName) &&
                    `${option.fullName} (${option.userName})`) ||
                  ''
                }
                getOptionSelected={(option) =>
                  selected.systemUsersId.findIndex((item) => item.usersId === option.usersId) !== -1 || false}
                withoutSearchButton
                isLoading={isLoading.users}
                onKeyDown={() => {
                  setSelected({ id: 'systemUsersId', value: null });
                }}
                onInputKeyUp={(e) => {
                  const searchText = e?.target?.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllUsers(searchText);
                  }, 700);
                }}
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const systemUsersIds = newValue && newValue.map(item => item?.usersId) || [];
                  setState({
                    id: 'systemUsersId',
                    value: systemUsersIds,
                  });
                  setSelected({ id: 'systemUsersId', value: newValue });
                }}
              />
            </div>
            {(unitOwner || leaseUnitOwner) &&
              <CheckboxesComponent idRef="unit-owner-IdRef"
                label={`${t(`${translationPath}send-to-unit-owner`)} (${getUnitOwnerName()})`}
                themeClass="theme-secondary"
                singleChecked={isUnitOwnerChecked}
                onSelectedCheckboxChanged={() => {
                  setIsUnitOwnerChecked(!isUnitOwnerChecked);
                }}
              />}
          </fieldset>


          <DialogActions>
            <div className='unit-template-action-btns'>
              <ButtonBase className='MuiButtonBase-root btns bg-cancel'
                onClick={() => closeHandler()}>
                {t(`${translationPath}cancel`)}
              </ButtonBase>
              <ButtonBase className='MuiButtonBase-root btns theme-solid '
                onClick={() => saveHandler()}>
                {t(`${translationPath}send`)}
                <span className='mdi mdi-send ml-2'></span>
              </ButtonBase>
            </div>
          </DialogActions>

        </div>
      )}
      isOpen={isOpen}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
