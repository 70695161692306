import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import moment from 'moment';
import { Button } from '@material-ui/core';
import {
  getErrorByName,
  showError,
  showSuccess,
  bottomBoxComponentUpdate,
} from '../../../../../Helper';
import { GlobalHistory } from '../../../../../Helper/Middleware.Helper';
import {
  CheckExistEmail,
  CheckExistPhone,
  CheckExistUserName,
  CreateOrganizationUser,
  lookupItemsGetId,
  OrganizationUserSearch,
  GetlookupTypeItems,
} from '../../../../../Services';
import { GetAllBranches } from '../../../../../Services/userServices';
import {
  emailExpression,
  mediumStringRegex,
  phoneExpression,
  strongStringRegex,
  atLeastOneNumber, charactersLength, containUpper, containSpecialCharacter
} from '../../../../../Utils/Expressions';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import {
  AutocompleteComponent,
  Inputs,
  PhonesComponent,
  ProgressComponet,
  DatePickerComponent,
} from '../../../../../Components/Controls';
import {
  RadiosGroupComponent,
  Spinner,
} from '../../../../../Components';
import {
  CityTypeIdEnum,
  CommunityTypeIdEnum,
  DistrictTypeIdEnum,
  SubCommunityTypeIdEnum,
} from '../../../../../Enums';
import { useTitle } from '../../../../../Hooks';

const translationPath = 'UsersManagementView.';

export const AddUserView = () => {
  const { t } = useTranslation('UsersView');
  useTitle(t(`${translationPath}add-user`));
  const typingTimer = useRef(null);

  const reducer = (select, action) => {
    if (action.id !== 'edit') return { ...select, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  };

  const initialState = {
    firstName: '',
    secondName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    profileImg: null,
    whatsAppNumber: '',
    nationality: null,
    dateOfBirth: null,
    dateOfJoining: null,
    registrationNo: null,
    jobTitleId: null,
    branchId: null,
    reportsToId: null,
    isDefaultBranchUser: null,
    staffCode: null,
    languages: [],
    targetMin: null,
    targetMax: null,
    aboutMe: '',
    totalYearsOfExperience: null,
    userAreaOfFocusSubCommunities: [],
  };
  const [state, setState] = useReducer(reducer, initialState);
  const [selected, setSelected] = useReducer(reducer, {
    reportsTo: null,
    branch: null,
    jobTitle: null,
  });
  const [data, setData] = useReducer(reducer, {
    users: [],
    branches: [],
    jobTitles: [],
    languages: [],
    countries: [],
    cities: [],
    districts: [],
    communities: [],
    subCommunities: [],
  });
  const [selectedAdress, setSelectedAdress] = useReducer(reducer, {
    country: [],
    city: [],
    district: [],
    community: [],
    subCommunity: [],
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExist, setIsExist] = useState({
    userName: null,
    email: null,
    phoneNumber: null,
  });

  const schema = Joi.object({
    firstName: Joi.string()
      .regex(/^.*\S*.*$/)
      .trim()
      .required()
      .messages({
        'string.empty': t(`${translationPath}first-name-is-required`),
      }),

    lastName: Joi.string()
      .regex(/^.*\S*.*$/)
      .trim()
      .required()
      .messages({
        'string.empty': t(`${translationPath}last-name-is-required`),
      }),
    targetMin:
      (state.targetMax &&
        Joi.number()
          .required()
          .messages({
            'number.base': t(`${translationPath}targetMin-is-required`),
            'number.empty': t(`${translationPath}targetMin-is-required`),
          })) ||
      Joi.any(),
    userName: Joi.string()
      .regex(/^.*\S*.*$/)
      .trim()
      .required()
      .custom(
        (value, helpers) => (isExist?.userName && helpers.error('any.invalid')) || value,
        `${translationPath}username-is-already-exist`
      )
      .messages({
        'any.invalid': t(`${translationPath}username-is-already-exist`),
        'string.empty': t(`${translationPath}username-is-required`),
      }),
    email: Joi.string()
      .required()
      .regex(emailExpression)
      .custom(
        (value, helpers) => (isExist?.email && helpers.error('any.invalid')) || value,
        t(`${translationPath}email-is-already-exist`)
      )
      .messages({
        'any.invalid': t(`${translationPath}email-is-already-exist`),
        'string.empty': t(`${translationPath}email-is-required`),
        'string.pattern.base': t(`${translationPath}invalid-email`),
      }),
    password: Joi.string()
      .required()
      .regex(atLeastOneNumber).regex(containSpecialCharacter).regex(containUpper).regex(charactersLength)
      .messages({
        'string.empty': t(`${translationPath}password-is-required`),
        'string.pattern.base': t(`${translationPath}weak-password`),
      }),

    confirmPassword:
      Joi.string()
        .required()
        .valid(Joi.ref('password'))
        .messages({
          'any.only': t(`${translationPath}password-not-matched`),
          'string.empty': t(`${translationPath}confirm-password-is-required`),
        }) ||
      Joi.any(),


    phoneNumber: Joi.string()
      .required()
      .regex(phoneExpression)
      .custom(
        (value, helpers) => {
          return (isExist?.phoneNumber && helpers.error('any.invalid')) || value
        },
        t(`${translationPath}mobile-number-is-already-exist`)
      )
      .messages({
        'any.invalid': t(`${translationPath}mobile-number-is-already-exist`),
        'string.empty': t(`${translationPath}mobile-number-is-required`),
        'string.pattern.base': t(`${translationPath}invalid-mobile-number`),
      }),
    whatsAppNumber: Joi.string()
      .required()
      .regex(phoneExpression)
      .messages({
        'string.empty': t(`${translationPath}whatsapp-number-is-required`),
        'string.pattern.base': t(`${translationPath}invalid-whatsapp-number`),
      }),
    nationality: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}nationality-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getCountires = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Country,
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'countries', value: res || [] });
    else setData({ id: 'countries', value: [] });
  };

  const getAllCities = async (searchItem) => {
    const res = await GetlookupTypeItems({
      lookupTypeId: CityTypeIdEnum.lookupTypeId,
      pageIndex: 0,
      pageSize: 10,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'cities', value: res?.result || [] });
    else setData({ id: 'cities', value: [] });
  };

  const getAllDistricts = async (searchItem) => {
    const res = await GetlookupTypeItems({
      lookupTypeId: DistrictTypeIdEnum.lookupTypeId,
      pageIndex: 0,
      pageSize: 10,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'districts', value: res?.result || [] });
    else setData({ id: 'districts', value: [] });
  };

  const getAllCommunities = async (searchItem) => {
    const res = await GetlookupTypeItems({
      lookupTypeId: CommunityTypeIdEnum.lookupTypeId,
      pageIndex: 0,
      pageSize: 10,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'communities', value: res?.result || [] });
    else setData({ id: 'communities', value: [] });
  };

  const getAllSubCommunities = async (searchItem) => {
    const res = await GetlookupTypeItems({
      lookupTypeId: SubCommunityTypeIdEnum.lookupTypeId,
      pageIndex: 0,
      pageSize: 10,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'subCommunities', value: res?.result || [] });
    else setData({ id: 'subCommunities', value: [] });
  };

  const getJobTitle = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.JobTitle,
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'jobTitles', value: res || [] });
    else setData({ id: 'jobTitles', value: [] });
  };
  const getLanguages = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Languages,
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'languages', value: res || [] });
    else setData({ id: 'languages', value: [] });
  };
  const getAllUsers = async (name) => {
    const res = await OrganizationUserSearch({
      name: name || '',
      pageIndex: 0,
      pageSize: 10,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'users', value: res?.result || [] });
    } else setData({ id: 'users', value: [] });
  };

  const getBranches = async (branchName) => {
    const res = await GetAllBranches({ pageSize: 25, pageIndex: 1, branchName });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'branches', value: res.result || [] });
    } else setData({ id: 'branches', value: [] });
  };

  const getIsExistUserName = async (userName) => {
    if (userName) {
      const response = await CheckExistUserName(userName);
      setIsExist({
        ...isExist,
        userName: response?.isExist || false,
      });
    }
  };

  const getIsExistPhoneNumber = async (phoneNumber) => {
    if (phoneNumber && phoneNumber.match(phoneExpression)) {
      const response = await CheckExistPhone(
        (!phoneNumber.startsWith('+') && `+${phoneNumber}`) || phoneNumber
      );
      setIsExist({
        ...isExist,
        phoneNumber: response?.isExist || false,
      });
    }
  };

  const getIsExistEmail = async (email) => {
    if (email && email.match(emailExpression)) {
      const response = await CheckExistEmail(email);
      setIsExist({
        ...isExist,
        email: response?.isExist || false,
      });
    }
  };

  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError(t(`${translationPath}shared.please-fix-all-errors`));
      return;
    }
    setIsLoading(true);
    const languages = [];
    state.languages.map((e) => {
      languages.push({
        languageId: e.lookupItemId || e.lookupsId,
      });
    });
    const saveDto = {
      ...state,
      phoneNumber:
        (state.phoneNumber && !state.phoneNumber.startsWith('+') && `+${state.phoneNumber}`) ||
        state.phoneNumber ||
        '',
      whatsAppNumber:
        (state.whatsAppNumber &&
          !state.whatsAppNumber.startsWith('+') &&
          `+${state.whatsAppNumber}`) ||
        (state.whatsAppNumber &&
          state.whatsAppNumber.match(phoneExpression) &&
          state.whatsAppNumber) ||
        '',
      nationalityId: state.nationality.lookupItemId,
      reportsTo: (state.reportsToId && selected.reportsTo && selected.reportsTo.fullName) || null,
      jobTitle: (state.jobTitleId && selected.jobTitle && selected.jobTitle.lookupItemName) || null,
      userLanguages: languages || null,
      branch: (state.branchId && selected.branch && selected.branch.branchName) || null,
      isDefaultBranchUser: state.isDefaultBranchUser == 1,
      staffCode: state.staffCode,
    };
    const res = await CreateOrganizationUser(saveDto);
    setIsLoading(false);
    if (res && !(res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}user-created-successfully`));
      GlobalHistory.push('/home/Users');
    } else showError(t(`${translationPath}user-create-failed`));
  };

  const [Passwords, setPasswords] = useState({
    ViewNewassword: '',
    ViewconfirmPassword: ''
  });

  const onClickednew = () => {
    setPasswords({ ...Passwords, ViewNewassword: !Passwords.ViewNewassword });
  };
  const onClickedViewconfirmPassword = () => {
    setPasswords({ ...Passwords, ViewconfirmPassword: !Passwords.ViewconfirmPassword });
  };

  useEffect(() => () => typingTimer.current ? clearTimeout(typingTimer.current) : {}, []);

  useEffect(() => {
    bottomBoxComponentUpdate(null);
  }, []);

  return (
    <div className='user-management-view view-wrapper'>
      <Spinner isActive={isLoading} />
      <form noValidate onSubmit={saveHandler} className='management-form-content'>
        <div className='view-management-header mb-2 px-2'>
          <Button type='submit' className='btns theme-solid mx-2 mb-2'>
            <span className='mdi mdi-plus   mx-1 ' />
            <span className='px-3'>{t(`${translationPath}add-new-user`)}</span>
          </Button>
          <Button
            className='btns theme-solid bg-cancel mb-2 mx-2'
            onClick={() => {
              GlobalHistory.push('/home/Users');
            }}>
            <span> {t(`${translationPath}cancel`)}</span>
          </Button>
        </div>
        <div className='body-content'>
          <div className='container'>
            <div className='information-section'>
              <div className='information-box-wrapper'>
                <div className='information-section-content'>
                  <div className='information-content-wrapper'>
                    <div className='form-item'>
                      <Inputs
                        inputPlaceholder='InputsFirstName'
                        labelValue='FirstName'
                        idRef='firstNameRef'
                        isWithError
                        parentTranslationPath='UsersView'
                        translationPath={translationPath}
                        isSubmitted={isSubmitted}
                        helperText={getErrorByName(schema, 'firstName').message}
                        error={getErrorByName(schema, 'firstName').error}
                        onKeyUp={(event) => {
                          const firstName = event?.target?.value;
                          if (typingTimer.current) clearTimeout(typingTimer.current);
                          typingTimer.current = setTimeout(() => {
                            setState({ id: 'firstName', value: firstName });
                          }, 500);
                        }}
                      />
                    </div>
                    <div className='form-item'>
                      <Inputs
                        inputPlaceholder='InputsSecondName'
                        labelValue='SecondName'
                        idRef='secondNameRef'
                        parentTranslationPath='UsersView'
                        translationPath={translationPath}
                        isWithError
                        isSubmitted={isSubmitted}
                        helperText={getErrorByName(schema, 'secondName').message}
                        error={getErrorByName(schema, 'secondName').error}
                        onKeyUp={(event) => {
                          const secondName = event?.target?.value;
                          if (typingTimer.current) clearTimeout(typingTimer.current);
                          typingTimer.current = setTimeout(() => {
                            setState({ id: 'secondName', value: secondName });
                          }, 500);
                        }}
                      />
                    </div>
                    <div className='form-item'>
                      <Inputs
                        inputPlaceholder='InputslastName'
                        labelValue='LastName'
                        idRef='lastNameRef'
                        isWithError
                        parentTranslationPath='UsersView'
                        translationPath={translationPath}
                        isSubmitted={isSubmitted}
                        helperText={getErrorByName(schema, 'lastName').message}
                        error={getErrorByName(schema, 'lastName').error}
                        onKeyUp={(event) => {
                          const lastName = event?.target?.value;
                          if (typingTimer.current) clearTimeout(typingTimer.current);
                          typingTimer.current = setTimeout(() => {
                            setState({ id: 'lastName', value: lastName });
                          }, 500);
                        }}
                      />
                    </div>
                    <div className='form-item'>
                      <AutocompleteComponent
                        idRef='nationalityRef'
                        labelValue={t(`${translationPath}nationality-req`)}
                        inputPlaceholder={t(`${translationPath}InputsNationality`)}
                        value={state.nationality}
                        data={data.countries || []}
                        chipsLabel={(option) =>
                          (option.lookupItemName && option.lookupItemName) || ''
                        }
                        displayLabel={(option) =>
                          (option.lookupItemName && option.lookupItemName) || ''
                        }
                        multiple={false}
                        withoutSearchButton
                        helperText={getErrorByName(schema, 'nationality').message}
                        error={getErrorByName(schema, 'nationality').error}
                        isWithError
                        isSubmitted={isSubmitted}
                        onOpen={() => {
                          if (data.countries && data.countries.length == 0) getCountires()
                        }}
                        onChange={(event, newValue) => {
                          setState({ id: 'nationality', value: newValue });
                        }}
                      />
                    </div>
                    <div className='form-item'>
                      <AutocompleteComponent
                        idRef='branchRef'
                        labelValue={t(`${translationPath}branch-req`)}
                        inputPlaceholder={t(`${translationPath}InputsUserBranc`)}
                        value={selected.branch}
                        data={data.branches || []}
                        chipsLabel={(option) => (option.branchName && option.branchName) || ''}
                        displayLabel={(option) => (option.branchName && option.branchName) || ''}
                        multiple={false}
                        withoutSearchButton
                        isWithError
                        isSubmitted={isSubmitted}
                        onOpen={() => {
                          if (data.branches && data.branches.length == 0) getBranches()
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (typingTimer.current) clearTimeout(typingTimer.current);
                          typingTimer.current = setTimeout(() => {
                            getBranches(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          setState({
                            id: 'branchId',
                            value: (newValue && newValue.branchId) || null,
                          });
                          setState({
                            id: 'isDefaultBranchUser',
                            value: null,
                          });
                          setSelected({ id: 'branch', value: newValue });
                        }}
                      />
                    </div>
                    <div className='form-item'>
                      <RadiosGroupComponent
                        idRef='DefaultBranchUser'
                        data={[
                          {
                            key: 1,
                            label: 'Yes',
                            value: true,
                          },
                          {
                            key: 2,
                            label: 'No',
                            value: false,
                          },
                        ]}
                        onSelectedRadioChanged={(event) =>
                          setState({
                            id: 'isDefaultBranchUser',
                            value: +event.target.value || null,
                          })
                        }
                        value={state.branchId && state.isDefaultBranchUser}
                        labelValue={t(`${translationPath}is-default-branch-user`)}
                        labelInput='label'
                        valueInput='key'
                        themeClass='theme-line'
                        isDisabled={!state.branchId}
                      />
                    </div>
                    <div className='form-item'>
                      <AutocompleteComponent
                        idRef='jobTitleRef'
                        labelValue={t(`${translationPath}jobTitle-req`)}
                        inputPlaceholder={t(`${translationPath}InputsJobTitle`)}
                        value={selected.jobTitle}
                        data={data.jobTitles || []}
                        chipsLabel={(option) =>
                          (option.lookupItemName && option.lookupItemName) || ''
                        }
                        displayLabel={(option) =>
                          (option.lookupItemName && option.lookupItemName) || ''
                        }
                        multiple={false}
                        withoutSearchButton
                        isWithError
                        isSubmitted={isSubmitted}
                        onOpen={() => {
                          if (data.jobTitles && data.jobTitles.length == 0) getJobTitle();
                        }}
                        onChange={(event, newValue) => {
                          setState({
                            id: 'jobTitleId',
                            value: newValue.lookupItemId,
                          });
                          setSelected({ id: 'jobTitle', value: newValue });
                        }}
                      />
                    </div>
                    <div className='form-item'>
                      <AutocompleteComponent
                        idRef='languagesRef'
                        labelValue={t(`${translationPath}languages`)}
                        inputPlaceholder={t(`${translationPath}languages`)}
                        value={state.languages}
                        data={data.languages || []}
                        chipsLabel={(option) =>
                          (option.lookupItemName && option.lookupItemName) || ''
                        }
                        displayLabel={(option) =>
                          (option.lookupItemName && option.lookupItemName) || ''
                        }
                        multiple
                        withoutSearchButton
                        isWithError
                        isSubmitted={isSubmitted}
                        onOpen={() => {
                          if (data.languages && data.languages.length == 0) getLanguages()
                        }}
                        onChange={(event, newValue) => {
                          setState({
                            id: 'languages',
                            value: newValue,
                          });
                        }}
                      />
                    </div>
                    <div className='form-item'>
                      <Inputs
                        inputPlaceholder='InputsStaffCode'
                        labelValue='employeeId'
                        idRef='staffCodeRef'
                        parentTranslationPath='UsersView'
                        translationPath={translationPath}
                        isSubmitted={isSubmitted}
                        onKeyUp={(event) => {
                          const staffCode = event?.target?.value;
                          if (typingTimer.current) clearTimeout(typingTimer.current);
                          typingTimer.current = setTimeout(() => {
                            setState({ id: 'staffCode', value: staffCode });
                          }, 500);
                        }}
                      />
                    </div>
                    <div className='form-range-wrapper'>
                      <div className='item-range'>
                        <Inputs
                          idRef='idMinTargetRef'
                          labelValue='minTarget'
                          value={state.targetMin}
                          type='number'
                          onInputChanged={(e) => {
                            const value = parseInt(e.target.value);
                            setState({ id: 'targetMin', value });
                            if (value > state.targetMax || !state.targetMax)
                              setState({ id: 'targetMax', value });
                          }}
                          onKeyUp={() => {
                            const maxAllowed = 100000000;
                            if (state.targetMin > maxAllowed)
                              setState({ id: 'targetMin', value: maxAllowed });
                          }}
                          isWithError
                          isSubmitted={isSubmitted}
                          helperText={getErrorByName(schema, 'targetMin').message}
                          error={getErrorByName(schema, 'targetMin').error}
                          parentTranslationPath='UsersView'
                          translationPath='UsersManagementView.'
                        />
                      </div>
                      <div className='item-range'>
                        <Inputs
                          idRef='idMaxTargetRef'
                          labelValue='maxTarget'
                          min={state.targetMin}
                          value={state.targetMax}
                          type='number'
                          onInputChanged={(e) => {
                            const value = parseInt(e.target.value);
                            setState({ id: 'targetMax', value });
                          }}
                          onKeyUp={() => {
                            const maxAllowed = 100000000;
                            if (state.targetMax > maxAllowed)
                              setState({ id: 'targetMax', value: maxAllowed });
                            if (state.targetMax < state.targetMin)
                              setState({ id: 'targetMin', value: '' });
                          }}
                          parentTranslationPath='UsersView'
                          translationPath='UsersManagementView.'
                        />
                      </div>
                    </div>
                    <AutocompleteComponent
                      idRef='countryIdRef'
                      labelValue='country'
                      multiple
                      data={data.countries || []}
                      wrapperClasses={'mb-2'}
                      displayLabel={(option) => (option && option.lookupItemName) || ''}
                      chipsLabel={(option) => (option && option.lookupItemName) || ''}
                      withoutSearchButton
                      selectedValues={selectedAdress.country}
                      parentTranslationPath='UsersView'
                      translationPath='UsersManagementView.'
                      onOpen={() => {
                        if (data.countries && data.countries.length == 0) getCountires()
                      }}
                      onChange={(e, newValue) => {
                        let list = [];
                        newValue.map((e) => list.push({ countryId: e.lookupItemId }));
                        setState({ id: 'userAreaOfFocusCountries', value: [...list] });
                        setSelectedAdress({ id: 'country', value: newValue });
                      }}
                    />

                    <AutocompleteComponent
                      idRef='cityIdRef'
                      labelValue='city'
                      multiple
                      wrapperClasses={'mb-2'}
                      data={data.cities || []}
                      displayLabel={(option) => (option && option.lookupItemName) || ''}
                      chipsLabel={(option) => (option && option.lookupItemName) || ''}
                      withoutSearchButton
                      selectedValues={selectedAdress.city}
                      parentTranslationPath='UsersView'
                      translationPath='UsersManagementView.'
                      onOpen={() => {
                        if (data.cities && data.cities.length == 0) getAllCities()
                      }}
                      onChange={(e, newValue) => {
                        let list = [];
                        newValue.map((e) => list.push({ cityId: e.lookupItemId }));
                        setState({ id: 'userAreaOfFocusCities', value: [...list] });
                        setSelectedAdress({ id: 'city', value: newValue });
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (typingTimer.current) clearTimeout(typingTimer.current);
                        typingTimer.current = setTimeout(() => {
                          getAllCities(value);
                        }, 500);
                      }}
                    />
                    <AutocompleteComponent
                      idRef='districtIdRef'
                      labelValue='district'
                      multiple
                      data={data.districts || []}
                      wrapperClasses={'mb-2'}
                      displayLabel={(option) => (option && option.lookupItemName) || ''}
                      chipsLabel={(option) => (option && option.lookupItemName) || ''}
                      withoutSearchButton
                      selectedValues={selectedAdress.district}
                      parentTranslationPath='UsersView'
                      translationPath='UsersManagementView.'
                      onOpen={() => {
                        if (data.districts && data.districts.length == 0) getAllDistricts()
                      }}
                      onChange={(e, newValue) => {
                        let list = [];
                        newValue.map((e) => list.push({ districtId: e.lookupItemId }));
                        setState({ id: 'userAreaOfFocusDistricts', value: [...list] });
                        setSelectedAdress({ id: 'district', value: newValue });
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (typingTimer.current) clearTimeout(typingTimer.current);
                        typingTimer.current = setTimeout(() => {
                          getAllDistricts(value);
                        }, 500);
                      }}
                    />
                    <AutocompleteComponent
                      idRef='communityIdRef'
                      labelValue='community'
                      multiple
                      data={data.communities || []}
                      wrapperClasses={'mb-2'}
                      displayLabel={(option) => (option && option.lookupItemName) || ''}
                      chipsLabel={(option) => (option && option.lookupItemName) || ''}
                      withoutSearchButton
                      selectedValues={selectedAdress.community}
                      parentTranslationPath='UsersView'
                      translationPath={translationPath}
                      onOpen={() => {
                        if (data.communities && data.communities.length == 0) getAllCommunities()
                      }}
                      onChange={(e, newValue) => {
                        let list = [];
                        newValue.map((e) => list.push({ communityId: e.lookupItemId }));
                        setState({ id: 'userAreaOfFocusCommunities', value: [...list] });
                        setSelectedAdress({ id: 'community', value: newValue });
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (typingTimer.current) clearTimeout(typingTimer.current);
                        typingTimer.current = setTimeout(() => {
                          getAllCommunities(value);
                        }, 500);
                      }}
                    />

                    <AutocompleteComponent
                      idRef='subcommunityIdRef'
                      labelValue='subCommunity'
                      multiple
                      data={data.subCommunities || []}
                      displayLabel={(option) => (option && option.lookupItemName) || ''}
                      chipsLabel={(option) => (option && option.lookupItemName) || ''}
                      withoutSearchButton
                      wrapperClasses={'mb-2'}
                      selectedValues={selectedAdress.subCommunity}
                      parentTranslationPath='UsersView'
                      translationPath={translationPath}
                      onOpen={() => {
                        if (data.subCommunities && data.subCommunities.length == 0) getAllSubCommunities()
                      }}
                      onChange={(e, newValue) => {
                        let list = [];
                        newValue.map((e) => list.push({ subCommunityId: e.lookupItemId }));
                        setState({ id: 'userAreaOfFocusSubCommunities', value: [...list] });
                        setSelectedAdress({ id: 'subCommunity', value: newValue });
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (typingTimer.current) clearTimeout(typingTimer.current);
                        typingTimer.current = setTimeout(() => {
                          getAllSubCommunities(value);
                        }, 500);
                      }}
                    />

                    <Inputs
                      inputPlaceholder='TotalYearOfExperince'
                      labelValue='TotalYearOfExperince'
                      idRef='TotalYearOfExperinceRef'
                      value={state.totalYearsOfExperience}
                      parentTranslationPath='UsersView'
                      translationPath={translationPath}
                      isWithError
                      isSubmitted={isSubmitted}
                      type='number'
                      wrapperClasses={'mb-2'}
                      withLoader
                      max={900000000}
                      onInputChanged={(event) =>
                        setState({ id: 'totalYearsOfExperience', value: +event.target.value })
                      }
                    />
                    <div className='form-item'>
                      <Inputs
                        idRef='aboutMeRef'
                        labelValue='about-me'
                        parentTranslationPath='UsersView'
                        translationPath={translationPath}
                        isWithError
                        rows={4}
                        multiline
                        isSubmitted={isSubmitted}
                        wrapperClasses={'about-me mb-0'}
                        withLoader
                        onKeyUp={(event) => {
                          const aboutMe = event?.target?.value;
                          if (typingTimer.current) clearTimeout(typingTimer.current);
                          typingTimer.current = setTimeout(() => {
                            setState({ id: 'aboutMe', value: aboutMe });
                          }, 500);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='account-dialog-section'>
            <div className='dialog-header'>{t(`${translationPath}account-details`)} </div>
            <div className='dialog-body'>
              <div className='form-item'>
                <Inputs
                  inputPlaceholder='InputsUsername'
                  labelValue='Username-req'
                  idRef='usernameRef'
                  parentTranslationPath='UsersView'
                  translationPath={translationPath}
                  isWithError
                  isSubmitted={isSubmitted}
                  afterIconClasses={
                    (isExist?.userName === true && 'mdi mdi-close-outline c-danger mt-1') ||
                    (!getErrorByName(schema, 'userName').error &&
                      'mdi mdi-check-outline c-success mt-1') ||
                    null
                  }
                  withLoader
                  helperText={getErrorByName(schema, 'userName').message}
                  error={getErrorByName(schema, 'userName').error}
                  onKeyUp={(event) => {
                    const userName = event?.target?.value;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      setState({ id: 'userName', value: userName });
                      getIsExistUserName(userName);
                    }, 500);
                  }}
                />
              </div>
              <div className='form-item'>
                <Inputs
                  inputPlaceholder='InputsEmail'
                  idRef='emailRef'
                  isWithError
                  parentTranslationPath='UsersView'
                  translationPath={translationPath}
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'email').message}
                  error={getErrorByName(schema, 'email').error}
                  afterIconClasses={
                    (isExist?.email === true && 'mdi mdi-close-outline c-danger mt-1') ||
                    (!getErrorByName(schema, 'email').error &&
                      'mdi mdi-check-outline c-success mt-1') ||
                    null
                  }
                  withLoader
                  labelValue='Email-req'
                  onKeyUp={(event) => {
                    const email = event?.target?.value;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      setState({ id: 'email', value: email });
                      getIsExistEmail(email);
                    }, 500);
                  }}
                />
              </div>
              <div className='form-item'>
                <PhonesComponent
                  country='ae'
                  idRef='phoneNumberRef'
                  labelValue={t(`${translationPath}Mobile-req`)}
                  value={state.phoneNumber}
                  helperText={getErrorByName(schema, 'phoneNumber').message}
                  error={getErrorByName(schema, 'phoneNumber').error}
                  inputPlaceholder='Mobile'
                  isSubmitted={isSubmitted}
                  afterIconClasses={
                    (isExist?.phoneNumber === true && 'mdi mdi-close-outline c-danger mt-1') ||
                    (!getErrorByName(schema, 'phoneNumber').error &&
                      'mdi mdi-check-outline c-success mt-1') ||
                    null
                  }
                  onInputChanged={(value) => {
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      setState({ id: 'phoneNumber', value });
                      getIsExistPhoneNumber(value);
                    }, 500);
                  }
                  }
                />
              </div>
              <div className='form-item'>
                <PhonesComponent
                  country='ae'
                  idRef='whatsappNumberRef'
                  labelValue={t(`${translationPath}whatsapp-req`)}
                  value={state.whatsAppNumber}
                  helperText={getErrorByName(schema, 'whatsAppNumber').message}
                  error={getErrorByName(schema, 'whatsAppNumber').error}
                  inputPlaceholder='whatsapp'
                  isSubmitted={isSubmitted}
                  onInputChanged={(value) => {
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      setState({ id: 'whatsAppNumber', value });
                    }, 500);
                  }
                  }
                />
              </div>
              <div className='form-item'>
                <DatePickerComponent
                  idRef='DateOfBirthRef'
                  labelValue='Date Of Birth'
                  placeholder='DD/MM/YYYY'
                  value={state.dateOfBirth || null}
                  onDateChanged={(newValue) => {
                    setState({
                      id: 'dateOfBirth',
                      value: (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null,
                    });
                  }}
                />
              </div>
              <div className='form-item'>
                <DatePickerComponent
                  idRef='DateOfJoiningRef'
                  labelValue='Date of Joining'
                  placeholder='DD/MM/YYYY'
                  value={state.dateOfJoining || null}
                  onDateChanged={(newValue) => {
                    setState({
                      id: 'dateOfJoining',
                      value: (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null,
                    });
                  }}
                />
              </div>
              <div className='form-item'>
                <AutocompleteComponent
                  idRef='RegistrationNoRef'
                  labelValue='Reports To'
                  inputPlaceholder='Reports To'
                  value={state.reportsTo}
                  data={data?.users || []}
                  chipsLabel={(option) =>
                    (option && option.fullName) || (option && option.firstName) || ''
                  }
                  displayLabel={(option) =>
                    (option && option.fullName) || (option && option.firstName) || ''
                  }
                  multiple={false}
                  withoutSearchButton
                  onOpen={() => {
                    if (data.users && data.users.length == 0) getAllUsers()
                  }}
                  onChange={(event, newValue) => {
                    setState({
                      id: 'reportsToId',
                      value: (newValue && newValue.id) || null,
                    });
                    setSelected({ id: 'reportsTo', value: newValue });
                  }}
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getAllUsers(value);
                    }, 700);
                  }}
                />
              </div>
              <div className='form-item'>
                <Inputs
                  labelValue='Registration No'
                  inputPlaceholder='RegistrationNo'
                  idRef='RegistrationNoRef'
                  onKeyUp={(event) => {
                    const registrationNo = event?.target?.value;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      setState({ id: 'registrationNo', value: registrationNo });
                    }, 500);
                  }}
                />
              </div>
              <div className='d-flex w-100'>
                <Inputs
                  inputPlaceholder='InputsPassword'
                  labelValue='Password'
                  idRef='passwordRef'
                  value={state.password}
                  parentTranslationPath='UsersView'
                  translationPath={translationPath}
                  isWithError
                  wrapperClasses={'mr-2'}
                  // type='password'
                  type={!Passwords.ViewNewassword ? 'password' : ''}

                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'password').message}
                  error={getErrorByName(schema, 'password').error}
                  onInputChanged={(event) =>
                    setState({ id: 'password', value: event.target.value })
                  }
                  endAdornment={(
                    <Button onClick={onClickednew} className='p-2'>
                      {Passwords.ViewNewassword ? <span className='mdi mdi-eye-outline' /> : <span className='mdi mdi-eye-off-outline' />}
                    </Button>
                  )}

                />
                <Inputs
                  inputPlaceholder={t(`${translationPath}Inputsconfirm-password`)}
                  labelValue={t(`${translationPath}confirm-password`)}
                  idRef='confirmPasswordRef'
                  value={state.confirmPassword}
                  // type='password'
                  isWithError
                  isDisabled={state.password === ''}
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'confirmPassword').message}
                  error={getErrorByName(schema, 'confirmPassword').error}
                  type={!Passwords.ViewconfirmPassword ? 'password' : ''}
                  onInputChanged={(event) =>
                    setState({
                      id: 'confirmPassword',
                      value: event.target.value,
                    })
                  }
                  endAdornment={(
                    <Button onClick={onClickedViewconfirmPassword} className='p-2'>
                      {Passwords.ViewconfirmPassword ? <span className='mdi mdi-eye-outline' /> : <span className='mdi mdi-eye-off-outline' />}
                    </Button>
                  )}
                />
              </div>
              {/* <div className='form-item'>

              </div> */}

              {/* <div className='w-100 pb-130px mb-3'>
                <ProgressComponet
                  inSameLine
                  value={
                    (state.password.match(strongStringRegex) && 100) ||
                    (state.password.match(mediumStringRegex) && 50) ||
                    (state.password !== '' && 25) ||
                    0
                  }
                  isTextColored
                  themeClasses='theme-gradient'
                  progressText={
                    (state.password.match(strongStringRegex) &&
                      t(`${translationPath}trong-password`)) ||
                    (state.password.match(mediumStringRegex) &&
                      `${translationPath}medium-password`) ||
                    (state.password !== '' && t(`${translationPath}weak-password`)) ||
                    t(`${translationPath}password-is-blank`)
                  }
                />
              </div> */}

              <div className='password-validation  my-2'>
                <span className={atLeastOneNumber.test(state.password) ? 'mdi mdi-check my-1' : 'mdi mdi-information-outline my-1'} >{ t(`${translationPath}one-number`)}</span>
                <span className={charactersLength.test(state.password) ? 'mdi mdi-check my-1 ' : 'mdi mdi-information-outline my-1'} > {t(`${translationPath}8-20-characters`)}</span>
                <span className={containUpper.test(state.password) ? 'mdi mdi-check my-1' : 'mdi mdi-information-outline my-1'} >{ t(`${translationPath}one-uppercase-letter`)}</span>
                <span className={containSpecialCharacter.test(state.password) ? 'mdi mdi-check my-1' : 'mdi mdi-information-outline my-1'} > {t(`${translationPath}special-character(!@#$%^&*)`)}</span>

              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
