import React, {
  useCallback, useEffect, useReducer, useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { ButtonBase } from '@material-ui/core';
import { PropertiesPermissionsCRM } from '../../../../../../Permissions/PropertiesPermissions';
import { Spinner, TabsComponent, PermissionsComponent } from '../../../../../../Components';
import {
  bottomBoxComponentUpdate,
  GlobalHistory,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import {
  CreatePropertyMarketing,
  GetPropertyMarketingByPropertyId,
  UpdatePropertyMarketing,
} from '../../../../../../Services';
import { PropertiesProfileMarketingTabsData } from './TabsData';

export const PropertiesProfileMarketingComponent = ({
  propertyId,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Fullstate, setFullState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const defaultState = {
    propertyId,
    propertyOverView: null,
    localAreaAndAmenitiesDescription: null,
    webRemarks: null,
    isFeaturedSliderOnWeb: false,
    isFeaturedProjectOnWeb: false,
    propertyAdditionalDescription: null,
    englishMarketingTitle: null,
    arabicMarketingTitle: null,
    arabicAddressLine: null,
    arabicPropertyOverview: null,
    videoBannerURL: null,
    imageBannerURL: null,
    propertyLabelId: null,
    siteInfoAndAmenitiesDescription: null,
    developerDescription: null,
    isShowInWeb: false,
    marketingUsp1Id: null,
    marketingUsp2Id: null,
    marketingUsp3Id: null,
    marketingUsp4Id: null,
    luxuryIds: [],
    financeIds: [],
    styleIds: [],
    viewIds: [],
  };

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [state, setState] = useReducer(reducer, defaultState);
  const onStateChanged = (newValue) => {
    setState(newValue);
  };

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const schema = Joi.object({
    propertyOverView: Joi.string()
      .allow(null, '')
      .min(200)
      .max(5000)
      .messages({
        'string.min': t(`${translationPath}least-allowed-character-description`),
        'string.max': t(
          `${translationPath}max-allowed-character-descriptionmax-allowed-character-description`
        ),
      }),
    localAreaAndAmenitiesDescription: Joi.string()
      .allow(null, '')
      .min(200)
      .max(5000)
      .messages({
        'string.min': t(`${translationPath}least-allowed-character-description`),
        'string.max': t(
          `${translationPath}max-allowed-character-descriptionmax-allowed-character-description`
        ),
      }),
    webRemarks: Joi.string()
      .allow(null, '')
      .min(200)
      .max(5000)
      .messages({
        'string.min': t(`${translationPath}least-allowed-character-description`),
        'string.max': t(
          `${translationPath}max-allowed-character-descriptionmax-allowed-character-description`
        ),
      }),
    siteInfoAndAmenitiesDescription: Joi.string()
      .allow(null, '')
      .min(200)
      .max(5000)
      .messages({
        'string.min': t(`${translationPath}least-allowed-character-description`),
        'string.max': t(
          `${translationPath}max-allowed-character-descriptionmax-allowed-character-description`
        ),
      }),
    developerDescription: Joi.string()
      .allow(null, '')
      .min(200)
      .max(5000)
      .messages({
        'string.min': t(`${translationPath}least-allowed-character-description`),
        'string.max': t(
          `${translationPath}max-allowed-character-descriptionmax-allowed-character-description`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const getPropertyMarketingByPropertyId = useCallback(async () => {
    setIsLoading(true);
    const res = await GetPropertyMarketingByPropertyId(propertyId);

    if (!(res && res.status && res.status !== 200)) {
      if (res && res.length > 0) {
      setFullState(res);
      res.forEach((item) => {
        if (item.propertyMarketingId) {
          setState({
            id: 'edit',
            value: {
              ...state,
              propertyId,
              relatedUnit: item.relatedUnit,
              relatedLead: item.relatedLead,
              isShowInWeb: item.isShowInWeb,
              propertyOverView: item.propertyOverView,
              localAreaAndAmenitiesDescription: item.localAreaAndAmenitiesDescription,
              webRemarks: item.webRemarks,
              siteInfoAndAmenitiesDescription: item.siteInfoAndAmenitiesDescription,
              developerDescription: item.developerDescription,
              luxuryIds: item.luxuryIds.map((item) => item.luxurysId || []),
              financeIds: item.financeIds.map((item) => item.financesId || []),
              viewIds: item.viewIds.map((item) => item.viewsId || []),
              styleIds: item.styleIds.map((item) => item.stylesId || []),
              isFeaturedSliderOnWeb: item.isFeaturedSliderOnWeb,
              isFeaturedProjectOnWeb: item.isFeaturedProjectOnWeb,
              propertyAdditionalDescription: item.propertyAdditionalDescription,
              englishMarketingTitle: item.englishMarketingTitle,
              arabicMarketingTitle: item.arabicMarketingTitle,
              arabicAddressLine: item.arabicAddressLine,
              arabicPropertyOverview: item.arabicPropertyOverview,
              videoBannerURL: item.videoBannerURL,
              imageBannerURL: item.imageBannerURL,
              propertyLabelId: item.propertyLabelId,
              marketingUsp1Id: item.marketingUsp1Id,
              marketingUsp2Id: item.marketingUsp2Id,
              marketingUsp3Id: item.marketingUsp3Id,
              marketingUsp4Id: item.marketingUsp4Id,

            }
          });
        }
      });
    } else {
      setState({
        id: 'edit',
        value: {
          ...defaultState,
        }
      });
    }

      setId(res.propertyMarketingId || null);
    }
    setIsLoading(false);
  }, [propertyId]);
  const cancelHandler = () => {
    GlobalHistory.goBack();
  };
  const saveHandler = useCallback(async () => {
    setIsSubmitted(true);
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    setIsLoading(true);
    const res =
      (id !== null ? (await UpdatePropertyMarketing(id,
        {
          propertyId,
          propertyOverView: state.propertyOverView,
          localAreaAndAmenitiesDescription: state.localAreaAndAmenitiesDescription,
          webRemarks: state.webRemarks,
          siteInfoAndAmenitiesDescription: state.siteInfoAndAmenitiesDescription,
          developerDescription: state.developerDescription,
          isShowInWeb: state.isShowInWeb,
          luxuryIds: state.luxuryIds,
          financeIds: state.financeIds,
          styleIds: state.styleIds,
          viewIds: state.viewIds
        })) : (await CreatePropertyMarketing(state)));
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      if (id) {
        showSuccess(t(`${translationPath}property-marketing-updated-successfully`));
        getPropertyMarketingByPropertyId();
      } else showSuccess(t(`${translationPath}property-marketing-created-successfully`));
    } else if (id) showError(t(`${translationPath}property-marketing-update-failed`));
    else showError(t(`${translationPath}property-marketing-create-failed`));
  }, [id, schema, state, t, translationPath]);
  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-end flex-wrap'>
        <ButtonBase className='btns theme-transparent mb-2' onClick={cancelHandler}>
          <span>{t('Shared:cancel')}</span>
        </ButtonBase>
        <PermissionsComponent
          permissionsList={Object.values(PropertiesPermissionsCRM)}
          permissionsId={PropertiesPermissionsCRM.EditPropertyMarketingInfo.permissionsId}
        >
          <ButtonBase className='btns theme-solid mb-2' onClick={saveHandler}>
            <span>{t('Shared:save')}</span>
          </ButtonBase>
        </PermissionsComponent>
      </div>
    );
  });
  useEffect(() => {
    if (propertyId) getPropertyMarketingByPropertyId();
  }, [getPropertyMarketingByPropertyId, propertyId]);
  return (
    <div className='properties-profile-marketing-wrapper childs-wrapper p-0 b-0'>
      <Spinner isActive={isLoading} isAbsolute />
      <TabsComponent
        data={PropertiesProfileMarketingTabsData}
        labelInput='label'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-curved'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          state,
          onStateChanged,
          Fullstate,
          isSubmitted,
          schema,
          parentTranslationPath,
          translationPath,
        }}
      />
    </div>
  );
};
