


import React , { useState , useEffect  , useRef , useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , AutocompleteComponent  } from '../../../../../../../Components' ; 
import { OrganizationUserSearch } from '../../../../../../../Services' ; 
import { AgentRoleEnum  } from '../../../../../../../Enums' ;  


    
export const UnitsSaleListedBy  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState , 
  checkAtLeastOneSelected ,
  checkAtLeastOneSelected2 , 
  setDefaultSelected , 
  
}) => {

    const { t } = useTranslation(parentTranslationPath);
    
    const searchTimer = useRef(null);
    let timer = null; 
    const [allUsers , setAllUsers] =  useState([]) ;
    const [isLoading ,   setIsLoading ] = useState(false) ;  

    const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
      setIsLoading(true);
      const res = await OrganizationUserSearch({
         pageIndex: 0,
         pageSize: 10,
         name: searchValue  ,
         userStatusId : 2  , 
         userTypeId : AgentRoleEnum.SaleListingAgent.value 

        });
      if (!(res && res.status && res.status !== 200)) 
      setAllUsers(res.result.map((item) => ({
        id :  item.id , 
        fullName : item.fullName  , 
        branch : item.branch  || null  , 
      })));
       else 
       setAllUsers([]);  

       setIsLoading(false);

    }, []); 


    useEffect(() => {
      getAllOrganizationUserSearch() ;
    }, []); 

  return (
    <div className='content'>
      <div className='w-100 px-2 mt-2 checkBoxsPart'>
      <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='unitsSaleListingViewAllUnitsRef'
          label={t(`${translationPath}view-all-units`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.saleListing.allUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.saleListing ,  allUsers : e.target.checked } );
            
            if(checkAtLeastOneSelected2(state.units.saleListing.specificUsers));
                else if(!checkIfCanSelected)
                  return ;

            const unitsSaleListing =  { ...state.units.saleListing ,
               allUsers : (e.target.checked)   , 
               myUser  : false  , 
               myTeamUsers : false , 
               myBusinessGroupUsers : false , 
               myBranchUsers :false , 
               myManageUsers : false ,
               specificUsers :[] , 
               myTeamLeadUsers : false

             } ;
          setState({id : 'units' , value : { ...state.units , saleListing :   unitsSaleListing  } });
         }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='unitsSaleListingViewMyUnitsRef'
          label={t(`${translationPath}view-my-units`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.saleListing.myUser}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.saleListing ,  myUser : e.target.checked } );

            if(checkAtLeastOneSelected2(state.units.saleListing.specificUsers));
                else if(!checkIfCanSelected)
                  return ;

            const unitsSaleListing =  { ...state.units.saleListing ,
               allUsers : false    , 
               myUser  : (e.target.checked)  , 
               myTeamUsers : false , 
               myBusinessGroupUsers : false , 

             } ;
          setState({id : 'units' , value : { ...state.units , saleListing :   unitsSaleListing  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
           <CheckboxesComponent
            idRef='unitsSaleListingViewMyTeamUnitsRef'
            label={t(`${translationPath}view-my-team-units`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.units.saleListing.myTeamUsers}
            onSelectedCheckboxClicked={(e)=> {

              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.saleListing ,  myTeamUsers : e.target.checked } );
              if(checkAtLeastOneSelected2(state.units.saleListing.specificUsers));
                else if(!checkIfCanSelected)
                     return ;

              const unitsSaleListing =  { ...state.units.saleListing ,
                 allUsers : false   , 
                 myUser  : false  , 
                 myTeamUsers :  (e.target.checked)  , 
                 myBusinessGroupUsers : false , 
  
               } ;
            setState({id : 'units' , value : { ...state.units , saleListing :   unitsSaleListing  } });
           }}
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
            idRef='unitsSaleListingViewMyBusinessGroupRef'
            label={t(`${translationPath}view-my-business-group-units`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.units.saleListing.myBusinessGroupUsers}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.saleListing ,  myBusinessGroupUsers : e.target.checked });

              if(checkAtLeastOneSelected2(state.units.saleListing.specificUsers));
                else if(!checkIfCanSelected)
                  return ;

              const unitsSaleListing =  { ...state.units.saleListing ,
                 allUsers :false   , 
                 myUser  : false  , 
                 myTeamUsers : false , 
                 myBusinessGroupUsers : (e.target.checked) , 
  
               } ;
            setState({id : 'units' , value : { ...state.units , saleListing :   unitsSaleListing  } });
           }}
         />
         </div>
         <div className='checkboxesItem'>
          <CheckboxesComponent
           idRef='unitsSaleListingViewMyBranchUnitsRef'
           label={t(`${translationPath}view-my-branch-units`)}
           parentTranslationPath={parentTranslationPath}
           translationPathForData={translationPath}
           singleChecked={state.units.saleListing.myBranchUsers}
           onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.saleListing ,  myBranchUsers : e.target.checked } );

            if(checkAtLeastOneSelected2(state.units.saleListing.specificUsers));
            else if(!checkIfCanSelected)
              return ; 

            const unitsSaleListing =  { ...state.units.saleListing ,
               allUsers : false   , 
               myBranchUsers :(e.target.checked) , 

             } ;
          setState({id : 'units' , value : { ...state.units , saleListing :   unitsSaleListing  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='unitsSaleListingViewMyManagedTeamUnitsRef'
          label={t(`${translationPath}view-my-managed-team-units`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.saleListing.myManageUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.saleListing ,  myManageUsers : e.target.checked });

            if(checkAtLeastOneSelected2(state.units.saleListing.specificUsers));
                else if(!checkIfCanSelected)
                  return ;
              
            const unitsSaleListing =  { ...state.units.saleListing ,
               allUsers : false   , 
               myManageUsers :(e.target.checked) , 

             } ;
          setState({id : 'units' , value : { ...state.units , saleListing :   unitsSaleListing  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='unitsSaleListingViewMyTeamLeadUsersRef'
            label={t(`${translationPath}view-my-team-lead-users`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.units.saleListing.myTeamLeadUsers}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.saleListing ,  myTeamLeadUsers : e.target.checked } );
               if(checkAtLeastOneSelected2(state.units.saleListing.specificUsers));
               else if(!checkIfCanSelected)
                   return ;
            
               const unitsSaleListing =  { ...state.units.saleListing ,
                  allUsers : false   , 
                  myTeamLeadUsers :(e.target.checked) , 
                 } ;
            setState({id : 'units' , value : { ...state.units , saleListing :   unitsSaleListing  } });
         }}
         />
         </div>
         <div className='w-100 px-2 mt-2 mb-2'>
          <AutocompleteComponent
              idRef='unitsSaleListingSpecificsUsersRef'
              labelValue={t(`${translationPath}specific_users`)}
              data={ allUsers || []}
              selectedValues={state.units.saleListing.specificUsers}
              multiple
              displayLabel={(option) =>
              (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                   searchTimer.current = setTimeout(() => {
                    getAllOrganizationUserSearch(value) ;  
                }, 700); 
                }}
                renderOption={
                  ((option) =>
                  <div className='d-flex-column'>
                   <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                    {option.fullName}
                   </div>
                   <span className='c-gray-secondary'> {option.branch} </span>
                 </div>
               )}
              value={state.units.saleListing.specificUsers}
              onChange={(e, newValues) => 
              {
                let unitsSaleListing = { ...state.units.saleListing  , specificUsers : newValues };
                if( !newValues.length && setDefaultSelected({ ...state.units.saleListing , specificUsers :false }))
                unitsSaleListing = { ...state.units.saleListing  , specificUsers : newValues , allUsers : true }
                setState({ id: 'units', value: { ...state.units , saleListing  : unitsSaleListing } })
              }}
             isLoading={isLoading} 
             withLoader
             filterOptions={(options) => {
              const isFind = (id) => state.units.saleListing.specificUsers.findIndex((w) => w.id === id) === -1;
             return options.filter((w) => isFind(w.id));
            }}
            isDisabled={state.units.saleListing.allUsers}
         /> 
        </div>
     </div>
  </div>
  );
};

UnitsSaleListedBy.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected  : PropTypes.func.isRequired, 
  checkAtLeastOneSelected2 :PropTypes.func.isRequired, 
  setDefaultSelected:PropTypes.func.isRequired, 
};
