import {
  DescriptionsView,
  PublishedImages
} from '../Sections';
import { WebPortal } from '../../../../../UnitsSalesView/UnitsSalesProfileManagement/Sections/Marketing/Sections/WebPortal' ; 
import { UnitsLeasePermissions} from '../../../../../../../Permissions' ; 
    
export const UnitProfileMarketingTabsData = [
  { label: 'descriptions', component: DescriptionsView },
  {
     label: 'web-portal',
     component: WebPortal , 
     permissionsList: Object.values(UnitsLeasePermissions),
     permissionsId: UnitsLeasePermissions.ShowWebPortals.permissionsId,

},
  { label: 'published-images', component: PublishedImages },
];
