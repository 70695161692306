import React, { useState, useCallback, useReducer, useEffect, useRef } from 'react';
import moment from 'moment';
import Joi from 'joi';
import { DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase, Tab, Tabs, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess, GetParams } from '../../../Helper';
import {
  Spinner,
  Inputs,
  DatePickerComponent,
  AutocompleteComponent,
  SelectComponet,
} from '../../../Components';
import {
  CreatePropertyCampaign,
  UpdatePropertyCampaign,
  lookupItemsGetId,
  lookupItemsGet,
  GetAllBranches
} from '../../../Services';
import { ApplicationUserSearch } from '../../../Services/userServices';
import {StaticLookupsIds} from '../../../assets/json/StaticLookupsIds';

export const CampaignMarketingDialog = ({
  parentTranslationPath,
  translationPath,
  activeItem,
  isDialogOpen,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '0px',
      paddingBottom: '5px',
      textTransform: 'initial',
    },
    indicator: {
      backgroundColor: '#3b72d9',
    },
    labelIcon: {
      minHeight: 'unset',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
    fixed: {
      width: 'fit-content',
      margin: 'auto',
    },
    scroller: {
      flex: 'unset',
    },
  }));
  const classes = useStyles();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [allBranches, setAllBranches] = useState([]);
  const [state, setState] = useReducer(reducer, {
    name: null,
    campaignStartDate: moment().add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
    campaignEndDate: moment().add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
    campaignLanguages: [],
    mediaTypeId: null,
    mediaUrl: null,
    campaignManagerId: null,
    mediaDetailsId: null,
    campaignStatus: true,
    campaignTypeIds: [],
    budgetAmount: 0,
    spentAmount: 0,
    budgetLevel: 0,
    budgetLevelAdminIds: [],
    expectedNumberOfLeads: 0,
    campaignBranches: []
  });
  const [selected, setSelected] = useReducer(reducer, {
    campaignLanguages: [],
    mediaTypeId: null,
    campaignManagerId: null,
    mediaDetailsId: null,
    campaignTypes: [],
    budgetLevelAdmins: [],
    campaignBranches: []
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    campaignLanguages: false,
    mediaTypes: false,
    mediaDetails: false,
    users: false,
    campaignTypes: false,
    branches: false
  });
  const [data, setData] = useReducer(reducer, {
    campaignLanguages: [],
    mediaTypes: [],
    mediaDetails: [],
    users: [],
    campaignTypes: [],
  });
  const [tabIndex, setTabIndex] = useState(0);

  const schema = Joi.object({
    campaignStartDate: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}campaign-start-date-is-required`),
        'state.required': t(`${translationPath}campaign-start-date-is-required`),
      }),
    campaignTypeIds: Joi.array()
      .required()
      .min(1)
      .messages({
        'array.base': t(`${translationPath}campaign-type-is-required`),
        'array.empty': t(`${translationPath}campaign-type-is-required`),
        'array.min': t(`${translationPath}campaign-type-is-required`),
        'state.required': t(`${translationPath}campaign-type-is-required`)
      }),
    campaignManagerId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}campaign-Manager-is-required`),
        'state.required': t(`${translationPath}campaign-Manager-is-required`),
      }),
    budgetLevel: Joi.number()
      .custom((value, helpers) => {
        if (state.budgetLevel > state.budgetAmount) return helpers.error('budget-level-value');
        return value;
      })
      .messages({
        'budget-level-value': t(`${translationPath}budget-level-should-be-less-or-equal-than-budget-amount`),
      }),
    budgetAmount: Joi.number()
      .custom((value, helpers) => {
        if (state.budgetAmount === 0) return helpers.error('budget-amount-value');
        return value;
      })
      .messages({
        'budget-amount-value': t(`${translationPath}budget-amount-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getCampaignTypes = async () => {
    setIsLoading({ id: 'campaignTypes', value: true });

    const res = await lookupItemsGet({
      lookupTypeName: 'CampaignType', pageIndex: 1, pageSize: 50
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: 'campaignTypes',
        value: res.result,
      });
    } else {
      setData({
        id: 'campaignTypes',
        value: [],
      });
    }
    setIsLoading({ id: 'campaignTypes', value: false });
  };

  const getLanguages = async () => {
    setIsLoading({ id: 'campaignLanguages', value: true });

    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Languages,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: 'campaignLanguages',
        value: res,
      });
    } else {
      setData({
        id: 'campaignLanguages',
        value: [],
      });
    }
    setIsLoading({ id: 'campaignLanguages', value: false });
  };

  const getAllBranches = useCallback(async () => {
    setIsLoading({ id: 'branches', value: true });
    const res = await GetAllBranches({ pageSize: 25, pageIndex: 1 });
    if (!(res && res.status && res.status !== 200)) setAllBranches(res.result);
    else setAllBranches([]);
    setIsLoading({ id: 'branches', value: false });

  }, []);

  const getAllmediaTypes = useCallback(async () => {
    setIsLoading({ id: 'mediaTypes', value: true });
    const res = await lookupItemsGetId({ lookupTypeId: 8 });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'mediaTypes', value: res });
    } else {
      setData({ id: 'mediaTypes', value: [] });
    }
    setIsLoading({ id: 'mediaTypes', value: false });
  }, []);

  const getAllMediaDetails = useCallback(async (mediaTypeId) => {
    setIsLoading({ id: 'mediaDetails', value: true });
    const res = await lookupItemsGetId({
      lookupTypeId: 1240,
      lookupParentId: mediaTypeId
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'mediaDetails', value: res });
    } else {
      setData({ id: 'mediaDetails', value: [] });
    }
    setIsLoading({ id: 'mediaDetails', value: false });
  }, []);

  const getAllUsers = useCallback(
    async (searchValue) => {
      setIsLoading({ id: 'users', value: true });
      const res = await ApplicationUserSearch({
        pageIndex: 0,
        pageSize: 25,
        name: searchValue || '',
      });
      if (!(res && res.status && res.status !== 200)) {
        const localValue = (res && res.result) || [];
        if (localValue.length > 0) {
          setData({
            id: 'users',
            value: localValue,
          });
        } else {
          setData({
            id: 'users',
            value: [],
          });
        }

        setIsLoading({ id: 'users', value: false });
      }
    },
    []
  );

  const saveHandler = async (event) => {
    event.preventDefault();
    if (schema?.error?.message) {
      showError(schema.error.message);
      return;
    }
    setIsSubmitLoading(true);
    const body = { ...state };
    const res =
      (activeItem &&
        (await UpdatePropertyCampaign({
          campaignId: activeItem.propertyCampaignId,
          body,
        }))) ||
      (await CreatePropertyCampaign(body));
    setIsSubmitLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t(
          `${translationPath}${(activeItem && `campaign-updated-successfully`) || `campaign-created-successfully`
          }`
        )
      );
      if (onSave) onSave();
    } else {
      showError(
        t(
          `${translationPath}${(activeItem && `campaign-updated-failed`) || `campaign-created-failed`
          }`
        )
      );
    }
  };

  const handleTabClick = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleMediaDetailsValue = (mediaTypeId) => {
    getAllmediaTypes();
    setSelected({ id: 'mediaDetailsId', value: null });
    setState({ id: 'mediaDetailsId', value: null });

    if (mediaTypeId) getAllMediaDetails(mediaTypeId);
    else getAllMediaDetails();
  }


  const handleMediaTypeValue = (mediaDetailsItem) => {

    if (mediaDetailsItem) {
      const mediaTypeItem = {
        lookupItemId: mediaDetailsItem.parentLookupItemId,
        lookupItemName: mediaDetailsItem.parentLookupItemName
      }
      setSelected({ id: 'mediaTypeId', value: mediaTypeItem });
      setState({ id: 'mediaTypeId', value: mediaTypeItem.lookupItemId });

      setData({ id: 'mediaTypes', value: [mediaTypeItem] });
      getAllMediaDetails(mediaTypeItem.lookupItemId);

    } else {
      getAllmediaTypes();
    }
  }

  useEffect(() => {
    getLanguages();
    getCampaignTypes();
    getAllUsers();
    getAllBranches();
  }, []);

  useEffect(() => {

    if (activeItem && activeItem.mediaTypeId && activeItem.mediaDetailsId) {
      getAllMediaDetails(activeItem.mediaTypeId);

      const mediaTypeItem = {
        lookupItemId: activeItem.mediaTypeId,
        lookupItemName: activeItem.mediaTypeName,
      };
      setSelected({ id: 'mediaTypeId', value: mediaTypeItem });
      setState({ id: 'mediaTypeId', value: mediaTypeItem.lookupItemId });
      setData({ id: 'mediaTypes', value: [mediaTypeItem] });

      const mediaDetailsItem = {
        lookupItemId: activeItem.mediaDetailsId,
        lookupItemName: activeItem.mediaDetailsName,
      };
      setSelected({ id: 'mediaDetailsId', value: mediaDetailsItem });
      setState({ id: 'mediaDetailsId', value: mediaDetailsItem.lookupItemId });
    } if (activeItem && activeItem.mediaTypeId) {
      getAllMediaDetails(activeItem.mediaTypeId);

      const mediaTypeItem = {
        lookupItemId: activeItem.mediaTypeId,
        lookupItemName: activeItem.mediaTypeName,
      };
      setSelected({ id: 'mediaTypeId', value: mediaTypeItem });
      setState({ id: 'mediaTypeId', value: mediaTypeItem.lookupItemId });
      setData({ id: 'mediaTypes', value: [mediaTypeItem] });
    } else {
      getAllmediaTypes();
      getAllMediaDetails();
    }
  }, [activeItem])

  useEffect(() => {
    if (activeItem) {
      const stateCampaignLanguageIds =
        activeItem.propertyCampaignLanguages &&
        activeItem.propertyCampaignLanguages.map((item) => item.languageId);

      const stateBudgetLevelAdminsIds =
        activeItem.campaignBudgetLevelAdminDtos &&
        activeItem.campaignBudgetLevelAdminDtos.map((item) => item.userId);

      const stateCampaignTypeIds =
        activeItem.campaignTypeChoicesDtos &&
        activeItem.campaignTypeChoicesDtos.map((item) => item.campaignTypeId);

        const statePropertyCampaignBranchesIds =
        activeItem.propertyCampaignBranches &&
        activeItem.propertyCampaignBranches.map((item) => item.branchId);

      setState({
        id: 'edit',
        value: {
          name: activeItem.name,
          campaignStartDate: activeItem.campaignStartDate,
          campaignEndDate: activeItem.campaignEndDate,
          campaignLanguages: stateCampaignLanguageIds || [],
          mediaTypeId: activeItem.mediaTypeId || null,
          mediaUrl: activeItem.mediaUrl,
          campaignManagerId: activeItem.campaignManagerId,
          mediaDetailsId: activeItem.mediaDetailsId,
          campaignStatus: activeItem.campaignStatus,
          campaignBranches: statePropertyCampaignBranchesIds || [],
          campaignTypeIds: stateCampaignTypeIds || [],
          budgetAmount: activeItem.budgetAmount || 0,
          spentAmount: activeItem.spentAmount || 0,
          budgetLevel: activeItem.budgetLevel || 0,
          budgetLevelAdminIds: stateBudgetLevelAdminsIds || [],
          expectedNumberOfLeads: activeItem.expectedNumberOfLeads || 0,
          actualNumberOfLeads: activeItem.actualNumberOfLeads || 0,
        },
      });
    }
  }, [activeItem]);

  useEffect(() => {
    if (activeItem) {

      const selectedCampaignLanguages = activeItem.propertyCampaignLanguages.map((item) => ({
        lookupItemId: item.languageId,
        lookupItemName: item.languageName,
      }));
      setSelected({ id: 'campaignLanguages', value: selectedCampaignLanguages });

      const selectedBudgetLevelAdmins = activeItem.campaignBudgetLevelAdminDtos.map((item) => ({
        applicationUserId: item.userId,
        fullName: item.userName,
      }));
      setSelected({ id: 'budgetLevelAdmins', value: selectedBudgetLevelAdmins });

      const selectedCampaignTypes = activeItem.campaignTypeChoicesDtos.map((item) => ({
        lookupItemId: item.campaignTypeId,
        lookupItemName: item.campaignTypeName,
      }));
      setSelected({ id: 'campaignTypes', value: selectedCampaignTypes });

      setSelected({ id: 'campaignBranches', value: activeItem.propertyCampaignBranches });

      if (!selected.campaignManagerId && activeItem.campaignManagerId) {
        const campaignManagerItem = {
          applicationUserId: activeItem.campaignManagerId,
          fullName: activeItem.campaignManagerName
        };
        setSelected({ id: 'campaignManagerId', value: campaignManagerItem });
      }

    }
  }, [activeItem, data])

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        className='activities-management-dialog-wrapper'>
        <Spinner isActive={isSubmitLoading} isAbsolute />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(`${translationPath}${(activeItem && 'edit-campaign') || 'add-new-campaign'}`)}
          </DialogTitle>
          <DialogContent>
            <>
              <Tabs
                value={tabIndex}
                onChange={handleTabClick}
                aria-label='icon label tabs'
                textColor='primary'
                classes={{
                  indicator: classes.indicator,
                  scroller: classes.scroller,
                  fixed: classes.fixed,
                }}>
                <Tab
                  classes={{
                    root: classes.root,
                    wrapper: classes.wrapper,
                    labelIcon: classes.labelIcon,
                  }}
                  icon={<span className='mr-2 mdi mdi-format-list-checks ' />}
                  label={t(`${translationPath}Details`)}
                />
                <Tab
                  classes={{
                    root: classes.root,
                    wrapper: classes.wrapper,
                    labelIcon: classes.labelIcon,
                  }}
                  icon={<span className='mr-2 mdi mdi-currency-usd' />}
                  label={t(`${translationPath}Budget&Leads`)}
                />
              </Tabs>
              {tabIndex === 0 && (
                <div className='d-flex'>
                  <div className='w-50 mx-2'>
                    <div className='my-2'>
                      <Inputs
                        idRef='campaignNameRef'
                        labelValue={t(`${translationPath}campaign-name`)}
                        value={state.name}
                        inputPlaceholder={t(`${translationPath}Add-text`)}
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          setState({
                            id: 'name',
                            value: value,
                          });
                        }}
                      />
                    </div>
                    <div className='my-2'>
                      <AutocompleteComponent
                        idRef='mediaDetailsRef'
                        labelValue={t(`${translationPath}media-details`)}
                        selectedValues={selected.mediaDetailsId || []}
                        isDisabled={isLoading.mediaDetails}
                        data={data.mediaDetails || []}
                        displayLabel={(option) =>
                          option.lookupItemName || ''
                        }
                        multiple={false}
                        withoutSearchButton
                        onChange={(event, newValue) => {
                          setState({
                            id: 'mediaDetailsId',
                            value: newValue && newValue.lookupItemId,
                          });
                          setSelected({
                            id: 'mediaDetailsId',
                            value: newValue,
                          });
                          handleMediaTypeValue(newValue);
                        }}
                        isLoading={isLoading.mediaDetails}
                      />
                    </div>
                    <div className='my-3'>
                      <DatePickerComponent
                        idRef='campaignStartDateRef'
                        labelValue={t(`${translationPath}start-date`)}
                        labelClasses='Requierd-Color'
                        placeholder='DD/MM/YYYY'
                        format='YYYY/MM/DD'
                        parentTranslationPath={parentTranslationPath}
                        value={
                          state.campaignStartDate
                            ? state.campaignStartDate
                            : moment().format('YYYY-MM-DDTHH:mm:ss')
                        }
                        minDate={moment().format('YYYY-MM-DDTHH:mm:ss')}
                        onDateChanged={(newValue) => {
                          setState({
                            id: 'campaignStartDate',
                            value: (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null,
                          });

                          const isEndDateBeforeStartDate = moment(state.campaignEndDate).isBefore(
                            newValue
                          );
                          if (isEndDateBeforeStartDate) {
                            setState({
                              id: 'campaignEndDate',
                              value:
                                (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className='my-1'>
                      <AutocompleteComponent
                        idRef='campaignLanguagesRef'
                        labelValue={t(`${translationPath}campaign-languages`)}
                        isDisabled={isLoading.campaignLanguages}
                        selectedValues={selected.campaignLanguages || []}
                        data={data.campaignLanguages || []}
                        chipsLabel={(option) => option.lookupItemName || ''}
                        displayLabel={(option) =>
                          option.lookupItemName || ''
                        }
                        filterOptions={(options) => {
                          const isFind = (id) => selected.campaignLanguages && selected.campaignLanguages.findIndex((w) => w.lookupItemId === id) === -1;
                          return options.filter((w) => isFind(w.lookupItemId));
                        }}
                        multiple={true}
                        withoutSearchButton
                        isWithError
                        onChange={(event, newValue) => {
                          const languageLookupIds =
                            newValue && newValue.map((item) => item.lookupItemId);
                          setState({
                            id: 'campaignLanguages',
                            value: languageLookupIds || [],
                          });
                          setSelected({
                            id: 'campaignLanguages',
                            value: newValue,
                          });
                        }}
                        isLoading={isLoading.campaignLanguages}
                      />
                    </div>
                    <div className='my-3'>
                      <SelectComponet
                        idRef={`CampaignStatusRef`}
                        data={[{
                          value: true,
                          text: 'Active',
                        },
                        {
                          value: false,
                          text: 'In-active',
                        }]}
                        value={state.campaignStatus}
                        labelValue='campaign-status'
                        valueInput='value'
                        textInput='text'
                        onSelectChanged={(value) => {
                          setState({
                            id: 'campaignStatus',
                            value: value,
                          });
                        }}
                        emptyItem={{
                          value: null,
                          text: '',
                        }}
                        translationPathForData={translationPath}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />
                    </div>
                    <div className='my-3'>
                      <AutocompleteComponent
                        idRef='branchRef'
                        multiple
                        isDisabled={isLoading.branches}
                        isLoading={isLoading.branches}
                        labelValue={t(`${translationPath}branchs`)}
                        data={allBranches || []}
                        selectedValues={selected.campaignBranches || []}
                        chipsLabel={(option) => (option && option.branchName) || ''}
                        displayLabel={(option) => (option && option.branchName) || ''}
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        filterOptions={(options) => {
                          const isFind = (id) => selected.campaignBranches && selected.campaignBranches.findIndex((w) => w.branchId === id) === -1;
                          return options.filter((w) => isFind(w.branchId));
                        }}
                        onChange={(event, newValue) => {
                          const languageLookupIds = newValue && newValue.map((item) => item.branchId);
                          setState({
                            id: 'campaignBranches',
                            value: languageLookupIds || [],
                          });
                          setSelected({
                            id: 'campaignBranches',
                            value: newValue,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className='w-50 mx-2'>
                    <div className='my-2'>
                      <AutocompleteComponent
                        idRef='mediaTypesRef'
                        labelValue={t(`${translationPath}media`)}
                        isDisabled={isLoading.mediaTypes}
                        selectedValues={selected.mediaTypeId || []}
                        data={data.mediaTypes || []}
                        chipsLabel={(option) => option.lookupItemName || ''}
                        displayLabel={(option) =>
                          option.lookupItemName || ''
                        }
                        multiple={false}
                        withoutSearchButton
                        onChange={(event, newValue) => {
                          setState({
                            id: 'mediaTypeId',
                            value: newValue && newValue.lookupItemId,
                          });
                          setSelected({
                            id: 'mediaTypeId',
                            value: newValue,
                          });
                          handleMediaDetailsValue(newValue && newValue.lookupItemId);
                        }}
                        isLoading={isLoading.mediaTypes}
                      />
                    </div>
                    <div className='my-2'>
                      <AutocompleteComponent
                        idRef='CampaignManagerRef'
                        labelValue='campaign-manager'
                        selectedValues={selected.campaignManagerId}
                        isDisabled={isLoading.users}
                        labelClasses='Requierd-Color'
                        multiple={false}
                        data={data.users || []}
                        displayLabel={(option) => option.fullName || ''}
                        renderOption={(option) =>
                          ((option.userName || option.fullName) &&
                            `${option.fullName} (${option.userName})`) ||
                          ''
                        }
                        getOptionSelected={(option) => option.applicationUserId === state.campaignManagerId}
                        withoutSearchButton
                        isLoading={isLoading.users}
                        onKeyDown={() => {
                          setSelected({ id: 'campaignManagerId', value: null });
                        }}
                        onInputKeyUp={(e) => {
                          const searchText = e?.target?.value;
                          if (searchTimer.current) clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllUsers(searchText);
                          }, 700);
                        }}
                        isWithError
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setSelected({ id: 'campaignManagerId', value: newValue });
                          setState({
                            id: 'campaignManagerId',
                            value: (newValue && newValue.applicationUserId) || null,
                          });

                        }}
                      />
                    </div>
                    <div className='my-2'>
                      <DatePickerComponent
                        idRef='campaignEndDateRef'
                        labelValue={t(`${translationPath}end-date`)}
                        placeholder='DD/MM/YYYY'
                        format='YYYY/MM/DD'
                        labelClasses='mt-3'
                        parentTranslationPath={parentTranslationPath}
                        value={state.campaignEndDate}
                        minDate={state.campaignStartDate || ''}
                        onDateChanged={(newValue) => {
                          setState({
                            id: 'campaignEndDate',
                            value: (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null,
                          });
                        }}
                      />
                    </div>
                    <div className='mt-3'>
                      <Inputs
                        idRef='mediaLinkRef'
                        labelValue={t(`${translationPath}media-link`)}
                        inputPlaceholder={`${translationPath}Add-text`}
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                        value={state.mediaUrl}
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          setState({
                            id: 'mediaUrl',
                            value: value,
                          });
                        }}
                      />
                    </div>
                    <div className='mt-2'>
                      <AutocompleteComponent
                        idRef='campaignTypeRef'
                        labelValue={t(`${translationPath}campaign-type`)}
                        isDisabled={isLoading.campaignTypes}
                        labelClasses='Requierd-Color'
                        selectedValues={selected.campaignTypes || []}
                        data={data.campaignTypes || []}
                        chipsLabel={(option) => option.lookupItemName || ''}
                        displayLabel={(option) =>
                          option.lookupItemName || ''
                        }
                        filterOptions={(options) => {
                          const isFind = (id) => selected.campaignTypes && selected.campaignTypes.findIndex((w) => w.lookupItemId === id) === -1;
                          return options.filter((w) => isFind(w.lookupItemId));
                        }}
                        multiple={true}
                        withoutSearchButton
                        isWithError
                        onChange={(event, newValue) => {
                          const languageLookupIds =
                            newValue && newValue.map((item) => item.lookupItemId);
                          setState({
                            id: 'campaignTypeIds',
                            value: languageLookupIds || [],
                          });
                          setSelected({
                            id: 'campaignTypes',
                            value: newValue,
                          });
                        }}
                        isLoading={isLoading.campaignTypes}
                      />
                    </div>
                  </div>
                </div>
              )}
              {tabIndex === 1 && (
                <div className='d-flex'>
                  <div className='w-50 mx-2'>
                    <div className='my-2'>
                      <Inputs
                        idRef='budgetAmountRef'
                        labelValue={t(`${translationPath}budget-amount`)}
                        value={state.budgetAmount}
                        labelClasses='Requierd-Color'
                        type='number'
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          const isNumeralValue = !isNaN(+value);
                          if (isNumeralValue) {
                            setState({
                              id: 'budgetAmount',
                              value: +value,
                            });
                          } else {
                            setState({
                              id: 'budgetAmount',
                              value: 0,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className='my-2'>
                      <Inputs
                        idRef='budgetLevelRef'
                        labelValue={t(`${translationPath}budget-level`)}
                        value={state.budgetLevel}
                        type='number'
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          const isNumeralValue = !isNaN(+value);
                          if (+value <= state.budgetAmount) {
                            if (isNumeralValue) {
                              setState({
                                id: 'budgetLevel',
                                value: +value,
                              });
                            } else {
                              setState({
                                id: 'budgetLevel',
                                value: 0,
                              });
                            }
                          }
                        }}
                      />
                    </div>
                    <div className='my-2'>
                      <Inputs
                        idRef='expectedNumberOfLeadsRef'
                        labelValue={t(`${translationPath}Expected-No-of-Leads`)}
                        value={state.expectedNumberOfLeads}
                        type='number'
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          const isNumeralValue = !isNaN(+value);
                          const isIntegerValue = Number.isInteger(+value);
                          if (isNumeralValue && isIntegerValue) {
                            setState({
                              id: 'expectedNumberOfLeads',
                              value: +value,
                            });
                          } else {
                            setState({
                              id: 'expectedNumberOfLeads',
                              value: 0,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className='w-50 mx-2'>
                    <div className='my-2'>
                      <Inputs
                        idRef='spentAmountRef'
                        labelValue={t(`${translationPath}spent-amount`)}
                        value={state.spentAmount}
                        type='number'
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          const isNumeralValue = !isNaN(+value);
                          if (isNumeralValue) {
                            setState({
                              id: 'spentAmount',
                              value: +value,
                            });
                          } else {
                            setState({
                              id: 'spentAmount',
                              value: 0,
                            });
                          }
                        }}
                        isDisabled={activeItem && (activeItem.campaignStatus == false)}
                      />
                    </div>
                    <div className='my-2'>
                      <AutocompleteComponent
                        idRef='budgetLevelAdminsRef'
                        labelValue='budget-level-admins'
                        selectedValues={selected.budgetLevelAdmins || []}
                        isDisabled={isLoading.users}
                        multiple={true}
                        data={data.users || []}
                        chipsLabel={(option) => option.fullName || ''}
                        displayLabel={(option) => option.fullName || ''}
                        renderOption={(option) =>
                          ((option.userName || option.fullName) &&
                            `${option.fullName} (${option.userName})`) ||
                          ''
                        }
                        withoutSearchButton
                        isLoading={isLoading.users}
                        onKeyDown={() => {
                          setSelected({ id: 'budgetLevelAdmins', value: null });
                        }}
                        onInputKeyUp={(e) => {
                          const searchText = e?.target?.value;
                          if (searchTimer.current) clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllUsers(searchText);
                          }, 700);
                        }}
                        isWithError
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          const budgetLevelAdminsIds =
                            newValue && newValue.map((item) => item.applicationUserId);

                          setSelected({ id: 'budgetLevelAdmins', value: newValue });
                          setState({
                            id: 'budgetLevelAdminIds',
                            value: budgetLevelAdminsIds || null,
                          });

                        }}
                      />
                    </div>
                    {activeItem && <div className='my-3'>
                      <Inputs
                        idRef='actualNumberOfLeadsRef'
                        labelValue={t(`${translationPath}actual-number-Of-leads`)}
                        value={state.actualNumberOfLeads}
                        isDisabled
                      />
                    </div>}
                  </div>
                </div>
              )}
            </>
          </DialogContent>
          <DialogActions>
            <ButtonBase onClick={onClose} className='btns theme-solid bg-cancel'>
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase className='btns theme-solid' onClick={saveHandler} type='submit'>
              {t(`${translationPath}save`)}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
