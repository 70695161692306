import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GetAllPropertyUnitModelsAPI } from '../../Services';
import { AutocompleteComponent } from '..';
import { AddModelsUnitsDialog } from '../../Views/Home/FormBuilder/Dialogs/AddModelsUnitsDialog';

export const UnitModelPopoverComponent = ({
  unitModelPopoverAttachedWith,
  onPopoverClose,
  allItems,
  propertyId,
  onValueChanged,
  setData,
  indexV1,
  labelValue,
  idRef,
  allItemsValues,
  itemValue,
  parentTranslationPath , 
  translationPath , 
}) => {

  const [unitModels, setUnitModels] = useState({
    result: [],
    totalCount: 0,
  });

  const [modelsUnitsDialog, setModelsUnitsDialog] = useState(false);

  const getAllUnitModelAndFilesByPropertyId = useCallback(async () => {
    const res = await GetAllPropertyUnitModelsAPI({
      propertyId: propertyId,
      pageIndex: 1,
      pageSize: 100,
    });

    // eslint-disable-next-line prefer-const
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setUnitModels({ result: (res && res.result) || [], });
    }
  }, [propertyId]);



  const updateOtherValuesRelateWithUnitModel = (value) => {

    const unitModelIndex = allItems.findIndex(
      (element) => element.field.id === 'unit_model'
    );


    if (onValueChanged && unitModelIndex !== -1) {
      onValueChanged(value  , 0, 'unit_model');
      onValueChanged(value.area ? parseFloat(value.area) : 0, 0, 'builtup_area_sqft');
      onValueChanged(value.bedroomNo ? parseFloat(value.bedroomNo) : 0, 0, 'bedrooms');
      onValueChanged(value.bathroomNo ? parseFloat(value.bathroomNo) : 0, 0, 'bathrooms');
      onValueChanged(value.maidsRoomNo ? parseFloat(value.maidsRoomNo) : 0, 0, 'maid_rooms');
      onValueChanged(value.primaryData, 0, 'primary_view');
      if (value.propertyUnitType) onValueChanged(value.propertyUnitType, 0, 'unit_type');
      onValueChanged(null, 0, 'secondary_view');
    }
    else if (setData && unitModelIndex !== -1) {
      if (unitModelIndex !== -1) {
        setData('unit_model', undefined);

        setTimeout(() => {
          setData('unit_model', value || {});
        }, 100);



      }

      setData('builtup_area_sqft', value.area ? parseFloat(value.area) : '0');
      setData('builtup_area-sqft', value.area);
      setData('bedrooms', value.bedroomNo ? parseFloat(value.bedroomNo) : '0');
      setData('bathrooms', value.bathroomNo ? parseFloat(value.bathroomNo) : '0');
      setData('maid_rooms', value.maidsRoomNo ? parseFloat(value.maidsRoomNo) : '0');
      if (value.propertyUnitType) setData('unit_type', value.propertyUnitType || {});
      setData('primary_view', value.primaryData || {});
      setData('secondary_view', {});

    }

    if (onPopoverClose) setTimeout(() => onPopoverClose(), 100);
  };


  useEffect(() => {
    if (propertyId) getAllUnitModelAndFilesByPropertyId();
  }, [propertyId, getAllUnitModelAndFilesByPropertyId]);

  return (
    <>
      <AutocompleteComponent
        idRef={idRef}
        className='px-2'
        selectedValues={(allItemsValues && allItemsValues && allItemsValues.unit_model) }
        labelValue={labelValue}
        data={unitModels.result || []}
        multiple={false}
        displayLabel={(option) =>
          (option && option.propertyUnitModelName) || ''}
        chipsLabel={(option) => (option && option.propertyUnitModelName) || ''}
        withoutSearchButton
        buttonOptions={{
          className: 'btns-icon theme-solid bg-blue-lighter',
          iconClasses: 'mdi mdi-plus',
          isRequired: false,
          onActionClicked: () => {
            setModelsUnitsDialog(true);
          },
        }}
        onChange={(event, newValue) => {
          if(newValue)
            updateOtherValuesRelateWithUnitModel(newValue);

          else if(onValueChanged)
          onValueChanged(null , 0, 'unit_model');

          else if(setData)
          setData('unit_model' , {});

        }}

      />
      {modelsUnitsDialog && (
        <AddModelsUnitsDialog
          open={modelsUnitsDialog}
          propertyName={(allItemsValues && allItemsValues.property_name)}
          close={() => {
            setModelsUnitsDialog(false);
            getAllUnitModelAndFilesByPropertyId();

          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}

    </>
  );
};
const convertJsonItemShape = PropTypes.shape({
  data: PropTypes.shape({
    enum: PropTypes.instanceOf(Array),
    CommunicationType: PropTypes.string,
    searchKey: PropTypes.string,
    title: PropTypes.string,
    regExp: PropTypes.string,
    dependOn: PropTypes.string,
    uiType: PropTypes.string,
    defaultCountryCode: PropTypes.string,
    minNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isReadonly: PropTypes.bool,
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
    lookup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    controlType: PropTypes.string, // this type is not sure is string
    multi: PropTypes.oneOf(['true', 'false']),
    lookupItem: PropTypes.number,
    items: PropTypes.shape({
      enum: PropTypes.instanceOf(Array),
    }),
  }),
  field: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    FieldType: PropTypes.string,
    isRequired: PropTypes.bool,
  }),
});
UnitModelPopoverComponent.propTypes = {
  unitModelPopoverAttachedWith: PropTypes.instanceOf(Object),
  onPopoverClose: PropTypes.func.isRequired,
  allItems: PropTypes.arrayOf(convertJsonItemShape).isRequired,
  propertyId: PropTypes.number.isRequired,
  onValueChanged: PropTypes.func,
  setData: PropTypes.func,
  indexV1: PropTypes.number,
};
UnitModelPopoverComponent.defaultProps = {
  unitModelPopoverAttachedWith: undefined,
  onValueChanged: undefined,
  setData: undefined,
  indexV1: undefined,
};
