import { config } from '../config';
import { HttpServices } from '../Helper';

export const GetAllAgentsServices = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/Agents/GetAllAgents`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAgentById = async (agentId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAgentById?agentId=${agentId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateAgentById = async (agentId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Agents/UpdateAgentInfo?agentId=${agentId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllListingAgentsServices = async ({ pageSize, pageIndex, search }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAllListingAgents?pageIndex=${pageIndex + 1
    }&pageSize=${pageSize}&search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllLeaseOrSaleAgentsServices = async ({ pageSize, pageIndex, search }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAllLeaseOrSaleAgents?pageIndex=${pageIndex + 1
    }&pageSize=${pageSize}&search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllSaleAgentsServices = async ({ pageSize, pageIndex, search }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAllSaleAgents?pageIndex=${pageIndex + 1
    }&pageSize=${pageSize}&search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllLeaseAgentsServices = async ({ pageSize, pageIndex, search }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAllLeaseAgents?pageIndex=${pageIndex + 1
    }&pageSize=${pageSize}&search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAgentsForContactLeads = async (contactId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAgentsForContactLeads?contactId=${contactId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAgentsInRotationLog = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/Agents/GetAllAgentsInRotationLog`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateAgentInRotationStatus = async ({agentId, isInRotation}) => {
  const queryList = [];
  queryList.push(`isInRotation=${isInRotation}`);
  
  if (agentId) queryList.push(`agentId=${agentId}`);

  const result = await HttpServices.put(`${config.server_address}/CrmDfm/Agents/UpdateAgentInRotationStatus?${queryList.join('&')}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateBulkAgentsInRotationStatus = async ({agentsId, isInRotation}) => {
  const queryList = [];
  queryList.push(`isInRotation=${isInRotation}`);
  

  const result = await HttpServices.put(`${config.server_address}/CrmDfm/Agents/UpdateBulkAgentsInRotationStatus?${queryList.join('&')}`,
   agentsId)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
