import React from 'react';
import './PageHeading.scss';
import { useTranslation } from 'react-i18next';

export const PageHeading = ({parentTranslationPath, translationPath, headerTitle, subTitle }) => {

    const { t } = useTranslation(parentTranslationPath);
  
  return (
    <div className='PageHeading mb-4'>
      <h5 className='b-600 m-0 mb-3 fz-20'>{t(`${translationPath}${headerTitle}`)}</h5>
      <p className='fz-12 ml-1 text-tertiary'>{t(`${translationPath}${subTitle}`)}</p>
    </div>
  );
};

