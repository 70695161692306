import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TableColumnsFilterComponent, Tables } from '../../../../../Components';
import {
  ContactPreferenceEnum,
  TableActions,
  FormsIdsEnum,
  ColumnsFilterPagesEnum,
  TableFilterTypesEnum,
  HighlightRowKeys , 
} from '../../../../../Enums';
import {
  GlobalHistory, GlobalTranslate, showError, WhatsAppMessage , returnPropsByPermissions  , 
} from '../../../../../Helper';
 import { ContactsPermissions  } from '../../../../../Permissions';
import { ActiveItemActions } from '../../../../../store/ActiveItem/ActiveItemActions';
import { ContactsTableHeaderData } from './ContactsTableHeaderData';
import { GetAllFormFieldsByFormId } from '../../../../../Services';
import { TableColumnsFilterActions } from '../../../../../store/TableColumnsFilter/TableColumnsFilterActions';
import { ContactsActionDialogsComponent } from '../ContactsActionDialogsComponent/ContactsActionDialogsComponent';
import { ActivitiesManagementDialog } from '../../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog' ; 
import { useTranslation } from 'react-i18next';

export const ContactsTableComponent = ({
  filter,
  pathName,
  onRowClick,
  isOneSelect,
  checkedCards,
  setCheckedCards,
  checkedCardsIds,
  onPageSizeChanged,
  setCheckedCardsIds,
  onPageIndexChanged,
  detailsContactsList,
  activeSelectedAction,
  contactTableFilter,
  onFilterValuesChanged,
  parentTranslationPath,
  translationPath,
  setActiveTab,
  isWithCheckboxColumn,
  setOrderBy,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  displyOpenFileButton,
  localStorageKey,
  checkIfSensitiveField,
}) => {
  const dispatch = useDispatch();
  const [allFormFields, setAllFormFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const [tableColumns, setTableColumns] = useState([]);
  const [isOpenContactsActionDialog, setisOpenContactsActionDialog] = useState(false);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [addActivity, setAddActivity] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [detailedCardAction, setdetailedCardAction] = useState(() => ({
    actionEnum: '',
    item: '',
  }));
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    ContactsTableHeaderData.filter((item) => item.isDefaultFilterColumn).map((column) => column.id)
  );
  // const loginResponse = useSelector((state) => state.login.loginResponse);
  const tableColumnsFilterResponse = useSelector((state) => state.TableColumnsFilterReducer);
  const [defaultPrefernces, setDefaultPrefernces] = useState([
    {
      enum: TableActions.openFile.key,
    },
    {
      enum: TableActions.addActivity.key,
    },
  ]);
  const [activePrefernces, setActiveprefernces] = useState(defaultPrefernces);
  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      // Contacts-CRM/contact-profile-edit
      event.stopPropagation();
      event.preventDefault();
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (actionEnum === TableActions.whatsappSolid.key) {
        const el = document.createElement('a');
        if (item?.mobile?.phone) {
          el.href = WhatsAppMessage(item?.whatsapp_mobile?.phone || item.mobile.phone);
          el.target = 'blank';
          el.click();
        } else
          showError(GlobalTranslate.t('Shared:Failure-Open-WhatsApp'));
      } else if (actionEnum === TableActions.openFile.key) {
        if (pathName === 'contact-sales') {
          GlobalHistory.push(
            `/home/contact-sales/contact-profile-edit?formType=${item.userTypeId}&id=${item.id}`
          );
        } else if (pathName === 'contact-lease') {
          GlobalHistory.push(
            `/home/contact-lease/contact-profile-edit?formType=${item.userTypeId}&id=${item.id}`
          );
        } else if (pathName === 'Contacts-CRM') {
          if (localStorage.getItem('parentContact')) localStorage.removeItem('parentContact');
          localStorage.setItem('parentContact', JSON.stringify(item));
          GlobalHistory.push(
            `/home/Contacts-CRM/contact-profile-edit?formType=${item.userTypeId}&id=${item.id}`
          );
        } else if (pathName === 'Contacts-CRM/contact-profile-edit') {
          setActiveTab(0);
          GlobalHistory.push(
            `/home/Contacts-CRM/contact-profile-edit?formType=${item.userTypeId}&id=${item.id}`
          );
        }
      } 
       else if (actionEnum === TableActions.smsSolid.key ||  actionEnum === TableActions.emailSolid.key) {
        setdetailedCardAction({
          actionEnum,
          item,
        });
        setisOpenContactsActionDialog(true);
      }
      else if (actionEnum === TableActions.addActivity.key) {
        setActiveItem(item);
        setAddActivity(true); 
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, pathName]
  );
  const getIsSelected = useCallback(
    (row) => checkedCards && checkedCards.findIndex((item) => item.id === row.id) !== -1,
    [checkedCards]
  );
  const getIsDisabled = useCallback(
    (row) =>
      (isOneSelect && checkedCardsIds && checkedCardsIds.length === 9 && !getIsSelected(row)) ||
      (checkedCardsIds && checkedCardsIds.length > 9 && !getIsSelected(row)) ||
      (checkedCards && checkedCards[0] && checkedCards[0].userTypeId !== row?.userTypeId),
    [checkedCards, checkedCardsIds, getIsSelected, isOneSelect]
  );
  const onSelectClicked = useCallback(
    (row) => {
      const isObsoleteContact = checkedCardsIds && checkedCardsIds.length !== 0;
      const isContactWithTransaction = (isObsoleteContact && row.isWithTransaction === 1 && !getIsSelected(row))

      const itemIndex = checkedCardsIds ? checkedCardsIds.findIndex((item) => item === row.id) : -1;
      if (itemIndex !== -1) {
        checkedCardsIds.splice(itemIndex, 1);
        setCheckedCards((items) => {
          const elementIndex = items.findIndex((item) => item.id === row.id);
          if (elementIndex !== -1) items.splice(elementIndex, 1);
          return [...items];
        });
      } else if (!isContactWithTransaction) {
        checkedCardsIds.push(row.id);
        setCheckedCards((items) => {
          items.push(row);
          return [...items];
        });
      } else if (isContactWithTransaction) showError(`Absolete Contacts Can't Have Transactions`);

      setCheckedCardsIds(checkedCardsIds);
    },
    [checkedCardsIds, setCheckedCards, setCheckedCardsIds]
  );
  const focusedRowChanged = useCallback(
    (rowIndex, item) => {
      if (rowIndex !== -1 && onRowClick) onRowClick(item, rowIndex);
      const currentActiveActions = defaultPrefernces;


      item && item.contactPreference.map((el) => {
        const index = Object.values(ContactPreferenceEnum).findIndex(
          (subItem) => (subItem.key === el.lookupItemId)
        );
        const actionEnumKey = Object.values(ContactPreferenceEnum)[index].actionEnumKey;
        const actionValue = Object.values(ContactPreferenceEnum)[index].value;

        const isItemExist = currentActiveActions.find((e) => e.enum === actionEnumKey)
        if (index !== -1 && !isItemExist && checkIfSensitiveField && checkIfSensitiveField(actionValue, item, item.contact_type_id)) {
          currentActiveActions.push({
            enum: actionEnumKey,
          });
        }
      });

      // if (currentActiveActions.length > 5) {
      //   const whatsAppIndex = currentActiveActions.findIndex(
      //     (subItem) => subItem.enum === 'phoneSolid'
      //   );
      //   if (whatsAppIndex !== -1) {
      //     currentActiveActions.splice(whatsAppIndex, 1);
      //     currentActiveActions.push({
      //       enum: 'dotsHorizontal',
      //     });
      //   }
      // }
      setActiveprefernces(currentActiveActions);
    },
    [defaultPrefernces, onRowClick]
  );
  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsLoading(true);
    Promise.all([
      await GetAllFormFieldsByFormId(FormsIdsEnum.contactsIndividual.id),
      await GetAllFormFieldsByFormId(FormsIdsEnum.contactsCorporate.id),
    ])
      .then((result) => {
        if (Array.isArray(result[0]) && Array.isArray(result[1])) {
          const concantinateFields = result[0]
            .concat(result[1])
            .filter(
              (field, index, array) =>
                array.findIndex((element) => element.formFieldKey === field.formFieldKey) === index
            );
          const list = concantinateFields.filter(e =>
            // e.formFieldName !== 'mobile' &&
            e.formFieldName !== 'company_logoimage' &&
            e.formFieldName !== 'contact_image' &&
            e.formFieldName !== 'contact_classifications' &&
            e.formFieldName !== 'contact_preference' &&
            e.formFieldName !== 'map' &&
            e.formFieldName !== 'contacts_person'
          ).map((field) => ({
            ...field , 
            id: field.formFieldId || null,
            key: field.formFieldKey || null,
            isDate: field.uiWidgetType === 'alt-date' || false,
            label: (field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
            input: field.displayPath || '',
            isNumber:
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size'
            ,
            isSortable: true,
            searchableKey: field.searchableKey ,
          }));
          setAllFormFields(list);
        } else setAllFormFields([]);
        setIsLoading(false);
      })
      .catch(() => {
        setAllFormFields([]);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);
  useEffect(() => {
    if (
      tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum.contacts.key]
    ) {
      setSelectedTableFilterColumns(
        tableColumnsFilterResponse[ColumnsFilterPagesEnum.contacts.key]
      );
    }
  }, [tableColumnsFilterResponse]);
  useEffect(() => {
    setTableColumns([
      ...ContactsTableHeaderData.filter(
        (item) => selectedTableFilterColumns.findIndex((element) => element === item.id) !== -1
      ),
      ...allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns.findIndex((element) => element === item.formFieldId) !== -1
        )
        .map((field) => ({
          id: field.formFieldId || null,
          key: field.formFieldKey || null,
          isDate: field.uiWidgetType === 'alt-date' || false,
          label: (field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
          input: field.displayPath || '',
          isNumber:
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size'
          ,
          isSortable: true,
          searchableKey: field.searchableKey,
        })),
    ]);
    
  }, [allFormFields, selectedTableFilterColumns , contactTableFilter]);

  useEffect(() => {
    setTableFilterData(
      tableColumns.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.isNumber && TableFilterTypesEnum.numberInput.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        searchableKey: column.searchableKey || column.input,
        displayPath:
          (column.key && column.input) ||
          (column.fieldKey &&
            allFormFields &&
            allFormFields.findIndex((item) => item.formFieldKey === column.fieldKey) !== -1 &&
            allFormFields.find((item) => item.formFieldKey === column.fieldKey).displayPath)
          || undefined,
      }))
    );
  }, [allFormFields, tableColumns]);


  useEffect(() => {
    if (sortBy)
      setOrderBy((item) => ({ ...item, filterBy: sortBy.filterBy && sortBy.filterBy[0].toLowerCase() + sortBy.filterBy.slice(1), orderBy: sortBy.orderBy, fieldType: sortBy.fieldType }));
  }, [sortBy ]);




  useEffect(() => {
    let list = [];
    if (displyOpenFileButton)
      list.push({ enum: TableActions.openFile.key });
    if(returnPropsByPermissions(ContactsPermissions.AddNewActivity.permissionsId))
      list.push({ enum: TableActions.addActivity.key });

     setDefaultPrefernces(list);
  }, [displyOpenFileButton]);

 
  return (
    <div className='w-100 px-3'>
      
      <TableColumnsFilterComponent
        columns={ContactsTableHeaderData.concat(
          allFormFields.filter(
            (item) =>
              ContactsTableHeaderData.findIndex(
                (element) => element.fieldKey === item.formFieldKey
              ) === -1
          )
        ).map((item) => ({
          key: item.formFieldId || item.id,
          value: (item.formFieldTitle && item.formFieldTitle.replace('*', '')) || item.label,
        }))}
        isLoading={isLoading}
        selectedColumns={selectedTableFilterColumns}
        onSelectedColumnsChanged={(newValue) => {
          setSelectedTableFilterColumns(newValue);
          let localTableColumnsFilterResponse = tableColumnsFilterResponse;
          if (localTableColumnsFilterResponse)
            localTableColumnsFilterResponse[ColumnsFilterPagesEnum.contacts.key] = newValue;
          else {
            localTableColumnsFilterResponse = {
              [ColumnsFilterPagesEnum.contacts.key]: newValue,
            };
          }
          dispatch(
            TableColumnsFilterActions.TableColumnsFilterRequest(localTableColumnsFilterResponse)
          );
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath=''
      />
      <Tables
        isWithCheckboxColumn={isWithCheckboxColumn}
        data={detailsContactsList.result}
        HideSellectAll
        TotalSelect={(checkedCardsIds&&checkedCardsIds.length )||0 }
        selectAllOptions={
          (activeSelectedAction === 'merge' && {
            selectedRows: checkedCardsIds,
            getIsSelected,
            disabledRows: [],
            getIsDisabled,
            onSelectClicked,
          }) ||
          undefined
        }
        headerData={tableColumns}
        defaultActions={activePrefernces}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        parentTranslationPath={parentTranslationPath}
        focusedRowChanged={focusedRowChanged}
        totalItems={detailsContactsList ? detailsContactsList.totalCount : 0}
        filterValues={contactTableFilter}
        onFilterValuesChanged={onFilterValuesChanged}
        filterData={tableFilterData}
        isWithFilter
        setSortBy={setSortBy}
        externalPopoverComponent={(
          <ButtonBase
            className={TableActions.whatsappSolid.buttonClasses}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <span className={TableActions.whatsappSolid.icon} />
          </ButtonBase>
        )}
        setIsClearFiltersClicked={setIsClearFiltersClicked}
        isClearFiltersClicked={isClearFiltersClicked}
        isLoading={isLoading}
        localStorageKey={localStorageKey}
        highlightedRowKey={HighlightRowKeys.Contacts.key}
      />

      <ContactsActionDialogsComponent
        isOpen={isOpenContactsActionDialog}
        isOpenChanged={() => setisOpenContactsActionDialog(false)}
        actionEnum={detailedCardAction.actionEnum}
        item={detailedCardAction.item}
        translationPath=''
        parentTranslationPath='ContactsView'
      />
      {
        addActivity && (
          <ActivitiesManagementDialog
            open={addActivity}
            onSave={() => {
              setAddActivity(false) ; 
            }}
            close={() => {
              setAddActivity(false) ; 

            }}
            actionItemId={activeItem?.id}
            translationPath={''}
            parentTranslationPath={'ContactProfileManagementView'}
           
          />
        )
      } 
    </div>
  );
};
ContactsTableComponent.propTypes = {
  onRowClick: PropTypes.func,
  isWithCheckboxColumn: PropTypes.bool,
  isOneSelect: PropTypes.bool,
  pathName: PropTypes.string.isRequired,
  setCheckedCards: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  setCheckedCardsIds: PropTypes.func.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onFilterValuesChanged: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  checkedCards: PropTypes.instanceOf(Object).isRequired,
  checkedCardsIds: PropTypes.instanceOf(Object).isRequired,
  detailsContactsList: PropTypes.instanceOf(Object).isRequired,
  activeSelectedAction: PropTypes.instanceOf(Object).isRequired,
  contactTableFilter: PropTypes.instanceOf(Object),
  setActiveTab: PropTypes.func.isRequired,
  setIsClearFiltersClicked: PropTypes.bool,
  isClearFiltersClicked: PropTypes.bool,
  displyOpenFileButton: PropTypes.bool,
  checkIfSensitiveField: PropTypes.func,
};
ContactsTableComponent.defaultProps = {
  onRowClick: undefined,
  isWithCheckboxColumn: false,
  contactTableFilter: undefined,
  isOneSelect: false,
  displyOpenFileButton: false,
  checkIfSensitiveField: undefined,

};
