/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ButtonBase } from '@material-ui/core';
import { lookupItemsGet } from '../../../../../../../Services';
import { UnitsFilterStatusEnum } from '../../../../../../../Enums';

export default function OpationTemplateBuilder({
  resonse, SetOpationTemplate, systemTemplateCategoryDto, setMain, SetsystemTemplateCategoryId, activeItem, OpationTemplateBuilderState, ParamsHandler, OpationState, Params,
  setIsUserTemplte, IsUserTemplte, activeItemedit

}) {
  const [UnitOperationType, setUnitOperationType] = React.useState(false);
  const getAllPeriodsOfStay = useCallback(async () => {
    const res = await lookupItemsGet({
      lookupTypeName: 'UnitOperationType',
    });
    if (!(res && res.status && res.status !== 200)) setUnitOperationType(res && res.result || []);
    else setUnitOperationType([]);
  }, []);
  const hasOnlyValue6 = [activeItemedit].every((item) => Array.isArray(item.templateExtraCategoryId) && item.templateExtraCategoryId.length === 1 && item.templateExtraCategoryId[0] === 6);
  const Sale = ((UnitOperationType && UnitOperationType.filter((item) => item && item.lookupItemName && item.lookupItemName === 'Sale')) || []);
  const Rent = ((UnitOperationType && UnitOperationType.filter((item) => item && item.lookupItemName && item.lookupItemName === 'Rent')) || []);
  const [state, setState] = React.useState(
    {
      SaleSale: false,
      SaleDraft: false,
      LeasedDraft: false,
      LeasedLeased: false,
      LeaseAvailable: false,
      SaleAvailable: false,
      SaleReserved: false,
      LeaseReserved: false,
    }
  );
  const handleChange = (event, checked, val, lookupItemId) => {
    setState({ ...state, [event.target.name]: { checked: event.target.checked, unitStatusId: val, unitOperationTypeId: lookupItemId } });
  };

  const [ActivityTemplates, setActivityTemplates] = React.useState(false);
  const [UnitTemplates, setUnitTemplates] = React.useState(false);
  const [OnOFF, setOnOFF] = React.useState('');
  const [OnOFF2, setOnOFF2] = React.useState('');
  const handleChange1 = (item) => {
    setUnitTemplates(!UnitTemplates);
    if (!UnitTemplates) {
      SetsystemTemplateCategoryId(item);
      SetOpationTemplate(item, 'add');
    } else
      SetOpationTemplate(item, 'Delete');
  };

  const handleChange2 = (item) => {
    setActivityTemplates(!ActivityTemplates);

    if (!ActivityTemplates) {
      SetsystemTemplateCategoryId(item);
      SetOpationTemplate(item, 'add');
    } else
      SetOpationTemplate(item, 'Delete');
  };

  useEffect(() => {
    getAllPeriodsOfStay();
  }, []);

  useEffect(() => {
    setMain(state);
  }, [state]);

  const UnitHandfunction = (item) => {
    setOnOFF(true);
    setOnOFF2(false);
    SetsystemTemplateCategoryId(item);
    setUnitTemplates(true);

    SetOpationTemplate(item, 'add');
  };

  const ActivtyHandfunction = (item) => {
    setOnOFF2(true);
    setOnOFF(false);
    setUnitTemplates(false);
    SetsystemTemplateCategoryId(item);
    SetOpationTemplate(item, 'add');
  };
  useEffect(() => {
    resonse.map((item) => ((item && item.systemTemplateCategoryId === systemTemplateCategoryDto.systemTemplateCategoryId && item.templateCategoryName === 'Unit Templates') &&
      UnitHandfunction(item) ||
      (item && item.systemTemplateCategoryId === systemTemplateCategoryDto.systemTemplateCategoryId && item.templateCategoryName !== 'Unit Templates') && ActivtyHandfunction(item)));
  }, [systemTemplateCategoryDto]);

  useEffect(() => {
    if (activeItem && OpationTemplateBuilderState)
      setState(OpationTemplateBuilderState);
  },
    [activeItem.systemTemplateUnitStatuses]);

  useEffect(() => {
    if (OnOFF === false) resonse.map((item) => item.categoryType === 'Main' && item.templateCategoryName === 'Unit Templates' && (SetOpationTemplate(item, 'Delete') && handleChange1(item))); else if (OnOFF === true)
      resonse.map((item) => item.categoryType === 'Main' && item.templateCategoryName !== 'Unit Templates' && (SetOpationTemplate(item, 'Delete') && handleChange2(item)));
  },
    [OnOFF]);

  return (
    <div className='d-flex' style={{ height: '7rem' }}>
      <div className='display-display-3'>
        {IsUserTemplte === false && resonse.map((item) => item.categoryType === 'Main' && item.templateCategoryName === 'Unit Templates' && ((
          (OpationState === 'add' && (
            <div>
              <ButtonBase
                className='btns theme-solid mb-2 '
                disabled={OnOFF}
                onClick={() => {
                  setOnOFF(true);
                  setOnOFF2(false);
                  ParamsHandler(item);
                  SetsystemTemplateCategoryId(item);
                  setUnitTemplates(true);
                  SetOpationTemplate(item, 'add');
                }}
              >
                {' '}
                <span className='px-1' />
                {item.templateCategoryName}
              </ButtonBase>
            </div>
          ) || (OpationState === 'edit' && systemTemplateCategoryDto.templateCategoryName === 'Unit Templates' && (
            <div>
              <ButtonBase
                className='btns theme-solid mb-2 '
                disabled
              >
                {' '}
                <span className='px-1' />
                {item.templateCategoryName}
              </ButtonBase>
            </div>
          )
            )
          ))))}
        {IsUserTemplte === false && resonse.map((item) => item.categoryType === 'Main' && item.templateCategoryName !== 'Unit Templates' && ((
          (
            (OpationState === 'add' && (
              <div>
                <ButtonBase
                  className='btns theme-solid mb-2 '
                  disabled={OnOFF2}
                  onClick={() => {
                    setOnOFF2(true);
                    setOnOFF(false);
                    setUnitTemplates(false);
                    ParamsHandler(item);
                    SetsystemTemplateCategoryId(item);
                    SetOpationTemplate(item, 'add');
                    // (handleChange2(item));
                  }}
                >
                  {' '}
                  <span className='px-1' />
                  {item.templateCategoryName}
                </ButtonBase>
              </div>
            ) || (OpationState === 'edit' && systemTemplateCategoryDto.templateCategoryName === 'Activity Templates' && (

              <ButtonBase
                className='btns theme-solid mb-2 '
                disabled
              >
                <span className='px-1' />
                {item.templateCategoryName}
              </ButtonBase>

            ))
            ))
        )))}
        {activeItemedit && IsUserTemplte === false && (<div />) || (
          <ButtonBase
            className='btns theme-solid mb-2'
            disabled={hasOnlyValue6 || activeItemedit && IsUserTemplte == false}
            onClick={() => {
              setIsUserTemplte(!IsUserTemplte);
            }}
          >
            <span className='px-1' />
            {' User Templates'}

          </ButtonBase>

        )}
      </div>
      <div className='box_sc_conteraner d-flex'>
        {UnitTemplates && !IsUserTemplte && (
          <>
            <div>
              <FormGroup row>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state && state.SaleSale && state.SaleSale.checked || false}
                      onChange={(event, checked) => handleChange(event, checked, 6, Sale[0].lookupItemId)}
                      name='SaleSale'
                      color='primary'
                    />
                  )}
                  label='SaleSale'
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state && state.SaleDraft && state.SaleDraft.checked || false}
                      onChange={(event, checked) => handleChange(event, checked, 10, Sale[0].lookupItemId)}
                      name='SaleDraft'
                      color='primary'
                    />
                  )}
                  label='SaleDraft'
                />
                {' '}
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state && state.SaleAvailable && state.SaleAvailable.checked || false}
                      onChange={(event, checked) => handleChange(event, checked, 1, Sale[0].lookupItemId)}
                      name='SaleAvailable'
                      color='primary'
                    />
                  )}
                  label='SaleAvailable'
                />
                {' '}
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state && state.SaleReserved && state.SaleReserved.checked || false}
                      onChange={(event, checked) => handleChange(event, checked, 5, Sale[0].lookupItemId)}
                      name='SaleReserved'
                      color='primary'
                    />
                  )}
                  label='SaleReserved'
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup row>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state && state.LeasedLeased && state.LeasedLeased.checked || false}
                      onChange={(event, checked) => handleChange(event, checked, 3, Rent[0].lookupItemId)}
                      name='LeasedLeased'
                      color='primary'
                    />
                  )}
                  label='LeasedLeased'
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state && state.LeasedDraft && state.LeasedDraft.checked || false}
                      onChange={(event, checked) => handleChange(event, checked, 10, Rent[0].lookupItemId)}
                      name='LeasedDraft'
                      color='primary'
                    />
                  )}
                  label='LeasedDraft'
                />
                {' '}
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state && state.LeaseAvailable && state.LeaseAvailable.checked || false}
                      onChange={(event, checked) => handleChange(event, checked, 1, Rent[0].lookupItemId)}
                      name='LeaseAvailable'
                      color='primary'
                    />
                  )}
                  label='LeaseAvailable'
                />
                {' '}
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state && state.LeaseReserved && state.LeaseReserved.checked || false}
                      onChange={(event, checked) => handleChange(event, checked, 2, Rent[0].lookupItemId)}
                      name='LeaseReserved'
                      color='primary'
                    />
                  )}
                  label='LeaseReserved'
                />
              </FormGroup>
            </div>
          </>
        ) || ''}
      </div>
    </div>

  );
}
