import React, {
  memo, useState, useEffect, useCallback, useRef
} from 'react';
import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTranslation } from 'react-i18next';
import {
  toCamelCase, GlobalHistory, GlobalTranslate, getDownloadableLink, GlobalRerender, HttpServices
} from '../../../../Helper';
import { ImportFileNotificationHub, ProcessStatusMethod } from '../../../../Hubs';
import { GetMyImportProcess } from '../../../../Services/File';
import { CrmDfmNotificationHub, CrmDfmNotification } from '../../../../Hubs/CrmDFMNotificationHub';
import { GetMyNotifications, MarkNotificationAsRead } from '../../../../Services';
import { LoadableImageComponant, PermissionsComponent } from '../../../../Components';
import { ContactTypeEnum, LoadableImageEnum } from '../../../../Enums';
import { ImportDetailsPermissions } from '../../../../Permissions';
import { Button, Tooltip } from '@material-ui/core';
import file_notf from '../../../../assets/images/defaults/file_notf.png';
import i18next from 'i18next';

const translationPath = 'notificationsView.';
let notificationContext;
let AlertContext;
const notificationsIcons = [
  {
    type: 1,
    classes: 'icons circle-ripple i-cloud',
  },
  {
    type: 2,
    classes: 'icons circle-ripple bg-green-light i-upload',
  },
  {
    type: 3,
    classes: 'circle-ripple bg-warning-light mdi mdi-close',
  },
  {
    type: 4,
    classes: 'icons circle-ripple bg-info i-upload',
  },
  {
    type: 5,
    classes: 'icons circle-ripple bg-warning-light i-shield',
  },
];

const localStoragedat = JSON.parse(localStorage.getItem('ActionsReadDataStorage')); // temp  Action
// const localStorageNotificationActivityListener = JSON.parse(localStorage.getItem('NotificationActivityListener'));  const [NotificationActivity, setNotificationActivity] = useState(localStorageNotificationActivityListener || [])
const NotificationNumberlocal = JSON.parse(localStorage.getItem('NotificationNumber'));

const getProccessName = (importProcceseType) => {
  if (importProcceseType === 1) return `${translationPath}contacts`;
  if (importProcceseType === 7) return `${translationPath}leads`;
  if (importProcceseType === 4) return `${translationPath}properties`;
  if (importProcceseType === 5) return `${translationPath}units`;
  if (importProcceseType === 6) return `${translationPath}companies`;
  return 'N/A';
};
const getHeaderName = (importProcceseStatus) => {
  if (importProcceseStatus === 1) return `${translationPath}reading-file`;
  if (importProcceseStatus === 2) return `${translationPath}reading-file-failed`;
  if (importProcceseStatus === 3) return `${translationPath}finish-reading-and-start-importing`;
  if (importProcceseStatus === 4) return `${translationPath}imported-successfully`;
  return 'N/A';
};
const getAction = (actionItem) => {
  const { t } = GlobalTranslate;
  const object = {
    id: actionItem.importProcceseId,
    processName: null,
    createdOn: null,
    type: null,
    header: null,
    text: null,
    withProgress: false,
  };

  object.processName = GlobalTranslate.t(`HeaderView:${getProccessName(actionItem.importProcceseType)}`);
  object.header = GlobalTranslate.t(`HeaderView:${getHeaderName(actionItem.importProcceseStatus)}`);
  if (actionItem.importProcceseStatus === 1) {
    object.text = `${object.header} ${actionItem.fileName}`;
    object.type = 4;
    object.withProgress = true;
    object.createdOn = actionItem.createdOn
  } else if (actionItem.importProcceseStatus === 2) {
    object.text = GlobalTranslate.t(`HeaderView:${translationPath}error-while-reading-file`) + actionItem.fileName;
    object.type = 3;
    object.createdOn = actionItem.createdOn
  } else if (actionItem.importProcceseStatus === 3) {
    object.text = `${object.header} ${object.processName}`;
    object.type = 4;
    object.createdOn = actionItem.createdOn
    object.withProgress = true;
  } else if (actionItem.importProcceseStatus === 4) {
    object.text = `${object.processName} ${object.header}`;
    object.type = 2;
    object.createdOn = actionItem.createdOn
  }
  return object;
};

const NotificationsView = memo(({
  isOpen, top, getCurrentNotificationNumber, loginResponse, NewNotification
}) => {
  const { t } = useTranslation('HeaderView');
  const [NotificationNumber, setNotificationNumber] = useState(NotificationNumberlocal || 0);
  const [prevPage, setPrevPage] = useState(0);
  const [currPage, setCurrPage] = useState(10);
  const [currentTab, setCurrentTab] = useState(0);
  const listInnerRef = useRef();
  const listInnerImportentRef = useRef();
  const [notificationItems, setNotificationsItems] = useState([]);
  const [notificationItemsimportant, setNotificationsItemsimportant] = useState([]);
  const [ActionsReadData, setActionsReadData] = useState(localStoragedat || []);
  const [lastList, setLastList] = useState(false);
  const [actions, setActions] = useState([]);

  const actionsHandler = useCallback((data) => {
    data.map((item) => {
      setActions((elements) => {
        const localActions = elements;
        const itemIndex = elements.findIndex((element) => element.id === item.importProcceseId);
        if (itemIndex === -1) return [{ ...getAction(item) }, ...elements];
        localActions[itemIndex] = getAction(item);
        return [...localActions];
      });
      return null;
    });
  }, []);

  const getDefaultContactImage = useCallback(
    (contactType) => ContactTypeEnum[contactType] && ContactTypeEnum[contactType].defaultImg,
    []
  );
  const getIcon = (type) => notificationsIcons.find((item) => item.type === type).classes;

  const goToAlertActions = useCallback(
    (item) => () => {
      saveHandlerMarkNotificationAsRead(item && item.notificationHistoryId, true, currPage, currentTab)
      // const found = (NotificationActivity).findIndex((element) => element.notificationId === item.notificationHistoryId);
      // if (found !== -1) {
      //   setNotificationActivity((items) => {
      //     items.splice(found, 1);
      //     return [...items];
      //   });
      // }
    },
    [currPage, currentTab]
  );



  // const notificationItemsNumber = useCallback(() => NotificationActivity.length, [NotificationActivity]); // .filter((item) => item.isNew === true)
  // const notificationItemssucceeded = useCallback(() => notificationItems.filter((item) => item.succeededRecords > 0).length, [notificationItems]);
  // useEffect(() => {
  //   if (localStorageNotificationActivityListener !== null)
  //     localStorage.setItem('NotificationActivityListener', JSON.stringify(NotificationActivity));
  // }, [NotificationActivity]);
  // useEffect(() => {
  //   getAlertActions();
  // }, []);
  // useEffect(() => {
  //   getActions();
  //   setCurrentTab(0);
  // }, [getActions]);
  // useEffect(() => {
  //   localStorage.setItem('ActionsReadDataStorage', (JSON.stringify(ActionsReadData) || []));
  // }, [ActionsReadData]);
  // useEffect(() => {
  //   AlertContext.on('NotificationActivityListener', (n) => {
  //     setNotificationActivity((items) => {
  //       items.push(n);
  //       return [...items];
  //     });
  //     setNotificationNumber((items) => items + 1);
  //     NewNotification(Math.random());
  //     getAlertActions();

  //   });
  // }, []);

  // useEffect(() => {
  //   fetchDataNotification();
  // }, []);

  // const notificationsNumber = useCallback(() => actions.filter((item) => item.withProgress).length + notificationItems.filter((item) => item.isNew === true).length,[actions, notificationItems]);
  // useEffect(() => {
  //   getCurrentNotificationNumber(notificationsNumber());
  // }, [getCurrentNotificationNumber, notificationsNumber]);



  const goToActions = useCallback(
    (action) => () => {
      if (action.type !== 2) return;
      GlobalHistory.push(`/home/import-details?id=${action.id}`);
      const found = (localStoragedat || []).find((element) => element.id === action.id);
      if (!found) {
        setActionsReadData((items) => {
          items.push(action);
          return [...items];
        });
        GetMyImportProcess(1, 10).then((data) => {
          if (data) actionsHandler(data.result);
          else setActions([]);
        });
      }
    },
    []
  )

  const withProgressNumber = useCallback(() => actions.filter((item) => item.withProgress).length, [actions]);

  const GetMyNotificationsapi = async (currPage, currentTab) => {
    const response = await GetMyNotifications({ pageIndex: 1, pageSize: currPage, isImportant: (currentTab === 2 ? true : false) })
    if (!(response && response.notificationItems && response.notificationItems.result.length || 0)) {
      setLastList(true);
      return;
    }
    setPrevPage(currPage);
    if (currentTab === 2) {
      setNotificationsItemsimportant(response?.notificationItems?.result || []);
    } else {
      setNotificationsItems(response?.notificationItems?.result || []);
    }
  };


  const GetMyimportantNotificationsapi = async () => {
    const response = await GetMyNotifications({ pageIndex: 1, pageSize: currPage, isImportant: true })
    if (!(response && response.notificationItems && response.notificationItems.result.length || 0)) {
      setLastList(true);
      return;
    }
    setPrevPage(currPage);
    setNotificationsItemsimportant(response?.notificationItems?.result || []);
  };


  const getAlertActions = useCallback(() => {
    if (AlertContext) {
      AlertContext.on(CrmDfmNotification, (data) => {
        if (data) {
          setNotificationNumber((items) => items + 1);
          NewNotification(Math.random());
          fetchDataNotification();
          GetMyimportantNotificationsapi();
        }
        GlobalRerender();
      });
    }
  }, []);

  const saveHandlerMarkNotificationAsRead = useCallback(async (notificationId, isRead, currPage, currentTab) => {
    const res = await MarkNotificationAsRead(notificationId, isRead);
    if (!(res && res.status && res.status !== 200)) {
      GetMyNotificationsapi(currPage, currentTab)
    }
  }, []);


  useEffect(() => {
    if (loginResponse) {
      if (!AlertContext) {
        AlertContext = CrmDfmNotificationHub();
        AlertContext
          .start()
          .then(() => AlertContext.invoke('GetConnectionId'))
          .catch(() => { });
        fetchDataNotification();
        getAlertActions();
      }
    } else AlertContext = null;
  }, [loginResponse]);


  useEffect(() => {
    if (loginResponse) {
      if (!notificationContext) {
        notificationContext = ImportFileNotificationHub();
        notificationContext
          .start()
          .then(() => notificationContext.invoke('GetConnectionId'))
          .catch(() => { });
        GetMyImportProcess(1, 10).then((data) => {
          if (data) actionsHandler(data.result);
          else setActions([]);
        });
      }
    } else notificationContext = null;
  }, [actionsHandler, loginResponse]);

  // Call api when scroll
  const fetchDataNotification = async () => {
    if (currentTab === 0) {
      const response = await GetMyNotifications({ pageIndex: 1, pageSize: currPage })
      if (!(response && response.notificationItems && response.notificationItems.result.length || 0)) {
        setLastList(true);
        return;
      }
      setNotificationsItems(response?.notificationItems?.result || []);
    }
    if (currentTab === 2) {

      const responseddd = await GetMyNotifications({ pageIndex: 1, pageSize: currPage, isImportant: true })
      if (!(responseddd && responseddd.notificationItems && responseddd.notificationItems.result.length || 0)) {
        setLastList(true);
        return;
      }
      setPrevPage(currPage);
      setNotificationsItemsimportant(responseddd?.notificationItems?.result || []);
    }
  };
  // Call api when scroll

  // ChangeTabs
  const ChangeTabs = (val) => {
    setCurrentTab(val);
    localStorage.setItem('NotificationNumber', JSON.stringify((0))); // To remove Notification Number Iteam
    setNotificationNumber(0);
  };
  // ChangeTabs

  //  onScroll Fun
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight)
        setCurrPage(currPage + 10);
    }
  };
  const onScrollImportent = () => {
    if (listInnerImportentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerImportentRef.current;
      if (scrollTop + clientHeight === scrollHeight)
        setCurrPage(currPage + 10);
    }
  };
  //  onScroll Fun

  // to Clear Notification Number
  useEffect(() => {
    if (isOpen)
      setNotificationNumber(0);
  }, [isOpen]);
  // to Clear Notification Number


  // to Scroll Page and Get Request
  useEffect(() => {
    try {
      if (!lastList && prevPage !== currPage)
        fetchDataNotification(currentTab);
    } catch (error) { console.error('error', error); }
  }, [currPage]);
  // to Scroll Page and Get Request

  // 
  useEffect(() => {
    try {
      GetMyimportantNotificationsapi();
    } catch (error) { console.error('error', error); }
  }, []);
  // 
  useEffect(() => {
    getCurrentNotificationNumber(NotificationNumber);
    localStorage.setItem('NotificationNumber', JSON.stringify(NotificationNumber));
  }, [NotificationNumber]);


  useEffect(() => {
    notificationContext.on('NotificationListener', (n) => {
      GetMyImportProcess(1, 10).then((data) => {
        if (data) {
          actionsHandler(data.result);
          setNotificationNumber((items) => items + 1);
          NewNotification(Math.random());
        } else setActions([]);
      });
    });
  }, []);

  return (
    <Collapse in={isOpen} className='collapses absolute-collapse' style={{ top }}>
      <div className='cards'>
        <div className='card-header'>
          <p className='texts-large'>
            {` ${currentTab === 2 ? NotificationNumberlocal || 0 : withProgressNumber()} ${t(
              `${translationPath}${currentTab === 1 ? 'new' : 'in-progress'}`
            )}`}
          </p>
          <p>{t(`${translationPath}user-notification`)}</p>
        </div>
        <div className='card-content p-0'>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => ChangeTabs(newValue)}
            variant='fullWidth'
            indicatorColor='primary'
            textColor='primary'
            className='tabs-wrapper'
            aria-label='icon tabs example'
          >
            <Tab label={t(`${translationPath}alerts`)} />
            <Tab label={t(`${translationPath}actions`)} />
            <Tab label={t(`${translationPath}important`)} />
          </Tabs>
          <div
            className='notificationItems-container'
            onScroll={onScroll}
            ref={listInnerRef}
          >
            {currentTab === 0
              && notificationItems.length > 0
              && notificationItems.map((item, index) => (
                <React.Fragment key={`actions${index + 1}`}>
                  <div>
                    <div className='separator-h' />
                    {item && item.sourceName === 'ExportReportJob' && (
                      <ButtonBase
                        onClick={goToAlertActions(item, currPage)}
                        className={`w-100  d-flex  flex-h-between  btns theme-wide br-0 ${(item && item.isRead && 'is-read' || '')}`}>
                        <LoadableImageComponant
                          classes='cover-image notifcationImage'
                          type={LoadableImageEnum.image.key}
                          alt={t(`${translationPath}contact-image`)}
                          src={file_notf}
                        />
                        <div
                          onClick={() => {
                            const link = document.createElement('a');
                            link.setAttribute('download', item && item.title);
                            link.href = getDownloadableLink(item && item.body);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                          }}
                          className='w-100  d-flex  flex-h-between'
                        >
                          <div className='d-inline-flex-column-center-v mx-3'>
                            <span className='texts-small textNotification'>
                              {((item && item.sourceName) + '     ' +
                                moment(item && item.createdOn)
                                  .locale(i18next.language)
                                  .format('DD/MM/YYYY  h:mm a')
                                || '') || ''}
                              {' '}
                              {' '}
                              {(item && item.data && item.data.date) || ''}
                              {' '}
                            </span>

                            <span className='texts-large textNotification'>{(item && item.title) || ''}</span>

                          </div>     </div>                      <Button
                            onClick={() => {
                              const link = document.createElement('a');
                              link.setAttribute('download', item && item.title);
                              link.href = getDownloadableLink(item && item.body);
                              document.body.appendChild(link);
                              link.click();
                              link.remove();
                            }}
                            id='download-ref'
                            className='MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn  btns-icon theme-solid bg-secondary'
                          >
                          <span className='MuiButton-label'>
                            <span className='table-action-icon  mdi mdi-download-outline' />
                          </span>
                        </Button>

                      </ButtonBase>
                    ) || (
                        <ButtonBase
                          onClick={goToAlertActions(item, currPage)}
                          className={`btns theme-wide br-0  ${(item && item.isRead && 'is-read' || '')}`}
                        >
                          <LoadableImageComponant
                            classes='cover-image notifcationImage'
                            type={LoadableImageEnum.div.key}
                            alt={t(`${translationPath}contact-image`)}
                            src={
                              (item.imageUrl && getDownloadableLink(item.imageUrl)) ||
                              getDefaultContactImage('man')
                            }
                          />
                          <div className='d-inline-flex-column-center-v mx-3'>
                            <span className='texts-small textNotification'>
                              <span className='texts-small textNotification'>
                                {((item && item.sourceName) + '     ' +
                                  moment(item && item.createdOn)
                                    .locale(i18next.language)
                                    .format('DD/MM/YYYY  h:mm a')
                                  || '') || ''}
                                {' '}
                                {' '}
                                {(item && item.data && item.data.date) || ''}
                                {' '}
                              </span>
                              {((item && item.title) || '') || ''}
                              {' '}
                              {' '}
                              {(item && item.data && item.data.date) || ''}
                              {' '}
                            </span>
                            <Tooltip title={item.body || ''}>
                              <span className='texts-large textNotification'>{(item && item.body) || ''}</span>
                            </Tooltip>
                          </div>
                        </ButtonBase>)}
                  </div>
                </React.Fragment>
              ))}
          </div>
          <div className='notificationItems-container'>
            {currentTab === 1
              && actions.length > 0
              && actions.map((item, index) => (
                <React.Fragment key={`actions${index + 1}`}>
                  <div className='separator-h' />
                  <ButtonBase
                    className={`btns theme-wide br-0 ${(ActionsReadData || []).find((element) => element.id === item.id) ?
                      'is-read' : ''
                      }`}
                    onClick={goToActions(item)}
                  >
                    <span className={getIcon(item.type)} />
                    {' '}
                    <div className='d-inline-flex-column-center-v mx-3'>
                      <span className='texts-large textNotification'>{`${item.processName}${' '}${item.header}`}
                        {('     ' +
                          moment(item && item.createdOn)
                            .locale(i18next.language)
                            .format('DD/MM/YYYY  h:mm a')
                          || '') || ''}

                      </span>
                      <Tooltip title={item.text || ''}>
                        <span className='texts-small textNotification'
                        >{item.text || ''}</span>
                      </Tooltip>
                    </div>
                  </ButtonBase>
                  {item.withProgress && <LinearProgress />}
                </React.Fragment>
              ))}
          </div>
          <div className='notificationItems-container'
            onScroll={onScrollImportent}
            ref={listInnerImportentRef}
          >
            {currentTab === 2 &&
              // && notificationItems.length > 0 && notificationItems.filter(item => item.isImportant === true && notificationItems.length > 0) &&
              // notificationItems.filter(item => item.isImportant === true)
              notificationItemsimportant && notificationItemsimportant.length > 0 &&
              notificationItemsimportant.map((item, index) => (
                <React.Fragment key={`actions${index + 1}`}>
                  <div>
                    <div className='separator-h' />
                    {item && item.sourceName === 'ExportReportJob' && (
                      <ButtonBase
                        onClick={goToAlertActions(item)}
                        className={`w-100  d-flex  flex-h-between  btns theme-wide br-0 ${(item && item.isRead && 'is-read' || '')}`}
                      >
                        <LoadableImageComponant
                          classes='cover-image notifcationImage'
                          type={LoadableImageEnum.image.key}
                          alt={t(`${translationPath}contact-image`)}
                          src={
                            file_notf
                          }
                        />
                        <div
                          onClick={() => {
                            const link = document.createElement('a');
                            link.setAttribute('download', item && item.title);
                            link.href = getDownloadableLink(item && item.body);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                          }}
                          className='w-100  d-flex  flex-h-between'
                        >
                          <div className='d-inline-flex-column-center-v mx-3'>
                            <span className='texts-small textNotification'>
                              {((item && item.sourceName) + '     ' +
                                moment(item && item.createdOn)
                                  .locale(i18next.language)
                                  .format('DD/MM/YYYY  h:mm a')
                                || '') || ''}
                              {' '}
                              {' '}
                              {(item && item.data && item.data.date) || ''}
                              {' '}
                            </span> item.isImportant
                            {toString(item)}
                            <span className='texts-large textNotification'>{(item && item.title) || ''}</span>

                          </div>     </div>                      <Button
                            onClick={() => {
                              const link = document.createElement('a');
                              link.setAttribute('download', item && item.title);
                              link.href = getDownloadableLink(item && item.body);
                              document.body.appendChild(link);
                              link.click();
                              link.remove();
                            }}
                            id='download-ref'
                            className='MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn  btns-icon theme-solid bg-secondary'
                          >
                          <span className='MuiButton-label'>
                            <span className='table-action-icon  mdi mdi-download-outline' />
                          </span>
                        </Button>

                      </ButtonBase>
                    ) || (
                        <ButtonBase
                          onClick={goToAlertActions(item)}
                          className={`btns theme-wide br-0  ${(item && item.isRead && 'is-read' || '')}`}>
                          <LoadableImageComponant
                            classes='cover-image notifcationImage'
                            type={LoadableImageEnum.div.key}
                            alt={t(`${translationPath}contact-image`)}
                            src={
                              (item.imageUrl && getDownloadableLink(item.imageUrl)) ||
                              getDefaultContactImage('man')
                            }
                          />
                          <div className='d-inline-flex-column-center-v mx-3'>
                            <span className='texts-small textNotification'>
                              <span className='texts-small textNotification'>
                                {((item && item.sourceName) + '     ' +
                                  moment(item && item.createdOn)
                                    .locale(i18next.language)
                                    .format('DD/MM/YYYY  h:mm a')
                                  || '') || ''}
                                {' '}
                                {' '}
                                {(item && item.data && item.data.date) || ''}
                                {' '}
                              </span>
                              {((item && item.title) || '') || ''}
                              {' '}
                              {' '}
                              {(item && item.data && item.data.date) || ''}
                              {' '}
                            </span>
                            <Tooltip title={item.body || ''}>
                              <span className='texts-large textNotification'>{(item && item.body) || ''}</span>
                            </Tooltip>
                          </div>
                        </ButtonBase>)}
                  </div>
                </React.Fragment>
              ))}

          </div>
          <div className='separator-h mb-3' />
        </div>
        <div className='card-footer separator-h s-gray-primary pt-2'>
          {((currentTab === 0 || currentTab === 1 || currentTab === 2) && (
            <PermissionsComponent
              permissionsList={Object.values(ImportDetailsPermissions)}
              permissionsId={ImportDetailsPermissions.GetAllImportDetails.permissionsId}
            >
              <ButtonBase
                className='btns theme-outline mb-1'
                onClick={() => {
                  if (currentTab === 0)
                    GlobalHistory.push('/home/user-notification-details');
                  if (currentTab === 2)
                    GlobalHistory.push('/home/user-notification-details?isImportant=1');
                  if (currentTab === 1)
                    GlobalHistory.push('/home/import-details-actions');
                }}
              >
                <span>{t(`${translationPath}view-all`)}</span>
              </ButtonBase>
            </PermissionsComponent>
          ))}
        </div>
      </div>
    </Collapse>
  );
});
const mapStateToProps = (state) => {
  const {
    login: { loginResponse },
  } = state;
  return {
    loginResponse,
  };
};

const store = connect(mapStateToProps)(NotificationsView);
NotificationsView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired,
  getCurrentNotificationNumber: PropTypes.func.isRequired,
  NewNotification: PropTypes.func.isRequired,
  loginResponse: PropTypes.shape(undefined),
};
NotificationsView.defaultProps = {
  loginResponse: null,
};
export { store as NotificationsView };
