import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { search } from 'core-js/fn/symbol';
import {
  DialogComponent, PaginationComponent, Spinner, Tables, TableColumnsFilterComponent
} from '../../../../Components';
import { TableColumnsFilterActions } from '../../../../store/TableColumnsFilter/TableColumnsFilterActions';
import { TableActions, ColumnsFilterPagesEnum } from '../../../../Enums';
import {
  bottomBoxComponentUpdate,
  showError,
  showSuccess, returnPropsByPermissions // , GlobalHistory, showSuccess
} from '../../../../Helper';
import { ActivityTypeManagementDialog } from '../ActivitiesTypeManagementView/DialogManagementViewComponent/ActivityTypeManagementDialog';
import {
  DeleteActivityType,
  GetActivityTypeById,
  GetAllActivityTypes,
} from '../../../../Services/ActivitiesTypesServices';
import { ActivityTypePermissions } from '../../../../Permissions';
import { ActivitiesTabelHeaderData } from './ActivitiesTabelHeaderData';
export const ActivitiesTypeTabelView = ({
  parentTranslationPath, translationPath, reloading, setFilter, filter
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(parentTranslationPath);
  const list = [];
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [Data, setData] = useState({});
  const [ISedit, setISedit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    ActivitiesTabelHeaderData.filter((item) => item.isDefaultFilterColumn).map((column) => column.id)
  );
  const tableColumnsFilterResponse = useSelector((state) => state.TableColumnsFilterReducer);

  const [response, setresponse] = useState({
    result: [],
    totalCount: 0,
  });

  const GetAllActivityTypesAPI = useCallback(async (sortByAndOrderBy) => {
    setIsLoading(true);
    const requestJson = {
      ...filter,
      pageIndex: filter.pageIndex + 1, pageSize: filter.pageSize,
      filterBy: (sortByAndOrderBy && sortByAndOrderBy.filterBy) || (sortBy && sortBy.filterBy),
      orderBy: (sortByAndOrderBy && sortByAndOrderBy.orderBy) || (sortBy && sortBy.orderBy)
    };
    const result = await GetAllActivityTypes({ ...requestJson });
    if (!(result && result.status && result.status !== 200)) {
      setresponse({
        result: (result && result.result) || [],
        totalCount: (result && result.totalCount) || 0,
      });
    } else {
      setresponse({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter]);

  const GetActivityTypeByIdAPI = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetActivityTypeById(id);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) setData(res);
    else setData([]);
  }, []);

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setOpenDialog(true);
      setISedit(true);
      GetActivityTypeByIdAPI(item.activityTypeId);
    } else if (actionEnum === TableActions.delete.key) {
      setOpenDeleteDialog(true);
      setActiveItem(item);
    }
  }, []);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const DeleteHandler = async () => {
    const res = await DeleteActivityType(activeItem.activityTypeId);
    if (!(res && res.status && res.status !== 200)) {
      setIsLoading(true);
      GetAllActivityTypesAPI();
      showSuccess(t(`${translationPath}activity-deleted-successfully`));
    } else if ((res && res.data && res.data.Message).includes('THERE_IS_ACTIVITIES_FROM_THIS_TYPE'))
      showError(t(`${translationPath}Can’t-delete-this-activity-type`));
    else
      showError(t(`${translationPath}activity-delete-failed`));
    setIsLoading(false);
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    if (!reloading)
      GetAllActivityTypesAPI();
  }, [GetAllActivityTypesAPI, reloading, filter]);

  useEffect(() => {
    if (sortBy !== null)
      GetAllActivityTypesAPI(sortBy);
  }, [sortBy]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={response.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  const getActionTableWithPermissions = () => {
    // eslint-disable-next-line prefer-const

    if (returnPropsByPermissions(ActivityTypePermissions.EditActivityType.permissionsId)) {
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    }
    if (returnPropsByPermissions(ActivityTypePermissions.DeleteActivityType.permissionsId)) {
      list.push({
        enum: TableActions.delete.key,
        isDisabled: true,
        externalComponent: null,
      });
    }
    return list;
  };

  const focusedRowChanged = (rowIndex, item) => {

    if (rowIndex !== -1) {
      if (item && item.isStatic === false) {
        list.splice(0, list.length);
        list.push({
          enum: TableActions.delete.key,
          isDisabled: false,
          externalComponent: null,
        },
          {
            enum: TableActions.editText.key,
            isDisabled: false,
            externalComponent: null,
          });
      } else if (item && item.isStatic === true) {
        list.splice(0, list.length);
        list.push({
          enum: TableActions.delete.key,
          isDisabled: true,
          externalComponent: null,
        }, {
          enum: TableActions.editText.key,
          isDisabled: false,
          externalComponent: null,
        });

      }
    }
  };
  useEffect(() => {
    if (
      tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum.activityType.key]
    ) {
      setSelectedTableFilterColumns(
        tableColumnsFilterResponse[ColumnsFilterPagesEnum.activityType.key]
      );
    }
  }, [tableColumnsFilterResponse]);
  useEffect(() => {
    setTableColumns([
      ...ActivitiesTabelHeaderData.filter(
        (item) => selectedTableFilterColumns.findIndex((element) => element === item.id) !== -1
      ),
    ]);
  }, [selectedTableFilterColumns]);
  return (
    <div className='ActivitiesType-View childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <TableColumnsFilterComponent
          columns={ActivitiesTabelHeaderData.concat(
            selectedTableFilterColumns.filter(
              (item) =>
                ActivitiesTabelHeaderData.findIndex(
                  (element) => element.fieldKey === item.formFieldKey
                ) === -1
            )
          ).map((item) => ({
            key: item.formFieldId || item.id,
            value: (item.formFieldTitle && item.formFieldTitle.replace('*', '')) || item.label,
          }))}
          isLoading={isLoading}
          selectedColumns={selectedTableFilterColumns}
          onSelectedColumnsChanged={(newValue) => {
            setSelectedTableFilterColumns(newValue);
            let localTableColumnsFilterResponse = tableColumnsFilterResponse;
            if (localTableColumnsFilterResponse){
              localTableColumnsFilterResponse[ColumnsFilterPagesEnum.activityType.key] = newValue;
            }else {
              localTableColumnsFilterResponse = {
                [ColumnsFilterPagesEnum.activityType.key]: newValue,
              };
            }
            dispatch(
              TableColumnsFilterActions.TableColumnsFilterRequest(localTableColumnsFilterResponse)
            );
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath=''
        />
        <Tables
          data={response.result}
          headerData={tableColumns}
          defaultActions={
            getActionTableWithPermissions()

          }
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={response.totalCount}
          focusedRowChanged={focusedRowChanged}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          setSortBy={setSortBy}
        />
      </div>
      <DialogComponent
        isOpen={openDialog}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        titleText='Edit-Activity'
        titleClasses='DialogComponent-ActivitiesType'
        wrapperClasses='wrapperClasses-ActivitiesType'
        maxWidth='md'
        dialogContent={(
          <>
            <ActivityTypeManagementDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              activeItem={activeItem}
              Data={Data}
              edit={ISedit}
              GetAllActivityTypesAPI={() => GetAllActivityTypesAPI()}
              onCancelClicked={() => {
                setOpenDialog(false);
                setISedit(false);
                setData({})
                setActiveItem(null);
              }}
            />
          </>
        )}
      />
      <DialogComponent
        titleText='confirm-message'
        saveText='confirm'
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span>{`${t(`${translationPath}activity-delete-description`)}`}</span>
          </div>
        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={OpenDeleteDialog}
        onSaveClicked={() => {
          DeleteHandler();
        }}
        onCloseClicked={() => {
          setOpenDeleteDialog(false);
          setActiveItem(null);
        }}
        onCancelClicked={() => {
          setOpenDeleteDialog(false);
          setActiveItem(null);
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div>
  );
};

ActivitiesTypeTabelView.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  reloading: PropTypes.bool.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,

};
