import { PowerBiEnum } from "../../../../Enums";
import { SaleTeamLeadDashboardPermissions } from '../../../../Permissions' ; 
import { MainDashboardPolicyFilters } from "../MainDashboardPolicyFilters/MainDashboardPolicyFilters";
import { MainSaleTeamsTabDashboards } from "../MainSaleTeamsTabDashboards";

export const SaleTeamsDashboardTabs = [
  {
    index: 0,
    label: "Dashboard:teamlead",
    component: MainDashboardPolicyFilters,
    config: {
      reportId: PowerBiEnum.SalesTeamLeadDate.reportid,
      groupId: PowerBiEnum.SalesTeamLeadDate.groupid,
      Url: PowerBiEnum.SalesTeamLeadDate.url,
    },
    permissionsId : SaleTeamLeadDashboardPermissions.SaleTeamLeadDashboard.permissionsId, 
  },
  {
    index: 1,
    label: "Dashboard:teamlead+",
    component: MainDashboardPolicyFilters,
    config: {
      reportId: PowerBiEnum.SalesTeamLeadplus.reportid,
      groupId: PowerBiEnum.SalesTeamLeadplus.groupid,
      Url: PowerBiEnum.SalesTeamLeadplus.url,
    },
  
    permissionsId : SaleTeamLeadDashboardPermissions.SalesTeamLeadPlusDashboard.permissionsId, 
  },
 
];
