import { HistoryComponent } from '../Sections/HistoryComponent';
import { UnitsSalesPermissions, UnitsLeasePermissions , LeadOwnerUnitSalePermissions , LeadOwnerUnitLeasePermissions } from '../../../Permissions';
import { ContactHistoryComponent, UnitHistoryComponent, LeadHistoryComponent, PropertyHistoryComponent , RotationSchemaHistoryComponent  , ManageAgentsHistoryComponent} from '../Sections';
import { TransactionHistoryComponent } from '../Sections/TransactionHistoryComponent/TransactionHistoryComponent';

export const HistoryComponentTabs = {
  history:
    [{ label: 'history', component: HistoryComponent },
    { label: 'transaction-history', component: TransactionHistoryComponent },
    ],
  contactHistory:
    [{ label: 'history', component: ContactHistoryComponent  }],

  saleUnitHistory:
    [
      {
        label: 'history',
        component: UnitHistoryComponent,
        permissionsList: Object.values(UnitsSalesPermissions),
        permissionsId: UnitsSalesPermissions.ViewUnitHistory.permissionsId,
      },
      { 
        label: 'transaction-history',
        component: TransactionHistoryComponent, 
        permissionsList: Object.values(UnitsSalesPermissions),
        permissionsId: UnitsSalesPermissions.ViewTransactionDetailsForUnit.permissionsId,
       },
    ],

  leaseUnitHistory:
    [
      {
        label: 'history', component: UnitHistoryComponent,
        permissionsList: Object.values(UnitsLeasePermissions),
        permissionsId: UnitsLeasePermissions.ViewUnitHistory.permissionsId,

      },
      {
        label: 'transaction-history',
        component: TransactionHistoryComponent,
        permissionsList: Object.values(UnitsLeasePermissions),
        permissionsId: UnitsLeasePermissions.ViewTransactionsForUnit.permissionsId,
      }

    ],
  unitPropertyManagmentHistory:
    [
      { label: 'history', component: UnitHistoryComponent },
      { label: 'transaction-history', component: TransactionHistoryComponent },
    ],
  saleLeadsHistory: [{ label: 'history', component: LeadHistoryComponent }],
  leaseLeadHistory: [{ label: 'history', component: LeadHistoryComponent }],

  callCenterLeadHistory:
    [{ label: 'history', component: LeadHistoryComponent }],

  propertyManagmentLeadHistory:
    [{ label: 'history', component: LeadHistoryComponent }],

  propertyHistory:
    [{ label: 'history', component: PropertyHistoryComponent }],

  propertyManagmentHistory:
    [{ label: 'history', component: PropertyHistoryComponent }],

  LeadsMortgageHistory:
    [{ label: 'history', component: LeadHistoryComponent }],

    contactLeadHistory : 
    [{ label: 'history', component: LeadHistoryComponent }],

    leadOwnerUnitSaleHistory : 
    [
      {
        label: 'history',
        component: UnitHistoryComponent,
        permissionsList: Object.values(LeadOwnerUnitSalePermissions),
        permissionsId: LeadOwnerUnitSalePermissions.ViewUnitHistory.permissionsId,
      },
      { 
        label: 'transaction-history',
        component: TransactionHistoryComponent, 
        permissionsList: Object.values(LeadOwnerUnitSalePermissions),
        permissionsId: LeadOwnerUnitSalePermissions.ViewTransactionDetailsForUnit.permissionsId,
       },
    ],
    leadOwnerUnitLeaseHistory : 
    [
      {
        label: 'history',
        component: UnitHistoryComponent,
        permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
        permissionsId: LeadOwnerUnitLeasePermissions.ViewUnitHistory.permissionsId,
      },
      { 
        label: 'transaction-history',
        component: TransactionHistoryComponent, 
        permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
        permissionsId: LeadOwnerUnitLeasePermissions.ViewTransactionDetailsForUnit.permissionsId,
       },
    ],

    leadOwnerLeadHistory : [
    { 
      label: 'history', component: LeadHistoryComponent 
    }] , 
    
    rotationSchema : 
      [{ label: 'history', component:RotationSchemaHistoryComponent  }]  , 

      manageAgents :  
      [{ label: 'history', component:ManageAgentsHistoryComponent  }] , 
};



