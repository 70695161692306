import { ContactsPermissions, ListingShortagePermissions, ZeroMatchingLeasePermissions, ZeroMatchingSalePermissions } from '../Permissions';
import { returnPropsByPermissions } from './ReturnPropsByPermissions.Helper';

export const ExceptionPermissionsHelper = () => {
  let userPermissions = localStorage.getItem('session') ;
  if(userPermissions){
  userPermissions = JSON.parse(localStorage.getItem('session')).permissions;
  let  view = false ;
  let edit = false ;
  var array = Object.values(ContactsPermissions);
  var res = userPermissions.filter(item1 =>
    array.some(item2 => (item2.permissionsId === item1.permissionsId)))
    if(res && res.length === 2){
      res.forEach(element => {
        if(element.permissionsId ===  ContactsPermissions.ViewContactInformation.permissionsId)
           view = true ;
           else if(element.permissionsId === ContactsPermissions.EditContactInformation.permissionsId)
           edit = true ;
      });
    }
    if(res && view && edit)
    {
     return true ; 
    }
  return false ; 
}
}

export const getIsFieldWithPermission = (item)=>{

  const fieldDto = item.data.DtoName;

  if (fieldDto === 'assign_to_listing_shortage')
    return returnPropsByPermissions(ListingShortagePermissions.AssignInquiry.permissionsId);

  else if (fieldDto === 'assign_to_zero_matching_sale')
    return  returnPropsByPermissions(ZeroMatchingSalePermissions.AssignInquiry.permissionsId);

  else if (fieldDto === 'assign_to_zero_matching_lease')
    return returnPropsByPermissions(ZeroMatchingLeasePermissions.AssignInquiry.permissionsId);

  else return false;
}


export const tabsPermissionsHelper = (tabsList) => {
  let userPermissions = localStorage.getItem('session') ;
  if(userPermissions)
  {
    userPermissions = JSON.parse(localStorage.getItem('session')).permissions;
    const  tabsListWithPermissions = tabsList.filter(item1 =>
      userPermissions.some(item2 => (item2.permissionsId === item1.permissionsId))) ;
    return tabsListWithPermissions ; 
  }
}




