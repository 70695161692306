import React, { useEffect, useState, useReducer, useCallback } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError } from "../../../../Helper";
import { MicrosoftToken } from "../../../../Services";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import { SelectComponet } from "../../../../Components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBase } from "@material-ui/core";
import { CellsSpinner } from "../../../../SharedComponents/CellSpinnerComponent/CellsSpinner";
import { GetUserTeamsInfo } from "../../../../Services/userServices";
import { PowerBiEnum } from "../../../../Enums";
import { PermissionDeniedLayout } from "../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout";

const parentTranslationPath = "LeadsView";
const translationPath = "";

export const LeaseListingTeamlead = () => {
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const userid = JSON.parse(localStorage.getItem("session")).userId;

  const [ShowReport2, setShowReport2] = useState(false);
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [Datetime, setDatetime] = useState(30);
  const [Leadstatus, setLeadstatus] = useState();
  const [Leadstatusstatus, setLeadstatusstatus] = useState(true);
  const [OperatorLeadstatus, setOperatorLeadstatus] = useState("All");
  const [datefilters, setdatefilter] = useState(true);
  const [datepicker, setdatepicker] = useState();

  const [monthsAgo, setMonthsAgo] = useState(1);

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );
  const [teamid, setTeamid] = useState([]);
  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate() - 7));
  const count2 = new Date(new Date().setDate(today.getDate()));

  const datetimes = moment(count).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: startDate || null,
    endDate: endDate || null,
    key: "selection",
  };

  const [transactions, settransactions] = useState(false);
  const [Activity, setActivity] = useState(false);
  const [Units, setUnits] = useState(false);
  const [Portal, setPortal] = useState(false);
  const [Inquiries, setInquiries] = useState(false);

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes,
        toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes2,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    setLeadstatus(0);
    setLeadstatusstatus(true);
    setdatepicker(0);
    setdatefilter(true);
  }, []);

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "ListingUnitsViews",
      column: datefilters ? "UnitsCreatedOn" : "listing_date",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedSalesTransactions = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "View_SalesTransactionsSellerBuyer",
      column: "TransactionDate",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedActivity = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "ListingActivity",
      column: "ActivityCreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedInquiries = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "Inquiries",
      column: "InquiriesCreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedPublishedPortals = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "PublishedPortals",
      column: "PublishedPortalsCreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const getUserTemasById = useCallback(async () => {
    const res = await GetUserTeamsInfo(userid, true);
    if (!(res && res.status && res.status !== 200))
      setTeamid(res && res.map((List) => List.teamsId || null));
    else setTeamid([]);
  }, [userid]);
  const Filter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Team Users BusinessGroups",
      column: "TeamsId",
    },
    operator: "In",
    values: teamid,
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  useEffect(() => {
    getUserTemasById(userid);
  }, []);

  let selectedFilter;

  switch (true) {
    case Units:
      selectedFilter = advancedFilter;
      break;

    case transactions:
      selectedFilter = advancedSalesTransactions;
      break;

    case Activity:
      selectedFilter = advancedActivity;
      break;

    case Portal:
      selectedFilter = advancedPublishedPortals;
      break;
    case Inquiries:
      selectedFilter = advancedInquiries;
      break;

    default:
      selectedFilter = advancedFilter;
  }

  const FilterLeadStatus = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "ListingUnitsViews",
      column: "Status",
    },
    operator: OperatorLeadstatus,
    values: [Leadstatus],
    filterType: models.FilterType.BasicFilter,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
        console.log(true);
      })
      .catch((error) => {
        console.log(false);
      });
  }, []);
  useEffect(() => {
    Microsoft(false);
  }, []);
  useEffect(() => {
    if (Leadstatusstatus) setOperatorLeadstatus("All");
    else setOperatorLeadstatus("In");
  }, [ Leadstatusstatus]);

  return (
    <div className="dashboardMain-PowerBIEmbed">
        
      <SpinnerDashboards isActive={!ShowReport} isAbsolute />

      {DateAppaer && (
        <div>
          <div className="MainFiltersBi">
            <CellsSpinner isActive={!ShowReport2} isAbsolute />
            <div className="selecterbi">
            <div>
                  <SelectComponet
                    data={[
                      { id: 1, Leadstatus: "Available" },
                      { id: 10, Leadstatus: "Draft" },
                      { id: 3, Leadstatus: "Leased" },
                      { id: 12, Leadstatus: "Pending Lease" },
                      { id: 11, Leadstatus: "Pending Reserve Lease" },
                      { id: 2, Leadstatus: "Reserved Leased" },
                    ]}
                    emptyItem={{
                      value: 0,
                      text: "select-status",
                      isDisabled: false,
                    }}
                    value={Leadstatus || 0}
                    defaultValue={0}
                    valueInput="id"
                    textInput="Leadstatus"
                    onSelectChanged={(value) => {
                      switch (value) {
                        case 0:
                          setLeadstatusstatus(true);
                          setLeadstatus(0);
                          break;
                        default:
                          setLeadstatus(value);
                          setLeadstatusstatus(false);
                          setShowReport2(false);
                          break;
                      }
                    }}
                    wrapperClasses="w-auto"
                    themeClass="theme-transparent"
                    idRef="Lead_Status"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
            </div>

            <div className="selecterbi">
              {!Units ? (
                <div>
                  
                </div>
              ) : (
                <div>
                  <SelectComponet
                    data={[
                      {
                        id: 1,
                        datepicker: "Listing Date",
                      },
                    ]}
                    emptyItem={{
                      value: 0,
                      text: `${translationPath}created-date`,
                      isDisabled: false,
                    }}
                    value={datepicker || 0}
                    defaultValue={0}
                    valueInput="id"
                    textInput="datepicker"
                    onSelectChanged={(value) => {
                      switch (value) {
                        case 0:
                          setdatepicker(0);
                          setdatefilter(true);
                          setShowReport2(false);
                          break;
                        default:
                          setdatepicker(1);
                          setdatefilter(false);
                          setShowReport2(false);
                          break;
                      }
                    }}
                    themeClass="theme-transparent"
                    wrapperClasses="w-auto"
                    idRef="Date_Select"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
              )}

              <div>
                <DateRangePickerComponent
                  onClearClicked={() => {
                    setShowReport2(false);
                    setDateFilter({
                      startDate: startDate,
                      endDate: endDate,
                      key: "selection",
                    });
                  }}
                  ranges={[dateFilter]}
                  onDateChanged={(selectedDate) => {
                    const newStartDate =
                      selectedDate.selection &&
                      selectedDate.selection.startDate;
                    const newEndDate = new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day")
                    );

                    if (
                      newStartDate.getTime() !==
                        dateFilter.startDate.getTime() ||
                      newEndDate.getTime() !== dateFilter.endDate.getTime()
                    ) {
                      setShowReport2(false);
                    } else {
                      setShowReport2(true);
                    }

                    setDateFilter({
                      startDate: newStartDate,
                      endDate: newEndDate,
                      key: "selection",
                    });
                  }}
                />
              </div>
            </div>
            <div className="clearDashboardFilter">
              <ButtonBase
                onClick={onClearedAllFiltersClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary clear-all-btn"
              >
                <span className="mdi mdi-filter-remove m-1" />
                {t(`${translationPath}clear-filters`)}
              </ButtonBase>
            </div>
            <div className="clearDashboardFilter">
            <ButtonBase
                onClick={handleRefreshClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-success clear-all-btn"
              >
                <span className="mdi mdi-chart-arc m-1" />
                Refresh
              </ButtonBase>
            </div>
          </div>
        </div>
        
      )}
 
 {render && teamid && teamid.length > 0 ? (
        <div className="dashboardMain">
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: PowerBiEnum.LeaseListingUnitsDate.reportid,
              embedUrl: PowerBiEnum.LeaseListingUnitsDate.url,
              accessToken: report,
              filters: [
                selectedFilter,
                FilterLeadStatus,
                Filter,
              ],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  (event) => {
                    setShowReport(true);
                    setDateAppaer(true);
                  },
                ],
                [
                  "dataSelected",

                  (event) => {
                    setShowReport2(false);
                  },
                ],

                [
                  "rendered",
                  (event) => {
                    setShowReport2(true);
                  },
                ],

                [
                  "pageChanged",
                  (event) => {
                    const newPageName = event.detail.newPage.displayName;

                    switch (newPageName) {
                      case "Achievment":
                        settransactions(true);
                        setActivity(false);
                        setUnits(false);
                        setPortal(false);
                        setInquiries(false);
                        setShowReport2(false);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);

                        break;
                      case "Inquiries":
                        settransactions(false);
                        setActivity(false);
                        setUnits(false);
                        setPortal(false);
                        setInquiries(true);
                        setShowReport2(false);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);

                        break;
                      case "Portals":
                        settransactions(false);
                        setActivity(false);
                        setUnits(false);
                        setPortal(true);
                        setInquiries(false);
                        setShowReport2(false);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);

                        break;
                      case "Activity Details":
                        settransactions(false);
                        setActivity(true);
                        setUnits(false);
                        setPortal(false);
                        setInquiries(false);
                        setShowReport2(false);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);

                        break;
                      default:
                        settransactions(false);
                        setActivity(false);
                        setUnits(true);
                        setPortal(false);
                        setInquiries(false);
                        setdatepicker(0);
                        setShowReport2(false);
                        setdatefilter(true);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);
                        break;
                    }
                  },
                ],

                [
                  "error",
                  (event) => {
                    const error = event.detail;
                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
        
       ) : (
          <div>{render && <PermissionDeniedLayout />}</div>
      )}
      
    </div>
        
  );
};

