import {
  DescriptionsView,
  WebPortal,
  PublishedImages
} from '../Sections';
import { UnitsSalesPermissions} from '../../../../../../../Permissions' ; 

export const UnitProfileMarketingTabsData = [
  { label: 'descriptions', component: DescriptionsView },
  { label: 'web-portal',
    component: WebPortal , 
    permissionsList: Object.values(UnitsSalesPermissions),
    permissionsId: UnitsSalesPermissions.ShowWebPortals.permissionsId,

 },
  { label: 'published-images', component: PublishedImages },
];
