import React, { useEffect, useCallback, useState, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteComponent } from '../../../../../../Components';
import { ApplicationUserSearch } from '../../../../../../Services/userServices';
import { GetAllRoles } from '../../../../../../Services';

export const RotationTabComponent = ({ parentTranslationPath,
   translationPath,
   state,
   setState,
   selected,
   setSelected,
   }) => {
  const searchTimer = useRef(null);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useReducer(reducer, {
    roles: false,
    users: false,
  });
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const [data, setData] = useReducer(reducer, {
    roles: [],
    users: [],
  });


  const getAllRoles = useCallback(async (searchValue) => {
    setIsLoading({ id: 'roles', value: true });
    const res = await GetAllRoles((filter.pageIndex + 1), filter.pageSize, searchValue);

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'roles', value: res?.result || [] });
    } else setData({ id: 'roles', value: [] });

    setIsLoading({ id: 'roles', value: false });
  }, []);


  const getAllUsers = useCallback(
    async (searchValue) => {

      setIsLoading({ id: 'users', value: true });
      const res = await ApplicationUserSearch({
        ...filter,
        rolesIds: [],
        name: searchValue || '',
      });
      if (!(res && res.status && res.status !== 200)) {
        const localValue = (res && res.result) || [];
        if (localValue.length > 0) {
          setData({
            id: 'users',
            value: localValue,
          });
        } else {
          setData({
            id: 'users',
            value: [],
          });
        }

        setIsLoading({ id: 'users', value: false });
      }
    },
    [filter, state.roles]
  );

  useEffect(() => {
    getAllRoles();
    getAllUsers();
  }, [getAllUsers]);

  return (
    <div>
      <div className='w-70 m-3'>
        <AutocompleteComponent
          idRef='rotationUsersRef'
          labelValue='rotation-users'
          selectedValues={selected.activityTypeRotationUsers || []}
          multiple
          data={data.users || []}
          chipsLabel={(option) => (option.fullName || option.usersName) || ''}
          displayLabel={(option) => (option.fullName || option.usersName) || ''}
          renderOption={(option) =>
            ((option.fullName)? `${option.fullName}`: `${option.usersName}`) ||
            ''}
          getOptionSelected={(option) =>
            selected.activityTypeRotationUsers&&selected.activityTypeRotationUsers.findIndex((item) => (item.applicationUserId === option.applicationUserId) || (item.usersId === option.usersId) ) !== -1 || ''}
          withoutSearchButton
          onInputKeyUp={(event) => {
            const {value} = event.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllUsers(value);
            }, 700);
          }}
          isLoading={isLoading.users}
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            const mappedRotationRoles = newValue&&newValue.map(item=>({usersId: item.applicationUserId|| item.usersId}))
            setSelected({ id:'activityTypeRotationUsers', value: newValue})
            setState({ id:'activityTypeRotationUsers', value: mappedRotationRoles})
          }}
        />
      </div>
      <div className='w-70  m-3'>
        <AutocompleteComponent
          idRef='rotationRolesIdRef'
          labelValue='rotation-roles'
          selectedValues={selected.activityTypeRotationRoles || []}
          multiple
          data={data.roles||[]}
          chipsLabel={(option) => option.rolesName || ''}
          displayLabel={(option) => option.rolesName || ''}
          getOptionSelected={(option) =>
          selected.activityTypeRotationRoles&&selected.activityTypeRotationRoles.findIndex((item) => item.rolesId === option.rolesId) !== -1 || ''}
          withoutSearchButton
          isLoading={isLoading.roles}
          onInputKeyUp={(event) => {
            const {value} = event.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllRoles(value);
            }, 700);
          }}
          isLoa
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            const mappedRotationRoles = newValue&&newValue.map(item=>({rolesId: item.rolesId}))
            setSelected({ id:'activityTypeRotationRoles', value: newValue})
            setState({ id:'activityTypeRotationRoles', value: mappedRotationRoles})
          }}
        />
      </div>
    </div>
  );
};
RotationTabComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setStateNotification: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
};
