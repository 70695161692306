import React, {
  useEffect, useState, useReducer, useCallback, useRef
} from 'react';
import {
  DialogActions, DialogContent, DialogTitle, Dialog,
  ButtonBase, Fab
} from '@material-ui/core';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { DialogComponent, Spinner, TabsComponent } from '../../../../../../../Components';
import { CityTypeIdEnum, SubCommunityTypeIdEnum, CommunityTypeIdEnum, DistrictTypeIdEnum } from '../../../../../../../Enums';
import { lookupItemsGetId, CreateBranch, GetParentLookupItemById, UpdateBranch, OrganizationUserSearch, GetBranchInfoById } from '../../../../../../../Services';
import { GetApplicationUserById } from '../../../../../../../Services/userServices';
import { showError, showSuccess } from '../../../../../../../Helper';
import { BranchTabComponent } from './BranchTabComponent'
import { FinancialDataComponent } from './FinancialDataComponent'

export const BranchManagmentDialog = ({
  activeItem,
  open,
  close,
  onSave,
  translationPath,
  parentTranslationPath
}) => {
  const searchTimer = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedModuleName, setSelectedModuleName] = useState(0);
  const [isOpenAddModule, setIsOpenAddModule] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [allUsers, setAllUsers] = useState({
    result: [],
    totalCount: 0
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [initialAddressLookupsFetched, setInitialAddressLookupsFetched] = useState(false);
  const [address, setAddress] = useState({
    country: null,
    city: null,
    district: null,
    community: null,
    subCommunity: null
  });
  const [currency, setCurrency] = useState([]);
  const [nameBank, setNameBank] = useState([]);
  const [currencyValue, setCurrencyValue] = useState(null);
  const [bankNameValue, setBankNameValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const reducer2 = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  }
    ; const [selected, setSelected] = useReducer(reducer2, {
      user: null,
    });
  const [addressLoadings, setAddressLoadings] = useState({
    countries: false, cities: false, districts: false, communities: false, subCommunities: false
  });
  const [addressList, setAddressList] = useState({
    countries: [], cities: [], districts: [], communities: [], subCommunities: []
  });

  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const initialState = {
    branchName: null,
    branchNumber: null,
    branchCountryId: null,
    branchCityId: null,
    branchDistrictId: null,
    maximumNumberOfUsers: null,
    branchLogoId: null,
    documentsFooterImageId: null,
    documentsHeaderImageId: null,
    isActive: true,
    licenseNumber: null,
    branchStreet: null,
    property: null,
    unit: null,
    floor: null,
    companyTelephoneNo: null,
    poBox: null,
    branchEmail: null,
    branchWebsite: null,
    userId: '',
    branchFinancial: [],
    branchFinancialBusinessUnits: [],
  };
  const [dublicatModule, setDublicatModule] = useState(null);
  const [state, setState] = useReducer(reducer, initialState);
  const initialModuleState = {
    businessUnit: 0,
    bankId: null,
    accountName: '',
    accountNumber: 0,
    ibanCode: '',
    swiftCode: '',
    currencyId: null,
    isBranchDefualt: false,
    branchFinancialId: null
  }
  const reducer3 = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [financialDetailState, setFinancialDetailState] = useReducer(reducer3, initialModuleState);

  const schema = Joi.object({
    branchName: Joi.string()
      .required()
      .messages({
        'string.empty': t(`${translationPath}branch-name-is-required`),
      }),
    userId: Joi.string()
      .required()
      .messages({
        'string.empty': t(`${translationPath}user-is-required`),
        'string.base': t`${translationPath}user-is-required`,
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };


  const getCountriesLookups = useCallback(async () => {
    setAddressLoadings((item) => ({ ...item, countries: true }));
    const res = await lookupItemsGetId({ lookupTypeId: 16 });
    if (!(res && res.status && res.status !== 200)) setAddressList((item) => ({ ...item, countries: res || [] }));
    else {
      setAddressList((item) => ({ ...item, countries: [] }));
    }
    setAddressLoadings((item) => ({ ...item, countries: false }));
  }, []);

  const getCitiesLookups = useCallback(async (countryId) => {
    setAddressLoadings((item) => ({ ...item, cities: true }));
    const res = await lookupItemsGetId({ lookupTypeId: CityTypeIdEnum.lookupTypeId, lookupParentId: countryId });
    if (!(res && res.status && res.status !== 200)) setAddressList((item) => ({ ...item, cities: res || [] }));
    else {
      setAddressList((item) => ({ ...item, cities: [] }));
    }
    setAddressLoadings((item) => ({ ...item, cities: false }));
  }, []);

  const getDistrictLookUps = useCallback(async (cityId) => {
    setAddressLoadings((item) => ({ ...item, districts: true }));
    const res = await lookupItemsGetId({ lookupTypeId: DistrictTypeIdEnum.lookupTypeId, lookupParentId: cityId });
    if (!(res && res.status && res.status !== 200)) setAddressList((item) => ({ ...item, districts: res || [] }));
    else {
      setAddressList((item) => ({ ...item, districts: [] }));
    }
    setAddressLoadings((item) => ({ ...item, districts: false }));
  }, []);

  const getCommunityLookUps = useCallback(async (districtId) => {
    setAddressLoadings((item) => ({ ...item, communities: true }));
    const res = await lookupItemsGetId({ lookupTypeId: CommunityTypeIdEnum.lookupTypeId, lookupParentId: districtId });
    if (!(res && res.status && res.status !== 200)) setAddressList((item) => ({ ...item, communities: res || [] }));
    else {
      setAddressList((item) => ({ ...item, communities: [] }));
    }
    setAddressLoadings((item) => ({ ...item, communities: false }));
  }, []);

  const getSubCommunityLookUps = useCallback(async (communityId) => {
    setAddressLoadings((item) => ({ ...item, subCommunities: true }));
    const res = await lookupItemsGetId({ lookupTypeId: SubCommunityTypeIdEnum.lookupTypeId, lookupParentId: communityId });
    if (!(res && res.status && res.status !== 200)) setAddressList((item) => ({ ...item, subCommunities: res || [] }));
    else {
      setAddressList((item) => ({ ...item, subCommunities: res || [] }));
    }
    setAddressLoadings((item) => ({ ...item, subCommunities: false }));
  }, []);

  const getCurrencyLookups = async () => {
    const res = await lookupItemsGetId({ lookupTypeId: 1213 });
    if (!(res && res.status && res.status !== 200)) setCurrency(res || []);
    else setCurrency([]);
  };

  const getNameBankLookups = async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({ lookupTypeId: 26 });
    if (!(res && res.status && res.status !== 200)) setNameBank(res || []);
    else setNameBank([]);
    setIsLoading(false);
  };
  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      getAllUsers(value)
    }, 700);
  };
  const getAllUsers = useCallback(async (searchValue) => {
    setIsLoading(true)
    const res = await OrganizationUserSearch({ pageSize: 25, pageIndex: 1, userStatusId: 2, name: searchValue });
    if (!(res && res.status && res.status !== 200)) setAllUsers(res || []);
    else setAllUsers([]);
    setIsLoading(false)
  }, []);

  const getUserById = useCallback(
    async (userId) => {
      const res = await GetApplicationUserById(userId);
      setSelected({ id: 'user', value: res || null });
    }, []);

  const getBranchInfoById = useCallback(async (userId) => {
    const res = await GetBranchInfoById(userId);
    if (!(res && res.status && res.status !== 200)) {
      setState({ id: 'edit', value: res })
      getInitialAddressLookups(res);
    }

  }, []);


  const getAddressFromActiveItem = () => {

    const country = addressList.countries.find((item) => item.lookupItemId === state?.branchCountryId) || null;
    const city = addressList.cities.find((item) => item.lookupItemId === state?.branchCityId) || null;
    const district = addressList.districts.find((item) => item.lookupItemId === state?.branchDistrictId) || null;
    const community = addressList.communities.find((item) => item.lookupItemId === state?.branchCommunityId) || null;
    const subCommunity = addressList.subCommunities.find((item) => item.lookupItemId === state?.branchSubCommunityId) || null;
    setAddress({
      country,
      city,
      district,
      community,
      subCommunity
    })
  };


  const getInitialAddressLookups = async (branchInfo) => {
    Promise.all([
      getCountriesLookups(),
      getCitiesLookups(branchInfo.branchCountryId),
      getDistrictLookUps(branchInfo.branchCityId),
      getCommunityLookUps(branchInfo.branchDistrictId),
      getSubCommunityLookUps(branchInfo.branchCommunityId),
    ]).then(() => setInitialAddressLookupsFetched(true)
    ).catch(() => setInitialAddressLookupsFetched(false)
    )
  }

  const getLookupsOfParent = useCallback(async (childLookup) => {
    const res = await GetParentLookupItemById({ pageIndex: 1, pageSize: 25, lookupItemId: childLookup?.lookupItemId });
    if (!(res && res.status && res.status !== 200)) {

      if (childLookup?.lookupItemName === '') setAddressList({ ...addressList, cities: res.result || [] });
      if (childLookup?.lookupItemName === '') setAddressList({ ...addressList, districts: res.result || [] });
      if (childLookup?.lookupItemName === '') setAddressList({ ...addressList, communities: res.result || [] });
      if (childLookup?.lookupItemName === '') setAddressList({ ...addressList, subCommunities: res.result || [] });
    }
  }, [])

  useEffect(() => {
    getCurrencyLookups();
    getNameBankLookups();
    getAllUsers();
  }, []);


  useEffect(() => {
    if (!activeItem) getCountriesLookups();
    if (activeItem && initialAddressLookupsFetched) getAddressFromActiveItem();

  }, [activeItem, initialAddressLookupsFetched])





  useEffect(() => {
    if (activeItem && activeItem.userId) {
      getUserById(activeItem.userId);
    }
    if (activeItem && activeItem.branchId) getBranchInfoById(activeItem.branchId)
  }, [activeItem]);

  useEffect(() => {
    setSelectedModuleName(dublicatModule);
    setFinancialDetailState({ id: 'businessUnit', value: dublicatModule && dublicatModule.key || 0 });

  }, [dublicatModule])
  const saveHandler = async (event) => {
    event.preventDefault();
    let financialDetailsList = []
    state.branchFinancialBusinessUnits && state.branchFinancialBusinessUnits.map((el) => {
      if (!dublicatModule) el.financialDetails = el.financialDetails.filter((item) => (financialDetailState && financialDetailState.branchFinancialId) !== (item && item.branchFinancialId))
    })

    state.branchFinancialBusinessUnits && state.branchFinancialBusinessUnits.map((module) => {
      module.financialDetails.map((data) => {
        let obj = {
          businessUnit: module.businessUnit || 0,
          bankId: data.bankId,
          accountName: data.accountName,
          accountNumber: data.accountNumber,
          ibanCode: data.ibanCode,
          swiftCode: data.swiftCode,
          currencyId: data.currencyId,
          isBranchDefualt: false
        }
        if (module.businessUnit) financialDetailsList.push(obj)
      })

    })
    state.branchFinancial = financialDetailState.businessUnit ? [{ ...financialDetailState }, ...financialDetailsList] : [...financialDetailsList]
    delete state.branchFinancialBusinessUnits

    const saveState = { ...state };
    setIsSaveDisabled(true);
    setIsLoading(true);
    const res = (activeItem &&
      activeItem.branchId && await UpdateBranch({
        branchId: activeItem.branchId,
        body: saveState
      })) ||
      (await CreateBranch(saveState));
    if (!(res && res.status && res.status !== 200)) {
      setIsLoading(false);
      setIsSaveDisabled(false);

      if (activeItem && activeItem.branchId)
        showSuccess(t(`${translationPath}branch-updated-successfully`));
      else showSuccess(t(`${translationPath}branch-created-successfully`));
      if (onSave) onSave();
    } else {
      setIsSaveDisabled(false);
      setIsLoading(false);
      if (activeItem && activeItem.branchId) showError(t(`${translationPath}branch-update-failed`));
      else showError(t(`${translationPath}branch-create-failed`));
    } t(`${translationPath}branch-create-failed`)

  };

  const branchDataList = [
    {
      label: t(`branch`),
      component: BranchTabComponent,
    },
    {
      label: t(`Financial-Data`),
      component: FinancialDataComponent,
    },
  ]
  return (
    <>
      <Spinner isActive={isLoading} />
      <Dialog
        open={open}
        maxWidth='lg'
        onClose={() => {
          close();
        }}
        className=' branch-dialog-wrapper'
      >
        <form>
          <DialogTitle id='alert-dialog-slide-title'>
            <span>
              {t(`${translationPath}${(activeItem && 'edit-branch') || 'add-new-branch'}`)}
            </span>
          </DialogTitle>

          <DialogContent>
            <TabsComponent
              data={branchDataList}
              labelInput='label'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              themeClasses='theme-curved'
              currentTab={activeTab}
              onTabChanged={onTabChanged}
              dynamicComponentProps={{
                state,
                setState,
                isSubmitted,
                addressList,
                address,
                setAddress,
                getSubCommunityLookUps,
                getCommunityLookUps,
                getDistrictLookUps,
                getCitiesLookups,
                getLookupsOfParent,
                parentTranslationPath,
                translationPath,
                allUsers,
                isLoading,
                schema,
                currency,
                currencyValue,
                nameBank,
                bankNameValue,
                addressLoadings,
                searchHandler,
                selected,
                setSelected,
                setCurrencyValue,
                setBankNameValue,
                financialDetailState,
                setFinancialDetailState,
                initialModuleState,
                isChecked,
                setIsChecked,
                selectedModuleName,
                setSelectedModuleName,
                isOpenAddModule,
                setIsOpenAddModule,
                isDisabled,
                setIsDisabled,
                dublicatModule,
                setDublicatModule,
                schema
              }}
            />
          </DialogContent>

          <DialogActions>
            <ButtonBase
              onClick={() =>
                close()}
              className='btns theme-solid bg-cancel'
            >
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase className='btns theme-solid' disabled={isSaveDisabled} onClick={() => {
              setIsSubmitted(true);
              if (schema.error) {
                showError(t(`${translationPath}please-fill-required-fields`));
                return;
              }
              setIsOpenConfirm(true)
            }
            }>
              {t(`${translationPath}${(activeItem && 'edit-branch') || 'add-branch'}`)}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog >
      {isOpenConfirm && (
        <DialogComponent
          isOpen={isOpenConfirm}
          titleText={'confirm-changes'}
          maxWidth={'sm'}
          SmothMove
          dialogContent={
            <div className='d-flex-column-center confirmation-message mb-5'>
              <span className='mdi mdi-help-circle' />

              <span className='confirm-msg mt-2'>{t(`${translationPath}confirm-edit-message`)}</span>
            </div>
          }
          saveType='button'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          // onCloseClicked={() => setIsOpenConfirm(false)}
          onCancelClicked={() => setIsOpenConfirm(false)}
          onSaveClicked={saveHandler}
        />)}
    </>
  );
};
