
export const InquiryTypeIdEnum = {
    ZeroMatchingSale : {
      key: 'Sale',
      InquiryTypeId: 1,
      type :'Sale' 
    },
    ZeroMatchingLease: {
      key: 'Lease',
      InquiryTypeId: 2,
      type :'Lease'
    },
    ListingShortageSale : {
        key : 'Listing Shortage Sale' , 
        InquiryTypeId: 3  ,  
        type : 'Sale'
    } , 
    ListingShortageLease : {
        key : 'Listing Shortage Lease' , 
        InquiryTypeId: 4  , 
        type : 'Lease'
    }
  };
