
export function DisabledFiledValidation(state, TypePrerequisites,
  SuperAdminnNOT,
  ClientNOT,
  LeadAuditorNOT,
  lineManagerNOT,
  teamLeadNOT,
  CreatedFromNotificationByNOT,
  AssigntoNOT) {

  const DisabledValidation = (
    state.activityTypeName === '' ||
    state.categoryId === undefined || state.categoryId === null ||
    state.relatedTo === undefined ||
    (state.relatedTo.length && state.relatedTo.length) === 0 ||
    state.activityRate === undefined || state.activityRate === '' ||
    state.createActivityTypeRoles === undefined ||
    (state.createActivityTypeRoles && state.createActivityTypeRoles.length) === 0 ||
    (state.assignedToActivityTypeRoles && state.assignedToActivityTypeRoles.length) === 0 ||
    state.assignedToActivityTypeRoles === '' ||
    state.assignedToActivityTypeRoles === undefined || (
      state.isWithslaExpirationPeriod === true && (
        !state.slaExpirationPeriod ||
        !state.slaExpirationPeriodComparisonDate ||
        !state.slaExpirationPeriodFreqency
      )
    ) ||
    ((
      (SuperAdminnNOT.length !== 0 && !TypePrerequisites.SuperAdminTemplat.templateId ||
      SuperAdminnNOT.length === 0 && TypePrerequisites.SuperAdminTemplat.templateId
    )||
    (ClientNOT.length !== 0 && !TypePrerequisites.ClientTemplat.templateId ||
      ClientNOT.length === 0 && TypePrerequisites.ClientTemplat.templateId
    )||
    (LeadAuditorNOT.length !== 0 && !TypePrerequisites.LeadAuditorTemplat.templateId ||
      LeadAuditorNOT.length === 0 && TypePrerequisites.LeadAuditorTemplat.templateId
    )||
    (teamLeadNOT.length !== 0 && !TypePrerequisites.TeamLeadTemplat.templateId ||
      teamLeadNOT.length === 0 && TypePrerequisites.TeamLeadTemplat.templateId
    )||
    (lineManagerNOT.length !== 0 && !TypePrerequisites.lineManagerTemplat.templateId ||
      lineManagerNOT.length === 0 && TypePrerequisites.lineManagerTemplat.templateId
    )||
    (CreatedFromNotificationByNOT.length !== 0 && !TypePrerequisites.CreatedFromNotificationByTemplat.templateId ||
      CreatedFromNotificationByNOT.length === 0 && TypePrerequisites.CreatedFromNotificationByTemplat.templateId
    )||
    (AssigntoNOT.length !== 0 && !TypePrerequisites.AssignToTemplat.templateId ||
      AssigntoNOT.length === 0 && TypePrerequisites.AssignToTemplat.templateId
    )
    ))
  );
  return (DisabledValidation);
}
