import React, {useState, useEffect} from 'react'; 
import PropTypes from 'prop-types';
import { Inputs, SelectComponet } from '../../../../../../Components';

export const ExpiredPeriodComponent = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  expirationPeriodTimeType,
  setExpirationPeriodTimeType,
}) => {
  const timeTypes = [
    {
      key: 1,
      value: 'minutes',
    },
    {
      key: 2,
      value: 'hours',
    }];
  const [selectedExpiredPeriod, setSelectedExpiredPeriod] = useState(0)

  useEffect(()=>{

      if (expirationPeriodTimeType === 1) {
        const timeInMinutes = state.expiredPeriod;
        setSelectedExpiredPeriod(timeInMinutes);
      }
      
      if (expirationPeriodTimeType === 2) {
        const timeInHours = Math.floor(state.expiredPeriod / 60);
        setSelectedExpiredPeriod(timeInHours);
      }
      
  },[state.expiredPeriod])

  return (
    <div>
      <Inputs
        idRef='ExpiredPeriodRef'
        labelValue='ExpiredPeriod'
        labelClasses='Requierd-Color'
        value={+selectedExpiredPeriod || 0}
        inputPlaceholder={`${translationPath}Add-number`}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        min={0}
        max={+(state.expiredPeriod || 0)}
        onInputChanged={(event) => {
          let { value } = event.target;
          setSelectedExpiredPeriod(+value);

          if (expirationPeriodTimeType === 1) {
            setState(value);
          }
          if (expirationPeriodTimeType === 2) {
            const timeInMinutes = Math.floor(value * 60);
            setState(timeInMinutes);
          }

        }}
        endAdornment={
          <SelectComponet
            idRef='ExpiredPeriodTimeTypeRef'
            data={timeTypes}
            value={expirationPeriodTimeType || 1}
            valueInput='key'
            textInput='value'
            onSelectChanged={(timeTypeValue) => {
              setExpirationPeriodTimeType(+timeTypeValue);

              if (timeTypeValue === 1) {
                const timeInMinutes = Math.floor(selectedExpiredPeriod * 60);
                setSelectedExpiredPeriod(timeInMinutes);
                setState(timeInMinutes);
              }
              
              if (timeTypeValue === 2) {
                const timeInHours = Math.floor(selectedExpiredPeriod / 60);
                setSelectedExpiredPeriod(timeInHours);
                setState(timeInHours * 60);
              }

            }}
            wrapperClasses='over-input-select w-auto'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
          />
        }
      />
    </div>
  );
    }
ExpiredPeriodComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setState: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};
