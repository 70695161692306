import { config } from '../config';
import { HttpServices } from '../Helper';

export const GetAllTemplateCategoryServices = async (pageSize, pageIndex) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/SystemTemplate/GetAllTemplateCategory/${pageIndex + 1}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetSuitableTemplateIdForUnit = async (unitId, pageIndex, pageSize, unitOperationTypeId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/SystemTemplate/GetSuitableTemplateIdForUnit?unitId=${unitId}&unitOperationTypeId=${unitOperationTypeId}&pageIndex=${pageIndex}&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllSystemTemplateByCategoryId = async (categoryId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/SystemTemplate/GetAllSystemTemplateByCategoryId/${categoryId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllTemplatesByUnitId = async (unitId, unitOperationTypeId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/SystemTemplate/GetAllTemplatesByUnitId/${unitId}/${unitOperationTypeId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const RunSystemTemplate = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/SystemTemplate/RunSystemTemplate`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const RunSystemTemplateForUnit = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/SystemTemplate/RunSystemTemplateForUnit`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UploadHtmlFile = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/SystemTemplate/UploadHtmlFile`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UploadHtmlFile2 = async (file) => {
  const body = new FormData();
  body.append('htmlFile', file);

  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/SystemTemplate/UploadHtmlFile`,
      body
    );
    return result;
  } catch (e) {
    if (e.response && e.response) throw e.response;
    else throw e;
  }
};

export const CreateSystemTemplateAPI = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/SystemTemplate/CreateSystemTemplate`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateSystemTemplateFolder = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/SystemTemplate/CreateSystemTemplateFolder`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateSystemTemplateFolder = async (systemTemplateFolderId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/SystemTemplate/UpdateSystemTemplateFolder/${systemTemplateFolderId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllSystemTemplateFolders = async (pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/SystemTemplate/GetAllSystemTemplateFolders/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetSystemTemplateFolderById = async (systemTemplateFolderId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/SystemTemplate/GetSystemTemplateFolderById/${systemTemplateFolderId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const DeleteSystemTemplateFolder = async (systemTemplateFolderId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/SystemTemplate/DeleteSystemTemplateFolder/${systemTemplateFolderId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
