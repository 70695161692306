import { PowerBiEnum } from "../../../../Enums";
import {  LeaseTeamLeadDashboardPermissions  } from '../../../../Permissions' ; 
import { MainLeaseTeamsTabDashboards } from "../MainLeaseTeamsTabDashboards";

export const LeaseTeamsDashboardTabs = [
  {
    index: 0,
    label: "Dashboard:leaseteamlead",
    component: MainLeaseTeamsTabDashboards,
    config: {
      reportId: PowerBiEnum.LeaseTeamLeadDate.reportid,
      groupId: PowerBiEnum.LeaseTeamLeadDate.groupid,
      Url: PowerBiEnum.LeaseTeamLeadDate.url,
    },
    permissionsId : LeaseTeamLeadDashboardPermissions.LeaseTeamLeadDashboard.permissionsId, 
  },
  {
    index: 1,
    label: "Dashboard:leaseteamlead+",
    component: MainLeaseTeamsTabDashboards,
    config: {
      reportId: PowerBiEnum.LeaseTeamLeadplus.reportid,
      groupId: PowerBiEnum.LeaseTeamLeadplus.groupid,
      Url: PowerBiEnum.LeaseTeamLeadplus.url,
    },
  
    permissionsId : LeaseTeamLeadDashboardPermissions.LeaseTeamLeadPlusDashboard.permissionsId , 
  },
 
];
