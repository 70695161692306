export const ReminderToEnum = [
  {
    value: 0,
    text: 'agent',
    isDisabled: false,
  },
   {
    value: 1,
    text: 'client',
    isDisabled: false,
  },
];

