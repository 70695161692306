import React, { useEffect, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Spinner, SwitchComponent, Tables, PermissionsComponent
} from '../../../../Components';
import { returnPropsByPermissions, bottomBoxComponentUpdate } from '../../../../Helper';
import { useTitle } from '../../../../Hooks';
import { RotationConfigurationPermissions } from '../../../../Permissions';
import { changeStatusOfconfigrationSystemServices, GetAllSystemConfigurationsStatus } from '../../../../Services/SystemConfigrationServices/SystemConfigrationServices';

const parentTranslationPath = 'SystemConfigration';
const translationPath = '';

export const SystemConfigrationView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useTitle(t('Shared:system-configration'));
  const getAllSystemConfigurations = async () => {
    setIsLoading(true);
    if (returnPropsByPermissions(RotationConfigurationPermissions.ViewRotationConfiguration.permissionsId)) {
      const result = await GetAllSystemConfigurationsStatus();
      if (!(result && result.status && result.status !== 200))
        setStatus((result[0].systemConfigurationValue == 'True'));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllSystemConfigurations();
  }, []);

  const changeStatusOfconfigrationSystem = useCallback(async (body) => {
    setIsLoading(true);
    const result = await changeStatusOfconfigrationSystemServices(body);
    if (!(result && result.status && result.status !== 200))
      setStatus(!status);
    else
      setStatus(status);
    setIsLoading(false);
  }, [status]);

  const isAlloweToEdit = () => returnPropsByPermissions(RotationConfigurationPermissions.ViewRotationConfiguration.permissionsId);

  useEffect(() => {
    bottomBoxComponentUpdate(null);
  }, []);

  return (
    <>
      <Spinner isActive={isLoading} />
      <div className='headOfTabel' />
      <PermissionsComponent
        permissionsList={Object.values(RotationConfigurationPermissions)}
        permissionsId={RotationConfigurationPermissions.ViewRotationConfiguration.permissionsId}
      >
        <div className='tableConfigration'>
          <Tables
            data={[{
              key: `${t('rotation-status')}`,
              value: true
            },
            ]}
            headerData={[
              {
                id: 1,
                label: `${t('configration-name')}`,
                input: 'key',
              },
              {
                id: 2,
                label: `${t('status')}`,
                input: 'value',
                component: (item, index) => (
                  <SwitchComponent
                    idRef={`isOpenRef${index + 1}`}
                    isDisabled={!isAlloweToEdit()}
                    isChecked={status}
                    labelClasses='px-0'
                    onChangeHandler={() => changeStatusOfconfigrationSystem({ key: 'RotationStatus', value: !status })}
                    themeClass='theme-line'
                    labelValue={(status && `${t('open')}`) || `${t('close')}`}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                )
              },
            ]}
            defaultActions={[]}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
      </PermissionsComponent>
    </>
  );
};
