import React, {
 useState, useEffect, useCallback, useRef
} from 'react';
import {
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  Typography,
  Slider,
  Grid,
  Button,
  InputAdornment
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ChipInput from 'material-ui-chip-input';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { setTimeout } from 'core-js';
import {
  TitleRule,
  ContactRule,
  PropertyRule,
  PropertyOrUnitRule,
  OnchangeCountryRule,
  OnchangeCityRule,
  ContactDefaultRule,
  PropertyDefaultRule,
  OnchangeDistrictRule,
  OnchangeCommunityRule,
  LookupRule,
  OnchangeSubCommunityRule,
  UserRule,
  UserDefaultRule,
  ListingDateRule,
  AutoSelectContactRule,
  OnAddnewContactRule,
  OnAddnewPropertyRule,
  PassportRule,
  ActivityTypeRuleDefaultRule,
  ActivityTypeRule,
  StatusLeadDefaulRule1,
  OperationTypeRule,
  DateRule,
  CheckIsDateToAfterDateFromRule,
  CheckIsDateValidRule,
  ListingExpiryDateRule1,
  ListingDate1,
  SellerHasPaidRule,
  PriceSqmRule,
  BuiltupAreaRule,
  isSekeerlead,
  OnChangeOperationTypeForSeekerLead,
  LiabilityAmount1,
  MortgageDetailsRules1,
  ReferredToByLeadOperationRule,
  LeadsDefaultRule,
  LeadsRule,
  UnitModelRule,
  onChangePropertyRule,
  CampaignsRule,
  CampaignsDefaultRule,
  onChangeLeadsRule,
  RentDeposit,
  ShortageInRule,
  ConstructionDateRule,
  UnitRentedRule,
  ContactPreferenceRule,
  UnitRefNumberLeadOwnerRule,
  LeadConvertSizeSqftToSqmRule1,
  LeadConvertSizeSqmToSqftRule1,
  LeadConvertSizeSqftToSqmRuleSlider1,
  LeadConvertSizeSqmToSqftRuleSlider1,
  IsMortgageReletedModeOfPaymentRule,
  ModeOfPaymentRule,
 UnitRefNumberRule, SekeerLeadRule, FinancialStatusDetails1, RotationLeadRule, AutoFillLeadZeroMatchingRule, SensitiveFieldsForUserRule,
 ListingAndRentListingDateRule,
 ContactPreferenceDefaultRule,
} from '../../../../../Rule';
import { PhoneValidationRole } from '../../../../../Rule/PhoneRule';
import { duplicateEmailRole, duplicatePhoneRole } from '../../../../../Rule/EmailRule';
import PriceAndPercentage from '../PriceAndPercentage';
import { VisaRule } from '../../../../../Rule/VisaRule';
import { MapDialog } from '../../Dialogs/MapDialog';
import { UploadDialog } from '../../Dialogs/UploadDialog';
import { ModelsUnitsDialog } from '../../Dialogs/ModelsUnitsDialog';
import { RepeatedItemDialog } from '../../Dialogs/RepeatedItemDialog';
import { RepeatedLinkDialog } from '../../Dialogs/RepeatedLinkDialog';
import {
  Inputs,
  AutocompleteComponent,
  PhonesComponent,
  CheckboxesComponent,
  DatePickerComponent,
  UnitModelPopoverComponent,
} from '../../../../../Components';
import { ContactsDialog } from '../../Dialogs/ContactsDialog';
import { LeadOwnerDialog } from '../../Dialogs/LeadOwnerDialog';
import { DetailsDialog } from '../../Dialogs/DetailsDialog';
import { CONTACTS, PROPERTIES, UNITS } from '../../../../../config/pagesName';
import {
  OnchangePropertyInUnitRule,
  OnchangePropertyOnLeadRule,
  OnAddnewUnitRule,
  UnitDefaultRule,
  UnitRule,
  UnitForLeadOwnerRule,
  SaleTypDependingOnRule,
} from '../../../../../Rule/UnitRule';
import { TabRule, TabForLeadRule, TabForLeadRuleModeOfPayment } from '../../../../../Rule/TapRule';
import { SearchLookupRule } from '../../../../../Rule/SearchLookupRule';
import {
 GetParams, isEmptyObject, showError, showSuccess, AssignToInquiresHelper
} from '../../../../../Helper';
import { getIsFieldWithPermission } from '../../../../../Helper/ExceptionPermissions.Helper';
import { BathroomsAndBedroomsDefaultRule } from '../../../../../Rule/BathroomsAndBedroomsDefaultRule';
import { getValueToEditinUint } from '../../../../../Rule/UnitOperationTypeDefaultRule';
import {
 LeadTypeIdEnum, LeadsClassTypesEnum, UnitsOperationTypeEnum, FormsIdsEnum
} from '../../../../../Enums';
import { NotHavePolicyToDisplayDataDialog } from '../../Dialogs/NotHavePolicyToDisplayDataDialog';
import { LeaseLeadOwnerDialog } from '../../Dialogs/LeaseLeadOwnerDialog';

import {
 GetAdvanceSearchContacts, PropertiesAdvanceSearchTest, UnitsAdvanceSearchTest, GetSensitiveFieldsForUser
} from '../../../../../Services';
import { UnitMapper as LeaseUnitMapper } from '../../../UnitsView/UnitMapper';
import { UnitMapper } from '../../../UnitsSalesView/UnitSalesMapper/UnitMapper';

const ConvertJson = ({
  item,
  setData,
  setError,
  index,
  itemValue,
  itemList,
  selectedValues,
  isSubmitted,
  setJsonForm,
  jsonForm,
  setSteps,
  steps,
  setitemList,
  values,
  setIsLoading,
  leadOperation,
  seekerLead,
  activeFormType,
  parentTranslationPath , 
  translationPath , 
}) => {
  const { id } = item.field;

  const type = item.field.FieldType;

  const searchTimer = useRef(null);

  const label = (item && item.data && item.data.caption && item.data.caption !== '') ? item.data.caption : item.data.title;
  const [isSeekerLead, setIsSeekerLead] = useState(false);
  const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];
  const isRequired =
    (item.field.isRequired !== undefined && item.field.isRequired) ||
    (item.field.Required && item.field.Required.toUpperCase() === 'TRUE');
  const items = item.data.enum;
  const itemRegex = new RegExp(item.data.regExp);
  const [newValue, setNewValue] = useState(itemValue || item.data.valueToEdit || item.data.default);
  const [, setRerender] = useState(0);
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [inputValue, setInputValue] = useState('');
  const [DialogType, setDialogType] = useState(null);
  const [helperText, setHelperText] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [dataInSideViewButton, setDataInSideViewButton] = useState(null);
  const [errorMsg] = useState(item.data.errorMsg);
  const [unitModelPopoverAttachedWith, setUnitModelPopoverAttachedWith] = useState(null);
  const [timer, setTimer] = useState(null);
  const [isOpenLeadOwnerDialog, setIsOpenLeadOwnerDialog] = useState(false);
  const [leadOwnerValue, setLeadOwnerValue] = useState(null);
  const [isOpenLeaseLeadOwnerDialog, setisOpenLeaseLeadOwnerDialog] = useState(false);
  const [LeaseleadOwnerValue, setLeaseLeadOwnerValue] = useState(null);
  const [isOpenItemDetails, setIsOpenItemDetails] = useState(false);
  const [isSensitiveLoading, setIsSensitiveLoading] = useState(0);
  const [isSensitiveLoading2, setIsSensitiveLoading2] = useState(0);

  const [sensitiveFieldsForUser2, setSensitiveFieldsForUser2] = useState(null);
  const [isNotHavePolicyToDisplayData, setIsNotHavePolicyToDisplayData] = useState(false);
  const [isLoadingDetailsDialog, setIsLoadingDetailsDialog] = useState(false);

  const [, setNewItems] = useState(
    items ?
      items.map((ele, itemIndex) => ({
        label: ele,
        value: itemIndex,
        checked: false,
      })) :
      null
  );

  const [loading, setLoading] = useState(false);
  const Input = React.useRef(null);
  const time = setTimeout(() => { }, 300);
  const [, setLabelWidth] = useState(0);
  const [country] = useState('United Arab Emirates');

  const GetSensitiveFieldsForUserAPI = useCallback(async (formId, sensitiveFieldsForUser) => {
    const allSensitiveFields = await GetSensitiveFieldsForUser(formId, sensitiveFieldsForUser);

    if (!(allSensitiveFields && allSensitiveFields.status && allSensitiveFields.status !== 200)) {
      setSensitiveFieldsForUser2(allSensitiveFields);
      setIsSensitiveLoading2(1);
    } else {
      setSensitiveFieldsForUser2([]);
      setIsSensitiveLoading2(2);
    }
  });

  const onSearchFieldActionClicked = useCallback(async () => {
    setIsLoadingDetailsDialog(true);
    let havePolicyToDisplayDataInSideViewButton = false;
    const itemIndex = itemList.findIndex(
      (effectedItem) => effectedItem.data.title.replace('*', '').trim() === item.data.dependOn
    );
    let l = {};
    if (values[itemIndex] && itemValue)
      l = { ...itemValue, itemId: values[itemIndex] };
    else if (!values[itemIndex] && itemValue)
      l = { ...itemValue, itemId: (item && item.data && item.data.searchKey) };
    else if (values[itemIndex]) l = { itemId: values[itemIndex] };
    else l = { itemId: (item && item.data && item.data.searchKey) };
    // if (!itemValue && item.data.dependOn && !allItemsValues[itemIndex]) return;

    if (l.itemId && l.itemId.toLowerCase() === 'contact') {
      l.itemId = CONTACTS;

      if (!l.type)
        l.type = 1;
        const body = {
          criteria: {
            Ids:
            [{ searchType: 1, value: l && l.id }]
          },
          filterBy: 'createdOn',
          orderBy: 2,
        };

          const res = await GetAdvanceSearchContacts({ pageIndex: 0, pageSize: 2 }, body);
        if (!(res && res.status && res.status !== 200)) {
          if (res && res.totalCount > 0) {
            if (res && res.result && res.result.length && res.result[0].contactJson) {
              const contactJson = JSON.parse(res && res.result && res.result.length && res.result[0] && res.result[0].contactJson);
              havePolicyToDisplayDataInSideViewButton = true;
              if (havePolicyToDisplayDataInSideViewButton) {
                setDataInSideViewButton(contactJson);

                setTimeout(() => {
                  GetSensitiveFieldsForUserAPI(contactJson && contactJson.contact && contactJson.contact.contact_type_id, { key: 'contact', id: (res.result[0].contactId) });
                }, 0);
              }
            }
          } else {
            havePolicyToDisplayDataInSideViewButton = false;
            setDataInSideViewButton(null);
          }
        } else {
          havePolicyToDisplayDataInSideViewButton = false;
          setDataInSideViewButton(null);
        }
    }

    if (l.itemId && l.itemId.toLowerCase() === 'property') {
      l.itemId = PROPERTIES;
      l.type = '1';
      const body = {
        criteria: {
          Ids:
          [{ searchType: 1, value: (l && l.id) }]
        },
        filterBy: 'createdOn',
        orderBy: 2,
      };

      const res = await PropertiesAdvanceSearchTest({ pageIndex: 0, pageSize: 2 }, body);
      if (!(res && res.status && res.status !== 200)) {
        if (res && res.totalCount > 0) {
          if (res && res.result && res.result.length && res.result[0].propertyJson) {
            const propertyJson = JSON.parse(res && res.result && res.result.length && res.result[0].propertyJson);
            havePolicyToDisplayDataInSideViewButton = true;
            if (havePolicyToDisplayDataInSideViewButton) {
              setDataInSideViewButton(propertyJson);
              setTimeout(() => {
                GetSensitiveFieldsForUserAPI(FormsIdsEnum.properties.id, { key: 'property', id: res.result[0].propertyId });
              }, 0);
            }
          }
        } else {
          havePolicyToDisplayDataInSideViewButton = false;
          setDataInSideViewButton(null);
        }
      } else {
        havePolicyToDisplayDataInSideViewButton = false;
        setDataInSideViewButton(null);
      }
    }
    if (l.itemId && l.itemId.toLowerCase() === 'unit') {
      const operationType = (values && values.unit_ref_number && values.unit_ref_number.operation_type_name) || (values && values.lead_operation && values.lead_operation === 'Seller' ? 'Sale' : 'Rent');
      localStorage.setItem('unitModelRelatedData', JSON.stringify({ operation_type: { lookupItemName: ((itemValue && itemValue.operationType) || operationType || null) } }));
      l.itemId = UNITS;
      l.operationType = (itemValue && itemValue.operationType) || operationType || null;
      const body = {
        OperationType: (l && l.operationType && l.operationType === 'Sale') ? 'Sale' : 'Lease',
        criteria: {
          unitId: [{ searchType: 1, value: (l && l.id) }]
        },
        filterBy: 'createdOn',
        orderBy: 2,
      };

      const res = await UnitsAdvanceSearchTest({ pageIndex: 0, pageSize: 2 }, body);
      if (!(res && res.status && res.status !== 200)) {
        if (res && res.totalCount > 0) {
          if (res && res.result && res.result.length && res.result[0] && res.result[0].unitJson) {
            const unitJson = JSON.parse(res && res.result && res.result.length && res.result[0].unitJson);
            const unitDatails = ((operationType === UnitsOperationTypeEnum.sale.key) || (operationType === UnitsOperationTypeEnum.rentAndSale.key)) ? UnitMapper({ ...unitJson, ...res.result[0] }) : LeaseUnitMapper({ ...unitJson, ...res.result[0] });
            localStorage.setItem('unitModelRelatedData', JSON.stringify(unitDatails));
            havePolicyToDisplayDataInSideViewButton = true;
            if (havePolicyToDisplayDataInSideViewButton) {
              const unitId = l && l.id;
              setDataInSideViewButton(unitJson);
              setTimeout(() => {
              GetSensitiveFieldsForUserAPI(FormsIdsEnum.units.id, { key: 'unit', id: unitId });
              }, 0);
            }
          }
        } else {
        havePolicyToDisplayDataInSideViewButton = false;
        setDataInSideViewButton(null);
       }
      } else {
        havePolicyToDisplayDataInSideViewButton = false;
        setDataInSideViewButton(null);
      }
    }

    localStorage.setItem('current', JSON.stringify(l));
    if (!itemValue)
      setIsOpenContactsDialog(true);
     else if (havePolicyToDisplayDataInSideViewButton)
      setIsOpenItemDetails(true);

      else if (!havePolicyToDisplayDataInSideViewButton)
         setIsNotHavePolicyToDisplayData(true);

     setIsLoadingDetailsDialog(false);
  }, [itemList, values, item.data.dependOn, item.data.searchKey, itemValue]);

  useEffect(() => {
    if (item.field.id === 'listing_expiry-date') {
      const listingExpiryDate = values && values.listing_expiry_date && new Date(values.listing_expiry_date);
      if (listingExpiryDate) setNewValue(listingExpiryDate);
      else if (!listingExpiryDate)
        setNewValue(undefined);
       else setNewValue();
    } else if (item.field.id === 'rent_listing-expiry-date') {
      const rentListingExpiryDate = values.rent_listing_expiry_date && new Date(values.rent_listing_expiry_date);
      if (rentListingExpiryDate) setNewValue(rentListingExpiryDate);
      else if (!rentListingExpiryDate)
        setNewValue(undefined);
       else setNewValue();
    } else if (item.field.id === 'date_of-establishment') {
      const dateOfEstablishment = values.date_of_establishment && new Date(values.date_of_establishment);
      if (dateOfEstablishment) setNewValue(dateOfEstablishment);
      else if (!dateOfEstablishment)
        setNewValue(undefined);
       else setNewValue();
    } else if (item.field.id === 'size_sqm') {
      const sizeSqm = values && values.size_sqm;
      if (sizeSqm) setNewValue(sizeSqm);
      else if (!sizeSqm)
        setNewValue(undefined);
       else setNewValue(undefined);
    } else if (item.field.id === 'size_sqft') {
      const sizeSqft = values && values.size_sqft;
      if (sizeSqft) setNewValue(sizeSqft);
      else if (!sizeSqft)
        setNewValue(undefined);
       else setNewValue(undefined);
    }
  }, [values]);

  useEffect(() => {
    if (itemValue)
      setData(itemValue);
     else if (item.data.valueToEdit) {
      if (
        item.data.valueToEdit &&
        Object.keys(item.data.valueToEdit).length === 0 &&
        item.data.valueToEdit.constructor === Object
      )
        setNewValue(null);

       else {
        setData(index, ((itemValue === undefined || itemValue === null) ? null : item.data.valueToEdit));
        setNewValue(itemValue);
        TabRule(
          item,
          setJsonForm,
          jsonForm,
          item.data.valueToEdit,
          setSteps,
          steps,
          itemList,
          setitemList
        );
      }
    } else if (item.data.default)
      setData(index, item.data.default);

    if (Input.current !== null) setLabelWidth(Input.current.offsetWidth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, item.data.default, item.data.valueToEdit]);

  const getIsMortgage = () => {
    if ((item.field.id === 'is_mortgage' && values && values.leadClass && values.leadClass === 'Tenant') || (item.field.id === 'is_mortgage' && values.operation_type && values.operation_type.lookupItemName === 'Rent'))
      return true;
    if ((item.field.id === 'mode_of-payment' && values && values.leadClass && values.leadClass === 'Tenant') || (item.field.id === 'mode_of-payment' && values.operation_type && values.operation_type.lookupItemName === 'Rent'))
    return true;

    return false;
  };

  const getIsLeadAuditor = () => {
    if ((item.field.id === 'lead_auditor'))
      return true;
    return false;
  };

  const disableQualification = () => 
  {
     if(item.field.id === 'qualification') 
      return true ; 
    else return false  ; 
   
  };

  const getStatusValue = () => {
    const statusIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'status'));
    if (item.field.id === 'referredto' || item.field.id === 'close_reason') {
      if (statusIndex !== -1)
        return !!((values[statusIndex] && values[statusIndex].lookupItemName === 'Closed'));
    }
    return false;
  };

  const getSendLeadToRotationSelected = () => {
    const sendToRotationIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'send_to-rotation'));
    if (sendToRotationIndex !== -1 && item.field.id === 'referredto' && values && values.send_to_rotation && values.send_to_rotation === 'Yes')
       return true;
    return false;
  };

  const getOperationTypeValue = () => {
    if (item.field.id === 'operation_type' && GetParams('id') && !(item.data.specialKey && item.data.specialKey === 'seeker_lead')) {
      const unitModelRelatedData = localStorage.getItem('activeItem');
      if (unitModelRelatedData) {
        const unitModelRelatedDataJson = JSON.parse(unitModelRelatedData);
        if (unitModelRelatedDataJson && unitModelRelatedDataJson.operation_type && unitModelRelatedDataJson.operation_type.lookupItemName === 'SaleAndRent')
          return true;
      }
    }
    return false;
  };

  const getLeadOperationValue = () => {
    if (item.field.id === 'unit_ref-number' && GetParams('id') && LeadTypeIdEnum.Owner.leadTypeId === +GetParams('formType'))
      return true;
    if (item.field.id === 'lead_operation' && GetParams('id'))
      return true;
    return false;
  };

  const getLeadOwnerLeadOperationValue = () => {
    if (item.field.id === 'lead_operation' && GetParams('id') && LeadTypeIdEnum.Owner.leadTypeId === +GetParams('formType'))
      return true;
    if (item.field.id === 'lead_operation' && GetParams('id'))
      return true;
    return false;
  };

  const getLeadOwnerLeadOperationIsSale = () => {
    const saleTypeIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'sale_type' && f.data.specialKey === 'sale_type_lead_owner'));
    const leadOperationIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'lead_operation'));

    if (saleTypeIndex !== -1 && leadOperationIndex !== -1 && values.lead_operation && values.lead_operation === 'Seller')
      return false;

    return true;
  };

  const displySearchField = () => {
    const fieldDto = item.data.DtoName;
    if (fieldDto === 'assign_to_listing_shortage' ||
      fieldDto === 'assign_to_zero_matching_sale' ||
      fieldDto === 'assign_to_zero_matching_lease'
    ) return getIsFieldWithPermission(item);

   return true;
  };

  const displySelectFields = () => {
    if (item.field.id === 'sale_type' && item.data.specialKey === 'sale_type_lead_owner')
       return getLeadOwnerLeadOperationIsSale();
     if (item.field.id === 'close_reason')
       return getLeadStatusValue();

   return false;
  };

  const displyTextField = () => {
    if (id !== 'rented_value' && (item.field.FieldType === 'textField' && (item.data.specialKey === 'currency' || item.data.specialKey === 'size' || item.data.specialKey === 'decimal')))
      return true;

    if ((item.field.FieldType === 'textField' && item.data.specialKey === 'currency') && (id === 'rented_value' && values && values.is_unit_rented && values.is_unit_rented === 'Yes'))
       return true;

   return false;
  };

  const getStatusValue2 = () => {
    const statusIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'status'));
    const status = 'status';
    if (item.field.id === 'close_reason') {
      const closeReasonIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'close_reason'));

      if (statusIndex !== -1) {
        if ((values[status] && values[status].lookupItemName === 'Open') || (isEmptyObject(values)) || !values[status]) {
          if (closeReasonIndex !== -1) {
            setTimeout(() => {
            }, 1000);
          }
          return true;
        }
      }
    } else if (item.field.id === 'closing_remarks') {
      const closingRemarksIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'closing_remarks'));
      if (statusIndex !== -1) {
        if ((values[status] && values[status].lookupItemName === 'Open') || (isEmptyObject(values)) || !values[status]) {
          if (closingRemarksIndex !== -1) {
            setTimeout(() => {
            }, 1000);
          }
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (id === 'property_name') {
     setTimeout(() => {
        SekeerLeadRule(itemList, values);
      }, 200);
    }

    if (item.field.id === 'contact_preference' && ((values && values.contact_preference && !values.contact_preference.length) || !(values.contact_preference))) {
      setTimeout(() => {
        ContactPreferenceDefaultRule(item, setData, 1);
       }, 1800);
    }

    if (GetParams('id')) {
      if ((item.field.id === 'bathrooms' && item.field.FieldType === 'select') || (item.field.id === 'bedrooms' && item.field.FieldType === 'select')) {
        setTimeout(() => {
          BathroomsAndBedroomsDefaultRule(item, setRerender, itemList, values, setData, setNewValue);
        }, 500);
      }
      if (item.field.id === 'is_mortgage') {
         setTimeout(() => {
          TabForLeadRule(
            item,
            setJsonForm,
            jsonForm,
            ((values && values.is_mortgage) || 'No') || 'No',
            setSteps,
            steps,
            itemList,
            setitemList,
            values,
            setData,
          );
         }, 200);
      }
    }

    if (item.field.id === 'listing_date') {
      setTimeout(() => {
        if (values && values.listing_date)
          ListingDate1(id, itemList, values.listing_date);
      }, 100);
    } else if (item.field.id === 'rent_listing-date') {
      setTimeout(() => {
        if (values && values.rent_listing_date)
          ListingDate1(id, itemList, values.rent_listing_date);
      }, 100);
    }
    if (sensitiveFieldsForUser2 && sensitiveFieldsForUser2.length > 0) {
      setTimeout(() => {
        SensitiveFieldsForUserRule(itemList, sensitiveFieldsForUser2);
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (item.field.id === 'seller_has-paid' || item.field.id === 'selling_price-agency-fee') {
      setTimeout(() => {
        SellerHasPaidRule(item, (values && values.seller_has_paid) || 0, setRerender, values, setData, setNewValue);
      }, 100);
    }
    if (id === 'property_name') {
      setTimeout(() => {
        // SekeerLeadRule(itemList,values);
      }, 500);
    }
    if (id === 'customer_profile') {
      setTimeout(() => {
        MortgageDetailsRules1(item, values && values.customer_profile, setData, itemList, values);
      }, 500);
    }

    if (id === 'sale_type' && item.data.specialKey !== 'sale_type_lead_owner') {
      setTimeout(() => {
        SaleTypDependingOnRule(item, (values && values.operation_type) || null, itemList, setRerender);
      }, 100);
    }
    if (item.field.id === 'is_mortgage') {
      // setTimeout(() => {
        TabForLeadRule(
          item,
          setJsonForm,
          jsonForm,
          ((values && values.is_mortgage) || 'No') || 'No',
          setSteps,
          steps,
          itemList,
          setitemList,
          values,
          setData,
        );
}
  }, [item.field.id]);

  const [openMapDialog, setOpenMapDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [modelsUnitsDialog, setModelsUnitsDialog] = useState(false);
  const [RepeatedDialog, setRepeatedDialog] = useState(false);
  const [repeatedLinkDialog, setRepeatedLinkDialog] = useState(false);
  const [isOpenContactsDialog, setIsOpenContactsDialog] = useState(false);
  const openUnitModelPopoverHandler = useCallback((event) => {
    setUnitModelPopoverAttachedWith(event.currentTarget);
  }, []);
  const onPopoverClose = useCallback(() => {
    setUnitModelPopoverAttachedWith(null);
  }, []);

  useEffect(() => {
     if (item.data.lookup)
     LookupRule(item, itemList);
  }, [item]);

  useEffect(() => {
    if (itemValue && Object.keys(itemValue).length === 0 && itemValue.constructor === Object) {
     // setInputValue('');
      setNewValue(null);
      setData(index, null);
    } else if (itemValue === '  ') {
      setInputValue('');
      setNewValue(null);
      setData(index, null);
    }
  }, [itemValue, values]);

  useEffect(() => {
    if (itemValue && !newValue) {
      if (Object.keys(itemValue).length === 0 && itemValue.constructor === Object) {
        setInputValue('');
        setNewValue(null);
      } else setNewValue(itemValue);
    } else if (typeof (newValue) !== 'boolean' && newValue && !itemValue)
      setData(index, newValue);
     else if (itemValue === '  ' || itemValue === ' ') {
      setInputValue('');
      setNewValue(null);
      setData(index, null);
    }
  }, [setData, itemValue]);

  useEffect(() => {
    const contactId = +GetParams('contactId');
    if (contactId && (item && item.data && item.data.title && !(item.data.title === 'Developer')))
      AutoSelectContactRule(item, setRerender, contactId, setData, index, setNewValue);
    if (pathName.includes('add')) {
      TabForLeadRule(
        item,
        setJsonForm,
        jsonForm,
        ((values && values.is_mortgage) || 'No'),
        setSteps,
        steps,
        itemList,
        setitemList,
        values,
        setData,
      );
    }
    const leadId = +GetParams('leadId');
    if (leadId && GetParams('formType') && item.field.id === 'lead') {
      AutoFillLeadZeroMatchingRule(item,
        setRerender,
        leadId,
        itemList,
        setData);
    }
  }, []);

  const getAreaValue = () => {
    // const builtupAreaSqftIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'builtup_area-sqft'));
    // if (builtupAreaSqftIndex !== -1)
    //   return +values[builtupAreaSqftIndex];
    const builtupAreaSqft = 'builtup_area-sqft';
    if (values && values[builtupAreaSqft])
      return +values[builtupAreaSqft];

    return 0;
  };
  const getSqrSalePrice = () => {
    // const priceSqmIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'pricesqm'));
    // if (priceSqmIndex !== -1 && values[priceSqmIndex]) {
    //   const sqmPrice = +values[priceSqmIndex];
    //   return sqmPrice;
    // }
    if (values && values.pricesqm)
      return +values.pricesqm;

    return 0;
  };

  const getLeadStatusValue = () => {
    const statusIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'status'));
    const status = statusIndex !== -1 && itemList[statusIndex].field.id;
    if (status !== -1 && values && values[status] && (values[status].lookupItemName === 'Open' || !values[status]))
      return true;
    return false;
  };

  useEffect(() => {
    setTimeout(() => {
      const leadStatus = localStorage.getItem('leadStatus');
      if (leadStatus) {
        const leadStatusJson = JSON.parse(leadStatus);
        if (item.field.id === 'status' && +GetParams('id') && leadStatusJson && leadStatusJson.lookupItemName === 'Closed' && !GetParams('contactId'))
          item.data.isReadonly = true;
        else if (item.field.id === 'status' && GetParams('id') && leadStatusJson && leadStatusJson.lookupItemName === 'Open' && !GetParams('contactId'))
          item.data.isReadonly = false;
        else if (+GetParams('id') && id === 'close_reason' || (GetParams('id') && id === 'closing_remarks')) {
          const closeReasonIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'close_reason'));
          const closingRemarksIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'closing_remarks'));

          if (leadStatusJson && leadStatusJson.lookupItemName === 'Closed' && closeReasonIndex !== -1 && values[itemList[closeReasonIndex].field.id])
            item.data.isReadonly = true;
          else if (leadStatusJson && leadStatusJson.lookupItemName === 'Closed' && closingRemarksIndex !== -1 && values[itemList[closingRemarksIndex].field.id])
            item.data.isReadonly = true;
          else
            item.data.isReadonly = false;
        }
      }
    }, 300);
  }, [item]);

  const getDataEnumList = (item) => {
    if (item.field.id === 'operation_type' && (item.data.specialKey && item.data.specialKey === 'UnitOperationType')) {
      let oprationList = [
        {
          lookupItemId: 430,
          lookupItemName: 'Sale',
          lookupItemCode: null,
          description: '430',
          order: 99,
          parentLookupItemId: null,
          parentLookupItemName: null,
          parentLookupTypeId: 0,
          parentLookupTypeName: null,
          isEditable: false,
          lookupItemParents: null,
          createdBy: 'A574EDAA-F2D5-4FC5-AD0D-B32CBA3978F3',
          createdByName: null,
          createdOn: '2020-04-19T00:00:00',
          updatedBy: '38b018f8-9719-4f30-a62f-f76e64c0c847',
          updatedByName: null,
          updateOn: '2020-04-19T00:00:00'
        },
        {
          lookupItemId: 431,
          lookupItemName: 'Rent',
          lookupItemCode: null,
          description: '431',
          order: 99,
          parentLookupItemId: null,
          parentLookupItemName: null,
          parentLookupTypeId: 0,
          parentLookupTypeName: null,
          isEditable: false,
          lookupItemParents: null,
          createdBy: 'A574EDAA-F2D5-4FC5-AD0D-B32CBA3978F3',
          createdByName: null,
          createdOn: '2020-04-19T00:00:00',
          updatedBy: '38b018f8-9719-4f30-a62f-f76e64c0c847',
          updatedByName: null,
          updateOn: '2020-04-19T00:00:00'
        },
        {
          lookupItemId: 20984,
          lookupItemName: 'SaleAndRent',
          lookupItemCode: null,
          description: '20984',
          order: 99,
          parentLookupItemId: null,
          parentLookupItemName: null,
          parentLookupTypeId: 0,
          parentLookupTypeName: null,
          isEditable: false,
          lookupItemParents: null,
          createdBy: 'A574EDAA-F2D5-4FC5-AD0D-B32CBA3978F3',
          createdByName: null,
          createdOn: '2021-02-18T12:24:04.2590059',
          updatedBy: '38b018f8-9719-4f30-a62f-f76e64c0c847',
          updatedByName: null,
          updateOn: '2021-02-25T16:28:18.0526483'
        }
     ];

      const leadOperationData = leadOperation;
      if (leadOperationData) {
        if (leadOperationData === 'Seller')
          oprationList = oprationList.filter((t) => t.lookupItemName === 'Sale' || t.lookupItemName === 'SaleAndRent');
        else if (leadOperationData === 'Landlord')
          oprationList = oprationList.filter((t) => t.lookupItemName === 'Rent' || t.lookupItemName === 'SaleAndRent');
        return oprationList;
      }
    }
    if (id === 'lead_type') {
      const filterlist = [{
        lookupItemId: 495,
        lookupItemName: 'Owner',
        lookupItemCode: null,
        description: '495',
        order: 99,
        parentLookupItemId: null,
        parentLookupItemName: null,
        parentLookupTypeId: 0,
        parentLookupTypeName: null,
        isEditable: false,
        lookupItemParents: null,
        createdBy: 'A574EDAA-F2D5-4FC5-AD0D-B32CBA3978F3',
        createdByName: null,
        createdOn: '2020-04-19T00:00:00',
        updatedBy: '38b018f8-9719-4f30-a62f-f76e64c0c847',
        updatedByName: null,
        updateOn: '2020-04-19T00:00:00'
      }];
      return filterlist;
    }
    if (id === 'contact_preference') {
      const list = [
        { lookupItemId: 1993, lookupItemName: 'Select All' },
        { lookupItemId: 18169, lookupItemName: 'Email' },
        { lookupItemId: 18170, lookupItemName: 'SMS' },
        { lookupItemId: 18171, lookupItemName: 'WhatsApp' },
        { lookupItemId: 18172, lookupItemName: 'Call' }];
       return list;
    }

    return item.data.enum;
  };

  const financialStatusDetailsRules = () => {
    if ((item.field.id === 'auto_loan-outstanding-balance' || item.field.id === 'auto_loan-monthly-installment') && (values.has_auto_loan === 'No' || !values.has_auto_loan))
      return true;

    if ((item.field.id === 'personal_loan-outstanding-balance' || item.field.id === 'personal_loan-monthly-installment') && (values.has_personal_loan === 'No' || !values.has_personal_loan))
      return true;

    if ((item.field.id === 'home_loan-outstanding-balance' || item.field.id === 'home_loan-monthly-installment') && (values.has_home_loan === 'No' || !values.has_home_loan))
      return true;

    if ((item.field.id === 'credit_card-outstanding-balance' || item.field.id === 'credit_card-monthly-installment') && (values.has_credit_card === 'No' || !values.has_credit_card))
      return true;

    if ((item.field.id === 'other_liabilities-outstanding-balance' || item.field.id === 'other_liabilities-monthly-installment') && (values.has_other_liabilities === 'No' || !values.has_other_liabilities))
      return true;

    if ((item.field.id === 'liability_amount'))
      return true;

    return false;
  };

  const customerProfileSelected = () => {
    if ((item.field.id === 'confirmed_employee') && (!values.customer_profile))
      return true;

    if ((item.field.id === 'allowance') && (!values.customer_profile))
      return true;

    if ((item.field.id === 'liability_from-company') && (!values.customer_profile))
      return true;

    if (item.field.id === 'company_name' && !values.customer_profile && item.data.specialKey !== 'contact_company_name')
      return true;
    if ((item.field.id === 'monthly_income') && (!values.customer_profile))
      return true;

    if ((item.field.id === 'yearly_income') && (!values.customer_profile))
      return true;
    if ((item.field.id === 'date_of-establishment') && (!values.customer_profile))
      return true;
    if ((item.field.id === 'number_of-partner') && (!values.customer_profile))
      return true;

    return false;
  };

  let component = <></>;
  switch (type) {
    case 'address':
      if (item && item.data && item.data.uiType) {
        switch (item.data.uiType) {
          case 'text':
            component = (
              <div className='helper-Information'>
                <Inputs
                  idRef={id}
                  isRequired={isRequired}
                  isDisabled={item.data.isReadonly}
                  labelValue={label}
                  value={newValue ? newValue.value : ''}
                  labelClasses={isRequired ? 'c-red' : ''}
                  isSubmitted={isSubmitted}
                  isWithError
                  helperText={helperText}
                  error={helperText !== '' || (isRequired && (!newValue || newValue.value === ''))}
                  onInputChanged={(e) => {
                    setHelperText('');
                    setError(index, '');
                    if (!itemRegex.test(e.target.value)) {
                      setHelperText(errorMsg);
                      setError(index, errorMsg);
                    }
                    setNewValue({ value: e.target.value, type: item.data.addressType });
                    setData(index, { value: e.target.value, type: item.data.addressType });
                    if (!duplicateEmailRole(item, itemList, selectedValues)) {
                      setHelperText('Duplicate Email Value');
                      setError(index, 'Duplicate Email Value');
                    }
                  }}
                />
                {
                  item.data.HelperInfo && item.data.HelperInfo !== '' && (
                    <div className='information-open-file-2'>
                      <Tooltip
                        size='larg'
                        title={item.data.HelperInfo}
                      >
                        <span className='mdi mdi-information-outline' />
                      </Tooltip>
                    </div>
                  )
}
              </div>
            );
            break;
          case 'map':
            component = (
              <div className='helper-Information'>
                <Inputs
                  idRef={id}
                  isRequired={isRequired}
                  isDisabled
                  labelValue={label}
                  labelClasses={isRequired ? 'c-red' : ''}
                  value={itemValue ? `${itemValue.latitude} , ${itemValue.longitude}` : ''}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={helperText}
                  error={helperText !== '' || (isRequired && (!newValue || newValue === ''))}
                  buttonOptions={{
                    className: 'btns-icon theme-outline c-blue-lighter',
                    iconClasses: 'mdi mdi-map-marker',
                    isDisabled: false,
                    isRequired: false,
                    onActionClicked: () => {
                      setOpenMapDialog(true);
                    },
                  }}
                />
                {itemValue && openMapDialog && (
                  <MapDialog
                    open={openMapDialog}
                    onChange={(e) => {
                      setNewValue({ ...e });
                      setData(index, { ...e });
                    }}
                    initialState={itemValue}
                    closeDialog={() => {
                      setOpenMapDialog(false);
                    }}
                  />
                )}
                {
                  item.data.HelperInfo && item.data.HelperInfo !== '' && (
                    <div className='information'>
                      <Tooltip
                        size='larg'
                        title={item.data.HelperInfo}
                      >
                        <span className='mdi mdi-information-outline' />
                      </Tooltip>
                    </div>
                  )
}
              </div>
            );
            break;
          case 'select':
            component = (
              <div className='helper-Information'>
                <AutocompleteComponent
                  idRef={id}
                  isRequired={isRequired}
                  isDisabled={
                    loading ||
                    getIsMortgage() ||
                    customerProfileSelected() ||
                    item.data.isReadonly ||
                    item.data.specialKey === 'IsDisabled' ||
                    item.data.specialKey === 'UnitRefNo'
                  }
                  isLoading={loading}
                  withLoader
                  multiple={item.data.multi ? item.data.multi === 'true' : false}
                  selectedValues={
                    item.data.multi && item.data.multi === 'true' ?
                      (itemValue && Array.isArray(itemValue) && [...itemValue]) || [] :
                      itemValue || null
                  }
                  data={item.data.enum}
                  inputClasses='inputs theme-form-builder'
                  labelClasses={isRequired ? 'c-red' : ''}
                  displayLabel={(option) =>
                    (!item.data.lookup ? option : option && option.lookupItemName || undefined) || ''}
                  chipsLabel={
                    (item.data.multi &&
                      item.data.multi === 'true' &&
                      ((option) => (!item.data.lookup ? option : option && option.lookupItemName || undefined))) ||
                    undefined
                  }
                  getOptionSelected={(option) =>
                    (item.data.multi &&
                      item.data.multi === 'true' &&
                      itemValue.findIndex(
                        (element) =>
                          (element.lookupItemId && element.lookupItemId === option.lookupItemId) ||
                          option === element
                      ) !== -1) ||
                    (itemValue.lookupItemId && itemValue.lookupItemId === option.lookupItemId) ||
                    option === itemValue}

                  onInputKeyUp={(event) => {
                    const { value } = event.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                       SearchLookupRule(item, value, itemList, setData, setRerender, setLoading);
                    }, 700);
                  }}
                  onKeyDown={() => {
                    clearTimeout(searchTimer.current);
                  }}
                  onChange={async (event, v) => {
                    if (item.field.id === 'status') {
                      if ((v && v.lookupItemName === 'Open') || !v)
                        StatusLeadDefaulRule1(item, setRerender, itemList, values, setData);
                    }
                    const isSekeerlead = +GetParams('formType') === LeadTypeIdEnum.Seeker.leadTypeId;
                    if (id === 'operation_type' && item.data.DtoName === 'seeker_lead')
                    OnChangeOperationTypeForSeekerLead(item, v, itemList, setData, setRerender, values, 1);
                    openUnitModelPopoverHandler(event);
                    // ListingDateRule(id ,item,itemList, v, values ,setData) ;
                    TitleRule(id, setData, item, itemList, v);
                    TabRule(item, setJsonForm, jsonForm, v, setSteps, steps, itemList, setitemList);
                    await OnchangeCountryRule(id, setData, item, itemList, v, 1);
                    await OnchangeCityRule(id, v, itemList, setData, item, 1);
                    await OnchangeDistrictRule(id, setData, item, itemList, v, 1);
                    await getValueToEditinUint(item, setRerender, itemList, values, setData);
                    await OnchangeCommunityRule(id, setData, item, itemList, v, 1);
                    await OnchangeSubCommunityRule(id, setData, item, itemList, v, 1);
                    if (v === null) {
                      setNewValue(v);
                      setData(index, {});
                      return;
                    }
                    setNewValue(v);
                    setData(index, v);
                  }}
                  withoutSearchButton
                  labelValue={label}
                />

                {item.data.HelperInfo && item.data.HelperInfo !== '' && (
                  <div className='information'>
                    <Tooltip
                      size='larg'
                      title={item.data.HelperInfo}
                    >
                      <span className='mdi mdi-information-outline' />
                    </Tooltip>
                  </div>
                )}
              </div>
            );
            break;
          default:
            break;
        }
      }
      break;

    case 'communication':
      switch (item.data.CommunicationType) {
        case 'Phone':
          component = (
            <div className='helper-Information'>
              <PhonesComponent
                idRef={id}
                isValid={() => PhoneValidationRole(newValue ? newValue.phone : '') && isValidPhone}
                labelValue={label}
                labelClasses={isRequired ? 'c-red' : ''}
                country={country}
                required={isRequired}
                helperText={helperText}
                error={(helperText !== '') || (isRequired && (!newValue || newValue === ''))}
                value={
                  newValue === null || newValue === undefined || !newValue.phone ?
                    item.data.defaultCountryCode :
                    newValue.phone
                }
                onInputChanged={(value) => {
                  if (value.length > 14) return;
                  if (selectedValues[index] && value === '') {
                    setNewValue(null);
                    setData(index, null);
                    return;
                  }
                  if (selectedValues[index] && selectedValues[index].others) {
                    setNewValue({
                      ...selectedValues[index],
                      phone: value,
                      typeId: item.data.lookupItem,
                    });
                    setData(index, {
                      ...selectedValues[index],
                      phone: value,
                      typeId: item.data.lookupItem,
                    });
                  } else {
                    setNewValue({ phone: value, typeId: item.data.lookupItem });
                    setData(index, { phone: value, typeId: item.data.lookupItem });
                  }
                }}
                onKeyUp={() => {
                    if (searchTimer.current)
                    clearTimeout(searchTimer.current);

                    searchTimer.current = setTimeout(async () => {
                      if (!newValue && !(newValue && newValue.phone)) return;
                      const isPhoneRole = await PhoneValidationRole(newValue.phone);
                      const isDuplicate = await duplicatePhoneRole(item, newValue.phone);
                      if (!isPhoneRole) {
                        setIsValidPhone(isPhoneRole);
                        setHelperText('Phone number must be 6 And above');
                        setError(index, 'Phone number must be  6 And above');
                      } else if (!isDuplicate) {
                        setIsValidPhone(isDuplicate);
                        setHelperText('Duplicate phone Value With Other Contact');
                        setError(index, 'Duplicate phone Value With Other Contact');
                      } else {
                        setIsValidPhone(true);
                        setHelperText('');
                        setError(index, '');
                      }
                    }, 700);
                }}
                onKeyDown={() => {
                  if (searchTimer) clearTimeout(searchTimer.current);
                }}
                buttonOptions={{
                  className: 'btns-icon theme-solid bg-blue-lighter',
                  iconClasses: 'mdi mdi-plus',
                  isDisabled: !(newValue && newValue.phone && newValue.phone.length >= 9),
                  isRequired: false,
                  onActionClicked: () => {
                    if (newValue && newValue.phone && newValue.phone.length >= 9)
                      setRepeatedDialog(true);
                  },
                }}
              />
              {
                item.data.HelperInfo && item.data.HelperInfo !== '' && (
                  <div className='information'>
                    <Tooltip
                      size='larg'
                      title={item.data.HelperInfo}
                    >
                      <span className='mdi mdi-information-outline' />
                    </Tooltip>
                  </div>
                )
        }
              <RepeatedItemDialog
                open={RepeatedDialog}
                item={item}
                type='phone'
                initialState={newValue}
                label={label}
                Textcancel='delete-all'
                closeDialog={() => {
                  setRepeatedDialog(false);
                }}
                selectedValues={selectedValues}
                itemList={itemList}
                onChange={(value) => {
                  setNewValue({ ...value, phone: newValue.phone, typeId: item.data.lookupItem });
                  setData(index, {
                    ...value,
                    phone: newValue.phone,
                    typeId: item.data.lookupItem,
                  });
                }}
              />
            </div>
          );
          break;
        case 'Email':
          component = (
            <div className='helper-Information'>
              <Inputs
                idRef={id}
                isRequired={isRequired}
                labelClasses={isRequired ? 'c-red' : ''}
                isDisabled={
                  item.data.isReadonly ||
                  item.data.specialKey === 'IsDisabled' ||
                  item.data.specialKey === 'UnitRefNo'
                }
                labelValue={label}
                value={newValue && newValue.email ? newValue.email : ''}
                helperText={helperText}
                error={helperText !== '' || (isRequired && (!newValue || newValue === ''))}
                onKeyUp={() => {
                  // if (!itemRegex.test(itemValue) || itemValue === '')
                  //   return;
                  if (newValue && newValue.email) {
                    setTimer(
                      setTimeout(async () => {
                        if (newValue && newValue.email && itemRegex.test(newValue.email)) {
                          setHelperText('Please wait Check from server if Duplicate Email Value With Other Contact');
                          setError(index, 'Check from sever if Duplicate Email Value With Other Contact ');
                          const isDuplicate = await duplicateEmailRole(item, newValue.email);
                          if (!isDuplicate) {
                            setHelperText('Duplicate Email Value With Other Contact');
                            setError(index, 'Duplicate Email Value With Other Contact ');
                          } else {
                            setHelperText('');
                            setError(index, '');
                          }
                        }
                      }, 800)
                    );
                  }
                }}
                onKeyDown={() => {
                  if (timer) clearTimeout(timer);
                }}
                onInputChanged={(e) => {
                  setHelperText('');
                  setError(index, '');
                  if (e.target.value === '') {
                    setHelperText('Email is required');
                    setError(index, 'The Email Is Required');
                  }
                  if (!itemRegex.test(e.target.value)) {
                    setHelperText(errorMsg);
                    setError(index, errorMsg);
                  }
                  if (selectedValues[index]) {
                    setNewValue({
                      ...selectedValues[index],
                      email: e.target.value,
                      typeId: item.data.lookupItem,
                    });
                    setData(index, {
                      ...selectedValues[index],
                      email: e.target.value,
                      typeId: item.data.lookupItem,
                    });
                  } else {
                    setNewValue({ email: e.target.value, typeId: item.data.lookupItem });
                    setData(index, { email: e.target.value, typeId: item.data.lookupItem });
                  }
                }}
                buttonOptions={{
                  className: 'btns-icon theme-solid bg-blue-lighter',
                  iconClasses: 'mdi mdi-plus',
                  isDisabled: !(
                    newValue &&
                    itemRegex.test(newValue.email) &&
                    newValue.email &&
                    newValue.email.length > 0
                  ),
                  isRequired: false,
                  onActionClicked: () => {
                    if (
                      newValue &&
                      itemRegex.test(newValue.email) &&
                      newValue.email &&
                      newValue.email.length > 0
                    )
                      setRepeatedDialog(true);
                  },
                }}
              />
              {
                item.data.HelperInfo && item.data.HelperInfo !== '' && (
                  <div className='information'>
                    <Tooltip
                      size='larg'
                      title={item.data.HelperInfo}
                    >
                      <span className='mdi mdi-information-outline' />
                    </Tooltip>
                  </div>
                )
}
              <RepeatedItemDialog
                open={RepeatedDialog}
                item={item}
                type='email'
                initialState={newValue}
                label={label}
                Textcancel='delete-all'
                closeDialog={() => {
                  setRepeatedDialog(false);
                }}
                selectedValues={selectedValues}
                itemList={itemList}
                onChange={(value) => {
                  setNewValue({ ...value, email: newValue.email, typeId: item.data.lookupItem });
                  setData(index, {
                    ...value,
                    email: newValue.email,
                    typeId: item.data.lookupItem,
                  });
                }}
              />
            </div>
          );
          break;
        case 'SocialMedia':
          component = (
            <div className='helper-Information'>
              <Inputs
                idRef={id}
                isRequired={isRequired}
                labelClasses={isRequired ? 'c-red' : ''}
                isDisabled={
                  item.data.isReadonly ||
                  item.data.specialKey === 'IsDisabled' ||
                  item.data.specialKey === 'UnitRefNo'
                }
                labelValue={label}
                value={newValue && newValue.link ? newValue.link : ''}
                helperText={helperText}
                error={helperText !== '' || (isRequired && (!newValue || newValue === ''))}
                onInputChanged={(e) => {
                  setHelperText('');
                  setError(index, '');
                  if (!itemRegex.test(e.target.value)) {
                    setHelperText(errorMsg);
                    setError(index, errorMsg);
                  }
                  setNewValue({ link: e.target.value, typeId: item.data.lookupItem });
                  setData(index, { link: e.target.value, typeId: item.data.lookupItem });
                }}
              />

              {item.data.HelperInfo && item.data.HelperInfo !== '' && (
                <div className='information'>
                  <Tooltip
                    size='larg'
                    title={item.data.HelperInfo}
                  >
                    <span className='mdi mdi-information-outline' />
                  </Tooltip>
                </div>
)}
            </div>
          );
          break;
        default:
          break;
      }
      break;
    case 'UnitsModels':
      component = (
        <div>
          <Grid container>
            <div className='w-100'>
              <label htmlFor={id} className='label-wrapper'>
                {label}
              </label>
              <div className='chip-input-wrapper'>
                <ChipInput
                  className='chip-input theme-form-builder'
                  InputProps={{ autoComplete: 'new-password' }}
                  id={id}
                  value={newValue && newValue.Models ? newValue.Models : []}
                  onAdd={(chip) => {
                    if (!isNaN(chip))
                       return;

                    if (newValue && newValue.Models && newValue.Models.length > 0) {
                      Object.keys(newValue).forEach((key) => {
                        if (typeof newValue[key] === 'object' && key !== 'Models') {
                          const isExist = newValue.Models.findIndex((modelItem) => modelItem === key);
                          if (isExist === -1)
                            delete newValue[key];
                        }
                      });
                    } else if (newValue && newValue.Models && newValue.Models.length === 0) {
                      Object.keys(newValue).forEach((key) => {
                        if (typeof newValue[key] === 'object' && key !== 'Models')
                          delete newValue[key];
                      });
                    }
                    if (newValue && newValue.Models) {
                      setNewValue({ ...newValue, Models: [...newValue.Models, chip] });
                      setData(index, { ...newValue, Models: [...newValue.Models, chip] });
                    } else {
                      setNewValue({ Models: [chip] });
                      setData(index, { Models: [chip] });
                    }
                  }}
                  onDelete={(chip, itemIndex) => {
                    if (newValue.Models && newValue.Models.length > 0) newValue.Models.splice(itemIndex, 1);
                    delete newValue[chip];
                    const unitModelIndex = itemList.findIndex((i) => i.field.id === 'units_models');
                    if (newValue && newValue.Models && newValue.Models.length > 0) {
                      Object.keys(newValue).forEach((key) => {
                        if (typeof newValue[key] === 'object' && key !== 'Models') {
                          const isExist = newValue.Models.findIndex((modelItem) => modelItem === key);
                          if (isExist === -1)
                            delete newValue[key];
                        }
                      });
                    } else if (newValue && newValue.Models && newValue.Models.length === 0) {
                      Object.keys(newValue).forEach((key) => {
                        if (typeof newValue[key] === 'object')
                          delete newValue[key];
                      });
                      // setNewValue(null);
                      setData(itemList[unitModelIndex].field.id, newValue ? null : newValue);
                      return;
                    }
                    setNewValue({ ...newValue });
                    setData(itemList[unitModelIndex].field.id, newValue);
                  }}
                />
                <Button
                  className='ml-2 btns-icon theme-solid bg-blue-lighter'
                  onClick={() => {
                    if (newValue && newValue.Models && newValue.Models.length > 0) setModelsUnitsDialog(true);
                  }}
                >
                  <span className='mdi mdi-plus' />
                </Button>
              </div>
            </div>
            {/* <Grid item xs={12} md={1}> */}
            {newValue && newValue.Models && (
              <ModelsUnitsDialog
                initialState={newValue}
                items={items}
                open={modelsUnitsDialog}
                closeDialog={() => {
                  setModelsUnitsDialog(false);
                }}
                onChange={(value) => {
                  setData(index, { ...value, Models: newValue.Models });
                  setNewValue({ ...value, Models: newValue.Models });
                }}
              />
            )}
            {/* </Grid> */}
          </Grid>
        </div>
      );
      break;
    case 'UploadFiles':
      component = (
        <div className='helper-Information'>
          <AutocompleteComponent
            idRef={id}
            isRequired={isRequired}
            labelClasses={isRequired ? 'c-red' : ''}
            multiple={item.data.multi ? item.data.multi === 'true' : false}
            selectedValues={
              item.data.multi && item.data.multi === 'true' ?
                (itemValue && [...itemValue.selected]) || [] :
                itemValue || null
            }
            defaultValue={null}
            data={item && item.data ? item.data.enum : []}
            displayLabel={(option) => (!item.data.lookup ? option : option.lookupItemName) || ''}
            chipsLabel={(option) => (!item.data.lookup ? option : option.lookupItemName)}
            getOptionSelected={(option) =>
              (item.data.multi &&
                item.data.multi === 'true' &&
                ((itemValue && [...itemValue.selected]) || []).findIndex(
                  (element) =>
                    (element.lookupItemId && element.lookupItemId === option.lookupItemId) ||
                    option === element
                ) !== -1) ||
              (itemValue.lookupItemId && itemValue.lookupItemId === option.lookupItemId) ||
              option === itemValue}
            onChange={(e, v) => {
              if (v == null) {
                setNewValue(v);
                setData(index, v);
                return;
              }
              setNewValue({ ...itemValue, selected: v });
              setData(index, { ...itemValue, selected: v });
            }}
            withoutSearchButton
            labelValue={label}
            buttonOptions={{
              className: 'btns-icon theme-solid bg-blue-lighter',
              iconClasses: id === 'external_url' ? 'mdi mdi-plus' : 'mdi mdi-attachment',
              isDisabled: !(itemValue && itemValue.selected && itemValue.selected.length > 0),
              isRequired: false,
              onActionClicked: () => {
                if (
                  itemValue &&
                  itemValue.selected &&
                  itemValue.selected.length > 0 &&
                  id !== 'external_url'
                )
                  setOpenUploadDialog(true);
                if (
                  itemValue &&
                  itemValue.selected &&
                  itemValue.selected.length > 0 &&
                  id === 'external_url'
                )
                  setRepeatedLinkDialog(true);
              },
            }}
          />
          {openUploadDialog && (
            <UploadDialog
              open={openUploadDialog}
              onChange={(e) => {
                setNewItems(e);
                setData(index, { ...e, selected: itemValue.selected });
              }}
              initialState={itemValue}
              closeDialog={() => {
                setOpenUploadDialog(false);
              }}
            />
          )}

          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )
          }
          {repeatedLinkDialog && (
            <RepeatedLinkDialog
              item={item}
              // label={label}
              label={label}
              itemList={itemList}
              initialState={itemValue}
              // initialState={newValue}
              open={repeatedLinkDialog}
              selectedValues={selectedValues}
              onChange={(e) => {
                setData(index, { ...e });
              }}
              closeDialog={() => setRepeatedLinkDialog(false)}
            />
          )}
        </div>
      );
      break;
    case 'MapField':
      component = (
        <div className='helper-Information'>
          <Grid container>
            <Inputs
              idRef={id}
              isRequired={isRequired}
              labelClasses={isRequired ? 'c-red' : ''}
              isDisabled
              labelValue={label}
              value={itemValue ? `${itemValue.latitude} , ${itemValue.longitude}` : ''}
              helperText={helperText}
              error={helperText !== '' || (isRequired && (!newValue || newValue === ''))}
              buttonOptions={{
                className: 'btns-icon theme-outline c-blue-lighter',
                iconClasses: 'mdi mdi-map-marker',
                isDisabled: false,
                isRequired: false,
                onActionClicked: () => {
                  setOpenMapDialog(true);
                },
              }}
            />
            {
              item.data.HelperInfo && item.data.HelperInfo !== '' && (
                <div className='information'>
                  <Tooltip
                    size='larg'
                    title={item.data.HelperInfo}
                  >
                    <span className='mdi mdi-information-outline' />
                  </Tooltip>
                </div>
              )
}

            {itemValue && openMapDialog && (
              <MapDialog
                open={openMapDialog}
                onChange={(e) => {
                  setNewValue({ ...e });
                  setData(index, { ...e });
                }}
                initialState={itemValue}
                closeDialog={() => {
                  setOpenMapDialog(false);
                }}
              />
            )}
          </Grid>

        </div>
      );
      break;
    case 'textField':
      component = (
        <>
          {(item.field.id !== 'unit_model' && item.data.specialKey !== 'Unit_Model') && item.data.specialKey !== 'currency' && item.data.specialKey !== 'size' && item.data.specialKey !== 'decimal' &&
            (
              <div className='helper-Information'>
                <Inputs
                  idRef={id}
                  labelClasses={isRequired ? 'c-red' : ''}
                  isRequired={isRequired}
                  isDisabled={
                    item.data.isReadonly ||
                    item.data.searchableKey === 'unit_ref_no' ||
                    item.data.specialKey === 'IsDisabled' ||
                    item.data.specialKey === 'UnitRefNo' ||
                    customerProfileSelected()
                  }
                  labelValue={label}
                  value={(itemValue && Object.keys(itemValue).length === 0 && itemValue.constructor === Object && '') || itemValue ||'' }
                  isSubmitted={isSubmitted}
                  isWithError
                  helperText={helperText || errorMsg}
                  onInputFocus={(item.field.id === 'unit_model' && openUnitModelPopoverHandler) || undefined}
                  onInputBlur={(item.field.id === 'unit_model' && onPopoverClose) || undefined}
                  error={helperText !== '' || (isRequired && (!itemValue || itemValue === ''))}
                  onInputChanged={(e) => {
                    setHelperText('');
                    setError(index, '');
                    if (!itemRegex.test(e.target.value)) {
                      setHelperText(errorMsg);
                      setError(index, errorMsg);
                    }
                    setNewValue(e.target.value);
                    setData(index, e.target.value);
                    if (!duplicateEmailRole(item, itemList, selectedValues)) {
                      setHelperText('Duplicate Email Value');
                      setError(index, 'Duplicate Email Value');
                    }
                  }}
                />
                {

                  item.data.HelperInfo && item.data.HelperInfo !== '' && (
                    <div className='information'>
                      <Tooltip
                        size='larg'
                        title={item.data.HelperInfo}
                      >
                        <span className='mdi mdi-information-outline' />
                      </Tooltip>
                    </div>

                  )
}
              </div>
            )}
          {(item.field.id === 'unit_model' && item.data.specialKey === 'Unit_Model') &&
            itemList &&
            itemList.findIndex((element) => element.field.id === 'property_name') !== -1 &&
            selectedValues.property_name
            && (
              <div className='helper-Information'>
                <UnitModelPopoverComponent
                  unitModelPopoverAttachedWith={unitModelPopoverAttachedWith}
                  onPopoverClose={onPopoverClose}
                  item={item}
                  itemValue={itemValue}
                  allItems={itemList}
                  propertyId={
                    selectedValues.property_name.id
                  }
                  setData={setData}
                  indexV1={index}
                  labelValue={label}
                  idRef={id}
                  allItemsValues={values}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />

                {

                  item.data.HelperInfo && item.data.HelperInfo !== '' && (
                    <div className='information'>
                      <Tooltip
                        size='larg'
                        title={item.data.HelperInfo}
                      >
                        <span className='mdi mdi-information-outline' />
                      </Tooltip>
                    </div>
                  )
}
              </div>
            )}
          {
            displyTextField() && (
              <div className='helper-Information'>
                <Inputs
                  type='number'
                  isAttachedInput
                  withNumberFormat
                  min={0}
                  endAdornment={(item.data.specialKey === 'currency' && (
                    <InputAdornment position='start' className='px-2'>
                      AED
                    </InputAdornment>
                  )) || false}
                  labelClasses={item.field.isRequired ? 'c-red' : ''}
                  idRef={item.field.id}
                  isRequired={item.field.isRequired}
                  isDisabled={
                    item.data.isReadonly || id === 'amount_due-to-developer' || id === 'pricesqm' ||
                    financialStatusDetailsRules() ||
                    customerProfileSelected()
                  }
                  labelValue={label}
                  value={(itemValue && Object.keys(itemValue).length === 0 && itemValue.constructor === Object && '') || itemValue ||'' }
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={helperText}
                  error={helperText !== '' || (isRequired && (!itemValue || itemValue === ''))}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    const editValue = fixed ? parseFloat(fixed) : 0;
                    SellerHasPaidRule(item, editValue, setRerender, values, setData, setNewValue);
                    if (id === 'builtup_area-sqft') {
                      const sellingPrice = itemList.find((x) => x.field.id === 'selling_price-agency-fee');
                      const salePrise = (sellingPrice && sellingPrice.data && sellingPrice.data.valueToEdit && sellingPrice.data.valueToEdit && sellingPrice.data.valueToEdit.salePrice) ||
                        (values && values.selling_price_agency_fee && values.selling_price_agency_fee.salePrice);
                      BuiltupAreaRule(id, value, salePrise, setData, 1);
                    }
                    LiabilityAmount1(item, value, itemList, values, setData);

                    setNewValue(editValue);
                    setData(index, editValue);
                  }}
                />
                {
                  item.data.HelperInfo && item.data.HelperInfo !== '' && (
                    <div className='information2'>
                      <Tooltip
                        size='larg'
                        title={item.data.HelperInfo}
                      >
                        <span className='mdi mdi-information-outline' />
                      </Tooltip>
                    </div>
                  )
}
              </div>

            )

          }
        </>
      );
      break;

    case 'textarea':
      component = item.field.id === 'closing_remarks' && getLeadStatusValue() ?
        <> </> : (
          <div className='helper-Information'>
            <Inputs
              idRef={id}
              isRequired={isRequired}
              labelClasses={isRequired ? 'c-red' : ''}
              isDisabled={
              getStatusValue2() ||
              item.data.isReadonly ||
              item.data.specialKey === 'IsDisabled' ||
              item.data.specialKey === 'UnitRefNo'
            }
              labelValue={label}
              value={newValue}
              helperText={errorMsg}
              multiline
              rows={4}
              onInputChanged={(e) => {
              setNewValue(e.target.value);
              setData(index, e.target.value);
            }}
            />
            {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )
}
          </div>
      );
      // (
      //   <Inputs
      //     idRef={id}
      //     isRequired={isRequired}
      //     labelClasses={isRequired ? 'c-red' : ''}
      //     isDisabled={
      //       getStatusValue2() ||
      //       item.data.isReadonly ||
      //       item.data.specialKey === 'IsDisabled' ||
      //       item.data.specialKey === 'UnitRefNo'
      //     }
      //     labelValue={label}
      //     value={newValue}
      //     helperText={errorMsg}
      //     multiline
      //     rows={4}
      //     onInputChanged={(e) => {
      //       setNewValue(e.target.value);
      //       setData(index, e.target.value);
      //     }}
      //   />
      //  )  ||

      break;

    case 'select':
      component = (displySelectFields() ? <> </> :

        (
          <div className='helper-Information'>
            <AutocompleteComponent
              idRef={id}
              isRequired={isRequired}
              labelClasses={isRequired ? 'c-red' : ''}
              isDisabled={
                getStatusValue2() ||
                disableQualification() || 
                getLeadOperationValue() ||
                getOperationTypeValue() ||
                getIsMortgage() ||
                customerProfileSelected() ||
                loading ||
                item.data.isReadonly ||
                item.data.specialKey === 'IsDisabled' ||
                item.data.specialKey === 'UnitRefNo'
              }
              isLoading={loading}
              withLoader
              multiple={item.data.multi ? item.data.multi === 'true' : false}
              selectedValues={
                item.data.multi && item.data.multi === 'true' ?
                  (itemValue && Array.isArray(itemValue) && [...itemValue]) || [] :
                  itemValue || null
              }
              data={getDataEnumList(item)}
              inputClasses='inputs theme-form-builder'
              displayLabel={(option) => ((!item.data.lookup) ? option : (option && option.lookupItemName) || undefined) || ''}
              chipsLabel={
                (item.data.multi &&
                  item.data.multi === 'true' &&
                  ((option) => ((!item.data.lookup) ? option : (option && option.lookupItemName) || undefined))) ||
                  undefined
              }
              getOptionSelected={(option) =>
                (item.data.multi &&
                  item.data.multi === 'true' &&
                  itemValue.findIndex(
                    (element) =>
                      (element.lookupItemId && element.lookupItemId === option.lookupItemId) ||
                      option === element
                  ) !== -1) ||
                (itemValue.lookupItemId && itemValue.lookupItemId === option.lookupItemId) ||
                option === itemValue}

              onInputKeyUp={(event) => {
                  const { value } = event.target;

                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    SearchLookupRule(item, value, itemList, setData, setRerender, setLoading);
                  }, 700);
                }}
              onKeyDown={() => {
                  clearTimeout(time);
                }}
              onChange={async (event, v) => {
                if (id === 'contact_preference') {
                   ContactPreferenceRule(item, v, setData, 1);
                    return;
                 }
                 if (id === 'is_mortgage' && item && item.data && item.data.specialKey === 'is_mortgage_seeker_lead')
                 IsMortgageReletedModeOfPaymentRule(item, v, setData, 1, itemList);

               if (id === 'mode_of-payment' && item && item.data && item.data.DtoName === 'mode_of_payment_seeker_lead') {
                ModeOfPaymentRule(item, v, setData, 1);
                TabForLeadRuleModeOfPayment(item, setJsonForm, jsonForm, v, setSteps, steps, itemList, setitemList, values, setData);
               }

                if (item.data.specialKey === 'sendLeadToRotation')
                   RotationLeadRule(item, v, setRerender, itemList, values, setData, 1);
                if (item.field.id === 'shortage_in')
                   ShortageInRule(itemList, setData, 1);
                if (id === 'operation_type')
                  SaleTypDependingOnRule(item, v, itemList, setRerender);
                TabForLeadRule(item, setJsonForm, jsonForm, v, setSteps, steps, itemList, setitemList, values, setData);
                FinancialStatusDetails1(item, v, itemList, values, setData);
                UnitRentedRule(item, v, setData, 1, values);
                if (item.field.id === 'lead_operation' && +(GetParams('formType')) === LeadTypeIdEnum.Owner.leadTypeId) {
                  UnitForLeadOwnerRule(item, v, setRerender, itemList, values, setData);
                  ReferredToByLeadOperationRule(item, v, setRerender, itemList, values, setData);
                }

                if (item.field.id === 'status') {
                  if ((v && v.lookupItemName === 'Open') || !v)
                    StatusLeadDefaulRule1(item, setRerender, itemList, values, setData);
                }
                const isSekeerlead = +GetParams('formType') === LeadTypeIdEnum.Seeker.leadTypeId;
                if (id === 'operation_type' && isSekeerlead);
                OnChangeOperationTypeForSeekerLead(item, v, itemList, setData, setRerender, values, 1);
                MortgageDetailsRules1(item, v, setData, itemList, values);
                if ((item.field.id === 'bedrooms' && item.field.FieldType === 'select') || (item.field.id === 'bathrooms' && item.field.FieldType === 'select')) {
                  const findAnyBathroomsAndBedrooms = v.find((num) => num === 'Any' || num === 'any');
                  if (findAnyBathroomsAndBedrooms) {
                    setNewValue([findAnyBathroomsAndBedrooms]);
                    setData(index, [findAnyBathroomsAndBedrooms]);
                    return;
                  }
                }

                ListingDateRule(id, item, itemList, v, values, setData);
                TitleRule(id, setData, item, itemList, v);
                TabRule(item, setJsonForm, jsonForm, v, setSteps, steps, itemList, setitemList);
                await OnchangeCountryRule(id, setData, item, itemList, v, 1);
                await OnchangeCityRule(id, v, itemList, setData, item, 1);
                await OnchangeDistrictRule(id, setData, item, itemList, v, 1);
                await getValueToEditinUint(item, setRerender, itemList, values, setData, 1);
                await OnchangeCommunityRule(id, setData, item, itemList, v, 1);
                await OnchangeSubCommunityRule(id, setData, item, itemList, v, 1);
                if (v === null) {
                  setNewValue(v);
                  setData(index, {});
                  return;
                }
                setNewValue(v);
                setData(index, v);
              }}
              withoutSearchButton
              labelValue={label}
            />
            {

              item.data.HelperInfo && item.data.HelperInfo !== '' && (
                <div className='information'>
                  <Tooltip
                    size='larg'
                    title={item.data.HelperInfo}
                  >
                    <span className='mdi mdi-information-outline' />
                  </Tooltip>
                </div>
              )
}
          </div>
        ));
      break;

    case 'alt-date':
      if (newValue === undefined) setNewValue(null);
      component = (
        <div className='helper-Information'>
          <DatePickerComponent
            required={isRequired}
            idRef={id}
            labelClasses={isRequired ? 'c-red' : ''}
            labelValue={label}
            value={(newValue && moment(newValue).isValid() && moment(newValue))}
            helperText={errorMsg}
            isSubmitted
            isDisabled={customerProfileSelected() || item.data.isReadonly}
            // buttonOptions={{ isDisabled: item.data.title === 'Listing Expiry Date' }}
            maxDate={item.data.maxDate ? new Date(item.data.maxDate) :
              (item.field.id === 'listing_date' || item.field.id === 'rent_listing-date' ? ListingAndRentListingDateRule(item, itemList) : undefined)}
            minDate={item.data.minDate ? new Date(item.data.minDate) : undefined}
            onDateChanged={(e) => {
              VisaRule(id, item, itemList, e);
              ListingDate1(id, itemList, e);
              PassportRule(id, item, itemList, e, setData);
              DateRule(item, itemList, e, values);
              if (CheckIsDateToAfterDateFromRule(e, item, values)) {
                showError('Please select date from befor date to   or update date to');
                return;
              }
              if (CheckIsDateValidRule(e, item, values)) {
                showError('Please select date to  after date from or update date from');
                return;
              }
              ConstructionDateRule(id, item, itemList, e, setData, 1);
              ListingExpiryDateRule1(item, setData);
              setNewValue(e);
              setData(index, e);
            }}
          />
          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (

              <div className='information'>

                <Tooltip

                  size='larg'

                  title={item.data.HelperInfo}
                >

                  <span className='mdi mdi-information-outline' />

                </Tooltip>

              </div>

            )
}
        </div>
      );
      break;

    case 'checkboxes': {
      let result = [];
      if (newValue) result = [...newValue];
      component = (
        <div className='helper-Information'>
          <CheckboxesComponent
            data={item.data.items.enum || []}
            labelClasses={isRequired ? 'c-red' : ''}
            onSelectedCheckboxChanged={(e) => {
              if (e.target.checked && result.indexOf(e.target.value) === -1)
                result.push(e.target.value);
              else result.splice(result.indexOf(e.target.value), 1);
              setNewValue(result);
              setData(index, result);
            }}
            isRow
            idRef={id}
            labelValue={label}
            required={isRequired}
            checked={(selectedItem) => result.indexOf(selectedItem) !== -1}
          />
          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>

              </div>
            )
}
        </div>
      );
      break;
    }
    case 'radio':
      component = (
        <div className='helper-Information'>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>{label}</FormLabel>
            <RadioGroup id={id} value={newValue} onChange={(e) => setData(index, e.target.value)}>
              {item.data.enum.map((ele) => (
                <FormControlLabel value={ele} control={<Radio />} label={ele} />
              ))}
            </RadioGroup>
          </FormControl>
          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>

              </div>
            )
}
        </div>
      );
      break;

    case 'checkbox':
      component = (
        <div className='helper-Information'>
          <CheckboxesComponent
            data={[newValue]}
            onSelectedCheckboxChanged={(item1, index1, checkedValue, event1) => {
              setData(index, checkedValue);
            }}
            isRow
            labelValue={label}
            idRef={id}
            value={newValue}
          />
        </div>
);
      break;

    case 'phone':
      component = (
        <div className='helper-Information'>
          <PhonesComponent
            idRef={id}
            isValid={() => PhoneValidationRole(newValue ? newValue.phone : '')}
            labelValue={label}
            labelClasses={isRequired ? 'c-red' : ''}
            country={country}
            required={isRequired}
            value={
              newValue === null || newValue === undefined || !newValue.phone ?
                item.data.defaultCountryCode :
                newValue.phone
            }
            onInputChanged={(value) => {
              if (value.length > 14) return;
              setNewValue({ phone: value, typeId: item.data.lookupItem });
              setData(index, { phone: value, typeId: item.data.lookupItem });
            }}
            buttonOptions={{
              className: 'btns-icon theme-solid bg-blue-lighter',
              iconClasses: 'mdi mdi-plus',
              isDisabled: !(newValue && newValue.phone && newValue.phone.length >= 9),
              isRequired: false,
              onActionClicked: () => {
                if (newValue && newValue.phone && newValue.phone.length >= 9)
                  setRepeatedDialog(true);
              },
            }}
          />
          {item.data.HelperInfo && item.data.HelperInfo !== '' && (
            <div className='information'>
              <Tooltip
                size='larg'
                title={item.data.HelperInfo}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
            </div>
          )}
          <RepeatedItemDialog
            open={RepeatedDialog}
            item={item}
            type={type}
            initialState={newValue}
            label={label}
            Textcancel='delete-all'
            closeDialog={() => {
              setRepeatedDialog(false);
            }}
            selectedValues={selectedValues}
            itemList={itemList}
            onChange={(value) => {
              setNewValue({ ...value, phone: newValue.phone });
              setData(index, { ...value, phone: newValue.phone });
            }}
          />
        </div>
      );
      break;

    case 'rangeField': {
      if (newValue === '') {
        setNewValue([parseFloat(item.data.minNumber), parseFloat(item.data.maxNumber)]);
        setData(index, [parseFloat(item.data.minNumber), parseFloat(item.data.maxNumber)]);
      }
      const marks = [
        {
          value: parseFloat(item.data.minNumber),
          label: `${parseFloat(item.data.minNumber)}`,
        },

        {
          value: parseFloat(item.data.maxNumber),
          label: `${parseFloat(item.data.maxNumber)}`,
        },
      ];
      component = (
        <div className=''>
          <Typography gutterBottom>{label}</Typography>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xl={6} lg={6} sm={6} xs={12}>
              <Inputs
                idRef={id}
                labelValue='From'
                withNumberFormat
                labelClasses={isRequired ? 'c-red' : ''}
                value={newValue ? newValue[0] : Number(item.data.minNumber)}
                onInputChanged={(e) => {
                  if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                  const v = newValue || [];
                  const withoutCommaInNumber = parseFloat(e.target.value && e.target.value.replace(/,/g, ''));
                  if (isNaN(withoutCommaInNumber) || !withoutCommaInNumber)
                     v[0] = parseFloat(item.data.minNumber);

                 else if (v && v[0] && withoutCommaInNumber && v && v.length === 2 && v[1] && withoutCommaInNumber > Number(v[1]))
                    v[0] = newValue && newValue[0];

                  else
                  v[0] = parseFloat(e.target.value);

                    setNewValue(v);
                    setData(index, v);
                  }
                }}
                onInputBlur={(e) => {
                  const sizeValue = e.target.value;

                  const withoutCommaInNumber = parseFloat(sizeValue && sizeValue.replace(/,/g, ''));

                  const v = newValue || [];
                  if (withoutCommaInNumber && v && v.length === 2 && v[1] && withoutCommaInNumber > Number(v[1]))
                    return;

                if (withoutCommaInNumber < Number(item.data.minNumber)) {
                  v[0] = Number(item.data.minNumber);
                  setNewValue(v);
                  setData(index, v);
                } else if (Number(withoutCommaInNumber) > Number(item.data.maxNumber)) {
                  v[0] = Number(item.data.maxNumber);
                  setNewValue(v);
                  setData(index, v);
                } else {
                  v[0] = withoutCommaInNumber;
                  setNewValue(v);
                  setData(index, v);
                }
                  if (item.field.id === 'size_sqft')
                      LeadConvertSizeSqftToSqmRule1(item, v[0], values, setData, 'from', itemList, setRerender);

                    else if (item.field.id === 'size_sqm')
                      LeadConvertSizeSqmToSqftRule1(item, v[0], values, setData, 'from', itemList, setRerender);
              }}
              />
            </Grid>
            <Grid item xl={6} lg={6} sm={6} xs={12}>
              <Inputs
                idRef={id}
                labelValue='To'
                withNumberFormat
                value={newValue ? newValue[1] : Number(item.data.maxNumber)}
                onInputChanged={(e) => {
                  if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                  const v = newValue || [];
                  const withoutCommaInNumber = parseFloat(e.target.value && e.target.value.replace(/,/g, ''));

                  if (isNaN(withoutCommaInNumber) || !withoutCommaInNumber)
                    v[1] = parseFloat(item.data.maxNumber);

                    else
                     v[1] = parseFloat(e.target.value);

                    setNewValue(v);
                    setData(index, v);
                  }
                }}
                onInputBlur={(e) => {
                  const sizeValue = e.target.value;
                  const withoutCommaInNumber = parseFloat(sizeValue && sizeValue.replace(/,/g, ''));

                  const v = newValue || [];
                  if (withoutCommaInNumber && v && v.length && v[0] && withoutCommaInNumber < Number(v[0])) {
                    v[1] = Number(item.data.maxNumber);
                    setNewValue(v);
                    setData(index, v);
                  } else if (Number(withoutCommaInNumber) < Number(item.data.minNumber)) {
                  v[1] = parseFloat(item.data.minNumber);
                  setNewValue(v);
                  setData(index, v);
                } else if (Number(withoutCommaInNumber) > Number(item.data.maxNumber)) {
                  v[1] = Number(item.data.maxNumber);
                  setNewValue(v);
                  setData(index, v);
                } else {
                  v[1] = withoutCommaInNumber;
                  setNewValue(v);
                  setData(index, v);
                }
                if (item.field.id === 'size_sqft')
                    LeadConvertSizeSqftToSqmRule1(item, v[1], values, setData, 'to', itemList, setNewValue, setRerender);

                else if (item.field.id === 'size_sqm')
                    LeadConvertSizeSqmToSqftRule1(item, v[1], values, setData, 'to', itemList, setNewValue, setRerender);
              }}
              />
            </Grid>
          </Grid>
          <div className='helper_rangField px-4'>
            <Slider
              required={isRequired}
              className='sliders'
              aria-labelledby='range-slider'
              valueLabelDisplay='auto'
              onChange={(event, value) => {
                  if (item.field.id === 'size_sqft')
                    LeadConvertSizeSqftToSqmRuleSlider1(value, setData, setRerender);

                 else if (item.field.id === 'size_sqm')
                   LeadConvertSizeSqmToSqftRuleSlider1(value, setData, setRerender);

                if ((value && value[0] && value[0] !== newValue[0]) || (value[1] && value[1] && value[1] !== newValue[1])) {
                  setNewValue(value);
                  setData(index, value);
                }
              }}

              value={
                newValue ?
                  [(newValue[0] && !isNaN(newValue[0]) && parseFloat(newValue[0].toFixed(2))),
                  (newValue[1] && !isNaN(newValue[0]) && parseFloat(newValue[1].toFixed(2)))] :
                  [(item.data.minNumber && parseFloat(item.data.minNumber)),
                  (item.data.maxNumber && parseFloat(item.data.maxNumber))]
              }
              step={item.field.id === 'size_sqft' ? 200 : 20}
              marks={marks}

              min={parseFloat(item.data.minNumber)}
              max={parseFloat(item.data.maxNumber)}
            />
            {
              item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <Tooltip
                size='larg'
                title={item.data.HelperInfo}
              >
                <span className='mdi mdi-information-outline InformationIcon' />
              </Tooltip>
              )
}
          </div>
        </div>
      );
      break;
    }

    case 'searchField':
      if (id !== 'owner') {
       // ContactDefaultRule(item, setRerender);
        PropertyDefaultRule(item, setRerender);
        (item && item.data.searchKey === 'User' && !(item.data.enum) && UserDefaultRule(item, setRerender, itemList, values, activeFormType));
        (item && !(item.data.enum) && CampaignsDefaultRule(item, setRerender, '', setIsLoading));
        ActivityTypeRuleDefaultRule(item, setRerender, itemList, values);
        LeadsDefaultRule(item, setRerender, activeFormType);
        component = displySearchField() ? (
          <div className='helper-Information'>
            <AutocompleteComponent
              idRef={id}
              isRequired={isRequired}
              labelClasses={isRequired ? 'c-red' : ''}
              isDisabled={
                getStatusValue() ||
                getLeadOperationValue() ||
                customerProfileSelected() ||
                loading ||
                item.data.isReadonly ||
                item.data.specialKey === 'IsDisabled' ||
                item.data.specialKey === 'UnitRefNo' ||
                // AssignToInquiresHelper(id) ||
                getSendLeadToRotationSelected() ||
                getIsLeadAuditor()

              }
              inputEndAdornment={(
                <span className={'class="MuiButtonBase-root MuiIconButton-root"'} />
              )}
              isLoading={loading}
              filterOptions={(options) => options}
              withLoader
              multiple={item.data.multi ? item.data.multi === 'true' : false}
              selectedValues={
                item.data.multi && item.data.multi === 'true' ?
                  (itemValue && Array.isArray(itemValue) && [...itemValue]) || [] :
                  itemValue || newValue || null
              }
              renderOption={
                (item.data.searchKey === 'property' &&
                ((option) => <span>{`${option.name || ''} - ${option.city || ''}  ${option && option.property_plan !== undefined && (`      (      ${((option && option.property_plan) || '')}      ) ` || '') || ''}  ` || ''}</span>)) ||
                (item.data.searchKey === 'contact' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                      <span className='c-gray-secondary'>{option.phone || ''}</span>
                    </div>
                  ))) ||
                (item.data.searchKey === 'Leads' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                      <span className='c-gray-secondary'>
                        {`${option.leadClass} ${option.leadId}` }
                        {' '}
                      </span>
                    </div>
                  ))) ||
                  (item.data.searchKey === 'campaign_name' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                    </div>
                  ))) ||
                (item.data.searchKey === 'User' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                      <span className='c-gray-secondary'>{option.branch || ''}</span>
                    </div>
                  ))) ||
                (item.data.searchKey === 'PropertyOrUnit' &&
                  ((option) =>
                    (option.isProperty && (
                      <span>{`${option.name || ''} - ${option.city || ''}`}</span>
                    )) || (
                      <div className='d-flex-column'>
                        <span>{`${option.unitModel} - ${option.name} - ${option.unitType}`}</span>
                        <span className='c-gray-secondary'>
                          {`${option.unitBedrooms} - ${option.unitRefNo}`}
                        </span>
                      </div>
                    ))) ||
                undefined
              }
              data={item.data.enum || []}
              inputClasses='inputs theme-form-builder'
              displayLabel={(option) => option.name || ''}
              chipsLabel={(option) => option.name || ''}
              onChange={(e, v) => {
                if (v === null || v === undefined) setInputValue('');
                setNewValue(v);
                if (item.field.id === 'lead_owner') {
                  if (v === null) {
                    setLeadOwnerValue(itemValue);
                    setIsOpenLeadOwnerDialog(true);
                  }
                }
                if (item.field.id === 'unit_ref-number' && item.data.DtoName === 'unit_ref_number_owner_lead')
                  UnitRefNumberLeadOwnerRule(item, itemList, v, values, setData, 1);

                if (item.field.id === 'lease_lead-owner') {
                  if (v === null) {
                    setLeaseLeadOwnerValue(itemValue);
                    setisOpenLeaseLeadOwnerDialog(true);
                  }
                }
                if (item.field.id === 'lead' && item.data.searchKey === 'Leads')
                  onChangeLeadsRule(item, v, setRerender, itemList, values, setData, 1);

                if (item.field.id === 'property' && item.data.searchableKey === 'property_inquiries')
                  onChangePropertyRule(item, v, setRerender, itemList, 1, setData);

                if (item.field.id === 'inquiry_unit-model')
                     UnitModelRule(item, v, setRerender, itemList, setData, 1, values);

                OnchangePropertyInUnitRule(item, v, itemList, setData, setRerender);
                const propertyNameReletedLead =  item.data.DtoName === 'property_seeker_lead' ||  item.data.DtoName === 'property_owner_lead'  ;
                if (propertyNameReletedLead)
                  OnchangePropertyOnLeadRule(item, v, itemList, setData, values);
                if (item.field.id === 'unit_ref-number' && (item.data.DtoName === 'unit_ref_number_sekeer_lead' || item.data.DtoName === 'unit_ref_number_owner_lead'))
                  UnitRefNumberRule(item, v, itemList, setData, 1, values);
                setData(index, v);
                if (v && v[0]) setInputValue('');
              }}
              inputValue={inputValue}
              onInputChange={(e, v) => {
                setInputValue(e && e.target && e.target.value ? e.target.value : inputValue);
                setInputValue(v !== '' ? v : e && e.target && e.target.value);
                ContactRule(item, v, setRerender);
                PropertyRule(item, v, setRerender);
                UnitRule(item, v, setRerender, itemList, values);
                ActivityTypeRule(item, v, setRerender);
                UserRule(item, v, setRerender, itemList, values, activeFormType, 1, setData);
                PropertyOrUnitRule(item, v, setRerender, itemList, selectedValues);
                LeadsRule(item, v, setRerender, activeFormType);
                CampaignsRule(item, v, setRerender, v && v.name);
              }}

              withoutSearchButton
              getOptionSelected={(option) =>
                (item.data.multi &&
                  item.data.multi === 'true' &&
                  itemValue.findIndex(
                    (element) => (element.id && element.id === option.id) || option === element
                  ) !== -1) ||
                (itemValue && itemValue.id && itemValue.id === option.id) ||
                option === itemValue}
              labelValue={label}
              buttonOptions={
                (item.data.searchKey !== 'User' && item.data.searchKey !== 'ActivityType' && id !== 'lead'
                 && id !== 'listing_agent' && id !== 'rent_listing_agent' && id !== 'rent_listing-agent' &&
                  id !== 'unit_model' && id !== 'inquiry_unit-model' && id !== 'campaign_name' && id !== 'lead_auditor') && {
                  className: 'btns-icon theme-outline c-blue-lighter ',
                  iconClasses: itemValue ? 'mdi mdi-eye-outline' : 'mdi mdi-plus',
                  isDisabled: item.data.multi && item.data.multi === 'true',
                  isRequired: false,
                  onActionClicked: itemValue ?
                    () => onSearchFieldActionClicked() :
                    () => {
                      const itemIndex = itemList.findIndex(
                        (effectedItem) =>
                          effectedItem.data.title.replace('*', '').trim() === item.data.dependOn
                      );

                      let l = {};
                      if (selectedValues[itemIndex]) l = { itemId: selectedValues[itemIndex] };
                      else l = { itemId: item.data.searchKey };

                      if (l.itemId && l.itemId.toLowerCase() === 'contact') {
                        l.itemId = CONTACTS;
                        if (!l.type)
                          l.type = 1;
                      }
                      if (l.itemId && l.itemId.toLowerCase() === 'property') {
                        l.itemId = PROPERTIES;
                        l.type = '1';
                      }
                      if (l.itemId && l.itemId.toLowerCase() === 'unit') {
                        l.itemId = UNITS;
                        l.type = '1';
                      }
                      setDialogType((l.itemId && l.itemId.toLowerCase()) || null);
                      localStorage.setItem('current', JSON.stringify(l));
                      const seekerIndex = itemList.findIndex((x) => x.data.specialKey === 'seeker_lead');

                      setIsSeekerLead(seekerIndex !== -1);
                      setIsOpenContactsDialog(true);
                    },
                }
              }
            />
            {
              item.data.HelperInfo && item.data.HelperInfo !== '' && (
                <div className='information'>
                  <Tooltip
                    size='larg'
                    title={item.data.HelperInfo}
                  >
                    <span className='mdi mdi-information-outline' />
                  </Tooltip>
                </div>
              )
}
            <ContactsDialog
              open={isOpenContactsDialog}
              onSave={() => { }}
              leadOperation={(values && values.lead_operation)}
              seekerLead={itemList}
              isSeekerLead={isSeekerLead}
              closeDialog={(itemsDialog) => {
                if (DialogType === UNITS) OnAddnewUnitRule(itemList, setData, itemsDialog);
                if (DialogType === CONTACTS)
                  OnAddnewContactRule(item, itemList, setData, itemsDialog);
                if (DialogType === PROPERTIES)
                  OnAddnewPropertyRule(item, itemList, setData, itemsDialog);
                setIsOpenContactsDialog(false);
              }}
            />
            {isOpenItemDetails && (
              <DetailsDialog
                isOpen={isOpenItemDetails}
                isOpenChanged={() => {
                  setIsOpenItemDetails(false);
                  setIsLoadingDetailsDialog(false);
                  setDataInSideViewButton(null);
                  setIsSensitiveLoading2(0);
                }}
                isOpenInsideForm={isOpenItemDetails}
                dataInSideViewButton={dataInSideViewButton}
                sensitiveFieldsForUser={sensitiveFieldsForUser2}
                isSensitiveLoading={isSensitiveLoading2}

              />
            )}
            {
            isNotHavePolicyToDisplayData && (
              <NotHavePolicyToDisplayDataDialog
                isOpen={isNotHavePolicyToDisplayData}
                isOpenChanged={() => { setIsNotHavePolicyToDisplayData(false); }}
              />
)
            }
            {isOpenLeadOwnerDialog && (
              <LeadOwnerDialog
                open={isOpenLeadOwnerDialog}
                onSave={() => setIsOpenLeadOwnerDialog(false)}
                close={() => {
                  const leadOwnerIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'lead_owner'));
                  if (leadOwnerIndex !== -1)
                    setData(itemList[leadOwnerIndex].field.id, leadOwnerValue);

                  setIsOpenLeadOwnerDialog(false);
                }}
              />
            )}
            {isOpenLeaseLeadOwnerDialog && (
              <LeaseLeadOwnerDialog
                open={isOpenLeaseLeadOwnerDialog}
                onSave={() => setisOpenLeaseLeadOwnerDialog(false)}
                close={() => {
                  const LeaseleadOwnerIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'lease_lead-owner'));
                  if (LeaseleadOwnerIndex !== -1)
                    setData(itemList[LeaseleadOwnerIndex].field.id, LeaseleadOwnerValue);
                  setData('lease_lead_owner', LeaseleadOwnerValue);
                  setisOpenLeaseLeadOwnerDialog(false);
                }}
              />
            )}
          </div>
        ) : <></>;
        break;
      }
      break;

    case 'PriceAndPercentage':
      component = (
        <>
          <PriceAndPercentage
            type={item.data.controlType}
            currency='AED'
            value={newValue}
            area={values && values.builtup_area_sqft && +values.builtup_area_sqft}
            sqrSalePrice={getSqrSalePrice()}
            onChange={(e) => {
              if (e === null) return;
              setNewValue(e);
              setData(index, e);
            }}
            setData={setData}
            itemList={itemList}
            values={values}
            depositSum={item.data.controlType === 'Rent Type' && RentDeposit(values, setData, 1)}
          />
        </>
      );
      break;

    default:
      break;
  }

  return component;
};
export default ConvertJson;
