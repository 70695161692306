import {  ZeroMatchingLeaseInformationComponent  } from './ZeroMatchingLeaseInformationComponent' ; 
import { AssignInquiryToUnitComponent  } from '../../../ListingShortage/ListingShortageUtilities/Sections' ; 



export const ZeroMatchingLeaseVerticalTabsData = [
    {
      label: 'Inquiries-information',
      component: ZeroMatchingLeaseInformationComponent,
    },
    {
      label: 'Assigned-Units',
      component: AssignInquiryToUnitComponent ,
    },
  
  ];