import React, {useEffect, useState, useRef, useCallback} from 'react';
import {ReminderToEnum, ReminderAmountOfTimeEnum, ReminderTypesEnum, TemplateCategoryEnum} from '../../../../../../Enums'
import { useTranslation } from 'react-i18next'; 
import {GetAllSystemTemplateByCategoryId} from '../../../../../../Services';
import {ReminderForEnum} from '../../../../../../Enums';
import {AutocompleteComponent, SelectComponet, Inputs} from '../../../../../../Components';
import {ButtonBase} from '@material-ui/core';
export const RemindersTabComponent = ({
    parentTranslationPath,
    translationPath,
    state,
    activeItem,
    activityTypeReminders,
    setActivityTypeReminders
}) =>{
    const [isLoading, setIsLoading] = useState(false);
    const [reminderTemplates, setReminderTemplates] = useState([]);

    const { t } = useTranslation(parentTranslationPath);
    const defaultActivityReminderItem = useRef({
        reminderType: null,
        reminderTo: null,
        reminderTime: 0,
      });


      const reminderDecrementHandler = useCallback(
        (index) => () => {
          const localActivityReminders = [...activityTypeReminders];
          localActivityReminders.splice(index, 1);

          if(localActivityReminders.length > 0) setActivityTypeReminders(localActivityReminders);
        else setActivityTypeReminders([{ ...defaultActivityReminderItem.current }]);
        },
        [activityTypeReminders]
      );
    
      const reminderIncrementHandler = useCallback(
        (index) => () => {
          const localActivityReminders = [...activityTypeReminders];
          localActivityReminders.splice(index, 0, { ...defaultActivityReminderItem.current });
          setActivityTypeReminders(localActivityReminders);
    
        },
        [activityTypeReminders]
      );
    
      const getAllSystemTemplateByCategoryId = useCallback(async () => {
        setIsLoading(true);
          const res = await GetAllSystemTemplateByCategoryId(TemplateCategoryEnum.Activity.key);
          if (!(res && res.status && res.status !== 200))  {
          const mappedTempatesData = res&&res.map(item=>({
            templateName: item.systemTemplateName,
            templateId: item.systemTemplateId
          }))
          setReminderTemplates(mappedTempatesData || []);
        }
        else setReminderTemplates([]);
    
        setIsLoading(false);
      }, []);
    
      const getReminderTypeOptions = (reminderTo) => {
        let reminderTypeOptions = []
    
        if (reminderTo === ReminderForEnum.Agent.key) reminderTypeOptions = Object.values(ReminderTypesEnum);
        if (reminderTo === ReminderForEnum.Lead.key){
          reminderTypeOptions.push(ReminderTypesEnum.Email);
          reminderTypeOptions.push(ReminderTypesEnum.Sms);
        }
        return reminderTypeOptions;
      };
    
      useEffect(()=>{
        if(!state.withReminder)
        setActivityTypeReminders([{ ...defaultActivityReminderItem.current }]);

      },[state.withReminder])

      useEffect(()=>{
        getAllSystemTemplateByCategoryId();
      },[])

        return (<>
        {state.withReminder&&(
                      <div className='activities-management-dialog-wrapper'>
                      <div className='dialog-content-wrapper'>
                      <div className='reminder-wrapper'>
                        {activityTypeReminders && (activityTypeReminders.map((item, index) => (
                          <div className='reminder-item-wrapper' key={`remindersRef${index + 1}`}>
                            <div className='mb-1 w-100 px-2'>
                              <span>{`${t(`${translationPath}reminder`)}# ${index + 1}`}</span>
                            </div>
                            <div className='reminder-inputs'>
                              <div className='reminder-input'>
                                <SelectComponet
                                  idRef={`reminderToRef${index + 1}`}
                                  data={ReminderToEnum}
                                  value={item.reminderTo}
                                  placeholder='reminder-to'
                                  valueInput='value'
                                  textInput='text'
                                  onSelectChanged={(value) => {
                                    const localActivityTypeReminders = [...activityTypeReminders];
                                    localActivityTypeReminders.splice(index, 0, { ...activityTypeReminders[index], 
                                      reminderTo: value,
                                      reminderType: null,
                                     })
                                    localActivityTypeReminders.splice(index + 1, 1);
                                    setActivityTypeReminders(localActivityTypeReminders)
                                  }}
                                  emptyItem={{
                                    value: null,
                                    text: 'reminder-to',
                                    isDisabled: false,
                                  }}
                                  translationPathForData={translationPath}
                                  parentTranslationPath={parentTranslationPath}
                                  translationPath={translationPath}
                                />
                              </div>
                              <div className='reminder-input'>
                                <SelectComponet
                                  idRef={`reminderWayRef${index + 1}`}
                                  data={getReminderTypeOptions(item.reminderTo)}
                                  value={item.reminderType}
                                  valueInput='key'
                                  textInput='value'
                                  onSelectChanged={(value) => {
                                    const localActivityTypeReminders = [...activityTypeReminders];
                                    localActivityTypeReminders.splice(index, 0, { ...activityTypeReminders[index], reminderType: +(value || 1) })
                                    localActivityTypeReminders.splice(index + 1, 1);
                                    setActivityTypeReminders(localActivityTypeReminders)
                                  }}
                                  translationPathForData={translationPath}
                                  parentTranslationPath={parentTranslationPath}
                                  translationPath={translationPath}
                                  placeholder='reminder-type'
                                  emptyItem={{
                                    value: null,
                                    text: 'reminder-type',
                                    isDisabled: false,
                                  }}
                                />

                              </div>
                              <div className='reminder-input'>
                                <AutocompleteComponent
                                  idRef='reminderTemplateRef'
                                  selectedValues={{
                                    templateId: item.templateId,
                                    templateName: item.templateName,
                                  } || []}
                                  multiple={false}
                                  data={reminderTemplates || []}
                                  displayLabel={(option) => t(`${option.templateName || ''}`)}
                                  getOptionSelected={(option) => option.templateId === item.templateId}
                                  withoutSearchButton
                                  inputPlaceholder={t(`${translationPath}Template`)}
                                  isLoading={isLoading}
                                  isWithError
                                  parentTranslationPath={parentTranslationPath}
                                  translationPath={translationPath}
                                  onChange={(event, newValue) => {
                                    const localActivityTypeReminders = [...activityTypeReminders];
                                    localActivityTypeReminders.splice(index, 0, { ...activityTypeReminders[index],
                                      templateId : newValue?.templateId || null,
                                      templateName : newValue?.templateName || null,                                       })
                                    localActivityTypeReminders.splice(index + 1, 1);
                                    setActivityTypeReminders(localActivityTypeReminders)
                                    
                                  }}
                                />
                              </div>
                              <div className='reminder-input'>
                                <div className='d-flex w-100'>
                                  <Inputs
                                    idRef='reminderTimeRef'
                                    value={item.reminderTime || 0}
                                    wrapperClasses='mb-0'
                                    endAdornment={(
                                      <SelectComponet
                                        data={ReminderAmountOfTimeEnum}
                                        value={item.reminderAmountOfTimeType || 1}
                                        valueInput='key'
                                        textInput='value'
                                        onSelectChanged={(value) => {
                                          const localActivityTypeReminders = [...activityTypeReminders];
                                          localActivityTypeReminders.splice(index, 0, { ...activityTypeReminders[index], reminderAmountOfTimeType: +(value || 1) })
                                          localActivityTypeReminders.splice(index + 1, 1);
                                          setActivityTypeReminders(localActivityTypeReminders)
                                        }}
                                        wrapperClasses='over-input-select w-auto'
                                        idRef='timeAmountTypeRef'
                                        parentTranslationPath={parentTranslationPath}
                                        translationPath={translationPath}
                                        translationPathForData={translationPath}
                                      />
                                    )}
                                    type='number'
                                    min={0}
                                    isWithError
                                    parentTranslationPath={parentTranslationPath}
                                    translationPath={translationPath}
                                    onInputChanged={(event) => {
                                      const localActivityTypeReminders = [...activityTypeReminders];
                                      localActivityTypeReminders.splice(index, 0, { ...activityTypeReminders[index], reminderTime: +(event.target.value) || 0 })
                                      localActivityTypeReminders.splice(index + 1, 1);
                                      setActivityTypeReminders(localActivityTypeReminders)
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='reminder-input'>
                                <ButtonBase
                                  className='btns-icon theme-solid bg-secondary-light mt-1 mr-1'
                                  onClick={reminderDecrementHandler(index)}
                                >
                                  <span className='mdi mdi-minus c-black-light' />
                                </ButtonBase>
                                {index === 0 && (
                                  <ButtonBase
                                    className='btns-icon theme-solid bg-secondary-light mt-1 mr-1'
                                    onClick={reminderIncrementHandler(index)}
                                  >
                                    <span className='mdi mdi-plus c-black-light' />
                                  </ButtonBase>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                  )}
                </div>
                    </div>
                    </div>
        )||
          <span>{`${t(`${translationPath}activity-type-is-without-reminders`)}`}</span>
                            }
        </>
        )
    }
