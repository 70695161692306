import { config } from '../config';
import { HttpServices } from '../Helper';

export const GetAllAMLTransactions = async (unitTransactionId, body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/GetAllAMLTransactions/${unitTransactionId}`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const TransactionManualApprove = async (amlTransactionId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitTransaction/TransactionManualApprove/${amlTransactionId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const TransactionComplete = async (unitTransactionId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitTransaction/TransactionComplete/${unitTransactionId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const TransactionManualCancel = async (amlTransactionId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitTransaction/TransactionManualCancel/${amlTransactionId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllTrackedTransactions = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/GetAllTrackedTransactions`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllMyTrackedTransactions = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/GetAllMyTrackedTransactions`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllMyAMLTransactions = async (unitTransactionId, body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/GetAllMyAMLTransactions/${unitTransactionId}`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateAMLTransactionStatus = async (contactId, isApproved) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/UpdateAMLTransactionStatus?contactId=${contactId}1&isApproved=${isApproved}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
