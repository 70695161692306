export function BuiltupAreaRule2(id, value, sellingPrice, setDate, formType, itemList, item) {
  if (formType === 2 && id === 'builtup_area_sqft' && item && !(item.data.isReadonly)) {
    if (sellingPrice !== '********') {
      const priceSqm = sellingPrice && Number(value) && Number(value) !== 0 ? (Number(sellingPrice) / Number(value)) : 0;
      setDate(priceSqm, 0, 'pricesqm');
    }
  }
}

export function BuiltupAreaRule(id, value, sellingPrice, setDate, formType) {
  if (id === 'builtup_area-sqft' && formType === 1) {
    const priceSqm = sellingPrice && Number(value) && Number(value) !== 0 ? Number(sellingPrice) / Number(value) : 0;
    if (formType === 1)
      setDate('pricesqm', priceSqm);
  }
}
