import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent } from '../../../../../../Components';
import { GetAllSystemTemplateByCategoryId } from '../../../../../../Services';
import { RecipientTypeCoreEnum, TemplateCategoryEnum } from '../../../../../../Enums';

export const NotificationTemplateTeamLeadComponent = ({
  parentTranslationPath,
  translationPath,
  stateNotification,
  teamLeadNOT,
  setTypePrerequisites,
  TypePrerequisites,
  Data,
  setTeamLeadTemplatData,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [selected, setSelected] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [loadings, setloadings] = useState(false);
  const [filter] = useState(
    {
      pageIndex: 0,
      pageSize: 9999,
    },
  );

  const getAllSystemTemplateByCategoryId = useCallback(async () => {
    setloadings(true);
    const res = await GetAllSystemTemplateByCategoryId(TemplateCategoryEnum.Activity.key);    if (!(res && res.status && res.status !== 200))  {
      const mappedTempatesData = res&&res.map(item=>({
        templateName: item.systemTemplateName,
        templateId: item.systemTemplateId
      }))
      setTemplates(mappedTempatesData || []);
    }
    else setTemplates([]);
    setloadings(false);
  }, [filter]);

  useEffect(() => {
    getAllSystemTemplateByCategoryId();
  }, []);
  
  useEffect(() => {
    setTypePrerequisites((item) => ({
      ...item,
      TeamLeadTemplat: {
        templateId: selected.templateId,
        templateName: selected.templateName,
        recipientTypeId: selected.recipientTypeId,
        activityTypeTemplateRecipientTypeSources:
          stateNotification.TeamLead,
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.templateId, stateNotification.TeamLead]);

  useEffect(() => {
    if (Data) {
      const filterData =
        Data &&
        Data &&
        Data.activityTypeTemplateRecipientTypes &&
        Data.activityTypeTemplateRecipientTypes.filter(
          (obj) => obj.recipientTypeId === RecipientTypeCoreEnum.TeamLead.key
        );

      setTypePrerequisites((item) => ({
        ...item,
        TeamLeadTemplat: {
          templateId: filterData && filterData[0] && filterData[0].templateId,
          recipientTypeId: RecipientTypeCoreEnum.TeamLead.key,
          activityTypeTemplateRecipientTypeSources:
            stateNotification.TeamLead,
        },
      }));
      setSelected({
        templateId: (filterData && filterData[0] && filterData[0].templateId) || '',
        templateName: (filterData && filterData[0] && filterData[0].templateName) || '',
        recipientTypeId: RecipientTypeCoreEnum.TeamLead.key,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  useEffect(() => {
    if (TypePrerequisites&&TypePrerequisites.TeamLeadTemplat) {
      setSelected({
        templateId: TypePrerequisites.TeamLeadTemplat.templateId || '',
        templateName: TypePrerequisites.TeamLeadTemplat.templateName|| '',
        recipientTypeId: TypePrerequisites.TeamLeadTemplat.recipientTypeId,
      });
    }
  }, []);

  return (
    <div>
      <AutocompleteComponent
        idRef='Notification-template-team-lead-AdminRef'
        selectedValues={selected || []}
        multiple={false}
        data={(templates && templates) || []}
        displayLabel={(option) => t(`${option.templateName || ''}`)}
        getOptionSelected={(option) => option.templateId === selected.templateId}
        withoutSearchButton
        inputPlaceholder={t(`${translationPath}notification-template`)}
        isLoading={loadings}
        isDisabled={(teamLeadNOT.length=== 0 &&
          teamLeadNOT.length === 0
        )}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setTypePrerequisites((item) => ({
            ...item,
            TeamLeadTemplat: {
              templateId: newValue && +newValue.templateId,
              templateName: newValue && newValue.templateName,
              recipientTypeId: RecipientTypeCoreEnum.TeamLead.key,
              activityTypeTemplateRecipientTypeSources:
                stateNotification.TeamLead,
            },
          }));
          setTeamLeadTemplatData(((newValue === null) && []) || [newValue.templateId]);
          setSelected({
            templateId: (newValue && +newValue.templateId) || '',
            templateName: (newValue && newValue.templateName) || '',
            recipientTypeId: RecipientTypeCoreEnum.TeamLead.key,
          });
        }}
      />
    </div>
  );
};
