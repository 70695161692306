export const RotationLeadRule = (item, value, setRerender, itemList, values, setData, formType) => {
  const referredToIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'referredto'));
  if (referredToIndex !== -1) {
    if (formType && formType === 1 && value && value === 'Yes')
      setData(itemList[referredToIndex].field.id, {});

    else if (formType && formType === 2 && value && value === 'Yes')
      setData(null, 0, 'referredto');
  }
};

export const RotationLeadDefaultRule = (item, itemList, values, setData) => {
  if (item.field.id === 'send_to_rotation' && item.data.valueToEdit === 'Yes') {
    const referredToIndex = itemList && itemList.indexOf(itemList.find((f) => f.field.id === 'referredto'));
    if (referredToIndex !== -1 && values && values.referredto)
      setData('No', 0, 'send_to_rotation');
  }
};
