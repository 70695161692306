
export const AMLViewPermissions = 
{
    ViewAMLAdminCheckerModule : {
        permissionsId: "5e5b5b1b-9499-4f96-44f7-08db5f99228b",
        permissionsName: "View AML Admin Checker Module",
        description: null,
        componentsId: null,
        components: null
      },
      CancelApproveRequest :   {
        permissionsId: "3c66d67f-147e-4743-44f8-08db5f99228b",
        permissionsName: "Cancel/Approve Request",
        description: null,
        componentsId: null,
        components: null
      },
  

};
  