import { ButtonBase } from '@material-ui/core';
import moment from 'moment';
import React, {
  useState, useEffect, useCallback, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteComponent,
  DialogComponent,
  Inputs,
  NoContentComponent,
  NoSearchResultComponent,
  PaginationComponent,
  SelectComponet,
  Spinner, ViewTypes,
  PermissionsComponent,
} from '../../../Components';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { ViewTypesEnum } from '../../../Enums';
import {
  bottomBoxComponentUpdate, GlobalHistory, showError, showSuccess, returnPropsByPermissions,
} from '../../../Helper';
import { useTitle } from '../../../Hooks';
import {
  DeleteReport, GetAllRoles, GetAllSystemReports, SearchUsersByName
} from '../../../Services';
import { CardReportsBuilder, TablesReportsBuilder } from './ReportsBuilderManagementView';
import { ReportBuilderPermissions } from '../../../Permissions';
import { ReportsType } from './ReportsManagementViewComponents';

const parentTranslationPath = 'ReportsBuilderg';
const translationPath = '';

export const ReportsBuilderView = () => {
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}ReportsBuilder`));
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: null,
    orderBy: null,
  });
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [activeItem, setActiveItem] = useState(null);
  // eslint-disable-next-line max-len
  const [orderBy, setOrderBy] = useState(selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: null, orderBy: null });
  const [IdSdelected, setIdSdelected] = useState(null);
  const [reportDto, setReportDto] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [isOpen, setisOpen] = useState(false);
  const [selected, setSelected] = useState({
    activeFormType: 0,
  });
  const [Roles, setRoles] = useState({});
  const [canAddReport, setCanAddReport] = useState(false);

  const [loadingsRoles, setloadingsRoles] = useState(false);
  const [isOpenOpation, setisisOpenOpation] = useState(false);
  const [loadingsRolesUSER, setloadingsRolesUSER] = useState(false);
  const searchTimer = useRef(null);
  const [viewTypes, setViewTypes] = useState(ViewTypesEnum.cards.key);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: '',
    createdBy: null,
    roleId: null,
    fromDate: null,
    ownerId: null,
    toDate: null,
    filterBy: selectedOrderBy.filterBy,
    orderBy: 1

  });

  const onTypeChanged = (activeType) => {
    localStorage.setItem('ReportsBuilderView', JSON.stringify(activeType));
    setViewTypes(activeType);
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };

  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, pageIndex: 0, search }));
    }, 700);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const getGetAllSystemReports = useCallback(async () => {
    setIsLoading(true);
    if (returnPropsByPermissions(ReportBuilderPermissions.ViewSystemReports.permissionsId)) {
      const res = await GetAllSystemReports({ ...filter, pageIndex: filter.pageIndex === 0 && 1 || filter.pageIndex + 1 });
      if (!(res && res.status && res.status !== 200)) setReportDto(res || []);
      else setReportDto([]);
    }
    setIsLoading(false);
  }, [filter]);
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
    //  setFilter((item) => ({ ...item, filterBy: value }));
  };

  const getRoles = useCallback(async (value) => {
    setloadingsRoles(true);
    const response = await GetAllRoles(1, 25, value);
    setRoles({
      result: (response && response.result) || [],
      totalCount: (response && response.totalCount) || 0,
    });
    setloadingsRoles(false);
  }, []);

  const DeleteHandler = async () => {
    setIsLoading(true);
    const res = await DeleteReport(activeItem && activeItem.systemReportId);
    if (!(res && res.status && res.status !== 200)) {
      getGetAllSystemReports();
      showSuccess(t(`${translationPath}Reports-deleted-successfully`));
      setisOpen(false);
    } else {
      showError(t(`${translationPath}Reports-delete-failed`));
      setIsLoading(false);
      setisOpen(false);
    }
  };
  const SaveHandler = async () => {
    if (IdSdelected !== null)
      GlobalHistory.push(`/home/Reports-Builder/add?id=${IdSdelected.systemReportCategoryTypeId}&Report=${IdSdelected.category}&spName=${IdSdelected.spName}&systemReportCategoryTypeId=${IdSdelected.systemReportCategoryTypeId}`);
    else showError(t(`${translationPath}Reports-failed`));
  };

  const haveAddReportPermissions = () => {
    if (returnPropsByPermissions(ReportBuilderPermissions.AddContactsReport.permissionsId))
      return true;
    if (returnPropsByPermissions(ReportBuilderPermissions.AddLeadsReport.permissionsId))
      return true;
    if (returnPropsByPermissions(ReportBuilderPermissions.AddUnitsReport.permissionsId))
      return true;
    if (returnPropsByPermissions(ReportBuilderPermissions.AddPropertiesReport.permissionsId))
      return true;
    if (returnPropsByPermissions(ReportBuilderPermissions.AddActivitiesReport.permissionsId))
      return true;
    if (returnPropsByPermissions(ReportBuilderPermissions.AddTransactioReport.permissionsId))
      return true;
      if (returnPropsByPermissions(ReportBuilderPermissions.AddUserLoginHistoryReport.permissionsId))
      return true;
      if (returnPropsByPermissions(ReportBuilderPermissions.AddRotationSchemesReport.permissionsId))
      return true;

    return false;
  };

  useEffect(() => {
    if (returnPropsByPermissions(ReportBuilderPermissions.ViewSystemReports.permissionsId)) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={(reportDto && reportDto.totalCount) || 0}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }

    setFilter((item) => ({
      ...item,
      orderBy: selectedOrderBy.orderBy,
      filterBy: selectedOrderBy.filterBy

    }));
  };
  const changeActiveFormType = (newValue) => {
    setSelected((item) => ({
      ...item,
      activeFormType: +newValue,
    }));
  };

  const getAllUsers = useCallback(async (name) => {
    setloadingsRolesUSER(true);
    const res = await SearchUsersByName(name || '', 0, 10);
    if (!(res && res.status && res.status !== 200)) {
      setAllUsers({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllUsers({
        result: [],
        totalCount: 0,
      });
    }
    setloadingsRolesUSER(false);
  }, []);

  useEffect(() => {
    getGetAllSystemReports(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    getRoles();
    getAllUsers();
    setCanAddReport(haveAddReportPermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='ReportsBuilderView-css'>
        <Spinner isActive={isLoading} />
        <div className=' view-wrapper d-flex-column'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='filter-ButtonBase'>
                <div className='section px-2'>
                  {
                    canAddReport && (
                      <ButtonBase
                        id='add-new-Reports-ref'
                        onClick={() => setisisOpenOpation(true)}
                        className='btns theme-solid bg-primary'
                      >
                        <span className='mdi mdi-plus mx-1 ' />
                        {t(`${translationPath}add-new-Reports`)}
                      </ButtonBase>
                    )
                  }
                </div>
                <PermissionsComponent
                  permissionsList={Object.values(ReportBuilderPermissions)}
                  permissionsId={ReportBuilderPermissions.ViewSystemReports.permissionsId}
                >
                  <div className='sc-search d-flex px-2'>
                    <span className='mx-2 mt-1'>{t(`${translationPath}select`)}</span>
                    <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
                    <span className='px-2 d-flex'>
                      <span className='texts-large mt-1'>
                        {t(`${translationPath}order-by`)}
                        :
                      </span>
                      <div className='px-2'>
                        <SelectComponet
                          idRef='filterByRef'
                          data={[
                            { id: 'CreatedOn', filterBy: 'created-date' },
                            { id: 'UpdateOn', filterBy: 'Updated-date' },
                          ]}
                          wrapperClasses='mb-3'
                          isRequired
                          value={selectedOrderBy.filterBy}
                          onSelectChanged={filterByChanged}
                          emptyItem={{
                            value: null,
                            text: 'select-filter-by',
                            isDisabled: false,
                          }}
                          valueInput='id'
                          textInput='filterBy'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />

                      </div>
                      <div className='px-2'>
                        <SelectComponet
                          idRef='orderByRef'
                          data={[
                            { id: 1, orderBy: 'ascending' },
                            { id: 2, orderBy: 'descending' },
                          ]}
                          emptyItem={{
                            value: null,
                            text: 'select-sort-by',
                            isDisabled: false,
                          }}
                          value={selectedOrderBy.orderBy}
                          onSelectChanged={orderByChanged}
                          wrapperClasses='mb-3'
                          isRequired
                          valueInput='id'
                          textInput='orderBy'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <div className='mt-1'>
                        <ButtonBase
                          className='btns theme-solid'
                          id='action_apply'
                          onClick={orderBySubmitted}
                          disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                        >
                          <span>{t(`${translationPath}apply`)}</span>
                        </ButtonBase>
                      </div>
                    </span>

                  </div>
                </PermissionsComponent>
              </div>
              <div />
              <PermissionsComponent
                permissionsList={Object.values(ReportBuilderPermissions)}
                permissionsId={ReportBuilderPermissions.ViewSystemReports.permissionsId}
              >
                <div className='section autocomplete-section'>
                  <div className='Rep d-flex-column px-2 w-100 p-relative '>
                    <div className='w-100 p-relative'>
                      <div className='sc-search d-flex m2-2'>
                        <Inputs
                          idRef='SearcReportshRef'
                          label={t(`${translationPath}Search-in-Reports`)}
                          // value={searchInput}
                          beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                          inputPlaceholder={t(`${translationPath}type-Search-in-Reports`)}
                          translationPath={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          onInputChanged={(e) => {
                            searchHandler(e.target.value);
                          }}
                        />
                        <div className='min-30' />
                        <AutocompleteComponent
                          isLoading={loadingsRolesUSER}
                          inputPlaceholder={(selected && selected.activeFormType === 1 && t(`${translationPath}search-owner`)) || (selected && selected.activeFormType === 2 && t(`${translationPath}search-createby`)) || t(`${translationPath}Selact-type-user-seach`)}
                          multiple={false}
                          // eslint-disable-next-line max-len
                          data={(selected.activeFormType === 0 && []) || (allUsers && allUsers.result) || []}
                          displayLabel={(option) => (option && (`${option.firstName} ${option.lastName}`)) || ''}
                          chipsLabel={(option) => (option && option.userName) || ''}
                          withoutSearchButton
                          onInputKeyUp={(e) => {
                            const newValue = e.target.value;
                            if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllUsers(newValue);
                            }, 700);
                          }}
                          inputStartAdornment={(
                            <SelectComponet
                              data={[
                                {
                                  key: 0,
                                  value: 'Select',
                                },
                                {
                                  key: 1,
                                  value: 'Owner',
                                },
                                {
                                  key: 2,
                                  value: 'CreatedBy',
                                },
                              ]}
                              value={selected.activeFormType}
                              valueInput='key'
                              textInput='value'
                              onSelectChanged={changeActiveFormType}
                              wrapperClasses='over-input-select w-auto'
                              idRef='relatedToTypeRef'
                              parentTranslationPath={parentTranslationPath}
                              translationPath={translationPath}
                              translationPathForData={translationPath}
                            />
                          )}
                          onChange={(event, newValue) => {
                            if (selected.activeFormType === 1) {
                              setFilter((item) => ({ ...item, ownerId: (newValue && newValue.applicationUserId) }));
                              setFilter((item) => ({ ...item, createdBy: null }));
                            } else if (selected.activeFormType === 2) {
                              setFilter((item) => ({ ...item, createdBy: (newValue && newValue.usersId) }));
                              setFilter((item) => ({ ...item, ownerId: null }));
                            }
                          }}

                        />
                      </div>
                      <div className='sc-search d-flex px-2'>
                        <span className=' mt-1' />
                        <AutocompleteComponent
                          isLoading={loadingsRoles}
                          inputPlaceholder={t(`${translationPath}search-Seaqrchbyrols`)}
                          multiple={false}
                          inputStartAdornment={<span className='mdi mdi-playlist-play mdi-24px c-gray-primary' />}
                          data={(Roles && Roles.result) || []}
                          displayLabel={(option) => (option && option.rolesName) || ''}
                          chipsLabel={(option) => (option && option.rolesName) || ''}
                          withoutSearchButton
                          onInputKeyUp={(e) => {
                            const newValue = e.target.value;
                            if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getRoles(newValue);
                            }, 700);
                          }}
                          onChange={(event, newValue) => {
                            // eslint-disable-next-line max-len
                            setFilter((item) => ({ ...item, roleId: (newValue && newValue.rolesId) }));
                          }}
                        />
                        <div className='min-30' />
                        <DateRangePickerComponent
                          onClearClicked={() => {
                            setDateFilter(dateRangeDefault);
                            setFilter((item) => ({
                              ...item,
                              fromDate: null,
                              toDate: null
                            }));
                          }}
                          ranges={[dateFilter]}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onDateChanged={(selectedDate) => {
                            setDateFilter({
                              startDate: selectedDate.selection && selectedDate.selection.startDate,
                              endDate: (selectedDate.selection && selectedDate.selection.endDate),
                              key: 'selection',
                            });
                            setFilter((item) => ({
                              ...item,
                              fromDate: (moment(selectedDate.selection && selectedDate.selection.startDate).add(24, 'hours')),
                              toDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                            }));
                          }}
                        />

                      </div>

                    </div>
                  </div>
                  <ViewTypes
                    onTypeChanged={onTypeChanged}
                    initialActiveType={viewTypes}
                    activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
                    className='mb-3'
                  />
                </div>
              </PermissionsComponent>
            </div>
          </div>
          {' '}
        </div>
      </div>
      <>
        <PermissionsComponent
          permissionsList={Object.values(ReportBuilderPermissions)}
          permissionsId={ReportBuilderPermissions.ViewSystemReports.permissionsId}
        >
          {((reportDto && reportDto.result && reportDto.result.length === 0) || (reportDto && reportDto.totalCount === 0)) && (filter.search !== '') ? (
            <NoSearchResultComponent />
          ) : (

            (reportDto && reportDto.totalCount === 0 && (
              <NoContentComponent />
            )) || (

              (viewTypes === ViewTypesEnum.tableView.key && (
                <>
                  <div className='ReportsBuilder-data-view-wrapers'>
                    <TablesReportsBuilder
                      translationPath={translationPath}
                      reportDto={(reportDto && reportDto.result) || []}
                      reportDtoFull={reportDto && reportDto}
                      filter={filter}
                      setActiveItem={(item) => { setActiveItem(item); }}
                      parentTranslationPath={parentTranslationPath}
                      isOpenChanged={() => setisOpen(true)}
                    />

                  </div>
                </>
              )) || (
                < >
                  <div className='ReportsBuilder-data-view-wrapers'>
                    <CardReportsBuilder
                      translationPath={translationPath}
                      reportDto={reportDto && reportDto.result}
                      setActiveItem={(item) => { setActiveItem(item); }}
                      parentTranslationPath={parentTranslationPath}
                      isOpenChanged={() => setisOpen(true)}
                    />
                  </div>
                </>
              )))}
        </PermissionsComponent>
      </>
      <DialogComponent
        titleText='confirm-message'
        saveText={t(`${translationPath}confirm`)}
        SmothMove
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <Spinner isActive={isLoading} isAbsolute />
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span className='fz-18px fw-bold'>
              {`${`${t(`${translationPath}Reports-delete-description`)}` || ''}`}
              <span className='d-flex-center  fz-22px fw-bold mt-2'>
                {' '}
                (
                {' '}
                {' '}
                {activeItem && activeItem.systemReportName}
                {' '}
                {' '}
                {' '}
                )
                {' '}
              </span>

            </span>
          </div>
        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={isOpen}
        onSaveClicked={DeleteHandler}
        onCloseClicked={() => setisOpen(false)}
        onCancelClicked={() => setisOpen(false)}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <DialogComponent
        titleText={t(`${translationPath}Report-Type`)}
        saveText={`${t(`${translationPath}confirm`)} ${t(`${translationPath}to`)} ${(IdSdelected != null) ? IdSdelected && IdSdelected.systemReportCategoryTypeName : ''}`}
        saveType='button'
        SmothMove
        maxWidth='lg'
        dialogContent={(
          <div className='d-flex-column-center'>
            <ReportsType
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              IdSdelected={(item) => setIdSdelected(item)}
            />
          </div>
        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={isOpenOpation}
        onSaveClicked={SaveHandler}
        saveIsDisabled={IdSdelected === null}
        onCloseClicked={() => { setisisOpenOpation(false); setIdSdelected(null); }}
        onCancelClicked={() => { setisisOpenOpation(false); setIdSdelected(null); }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </>
  );
};
