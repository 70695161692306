import React, { useCallback, useEffect, useState } from 'react';
import { Slider, Button, InputAdornment } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import ChipInput from 'material-ui-chip-input';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import {
 UnitsOperationTypeEnum, FormsIdsEnum, LeadsStatusEnum, LeadTypeIdEnum, LeadsClassTypesEnum
} from '../../Enums';
import {
 GetAdvanceSearchContacts, PropertiesAdvanceSearchTest, UnitsAdvanceSearchTest, GetSensitiveFieldsForUser
} from '../../Services';
import { getIsFieldWithPermission } from '../../Helper/ExceptionPermissions.Helper';
import {
  LookupRule,
  ContactRule,
  PropertyRule,
  PropertyOrUnitRule,
  VisaRules,
  LookupsEffectedOnRules,
  UserRule,
  TitleRuleV2,
  UserDefaultRule,
  StatusLeadDefaulRule2,
  OperationTypeRule,
  ListingDateRule2,
  ListingExpiryDateRule2,
  ListingDate,
  BuiltupAreaRule2,
  OnchangeCountryRule2,
  OnchangeCityRule2,
  OnchangeDistrictRule2,
  OnchangeCommunityRule2,
  OnchangeSubCommunityRule2,
  UnitRefNumberRule,
  SekeerLeadRule,
  OnChangeOperationTypeForSeekerLead,
  PassportRule2,
  LiabilityAmount2,
  MortgageDetailsRules2,
  LeadsRule,
  LeadsDefaultRule,
  onChangePropertyRule,
  UnitModelRule,
  ShortageInRule,
  CampaignsDefaultRule,
  CampaignsRule,
  RotationLeadRule,
  RotationLeadDefaultRule,
  EmptyDateFillDefaultRuleV2,
  RentDeposit,
  UnitModelDefaultRule,
  ConstructionDateRule,
  ConstructionDateDefault,
  UnitRentedRule,
  ContactPreferenceRule,
  SensitiveFieldsForUserRule,
  RentDeposit2,
  UnitRefNumberLeadOwnerRule,
  LeadConvertSizeSqftToSqmRule2,
  LeadConvertSizeSqmToSqftRule2,
  LeadConvertSizeSqftToSqmRuleSlider,
  LeadConvertSizeSqmToSqftRuleSlider,
  LeadConvertSizeSqftToSqmDefaultRule,
  IsMortgageReletedModeOfPaymentRule,
  ModeOfPaymentRule,
  ListingAndRentListingDateRule,
  PropertyPlanRule,
} from '../../Rule';

import { UnitMapper as LeaseUnitMapper } from '../../Views/Home/UnitsView/UnitMapper';
import { UnitMapper } from '../../Views/Home/UnitsSalesView/UnitSalesMapper/UnitMapper';

import { OnchangePropertyInUnitRuleV2, OnChangePropertyOnLeadRule2, UnitRule } from '../../Rule/UnitRule';
import {
  AutocompleteComponent,
  CheckboxesComponent,
  DatePickerComponent,
  Inputs,
  PhonesComponent,
  RadiosGroupComponent,
} from '../Controls';
import { RepeatedItemDialog } from '../../Views/Home/FormBuilder/Dialogs/RepeatedItemDialog';
import PriceAndPercentage from '../../Views/Home/FormBuilder/Utilities/PriceAndPercentage';
import { ContactsDialog } from '../../Views/Home/FormBuilder/Dialogs/ContactsDialog';
import { DetailsDialog } from '../../Views/Home/FormBuilder/Dialogs/DetailsDialog';
import { NotHavePolicyToDisplayDataDialog } from '../../Views/Home/FormBuilder/Dialogs/NotHavePolicyToDisplayDataDialog';
import { UploadDialog } from '../../Views/Home/FormBuilder/Dialogs/UploadDialog';
import { MapDialog } from '../../Views/Home/FormBuilder/Dialogs/MapDialog';
import { ModelsUnitsDialog } from '../../Views/Home/FormBuilder/Dialogs/ModelsUnitsDialog';
import { LeadOwnerDialog } from '../../Views/Home/FormBuilder/Dialogs/LeadOwnerDialog';
import { CONTACTS, PROPERTIES, UNITS } from '../../config/pagesName';
import { UnitModelPopoverComponent } from '../UnitModelPopoverComponent/UnitModelPopoverComponent';
import { RepeatedLinkDialog } from '../../Views/Home/FormBuilder/Dialogs/RepeatedLinkDialog';
import { BathroomsAndBedroomsDefaultRuleV2 } from '../../Rule/BathroomsAndBedroomsDefaultRule';
import { GetParams, showError, AssignToInquiresHelper } from '../../Helper';

import { LeaseLeadOwnerDialog } from '../../Views/Home/FormBuilder/Dialogs/LeaseLeadOwnerDialog';
import { FinancialStatusDetails2 } from '../../Rule/FinancialStatusDetailsRules';

export const ConvertJsonV2Component = ({
  item, // item properties & data
  itemValue, // value of item
  allItems, // all items as properties
  allItemsValues, // all items values
  // onValueChanged, // on itemValue or on element of properties like enum changed
  onItemChanged, // on itemValue or on element of properties like enum changed
  onValueChanged, // on itemValue or on element of properties like enum changed
  error, // if this item is invalid (bool)
  helperText, // the error message (string)
  isSubmitted,
  // loadings, // array of current items loading on
  onLoadingsChanged,
  isLoading, // currentItemIsLoadingOrNot
  reload,
  save,
  hideDeposit,
  typeOf,
  isOpenInsideForm,
  sensitiveFieldsForUser,
  parentTranslationPath ,
  translationPath ,
}) => {
  const { t } = useTranslation(['Shared']);
  const { id } = item.field;

  const label = (item && item.data && item.data.caption && item.data.caption !== '') ? (item.data.caption) : (item.data.title);

  const [unitModelPopoverAttachedWith, setUnitModelPopoverAttachedWith] = useState(null);
  const [dataInSideViewButton, setDataInSideViewButton] = useState(null);
  const [isSensitiveLoading2, setIsSensitiveLoading2] = useState(0);
  const [openMapDialog, setOpenMapDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [modelsUnitsDialog, setModelsUnitsDialog] = useState(false);
  const [RepeatedDialog, setRepeatedDialog] = useState(false);
  const [repeatedLinkDialog, setRepeatedLinkDialog] = useState(false);
  const [isOpenContactsDialog, setIsOpenContactsDialog] = useState(false);
  const [isOpenLeadOwnerDialog, setIsOpenLeadOwnerDialog] = useState(false);
  const [leadOwnerValue, setLeadOwnerValue] = useState(null);
  const [isOpenLeaseLeadOwnerDialog, setisOpenLeaseLeadOwnerDialog] = useState(false);
  const [LeaseleadOwnerValue, setLeaseLeadOwnerValue] = useState(null);
  const [isOpenItemDetails, setIsOpenItemDetails] = useState(false);
  const [sensitiveFieldsForUser2, setSensitiveFieldsForUser2] = useState(null);
  const [isNotHavePolicyToDisplayData, setIsNotHavePolicyToDisplayData] = useState(false);
  const [isLoadingDetailsDialog, setIsLoadingDetailsDialog] = useState(false);

  useEffect(() => {
    if ((item.field.id === 'rent_listing_expiry_period' && item.data.enum.length === 0) || (item.field.id === 'sale_type' && item.data.enum.length === 0))
      LookupRule(item, allItems);

       CampaignsDefaultRule(item, onItemChanged, (allItemsValues && allItemsValues.campaign_name && allItemsValues.campaign_name.name) || '');
  }, [item, allItems]);

  const GetSensitiveFieldsForUserAPI = useCallback(async (formId, sensitiveFieldsForUser) => {
    const allSensitiveFields = await GetSensitiveFieldsForUser(formId, sensitiveFieldsForUser);

    if (!(allSensitiveFields && allSensitiveFields.status && allSensitiveFields.status !== 200)) {
      setSensitiveFieldsForUser2(allSensitiveFields);
      setIsSensitiveLoading2(1);
    } else {
      setSensitiveFieldsForUser2([]);
      setIsSensitiveLoading2(2);
    }
  });

  const onSearchFieldActionClicked = useCallback(async () => {
    setIsLoadingDetailsDialog(true);
    let havePolicyToDisplayDataInSideViewButton = false;
    const itemIndex = allItems.findIndex(
      (effectedItem) => effectedItem.data.title.replace('*', '').trim() === item.data.dependOn
    );
    let l = {};
    if (allItemsValues[itemIndex] && itemValue)
      l = { ...itemValue, itemId: allItemsValues[itemIndex] };
    else if (!allItemsValues[itemIndex] && itemValue)
      l = { ...itemValue, itemId: (item && item.data && item.data.searchKey) };
    else if (allItemsValues[itemIndex]) l = { itemId: allItemsValues[itemIndex] };
    else l = { itemId: (item && item.data && item.data.searchKey) };

    if (l.itemId && l.itemId.toLowerCase() === 'contact') {
      l.itemId = CONTACTS;

      if (!l.type)
        l.type = 1;
        const body = {
          criteria: {
            Ids:
            [{ searchType: 1, value: l && l.id }]
          },
          filterBy: 'createdOn',
          orderBy: 2,
        };

          const res = await GetAdvanceSearchContacts({ pageIndex: 0, pageSize: 2 }, body);
        if (!(res && res.status && res.status !== 200)) {
          if (res && res.totalCount > 0) {
            if (res && res.result && res.result.length && res.result[0].contactJson) {
              const contactJson = JSON.parse(res && res.result && res.result.length && res.result[0] && res.result[0].contactJson);
              havePolicyToDisplayDataInSideViewButton = true;
              if (havePolicyToDisplayDataInSideViewButton) {
                setDataInSideViewButton(contactJson);

                setTimeout(() => {
                  GetSensitiveFieldsForUserAPI(contactJson && contactJson.contact && contactJson.contact.contact_type_id, { key: 'contact', id: res.result[0].contactId });
                }, 200);
              }
            }
          } else {
            havePolicyToDisplayDataInSideViewButton = false;
            setDataInSideViewButton(null);
          }
        } else {
          havePolicyToDisplayDataInSideViewButton = false;
          setDataInSideViewButton(null);
        }
    }

    if (l.itemId && l.itemId.toLowerCase() === 'property') {
      l.itemId = PROPERTIES;
      l.type = '1';
      const body = {
        criteria: {
          Ids:
          [{ searchType: 1, value: (l && l.id) }]
        },
        filterBy: 'createdOn',
        orderBy: 2,
      };

      const res = await PropertiesAdvanceSearchTest({ pageIndex: 0, pageSize: 2 }, body);
      if (!(res && res.status && res.status !== 200)) {
        if (res && res.totalCount > 0) {
          if (res && res.result && res.result.length && res.result[0].propertyJson) {
            const propertyJson = JSON.parse(res && res.result && res.result.length && res.result[0].propertyJson);
            havePolicyToDisplayDataInSideViewButton = true;
            if (havePolicyToDisplayDataInSideViewButton) {
              setDataInSideViewButton(propertyJson);
              setTimeout(() => {
                GetSensitiveFieldsForUserAPI(FormsIdsEnum.properties.id, (propertyJson && { key: 'property', id: res.result[0].propertyId }));
              }, 200);
            }
          }
        } else {
          havePolicyToDisplayDataInSideViewButton = false;
          setDataInSideViewButton(null);
        }
      } else {
        havePolicyToDisplayDataInSideViewButton = false;
        setDataInSideViewButton(null);
      }
    }
    if (l.itemId && l.itemId.toLowerCase() === 'unit') {
      const operationType = (allItemsValues.unit_ref_number && allItemsValues.unit_ref_number.operation_type_name) || (allItemsValues.lead_operation && allItemsValues.lead_operation === 'Seller' ? 'Sale' : 'Rent');
      localStorage.setItem('unitModelRelatedData', JSON.stringify({ operation_type: { lookupItemName: ((itemValue && itemValue.operationType) || operationType || null) } }));
      l.itemId = UNITS;
      l.operationType = (itemValue && itemValue.operationType) || operationType || null;
      const body = {
        OperationType: (l && l.operationType && l.operationType === 'Sale') ? 'Sale' : 'Lease',
        criteria: {
          unitId: [{ searchType: 1, value: (l && l.id) }]
        },
        filterBy: 'createdOn',
        orderBy: 2,
      };

      const res = await UnitsAdvanceSearchTest({ pageIndex: 0, pageSize: 2 }, body);
      if (!(res && res.status && res.status !== 200)) {
        if (res && res.totalCount > 0) {
          if (res && res.result && res.result.length && res.result[0] && res.result[0].unitJson) {
            const unitJson = JSON.parse(res && res.result && res.result.length && res.result[0].unitJson);
            const unitDatails = ((operationType === UnitsOperationTypeEnum.sale.key) || (operationType === UnitsOperationTypeEnum.rentAndSale.key)) ? UnitMapper({ ...unitJson, ...res.result[0] }) : LeaseUnitMapper({ ...unitJson, ...res.result[0] });
            localStorage.setItem('unitModelRelatedData', JSON.stringify(unitDatails));
            havePolicyToDisplayDataInSideViewButton = true;
            if (havePolicyToDisplayDataInSideViewButton) {
              const unitId = l && l.id;

              setDataInSideViewButton(unitJson);
              setTimeout(() => {
              GetSensitiveFieldsForUserAPI(FormsIdsEnum.units.id, unitJson && { key: 'unit', id: res.result[0] && res.result[0].unitId });
              }, 200);
            }
          }
        } else {
        havePolicyToDisplayDataInSideViewButton = false;
        setDataInSideViewButton(null);
       }
      } else {
        havePolicyToDisplayDataInSideViewButton = false;
        setDataInSideViewButton(null);
      }
    }
    localStorage.setItem('current', JSON.stringify(l));
    if (!itemValue)
      setIsOpenContactsDialog(true);
     else if (havePolicyToDisplayDataInSideViewButton)
      setIsOpenItemDetails(true);

      else if (!havePolicyToDisplayDataInSideViewButton)
         setIsNotHavePolicyToDisplayData(true);

     setIsLoadingDetailsDialog(false);
  }, [allItems, allItemsValues, item.data.dependOn, item.data.searchKey, itemValue]);
  const openUnitModelPopoverHandler = useCallback((event) => {
    setUnitModelPopoverAttachedWith(event.currentTarget);
  }, []);
  const onPopoverClose = useCallback(() => {
    setUnitModelPopoverAttachedWith(null);
  }, []);

  useEffect(() => {
    if (id === 'title')
      setTimeout(() => { TitleRuleV2(id, onValueChanged, item, allItems, itemValue); }, 2000);
  }, []);

  useEffect(() => {
    if (reload) {
      if (allItemsValues && allItemsValues.operation_type && allItemsValues.operation_type.lookupItemName && allItemsValues.operation_type.lookupItemName === 'SaleAndRent') {
        const operationTypeIndex = allItems.findIndex((f) => f.field.id.toLowerCase() === 'operation_type' && f.data.specialKey === 'UnitOperationType');
        if (operationTypeIndex !== -1)
          allItems[operationTypeIndex].data.isReadonly = true;
      }
    }
  }, [reload]);

  useEffect(() => {
    setTimeout(() => {
      const leadStatus = localStorage.getItem('leadStatus');
      if (leadStatus) {
        const leadStatusJson = JSON.parse(leadStatus);
        if (item.field.id === 'status' && leadStatusJson && (leadStatusJson.lookupItemName === 'Closed' || (+LeadsStatusEnum.Closed.status === +leadStatusJson.lookupItemId)))
          item.data.isReadonly = true;
        else if (item.field.id === 'status' && leadStatusJson && (leadStatusJson.lookupItemName === 'Open' || (+LeadsStatusEnum.Open.status === +leadStatusJson.lookupItemId)))
          item.data.isReadonly = false;
      }
    }, 300);
  }, [item]);

  const getStatusValue = () => {
    if (item.field.id === 'referredto') {
      const { status } = allItemsValues;
      if (status)
        return !!((status && status.lookupItemName === 'Closed'));
    }
    return false;
  };

  const disableIsMortgageInTenant = () => {
    if (item.field.id === 'is_mortgage' && item.data.specialKey === 'is_mortgage_seeker_lead' && allItemsValues && allItemsValues.operation_type && allItemsValues.operation_type.lookupItemName === 'Rent')
        return true;
     if (item.field.id === 'mode_of_payment' && item.data.DtoName === 'mode_of_payment_seeker_lead' && allItemsValues &&
       allItemsValues.operation_type && allItemsValues.operation_type.lookupItemName === 'Rent')
        return true;

    return false;
  };

  const getStatusValue2 = () => {
    if (item.field.id === 'status') {
      const { status } = allItemsValues;
      if (status && status.lookupItemName === 'Closed')
        return true;
    }
    return false;
  };

  const disableQualification = () => 
  {
     if(item.field.id === 'qualification') 
      return true ; 
    else return false  ; 
   
  };

  const getSendLeadToRotationSelected = () => {
    const sendToRotationIndex = allItems.indexOf(allItems.find((f) => f.field.id === 'send_to_rotation'));
    if (sendToRotationIndex !== -1 && item.field.id === 'referredto' && allItemsValues && allItemsValues.send_to_rotation && allItemsValues.send_to_rotation === 'Yes')
       return true;
    return false;
  };

  const getLeadOperationValue = () => {
    if (item.field.id === 'lead_operation')
      return true;
    return false;
  };

  const saleTypeOwnerlead = () => {
    if (item.field.id === 'sale_type' && item.data.specialKey === 'sale_type_lead_owner' && allItemsValues && allItemsValues.unit_ref_number)
      return true;
    return false;
  };

  const getIsLeadAuditor = () => {
    if (item.field.id === 'lead_auditor')
      return true;
    return false;
  };

  const disableLead = () => {
    if (item && item.field && item.field.id === 'lead' && item.data.searchableKey === 'lead_inquiry')
      return true;
    return false;
  };

  const financialStatusDetailsRules = () => {
    if ((item.field.id === 'auto_loan_outstanding_balance' || item.field.id === 'auto_loan_monthly_installment') && (allItemsValues.has_auto_loan === 'No' || !allItemsValues.has_auto_loan))
      return true;

      if ((item.field.id === 'personal_loan_outstanding_balance' || item.field.id === 'personal_loan_monthly_installment') && (allItemsValues.has_personal_loan === 'No' || !allItemsValues.has_personal_loan))
      return true;

    if ((item.field.id === 'home_loan_outstanding_balance' || item.field.id === 'home_loan_monthly_installment') && (allItemsValues.has_home_loan === 'No' || !allItemsValues.has_home_loan))
      return true;

    if ((item.field.id === 'credit_card_outstanding_balance' || item.field.id === 'credit_card_monthly_installment') && (allItemsValues.has_credit_card === 'No' || !allItemsValues.has_credit_card))
      return true;
    if ((item.field.id === 'other_liabilities_outstanding_balance' || item.field.id === 'other_liabilities_monthly_installment') && (allItemsValues.has_other_liabilities === 'No' || !allItemsValues.has_other_liabilities))
      return true;

    if ((item.field.id === 'liability_amount'))
      return true;

    return false;
  };

  const customerProfileSelected = () => {
    const customerProfileIndex = allItems && allItems.indexOf(allItems.find((f) => f.field.id === 'customer_profile'));
    if (customerProfileIndex !== -1 && allItems && allItems[customerProfileIndex].data.isReadonly)
      return true;

    if ((item.field.id === 'confirmed_employee') && (!allItemsValues.customer_profile))
      return true;

    if ((item.field.id === 'allowance') && (!allItemsValues.customer_profile))
      return true;

    if ((item.field.id === 'liability_from_company') && (!allItemsValues.customer_profile))
      return true;

    if ((item.field.id === 'company_name') && (!allItemsValues.customer_profile) && (item.data.specialKey !== 'contact_company_name'))
      return true;
    if ((item.field.id === 'monthly_income') && (!allItemsValues.customer_profile))
      return true;

    if ((item.field.id === 'yearly_income') && (!allItemsValues.customer_profile))
      return true;
    if ((item.field.id === 'date_of_establishment') && (!allItemsValues.customer_profile))
      return true;
    if ((item.field.id === 'number_of_partner') && (!allItemsValues.customer_profile))
      return true;

    return false;
  };

  const disableUnitRefNumber = () => {
    if (item.field.id === 'unit_ref_number' && item.data.DtoName === 'unit_ref_number_owner_lead') {
       if (item.data.isReadonly)
          return true;

        if (!(item.data.isReadonly) && item.data && item.data.valueToEdit)
          return true;

          if (!(item.data.isReadonly) && !(item.data.valueToEdit))
          return false;
    }
    return false;
  };

  const getDataEnumList = (item) => {
    let list = [];
    if (item.field.id === 'operation_type' && (item.data.specialKey && item.data.specialKey === 'UnitOperationType')) {
      const unitModelRelatedData = localStorage.getItem('unitModelRelatedData');
      if (unitModelRelatedData) {
        const unitModelRelatedDataJson = JSON.parse(unitModelRelatedData);
        if ((id === 'operation_type' && +GetParams('id') && unitModelRelatedDataJson && unitModelRelatedDataJson.operation_type) || (unitModelRelatedDataJson && unitModelRelatedDataJson.lookupItemName)) {
          if ((unitModelRelatedDataJson.operation_type && unitModelRelatedDataJson.operation_type.lookupItemName && unitModelRelatedDataJson.operation_type.lookupItemName === 'Sale') || (unitModelRelatedDataJson.operation_type && unitModelRelatedDataJson.operation_type_name === 'Sale'))
            list = item.data.enum.filter((ot) => ot.lookupItemName === 'Sale' || ot.lookupItemName === 'SaleAndRent');
          else if ((unitModelRelatedDataJson.operation_type && unitModelRelatedDataJson.operation_type.lookupItemName && unitModelRelatedDataJson.operation_type.lookupItemName === 'Rent') || (unitModelRelatedDataJson.operation_type && unitModelRelatedDataJson.operation_type.operation_type_name === 'Rent'))
            list = item.data.enum.filter((ot) => ot.lookupItemName === 'Rent' || ot.lookupItemName === 'SaleAndRent');
          else if ((unitModelRelatedDataJson && unitModelRelatedDataJson.operation_type && unitModelRelatedDataJson.operation_type.lookupItemName && unitModelRelatedDataJson.operation_type.lookupItemName === 'SaleAndRent') || (unitModelRelatedDataJson && unitModelRelatedDataJson.operation_type && unitModelRelatedDataJson.operation_type.operation_type_name === 'SaleAndRent') || (unitModelRelatedDataJson && unitModelRelatedDataJson.lookupItemName === 'SaleAndRent')) {
            item.data.isReadonly = true;
            list = item.data.enum.filter((ot) => ot.lookupItemName === 'SaleAndRent');
          }
        }
        return list;
      }
    } else if (id === 'lead_type') {
      const filterlist = [{
        lookupItemId: 495,
        lookupItemName: 'Owner',
        lookupItemCode: null,
        description: '495',
        order: 99,
        parentLookupItemId: null,
        parentLookupItemName: null,
        parentLookupTypeId: 0,
        parentLookupTypeName: null,
        isEditable: false,
        lookupItemParents: null,
        createdBy: null,
        createdByName: null,
        createdOn: null,
        updatedBy: null,
        updatedByName: null,
        updateOn: null
      }];
      return filterlist;
    } else if (id === 'contact_preference') {
      const list = [];
      const addSelecAll = {
        lookupItemId: 1993,
        lookupItemName: 'Select All',
      };

      list.push(addSelecAll);
      item.data.enum.map((item1) => {
        list.push(item1);
      });

      return list;
    }
    return item.data.enum;
  };

  const getIsUpdateCloseResonAndRemarks = () => {
    if ((id === 'close_reason')) {
      const leadStatus = localStorage.getItem('leadStatus');
      if (leadStatus) {
        const leadStatusJson = JSON.parse(leadStatus);
        if (leadStatusJson && leadStatusJson.lookupItemName === 'Closed')
          return true;
      }
    } else if (id === 'closing_remarks')
      return !(allItemsValues.status && allItemsValues.status.lookupItemName === 'Closed');

    return false;
  };

  const displyTextField = () => {
    if (id !== 'rented_value' && (item.field.FieldType === 'textField' && (item.data.specialKey === 'currency' || item.data.specialKey === 'size' || item.data.specialKey === 'decimal')))
      return true;

    if ((item.field.FieldType === 'textField' && item.data.specialKey === 'currency') && (id === 'rented_value' && allItemsValues && allItemsValues.is_unit_rented && allItemsValues.is_unit_rented === 'Yes'))
       return true;

   return false;
  };

  const notUpdateInsideForm = () => {
    if (isOpenInsideForm && (item.field.id === 'first_name' || item.field.id === 'last_name' || item.field.id === 'property_name' || item.field.id === 'company_name'))
      return true;
    return false;
  };

  const displySelectFields = () => {
    if (item.field.id === 'sale_type' && item.data.specialKey === 'sale_type_lead_owner') {
      const saleTypeIndex = allItems.indexOf(allItems.find((f) => f.field.id === 'sale_type' && f.data.specialKey === 'sale_type_lead_owner'));
    const leadOperationIndex = allItems.indexOf(allItems.find((f) => f.field.id === 'lead_operation'));

    if (saleTypeIndex !== -1 && leadOperationIndex !== -1 && allItemsValues.lead_operation && allItemsValues.lead_operation === 'Seller')
      return true;

        return false;
    }

     if (item.field.id === 'close_reason')
       return (!!(allItemsValues && allItemsValues.status && allItemsValues.status.lookupItemName === 'Closed'));

   return false;
  };

  const displySearchFields = () => {
    const fieldDto = item && item.data && item.data.Dto;
    if (fieldDto && (fieldDto === 'assign_to_listing_shortage' || fieldDto === 'assign_to_zero_matching_sale' || fieldDto === 'assign_to_zero_matching_lease'))
       return getIsFieldWithPermission(item);

   return true;
  };

  useEffect(() => {
    if ((item.field.id === 'bathrooms' && item.field.FieldType === 'select') || (item.field.id === 'bedrooms' && item.field.FieldType === 'select')) {
      setTimeout(() => {
        BathroomsAndBedroomsDefaultRuleV2(item, onValueChanged, allItems, allItemsValues);
      }, 500);
    }

    if (item.field.id === 'size_sqft')
      LeadConvertSizeSqftToSqmDefaultRule(item, allItemsValues, onValueChanged, allItems);

      if (item.field.id === 'referredto' && item.data.DtoName === LeadTypeIdEnum.Seeker.name)
      UserRule(item, null, onItemChanged, allItems, allItemsValues, 1, 2, onValueChanged);

    // if(item.field.id === 'send_to_rotation')
    //  RotationLeadDefaultRule(item, allItems ,allItemsValues , onValueChanged ) ;

     if (item.field.id === 'inquiry_unit_model')
       UnitModelDefaultRule(item, onItemChanged, allItems, allItemsValues);

   if (item.field.FieldType === 'alt-date' && item.data.valueToEdit === '') {
      setTimeout(() => {
        EmptyDateFillDefaultRuleV2(item, onValueChanged);
      }, 500);
    }

    if (item.field.id === 'construction_date') {
    if (allItemsValues && allItemsValues.construction_date)
      ConstructionDateDefault(id, allItems, allItemsValues.construction_date, onValueChanged, allItemsValues);
    }

    if (item.field.id === 'customer_profile' && !(item.data.isReadonly))
      MortgageDetailsRules2(item, allItemsValues.customer_profile, onValueChanged, allItems, allItemsValues);

    if (item.field.id === 'property_name' && item.data.DtoName === 'property_seeker_lead' && (allItemsValues.leadClass === LeadsClassTypesEnum.tenant.name || allItemsValues.leadClass === LeadsClassTypesEnum.buyer.name)) {
      setTimeout(() => {
        SekeerLeadRule(allItems, allItemsValues);
      }, 500);
    }
    setTimeout(() => {
      if (item.field.id === 'status') {
        getStatusValue();
        getStatusValue2();
        getIsUpdateCloseResonAndRemarks();
      }
    }, 700);
  }, []);

  useEffect(() => {
    if (item.field.id === 'listing_date') {
      if (allItemsValues && allItemsValues.listing_date) {
        setTimeout(() => {
          ListingDate(id, allItems, allItemsValues.listing_date);
        }, 500);
      }
    }
    if (item.field.id === 'rent_listing_date') {
      if (allItemsValues && allItemsValues.rent_listing_date) {
        setTimeout(() => {
          ListingDate(id, allItems, allItemsValues.rent_listing_date);
        }, 500);
      }
    }
  }, [item.field.id]);

  useEffect(() => {
    const sellingPriceAgencyFeeIndex = allItems.indexOf(allItems.find((f) => f.field.id === 'selling_price_agency_fee'));
    if ((id === 'seller_has_paid' || id === 'selling_price_agency_fee') && (sellingPriceAgencyFeeIndex !== -1 && !(allItems[sellingPriceAgencyFeeIndex].data.isReadonly))) {
      const sellerhaspaid = allItemsValues && allItemsValues.seller_has_paid ? allItemsValues.seller_has_paid : 0;
      const sellingpriceagencyfee = allItemsValues && allItemsValues.selling_price_agency_fee ? allItemsValues.selling_price_agency_fee.salePrice : 0;
      const sub = sellingpriceagencyfee - sellerhaspaid;

      setTimeout(() => {
        onValueChanged(sub, 0, 'amount_due_to_developer');
      }, 300);
    } else if (id === 'email_address' && allItemsValues && allItemsValues.email_address) {
      if (allItemsValues.email_address.email === '')
        onValueChanged(null, 0, 'email_address');
    }

    if (!isSubmitted && item.data.addressType) {
      setTimeout(() => {
         if (item && (item.data.enum.length === 0) && item.data.uiType === 'select' && !(item.data.isFillData))
        LookupRule(item, allItems);
      }, 3800);
    } else if (isSubmitted && item.data.uiType === 'select' && error)
      LookupRule(item, allItems);

    const saleTypeIndex = allItems.indexOf(
      allItems.find(
        (f) => f.field.id === 'sale_type'
      )
);

    if (allItemsValues && allItemsValues.operation_type && (allItemsValues.operation_type.lookupItemName === 'Sale' || allItemsValues.operation_type.lookupItemName === 'SaleAndRent') && item.field.id === 'sale_type') {
      if (saleTypeIndex !== -1) {
        allItems[saleTypeIndex].field.isRequired = true;
        allItems[saleTypeIndex].data.title = 'Sale Type *';
      }
    } else if (allItemsValues && allItemsValues.operation_type && (allItemsValues.operation_type.lookupItemName === 'Rent') && item.field.id === 'sale_type') {
      if (saleTypeIndex !== -1) {
        allItems[saleTypeIndex].field.isRequired = false;
        allItems[saleTypeIndex].data.title = 'Sale Type';
      }
    }
  }, [itemValue]);

  return (
    <>
      {item.field.FieldType === 'UnitsModels' && (
        <div className='form-item'>
          <div className='d-flex-column w-100'>
            <label htmlFor={item.field.id} className='label-wrapper'>
              {label}
            </label>
            <div className='chip-input-wrapper'>
              <ChipInput
                className='chip-input theme-form-builder'
                InputProps={{ autoComplete: 'new-password' }}
                id={item.field.id}
                value={itemValue && itemValue.Models ? itemValue.Models : []}
                onAdd={(chip) => {
                  if (!isNaN(chip))
                    return;

                  if (itemValue && itemValue.Models && itemValue.Models.length > 0) {
                    Object.keys(itemValue).forEach((key) => {
                      if (typeof itemValue[key] === 'object' && key !== 'Models') {
                        const isExist = itemValue.Models.findIndex((modelItem) => modelItem === key);
                        if (isExist === -1)
                          delete itemValue[key];
                      }
                    });
                  } else if (itemValue && itemValue.Models && itemValue.Models.length === 0) {
                    Object.keys(itemValue).forEach((key) => {
                      if (typeof itemValue[key] === 'object' && key !== 'Models')
                        delete itemValue[key];
                    });
                  }
                  if (itemValue && itemValue.Models) {
                    onValueChanged({
                      ...itemValue,
                      Models: [...itemValue.Models, chip],
                    });
                  } else onValueChanged({ Models: [chip] });
                }}
                onDelete={(chip, itemIndex) => {
                  if (itemValue && itemValue.Models)
                    itemValue.Models.splice(itemIndex, 1);
                  delete itemValue[chip];
                  if (itemValue && itemValue.Models && itemValue.Models.length > 0) {
                    Object.keys(itemValue).forEach((key) => {
                      if (typeof itemValue[key] === 'object' && key !== 'Models') {
                        const isExist = itemValue.Models.findIndex((modelItem) => modelItem === key);
                        if (isExist === -1)
                          delete itemValue[key];
                      }
                    });
                  } else if (itemValue && itemValue.Models && itemValue.Models.length === 0) {
                    Object.keys(itemValue).forEach((key) => {
                      if (typeof itemValue[key] === 'object' && key !== 'Models')
                        delete itemValue[key];
                    });
                    onValueChanged(null, undefined, 'units_models');
                    return;
                  }
                  onValueChanged({ ...itemValue });
                }}
              // variant="standard"
              />
              <Button
                className='ml-2-reversed btns-icon theme-solid bg-blue-lighter'
                disabled={!(itemValue && itemValue.Models && itemValue.Models.length > 0)}
                onClick={() => {
                  if (itemValue && itemValue.Models && itemValue.Models.length > 0)
                    setModelsUnitsDialog(true);
                }}
              >
                <span className='mdi mdi-plus' />
              </Button>
            </div>
          </div>
          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information-open-file'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )
          }
        </div>
      )}
      {((item.field.FieldType === 'address' &&
        (item.data.uiType === 'text' || item.data.uiType === 'map')) ||
        item.field.FieldType === 'MapField') && (
          <div className='form-item'>
            <Inputs
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              idRef={item.field.id}
              isRequired={item.field.isRequired}
              isDisabled={
                isLoading || item.data.uiType === 'map' ||
                item.field.FieldType === 'MapField' ||
                item.data.specialKey === 'IsDisabled' ||
                item.data.specialKey === 'UnitRefNo' ||
                item.data.isReadonly
              }
              labelValue={label}
              value={
                (itemValue &&
                  ((item.data.uiType === 'text' && itemValue.value) ||
                    ((item.data.uiType === 'map' || item.field.FieldType === 'MapField') &&
                      `${itemValue.latitude} , ${itemValue.longitude}`) ||
                    '')) ||
                ''
              }
              isSubmitted={isSubmitted}
              isWithError
              helperText={helperText}
              error={error}
              onInputChanged={
                (item.data.uiType === 'text' &&
                  ((e) => {
                    if (item.data.isReadonly)
                      return;

                    onValueChanged({
                      value: e.target.value,
                      type: item.data.addressType,
                    });
                  })) ||
                undefined

              }
              buttonOptions={
                ((item.data.uiType === 'map' || item.field.FieldType === 'MapField') && {
                  className: 'btns-icon theme-outline c-blue-lighter',
                  iconClasses: 'mdi mdi-map-marker',
                  isDisabled: (item.data.isReadonly) || false,
                  isRequired: false,
                  onActionClicked: () => {
                    setOpenMapDialog(true);
                  },
                }) ||
                undefined
              }
            />
            {
              item.data.HelperInfo && item.data.HelperInfo !== '' && (
                <div className='information-open-file'>
                  <Tooltip
                    size='larg'
                    title={item.data.HelperInfo}
                  >
                    <span className='mdi mdi-information-outline' />
                  </Tooltip>
                </div>
              )
            }
          </div>
        )}
      {((item.field.FieldType === 'textField' && (item.data.specialKey !== 'currency' && item.data.specialKey !== 'size' && item.data.specialKey !== 'decimal')) || item.field.FieldType === 'textarea') && (
        <div className='form-item'>
          <div className='w-100 p-relative'>
            {item.field.id !== 'unit_model' && item.field.id !== 'closing_remarks' && (
              <Inputs
                labelClasses={item.field.isRequired ? 'c-red' : ''}
                idRef={item.field.id}
                isRequired={item.field.isRequired}
                isDisabled={
                  isLoading ||
                  getIsUpdateCloseResonAndRemarks() ||
                  customerProfileSelected() ||
                  item.data.isReadonly ||
                  item.data.specialKey === 'IsDisabled' ||
                  item.data.specialKey === 'UnitRefNo' ||
                  notUpdateInsideForm()
                }
                labelValue={label}
                value={itemValue || ''}
                isWithError
                multiline={item.field.FieldType === 'textarea'}
                isSubmitted={isSubmitted}
                helperText={helperText}
                onInputFocus={
                  (item.field.id === 'unit_model' && openUnitModelPopoverHandler) || undefined
                }
                onInputBlur={(item.field.id === 'unit_model' && onPopoverClose) || undefined}
                error={error}
                rows={(item.field.FieldType === 'textarea' && 4) || undefined}
                onInputChanged={(e) => {
                  if (item.data.isReadonly)
                    return;

                  onValueChanged(e.target.value);
                }}
              />

            )}

            {(item.field.id !== 'unit_model' && item.field.id === 'closing_remarks' && allItemsValues.status && allItemsValues.status.lookupItemName === 'Closed') && (
              <Inputs
                labelClasses={item.field.isRequired ? 'c-red' : ''}
                idRef={item.field.id}
                isRequired={item.field.isRequired}
                isDisabled={
                  isLoading ||
                  getIsUpdateCloseResonAndRemarks() ||
                  item.data.isReadonly ||
                  item.data.specialKey === 'IsDisabled' ||
                  item.data.specialKey === 'UnitRefNo'
                }
                labelValue={label}
                value={itemValue || ''}
                isWithError
                multiline={item.field.FieldType === 'textarea'}
                isSubmitted={isSubmitted}
                helperText={helperText}
                onInputFocus={
                  (item.field.id === 'unit_model' && openUnitModelPopoverHandler) || undefined
                }
                onInputBlur={(item.field.id === 'unit_model' && onPopoverClose) || undefined}
                error={error}
                rows={(item.field.FieldType === 'textarea' && 4) || undefined}
                onInputChanged={(e) => {
                  if (item.data.isReadonly)
                     return;

                  onValueChanged(e.target.value);
                }}
              />

            )}

            {allItemsValues && allItemsValues.property_name && item.field.id === 'unit_model' && (
              <UnitModelPopoverComponent
                unitModelPopoverAttachedWith={unitModelPopoverAttachedWith}
                onPopoverClose={onPopoverClose}
                item={item}
                itemValue={itemValue}
                allItems={allItems}
                propertyId={allItemsValues.property_name.id}
                onValueChanged={onValueChanged}
                labelValue={label}
                idRef={id}
                allItemsValues={allItemsValues}
                propertyName={allItemsValues && allItemsValues.property_name}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            )}
          </div>
          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information-open-file-2'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )
          }
        </div>
      )}

      { (displyTextField()) && (
        <div className='form-item'>
          <div className='w-100 p-relative'>
            <Inputs
              isAttachedInput
              key={item.field.id}
              type={item.data.isReadonly ? 'text' : 'number'}

              endAdornment={(item.data.specialKey === 'currency' && (
                <InputAdornment position='start' className='px-2'>
                  AED
                </InputAdornment>
              ))}
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              idRef={item.field.id}
              isRequired={item.field.isRequired}
              isDisabled={
                item.data.isReadonly || (id === 'amount_due_to_developer' || id === 'pricesqm') ||
                financialStatusDetailsRules() ||
                customerProfileSelected() ||
                (
                  isLoading

                )
              }
              labelValue={label}
              value={itemValue || ''}
              isWithError
              isSubmitted={isSubmitted}
              helperText={helperText}
              error={error}
              withNumberFormat={!(item.data.isReadonly)}
              onKeyUp={(e) => {
                if (item.data.isReadonly)
                   return;
                if (id === 'pricesqm') {
                  const { value } = e.target;
                  const fixed = (value && value.replace(/,/g, ''));
                  const editValue = fixed ? parseFloat(fixed) : 0;
                  if (editValue)
                    onValueChanged(parseFloat(editValue));
                  else
                    onValueChanged(editValue);
                }
              }}
              onInputChanged={(e) => {
                if (item.data.isReadonly)
                   return;
                if (id !== 'pricesqm') {
                  if (id === 'builtup_area_sqft') {
                    const sellingPrice = (allItemsValues && allItemsValues.selling_price_agency_fee);
                    BuiltupAreaRule2(id, e.target.value, ((sellingPrice && sellingPrice.salePrice) || 0), onValueChanged, 2, item);
                  }
                  LiabilityAmount2(item, e.target.value, allItems, allItemsValues, onValueChanged);
                  if (!Number.isNaN(e.target.value))
                    onValueChanged(parseFloat(e.target.value));
                  else
                    onValueChanged(0);
                }
              }}

            />
          </div>
          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information-open-file-2'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )
          }
        </div>
      )}

      {item.field.FieldType === 'communication' &&
        (item.data.CommunicationType === 'Email' ||
          item.data.CommunicationType === 'SocialMedia') && (
          <div className='form-item'>
            <Inputs
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              idRef={item.field.id}
              isRequired={item.field.isRequired}
              isDisabled={
                isLoading ||
                item.data.isReadonly ||
                item.data.specialKey === 'IsDisabled' ||
                item.data.specialKey === 'UnitRefNo'
              }
              labelValue={label}
              value={
                (itemValue &&
                  ((item.data.CommunicationType === 'Email' && itemValue.email) ||
                    itemValue.link ||
                    '')) ||
                ''
              }
              isWithError
              isSubmitted={isSubmitted}
              helperText={helperText}
              error={error}
              onInputChanged={(e) => {
                if (item.data.isReadonly)
                  return;
                if (item.data.CommunicationType === 'Email') {
                  if (e.target.value === '') {
                    onValueChanged(null);
                    return;
                  }
                  onValueChanged({
                    email: e.target.value.toLowerCase(),
                    typeId: item.data.lookupItem,
                  });
                } else {
                  onValueChanged({
                    link: e.target.value,
                    typeId: item.data.lookupItem,
                  });
                }
              }}
              buttonOptions={
                (item.data.CommunicationType === 'Email' && {
                  className: 'btns-icon theme-solid bg-blue-lighter',
                  iconClasses: 'mdi mdi-plus',
                  isDisabled: (item.data.isReadonly) || !(
                    itemValue &&
                    new RegExp(item.data.regExp).test(itemValue.email) &&
                    itemValue.email &&
                    itemValue.email.length > 0
                  ),
                  isRequired: false,
                  onActionClicked: () => {
                    if (
                      itemValue &&
                      new RegExp(item.data.regExp).test(itemValue.email) &&
                      itemValue.email &&
                      itemValue.email.length > 0
                    )
                      setRepeatedDialog(true);
                  },
                }) ||
                undefined
              }
            />
            {
              item.data.HelperInfo && item.data.HelperInfo !== '' && (
                <div className='information-open-file-2'>
                  <Tooltip
                    size='larg'
                    title={item.data.HelperInfo}
                  >
                    <span className='mdi mdi-information-outline' />
                  </Tooltip>
                </div>
              )
            }
          </div>
        )}
      {item.field.FieldType === 'rangeField' && (
        <div className='form-item flex-wrap px-0'>
          <div className='w-50 px-2'>
            <Inputs
              idRef={item.field.id}
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              labelValue={label}
              inputPlaceholder={t('from')}
              value={itemValue ? itemValue[0] : Number(item.data.minNumber)}
              isRequired={item.field.isRequired}
              helperText={helperText}
              withNumberFormat
              error={error}
              isWithError
              isSubmitted={isSubmitted}
              onInputChanged={(e) => {
                if (item.data.isReadonly)
                  return;
                if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                  const v = itemValue || [];
                  const withoutCommaInNumber = parseFloat(e.target.value && e.target.value.replace(/,/g, ''));

                  if (v && v[0] && withoutCommaInNumber && v && v.length === 2 && v[1] && withoutCommaInNumber > Number(v[1]))
                    v[0] = itemValue && itemValue[0];

                  else
                  v[0] = parseFloat(e.target.value);

                  onValueChanged(v);
                }
              }}
              onInputBlur={(e) => {
                if (item.data.isReadonly)
                  return;
                  const sizeValue = e.target.value;
                  const withoutCommaInNumber = parseFloat(sizeValue && sizeValue.replace(/,/g, ''));

                  const v = itemValue || [];
                  if (withoutCommaInNumber && v && v.length === 2 && v[1] && withoutCommaInNumber > Number(v[1]))
                    return;

                if (withoutCommaInNumber < Number(item.data.minNumber)) {
                  v[0] = Number(item.data.minNumber);
                  onValueChanged(v);
                } else if (Number(withoutCommaInNumber) > Number(item.data.maxNumber)) {
                  v[0] = Number(item.data.maxNumber);
                  onValueChanged(v);
                } else {
                  v[0] = withoutCommaInNumber;
                  onValueChanged(v);
                }
                  if (item.field.id === 'size_sqft')
                      LeadConvertSizeSqftToSqmRule2(item, v[0], allItemsValues, onValueChanged, 'from', allItems);

                    else if (item.field.id === 'size_sqm')
                      LeadConvertSizeSqmToSqftRule2(item, v[0], allItemsValues, onValueChanged, 'from', allItems);
              }}
            />
          </div>
          <div className='w-50 px-2'>
            <Inputs
              idRef={`${item.field.id}to`}
              labelValue={t('to')}
              value={itemValue ? itemValue[1] : Number(item.data.maxNumber)}
              isRequired={item.field.isRequired}
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              helperText={helperText}
              error={error}
              isWithError
              withNumberFormat
              isSubmitted={isSubmitted}
              onInputChanged={(e) => {
                if (item.data.isReadonly)
                  return;
                if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                  const v = itemValue || [];
                  const withoutCommaInNumber = parseFloat(e.target.value && e.target.value.replace(/,/g, ''));

                  v[1] = parseFloat(withoutCommaInNumber);

                  onValueChanged(v);
                }
              }}
              onInputBlur={(e) => {
                if (item.data.isReadonly)
                 return;
                 const sizeValue = e.target.value;
                 const v = itemValue || [];
                 const withoutCommaInNumber = parseFloat(sizeValue && sizeValue.replace(/,/g, ''));

                const marks = [
                  {
                    value: parseFloat(item.data.minNumber),
                    label: `${(item.data.minNumber)}`,
                  },
                  {
                    value: parseFloat(item.data.maxNumber),
                    label: `${(item.data.maxNumber)}`,
                  },
                ];

                  if (withoutCommaInNumber < Number(item.data.minNumber)) {
                  v[1] = Number(marks[1].value);

                  onValueChanged(v);
                } else if (withoutCommaInNumber > Number(item.data.maxNumber)) {
                  v[1] = Number(item.data.maxNumber);
                  onValueChanged(v);
                } else {
                  v[1] = withoutCommaInNumber;
                  onValueChanged(v);
                }

                if (item.field.id === 'size_sqft')
                LeadConvertSizeSqftToSqmRule2(item, v[1], allItemsValues, onValueChanged, 'to', allItems);

              else if (item.field.id === 'size_sqm')
                LeadConvertSizeSqmToSqftRule2(item, v[1], allItemsValues, onValueChanged, 'to', allItems);
              }}
            />
          </div>

          <div className='helper_rangField px-4'>
            <Slider
              required={item.field.isRequired}
              className='sliders'
              aria-labelledby='range-slider'
              valueLabelDisplay='auto'
              onChange={(event, value) => {
                if (item.data.isReadonly)
                  return;
                  if (item.field.id === 'size_sqft')
                    LeadConvertSizeSqftToSqmRuleSlider(value, onValueChanged);

                  else if (item.field.id === 'size_sqm')
                   LeadConvertSizeSqmToSqftRuleSlider(value, onValueChanged);

                if (!itemValue || value[0] !== itemValue[0] || value[1] !== itemValue[1])
                  onValueChanged(value);
              }}
              value={
                itemValue ?
                  [(itemValue[0] && parseFloat(itemValue[0].toFixed(2))),
                  (itemValue[1] && parseFloat(itemValue[1].toFixed(2)))] :
                  [(item.data.minNumber && (item.data.minNumber)),
                  (item.data.maxNumber && (item.data.maxNumber))]
              }
              step={item.field.id === 'size_sqft' ? 200 : 20}
              marks={[
                {
                  value: parseFloat(item.data.minNumber),
                  label: `${parseFloat(item.data.minNumber)}`,
                },
                {
                  value: (item.data.maxNumber),
                  label: `${parseFloat(item.data.maxNumber)}`,
                },
              ]}
              min={Number(item.data.minNumber)}
              max={Number(item.data.maxNumber)}

            />
            {
              item.data.HelperInfo && item.data.HelperInfo !== '' && (
                // <div className='information-open-file-2'>
              <Tooltip
                size='larg'
                title={item.data.HelperInfo}
              >
                <span className='mdi mdi-information-outline InformationIcon' />
              </Tooltip>
                // </div>
              )
            }

          </div>
        </div>
      )}
      {(item.data.CommunicationType === 'Phone' || item.field.FieldType === 'phone') && (
        <div className='form-item'>
          <PhonesComponent
            labelClasses={item.field.isRequired ? 'c-red' : ''}
            idRef={item.field.id}
            labelValue={label}
            required={item.field.isRequired}
            value={
              itemValue === null || itemValue === undefined || !itemValue.phone ?
                item.data.defaultCountryCode :
                (itemValue.phone)
            }
            onInputChanged={(value) => {
              if (item.data.isReadonly)
                return;

              if (value.length > 14) return;

              onValueChanged(value !== ''? { phone: value, typeId: item.data.lookupItem } : null);
            }}
            isDisabled={(item.data.isReadonly || isLoading)}
            isSubmitted={isSubmitted}
            error={error}
            helperText={helperText}
            buttonOptions={{
              className: 'btns-icon theme-solid bg-blue-lighter',
              iconClasses: 'mdi mdi-plus',
              isDisabled: (item.data.isReadonly) || !(itemValue && itemValue.phone && itemValue.phone.length >= 9),
              isRequired: false,
              onActionClicked: () => {
                if (itemValue && itemValue.phone && itemValue.phone.length >= 9)
                  setRepeatedDialog(true);
              },
            }}
          />
          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information-open-file-2'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )
          }
        </div>
      )}
      {item.field.FieldType === 'radio' && (
        <div className='form-item'>
          <RadiosGroupComponent
            data={item.data.enum}
            idRef={item.field.id}
            onSelectedRadioChanged={(e) => onValueChanged(e.target.value)}
            value={itemValue}
          // name='Active'
          // labelInput='item.data.title'
          // valueInput='value'
          />
          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information-open-file-2'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )
          }
        </div>
      )}
      {(item.field.FieldType === 'checkbox' || item.field.FieldType === 'checkboxes') && (
        <div className='form-item'>
          <CheckboxesComponent
            data={
              (item.field.FieldType === 'checkbox' && [itemValue]) || item.data.items.enum || []
            }
            onSelectedCheckboxChanged={(e) => {
              if (item.field.FieldType === 'checkboxes') {
                const result = (itemValue && [...itemValue]) || [];
                if (e.target.checked && result.indexOf(e.target.value) === -1)
                  result.push(e.target.value);
                else result.splice(result.indexOf(e.target.value), 1);
                onValueChanged(result);
              } else onValueChanged(e.target.checked);
            }}
            isRow
            idRef={item.field.id}
            labelClasses={item.field.isRequired ? 'c-red' : ''}
            labelValue={label}
            required={item.field.isRequired}
            checked={(selectedItem) => itemValue && [...itemValue].indexOf(selectedItem) !== -1}
            value={(item.field.FieldType === 'checkbox' && itemValue) || undefined}
          />
          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information-open-file-2'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )
          }
        </div>
      )}
      {item.field.FieldType === 'alt-date' && (
        <div className='form-item'>
          <DatePickerComponent
            required={item.field.isRequired}
            idRef={item.field.id}
            labelValue={label}
            isDisabled={customerProfileSelected() || item.data.isReadonly || isLoading}
            labelClasses={item.field.isRequired ? 'c-red' : ''}
            value={(itemValue && moment(itemValue).isValid() && moment(itemValue)) || null}
            helperText={helperText}
            error={error}
            isSubmitted={isSubmitted}
            maxDate={
              item.data.maxDate && moment(new Date(item.data.maxDate)).isValid() ?
                moment(new Date(item.data.maxDate)) : (
                (item.field.id === 'listing_date' || item.field.id === 'rent_listing_date' ? ListingAndRentListingDateRule(item, allItems) : undefined))
            }
            minDate={
              item.data.minDate && moment(new Date(item.data.minDate)).isValid() ?
                moment(new Date(item.data.minDate)) :
                undefined
            }
            onDateChanged={(e) => {
              if (item.data.isReadonly)
                return;
              VisaRules(e, item, onValueChanged, allItems);
              PassportRule2(id, item, allItems, e, onValueChanged);
              ListingDate(id, allItems, e);
              ListingExpiryDateRule2(item, onValueChanged);
              ConstructionDateRule(id, item, allItems, e, onValueChanged, 2);
              onValueChanged(e);
            }}
          />
          {
            item.data.HelperInfo && item.data.HelperInfo !== '' && (
              <div className='information-open-file-2'>
                <Tooltip
                  size='larg'
                  title={item.data.HelperInfo}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )
          }
        </div>
      )}
      {displySearchFields() && (
        (item.data.uiType === 'select'

        ) ||
        (item.field.FieldType === 'UploadFiles') ||
        (item.field.FieldType === 'searchField' && item.data.searchKey === 'User' &&
          UserDefaultRule(item, onItemChanged, allItems, allItemsValues, GetParams('activeFormType'))) ||
        (item.data.searchKey === 'Leads' && LeadsDefaultRule(item, onItemChanged, typeOf)) ||
        (item.field.FieldType === 'searchField' && item.field.id !== 'owner') ||
        (item.field.FieldType === 'select' && item.field.id !== 'close_reason' && !(item.field.id === 'sale_type' && item.data.specialKey === 'sale_type_lead_owner'))
      ) && (
      <div className='form-item'>

        <AutocompleteComponent
          labelClasses={item.field.isRequired ? 'c-red' : ''}
          idRef={item.field.id}
          isRequired={item.field.isRequired}
          isDisabled={
                getStatusValue() ||
                disableQualification() || 
                getLeadOperationValue() ||
                disableUnitRefNumber() ||
                disableIsMortgageInTenant() ||
                customerProfileSelected() ||
                disableLead() ||
                isLoading ||
                item.data.isReadonly ||
                item.data.specialKey === 'IsDisabled' ||
                item.data.specialKey === 'UnitRefNo' ||
               // AssignToInquiresHelper(item.field.id) ||
                getSendLeadToRotationSelected() ||
                getIsLeadAuditor()

              }
          isLoading={isLoading}
          withLoader
          isWithError
          isSubmitted={isSubmitted}
          helperText={helperText}
          error={error}
          multiple={(item.data.multi && item.data.multi === 'true') || false}
          filterOptions={
                ((item.data.searchKey === 'property' ||
                  item.data.searchKey === 'contact' ||
                  item.data.searchKey === 'Leads' ||
                  item.data.searchKey === 'PropertyOrUnit') &&
                  ((option) => option)) ||
                undefined
              }
          renderOption={
                (item.data.searchKey === 'property' &&
                ((option) => <span>{`${option.name || ''} - ${option.city || ''}  ${option && option.property_plan !== undefined && (`      (      ${((option && option.property_plan) || '')}      ) ` || '') || ''}  ` || ''}</span>)) ||
                (item.data.searchKey === 'User' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        { (option && option.name) || (option && option.fullName)}
                      </div>
                      <span className='c-gray-secondary'>{ option.branch || ''}</span>
                    </div>
                  ))) ||
                (item.data.searchKey === 'contact' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                      <span className='c-gray-secondary'>{option.phone}</span>
                    </div>
                  ))) ||
                (item.data.searchKey === 'Leads' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                      <span className='c-gray-secondary'>{option.leadClass}</span>
                    </div>
                  ))) ||
                (item.data.searchKey === 'campaign_name' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                    </div>
                  ))) ||
                (item.data.searchKey === 'PropertyOrUnit' &&
                  ((option) =>
                    (option.isProperty && (
                      <span>{`${option.name || ''} - ${option.city || ''}`}</span>
                    )) || (
                      <div className='d-flex-column'>
                        <span>{`${option.unitModel} - ${option.name} - ${option.unitType}`}</span>
                        <span className='c-gray-secondary'>
                          {`${option.unitBedrooms} - ${option.unitRefNo}`}
                        </span>
                      </div>
                    ))) ||
                undefined
              }
          selectedValues={
                item.data.multi && item.data.multi === 'true' ?
                  (itemValue &&
                    ((item.field.FieldType === 'UploadFiles' && itemValue && itemValue.selected && itemValue.selected.length > 0  &&  [...itemValue.selected]) ||
                      (!Array.isArray(itemValue) && [itemValue]) || [...itemValue])) ||
                  [] :
                  itemValue || null
              }
          data={getDataEnumList(item)}
          chipsLabel={
                (item.data && item.data.multi &&
                  item.data.multi === 'true' &&
                  ((item.field.FieldType === 'searchField' &&
                    ((option) => (option && option.name) || '')) ||
                    ((option) => (!item.data.lookup ? option : option && option.lookupItemName || undefined)))) ||
                undefined
              }
          onInputChange={
                (item.field.FieldType === 'searchField' &&
                  ((e, v) => {
                    ContactRule(item, v, onItemChanged);
                    PropertyRule(item, v, onItemChanged);
                    UserRule(item, v, onItemChanged, allItems, allItemsValues, GetParams('activeFormType'), 2, onvolumechange);
                    LeadsRule(item, v, onItemChanged, typeOf);
                    PropertyOrUnitRule(item, v, onItemChanged, allItems, allItemsValues);
                    UnitRule(item, v, onItemChanged, allItems, allItemsValues);
                    CampaignsRule(item, v, onItemChanged);
                  })) ||
                undefined
              }
          inputClasses='inputs theme-form-builder'
          displayLabel={
                (item.field.FieldType === 'searchField' &&
                  ((option) => (option && `${option.name} ${option.barnch || ''}`) || '')) ||
                ((option) => (!item.data.lookup ? option : option.lookupItemName) || '')
              }
          getOptionSelected={
                (item.field.FieldType === 'searchField' &&
                  ((option) =>
                    (item.data.multi &&
                      item.data.multi === 'true' &&
                      itemValue &&
                      ((!Array.isArray(itemValue) && [itemValue]) || itemValue).findIndex(
                        (element) => (element && element.id && element.id === option.id) || option === element
                      ) !== -1) ||
                    (itemValue.id && itemValue.id === option.id) ||
                    option === itemValue)) ||
                ((option) =>
                  (item.data.multi &&
                    item.data.multi === 'true' &&
                    (
                      (itemValue &&
                        ((item.field.FieldType === 'UploadFiles' && itemValue && itemValue.selected && itemValue.selected.length &&  itemValue.selected ) ||
                          (!Array.isArray(itemValue) && [itemValue]) ||
                          itemValue)) ||
                      []
                    ).findIndex(
                      (element) =>
                        (element && element.lookupItemId && element.lookupItemId === option.lookupItemId) ||
                        option === element
                    ) !== -1) ||
                  (((item.field.FieldType === 'UploadFiles' && itemValue  && itemValue.selected) || itemValue)
                    .lookupItemId &&
                    ((item.field.FieldType === 'UploadFiles' && itemValue  && itemValue.selected) || itemValue)
                      .lookupItemId === option.lookupItemId) ||
                  option === itemValue)
              }
          onChange={(e, v) => {
                if (item.data.isReadonly)
                 return;
                if (id === 'contact_preference') {
                  ContactPreferenceRule(item, v, onValueChanged, 2);
                  return;
                }
                if (item.field.id === 'property_plan' && allItemsValues && allItemsValues.property_plan && allItemsValues.property_plan.lookupItemName === 'Completed')
                 PropertyPlanRule(item, v, onValueChanged, 2);

                if (id === 'is_mortgage' && item && item.data && item.data.specialKey === 'is_mortgage_seeker_lead')
                  IsMortgageReletedModeOfPaymentRule(item, v, onValueChanged, 2, allItems);

                if (id === 'mode_of_payment' && item && item.data && item.data.DtoName === 'mode_of_payment_seeker_lead')
                  ModeOfPaymentRule(item, v, onValueChanged, 2);

                UnitRentedRule(item, v, onValueChanged, 2, allItemsValues);
                FinancialStatusDetails2(item, v, allItems, allItemsValues, onValueChanged);
                MortgageDetailsRules2(item, v, onValueChanged, allItems, allItemsValues);
                if (item.field.id === 'bedrooms' || item.field.id === 'bathrooms') {
                  const findAnyBathroomsAndBedrooms = v.find((num) => num === 'Any' || num === 'any');
                  if (findAnyBathroomsAndBedrooms) {
                    onValueChanged([findAnyBathroomsAndBedrooms]);
                    return;
                  }
                }
                if (item.field.id === 'listing_expiry_period' || item.field.id === 'rent_listing_expiry_period')
                  ListingDateRule2(item, v, onValueChanged, allItems, allItemsValues);
                if (item.field.id === 'status') {
                  if ((v && v.lookupItemName === 'Open') || !v)
                    StatusLeadDefaulRule2(item, onValueChanged, allItems, allItemsValues);
                }

                if (item.data.specialKey === 'sendLeadToRotation')
                  RotationLeadRule(item, v, onItemChanged, allItems, allItemsValues, onValueChanged, 2);

                if (id === 'unit_ref_number' && item && item.data && (item.data.DtoName === 'unit_ref_number_sekeer_lead' || item.data.DtoName === 'unit_ref_number_owner_lead' ))
                  UnitRefNumberRule(item, v, allItems, onValueChanged, 2, allItemsValues);

                if (id === 'operation_type' && item && item.data && item.data.DtoName === 'operation_type_seeker_lead');
                OnChangeOperationTypeForSeekerLead(item, v, allItems, onValueChanged, onItemChanged, allItemsValues, 2);
                if (id === 'property_name' && item && item.data && (item.data.DtoName === 'property_seeker_lead' ||  item.data.DtoName === 'property_owner_lead' ) )
                  OnChangePropertyOnLeadRule2(item, v, allItems, onValueChanged, onItemChanged, allItemsValues);

                  if (item.field.id === 'unit_ref_number' && item.data.DtoName === 'unit_ref_number_owner_lead')
                    UnitRefNumberLeadOwnerRule(item, allItems, v, allItemsValues, onValueChanged, 2);

                if (item.field.id === 'property' && item.data.searchableKey === 'property_inquiries')
                  onChangePropertyRule(item, v, onItemChanged, allItems, 2, onValueChanged);

                if (item.field.id === 'inquiry_unit_model')
                  UnitModelRule(item, v, onItemChanged, allItems, onValueChanged, 2, allItemsValues);

                if (item.field.id === 'shortage_in')
                ShortageInRule(allItems, onValueChanged, 2);

                if (item.field.FieldType === 'UploadFiles') {
                  console.log('value is selected' , v );
                  onValueChanged(v && v.length ? (itemValue ? { ...itemValue, selected: v }:{selected :v}):null);
                  return;
                }
                if (item.field.id === 'lead_owner') {
                  if (v === null) {
                    setLeadOwnerValue(itemValue);
                    setIsOpenLeadOwnerDialog(true);
                  }
                } else if (item.field.id === 'lease_lead_owner') {
                  if (v === null) {
                    setLeaseLeadOwnerValue(itemValue);
                    setisOpenLeaseLeadOwnerDialog(true);
                  }
                }

                TitleRuleV2(id, onValueChanged, item, allItems, v);
                OnchangePropertyInUnitRuleV2(item, v, onValueChanged);
                LookupsEffectedOnRules(
                  v,
                  item,
                  onValueChanged,
                  onItemChanged,
                  allItems,
                  allItemsValues,
                  onLoadingsChanged
                );

                if (v) {
                  OnchangeCountryRule2(id, onValueChanged, item, allItems, v);
                  OnchangeCityRule2(id, v, allItems, onValueChanged, item);
                  OnchangeDistrictRule2(id, onValueChanged, item, allItems, v);
                  OnchangeCommunityRule2(id, onValueChanged, item, allItems, v);
                  OnchangeSubCommunityRule2(id, onValueChanged, item, allItems, v);
                }
                onValueChanged(v);
              }}
          withoutSearchButton
          buttonOptions={
                (item.field.FieldType === 'UploadFiles' && {
                  className: 'btns-icon theme-solid bg-blue-lighter',
                  iconClasses:
                    item.field.id === 'external_url' ? 'mdi mdi-plus' : 'mdi mdi-attachment',
                  isDisabled: (item.data.isReadonly) || !(itemValue && itemValue.selected && itemValue.selected.length > 0),
                  isRequired: false,
                  onActionClicked: () => {
                    if (
                      itemValue &&
                      itemValue.selected &&
                      itemValue.selected.length > 0 &&
                      item.field.id !== 'external_url'
                    )
                      setOpenUploadDialog(true);
                    if (
                      itemValue &&
                      itemValue.selected &&
                      itemValue.selected.length > 0 &&
                      item.field.id === 'external_url'
                    )
                      setRepeatedLinkDialog(true);
                  },
                }) ||
                (item.field.FieldType === 'searchField' &&
                  item.field.id !== 'referredto' &&
                  item.field.id !== 'referredby' &&
                  item.field.id !== 'listing_agent' &&
                  item.field.id !== 'assign_to' &&
                  item.field.id !== 'lead' &&
                  item.field.id !== 'rent_listing_agent' &&
                  item.field.id !== 'inquiry_unit_model' &&
                  item.field.id !== 'campaign_name' &&
                  item.field.id !== 'lead_auditor' &&
                {
                  className: 'btns-icon theme-outline c-blue-lighter',
                  iconClasses: itemValue ? 'mdi mdi-eye-outline' : 'mdi mdi-plus',
                  isDisabled:
                    (item.data.multi && item.data.multi === 'true') ||
                    item.field.id === 'listing_agent',
                  isRequired: false,
                  onActionClicked: onSearchFieldActionClicked,
                }) ||
                undefined
              }
          labelValue={label}
        />
        {
              item.data.HelperInfo && item.data.HelperInfo !== '' && (
                <div className='information-open-file-1'>
                  <Tooltip
                    size='larg'
                    title={item.data.HelperInfo}
                  >
                    <span className='mdi mdi-information-outline' />
                  </Tooltip>
                </div>
              )
            }
      </div>
        )}
      {/* /************************ display colse reason when lead status  is closed only  ************************** */}
      {((displySelectFields()))
        && (
          <div className='form-item'>
            <AutocompleteComponent
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              idRef={item.field.id}
              isRequired={item.field.isRequired}
              isDisabled={getIsUpdateCloseResonAndRemarks() || saleTypeOwnerlead() || isLoading}
              isLoading={isLoading}
              withLoader
              isWithError
              isSubmitted={isSubmitted}
              helperText={helperText}
              error={error}
              multiple={(item.data.multi && item.data.multi === 'true') || false}
              filterOptions={
                ((item.data.searchKey === 'property' ||
                  item.data.searchKey === 'contact' ||
                  item.data.searchKey === 'PropertyOrUnit') &&
                  ((option) => option)) ||
                undefined
              }
              renderOption={
                (item.data.searchKey === 'property' &&
                  ((option) => <span>{`${option.name || ''} - ${option.city || ''}`}</span>)) ||
                (item.data.searchKey === 'contact' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                      <span className='c-gray-secondary'>{option.phone}</span>
                    </div>
                  ))) ||
                (item.data.searchKey === 'PropertyOrUnit' &&
                  ((option) =>
                    (option.isProperty && (
                      <span>{`${option.name || ''} - ${option.city || ''}`}</span>
                    )) || (
                      <div className='d-flex-column'>
                        <span>{`${option.unitModel} - ${option.name} - ${option.unitType}`}</span>
                        <span className='c-gray-secondary'>
                          {`${option.unitBedrooms} - ${option.unitRefNo}`}
                        </span>
                      </div>
                    ))) ||
                undefined
              }
              selectedValues={
                item.data.multi && item.data.multi === 'true' ?
                  (itemValue &&
                    ((item.field.FieldType === 'UploadFiles' && itemValue && itemValue.selected && [...itemValue.selected]) ||
                      (!Array.isArray(itemValue) && [itemValue]) || [...itemValue])) ||
                  [] :
                  itemValue || null
              }
              data={item.data.enum}
              chipsLabel={
                (item.data && item.data.multi &&
                  item.data.multi === 'true' &&
                  ((item.field.FieldType === 'searchField' &&
                    ((option) => (option && option.name) || '')) ||
                    ((option) => (!item.data.lookup ? option : option && option.lookupItemName || undefined)))) ||
                undefined
              }
              onInputChange={
                (item.field.FieldType === 'searchField' &&
                  ((e, v) => {
                    ContactRule(item, v, onItemChanged);
                    PropertyRule(item, v, onItemChanged);
                    UserRule(item, v, onItemChanged, allItems, allItemsValues);
                    LeadsRule(item, v, onItemChanged);
                    PropertyOrUnitRule(item, v, onItemChanged, allItems, allItemsValues);
                  })) ||
                undefined
              }
              inputClasses='inputs theme-form-builder'
              displayLabel={
                (item.field.FieldType === 'searchField' &&
                  ((option) => (option && option.name) || '')) ||
                ((option) => (!item.data.lookup ? option : option.lookupItemName) || '')
              }
              getOptionSelected={
                (item.field.FieldType === 'searchField' &&
                  ((option) =>
                    (item.data.multi &&
                      item.data.multi === 'true' &&
                      itemValue &&
                      ((!Array.isArray(itemValue) && [itemValue]) || itemValue).findIndex(
                        (element) => ( element && element.id && element.id === option.id) || option === element
                      ) !== -1) ||
                    (itemValue.id && itemValue.id === option.id) ||
                    option === itemValue)) ||
                ((option) =>
                  (item.data.multi &&
                    item.data.multi === 'true' &&
                    (
                      (itemValue &&
                        ((item.field.FieldType === 'UploadFiles' && itemValue && itemValue.selected) ||
                          (!Array.isArray(itemValue) && [itemValue]) ||
                          itemValue)) ||
                      []
                    ).findIndex(
                      (element) =>
                        ( element && element.lookupItemId && element.lookupItemId === option.lookupItemId) ||
                        option === element
                    ) !== -1) ||
                  (((item.field.FieldType === 'UploadFiles' && itemValue  && itemValue.selected) || itemValue)
                    .lookupItemId &&
                    ((item.field.FieldType === 'UploadFiles' && itemValue && itemValue.selected) || itemValue)
                      .lookupItemId === option.lookupItemId) ||
                  option === itemValue)
              }
              onChange={(e, v) => {
                if (item.data.isReadonly)
                  return;
                if (item.field.id === 'bedrooms' || item.field.id === 'bathrooms') {
                  const findAnyBathroomsAndBedrooms = v.find((num) => num === 'Any' || num === 'any');
                  if (findAnyBathroomsAndBedrooms) {
                    onValueChanged([findAnyBathroomsAndBedrooms]);
                    return;
                  }
                }
                TitleRuleV2(id, onValueChanged, item, allItems, v);
                OnchangePropertyInUnitRuleV2(item, v, onValueChanged);
                if (item.field.FieldType === 'UploadFiles')
                  onValueChanged({ ...itemValue, selected: v });
                else onValueChanged(v);
                LookupsEffectedOnRules(
                  v,
                  item,
                  onValueChanged,
                  onItemChanged,
                  allItems,
                  allItemsValues,
                  onLoadingsChanged
                );
              }}
              withoutSearchButton
              buttonOptions={
                (item.field.FieldType === 'UploadFiles' && {
                  className: 'btns-icon theme-solid bg-blue-lighter',
                  iconClasses:
                    item.field.id === 'external_url' ? 'mdi mdi-plus' : 'mdi mdi-attachment',
                  isDisabled: (item.data.isReadonly) || !(itemValue && itemValue.selected && itemValue.selected.length > 0),
                  isRequired: false,
                  onActionClicked: () => {
                    if (
                      itemValue &&
                      itemValue.selected &&
                      itemValue.selected.length > 0 &&
                      item.field.id !== 'external_url'
                    )
                      setOpenUploadDialog(true);
                    if (
                      itemValue &&
                      itemValue.selected &&
                      itemValue.selected.length > 0 &&
                      item.field.id === 'external_url'
                    )
                      setRepeatedLinkDialog(true);
                  },
                }) ||
                (item.field.FieldType === 'searchField' &&
                  item.field.id !== 'referredto' &&
                  item.field.id !== 'referredby' &&

                {
                  className: 'btns-icon theme-outline c-blue-lighter',
                  iconClasses: itemValue ? 'mdi mdi-eye-outline' : 'mdi mdi-plus',
                  isDisabled:
                    (item.data.multi && item.data.multi === 'true') ||
                    item.field.id === 'listing_agent',
                  isRequired: false,
                  onActionClicked: onSearchFieldActionClicked,
                }) ||
                undefined
              }
              labelValue={label}
            />
            {
              item.data.HelperInfo && item.data.HelperInfo !== '' && (
                <div className='information-open-file-1'>
                  <Tooltip
                    size='larg'
                    title={item.data.HelperInfo}
                  >
                    <span className='mdi mdi-information-outline' />
                  </Tooltip>
                </div>
              )
            }
          </div>
        )}

      {item.field.FieldType === 'PriceAndPercentage' && (
        <PriceAndPercentage
          type={item.data.controlType}
          itemIsSensitive={(item.data.isReadonly) || false}
          allItemsValues={allItemsValues}
          onValueChanged={onValueChanged}
          currency='AED'
          value={itemValue}
          onChange={(e) => {
            if (e === null) return;
            onValueChanged(e);
          }}
          // sqrSalePrice={(allItemsValues && allItemsValues.pricesqm && Number(allItemsValues.pricesqm))}
          depositSum={item.data.controlType === 'Rent Type' && RentDeposit2(allItemsValues, onValueChanged, 2, allItems)}
          hideDeposit={hideDeposit}

        />
      )}
      {itemValue && itemValue.selected && itemValue.selected.length > 0 && (
        <UploadDialog
          open={openUploadDialog}
          onChange={(e) => {
            onValueChanged({ ...e, selected: (itemValue && itemValue.selected) });
          }}
          initialState={itemValue}
          closeDialog={() => {
            setOpenUploadDialog(false);
          }}
        />
      )}
      {itemValue && openMapDialog && (
        <MapDialog
          open={openMapDialog}
          onChange={(e) => {
            onValueChanged({ ...e });
          }}
          initialState={itemValue}
          closeDialog={() => {
            setOpenMapDialog(false);
          }}
        />
      )}
      {/* item.field.id==="property_name" &&  */}
      {itemValue && itemValue.Models && (
        <ModelsUnitsDialog
          initialState={itemValue}
          items={item.data.enum}
          open={modelsUnitsDialog}
          closeDialog={() => {
            setModelsUnitsDialog(false);
          }}
          onChange={(value) => {
            onValueChanged({
              ...value,
              Models: itemValue.Models,
            });
          }}
        />
      )}
      {
      }
      <ContactsDialog
        open={isOpenContactsDialog}
        onSave={() => { }}
        closeDialog={() => {
          setIsOpenContactsDialog(false);
        }}
        isViewContact={itemValue}
        isOpenInsideForm={isOpenContactsDialog}
      />

      {isOpenItemDetails && (
        <DetailsDialog
          isOpen={isOpenItemDetails}
          isOpenChanged={() => {
            setIsOpenItemDetails(false);
            setIsLoadingDetailsDialog(false);
            setIsSensitiveLoading2(0);
          }}
          isLoadingDetailsDialog={isLoadingDetailsDialog}
          isOpenInsideForm={isOpenItemDetails}
          dataInSideViewButton={dataInSideViewButton}
          sensitiveFieldsForUser={sensitiveFieldsForUser2}
          isSensitiveLoading={isSensitiveLoading2}
        />
      )}

      {
      isNotHavePolicyToDisplayData && (
        <NotHavePolicyToDisplayDataDialog
          isOpen={isNotHavePolicyToDisplayData}
          isOpenChanged={() => { setIsNotHavePolicyToDisplayData(false); }}
        />
      )

      }

      {RepeatedDialog && (
        <RepeatedItemDialog
          open={RepeatedDialog}
          item={item}
          type={(item.data.CommunicationType === 'Email' && 'email') || 'phone'}
          initialState={itemValue}
          label={label}
          closeDialog={() => {
            setRepeatedDialog(false);
          }}
          selectedValues={allItemsValues}
          itemList={allItems}
          onChange={
            (item.data.CommunicationType === 'Email' &&
              ((value) => {
                onValueChanged({
                  ...value,
                  email: itemValue.email,
                  typeId: item.data.lookupItem,
                });
              })) ||
            ((newValue) => {
              onValueChanged({
                ...newValue,
                phone: itemValue.phone,
                typeId: item.data.lookupItem,
              });
            })
          }
        />
      )}
      {repeatedLinkDialog && (
        <RepeatedLinkDialog
          item={item}
          itemList={allItems}
          label={label}
          initialState={itemValue}
          open={repeatedLinkDialog}
          selectedValues={allItemsValues}
          onChange={(newValue) => {
            onValueChanged({
              ...newValue,
            });
          }}
          closeDialog={() => setRepeatedLinkDialog(false)}
        />
      )}
      {isOpenLeadOwnerDialog && (
        <LeadOwnerDialog
          open={isOpenLeadOwnerDialog}
          onSave={() => setIsOpenLeadOwnerDialog(false)}
          close={() => {
            onValueChanged(leadOwnerValue, 0, 'lead_owner');
            setIsOpenLeadOwnerDialog(false);
          }}
        />
      )}
      {isOpenLeaseLeadOwnerDialog && (
        <LeaseLeadOwnerDialog
          open={isOpenLeaseLeadOwnerDialog}
          onSave={() => setisOpenLeaseLeadOwnerDialog(false)}
          close={() => {
            onValueChanged(leadOwnerValue, 0, 'lease_lead_owner');
            setisOpenLeaseLeadOwnerDialog(false);
          }}

        />
      )}
      {
      isNotHavePolicyToDisplayData && (
        <NotHavePolicyToDisplayDataDialog
          isOpen={isNotHavePolicyToDisplayData}
          isOpenChanged={() => { setIsNotHavePolicyToDisplayData(false); }}
        />
      )
      }
    </>
  );
};
const convertJsonItemShape = PropTypes.shape({
  data: PropTypes.shape({
    enum: PropTypes.instanceOf(Array),
    CommunicationType: PropTypes.string,
    searchKey: PropTypes.string,
    title: PropTypes.string,
    regExp: PropTypes.string,
    dependOn: PropTypes.string,
    uiType: PropTypes.string,
    defaultCountryCode: PropTypes.string,
    minNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isReadonly: PropTypes.bool,
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
    lookup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    controlType: PropTypes.string, // this type is not sure is string
    multi: PropTypes.oneOf(['true', 'false']),
    lookupItem: PropTypes.number,
    items: PropTypes.shape({
      enum: PropTypes.instanceOf(Array),
    }),
  }),
  field: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    FieldType: PropTypes.string,
    isRequired: PropTypes.bool,
  }),
});
const convertJsonValueShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  PropTypes.instanceOf(Object),
  PropTypes.instanceOf(Array),
]);
ConvertJsonV2Component.propTypes = {
  item: convertJsonItemShape.isRequired,
  itemValue: convertJsonValueShape,
  allItems: PropTypes.arrayOf(convertJsonItemShape).isRequired,
  allItemsValues: PropTypes.objectOf(convertJsonValueShape).isRequired,
  onItemChanged: PropTypes.func.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  onLoadingsChanged: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  reload: PropTypes.bool.isRequired,
  save: PropTypes.bool.isRequired,
  hideDeposit: PropTypes.bool,
  typeOf: PropTypes.string,
  isOpenInsideForm: PropTypes.bool,
  sensitiveFieldsForUser: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};
ConvertJsonV2Component.defaultProps = {
  itemValue: undefined,
  reload: false,
  save: false,
  hideDeposit: false,
  typeOf: '',
  isOpenInsideForm: false,
  sensitiveFieldsForUser: []
};
