import React from 'react';
import PropTypes from 'prop-types';
import {
  ActivityNameComponent,
  StageComponent,
  ActivityCategoryComponent,
  RatingComponent,
  LeadRatingComponent,
  RelatedToComponent,
  CreatedByComponent,
  CreatedByBranchesComponent,
  PrerequisiteActivitiesComponent,
  ExpiredPeriodComponent,
  AssignedToComponent,
  CheckboxesComponentActivities,
  LeadQualificationComponent,
  SLAExpirationPeriodSection,
  SLAExpirationPeriodInputComponent,
  SLALApplyForComponent,
  SLAExpirationPeriodDependOn
} from '../DialogManagementConrtolComponent';

export const DetailsTabComponent = ({
  parentTranslationPath,
  translationPath,
  setState,
  state,
  Data,
  expirationPeriodTimeType,
  setExpirationPeriodTimeType,
}) => (
  <div className='DialogManagementViewComponent-wrapper'>
    <div className='w-50 px-2'>
      <div className='activity-dialog-item'>
        <ActivityNameComponent
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          state={state}
          setState={(item) =>
            setState({
              id: 'activityTypeName',
              value: item,
            })}
        />
      </div>
      <div className='activity-dialog-item'>
        <StageComponent
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          state={state}
          Data={Data}
          setState={(item) => {
            setState({
              id: 'leadStageId',
              value: item.leadStageId,
            });
            setState({
              id: 'leadStageName',
              value: item.leadStageName,
            });
          }}
        />
      </div>

      <div className='activity-dialog-item'>
        <ExpiredPeriodComponent
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          state={state}
          setState={(item) =>
            setState({
              id: 'expiredPeriod',
              value: +item,
            })}
          expirationPeriodTimeType={expirationPeriodTimeType}
          setExpirationPeriodTimeType={setExpirationPeriodTimeType}
        />
      </div>
      <div className='activity-dialog-item'>
        <PrerequisiteActivitiesComponent
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          state={state}
          Data={Data}
          setState={(item) =>
            setState({
              id: 'activityTypeActivityTypePrerequisites',
              value: item,
            })}
        />
      </div>
      <div className='activity-dialog-item'>
        <CreatedByBranchesComponent
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          state={state}
          Data={Data}
          setState={(item) =>
            setState({
              id: 'activityTypeCreatedByBranches',
              value: item,
            })}
        />
      </div>
      <div className='activity-dialog-item'>
        <CheckboxesComponentActivities
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          state={state}
          setStatewithDateTime={(item) =>
            setState({
              id: 'withDateTime',
              value: item,
            })}
          setStatewithReminder={(item) => {
            if (item === false) {
              setState({
                id: 'edit',
                value: {
                  ...state,
                  withReminder: item,
                  isWithslaExpirationPeriod: false,
                  slaExpirationPeriod: 0,
                  slaExpirationPeriodFreqency: null,
                  slaExpirationPeriodComparisonDate: null,
                },
              });
            } else setState({ id: 'withReminder', value: item });
          }}
          setStateWithDuration={(item) =>
            setState({
              id: 'withDuration',
              value: item,
            })}
          setStatefollowUpRequired={(item) =>
            setState({
              id: 'followUpRequired',
              value: item,
            })}
          setStateIsForMobile={(item) =>
            setState({
              id: 'isForMobile',
              value: item,
            })}
          setIsWithslaExpirationPeriod={(item) => {
            if (item === false) {
              setState({
                id: 'edit',
                value: {
                  ...state,
                  isWithslaExpirationPeriod: item,
                  slaExpirationPeriod: 0,
                  slaExpirationPeriodFreqency: null,
                  slaExpirationPeriodComparisonDate: null,
                },
              });
            } else setState({ id: 'isWithslaExpirationPeriod', value: item });
          }}
        />
      </div>
      {state.isWithslaExpirationPeriod && (
        <div className='activity-dialog-item'>
          <SLAExpirationPeriodSection
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          >
            <SLAExpirationPeriodInputComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={(item) =>
                setState({
                  id: 'edit',
                  value: {
                    ...state,
                    slaExpirationPeriod: item.slaExpirationPeriod,
                    slaExpirationPeriodFreqency: item.slaExpirationPeriodFreqency,
                  },
                })}
            />
            <SLALApplyForComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={(item) =>
                setState({
                  id: 'edit',
                  value: {
                    ...state,
                    slaAppliedFor: item.slaAppliedFor,
                  },
                })}
            />
            <SLAExpirationPeriodDependOn
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={(item) =>
                setState({
                  id: 'slaExpirationPeriodComparisonDate',
                  value: +item,
                })}
            />
          </SLAExpirationPeriodSection>
        </div>
      )}
    </div>
    <div className='w-50 px-2'>
      <div className='activity-dialog-item'>
        <ActivityCategoryComponent
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          state={state}
          Data={Data}
          setState={(item) => {
            setState({
              id: 'categoryId',
              value: item.categoryId,
            });
            setState({
              id: 'categoryName',
              value: item.categoryName,
            });
          }}
        />
      </div>
      <div className='activity-dialog-item'>
        <RatingComponent
          translationPath={translationPath}
          state={state}
          Data={Data}
          parentTranslationPath={parentTranslationPath}
          setState={(item) => {
            setState({
              id: 'activityRate',
              value: item.activityRate,
            });
            setState({
              id: 'activityRateName',
              value: item.activityRateName,
            });
          }}
        />
      </div>
      <div className='activity-dialog-item'>
        <LeadRatingComponent
          translationPath={translationPath}
          state={state}
          Data={Data}
          parentTranslationPath={parentTranslationPath}
          setState={(item) => {
            setState({
              id: 'leadRatingId',
              value: item.leadRatingId,
            });
            setState({
              id: 'leadRatingName',
              value: item.leadRatingName,
            });
          }}
        />
      </div>
      <div className='activity-dialog-item'>
        <LeadQualificationComponent
          translationPath={translationPath}
          state={state}
          Data={Data}
          parentTranslationPath={parentTranslationPath}
          setState={(item) => {
            setState({
              id: 'leadQualificationId',
              value: item.leadQualificationId,
            });
            setState({
              id: 'leadQualificationName',
              value: item.leadQualificationName,
            });
          }}
        />
      </div>
      <div className='activity-dialog-item'>
        <RelatedToComponent
          translationPath={translationPath}
          state={state}
          Data={Data}
          parentTranslationPath={parentTranslationPath}
          setState={(item) =>
            setState({
              id: 'relatedTo',
              value: item,
            })}
        />
      </div>
      <div className='activity-dialog-item'>
        <AssignedToComponent
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          state={state}
          Data={Data}
          setState={(item) =>
            setState({
              id: 'assignedToActivityTypeRoles',
              value: item,
            })}
        />
      </div>
      <div className='activity-dialog-item'>
        <CreatedByComponent
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          state={state}
          Data={Data}
          setState={(item) =>
            setState({
              id: 'createActivityTypeRoles',
              value: item,
            })}
        />
      </div>
    </div>
  </div>
);
DetailsTabComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setState: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};
