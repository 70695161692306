import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {  UploaderComponent, Inputs } from '../../../../../Components';
import 'react-quill/dist/quill.snow.css';
import { showError, getIsValidURL } from '../../../../../Helper';
import { UploaderThemesEnum } from '../../../../../Enums';
import { ImagesGalleryPhotosCardComponent } from '../../LocationSharingUtilities/ImagesGalleryPhotosCardComponent/ImagesGalleryPhotosCardComponent';
import { ButtonBase } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { isURL, matchYoutubeUrl } from '../../../../../Helper/Link.helper';

export const LandmarkAttachments = ({
  parentTranslationPath,
  translationPath,
  selected,
  setSelected
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const removeImageHandler = (removedImage) => {
    const removedImageIndex = selected.files && selected.files.findIndex(
      (item) => removedImage.uuid == item.uuid
    );

    if (removedImageIndex !== -1) {
      const localFiles = [...(selected.files || [])];
      localFiles.splice(removedImageIndex, 1);

      setSelected({ id: 'files', value: localFiles });
    }
  };


  return (
    <div className='lamdmarks-form1 lamdmarks-form2'>
      <div className='d-flex-column'>
        <div className='UploaderComponent-wrapered pt-3'>
          <UploaderComponent
            idRef='profileImgRef'
            uploaderTheme={UploaderThemesEnum.box.key}
            defaultImage='Uploadmark'
            WithoutDefaultImg
            accept="image/*"
            dropHereText='Drag-and-drop-here-or-browse-File'
            multiple={false}
            initUploadedFiles={selected.files || []}
            uploadedChanged={(newFiles) => {
              setSelected({ id: 'files', value: [...(selected.files || []), ...(newFiles || [])] });
            }}
          />
        </div>
        <div>
          <div className='pt-4'>
            <ImagesGalleryPhotosCardComponent
              data={selected.files || []}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              imageInput={"uuid"}
              altInput={"fileName"}
              deletevalue={removedImage => removeImageHandler(removedImage)}
            />
          </div>
          <Inputs
            idRef='VirtualtoursRef'
            labelValue='Virtual-tours'
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.virtualTourLink || ''}
            onInputChanged={(event) => setSelected({ id: 'virtualTourLink', value: event.target.value })}
          />
          <Inputs
            idRef='usefulLinkRef'
            labelValue='usefulLink'
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.usefulLink || ''}
            onInputChanged={(event) => setSelected({ id: 'usefulLink', value: event.target.value })}
          />
          <Inputs
            idRef='website-linksRef'
            labelValue='website'
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.website || ''}
            onInputChanged={(event) => setSelected({ id: 'website', value: event.target.value })}
          />
          <Inputs
            idRef='youtubeLinksRef'
            labelValue='youtubeLink'
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.youtubeLink || ''}
            onInputChanged={(event) => setSelected({ id: 'youtubeLink', value: event.target.value })}
          />
          <div className='Active-btt-menu mt-2'>
            <ButtonBase
              className={`menu-button ${selected.isActive === true ? 'is-active' : ''}`}
              onClick={() => {
                setSelected({ id: 'isActive', value: true })
              }}>
              <span className='mdi mdi-check-circle-outline' />
              {t(`${translationPath}Active`)}
            </ButtonBase>
            <ButtonBase
              className={`menu-button ${selected.isActive === false ? 'is-active' : ''}`}
              onClick={() => {
                setSelected({ id: 'isActive', value: false })
              }}>
              <span className='mdi mdi-close-circle-outline' />
              {t(`${translationPath}inActive`)}
            </ButtonBase>
          </div>
          <div className='Active-btt-menu mt-2' />
        </div>
      </div>
    </div>
  );
};
