import React, {
  useState, useCallback, useEffect, useRef
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonBase } from '@material-ui/core';
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalHistory,
  bottomBoxComponentUpdate,
  getIsAllowedPermission,
  WhatsAppMessage,
  showError,
  getSideMenuStatus,
  havePermissionToViewDetails,
  havePermissionToEditInformation,
  getSideMenuComponent,
  returnPropsByPermissions
} from '../../../Helper';
import {
  ActionsButtonsComponent,
  ViewTypes,
  AutocompleteComponent,
  SelectComponet,
  Spinner,
  PaginationComponent,
  PermissionsComponent,
  Inputs,
  DialogComponent,
} from '../../../Components';
import {
  ActionsEnum,
  ViewTypesEnum,
  ActionsButtonsEnum,
  FormsIdsEnum,
  TableFilterOperatorsEnum,
  TableFilterTypesEnum,
  LeadsClassTypesEnum,
  DateFilterTypesEnum,
  SearchCriteriaEnum,
  ContactPreferenceEnum , 
  ContactClassTypesEnum , 
} from '../../../Enums';
import {
  CardDetailsComponent,
  ContactsCardsComponent,
  ContactsImportDialog,
  ContactsMapper,
  ContactsTableComponent,
} from './ContactsUtilities';
import { ContactsMergeActions } from '../../../store/ContactsMerge/Actions';
import { AdvanceSearch } from '../../../SharedComponents/AdvanceSearch/AdvanceSearch' ; 
import { AdvanceSearchSecondVersion } from '../../../SharedComponents/AdvanceSearchSecondVersion';
import { ContactsTableHeaderData } from './ContactsUtilities/ContactsTableComponent/ContactsTableHeaderData'
import { GetAllSearchableFormFieldsByFormId, GetAdvanceSearchContacts, GetAllFormFieldsByFormId, MaqsamTokenServices, MaqsamautologinServices } from '../../../Services';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { GlobalAdvanceSearchActions } from '../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions';

import { useTitle } from '../../../Hooks';
import { ContactsActionDialogsComponent } from './ContactsUtilities/ContactsActionDialogsComponent/ContactsActionDialogsComponent';
import { ContactsPermissions } from '../../../Permissions';
import { ContactsVerticalTabsData } from '../../Home/Common/OpenFileView/OpenFileUtilities/OpenFileData/ContactsVerticalTabsData';

const parentTranslationPath = 'ContactsView';
const translationPath = '';
export const ContactsView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [displyOpenFileButton, setDisplyOpenFileButton] = useState(false);

  const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const advanceSearch = useSelector((state) => state.GlobalAdvanceSearchReducer);

  const [contactTableFilter, setContactTableFilter] = useState((advanceSearch && advanceSearch['ContactFilter']) || null);
  const [isLoading, setIsLoading
  ] = useState(false);
  const [isFirst, setFirst] = useState(false);
  const [isAllFormFieldsLoading, setIsAllFormFieldsLoading] = useState(false);
  const [allFormFields, setAllFormFields] = useState([]); 

  const [selectFieldsOnAdvanceSearch , setSelectFieldsOnAdvanceSearch] = useState([]) ; 
  const [filterValuesFromAdvanceSearch , setFilterValuesFromAdvanceSearch ]= useState(null) ; 


  const [LeadClassesFilter, setLeadClassesFilter] = useState(orderFilter.LeadClasses || []);
  const [isFirst1, setFirst1] = useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const dispatch = useDispatch();
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [isOpenContactsActionDialog, setisOpenContactsActionDialog] = useState(false);
  const [isOpenMaqsamiframe, setisOpenMaqsamiframe] = useState(false);
  const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isAdvanceSearchCleared, setIsAdvanceSearchCleared] = useState(false);
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [detailedCardAction, setdetailedCardAction] = useState(() => ({
    actionEnum: '',
    item: '',
  }));

  const [contactClassesFilter, setContactClassesFilter] = useState((orderFilter && orderFilter.ContactClass) ||[] );
  const [contactClasses, setContactClasses] = useState((orderFilter && orderFilter.ContactClass)|| []); 


  const MaqsamTokenServicesAPI = useCallback(async (mobile) => {
    const result = await MaqsamTokenServices();
    if (result) {
      setisOpenMaqsamiframe(true)
      var iframe = document.getElementById('maqsamiframe');
      if (iframe !== null) {
        let token = result
        iframe.setAttribute('src', `https://portal.maqsam.com/autologin?auth_token=${token};continue_path=/phone/dialer#autodial=${mobile}`);
      }
    }
  }, []);

  const detailedCardActionClicked = useCallback(
    (actionEnum, item) => (event) => {
      event.stopPropagation();
      setisOpenContactsActionDialog(true);
      setdetailedCardAction({
        actionEnum,
        item,
      });
      if (actionEnum === 'whatsappSolid') {
        const el = document.createElement('a');
        if (item?.mobile?.phone) {
          el.href = WhatsAppMessage(item?.whatsapp_mobile?.phone || item.mobile.phone);
          el.target = 'blank';
          el.click();
        } else
          showError(t(`${translationPath}Failure-Open-WhatsApp`));
      }
      if (actionEnum === 'phoneSolid') {
        MaqsamTokenServicesAPI(item && item.mobile && item.mobile.phone || item && item.contacts_person && item.contacts_person[0].mobile || null);
      }
      // eslint-disable-next-line no-console
    },
    []
  );
  const [activeActionType, setActiveActionType] = useState(
    localStorage.getItem('ViewType') &&
    JSON.parse(localStorage.getItem('ViewType')).contactView
    || ViewTypesEnum.cards.key);

  const [isExpanded, setIsExpanded] = useState(
    activeActionType === ViewTypesEnum.cardsExpanded.key
  );
  const [, setOrderByToggler] = useState(false);
  const [activeSelectedAction, setActiveSelectedAction] = useState('');
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [selectedSeachIn, setSelectedSeachIn] = useState(orderFilter.LeadClasses || []);

  const seachInChangedHandler = (newValue) => {
    setSelectedSeachIn((items) => {
      setLeadClassesFilter([...newValue]);
      return [...newValue];
    });
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        LeadClasses: newValue
      })
    );

  };

  const [selectedOrderBy, setSelectedOrderBy] = useState(
    (pathName === 'contacts' && {
      filterBy: orderFilter.contactsFilter.filterBy,
      orderBy: orderFilter.contactsFilter.orderBy,
    }) ||
    (pathName === 'contact-lease' && {
      filterBy: orderFilter.contactsLeaseFilter.filterBy,
      orderBy: orderFilter.contactsLeaseFilter.orderBy,
    }) ||
    (pathName === 'contact-sales' && {
      filterBy: orderFilter.contactsSalesFilter.filterBy,
      orderBy: orderFilter.contactsSalesFilter.orderBy,
    }) ||
    (pathName === 'contact-property-management' && {
      filterBy: orderFilter.contactsFilter.filterBy,
      orderBy: orderFilter.contactsFilter.orderBy,
    }) ||
    (pathName === 'Contacts-CRM' && {
      filterBy: orderFilter.contactsCrmFilter.filterBy,
      orderBy: orderFilter.contactsCrmFilter.orderBy,
    })
  );

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: 1
  };

  const [dateFilter, setDateFilter] = useState(
    (orderFilter && orderFilter.ContactsFilterDate && orderFilter.ContactsFilterDate.startDate && orderFilter.ContactsFilterDate.endDat !== null) &&
    (
      {
        startDate: new Date(orderFilter && orderFilter.ContactsFilterDate && orderFilter.ContactsFilterDate.startDate || null),
        endDate: new Date(orderFilter && orderFilter.ContactsFilterDate && orderFilter.ContactsFilterDate.endDate || null),
        key: new Date(orderFilter && orderFilter.ContactsFilterDate && orderFilter.ContactsFilterDate.key || null),
        selectedDateType: orderFilter && orderFilter.ContactsFilterDate && orderFilter.ContactsFilterDate.selectedDateType || 1
      }) || dateRangeDefault
  );
  const [orderBy, setOrderBy] = useState(selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: "createdOn", orderBy: 2 });
  const [checkedCards, setCheckedCards] = useState([]);
  const [checkedCardsIds, setCheckedCardsIds] = useState([]);
  const [detailsContactsList, setDetailsContactsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  // this one to change searchable form fields
  const [filterFormType, setFilterFormType] = useState(1);
  // this one to change get contacts by type
  const [activeFormType, setActiveFormType] = useState(orderFilter.activeFormType || 0);
  const [searchableFormFields, setSearchableFormFields] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchedItem, setSearchedItem] = useState('');
  const searchTimer = useRef(null);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
  });
  useTitle(t(`${translationPath}contacts`));
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    if (pathName === 'contact-sales') {
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          contactsSalesFilter: {
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    } else if (pathName === 'contact-lease') {
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          contactsLeaseFilter: {
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    } else if (pathName === 'Contacts-CRM') {
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          contactsCrmFilter: {
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    } else {
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          contactsFilter: {
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    }
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };

  const searchHandler = (data) => {
    const newV = [{
      key: 'All',
      title: 'All',
      value: data
    }];

    if (searchTimer.current) clearTimeout(searchTimer.current);

    if (data === '') {
      const index = searchData.findIndex((item) => item.key === 'All');
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem('ContactFilter');
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newV]);
        setFilterSearchDto({
          All:
            [
              {
                value: data
              }
            ]
        });
      }, 1300);
    }
  };
  const onTypeChanged = useCallback(
    (activeType) => {
      let ViewTypeData = JSON.parse(localStorage.getItem('ViewType'));
      if (ViewTypeData) {
        ViewTypeData.contactView = activeType;
        localStorage.setItem('ViewType', JSON.stringify(ViewTypeData));
      } else localStorage.setItem('ViewType', JSON.stringify({ contactView: activeType }));

      setActiveActionType(activeType);
      setIsExpanded(activeType === ViewTypesEnum.cardsExpanded.key);
      sideMenuIsOpenUpdate(false);
      sideMenuComponentUpdate(null);

    },
    [setActiveActionType]
  );
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const filterOnChange = (event, newValue) => {
    const emptyKeyIndex = newValue.findIndex((item) => !item.value);
    if (!searchInputValue && emptyKeyIndex !== -1) {
      newValue.splice(emptyKeyIndex, 1);
      return;
    }
    if (emptyKeyIndex !== -1) newValue[emptyKeyIndex].value = searchInputValue;
    if (filterSearchDto && Object.keys(filterSearchDto).length > 0 && newValue.length === 0) {
      onPageIndexChanged(0);
      setFilterSearchDto(null);
    }
    setSearchData([...newValue]);
  };
  const onFilterFormTypeSelectChanged = (value) => {
    if (value === 2) setFilterFormType(FormsIdsEnum.contactsCorporate.id);
    else setFilterFormType(FormsIdsEnum.contactsIndividual.id);

    if (searchData.length > 0) {
      setSearchData([]);
      onPageIndexChanged(0);
      setFilterSearchDto(null);
    }
  };
  const changeActiveFormType = (value) => {
    setActiveFormType(value);
    setSearchedItem('');
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        activeFormType: value
      })
    );

    onFilterFormTypeSelectChanged(value);
  };

  const changeContactClass = (newValue) => {
    if(newValue) 
    {
      setContactClasses((items) => {
        setContactClassesFilter([...newValue]);
        return [...newValue];
      });

      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          ContactClass: newValue
        })
      );

    }
   else 
   {
    setContactClasses([]) ;
    setContactClassesFilter([]);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ContactClass: []
      })
    );

  }

  };
  const getAllSearchableFormFieldsByFormId = useCallback(async () => {
    const result = await GetAllSearchableFormFieldsByFormId(filterFormType);
    if (!((result && result.data && result.data.ErrorId) || !result)) {
      let list = [];
      list.push({ key: 'All', title: 'All' });
      list.push({ key: 'Ids', title: 'Contact Id' });
      list.push({ key: 'lead_id', title: 'Lead Id' });
      const res =
        (Array.isArray(result) &&
          result
            .filter((item) => item.isSearchable)
            .map((item) => ({ key: item.searchableKey, title: item.formFieldTitle }))) ||
        [];
      list = [...list, ...res];
      setSearchableFormFields(list);
    } else setSearchableFormFields([]);
  }, [filterFormType]);

  const getContactsData = useCallback(async (f) => {
    if (returnPropsByPermissions(ContactsPermissions.ViewAndSearchContacts.permissionsId)) {

      setIsLoading(true);
      const localFilterDto = f || filterSearchDto || {};
      if (activeFormType) {
        localFilterDto.contacttypeid = [
          { searchType: TableFilterOperatorsEnum.equal.key, value: activeFormType },
        ];
      }
       if (contactTableFilter) {
        Object.values(contactTableFilter)
          .filter((item) => item.searchableKey || item.displayPath)
          .map((item) => {
            if (localFilterDto[item.searchableKey || item.displayPath]) {
              localFilterDto[item.searchableKey || item.displayPath].push({
                searchType: (item && item.operator),
                value: (item && item.value),
              });
            } else if (item.value) {
              localFilterDto[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: item.value,

                },
              ];
            } else if (!item.value && (item.operator === TableFilterOperatorsEnum.isNotBlank.key
              || item.operator === TableFilterOperatorsEnum.isBlank.key)) {
              localFilterDto[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: null,
                },
              ];
            }
            return undefined;
          });
      }
      if (Object.values(localFilterDto).length) setIsAdvanceSearchActive(true);
      else setIsAdvanceSearchActive(false);

      let body = {
        criteria: localFilterDto,
        ...orderBy,
        LeadClasses: LeadClassesFilter,
        contactClasses : contactClassesFilter , 
      }
      setIsDatePickerChanged(false)

      if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
        const fromDate = moment(dateFilter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const toDate = moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss');

        if (dateFilter.selectedDateType === DateFilterTypesEnum.CreatedOnDate.key) {
          body.fromDate = fromDate;
          body.toDate = toDate;
        } else if (dateFilter.selectedDateType === DateFilterTypesEnum.UpdatedOnDate.key) {
          body.updatedFromDate = fromDate;
          body.updatedToDate = toDate;
        }
      }

      const res = await GetAdvanceSearchContacts(filter, body);
      if (!(res && res.status && res.status !== 200)) {
        setDetailsContactsList({
          result: ((res && res.result) || []).map((item) => item.contactJson && ContactsMapper(item, JSON.parse(item.contactJson).contact)),
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setDetailsContactsList({
          result: [],
          totalCount: 0,
        });
      }
      setIsLoading(false);
    }
  }, [activeFormType, filter, dateFilter, filterSearchDto, orderBy, LeadClassesFilter , contactClassesFilter , contactTableFilter]);

  const searchClicked = async () => {
    if (searchData.length === 0) return;
    localStorage.setItem('ContactFilter', JSON.stringify(searchData));
    setSearchedItem('');
    setFilterSearchDto(
      searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
    );
    onPageIndexChanged(0);
  };

  const searchchachedClickedWithoutFilter = async (data) => {
    if (data.length === 0) return;
    const oldfilter = data.reduce((total, item) => {
      if (total[item.key]) {
        total[item.key].push({
          searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
          value: item.value,
        });
      } else {
        total[item.key] = [
          {
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          },
        ];
      }
      return total;
    }, {});
    getContactsData(oldfilter);
  };
  const displayedLabel = (option) => `${option.title}: ${searchInputValue}`;
  const disabledOptions = (option) => option.disabledOnSelect;
  const chipsLabel = (option) => `${option.title}: ${option.value}`;
  const inputValueChanged = (event, newInputValue) => {
    setSearchInputValue(newInputValue);
  };
  const onActionButtonChanged = (activeAction) => {
    setActiveSelectedAction(activeAction);
    setCheckedCards([]);
    setCheckedCardsIds([]);
  };

  const checkIfSensitiveField = (contactPreferenceType, item, contact_type) => {
    const isEmail = contactPreferenceType === 'Email';
    const isSMS = contactPreferenceType === 'SMS';
    const isWhatsApp = contactPreferenceType === 'WhatsApp';
    const isCall = contactPreferenceType === 'Call';

    if (+contact_type === 1) {
      if (
        (isEmail && item?.email_address?.email === '********') ||
        (isSMS && item?.mobile?.phone === '********') ||
        (isWhatsApp && (item?.whatsapp_mobile?.phone === '********' || item?.mobile?.phone === '********')) ||
        (isCall && item?.mobile?.phone === '********')
      ) {
        return false;
      }
    } else if (+contact_type === 2) {
      if (
        (isEmail && item?.general_email?.email === '********') ||
        (isSMS && item?.landline_number?.phone === '********') ||
        (isWhatsApp && item?.landline_number?.phone === '********') ||
        (isCall && item?.landline_number?.phone === '********')
      ) {
        return false;
      }
    }

    return true;
  };

  const contactPreferenceFieldThanFour = (contactPreferenceType, item, contact_type) => {
    const whatsappPolicy = contactPreferenceType?.some(c => c.value === 'WhatsApp');

    if (+contact_type === 1) {
      const hasWhatsApp = (item?.whatsapp_mobile?.phone === '********') || (item?.mobile?.phone === '********');
      if (whatsappPolicy && hasWhatsApp) {
        return false;
      }
    } else if (+contact_type === 2) {
      const hasLandline = item?.landline_number?.phone === '********';
      if (whatsappPolicy && hasLandline) {
        return false;
      }
    }

    return true;
  };



  const cardCheckboxClicked = useCallback((itemIndex, element, isContactWithTransaction) => {
    if (!isContactWithTransaction) {
      setCheckedCards((items) => {
        const index = items.findIndex((item) => item.id === element.id);
        if (index !== -1) items.splice(index, 1);
        else items.push(element);
        return [...items];
      });
      setCheckedCardsIds((items) => {
        const index = items.findIndex((item) => item === element.id);
        if (index !== -1) items.splice(index, 1);
        else items.push(element.id);
        return [...items];
      });
    } else showError(t(`${translationPath}absolete-contacts-shouldnt-have-transactions`));
  }, []);
  const onActionsButtonClicked = useCallback(
    (activeAction) => {
      if (activeAction === ActionsButtonsEnum[3].id) setIsOpenImportDialog(true);
      if (activeAction === ActionsButtonsEnum[2].id) {
        if (pathName === 'Contacts-CRM' && checkedCardsIds.length >= 2) {
          const mergeContactsInfo = {
            ContactsMergeIds: checkedCardsIds,
            isOriginalWithTransactions: checkedCards[0].isWithTransaction,
          }
          dispatch(ContactsMergeActions.contactsMergeRequest(mergeContactsInfo));
          GlobalHistory.push(
            `/home/Contacts-CRM/merge?userTypeId=${checkedCards[0].userTypeId}`
          );
        } else {
          showError(t(`${translationPath}select_another_contact_to_merge`));

        }
      }
    },
    [checkedCards, checkedCardsIds, pathName]
  );
  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        if (pathName === 'contact-sales') {
          GlobalHistory.push(
            `/home/contact-sales/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'contact-lease') {
          GlobalHistory.push(
            `/home/contact-lease/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'contact-property-management') {
          GlobalHistory.push(
            `/home/contact-property-management/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'Contacts-CRM') {
          if (localStorage.getItem('parentContact')) localStorage.removeItem('parentContact');
          localStorage.setItem('parentContact', JSON.stringify(activeData));
          GlobalHistory.push(
            `/home/Contacts-CRM/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`
          );
        } else {
          GlobalHistory.push(
            `/home/contacts/contact-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`
          );
        }
      }
    },
    [dispatch, pathName]
  );
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const reloadData = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    getContactsData();
  }, [getContactsData]);

  const onCardClick = useCallback(
    (item, selectedIndex) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveCard(item);
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (getSideMenuStatus()) setAdvanceSearchBtn(true)
      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={detailsContactsList.result[selectedIndex]}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          loginResponse={loginResponse}
          onActionClicked={detailedCardActionClicked}
          relodedata={reloadData}
          displyOpenFileButton={displyOpenFileButton}
          checkIfSensitiveField={checkIfSensitiveField}
          contactPreferenceFieldThanFour={contactPreferenceFieldThanFour}

        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [detailedCardActionClicked, detailedCardSideActionClicked,
      detailsContactsList.result, loginResponse, reloadData, advanceSearchBtn]
  );

  const GetSideMenuStatus = useCallback(() => {
    let isMenuOpen = getSideMenuStatus();
    let activeComponentOpen = getSideMenuComponent() && getSideMenuComponent().type;

    if (!isMenuOpen) setAdvanceSearchBtn(true);
    else if (activeComponentOpen === AdvanceSearch) setAdvanceSearchBtn(false);
  });

  const searchAdvanceSearch = (newFilter) => 
  {
    const searchObj = (newFilter &&  {...newFilter}) || null ; 
        setContactTableFilter(searchObj) ; 
    if (!(searchObj && searchObj.length)) {
      localStorage.removeItem('ContactFilter');
    }
       dispatch(
        GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
           ...advanceSearch,
          ContactFilter: searchObj || null  
        })
      );
     
  };

  const onAdvanceSearchClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    if (advanceSearchBtn) {
      sideMenuIsOpenUpdate(true);
      setAdvanceSearchBtn(false);
    } else {
      sideMenuIsOpenUpdate(false);
      setAdvanceSearchBtn(true);
    }
    sideMenuComponentUpdate(
      <AdvanceSearchSecondVersion
        filterData={[...ContactsTableHeaderData, ...allFormFields]}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        getContactsData={getContactsData}
        onFilterValuesChanged={onFilterValuesChanged}
        localStorageKey='ContactFilter'
        isAdvanceSearchCleared={isAdvanceSearchCleared}
        setIsAdvanceSearchCleared={setIsAdvanceSearchCleared}
        setOrderBy={setOrderBy}
        searchCriteriaTypeId={SearchCriteriaEnum.Contact.typeId}
        setIsAdvanceSearchActive={setIsAdvanceSearchActive}
        fields={[...ContactsTableHeaderData, ...allFormFields]}
        selectFieldsOnAdvanceSearch= {selectFieldsOnAdvanceSearch}
        setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
        setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
        filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
        searchAdvanceSearch={searchAdvanceSearch}
      />
    );
  }, [isAllFormFieldsLoading, allFormFields, advanceSearchBtn, isAdvanceSearchCleared , selectFieldsOnAdvanceSearch]);


  const onClearedAllFiltersClick = useCallback(() => {

    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: "createdOn", orderBy: 2 })
    setSearchData([]);
    setDateFilter(dateRangeDefault);
    setLeadClassesFilter([]);
    setContactClassesFilter([]) ; 
    setContactClasses([]) ; 
    changeActiveFormType(0);
    setActiveFormType(0);
    setSearchedItem('');
    setSelectedSeachIn([]);
    setIsClearFiltersClicked(true);
    setIsAdvanceSearchCleared(true);
    setContactTableFilter(null);
    setFilterSearchDto(null);
    sideMenuIsOpenUpdate(false);
    setIsAdvanceSearchActive(false);
    setFilterValuesFromAdvanceSearch() ; 
    setSelectFieldsOnAdvanceSearch([]) ; 
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        activeFormType: 0,
        LeadClasses: [],
        ContactClass: [] , 
        contactsCrmFilter: {
          filterBy: null,
          orderBy: null,
        },
      })
    );
    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        ContactFilter: null
      })
    );
  }, []);
  
  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    Promise.all([
      await GetAllFormFieldsByFormId(FormsIdsEnum.contactsIndividual.id),
      await GetAllFormFieldsByFormId(FormsIdsEnum.contactsCorporate.id),
    ])
      .then((result) => {
        if (Array.isArray(result[0]) && Array.isArray(result[1])) {
          const concantinateFields = result[0]
            .concat(result[1])
            .filter(
              (field, index, array) =>
                array.findIndex((element) => element.formFieldKey === field.formFieldKey) === index
            );
          const list = concantinateFields.filter(e =>
            // e.formFieldName !== 'mobile' &&
            e.formFieldName !== 'company_logoimage' &&
            e.formFieldName !== 'contact_image' &&
            e.formFieldName !== 'contact_classifications' &&
            e.formFieldName !== 'contact_preference' &&
            e.formFieldName !== 'map' &&
            e.formFieldName !== 'contacts_person'
          ).map((field) => ({
            id: field.formFieldId || null,
            key: field.formFieldKey || null,
            isDate: field.uiWidgetType === 'alt-date' || false,
            label: (field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
            input: field.displayPath || '',
            isNumber:
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size'
            ,
            isSortable: true,
            searchableKey: field.searchableKey,
            data :JSON.parse(field.propertyJson).schema  ,
            fieldType : 
             (field.propertyJson && JSON.parse(field.propertyJson).schema.uiType) ||
             (field.propertyJson && JSON.parse(field.propertyJson).uiSchema['ui:widget'])  ||
             (field.uiWidgetType === null && 'text' ) ,
           
          })); 
          setAllFormFields([...list]);
        } else setAllFormFields([]);
        setIsAllFormFieldsLoading(false);
      })
      .catch(() => {
        setAllFormFields([]);
        setIsAllFormFieldsLoading(false);
      });
    setIsAllFormFieldsLoading(false);

  }, []);



  const onRowClicked = useCallback(
    (item, rowIndex) => {
      setActiveCard(item);
      sideMenuComponentUpdate(
        <CardDetailsComponent
          relodedata={reloadData}
          loginResponse={loginResponse}
          onActionClicked={detailedCardActionClicked}
          activeData={detailsContactsList.result[rowIndex]}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          displyOpenFileButton={displyOpenFileButton}
          checkIfSensitiveField={checkIfSensitiveField}
          contactPreferenceFieldThanFour={contactPreferenceFieldThanFour}


        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [detailedCardActionClicked, detailedCardSideActionClicked,
      detailsContactsList.result, loginResponse, reloadData]
  );

  const getIsContactClassChecked = (item) =>
    contactClassesFilter && contactClassesFilter.findIndex((element) => element === item.value) !== -1;
 

  const onFormTypeSelectChanged = (formType) => {
    if (pathName === 'contact-sales')
      GlobalHistory.push(`/home/contact-sales/add?formType=${formType}`);
    else if (pathName === 'contact-lease')
      GlobalHistory.push(`/home/contact-lease/add?formType=${formType}`);
    else if (pathName === 'contact-property-management')
      GlobalHistory.push(`/home/contact-property-management/add?formType=${formType}`);
    else if (pathName === 'Contacts-CRM')
      GlobalHistory.push(`/home/Contacts-CRM/add?formType=${formType}`);
    else GlobalHistory.push(`/home/contacts/add?formType=${formType}`);
  };
  const onFilterValuesChanged = (newValue) => 
  {

     setContactTableFilter(newValue) ; 
    if (!(newValue && newValue.length)) {
      localStorage.removeItem('ContactFilter');
    }
  };
  const getIsChecked = (item) =>
    selectedSeachIn.findIndex((element) => element === item.key) !== -1;

  useEffect(() => {
    getAllSearchableFormFieldsByFormId();
  }, [filterFormType, getAllSearchableFormFieldsByFormId]);

  useEffect(() => {
    if (returnPropsByPermissions(ContactsPermissions.ViewAndSearchContacts.permissionsId)) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={detailsContactsList.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }

  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  useEffect(() => {
    const data = localStorage.getItem('ContactFilter');

    if (data) {
      setSearchData(JSON.parse(data));
      searchchachedClickedWithoutFilter(JSON.parse(data));
    } else
      getContactsData();
  }, []);

  useEffect(() => {
    if (isFirst1) {
      if (searchData && searchData.length === 0) {
        localStorage.removeItem('ContactFilter');

        setSearchedItem('');
        // getContactsData();
      } else{
        localStorage.setItem('ContactFilter', JSON.stringify(searchData));

      }
       
    }
    else
      setFirst1(true);
  }, [searchData]);

  useEffect(() => {
    if (!isFirst) setFirst(true);
    else {
      const data = localStorage.getItem('ContactFilter');
      if (data)
        searchchachedClickedWithoutFilter(JSON.parse(data));
      else if (searchData && searchData.length === 0)
        getContactsData();
    }
  }, [activeFormType, contactTableFilter ,filter, filterSearchDto, orderBy, LeadClassesFilter, searchData , contactClassesFilter ]);


  useEffect(() => {
    setDisplyOpenFileButton(havePermissionToViewDetails(ContactsVerticalTabsData));
  }, [isFirst]);

  useEffect(() => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ContactsFilterDate: dateFilter || dateRangeDefault
      })
    );
    // getContactsData();
  }, [dateFilter]);

  useEffect(() => {
    if (isFirst && isDatePickerChanged) {

      const searchDataFilter = searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
      getContactsData(searchDataFilter);
    }
  }, [dateFilter, isDatePickerChanged, searchData]);

  
  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId])

  useEffect(() => {
    GetSideMenuStatus();
  }, [GetSideMenuStatus]); 

  return (
    <div className='view-wrapper contacts-view'>
      <Spinner isActive={isLoading} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section'>
              <ActionsButtonsComponent
                permissionsList={Object.values(ContactsPermissions)}
                addPermissionsId={ContactsPermissions.AddNewContact.permissionsId}
                selectPermissionsId={[
                  ContactsPermissions.ImportContact.permissionsId,
                  ContactsPermissions.MergeContacts.permissionsId,
                  ContactsPermissions.AddNewContact.permissionsId
                ]}
                enableMerge={getIsAllowedPermission(
                  Object.values(ContactsPermissions),
                  loginResponse,
                  ContactsPermissions.MergeContacts.permissionsId
                )}
                enableImport={getIsAllowedPermission(
                  Object.values(ContactsPermissions),
                  loginResponse,
                  ContactsPermissions.ImportContact.permissionsId
                )}
                withType
                typeData={[
                  { id: '1', name: 'individual' },
                  { id: '2', name: 'corporate' },
                ]}
                onFormTypeSelectChanged={onFormTypeSelectChanged}
                onActionsButtonClicked={onActionsButtonClicked}
                onActionButtonChanged={onActionButtonChanged}
              />

            </div>
            <div className='section autocomplete-section'>
              <PermissionsComponent
                permissionsList={Object.values(ContactsPermissions)}
                permissionsId={ContactsPermissions.ViewAndSearchContacts.permissionsId}
              >
                <div className='d-flex-column px-2 w-100 p-relative'>
                  <div className='mb-1'>
                    <AutocompleteComponent
                      data={
                        searchableFormFields &&
                        searchableFormFields.map((item) => ({
                          key: item.key,
                          title: item.title,
                        }))
                      }
                      wrapperClasses='autocomplete-with-btn'
                      selectedValues={searchData}
                      parentTranslationPath='ContactsView'
                      displayLabel={displayedLabel}
                      disabledOptions={disabledOptions}
                      onChange={filterOnChange}
                      searchClicked={searchClicked}
                      chipsLabel={chipsLabel}
                      getOptionSelected={(option) =>
                        searchData.findIndex(
                          (item) => item.key === option.key && item.value === searchInputValue
                        ) !== -1}
                      tagValues={searchData}
                      inputValue={searchInputValue}
                      onInputChange={inputValueChanged}
                      inputLabel='filter'
                      inputPlaceholder='search-contacts'
                    />
                  </div>
                  <div className='d-flex-v-center-h-between pl-5-reversed'>
                    <div className='mr-1-reversed'>
                      <SelectComponet
                        data={Object.values(FormsIdsEnum).filter((item) => item.page === 'contacts')}
                        emptyItem={{ value: 0, text: 'select-type', isDisabled: false }}
                        value={activeFormType}
                        valueInput='id'
                        textInput='name'
                        onSelectChanged={changeActiveFormType}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='Contact_Type'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>

                    <div className='mr-1-reversed'>
                      <SelectComponet
                        getIsChecked={getIsChecked}
                        singleIndeterminate={
                          selectedSeachIn &&
                          selectedSeachIn.length > 0 &&
                          Object.values(LeadsClassTypesEnum).length > 0 &&
                          Object.values(LeadsClassTypesEnum).length > selectedSeachIn.length
                        }
                        singleChecked={
                          selectedSeachIn &&
                          selectedSeachIn.length > 0 &&
                          Object.values(LeadsClassTypesEnum).length === selectedSeachIn.length - 1
                        }
                        renderValue={(value) =>
                          (selectedSeachIn.length > 0 && (
                            <span>
                              {value.map((option, mapIndex) => {
                                let toReturn = '';
                                const optionIndex = Object.values(LeadsClassTypesEnum).findIndex((element) => element.key === option);
                                if (optionIndex !== -1) {
                                  toReturn += Object.values(LeadsClassTypesEnum)[optionIndex].value;
                                  if (mapIndex < value.length - 1) toReturn += ', ';
                                }
                                return toReturn;
                              })}
                            </span>
                          )) || <span>{t(`${translationPath}select-Leads-Class`)}</span>}
                        data={Object.values(LeadsClassTypesEnum)}
                        value={LeadClassesFilter}
                        multiple
                        isWithCheckAll
                        valueInput='key'
                        keyLoopBy='key'
                        textInput='value'
                        onSelectChanged={seachInChangedHandler}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='headerSearchRef'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <div className='mr-1-reversed'>
                      <SelectComponet
                       getIsChecked={getIsContactClassChecked}
                        singleIndeterminate={
                          contactClasses &&
                          contactClasses.length > 0 &&
                          Object.values(ContactClassTypesEnum).length > 0 &&
                          Object.values(ContactClassTypesEnum).length > contactClasses.length
                        }
                        renderValue={(value) =>
                           (contactClasses.length > 0 && (
                            <span>
                              {value.map((option, mapIndex) => {
                                let toReturn = '';
                                const optionIndex = Object.values(ContactClassTypesEnum).findIndex((element) => element.value === option);
                                if (optionIndex !== -1) {
                                  toReturn += Object.values(ContactClassTypesEnum)[optionIndex].value;
                                  if (mapIndex < value.length - 1) toReturn += ', ';
                                }
                                return toReturn;
                              })}
                            </span>
                          )) || <span>{t(`${translationPath}select-contact-class`)}</span>}
                          singleChecked={
                          contactClasses &&
                          contactClasses.length > 0 &&
                          Object.values(ContactClassTypesEnum).length === contactClasses.length - 1
                        }
                        data={Object.values(ContactClassTypesEnum)}
                        value={contactClassesFilter}
                        isWithCheckAll
                        valueInput='value'
                        multiple={true}
                        keyLoopBy='value'
                        textInput='value'
                        onSelectChanged={changeContactClass}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='changeContactClassRef'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                        
                      />
                    </div>


                    <div className='w-100 mt-2'>
                      <Inputs
                        value={searchedItem}
                        onKeyUp={(e) => searchHandler(e.target.value)}
                        idRef='activitiesSearchRef'
                        labelClasses='mt-4'
                        onInputChanged={(e) => setSearchedItem(e.target.value)}
                        inputPlaceholder={t(`${translationPath}search-Mobile-Email-ID`)}
                      />

                    </div>
                    <div className='w-100 customDatePicker'>
                      <div className='date-type-select'>
                        <SelectComponet
                          data={[
                            { key: 1, value: `${translationPath}created-date` },
                            { key: 2, value: `${translationPath}update-on-date` }
                          ]}
                          defaultValue={{ key: 1, value: `${translationPath}created-date` }}
                          value={dateFilter.selectedDateType || 1}
                          valueInput='key'
                          textInput='value'
                          isDisabled={isLoading}
                          onSelectChanged={(value) => {
                            setIsDatePickerChanged(true)
                            setDateFilter((f) => ({ ...f, selectedDateType: value }));
                          }}
                          wrapperClasses='w-auto'
                          themeClass='theme-transparent'
                          idRef='Date_Select'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <DateRangePickerComponent
                        onClearClicked={() => {
                          setDateFilter(dateRangeDefault)
                          setIsDatePickerChanged(true)
                        }}
                        onDialogClose={() => {
                          setIsDatePickerChanged(true)
                        }}
                        ranges={[dateFilter]}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(selectedDate) => {
                          setDateFilter((item) => ({
                            ...item,
                            startDate: selectedDate.selection && selectedDate.selection.startDate,
                            endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                            key: 'selection',
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='view-search-wrapper'>
                  <ViewTypes onTypeChanged={onTypeChanged} initialActiveType={activeActionType} className='mb-3' />
                  <span className='advance-search'>
                    <ButtonBase
                      onClick={onAdvanceSearchClick} id='AdvanceSearch_bbt'
                      disabled={isAllFormFieldsLoading}
                      className={`btns theme-solid ${isAdvanceSearchActive && !isAllFormFieldsLoading && 'is-active-filter'}`}
                    >
                      <span className={`${advanceSearchBtn ? 'mdi mdi-arrow-expand-left' : 'mdi mdi-arrow-expand-right'} mr-1`} />
                      {t(`${translationPath}advance-search`)}
                    </ButtonBase>
                  </span>
                  <ButtonBase
                    onClick={onClearedAllFiltersClick} id='onClearedAllFiltersref'
                    disabled={isAllFormFieldsLoading}
                    className='btns theme-solid bg-danger clear-all-btn'
                  >
                    <span className='mdi mdi-filter-remove m-1' />
                    {t(`${translationPath}clear-filters`)}

                  </ButtonBase>
                </div>
              </PermissionsComponent>
            </div>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(ContactsPermissions)}
            permissionsId={ContactsPermissions.ViewAndSearchContacts.permissionsId}
          >
            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}contacts`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}
                  :
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='filterByRef'
                    data={[
                      { id: 'createdOn', filterBy: 'created-on' },
                      { id: 'updateOn', filterBy: 'last-updated' },
                    ]}
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={filterByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='filterBy'
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    emptyItem={{ value: null, text: 'select-sort-by', isDisabled: false }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    onClick={orderBySubmitted}
                    id='action_apply'
                    disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                  {activeActionType !== ViewTypesEnum.tableView.key && activeSelectedAction === 'merge' && (
                    <span className='fw-simi-bold'>
                      <span>
                        {t('Shared:Select')}
                        {' '}
                        :
                        {' '}
                        {checkedCards?.length || 0}
                      </span></span>) || ''}
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>
        {
          activeActionType !== ViewTypesEnum.tableView.key && (
            <PermissionsComponent
              permissionsList={Object.values(ContactsPermissions)}
              permissionsId={ContactsPermissions.ViewAndSearchContacts.permissionsId}
            >
              <div className='body-section'>
                <ContactsCardsComponent
                  data={detailsContactsList}
                  isExpanded={isExpanded}
                  onCardClicked={onCardClick}
                  onFooterActionsClicked={detailedCardSideActionClicked}
                  onActionClicked={detailedCardActionClicked}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  withCheckbox={activeSelectedAction === 'merge'}
                  onCardCheckboxClick={cardCheckboxClicked}
                  activeCard={activeCard}
                  selectedCards={checkedCards}
                  isCheckBoxDisabled={
                    activeSelectedAction === 'merge' ? checkedCards.length >= 10 : false
                  }
                  displyOpenFileButton={displyOpenFileButton}
                  checkIfSensitiveField={checkIfSensitiveField}
                  contactPreferenceFieldThanFour={contactPreferenceFieldThanFour}
                />
              </div>
            </PermissionsComponent>
          )
        }
        {
          // loginResponse &&
          //   loginResponse.permissions &&
          //   loginResponse.permissions
          //     .map((item) => item.permissionsId ===
          //  ContactsPermissions.ReadContacts.permissionsId)
          //     .includes(true) &&
          activeActionType === ViewTypesEnum.tableView.key && (
            <PermissionsComponent
              permissionsList={Object.values(ContactsPermissions)}
              permissionsId={ContactsPermissions.ViewAndSearchContacts.permissionsId}
            >
              <ContactsTableComponent
                filter={filter}
                pathName={pathName}
                reloadData={reloadData}
                onRowClick={onRowClicked}
                checkedCards={checkedCards}
                setCheckedCards={setCheckedCards}
                checkedCardsIds={checkedCardsIds}
                onActionClicked={detailedCardActionClicked}
                contactTableFilter={contactTableFilter}
                onFilterValuesChanged={onFilterValuesChanged}
                onPageSizeChanged={onPageSizeChanged}
                setCheckedCardsIds={setCheckedCardsIds}
                onPageIndexChanged={onPageIndexChanged}
                detailsContactsList={detailsContactsList}
                activeSelectedAction={activeSelectedAction}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setOrderBy={setOrderBy}
                isWithCheckboxColumn={activeSelectedAction === 'merge'}
                isClearFiltersClicked={isClearFiltersClicked}
                setIsClearFiltersClicked={setIsClearFiltersClicked}
                displyOpenFileButton={displyOpenFileButton}
                localStorageKey='ContactFilter'
                checkIfSensitiveField={checkIfSensitiveField}
                contactPreferenceFieldThanFour={contactPreferenceFieldThanFour}
              />
            </PermissionsComponent>
          )
        }
      </div >
      <ContactsImportDialog
        isOpen={isOpenImportDialog}
        isOpenChanged={() => setIsOpenImportDialog(false)}
      />
      <ContactsActionDialogsComponent
        isOpen={isOpenContactsActionDialog}
        isOpenChanged={() => setisOpenContactsActionDialog(false)}
        actionEnum={detailedCardAction.actionEnum}
        item={detailedCardAction.item}
        translationPath=''
        parentTranslationPath='ContactsView'
      />
      <DialogComponent
        saveText='confirm'
        titleText='Call Server'
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <iframe className='maqsamiframeClass' id="maqsamiframe" width="500" height="600" allow="microphone"></iframe>
          </div>
        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={isOpenMaqsamiframe}
        onCloseClicked={() => { setisOpenMaqsamiframe(false); }}
        onCancelClicked={() => { setisOpenMaqsamiframe(false); }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div >
  );
};
