import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  TabsComponent,
  RadiosGroupComponent,
  Spinner,
  CompletedDataComponent,
  SelectComponet, CollapseComponent, PermissionsComponent
} from '../../../../Components';
import { AMLStatusEnum, ActionsEnum, UnitsStatusEnum, FormsIdsEnum, UnitsOperationTypeEnum } from '../../../../Enums';
import {
  GetParams, GlobalHistory, sideMenuComponentUpdate, returnPropsByPermissions
} from '../../../../Helper';
import { GetAllTemplatesByUnitId, unitDetailsGet, UnitsAdvanceSearchTest, GetSensitiveFieldsForUser, GetSuitableTemplateIdForUnit } from '../../../../Services';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { PermissionDeniedLayout } from '../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout';
import { UnitsVerticalTabsData, UnitsVerticalTabsDataUnitsPropertyManagement } from '../../Common/OpenFileView/OpenFileUtilities/OpenFileData';
import { UnitMapper } from '../../UnitsView/UnitMapper';
import { CardDetailsComponent } from '../UnitsLeaseUtilities';
import {
  UnitProfileMatchingComponent,
} from './Sections';
import { UnitPermissions, UnitsLeasePermissions } from '../../../../Permissions';
import { ContactsActionDialogsComponent } from '../../ContactsView/ContactsUtilities/ContactsActionDialogsComponent/ContactsActionDialogsComponent';
import { UnitTemplateDialogPreviewDialog } from '../../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog';

const parentTranslationPath = 'UnitsProfileManagementView';
const translationPath = '';
export const UnitsLeaseProfileManagementView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [displayDetailsToUserDependsOnPolicy, setDisplayDetailsToUserDependsOnPolicy] = useState(null);
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [unitData, setUnitData] = useState(null);
  const [SensitiveLoading, SetSensitiveLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isSensitiveLoading, setIsSensitiveLoading] = useState(0);


  const [unitWithPolicy, setUnitWithPolicy] = useState(null);
  const [unitEmptyFields, setUnitEmptyFields] = useState(null);
  const [sensitiveFieldsForUser, setSensitiveFieldsForUser] = useState([]);

  const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];
  const [activeTab, setActiveTab] = useState(0);
  const [viewType, setViewType] = useState(1);
  const [templates, setTemplates] = useState([]);
  const [activeTemplateId, setActiveTemplateId] = useState(-1);
  const [activeTemplateName, setActiveTemplateName] = useState(null);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const [isOpenContactsActionDialog, setIsOpenContactsActionDialog] = useState(false);
  const [templateFile, setTemplateFile] = useState(null);
  const [dialogEnum, setDialogEnum] = useState(null);
  const [showPopover, setshowPopover] = useState(false);
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 9999,
  });
  const [list, setList] = useState([]);
  const isPropertyManagementView = (pathName === 'units-property-management/unit-profile-edit');
  const [unitType, setUnitType] = useState(isPropertyManagementView ? 'propertyManagement' : 'lease');
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);

  useEffect(() => {
    if (pathName === 'units-property-management/unit-profile-edit')
      setList([...UnitsVerticalTabsDataUnitsPropertyManagement]);
    else
      setList([...UnitsVerticalTabsData.rent]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [filterBy, setFilterBy] = useState({
    id: GetParams('id'),
    formType: GetParams('formType'),
  });
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  useEffect(() => {
    setActiveTab(GetParams('matching') === 'true' ? 14 : 0);
  }, []);

  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };
  useEffect(() => {
    setFilterBy({
      formType: GetParams('formType'),
      id: GetParams('id'),
    });
  }, [location]);
  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      event.stopPropagation();
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      if (actionEnum === ActionsEnum.reportEdit.key) {
        if (pathName === 'units-property-management') {
          GlobalHistory.push(
            `/home/units-property-management/edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'units-lease') {
          GlobalHistory.push(
            `/home/units-lease/edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        }
      } else if (actionEnum === ActionsEnum.folder.key) {
        if (pathName === 'units-property-management') {
          GlobalHistory.push(
            `/home/units-property-management/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'units-lease') {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        }

        // setSelectedDetailsUnitItem(activeData);
        // setOpenFileIsOpen(true);
      }
    },
    [dispatch, pathName]
  );

  const reloadData = () => {
    userHasPolicyToDisplayData(+GetParams('id'));
    GetSensitiveFieldsForUserAPI();

  };
  const getAllTemplatesByCategoriesId = useCallback(async () => {
    if (isPropertyManagementView) {
      if (!returnPropsByPermissions(UnitPermissions.ViewUnitTemplates.permissionsId))
        return;
    }
    setIsTemplatesLoading(true)
    const res = await GetAllTemplatesByUnitId(filterBy.id, UnitsOperationTypeEnum.rent.key);
    if (!((res && res.data && res.data.ErrorId) || !res)) setTemplates(res || []);
    else setTemplates([]);

    setIsTemplatesLoading(false)
  }, [activeItem.operationType, filterBy.id, isPropertyManagementView]);

  useEffect(() => {
    const unitId = +GetParams('id');
    if (unitId && activeTab === 0)
      userHasPolicyToDisplayData(unitId);
  }, [activeTab]);

  useEffect(() => {
    const unitId = +GetParams('id');
    if (unitId && displayDetailsToUserDependsOnPolicy === 'allowed' && activeTab === 0)
      GetSensitiveFieldsForUserAPI();

  }, [activeTab, displayDetailsToUserDependsOnPolicy, activeTab]);

  useEffect(() => {
    if (unitData !== null) {
      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={unitData}
          from={2}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          parentTranslationPath='UnitsView'
          translationPath={translationPath}
          reloadData={() => reloadData()}
        />
      );
      // sideMenuIsOpenUpdate(true);
    } else sideMenuComponentUpdate(null);
  }, [unitData, detailedCardSideActionClicked]);
  useEffect(() => {
    if ((!isTemplatesLoading && templates && templates.length == 0) &&
      activeItem &&
      activeItem.unitStatus &&
      activeItem.unitStatus.key &&
      Object.values(UnitsStatusEnum).filter(
        (item) => item.key === activeItem.unitStatus.key && (item.category || item.leaseCategory)
      ).length > 0
    ) getAllTemplatesByCategoriesId();
  }, [activeItem, getAllTemplatesByCategoriesId]);

  const userHasPolicyToDisplayData = useCallback(async (unitId) => {
    setIsLoading(true);
    const isForLog = true;

    const body = {
      OperationType: 'Lease',
      criteria: {
        unitId: [{ searchType: 1, value: unitId }]
      },
      filterBy: 'createdOn',
      orderBy: 2,
    };

    const res = await UnitsAdvanceSearchTest({ pageIndex: 0, pageSize: 2, isForLog }, body);
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.totalCount > 0) {
        setDisplayDetailsToUserDependsOnPolicy('allowed');
        if (res && res.result && res.result.length && res.result[0] && res.result[0].unitJson) {
          const unitJson = JSON.parse(res && res.result && res.result.length && res.result[0].unitJson);
          const unitDatails = UnitMapper({ ...unitJson, ...res.result[0] });
          localStorage.setItem('unitModelRelatedData', JSON.stringify(unitDatails));
          dispatch(ActiveItemActions.activeItemRequest(unitDatails));
          setUnitData(unitDatails);
          setUnitEmptyFields(JSON.parse(res && res.result && res.result.length && res.result[0].unitJson));
          setUnitWithPolicy(unitJson);
        }
      }
      else {
        setUnitWithPolicy(null);
        setUnitData(null);
        setUnitEmptyFields(null);
        setDisplayDetailsToUserDependsOnPolicy('notAllowed');
      }
    } else {
      setUnitWithPolicy(null);
      setUnitData(null);
      setUnitEmptyFields(null);
      setDisplayDetailsToUserDependsOnPolicy('notAllowed');
    }

    setIsLoading(false);
  });

  const GetSensitiveFieldsForUserAPI = useCallback(async () => {
    SetSensitiveLoading(true);
    const unitId = +GetParams('id');
    const allSensitiveFields = await GetSensitiveFieldsForUser(FormsIdsEnum.units.id, { key: 'unit', id: (activeItem && activeItem.id) });

    if (!(allSensitiveFields && allSensitiveFields.status && allSensitiveFields.status !== 200)) {
      setSensitiveFieldsForUser(allSensitiveFields);
      setIsSensitiveLoading(1);

    } else {
      setSensitiveFieldsForUser([]);
      setIsSensitiveLoading(2);

    }

    SetSensitiveLoading(false);
  }, []);

  useEffect(() => {
    const unitId = +GetParams('id');
    if (unitId && activeTab === 0)
      userHasPolicyToDisplayData(unitId);
  }, [activeTab]);

  useEffect(() => {
    const unitId = +GetParams('id');
    if (unitId && displayDetailsToUserDependsOnPolicy === 'allowed' && activeTab === 0)
      GetSensitiveFieldsForUserAPI();


  }, [activeTab, displayDetailsToUserDependsOnPolicy, activeTab]);


  const GetSuitableTemplateIdForUnitApi = useCallback(async () => {
    const res = await GetSuitableTemplateIdForUnit(filterBy.id, 1, 10000 , UnitsOperationTypeEnum.rent.key);
    if (!((res && res.data && res.data.ErrorId) || !res)) setTemplatesProposal(res&&res.result || []);
    else setTemplatesProposal([]);
  }, [filterBy.id]);

  useEffect(() => {
    GetSuitableTemplateIdForUnitApi();
  }, [GetSuitableTemplateIdForUnitApi]);



  return (
    <div className='units-profile-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      {
        displayDetailsToUserDependsOnPolicy === 'allowed' && (
          <div className='d-flex-v-center-h-between flex-wrap'>
            <div className='d-inline-flex-v-center flex-wrap'>
              <div className='d-inline-flex-v-center'>
                {isPropertyManagementView && (
                  <PermissionsComponent
                    permissionsList={Object.values(UnitPermissions)}
                    permissionsId={UnitPermissions.ViewUnitTemplates.permissionsId}
                  >
                    <SelectComponet
                      data={templates}
                      keyLoopBy='templateId'
                      valueInput='templateId'
                      textInput='templateName'
                      value={activeTemplateId}
                      keyValue='actionsbuttons'
                      idRef='contactsActionsRef'
                      isLoading={isTemplatesLoading}
                      isDisabled={isTemplatesLoading}
                      onSelectChanged={(value) => {
                        setActiveTemplateId(value);
                        if (value !== -1) {
                          const itemIndex = templates.findIndex((item) => item.templateId === value);
                          if (itemIndex !== -1) setActiveTemplateName(templates[itemIndex].templateName);
                          setIsOpenTemplateDialog(true);
                        }
                      }}
                      themeClass='theme-action-buttons'
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                      wrapperClasses={`${isTemplatesLoading ? 'disable-style' : ''} bg-secondary c-white mx-2`}
                      parentTranslationPath={parentTranslationPath}
                      emptyItem={{ value: -1, text: 'template', isHiddenOnOpen: true }}
                    />
                    <SelectComponet
                      data={templatesProposal}
                      keyLoopBy='systemTemplateId'
                      valueInput='systemTemplateId'
                      textInput='systemTemplateName'
                      value={activeTemplateId}
                      keyValue='actionsbuttons'
                      idRef='contactsActionsRef'
                      onSelectChanged={(value) => {
                        setActiveTemplateId(value);
                        if (value !== -1) {
                          const itemIndex = templates.findIndex((item) => item.systemTemplateId === value);
                          if (itemIndex !== -1) setActiveTemplateName(templates[itemIndex].systemTemplateName);
                          setIsOpenTemplateDialog(true);
                        }
                      }}
                      themeClass='theme-action-buttons'
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                      wrapperClasses='bg-secondary c-white mx-2'
                      parentTranslationPath={parentTranslationPath}
                      emptyItem={{ value: -1, text: 'templateProposal', isHiddenOnOpen: true }}
                    />
                  </PermissionsComponent>
                )}
                {!isPropertyManagementView && (
                  <PermissionsComponent
                    permissionsList={Object.values(UnitsLeasePermissions)}
                    permissionsId={UnitsLeasePermissions.ViewUnitTemplates.permissionsId}
                  >
                    <SelectComponet
                      data={templates}
                      keyLoopBy='systemTemplateId'
                      valueInput='systemTemplateId'
                      textInput='systemTemplateName'
                      value={activeTemplateId}
                      keyValue='actionsbuttons'
                      idRef='contactsActionsRef'
                      isLoading={isTemplatesLoading}
                      isDisabled={isTemplatesLoading}
                      onSelectChanged={(value) => {
                        setActiveTemplateId(value);
                        if (value !== -1) {
                          const itemIndex = templates.findIndex((item) => item.systemTemplateId === value);
                          if (itemIndex !== -1) setActiveTemplateName(templates[itemIndex].systemTemplateName);
                          setIsOpenTemplateDialog(true);
                        }
                      }}
                      themeClass='theme-action-buttons'
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                      wrapperClasses={`${isTemplatesLoading ? 'disable-style' : ''} bg-secondary c-white mx-2`}
                      parentTranslationPath={parentTranslationPath}
                      emptyItem={{ value: -1, text: 'template', isHiddenOnOpen: true }}
                    />
                    <SelectComponet
                      data={templatesProposal}
                      keyLoopBy='systemTemplateId'
                      valueInput='systemTemplateId'
                      textInput='systemTemplateName'
                      value={activeTemplateId}
                      keyValue='actionsbuttons'
                      idRef='contactsActionsRef'
                      onSelectChanged={(value) => {
                        setActiveTemplateId(value);
                        if (value !== -1) {
                          const itemIndex = templates.findIndex((item) => item.systemTemplateId === value);
                          if (itemIndex !== -1) setActiveTemplateName(templates[itemIndex].systemTemplateName);
                          setIsOpenTemplateDialog(true);
                        }
                      }}
                      themeClass='theme-action-buttons'
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                      wrapperClasses='bg-secondary c-white mx-2'
                      parentTranslationPath={parentTranslationPath}
                      emptyItem={{ value: -1, text: 'templateProposal', isHiddenOnOpen: true }}
                    />
                  </PermissionsComponent>
                )}

                <ButtonBase
                  disabled={
                    activeItem &&
                    activeItem.leaseUnitStatusDetails &&
                    activeItem.leaseUnitStatusDetails.value &&
                    (activeItem.leaseUnitStatusDetails.value.toLowerCase() === 'draft' ||
                      activeItem.leaseUnitStatusDetails.value.toLowerCase() === 'available')
                  }
                  onClick={() =>
                    GlobalHistory.push(`/home/units-lease/unit-profile-reservation?id=${activeItem.id}`)}
                  className={`body-status btns ${(activeItem.leaseUnitStatusDetails && activeItem.leaseUnitStatusDetails.classes) ||
                    AMLStatusEnum[activeItem.leaseUnitStatusDetails] && AMLStatusEnum[activeItem.leaseUnitStatusDetails].name ||

                    'bg-warning'
                    } bg-secondary-light px-4`}
                >
                  <span className='body-status c-primary'>
                    {(activeItem &&
                      activeItem.leaseUnitStatusDetails &&
                      activeItem.leaseUnitStatusDetails.value &&
                      t(`${translationPath}${activeItem.leaseUnitStatusDetails.value}`)) ||
                      (t(`Shared:${AMLStatusEnum[activeItem.leaseUnitStatusDetails] && AMLStatusEnum[activeItem.leaseUnitStatusDetails].name}`)) ||
                      'N/A'}

                  </span>
                </ButtonBase>
              </div>
              {activeTab === 0 && (
                <div className='d-inline-flex-v-center'>
                  <RadiosGroupComponent
                    idRef='viewDataRef'
                    data={[
                      {
                        key: 1,
                        value: 'all-data',
                      },
                      {
                        key: 2,
                        value: 'missing-data',
                      },
                    ]}
                    value={viewType}
                    labelValue='view'
                    labelInput='value'
                    valueInput='key'
                    themeClass='theme-line'
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    onSelectedRadioChanged={onViewTypeChangedHandler}
                  />
                </div>

              )}

              {/* <div className='leadOwner'>
            <span>
              {' '}
              {t(`${translationPath}leadOwner`)}
              {' '}
              {' '}
              :
              {' '}

              <span className='leadName'>
                {(activeItem && activeItem.lead_owner ? `${activeItem.lead_owner.name || ''}    ${activeItem.lead_owner.phone ? `(${activeItem.lead_owner.phone})` : ''}` : 'N/A')}
              </span>

            </span>

          </div> */}
            </div>
            <div className='d-inline-flex'>
              {/* <ButtonBase
            disabled={activeItem && activeItem.matchingLeadsNumber === 0}
            className='btns c-black-light'
            onClick={() => setActiveTab(20)}
          >
            <span className={ActionsEnum.matching.icon} />
            <span>{activeItem && activeItem.matchingLeadsNumber}</span>
            <span className='px-1'>{t(`${translationPath}matching`)}</span>
          </ButtonBase> */}
              <PermissionsComponent
                permissionsList={isPropertyManagementView ? Object.values(UnitPermissions) : Object.values(UnitsLeasePermissions)}
                permissionsId={isPropertyManagementView ? 'we need permissionsId in isProperty Management View ' : UnitsLeasePermissions.ShareUnit.permissionsId}
              >
                <ButtonBase className='btns c-black-light' onClick={() => { setshowPopover(true); }}>
                  <span className='mdi mdi-share-outline px-1' />
                  <span className='px-1'>{t(`${translationPath}share`)}</span>
                  <CollapseComponent
                    onClickOutside={() => setshowPopover(false)}
                    isOpen={showPopover}
                    classes='popover-unit-share'
                    top={30}
                    component={(
                      <div className='unit-model-items-wrapper'>
                        <ButtonBase
                          key='sms'
                          className='btns theme-transparent unit-model-item'
                          onClick={() => { setshowPopover(false); setDialogEnum('smsSolid'); }}
                        >
                          <span>sms</span>
                        </ButtonBase>
                        <ButtonBase
                          key='email'
                          className='btns theme-transparent unit-model-item'
                          onClick={() => { setshowPopover(false); setDialogEnum('emailSolid'); }}
                        >
                          <span>Email</span>
                        </ButtonBase>
                        {/* <ButtonBase
                    key='whatsapp'
                    className='btns theme-transparent unit-model-item'
                    onClick={() => { setshowPopover(false); setDialogEnum('whatsappSolid'); }}
                  >
                    <span>Whatsapp</span>
                  </ButtonBase> */}

                      </div>
                    )}
                  />
                </ButtonBase>
              </PermissionsComponent>

              {!isPropertyManagementView && (
                <PermissionsComponent
                  permissionsList={Object.values(UnitsLeasePermissions)}
                  permissionsId={UnitsLeasePermissions.ViewUnitHistory.permissionsId}
                >
                  <CompletedDataComponent operationType='lease' typeId='unit' activeItem={activeItem} completedData={activeItem && activeItem.progress} />
                </PermissionsComponent>
              )}

              {isPropertyManagementView && (
                <PermissionsComponent
                  permissionsList={Object.values(UnitPermissions)}
                  permissionsId={UnitPermissions.ViewUnitHistory.permissionsId}
                >
                  <CompletedDataComponent completedData={activeItem && activeItem.progress} />
                </PermissionsComponent>
              )}
            </div>
          </div>
        )
      }

      {
        displayDetailsToUserDependsOnPolicy === 'allowed' && (
          <TabsComponent
            data={list}
            labelInput='label'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            themeClasses='theme-solid'
            dynamicComponentProps={{
              propertyId: (unitData && unitData.property_name && unitData.property_name.id),
              activeItem,
              viewType,
              unitId: filterBy.id,
              parentTranslationPath,
              translationPath,
              unitType,
              sensitiveFieldsForUser,
              unitWithPolicy,
              isLoadingUnitData: isLoading,
              isSensitiveLoading,
              unitEmptyFields,

            }}
            currentTab={activeTab}
            onTabChanged={onTabChanged}
          />
        )
      }
      <div className='tabs-content-wrapper'>
        {activeTab === 14 && (
          <UnitProfileMatchingComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
      </div>
      {isOpenTemplateDialog && (
        <UnitTemplateDialogPreviewDialog
          activeItem={activeTemplateId}
          unitOwner={unitData?.lead_owner}
          leaseUnitOwner={unitData?.lease_lead_owner}
          isOpen={isOpenTemplateDialog}
          unitID={+GetParams('id')}
          maintitleText={activeTemplateName}
          isOpenChanged={() => {
            setIsOpenTemplateDialog(false);
            setActiveTemplateId(-1);
          }}
          templateName={activeTemplateName}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenContactsActionDialog && templateFile && (
        <ContactsActionDialogsComponent
          isOpen={isOpenContactsActionDialog}
          unitTemplateFile={templateFile}
          isOpenChanged={() => {
            setIsOpenContactsActionDialog(false);
            setTemplateFile(null);
          }}
          actionEnum='emailSolid'
          item={null}
          translationPath=''
          parentTranslationPath='ContactsView'
        />
      )}

      <ContactsActionDialogsComponent
        isOpenChanged={() => setDialogEnum(null)}
        isOpen={dialogEnum !== null}
        translationPath=''
        parentTranslationPath='ContactsView'
        actionEnum={dialogEnum}
        unitItem={activeItem}
        showlink
      />
      {
        displayDetailsToUserDependsOnPolicy === 'notAllowed' && (<PermissionDeniedLayout />)
      }
    </div>
  );
};
