import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent } from '../../../../../../Components';
import { RecipientTypeCoreEnum, ReminderTypeEnum } from '../../../../../../Enums';

export const NotificationLineManagerComponent = ({
  parentTranslationPath,
  translationPath,
  setStateNotification,
  setlineManagerNOT,
  lineManagerNOT,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [selected, setSelected] = useState([]);


  useEffect(()=>{
    if(lineManagerNOT){

      setSelected(
        [...lineManagerNOT]
        )
      }
    
  },[lineManagerNOT])

  return (
    <div>
      <AutocompleteComponent
        idRef='Notification-by-Created-fromRef'
        selectedValues={selected || []}
        multiple
        data={Object.values(ReminderTypeEnum) || []}
        chipsLabel={(option) => t(`${option.value || ''}`)}
        displayLabel={(option) => t(`${option.value || ''}`)}
        getOptionSelected={(option) =>
          selected.findIndex((item) => item.key === option.key) !== -1 || ''}
        withoutSearchButton
        inputPlaceholder={t(`${translationPath}notification-by`)}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setStateNotification(
            newValue &&
            newValue.map((x) => ({
              notifyBy: x.key,
            }))
          );
          setlineManagerNOT(
            newValue &&
            newValue.map((x) => ({
              key: x.key,
              value: x.value,
            }))
          );
          setSelected(
            newValue &&
            newValue.map((x) => ({
              key: x.key,
              value: x.value,
            }))
          );
        }}
      />
    </div>
  );
};

NotificationLineManagerComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setStateNotification: PropTypes.func.isRequired,
};
