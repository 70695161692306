import { AssignInquiryToUnitComponent , ListingShortageInformationComponent } from '../Sections' ; 



export const ListingShortageVerticalTabsData = [
    {
      label: 'Inquiries-information',
      component: ListingShortageInformationComponent,
    },
    {
      label: 'Assigned-Units',
      component: AssignInquiryToUnitComponent ,
    },
  
  ];