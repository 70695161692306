import { LeadsAdvanceSearchTest, GetAllUnitModelByPropertyId } from '../Services';

let oldvalue = '';
let timer = null;

export const LeadsRule = async (item, value, setRerender, type) => {
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'Leads') return;
  if (item.value === '') return;
  if (value === '') return;
  if (timer !== null) clearTimeout(timer);
  if (oldvalue === value) return;
  oldvalue = value;
  let isLeadId = false;
  if (value)
    isLeadId = !isNaN(value);

  const body = {
    criteria: isLeadId ?
      {
        Ids: [{ searchType: 1, value }],
        lead_type_id: [{ searchType: 1, value: 2 }],
        'status.lookupItemName': [{ searchType: 1, value: 'open' }]

      } :
      {
        'contact_name.name': [{ searchType: 2, value }],
        lead_type_id: [{ searchType: 1, value: 2 }],
        'status.lookupItemName': [{ searchType: 1, value: 'open' }]

      },
    orderBy: 2,
    filterBy: 'createdOn',
    OperationType: (type === '1' || type === '3') ? 'Sale' : 'Lease'
  };

  timer = setTimeout(async () => {
    const rs = await LeadsAdvanceSearchTest({ pageIndex: 0, pageSize: 10 }, body);
    item.data.enum = [];
    if (!rs || !rs.result) return;

    rs.result.map((itemValue) => {
      const leadJson = JSON.parse(itemValue.leadJson);
      item.data.enum.push({
        leadId: itemValue.leadId,
        name: (leadJson && leadJson.lead && leadJson.lead.contact_name && leadJson.lead.contact_name.name),
        leadType: itemValue.leadsType,
        leadClass: (leadJson && leadJson.lead && leadJson.lead.leadClass) || null,
        budgetFrom: (leadJson && leadJson.lead && leadJson.lead.budgetFrom) || null,
        budgetTo: (leadJson && leadJson.lead && leadJson.lead.budgetTo) || null,
        bathrooms: (leadJson.lead && leadJson.lead.bathrooms && leadJson.lead.bathrooms.length > 0 && leadJson.lead.bathrooms) || null,
        bedrooms: (leadJson.lead && leadJson.lead.bedrooms && leadJson.lead.bedrooms.length > 0 && leadJson.lead.bedrooms) || null,
        sizeAreaFrom: (leadJson.lead && leadJson.lead.sizeAreaFrom) || null,
        sizeAreaTo: (leadJson.lead && leadJson.lead.sizeAreaTo) || null,
        community: (leadJson.lead && leadJson.lead.community) || null,
        property: (leadJson.lead && leadJson.lead.property_name) || null,
        primaryView: (leadJson.lead && leadJson.lead.view) || null,
        unitType: (leadJson.lead && leadJson.lead.propertyunit_type) || null,

      });
    });

    setRerender(Math.random());
  }, 500);
};

export const LeadsDefaultRule = async (item, setRerender, type) => {
  if (item.data.searchKey !== 'Leads') return;
  if (item.data.enum) return;
  const body = {
    criteria: {
      lead_type_id: [{ searchType: 2, value: 2 }],
      'status.lookupItemName': [{ searchType: 1, value: 'open' }]

    },
    orderBy: 2,
    filterBy: 'createdOn',
    OperationType: (type === '1' || type === '3') ? 'Sale' : 'Lease'

  };

  const rs = await LeadsAdvanceSearchTest({ pageIndex: 0, pageSize: 10 }, body);

  item.data.enum = [];
  if (!rs || !rs.result) return;
  rs.result.map((value) => {
    const leadJson = JSON.parse(value.leadJson);
    item.data.enum.push({
      leadId: value.leadId,
      name: (leadJson.lead && leadJson.lead.contact_name && leadJson.lead.contact_name.name),
      leadType: value.leadsType,
      leadClass: (leadJson.lead && leadJson.lead.leadClass),
      budgetFrom: (leadJson && leadJson.lead && leadJson.lead.budgetFrom) || null,
      budgetTo: (leadJson && leadJson.lead && leadJson.lead.budgetTo) || null,
      bathrooms: (leadJson.lead && leadJson.lead.bathrooms && leadJson.lead.bathrooms.length > 0 && leadJson.lead.bathrooms) || null,
      bedrooms: (leadJson.lead && leadJson.lead.bedrooms && leadJson.lead.bedrooms.length > 0 && leadJson.lead.bedrooms) || null,
      sizeAreaFrom: (leadJson.lead && leadJson.lead.sizeAreaFrom) || null,
      sizeAreaTo: (leadJson.lead && leadJson.lead.sizeAreaTo) || null,
      community: (leadJson.lead && leadJson.lead.community) || null,
      property: (leadJson.lead && leadJson.lead.property_name) || null,

    });
  });
  setRerender(Math.random());
};

export const onChangeLeadsRule = (item, value, setRerender, itemList, values, setData, formType) => {
  if (item.data.searchKey !== 'Leads') return;
  if (!value)
    return;
  const budgetFromIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'client_budget-from'));
  const budgetToIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'client_budget-to'));
  const bedroomIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'bedroom'));
  const communityIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'community'));
  const propertyIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'property'));
  const inquiryUnitModelIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'inquiry_unit-model'));
  const areaSizeFrom = itemList.indexOf(itemList.find((f) => f.field.id === 'area_size-fromsqft'));
  const areaSizeTo = itemList.indexOf(itemList.find((f) => f.field.id === 'area_size-tosqft'));
  const unitTypeIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'unit_type'));
  const primaryViewIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'primary_view'));

  if (formType === 1) {
    if (unitTypeIndex !== -1)
      setData(itemList[unitTypeIndex].field.id, (value && value.unitType) || {});

    if (primaryViewIndex !== -1)
      setData(itemList[primaryViewIndex].field.id, (value && value.primaryView) || {});

    if (communityIndex !== -1)
      setData(itemList[communityIndex].field.id, (value && value.community) || {});

    if (propertyIndex !== -1)
      setData(itemList[propertyIndex].field.id, (value && value.property) || {});

    if (budgetFromIndex !== -1) {
      setData(itemList[budgetFromIndex].field.id, (value && value.budgetFrom) || '  ');
      setData('client_budget_from', (value && value.budgetFrom) || '  ');
    }
    if (budgetToIndex !== -1) {
      setData(itemList[budgetToIndex].field.id, (value && value.budgetTo) || '  ');
      setData('client_budget_to', (value && value.budgetTo) || '  ');
    }
    if (bedroomIndex !== -1)
      setData(itemList[bedroomIndex].field.id, (value && value.bedrooms && value.bedrooms[0] === '' ? null : (value && value.bedrooms)) || '  ');
    if (areaSizeFrom !== -1) {
      setData(itemList[areaSizeFrom].field.id, (value && value.sizeAreaFrom) || '  ');
      setData('area_size_fromsqft', (value && value.sizeAreaFrom) || '  ');
    }
    if (areaSizeTo !== -1) {
      setData(itemList[areaSizeTo].field.id, (value && value.sizeAreaTo) || '  ');
      setData('area_size_tosqft', (value && value.sizeAreaTo) || '  ');
    }

    if (inquiryUnitModelIndex !== -1) {
      setData(itemList[inquiryUnitModelIndex].field.id, null);
      setData('inquiry_unit_model', {});
      itemList[inquiryUnitModelIndex].data.enum = [];
      if (value && value.property && value.property.id) {
        timer = setTimeout(async () => {
          const rs = await GetAllUnitModelByPropertyId({ propertyId: (value.property && value.property.id), pageIndex: 1, pageSize: 99999 });
          if (!rs || !rs.result) return;

          rs.result.map((element) => {
            itemList[inquiryUnitModelIndex].data.enum.push({
              name: `${element.propertyUnitModelName}`,
              area: (element.area) || null,
              bedroomNo: (element.bedroomNo) || null,
              bathroomNo: element.bathroomNo || null,
              primaryView: (element.primaryViewDto) || null,
              rentPerYearFrom: (element.rentPerYearFrom) || null,
              rentPerYearTo: (element.rentPerYearTo) || null,
              secondaryView: (element.seccondaryViewDto) || null,
              sellingPriceFrom: (element.sellingPriceFrom) || null,
              sellingPriceTo: (element.sellingPriceTo) || null
            });
          });
          setRerender(Math.random());
        }, 300);
      }
    }
  }
};
