export const ReminderForEnum = {
  Agent: {
    key: 0,
    value: 'Agent',
  },
  Lead: {
    key: 1,
    value: 'Lead',
  },
};

