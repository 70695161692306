import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { ButtonBase } from '@material-ui/core';
import {
  AutocompleteComponent, CheckboxesComponent, Inputs, SelectComponet , PermissionsComponent
} from '../../../../../../../../Components';
import { getErrorByName } from '../../../../../../../../Helper';
import { lookupItemsGet, lookupItemsGetId } from '../../../../../../../../Services';
import { UspLookupTypeIdEnum } from '../../../../../../../../Enums';
import { PropertiesPermissionsCRM  } from  '../../../../../../../../Permissions';
// import { MarketingBrochureImages } from './MarketingBrochureImages';

export const PropertyMarketingRemarks = ({
  state,
  schema,
  Fullstate,
  onStateChanged,
  isSubmitted,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath]);
  const activeItem = useSelector((statess) => statess.ActiveItemReducer);
  const fullstate = (Fullstate && Fullstate[0]) || [];
  const [usps, setUsps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [marketingWebLabelData, setMarketingWebLabelData] = useState([]);
  const [usp1, setUsp1] = useState(null);
  const [usp2, setUsp2] = useState(null);
  const [usp3, setUsp3] = useState(null);
  const [usp4, setUsp4] = useState(null);
  const getAllUsp = useCallback(async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: UspLookupTypeIdEnum.lookupTypeId,
    });
    if (!(res && res.status && res.status !== 200)) setUsps(res || []);
    else setUsps([]);
    setIsLoading(false);
  }, []);

  const getMarketingWebLabel = useCallback(async (pageIndex, pageSize, searchedItem) => {
    const result = await lookupItemsGet({
      pageIndex,
      pageSize,
      lookupTypeName: 'MarketingWebLabel',
      searchedItem,
    });

    if (!(result && result.status && result.status !== 200)) setMarketingWebLabelData(result.result);
    else setMarketingWebLabelData([]);
  }, []);

  useEffect(() => {
    if (state.marketingUsp1Id && usps && usps.length > 0) {
      setUsp1(() => {
        const uspIndex = usps.findIndex((item) => item.lookupItemId === state.marketingUsp1Id);
        return usps[uspIndex] || '';
      });
    } else if (!state.marketingUsp1Id)
      setUsp1(null);

    if (state.marketingUsp2Id && usps && usps.length > 0) {
      setUsp2(() => {
        const uspIndex = usps.findIndex((item) => item.lookupItemId === state.marketingUsp2Id);
        return usps[uspIndex] || '';
      });
    } else if (!state.marketingUsp2Id)
      setUsp2(null);

    if (state.marketingUsp3Id && usps && usps.length > 0) {
      setUsp3(() => {
        const uspIndex = usps.findIndex((item) => item.lookupItemId === state.marketingUsp3Id);
        return usps[uspIndex] || '';
      });
    } else if (!state.marketingUsp3Id)
      setUsp3(null);

    if (state.marketingUsp4Id && usps && usps.length > 0) {
      setUsp4(() => {
        const uspIndex = usps.findIndex((item) => item.lookupItemId === state.marketingUsp4Id);
        return usps[uspIndex] || '';
      });
    } else if (!state.marketingUsp4Id)
      setUsp4(null);
  }, [state, usps]);

  useEffect(() => {
    getAllUsp();
    getMarketingWebLabel(1, 50);
  }, [getAllUsp, getMarketingWebLabel]);
  return (
    <div className='property-marketing-remarks-wrapper presentational-wrapper'>
      <div className='w-100 px-2'>
        <span className='fw-simi-bold'>{t(`${translationPath}notes`)}</span>
        <span>:</span>
        <span className='px-1'>{t(`${translationPath}marketing-remarks-notes-description`)}</span>
      </div>
      <div className='w-100 px-2 mb-2'>
      <PermissionsComponent
          permissionsList={Object.values(PropertiesPermissionsCRM)}
          permissionsId={PropertiesPermissionsCRM.PublishedOnWeb.permissionsId}
       >
        <CheckboxesComponent
          idRef='publishToWebRef'
          label='publish-to-web'
          singleChecked={state.isShowInWeb}
          onSelectedCheckboxChanged={(event) =>
            onStateChanged({ id: 'isShowInWeb', value: event.target.checked })}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
        />
        </PermissionsComponent>
        {/* <CheckboxesComponent
          idRef='liderToWebRef'
          label='slider-on-web'
          singleChecked={state.isFeaturedSliderOnWeb}
          onSelectedCheckboxChanged={(event) =>
            onStateChanged({ id: 'isFeaturedSliderOnWeb', value: event.target.checked })}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
        />
        <CheckboxesComponent
          idRef='ProjectOnWebRef'
          label='Project_On_Web'
          singleChecked={state.isFeaturedProjectOnWeb}
          onSelectedCheckboxChanged={(event) =>
            onStateChanged({ id: 'isFeaturedProjectOnWeb', value: event.target.checked })}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
        /> */}
        {/* <ButtonBase
          className='btns theme-solid bg-primary'
          onClick={() => {
                setIsOpenDialog(true);
              }}
        >
          <span className='mdi mdi-plus' />
          <span className='px-1'>{t(`${translationPath}Add-new`)}</span>
        </ButtonBase> */}
      </div>
      <div className='form-item'>
        <Inputs
          idRef='propertyOverViewRef'
          labelValue='english_property-overview'
          value={state.propertyOverView || ''}
          helperText={getErrorByName(schema, 'propertyOverView').message}
          error={getErrorByName(schema, 'propertyOverView').error}
          isWithError
          isWithCharactersCounter
          isSubmitted={isSubmitted}
          multiline
          rows={3}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'propertyOverView', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='arabicPropertyOverviewRef'
          labelValue='arabic_property-overview'
          value={state.arabicPropertyOverview || ''}
          helperText={getErrorByName(schema, 'arabicPropertyOverview').message}
          error={getErrorByName(schema, 'arabicPropertyOverview').error}
          isWithError
          isWithCharactersCounter
          isSubmitted={isSubmitted}
          multiline
          rows={3}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'arabicPropertyOverview', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='localAreaAndAmenitiesDescriptionRef'
          labelValue='local-area-and-amenities-description'
          value={state.localAreaAndAmenitiesDescription || ''}
          helperText={getErrorByName(schema, 'localAreaAndAmenitiesDescription').message}
          error={getErrorByName(schema, 'localAreaAndAmenitiesDescription').error}
          isWithError
          isWithCharactersCounter
          isSubmitted={isSubmitted}
          multiline
          rows={3}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'localAreaAndAmenitiesDescription', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='propertyAdditionalDescriptionRef'
          labelValue='additional_text'
          value={state.propertyAdditionalDescription || ''}
          // helperText={getErrorByName(schema, 'propertyAdditionalDescription').message}
          // error={getErrorByName(schema, 'propertyAdditionalDescription').error}
          isWithError
          isWithCharactersCounter
          isSubmitted={isSubmitted}
          multiline
          rows={3}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'propertyAdditionalDescription', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='webRemarksRef'
          labelValue='web-remarks'
          value={state.webRemarks || ''}
          helperText={getErrorByName(schema, 'webRemarks').message}
          error={getErrorByName(schema, 'webRemarks').error}
          isWithError
          isWithCharactersCounter
          isSubmitted={isSubmitted}
          multiline
          rows={3}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'webRemarks', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='siteInfoAndAmenitiesDescriptionRef'
          labelValue='site-info-and-amenities-description'
          value={state.siteInfoAndAmenitiesDescription || ''}
          helperText={getErrorByName(schema, 'siteInfoAndAmenitiesDescription').message}
          error={getErrorByName(schema, 'siteInfoAndAmenitiesDescription').error}
          isWithError
          isWithCharactersCounter
          isSubmitted={isSubmitted}
          multiline
          rows={3}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'siteInfoAndAmenitiesDescription', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='englishMarketingTitleRef'
          labelValue='english_marketing_title'
          value={state.englishMarketingTitle || ''}
          helperText={getErrorByName(schema, 'englishMarketingTitle').message}
          error={getErrorByName(schema, 'englishMarketingTitle').error}
          isWithError
          isWithCharactersCounter
          isSubmitted={isSubmitted}
          multiline
          rows={2}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'englishMarketingTitle', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='arabicMarketingTitleRef'
          labelValue='arabic_marketing_title'
          value={state.arabicMarketingTitle || ''}
          helperText={getErrorByName(schema, 'arabicMarketingTitle').message}
          error={getErrorByName(schema, 'arabicMarketingTitle').error}
          isWithError
          isWithCharactersCounter
          isSubmitted={isSubmitted}
          multiline
          rows={2}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'arabicMarketingTitle', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='developerDescriptionRef'
          labelValue='developer-description'
          value={state.developerDescription || ''}
          helperText={getErrorByName(schema, 'developerDescription').message}
          error={getErrorByName(schema, 'developerDescription').error}
          isWithError
          isWithCharactersCounter
          isSubmitted={isSubmitted}
          multiline
          rows={2}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'developerDescription', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='English_addressRef'
          labelValue='englishAddressLine'
          value={fullstate.englishAddressLine || `${((activeItem.country && activeItem.country.lookupItemName) || '')} - ${((activeItem && activeItem.city && activeItem.city.lookupItemName) || '')} - ${((activeItem && activeItem.district && activeItem.district.lookupItemName) || '')} - ${((activeItem && activeItem.community && activeItem.community.lookupItemName) || '')} - ${((activeItem && activeItem.sub_community && activeItem.sub_community.lookupItemName) || '')} ` || ''}
          isWithError
          isDisabled
          isSubmitted={isSubmitted}
          multiline
          rows={2}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='arabicAddressLineRef'
          labelValue='arabicAddressLine'
          value={state.arabicAddressLine || ''}
          helperText={getErrorByName(schema, 'arabicAddressLine').message}
          error={getErrorByName(schema, 'arabicAddressLine').error}
          isWithError
          isSubmitted={isSubmitted}
          multiline
          rows={2}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'arabicAddressLine', value: event.target.value });
          }}
        />
      </div>

      <div className='d-flex Inputs-ss-marketing mb-2'>
        <AutocompleteComponent
          idRef='marketingUSP1Ref'
          inputPlaceholder='SelectmarketingUSP'
          labelValue='marketing_usp1'
          selectedValues={usp1}
          multiple={false}
          data={usps || []}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) =>
            usp1 && option.lookupItemId === usp1.lookupItemId}
          withoutSearchButton
          // helperText={getErrorByName(schema, stateKey).message}
          // error={getErrorByName(schema, stateKey).error}
          isWithError
          // isLoading={isLoading}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onStateChanged({ id: 'marketingUsp1Id', value: ((newValue && newValue.lookupItemId) || null) });
          }}
        />
        <AutocompleteComponent
          idRef='marketingUSP2Ref'
          inputPlaceholder='SelectmarketingUSP'
          labelValue='marketing_usp2'
          selectedValues={usp2}
          multiple={false}
          data={usps || []}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) =>
            usp2 && option.lookupItemId === usp2.lookupItemId}
          withoutSearchButton
          // helperText={getErrorByName(schema, stateKey).message}
          // error={getErrorByName(schema, stateKey).error}
          isWithError
          // isLoading={isLoading}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onStateChanged({ id: 'marketingUsp2Id', value: ((newValue && newValue.lookupItemId) || null) });
            // setSelectedValue(newValue);
            // onStateChanged((newValue && newValue.lookupItemId) || null);
          }}
        />
        {' '}

        <AutocompleteComponent
          idRef='marketingUSP3Ref'
          inputPlaceholder='SelectmarketingUSP'
          labelValue='marketing_usp3'
          selectedValues={usp3}
          multiple={false}
          data={usps || []}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) =>
            usp3 && option.lookupItemId === usp3.lookupItemId}
          withoutSearchButton
          // helperText={getErrorByName(schema, stateKey).message}
          // error={getErrorByName(schema, stateKey).error}
          isWithError
          // isLoading={isLoading}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onStateChanged({ id: 'marketingUsp3Id', value: ((newValue && newValue.lookupItemId) || null) });
            // setSelectedValue(newValue);
            // onStateChanged((newValue && newValue.lookupItemId) || null);
          }}
        />
        <AutocompleteComponent
          idRef='marketingUSP4Ref'
          inputPlaceholder='SelectmarketingUSP'
          labelValue='marketing_usp4'
          selectedValues={usp4}
          multiple={false}
          data={usps || []}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) =>
            usp4 && option.lookupItemId === usp4.lookupItemId}
          withoutSearchButton
          // helperText={getErrorByName(schema, stateKey).message}
          // error={getErrorByName(schema, stateKey).error}
          isWithError
          // isLoading={isLoading}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onStateChanged({ id: 'marketingUsp4Id', value: ((newValue && newValue.lookupItemId) || null) });
            // setSelectedValue(newValue);
            // onStateChanged((newValue && newValue.lookupItemId) || null);
          }}
        />
        {' '}
        <SelectComponet
          data={marketingWebLabelData || []}
          value={state.propertyLabelId || null}
          translationPathForData={translationPath}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          labelValue='propertyLabel'
          valueInput='lookupItemId'
          textInput='lookupItemName'
          idRef='property_for_webRef'
          emptyItem={{
            value: null,
            text: 'select-filter-by',
            isDisabled: false,
          }}
          onSelectChanged={(e) =>
            onStateChanged({ id: 'propertyLabelId', value: e })}
        />
      </div>

      <div className='d-flex Inputs-ss-marketing mt-1'>
        <Inputs
          idRef='videoBannerURLRef'
          labelValue='videoBannerURL'
          value={state.videoBannerURL || ''}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'videoBannerURL', value: event.target.value });
          }}
        />

        <Inputs
          idRef='imageBannerURLRef'
          labelValue='imageBannerURL'
          value={state.imageBannerURL || ''}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'imageBannerURL', value: event.target.value });
          }}
        />
        <Inputs
          idRef='available_bedroomsRef'
          labelValue='available_bedrooms'
          value={fullstate.availableBedrooms || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <Inputs
          idRef='epectedROIRef'
          labelValue='epectedROI'
          value={fullstate.epectedROI || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <Inputs
          idRef='priceFromRef'
          labelValue='priceFrom'
          value={fullstate.priceFrom || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <Inputs
          idRef='priceMaxRef'
          labelValue='priceMax'
          value={fullstate.priceMax || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <Inputs
          idRef='launchYearRef'
          labelValue='launchYear'
          value={fullstate.launchYear || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <Inputs
          idRef='launchMonthRef'
          labelValue='launchMonth'
          value={fullstate.launchMonth || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />

        <Inputs
          idRef='projectRatingRef'
          labelValue='projectRating'
          value={fullstate.projectRating || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <Inputs
          idRef='price_SqFthRef'
          labelValue='price_SqFt'
          value={fullstate.price_SqFt || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <Inputs
          idRef='capitalAppreciateRef'
          labelValue='capitalAppreciate'
          value={fullstate.capitalAppreciate || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      {/* <MarketingBrochureImages isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} /> */}
    </div>
  );
};
PropertyMarketingRemarks.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
