import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  MarketingAgentAutocomplete,
  TitleDescriptionTemplateControls,
  UspAutocomplete,
} from './controls';
import {
  CheckboxesComponent,
  RadiosGroupComponent,
  PermissionsComponent,
} from '../../../../../../../../../Components';
import { UnitsOperationTypeEnum } from '../../../../../../../../../Enums';
import { GetParams, returnPropsByPermissions } from '../../../../../../../../../Helper';
import { UnitsSalesPermissions } from '../../../../../../../../../Permissions';
import {
  StaticLookupsIds
} from '../../../../../../../../../assets/json/StaticLookupsIds';

export const Descriptions = ({
  state,
  schema,
  onStateChanged,
  isMarketAsADifferentAgent,
  onIsMarketAsADifferentAgent,
  isSubmitted,
  parentTranslationPath,
  translationPath,
  activeItem,
}) => {
  const { t } = useTranslation([parentTranslationPath]);
  const [isSaleAndRent, setIsSaleAndRent] = useState((+GetParams('operationType') === UnitsOperationTypeEnum.rentAndSale.key));

  const getFeaturedUnitWithPermissions = () => {
    // eslint-disable-next-line prefer-const
    let list = [];
    if (returnPropsByPermissions(UnitsSalesPermissions.EditFeaturedUnitMarketing.permissionsId)) {
      list.push({
        key: 'isFeatureUnit',
        value: 'featured-unit',
      });
    }
    if (returnPropsByPermissions(UnitsSalesPermissions.EditHotDealMarketing.permissionsId)) {
      list.push({
        key: 'isHotDealUnit',
        value: 'hot-deal-unit',
      });
    }
    return list;
  };

  useEffect(() => {
    if (activeItem && activeItem.operation_type && activeItem.operation_type.lookupItemId === UnitsOperationTypeEnum.rentAndSale.key)
      setIsSaleAndRent(true);
    else
      setIsSaleAndRent(false);
  }, [activeItem]);

  return (
    <div className='marketing-description-wrapper presentational-wrapper'>
      <div className='title-wrapper'>
        <span className='title-text'>{t(`${translationPath}description`)}</span>
      </div>
      <PermissionsComponent
        permissionsList={Object.values(UnitsSalesPermissions)}
        permissionsId={
          UnitsSalesPermissions.EditUspMarketing.permissionsId
        }
      >
        <UspAutocomplete
          state={state}
          schema={schema}
          onStateChanged={onStateChanged}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />

      </PermissionsComponent>

      <div className='form-item'>
        <RadiosGroupComponent
          idRef='isMarketAsADifferentAgentRef'
          labelValue='is-market-as-a-different-agent'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          value={isMarketAsADifferentAgent}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(e, newValue) => {
            if (state.agentsId && onStateChanged)
              onStateChanged({ id: 'agentsId', value: null });
            if (onIsMarketAsADifferentAgent)
              onIsMarketAsADifferentAgent(newValue === 'true');
          }}
        />
      </div>
      {isMarketAsADifferentAgent && (

        <MarketingAgentAutocomplete
          state={state}
          schema={schema}
          onStateChanged={onStateChanged}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}

      <TitleDescriptionTemplateControls
        state={state}
        schema={schema}
        onStateChanged={onStateChanged}
        isSubmitted={isSubmitted}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        activeItem={activeItem}
      />

      <div className='w-100'>
        <CheckboxesComponent
          idRef='printMediaRef'
          labelValue=''
          data={getFeaturedUnitWithPermissions()}
          isRow
          onSelectedCheckboxChanged={(item) => {
            if (onStateChanged) {
              onStateChanged({
                id: item.key,
                value: !state[item.key],
              });
            }
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          translationPathForData={translationPath}
          labelInput='value'
          checked={(selectedItem) => state[selectedItem.key]}
        />
      </div>

      <PermissionsComponent
        permissionsList={Object.values(UnitsSalesPermissions)}
        permissionsId={
          UnitsSalesPermissions.EditPublishedToWebsiteMarketing.permissionsId
        }
      >

        {((activeItem && activeItem.operation_type && activeItem.operation_type.lookupItemId === StaticLookupsIds.Sales) || (activeItem && activeItem.operation_type && activeItem.operation_type.lookupItemId === StaticLookupsIds.SaleAndRent)) && (
          <>
            <div className='Publish-wrapper'>
              <div className='Publish-title'>
                <span>{t(`${translationPath}Publish-as`)}</span>
              </div>

              <CheckboxesComponent
                idRef='isPublishUnitSaleRef'
                labelValue=''
                data={[
                  {
                    key: 'isPublishUnitSale',
                    value: 'sale-listing-Unit/psi-website',
                  },
                ]}
                isRow
                onSelectedCheckboxChanged={(item) => {
                  if (onStateChanged) {
                    onStateChanged({
                      id: item.key,
                      value: !state[item.key],
                    });
                  }
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
                labelInput='value'
                checked={(selectedItem) => state[selectedItem.key]}
              />
              {/* <PermissionsComponent
                permissionsList={Object.values(UnitsSalesPermissions)}
                permissionsId={UnitsSalesPermissions.SaleListingUnitDubizzle.permissionsId}
              >
                <CheckboxesComponent
                  idRef='isPublishedForDubizzleSaleRef'
                  labelValue=''
                  data={[
                    {
                      key: 'isPublishedForDubizzleSale',
                      value: 'sale-listing-Unit/dubizzle',
                    },
                  ]}
                  isRow
                  onSelectedCheckboxChanged={(item) => {
                    if (onStateChanged) {
                      onStateChanged({
                        id: item.key,
                        value: !state[item.key],
                      });
                    }
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  labelInput='value'
                  checked={(selectedItem) => state[selectedItem.key]}
                />
              </PermissionsComponent> */}

              {/* {   isSaleAndRent && (
           <CheckboxesComponent
            idRef='isPublishUnitLeaseRef'
            labelValue=''
            data={[
            {
              key: 'isPublishUnitLease',
              value: 'lease-listing-Unit/psi-website',
            },
            ]}
           isRow
           onSelectedCheckboxChanged={(item) => {
           if (onStateChanged) {
             onStateChanged({
               id: item.key,
               value: !state[item.key],
             });
           }
         }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          translationPathForData={translationPath}
          labelInput='value'
          checked={(selectedItem) => state[selectedItem.key]}
       />
       )} */}
              {/* <PermissionsComponent
                permissionsList={Object.values(UnitsSalesPermissions)}
                permissionsId={UnitsSalesPermissions.SaleListingUnitBayut.permissionsId}
              >

                <CheckboxesComponent
                  idRef='bayutRef'
                  labelValue=''
                  data={[
                    {
                      key: 'isPublishedForBayoutSale',
                      value: 'sale-listing-Unit/bayut',
                    },
                  ]}
                  isRow
                  onSelectedCheckboxChanged={(item) => {
                    if (onStateChanged) {
                      onStateChanged({
                        id: item.key,
                        value: !state[item.key],
                      });
                    }
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  labelInput='value'
                  checked={(selectedItem) => state[selectedItem.key]}
                />
              </PermissionsComponent> */}
              {/* <PermissionsComponent
                permissionsList={Object.values(UnitsSalesPermissions)}
                permissionsId={UnitsSalesPermissions.SaleListingUnitPropertyFinder.permissionsId}
              >
                <CheckboxesComponent
                  idRef='PropertyFinderRef'
                  labelValue=''
                  data={[
                    {
                      key: 'isPublishedForPropertyFinderSale',
                      value: 'sale-listing-Unit/PropertyFinder',
                    },
                  ]}
                  isRow
                  onSelectedCheckboxChanged={(item) => {
                    if (onStateChanged) {
                      onStateChanged({
                        id: item.key,
                        value: !state[item.key],
                      });
                    }
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  labelInput='value'
                  checked={(selectedItem) => state[selectedItem.key]}
                />
              </PermissionsComponent> */}

              {/* <CheckboxesComponent
                idRef='Prian.ruRef'
                labelValue=''
                data={[
                  {
                    key: 'isPublishedForPrianSale',
                    value: 'sale-listing-Unit/Prianru',
                  },
                ]}
                isRow
                onSelectedCheckboxChanged={(item) => {
                  if (onStateChanged) {
                    onStateChanged({
                      id: item.key,
                      value: !state[item.key],
                    });
                  }
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
                labelInput='value'
                checked={(selectedItem) => state[selectedItem.key]}
              />  */}
              {/* <PermissionsComponent
                permissionsList={Object.values(UnitsSalesPermissions)}
                permissionsId={UnitsSalesPermissions.SaleListingUnitHouza.permissionsId}
              >

                <CheckboxesComponent
                  idRef='Houza.comRef'
                  labelValue=''
                  data={[
                    {
                      key: 'isPublishedForHouzaSale',
                      value: 'sale-listing-Unit/Houzacom',
                    },
                  ]}
                  isRow
                  onSelectedCheckboxChanged={(item) => {
                    if (onStateChanged) {
                      onStateChanged({
                        id: item.key,
                        value: !state[item.key],
                      });
                    }
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  labelInput='value'
                  checked={(selectedItem) => state[selectedItem.key]}
                />
              </PermissionsComponent> */}

            </div>
          </>

        )}
      </PermissionsComponent>
    </div>
  );
};

Descriptions.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  isMarketAsADifferentAgent: PropTypes.bool.isRequired,
  onIsMarketAsADifferentAgent: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  activeItem: PropTypes.instanceOf(Object).isRequired
};
