

import React, { useState ,  useEffect , useReducer  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Joi from 'joi';
import {
  DialogActions, DialogContent, DialogTitle, Dialog
} from '@material-ui/core';
import {
  Inputs,
  Spinner, 
} from '../../../../Components';
import { showSuccess ,  showError } from '../../../../Helper' ; 
import { CreatePolicyServices  , UpdatePolicyServices   } from '../../../../Services' ; 
import { PoliciesFooterDialog , PoliciesContentDialog  } from '../PoliciesManagement' ; 



export const PoliciesManagementDialog = ({
   parentTranslationPath ,
   translationPath ,
   isOpen ,
   onClose ,
   onSave  , 
   editPolicy , 
   isLoadingPolicy , 
   
  }) => {

  const { t } = useTranslation(parentTranslationPath);
  const [isLoading , setIsLoading] = useState(false) ; 
  const [isSubmitted , setIsSubmitted] = useState(false) ;

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
    return undefined;
  }

  const [state, setState] = useReducer(reducer,
  {
      policyDataName : null , 
      contacts: {
        createdBy: {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true ,
          specificUsers : [] , 
          myTeamLeadUsers : false , 
        },

        basedOnOtherPolicy : { relatedWithLeadPolicy: false  } , 
        viewSensitive:false
      },
      properties: {
        createdBy: {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true ,
          specificUsers : [] , 
          myTeamLeadUsers : false , 
        },
        address: {
          city: [],
          district: [],
          community: [],
          subCommunity:[] , 
          property: []
        },
        viewSensitive:false
      },
      units: {
        createdBy: {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true ,
          specificUsers : []  , 
          myTeamLeadUsers : false   , 
        },
        address: {
          city: [],
          district: [],
          community: [],
          subCommunity:[] , 
          property: []
        },
        saleListing : {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true ,
          specificUsers : [] , 
          myTeamLeadUsers : false 
        } ,
        leaseListing : {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true ,
          specificUsers : [] , 
          myTeamLeadUsers : false , 
        } , 
        unitStatus :  {
          all: true,
          available: false,
          reservedLeased: false,
          leased: false,
          reservedSale: false,
          sale: false,
          draft: false,
          pendingReservedLease: false,
          pendingLeased: false,
          pendingReservedSale: false,
          pendingSale: false,
        },
        viewSensitive:false
      },
       leads: {
        leadClass:[],
        createdBy: {
         allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true ,
          specificUsers : [] , 
          myTeamLeadUsers : false ,
        },
        address:
        {
          city:[],
          district:[],
          community:[],
          subCommunity:[] , 
          property:[]
        },
        referredBy:
        {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true ,
          specificUsers :[] , 
          myTeamLeadUsers : false 
        },
        referredTo:
        {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true  , 
          specificUsers : [] ,
          myTeamLeadUsers : false  , 
          isEmpty :false   , 
          isEmptyAndReferredByMyBranch :  false , 
        },
        dataDetails : {
          isLastDays : false , 
          lastDaysValue : null , 
          leadStatus : null ,
        },
        mediaDetails:[],
        methodOfContact:[],
        isMorgage: false,
        viewSensitive:false
    
      },
      activities:{
          createdBy: {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true , 
          specificUsers : [] ,
          myTeamLeadUsers : false , 
        },
        assignedTo: {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true  , 
          specificUsers : [] , 
          myTeamLeadUsers : false ,
        },
        myActivities: {
          myLead: false,
          leadMyLead: false,
          managerMyLead: false,
        },
        viewSensitive:false
      }
  });


  const schema = Joi.object({
    policyDataName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}policyDataName-is-required`),
        'string.empty': t(`${translationPath}policyDataName-is-required`),
      }),
  
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);


  const saveHandler = async () => {
    setIsSubmitted(true);
    if(schema.error)
    {
      showError(t('Shared:please-fix-all-errors'));
      setIsSubmitted(false);
      return ; 
    }
    if(state && state.leads && state.leads.dataDetails &&  state.leads.dataDetails.isLastDays && !(state.leads.dataDetails.lastDaysValue)){
      showError(t(`${translationPath}please-enter-days-on-lead-data-details-tab`));
      setIsSubmitted(false);
      return ; 
    }
     
    setIsLoading(true);
    const requestJson = { policyDataName :   (state.policyDataName) , policyJson : (JSON.stringify(state))} ; 
    const res =  !editPolicy ?  await CreatePolicyServices(requestJson) :
                await UpdatePolicyServices( state.policyDataId ,requestJson);
    if (!(res && res.status && res.status !== 200)) {
      if(!editPolicy )
      showSuccess(t(`${translationPath}add-policy-successfully`));
      else 
      showSuccess(t(`${translationPath}edit-policy-successfully`));

      setIsSubmitted(false);
      onSave() ;
    } 
    else 
    {
      if(!editPolicy)
         showError(t(`${translationPath}add-policy-failed`));
       else
         showError(t(`${translationPath}edit-policy-failed`));

      setIsSubmitted(false);

    }
    setIsLoading(false);
  };


  useEffect(() => {
    if (editPolicy === undefined || editPolicy === null) return;
    const policyData = JSON.parse(editPolicy.policyJson) ;
    const currntState = {
      policyDataId : editPolicy &&  editPolicy.policyDataId, 
      policyDataName :  (policyData &&  policyData.policyDataName ) || null  ,
      contacts : {  ...policyData.contacts , 
            createdBy : {...policyData.contacts.createdBy  ,
              myTeamLeadUsers :(policyData.contacts.createdBy.myTeamLeadUsers ? policyData.contacts.createdBy.myTeamLeadUsers :  false ), 
              specificUsers : ( policyData.contacts.createdBy && policyData.contacts.createdBy.specificUsers)  || []  } ,
            basedOnOtherPolicy : policyData.contacts &&  policyData.contacts.basedOnOtherPolicy ?   { ...policyData.contacts.basedOnOtherPolicy}:
            {
              relatedWithLeadPolicy : false , 
            }
          } , 
      activities :  {  ...policyData.activities , 
          createdBy : {
            ...policyData.activities.createdBy  ,
             myTeamLeadUsers :(policyData.activities.createdBy.myTeamLeadUsers ? policyData.activities.createdBy.myTeamLeadUsers : false ), 
             specificUsers : ( policyData.activities.createdBy && policyData.activities.createdBy.specificUsers)  || [] 
             } , 
             assignedTo : {
              ...policyData.activities.assignedTo  ,
               myTeamLeadUsers :(policyData.activities.assignedTo.myTeamLeadUsers ? true  :  false ), 
               specificUsers : ( policyData.activities.assignedTo && policyData.activities.assignedTo.specificUsers)  || [] 
               },
             myActivities : policyData.activities.myActivities ? {
              ...policyData.activities.myActivities,
             } : {
              myLead: false,
              leadMyLead: false,
              managerMyLead: false,
            },
            
            } , 
      leads :  {  ...policyData.leads , 
          createdBy : {...policyData.leads.createdBy   , 
          myTeamLeadUsers :(policyData.leads.createdBy.myTeamLeadUsers ? true :  false ), 
          specificUsers : ( policyData.leads.createdBy && policyData.leads.createdBy.specificUsers)  || []  } , 
          referredTo : {
            ...policyData.leads.referredTo  ,
             myTeamLeadUsers :(policyData.leads.referredTo.myTeamLeadUsers ?  true:  false ), 
             specificUsers : ( policyData.leads.referredTo && policyData.leads.referredTo.specificUsers)  || []  , 
             isEmpty :(policyData.leads.referredTo.isEmpty ?  true:  false ), 
             isEmptyAndReferredByMyBranch :(policyData.leads.referredTo.isEmptyAndReferredByMyBranch ?  true:  false ), 

             } , 
          referredBy : {
            ...policyData.leads.referredBy  ,
            myTeamLeadUsers :(policyData.leads.referredBy.myTeamLeadUsers ? true  : false ), 
            specificUsers : ( policyData.leads.referredBy && policyData.leads.referredBy.specificUsers)  || [] 
               } , 
          dataDetails : policyData && policyData.leads  && policyData.leads.dataDetails ?  {
                ...policyData.leads.dataDetails,
                lastDaysValue : (policyData.leads.dataDetails.lastDaysValue || null )
             } :
               {
                isLastDays :  false , 
                lastDaysValue : null , 
                leadStatus : null ,
              }
        }  , 
      properties : {  ...policyData.properties , 
       createdBy : { ...policyData.properties.createdBy  ,
       myTeamLeadUsers : (policyData.properties.createdBy.myTeamLeadUsers ? policyData.properties.createdBy.myTeamLeadUsers  :false ) ,
      specificUsers : ( policyData.properties.createdBy && policyData.properties.createdBy.specificUsers)  || []  }}   , 
      units :  {  ...policyData.units ,
      createdBy : policyData && policyData.units &&  policyData.units.createdBy  ? {...policyData.units.createdBy  , myTeamLeadUsers : (policyData.units.createdBy.myTeamLeadUsers ? true  :  false ) , specificUsers : ( policyData.units.createdBy && policyData.units.createdBy.specificUsers)  || []  } : null , 
      saleListing : policyData &&  policyData.units &&  policyData.units.saleListing  ?  {...policyData.units.saleListing  , myTeamLeadUsers :(policyData.units.saleListing.myTeamLeadUsers ? true : false) , specificUsers : ( policyData.units.saleListing && policyData.units.saleListing.specificUsers)  || []  } : 
      {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true ,
          specificUsers : [] , 
          myTeamLeadUsers : false , 
      } , 
      leaseListing : policyData &&  policyData.units &&  policyData.units.leaseListing  ?  {...policyData.units.leaseListing  ,  myTeamLeadUsers :(policyData.units.leaseListing.myTeamLeadUsers ? true :  false )  , specificUsers : ( policyData.units.leaseListing && policyData.units.leaseListing.specificUsers)  || []  } : 
      {
          allUsers: false,
          myUser: false,
          myTeamUsers: false,
          myBusinessGroupUsers: false,
          myManageUsers: false,
          myBranchUsers: true ,
          specificUsers : [] , 
          myTeamLeadUsers : false , 
      },
      unitStatus : policyData &&  policyData.units &&  policyData.units.unitStatus  ?  { ...policyData.units.unitStatus } : 
      {
        all: true,
        available: false,
        reservedLeased: false,
        leased: false,
        reservedSale: false,
        sale: false,
        draft: false,
        pendingReservedLease: false,
        pendingLeased: false,
        pendingReservedSale: false,
        pendingSale: false,
      }
      }}  

     setState({ id: 'edit', value: currntState });
  }, [editPolicy]);



  return (
    <div>
      <Spinner isActive={( isLoading ||  isLoadingPolicy)} isAbsolute />
      <Dialog
        open={isOpen}
        maxWidth='md'
        fullWidth
        onClose={() => {
          onClose();
        }}
        className='Policies-dialog-wrapper'
        disableBackdropClick
        SmothMove
      >
          <DialogTitle id='alert-dialog-slide-title'>
            <span>
              {!editPolicy ?
                t(`${translationPath}Add-Policy`) :
                t(`${translationPath}edit-policy`)}
            </span>
          </DialogTitle>
          <DialogContent>
            <PoliciesContentDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state} 
              setState={setState}
              schema={schema}
              isSubmitted={isSubmitted}
            />
          </DialogContent>
          <DialogActions>
            <PoliciesFooterDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onClose={onClose}
              onSave ={saveHandler}
            />  
          </DialogActions>
      </Dialog>
    </div>
  );

};

PoliciesManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editPolicy: PropTypes.instanceOf(Object),
  isLoadingPolicy :  PropTypes.bool,
};
PoliciesManagementDialog.defaultProps = {
  editPolicy: undefined ,
  isLoadingPolicy : undefined , 


};

