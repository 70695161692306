import { ZeroMatchingInformationComponent } from './ZeroMatchingInformationComponent' ; 
import { AssignInquiryToUnitComponent  } from '../../../ListingShortage/ListingShortageUtilities/Sections' ; 



export const ZeroMatchingtVerticalTabsData = [
    {
      label: 'Inquiries-information',
      component: ZeroMatchingInformationComponent,
    },
    {
      label: 'Assigned-Units',
      component: AssignInquiryToUnitComponent ,
    },
  
  ];