import React, {
 useState, useCallback, useEffect, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalHistory,
  bottomBoxComponentUpdate,
  getIsAllowedPermission,
  getSideMenuStatus,
  havePermissionToViewDetails,
  havePermissionToEditInformation,
  getSideMenuComponent,
  returnPropsByPermissions
} from '../../../Helper';
import {
  ActionsButtonsComponent,
  ViewTypes,
  AutocompleteComponent,
  SelectComponet,
  Spinner,
  PaginationComponent,
  PermissionsComponent,
  Inputs,
} from '../../../Components';
import {
  ActionsEnum,
  ViewTypesEnum,
  ContactTypeEnum,
  ActionsButtonsEnum,
  TableActions,
  PropertyStatusEnum,
  TableFilterTypesEnum,
  TableFilterOperatorsEnum,
  DateFilterTypesEnum,
  FormsIdsEnum,
  SearchCriteriaEnum
} from '../../../Enums';
import {
  CardDetailsComponent,
  PropertiesCardsComponent,
  PropertiesTableComponent,
  PropertiesMapper
} from './PropertiesUtilities';
import {
  GetAllSearchableFormFieldsByFormId,
  PropertiesAdvanceSearchTest,
  GetAdvanceSearchPropertyManagement,
  GetAllFormFieldsByFormId , 
  GetLookupItems 
  
} from '../../../Services';
import { AdvanceSearch } from '../../../SharedComponents/AdvanceSearch/AdvanceSearch';
import { AdvanceSearchSecondVersion } from '../../../SharedComponents/AdvanceSearchSecondVersion';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { PropertiesImportDialog } from './PropertiesUtilities/Dialogs/PropertiesImportDialog/PropertiesImportDialog';
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { GlobalAdvanceSearchActions } from '../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions';
import { useTitle } from '../../../Hooks';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { PropertiesPermissionsCRM } from '../../../Permissions/PropertiesPermissions';
import { PropertyManagementListPermissions } from '../../../Permissions/PropertyManagement/PropertyManagementList.Permissions';
import { PropertiesTableHeaderData } from './PropertiesUtilities/PropertiesTableComponent/PropertiesTableHeaderData';
import { PropertiesVerticalTabsData, PropertiesVerticalTabsData2 } from '../Common/OpenFileView/OpenFileUtilities/OpenFileData/PropertiesVerticalTabsData';

const parentTranslationPath = 'PropertiesView';
const translationPath = '';

export const PropertiesView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];
  useTitle(t('Shared:property-management'));
  const isPropertyManagementView = pathName === 'properties';
  const dispatch = useDispatch();
  const [viewAllData, setViewAllData] = useState(null);
  const [propertyPlanList , setPropertyPlanList] = useState(null);
  const [displyOpenFileButton, setDisplyOpenFileButton] = useState(false);
  const [displyEditeButton, setDisplyEditeButton] = useState(false);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const advanceSearch = useSelector((state) => state.GlobalAdvanceSearchReducer);
  const [propertiesTableFilter, setPropertiesTableFilter] = useState(advanceSearch && advanceSearch.PropertyFilter || null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllFormFieldsLoading, setIsAllFormFieldsLoading] = useState(false);
  const [isFirst, setFirst] = useState(false);
  const [isFirst1, setFirst1] = useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const searchTimer = useRef(null);
  const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [activeActionType, setActiveActionType] = useState(
    localStorage.getItem('ViewType') &&
    JSON.parse(localStorage.getItem('ViewType')).propertiesView ||
    ViewTypesEnum.cards.key
);
  const [selectFieldsOnAdvanceSearch , setSelectFieldsOnAdvanceSearch] = useState([]) ; 
  const [filterValuesFromAdvanceSearch , setFilterValuesFromAdvanceSearch ]= useState(null) ; 
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isAdvanceSearchCleared, setIsAdvanceSearchCleared] = useState(false);
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(
    activeActionType === ViewTypesEnum.cardsExpanded.key
  );
  const [searchedItem, setSearchedItem] = useState('');
  const [, setOrderByToggler] = useState(false);
  const [isSelect] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [allFormFields, setAllFormFields] = useState([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState(
    (pathName === 'properties-lease' && {
      filterBy: orderFilter.propertiesLeaseFilter.filterBy,
      orderBy: orderFilter.propertiesLeaseFilter.orderBy,
    }) ||
    (pathName === 'properties-sales' && {
      filterBy: orderFilter.propertiesSalesFilter.filterBy,
      orderBy: orderFilter.propertiesSalesFilter.orderBy,
    }) ||
    (pathName === 'properties' && {
      filterBy: orderFilter.propertiesSalesFilter.filterBy,
      orderBy: orderFilter.propertiesSalesFilter.orderBy,
    }) ||
    (pathName === 'Properties-CRM' && {
      filterBy: orderFilter.propertiesCrmFilter.filterBy,
      orderBy: orderFilter.propertiesCrmFilter.orderBy,
    })
  );
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: 1
  };
  const [dateFilter, setDateFilter] = useState(
    (orderFilter && orderFilter.PropertyFilterDate && orderFilter.PropertyFilterDate.startDate && orderFilter.PropertyFilterDate.endDat !== null) &&
    (
      {
        startDate: new Date(orderFilter && orderFilter.PropertyFilterDate && orderFilter.PropertyFilterDate.startDate || null),
        endDate: new Date(orderFilter && orderFilter.PropertyFilterDate && orderFilter.PropertyFilterDate.endDate || null),
        key: new Date(orderFilter && orderFilter.PropertyFilterDate && orderFilter.PropertyFilterDate.key || null),
        selectedDateType: orderFilter && orderFilter.PropertyFilterDate && orderFilter.PropertyFilterDate.selectedDateType || 1
      }) || dateRangeDefault
  );
  const [orderBy, setOrderBy] = useState(selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: 'createdOn', orderBy: 2 });
  const [checkedDetailedCards, setCheckedDetailedCards] = useState([]);
  const [detailsPropertiesList, setDetailsPropertiesList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  const [searchableFormFields, setSearchableFormFields] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [activeFormType, setActiveFormType] = useState(0);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
  });
  // End New Code

  const changeActiveFormType = (value) => {
    setActiveFormType(value);
  };

  const onTypeChanged = useCallback(
    (activeType) => {
      const ViewTypeData = JSON.parse(localStorage.getItem('ViewType'));
      if (ViewTypeData) {
        ViewTypeData.propertiesView = activeType;
        localStorage.setItem('ViewType', JSON.stringify(ViewTypeData));
      } else localStorage.setItem('ViewType', JSON.stringify({ propertiesView: activeType }));

      setActiveActionType(activeType);
      setIsExpanded(activeType === ViewTypesEnum.cardsExpanded.key);
      sideMenuIsOpenUpdate(false);
      sideMenuComponentUpdate(null);
    },
    [setActiveActionType]
  );
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const filterOnChange = (event, newValue) => {
    const emptyKeyIndex = newValue.findIndex((item) => !item.value);
    if (!searchInputValue && emptyKeyIndex !== -1) {
      newValue.splice(emptyKeyIndex, 1);
      return;
    }
    if (emptyKeyIndex !== -1) newValue[emptyKeyIndex].value = searchInputValue;
    if (filterSearchDto && Object.keys(filterSearchDto).length > 0 && newValue.length === 0) {
      onPageIndexChanged(0);
      setFilterSearchDto(null);
    }
    setSearchData([...newValue]);
  };

  
  const searchAdvanceSearch = (newFilter) => 
  {
    const searchObj =   newFilter ? {...newFilter} : null ; 
    if (!(newFilter &&  newFilter.length)) 
    localStorage.removeItem('PropertyFilter');


      dispatch(
        GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
           ...advanceSearch,
           PropertyFilter: searchObj || null   , 
        })
      );
      setPropertiesTableFilter(searchObj);     
  };

  const getAllSearchableFormFieldsByFormId = useCallback(async () => {
    const result = await GetAllSearchableFormFieldsByFormId(3);
    if (!(result && result.status && result.status !== 200)) {
      const list = [];
      list.push({ key: 'All', title: 'All' });
      list.push({ key: 'Ids', title: 'Property Id' });
      list.push({ key: 'Address', title: 'Address' });
      result.filter((item) => item.isSearchable)
        .map((item) => (
          list.push({
            key: item.searchableKey,
            title: item.formFieldTitle,
          })));
      setSearchableFormFields(list);
    } else setSearchableFormFields([]);
  }, []);
  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.properties.id);
    if (!((result && result.data && result.data.ErrorId) || !result) && Array.isArray(result)) {
      const list = result.filter((e) =>
        e.formFieldName !== 'amenities' &&
        e.formFieldName !== 'map' &&
        e.formFieldName !== 'facilities' &&
        e.formFieldName !== 'property_name' &&
        e.formFieldName !== 'property_owner' &&
        e.formFieldName !== 'unit_types_in_property').map((field) => ({
        id: field.formFieldId || null,
        key: field.formFieldKey || null,
        isDate: field.uiWidgetType === 'alt-date' || false,
        label: (field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
        input: field.displayPath || '',
        isNumber:
          field.propertyJson && JSON.parse(field.propertyJson).schema &&
          field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
          field.propertyJson && JSON.parse(field.propertyJson).schema &&
          field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
          field.propertyJson && JSON.parse(field.propertyJson).schema &&
          field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size',
        isSortable: true,
        searchableKey: field.searchableKey,
        data :JSON.parse(field.propertyJson).schema  ,
        fieldType : 
         (field.propertyJson && JSON.parse(field.propertyJson).schema.uiType) ||
         (field.propertyJson && JSON.parse(field.propertyJson).uiSchema['ui:widget'])  ||
         (field.uiWidgetType === null && 'text' ) ,
       
       })); 
      setAllFormFields([...list]); 

    } else setAllFormFields([]);
    setIsAllFormFieldsLoading(false);
  }, []);

  const getLookupItem = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const res = await GetLookupItems(1 , 25 ,'Property Plan' );
    if (!(res && res.data && res.data.ErrorId)) 
    {
      setPropertyPlanList((res && res.result))
    } else setPropertyPlanList([]);
  }, []);

  const getPropertiesData = useCallback(async (f) => {
    setIsLoading(true);
    const isViewAllData = pathName === 'properties' ? returnPropsByPermissions(PropertyManagementListPermissions.ViewandsearchinPropertyManagementProperties.permissionsId) : returnPropsByPermissions(PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId);
    if (isViewAllData) {
    const localFilterDto = f || filterSearchDto || {};

    if (activeFormType) {
      localFilterDto['property_plan.lookupItemId'] = [
        { searchType: TableFilterOperatorsEnum.equal.key, value: activeFormType },
      ];
    }

    if (propertiesTableFilter) {
      Object.values(propertiesTableFilter)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.searchableKey || item.displayPath]) {
            localFilterDto[item.searchableKey || item.displayPath].push({
              searchType: item.operator,
              value: item.value,
            });
          } else if (item.value) {
            localFilterDto[item.searchableKey || item.displayPath] = [
              {
                searchType: item.operator,
                value: item.value,
              },
            ];
          } else if (!item.value && (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
            item.operator === TableFilterOperatorsEnum.isBlank.key)) {
            localFilterDto[item.searchableKey || item.displayPath] = [
              {
                searchType: item.operator,
                value: null,
              },
            ];
          }
          return undefined;
        });
    }

    if (Object.values(localFilterDto).length) setIsAdvanceSearchActive(true);
    else setIsAdvanceSearchActive(false);

    const body = {
      criteria: localFilterDto,
      ...orderBy,
    };
    setIsDatePickerChanged(false);
    if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
      const fromDate = moment(dateFilter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      const toDate = moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss');

      if (dateFilter.selectedDateType === DateFilterTypesEnum.CreatedOnDate.key) {
        body.fromDate = fromDate;
        body.toDate = toDate;
      } else if (dateFilter.selectedDateType === DateFilterTypesEnum.UpdatedOnDate.key) {
        body.updatedFromDate = fromDate;
        body.updatedToDate = toDate;
      }
    }
    const res =
      pathName === 'properties' ?
        await GetAdvanceSearchPropertyManagement(filter, body) :
        await PropertiesAdvanceSearchTest(filter, body);
    if (!(res && res.status && res.status !== 200)) {
      setDetailsPropertiesList({
        result: ((res && res.result) || []).map((item) => item.propertyJson && PropertiesMapper(item, JSON.parse(item.propertyJson).property)),
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setDetailsPropertiesList({
        result: [],
        totalCount: 0,
      });
    }
  }
    setIsLoading(false);
  }, [activeFormType, filter, filterSearchDto, dateFilter, orderBy, pathName, propertiesTableFilter]);

  const searchHandler = (data) => {
    const newValue = [{
      key: 'Address',
      title: 'Address',
      value: data
    }];

    if (searchTimer.current) clearTimeout(searchTimer.current);
    if (data === '') {
      const index = searchData.findIndex((item) => item.key === 'All');
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem('PropertiesFilter');
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newValue]);
        setFilterSearchDto({
          All:
            [
              {
                value: data
              }
            ]
        });
      }, 1300);
    }
  };
  const searchClicked = async () => {
    if (searchData.length === 0) return;
    localStorage.setItem('PropertiesFilter', JSON.stringify(searchData));
    setFilterSearchDto(
      searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
    );
    onPageIndexChanged(0);
  };

  const searchchachedClickedWithoutFilter = async (data) => {
    if (data.length === 0) return;
    const oldfilter = data.reduce((total, item) => {
      if (total[item.key]) {
        total[item.key].push({
          searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
          value: item.value,
        });
      } else {
        total[item.key] = [
          {
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          },
        ];
      }
      return total;
    }, {});
    getPropertiesData(oldfilter);
  };

  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (actionEnum === TableActions.openFile.key) {
        if (pathName === 'properties-sales') {
          GlobalHistory.push(
            `/home/properties-sales/property-profile-edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        } else if (pathName === 'properties-lease') {
          GlobalHistory.push(
            `/home/properties-lease/property-profile-edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        } else if (pathName === 'Properties-CRM') {
          GlobalHistory.push(
            `/home/Properties-CRM/property-profile-edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        }
      } else if (actionEnum === TableActions.editText.key) {
        if (pathName === 'properties-sales') {
          GlobalHistory.push(
            `/home/properties-sales/edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        } else if (pathName === 'properties-lease') {
          GlobalHistory.push(
            `/home/properties-lease/edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        } else if (pathName === 'Properties-CRM') {
          GlobalHistory.push(
            `/home/Properties-CRM/edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        }
      }
    },
    [dispatch, pathName]
  );
  const displayedLabel = (option) => `${option.title}: ${searchInputValue}`;
  const disabledOptions = (option) => option.disabledOnSelect;
  const chipsLabel = (option) => `${option.title}: ${option.value}`;
  // const chipsDisabled = (option) => option.disabledOnTag;
  const inputValueChanged = (event, newInputValue) => {
    setSearchInputValue(newInputValue);
  };
  const onActionsButtonClicked = (activeAction) => {
    if (activeAction === ActionsButtonsEnum[3].id) setIsOpenImportDialog(true);
    if (activeAction === ActionsButtonsEnum[1].id) {
      if (pathName === 'properties-sales') GlobalHistory.push('/home/properties-sales/add');
      else if (pathName === 'properties') GlobalHistory.push('/home/properties/add');
      else if (pathName === 'Properties-CRM') GlobalHistory.push('/home/Properties-CRM/add');
      else GlobalHistory.push('/home/properties-lease/add');
    }
  };
  const onActionButtonChanged = () => { };
  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      event.stopPropagation();
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      if (actionEnum === ActionsEnum.reportEdit.key) {
        if (pathName === 'properties-sales') {
          GlobalHistory.push(
            `/home/properties-sales/edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'properties') {
          GlobalHistory.push(
            `/home/properties/edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'Properties-CRM') {
          GlobalHistory.push(
            `/home/Properties-CRM/edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else {
          GlobalHistory.push(
            `/home/properties-lease/edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        }
      } else if (actionEnum === ActionsEnum.folder.key) {
        if (pathName === 'properties-sales') {
          GlobalHistory.push(
            `/home/properties-sales/property-profile-edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'properties') {
          GlobalHistory.push(
            `/home/properties/property-profile-edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'Properties-CRM') {
          GlobalHistory.push(
            `/home/Properties-CRM/property-profile-edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else {
          GlobalHistory.push(
            `/home/properties-lease/property-profile-edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        }
      }
    },
    [dispatch, pathName]
  );
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    if (pathName === 'properties-sales') {
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          propertiesSalesFilter: {
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    } else if (pathName === 'properties-lease') {
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          propertiesLeaseFilter: {
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    } else if (pathName === 'properties') {
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          propertiesLeaseFilter: {
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    } else if (pathName === 'Properties-CRM') {
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          propertiesCrmFilter: {
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    } else {
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          propertiesFilter: {
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    }
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };
  const cardCheckboxClicked = useCallback((itemIndex) => {
    setCheckedDetailedCards((items) => {
      const index = items.findIndex((item) => item === itemIndex);
      if (index !== -1) items.splice(index, 1);
      else items.push(itemIndex);
      return [...items];
    });
  }, []);
  const reloadData = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    getPropertiesData();
  }, [getPropertiesData]);
  const onCardClick = useCallback(
    (item, selectedIndex) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveCard(item);
      if (getSideMenuStatus()) setAdvanceSearchBtn(true);
      dispatch(ActiveItemActions.activeItemRequest(item));
      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={detailsPropertiesList.result[selectedIndex]}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          loginResponse={loginResponse}
          relodedata={reloadData}
          displyOpenFileButton={displyOpenFileButton}
          displyEditeButton={displyEditeButton}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [detailedCardSideActionClicked, detailsPropertiesList.result, loginResponse, reloadData]
  );
  const onFilterValuesChanged = (newValue) => {
    setPropertiesTableFilter(newValue);
  };
  const focusedRowChanged = useCallback(
    (rowIndex) => {
      if (rowIndex !== -1) {
        sideMenuComponentUpdate(
          <CardDetailsComponent
            activeData={detailsPropertiesList.result[rowIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            loginResponse={loginResponse}
            relodedata={reloadData}
            displyOpenFileButton={displyOpenFileButton}
            displyEditeButton={displyEditeButton}
          />
        );
        sideMenuIsOpenUpdate(true);
      } else {
        sideMenuComponentUpdate(<></>);
        sideMenuIsOpenUpdate(false);
      }
    },
    [detailedCardSideActionClicked, detailsPropertiesList.result, loginResponse, reloadData, advanceSearchBtn]
  );

  const GetSideMenuStatus = useCallback(() => {
    const isMenuOpen = getSideMenuStatus();
    const activeComponentOpen = getSideMenuComponent() && getSideMenuComponent().type;

    if (!isMenuOpen) setAdvanceSearchBtn(true);
    else if (activeComponentOpen === AdvanceSearch) setAdvanceSearchBtn(false);
  });

  const onAdvanceSearchClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();

    if (advanceSearchBtn) {
      sideMenuIsOpenUpdate(true);
      setAdvanceSearchBtn(false);
    } else {
      sideMenuIsOpenUpdate(false);
      setAdvanceSearchBtn(true);
    }

    sideMenuComponentUpdate(
      <AdvanceSearchSecondVersion
        filterData={[...PropertiesTableHeaderData, ...allFormFields]}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onFilterValuesChanged={onFilterValuesChanged}
        localStorageKey='PropertyFilter'
        isAdvanceSearchCleared={isAdvanceSearchCleared}
        setIsAdvanceSearchCleared={setIsAdvanceSearchCleared}
        setOrderBy={setOrderBy}
        searchCriteriaTypeId={SearchCriteriaEnum.Property.typeId}
        setIsAdvanceSearchActive={setIsAdvanceSearchActive}
        fields={[...PropertiesTableHeaderData, ...allFormFields]}
        selectFieldsOnAdvanceSearch= {selectFieldsOnAdvanceSearch}
        setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
        setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
        filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
        searchAdvanceSearch={searchAdvanceSearch}
      />

    );
  }, [isAllFormFieldsLoading, allFormFields, advanceSearchBtn, isAdvanceSearchCleared , selectFieldsOnAdvanceSearch]);

  const onClearedAllFiltersClick = useCallback(() => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: 'createdOn', orderBy: 2 });
    setSearchData([]);
    setDateFilter(dateRangeDefault);
    changeActiveFormType(0);
    setIsClearFiltersClicked(true);
    setIsAdvanceSearchCleared(true);
    setPropertiesTableFilter(null);
    setFilterSearchDto(null);
    sideMenuIsOpenUpdate(false);
    setIsAdvanceSearchActive(false);
    setFilterValuesFromAdvanceSearch() ; 
    setSelectFieldsOnAdvanceSearch([]) ;

    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        propertiesCrmFilter: {
          filterBy: null,
          orderBy: null,
        },
      })
    );

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        PropertyFilter: null
      })
    );
  }, []);

  useEffect(() => {
    if (pathName === 'Properties-CRM')
      localStorage.setItem('PropertiesPropertyManagement', false);
    else if (pathName === 'properties')
      localStorage.setItem('PropertiesPropertyManagement', true);
  }, [pathName]);

  useEffect(() => {
    getLookupItem();
  
  }, [getLookupItem]);
  
  useEffect(() => {
    getAllSearchableFormFieldsByFormId();
  }, [getAllSearchableFormFieldsByFormId]);
  useEffect(() => {
   const isViewAllData = pathName === 'properties' ? returnPropsByPermissions(PropertyManagementListPermissions.ViewandsearchinPropertyManagementProperties.permissionsId) : returnPropsByPermissions(PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId);
   if (isViewAllData) {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={detailsPropertiesList.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
   }
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  useEffect(() => {
    const data = localStorage.getItem('PropertiesFilter');

    if (data) {
      setSearchData(JSON.parse(data));
      searchchachedClickedWithoutFilter(JSON.parse(data));
    } else
      getPropertiesData();
  }, []);

  useEffect(() => {
    if (isFirst1) {
      if (searchData && searchData.length === 0) {
        localStorage.removeItem('PropertiesFilter');
        setSearchedItem('');
      } else
        localStorage.setItem('PropertiesFilter', JSON.stringify(searchData));
    } else
      setFirst1(true);
  }, [searchData]);

  useEffect(() => {
    if (!isFirst) setFirst(true);
    else {
      const data = localStorage.getItem('PropertiesFilter');
      if (data)
        searchchachedClickedWithoutFilter(JSON.parse(data));
      else if (searchData && searchData.length === 0)
        getPropertiesData();
    }
  }, [activeFormType, filter, filterSearchDto, orderBy, pathName, propertiesTableFilter, searchData]);

  useEffect(() => {
    if (isFirst && isDatePickerChanged) {

      const searchDataFilter = searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
      getPropertiesData(searchDataFilter);
    }
  }, [dateFilter, isDatePickerChanged, searchData]);

  useEffect(() => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        PropertyFilterDate: dateFilter || dateRangeDefault
      })
    );
    // getPropertiesData();
  }, [dateFilter]);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    GetSideMenuStatus();
  }, [GetSideMenuStatus]);

  useEffect(() => {
    const openFileWithPermissions = !isPropertyManagementView ? PropertiesVerticalTabsData : PropertiesVerticalTabsData2;
    const editFileWithPermissions = !isPropertyManagementView ? PropertiesPermissionsCRM.EditPropertyDetails.permissionsId : PropertyManagementListPermissions.UpdateProperty.permissionsId;
    setDisplyOpenFileButton(havePermissionToViewDetails(openFileWithPermissions));
    setDisplyEditeButton(havePermissionToEditInformation(editFileWithPermissions));
  }, [isFirst]);

  return (
    <div className='view-wrapper property-view'>
      <Spinner isActive={isLoading} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section'>
              <ActionsButtonsComponent
                permissionsList={!isPropertyManagementView ? Object.values(PropertiesPermissionsCRM) : Object.values(PropertyManagementListPermissions)}
                addPermissionsId={!isPropertyManagementView ? PropertiesPermissionsCRM.AddNewProperty.permissionsId : PropertyManagementListPermissions.AddNewProperty.permissionsId}
                selectPermissionsId={!isPropertyManagementView ? [PropertiesPermissionsCRM.AddNewProperty.permissionsId, PropertiesPermissionsCRM.ImportProperty.permissionsId] : [PropertyManagementListPermissions.ImportProperty.permissionsId, PropertyManagementListPermissions.AddNewProperty.permissionsId]}
                enableImport={!isPropertyManagementView ? getIsAllowedPermission(
                  Object.values(PropertiesPermissionsCRM),
                  loginResponse,
                  PropertiesPermissionsCRM.ImportProperty.permissionsId
                ) : getIsAllowedPermission(
                  Object.values(PropertyManagementListPermissions),
                  loginResponse,
                  PropertyManagementListPermissions.ImportProperty.permissionsId
                )}
                onActionsButtonClicked={onActionsButtonClicked}
                onActionButtonChanged={onActionButtonChanged}

              />
            </div>
            <div className='section autocomplete-section'>
              <PermissionsComponent
                permissionsList={!isPropertyManagementView ? Object.values(PropertiesPermissionsCRM) : Object.values(PropertyManagementListPermissions)}
                permissionsId={!isPropertyManagementView ? PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId : PropertyManagementListPermissions.ViewandsearchinPropertyManagementProperties.permissionsId}
              >
                <div className='d-flex-column px-2 w-100 p-relative'>
                  <div className='d-flex-column w-100'>
                    <AutocompleteComponent
                      data={searchableFormFields.map((item) => ({
                        key: item.key,
                        title: item.title,
                      }))}
                      selectedValues={searchData}
                      displayLabel={displayedLabel}
                      disabledOptions={disabledOptions}
                      onChange={filterOnChange}
                      searchClicked={searchClicked}
                      chipsLabel={chipsLabel}
                      getOptionSelected={(option) =>
                        searchData.findIndex(
                          (item) => item.key === option.key && item.value === searchInputValue
                        ) !== -1}
                      tagValues={searchData}
                      inputValue={searchInputValue}
                      onInputChange={inputValueChanged}
                      inputLabel='filter'
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                      parentTranslationPath={parentTranslationPath}
                      inputPlaceholder='search-properties'
                    />
                  </div>
                  <div className='search-properties mt-1 pl-5-reversed'>
                    <div className='mr-1-reversed w-100'>
                      <SelectComponet
                        data={propertyPlanList}
                        emptyItem={{ value: 0, text: 'select-property-plan', isDisabled: false }}
                        value={activeFormType}
                        valueInput='lookupItemId'
                        textInput='lookupItemName'
                        onSelectChanged={changeActiveFormType}
                        themeClass='theme-default'
                        idRef='Select_Type'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <div className='mt-2 w-100'>
                      <Inputs
                        value={searchedItem}
                        onKeyUp={(e) => searchHandler(e.target.value)}
                        idRef='activitiesSearchRef'
                        labelClasses='mt-4'
                        onInputChanged={(e) => setSearchedItem(e.target.value)}
                        inputPlaceholder={t(`${translationPath}address-filter`)}
                      />
                    </div>

                    <div className='w-30 customDatePicker'>
                      <div className='date-type-select'>
                        <SelectComponet
                          data={[
                            { key: 1, value: `${translationPath}created-date` },
                            { key: 2, value: `${translationPath}update-on-date` }
                          ]}
                          defaultValue={{ key: 1, value: `${translationPath}created-date` }}
                          value={dateFilter.selectedDateType || 1}
                          valueInput='key'
                          textInput='value'
                          isDisabled={isLoading}
                          onSelectChanged={(value) => {
                            setIsDatePickerChanged(true);
                            setDateFilter((f) => ({ ...f, selectedDateType: value }));
                          }}
                          wrapperClasses='w-auto'
                          themeClass='theme-transparent'
                          idRef='Date_Select'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <DateRangePickerComponent
                        onClearClicked={() => {
                          setDateFilter(dateRangeDefault);
                          setIsDatePickerChanged(true);
                        }}
                        onDialogClose={() => {
                          setIsDatePickerChanged(true);
                        }}
                        ranges={[dateFilter]}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(selectedDate) => {
                          setDateFilter((item) => ({
                            ...item,
                            startDate: selectedDate.selection && selectedDate.selection.startDate,
                            endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                            key: 'selection',
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='view-search-wrapper'>
                  <ViewTypes onTypeChanged={onTypeChanged} initialActiveType={activeActionType} className='mb-3' />
                  <span className='advance-search'>
                    <ButtonBase
                      onClick={onAdvanceSearchClick}
                      id='AdvanceSearch_bbt'
                      disabled={isAllFormFieldsLoading}
                      className={`btns theme-solid ${isAdvanceSearchActive && !isAllFormFieldsLoading && 'is-active-filter'}`}
                    >
                      <span className={`${advanceSearchBtn ? 'mdi mdi-arrow-expand-left' : 'mdi mdi-arrow-expand-right'} mr-1`} />
                      {t(`${translationPath}advance-search`)}
                    </ButtonBase>
                  </span>
                  <ButtonBase
                    onClick={onClearedAllFiltersClick}
                    id='onClearedAllFiltersref'
                    disabled={isAllFormFieldsLoading}
                    className='btns theme-solid bg-danger clear-all-btn'
                  >
                    <span className='mdi mdi-filter-remove m-1' />
                    {t(`${translationPath}clear-filters`)}

                  </ButtonBase>
                </div>
              </PermissionsComponent>
            </div>

          </div>
          <PermissionsComponent
            permissionsList={!isPropertyManagementView ? Object.values(PropertiesPermissionsCRM) : Object.values(PropertyManagementListPermissions)}
            permissionsId={!isPropertyManagementView ? PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId : PropertyManagementListPermissions.ViewandsearchinPropertyManagementProperties.permissionsId}
          >
            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}properties`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}
                  :
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='filterByRef'
                    data={[
                      { id: 'createdOn', filterBy: 'created-on' },
                      { id: 'updateOn', filterBy: 'last-updated' },
                    ]}
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={filterByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='filterBy'
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    emptyItem={{ value: null, text: 'select-sort-by', isDisabled: false }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    id='action_apply'
                    onClick={orderBySubmitted}
                    disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>
        {activeActionType !== ViewTypesEnum.tableView.key && (
          <PermissionsComponent
            permissionsList={!isPropertyManagementView ? Object.values(PropertiesPermissionsCRM) : Object.values(PropertyManagementListPermissions)}
            permissionsId={!isPropertyManagementView ? PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId : PropertyManagementListPermissions.ViewandsearchinPropertyManagementProperties.permissionsId}
          >

            <div className='body-section'>
              <PropertiesCardsComponent
                data={detailsPropertiesList}
                isExpanded={isExpanded}
                onCardClicked={onCardClick}
                onFooterActionsClicked={detailedCardSideActionClicked}
                checkedDetailedCards={checkedDetailedCards}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                withCheckbox={isSelect}
                onCardCheckboxClick={cardCheckboxClicked}
                activeCard={activeCard}
                displyOpenFileButton={displyOpenFileButton}
                displyEditeButton={displyEditeButton}
              />
            </div>
          </PermissionsComponent>
        )}
        {activeActionType === ViewTypesEnum.tableView.key && (

          <PermissionsComponent
            permissionsList={!isPropertyManagementView ? Object.values(PropertiesPermissionsCRM) : Object.values(PropertyManagementListPermissions)}
            permissionsId={!isPropertyManagementView ? PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId : PropertyManagementListPermissions.ViewandsearchinPropertyManagementProperties.permissionsId}
          >
            <PropertiesTableComponent
              detailsPropertiesList={detailsPropertiesList}
              tableActionClicked={tableActionClicked}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              propertiesTableFilter={propertiesTableFilter}
              onFilterValuesChanged={onFilterValuesChanged}
              filter={filter}
              parentTranslationPath={parentTranslationPath}
              focusedRowChanged={focusedRowChanged}
              setOrderBy={setOrderBy}
              isClearFiltersClicked={isClearFiltersClicked}
              setIsClearFiltersClicked={setIsClearFiltersClicked}
              displyOpenFileButton={displyOpenFileButton}
              displyEditeButton={displyEditeButton}
              localStorageKey='PropertyFilter'
            />
          </PermissionsComponent>

        )}
      </div>
      <PropertiesImportDialog
        isOpen={isOpenImportDialog}
        isOpenChanged={() => setIsOpenImportDialog(false)}
      />
    </div>
  );
};
