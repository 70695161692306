import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from '../../Components';
import {
  BottomBoxView, Header, MainMenuView, NewReleaseNotification, OpenCloseView
} from '../../Views/Home';
import { SwitchRoute } from '../../Components/Route/SwitchRoute';
import { HomeRoutes } from '../../routes/HomeRoutes/HomeRoutes';
import {
  setLoading,
  setSideMenuIsOpenCallback,
  sideMenuIsOpenUpdate,
} from '../../Helper/Middleware.Helper';
import { config } from '../../config';
import { UpdateUserVersionNumber } from '../../Services';

const HomeLayout = () => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  setSideMenuIsOpenCallback(setSideMenuIsOpen);
  const [, setShowLoading] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(70);
  const [NewReleaseNotificationState, setNewReleaseNotificationState] = useState(false);
  const changeShowLoading = (flag) => { setShowLoading(flag); };
  setLoading(changeShowLoading);
  if (config.DisableRightClicking) document.addEventListener('contextmenu', (event) => event.preventDefault());

  const UpdateUserVersionNumberAPI = useCallback(async () => {
    (await UpdateUserVersionNumber(JSON.parse(localStorage.getItem('session')).userId, config.version));
  }, []);

  useEffect(() => {
    try {
      const storedSession = localStorage.getItem('session');
      if (storedSession) {
        const versionFrom = JSON.parse(storedSession).versionNo;
        if (versionFrom && versionFrom !== config.version)
          setNewReleaseNotificationState(true);
      } else
        console.warn("'session' not found in localStorage");
    } catch (error) {
      console.error("Error  parsing 'session' from localStorage:", error);
    }
  }, [UpdateUserVersionNumberAPI, config.version]);

  return (
    <>
      <Header headerHeightChanged={(hh) => setHeaderHeight(hh)} />
      <div className='container' style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}>
        <MainMenuView isHover={isHover} setIsHover={setIsHover} />
        <div className={`content-wrapper${isHover ? ' is-open' : ''}${(sideMenuIsOpen && ' is-open-side-menu') || ''}`}>
          <div className='open-close-main-layout'>
            <Breadcrumb />
            <SwitchRoute routes={HomeRoutes} />
          </div>
          <BottomBoxView />
        </div>
        <OpenCloseView isOpen={sideMenuIsOpen} isOpenClicked={() => sideMenuIsOpenUpdate()} />
      </div>
      <NewReleaseNotification
        isOpen={NewReleaseNotificationState}
        setNewReleaseNotificationState={setNewReleaseNotificationState}
        isClicked={() => { }}
      />
    </>
  );
};

export { HomeLayout };
