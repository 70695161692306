import React from 'react';

export const LeadsLeaseTableHeaderData = [
  {
    id: 1,
    label: 'Name',
    input: 'contact_name',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    component: (item) => <span>{item && item.name}</span> || <span />,
    fieldType: 'text' ,
  },
  {
    id: 2,
    label: 'Type',
    input: 'leadClass',
    fieldType: 'select' , 
    data : { 
     enum : [
    { id: 'Seller', name: 'seller' },
    { id: 'Buyer', name: 'buyer' },
    { id: 'Landlord', name: 'landlord' },
    { id: 'Tenant', name: 'tenant' },
    ]} , 
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
  },
  {
    id: 3,
    label: 'Created By',
    input: 'createdBy',
    fieldKey: 'createdBy',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: 'searchField' , 
    data:  {
      type: "string",
      title: "Created By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "createdBy",
      displayPath: "createdBy"
   }
  },
  {
    id: 4,
    label: 'Creation Date',
    input: 'createdOn',
    fieldKey: 'createdOn',
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    dateFormat: 'DD/MM/YYYY',
    fieldType: 'alt-date' , 
  },
  {
    id: 5,
    label: 'Updated By',
    input: 'updatedBy',
    fieldKey: 'updatedBy',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: 'searchField' , 
    data:  {
      type: "string",
      title: "Updated By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "updatedBy",
      displayPath: "updatedBy"
  }
  },
  {
    id: 6,
    label: 'Creation Time',
    input: 'createdOn',
    fieldKey: 'creationTime',
    isDefaultFilterColumn: true,
    isHiddenFilter: true,
    isDate: true,
    isSortable: true,
    dateFormat: 'hh:mm A',
    fieldType: 'alt-date' , 
  },
  {
    id: 7,
    label: 'Progress',
    input: 'data_completed',
    textInputType: 'number',
    fieldKey: 'data_completed',
    textInputMax: 100,
    fieldType: 'number' , 
    textInputMin: 0,
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    isNumber: true,
    isSortable: true,
    component: (item) => <span>{item && item.progressWithPercentage}</span> || <span />
  },
  {
    id: 8,
    label: 'Lead Assigned Date',
    input: 'assignDate',
    fieldKey: 'leadAssignedDate',
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    dateFormat: 'DD/MM/YYYY - hh:mm A',
    fieldType: 'alt-date' , 
  },
  {
    id: 9,
    label: 'Last Activity Date',
    input: 'lastActivityDate',
    fieldKey: 'lastActivityDate',
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    dateFormat: 'DD/MM/YYYY - hh:mm A',
    fieldType: 'alt-date' , 
  },
  {
    id: 10,
    label: 'Last Activity Type Name',
    input: 'lastActivtyTypeName',
    fieldKey: 'lastActivityTypeName',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: 'searchField' ,
    data: {
      type: "string",
      title: "Last Activty Type Name",
      description: "General Information",
      searchKey: "activtyType",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "lastActivtyTypeName",
      displayPath: "lastActivtyTypeName"
   } 
  },
  {
    id: 11,
    label: 'Activity Created By',
    input: 'lastActivityCreatedByName',
    fieldKey: 'activityCreatedBy',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true, 
    fieldType: 'searchField' , 
    data:  {
      type: "string",
      title: "Created By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "activityCreatedBy",
      displayPath: "activityCreatedBy"
   }
  },

  {
    id: 12,
    label: 'Activity Remarks',
    input: 'lastActivityComment',
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: 'text' ,

  },

  {
    id: 13,
    label: 'Size Area From',
    input: 'sizeAreaFrom',
    fieldKey: 'sizeAreaFrom',
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isSortable: true,
    isNumber: true , 
    fieldType: 'number' ,

  },
  {
    id: 14,
    label: 'Size Area To',
    input: 'sizeAreaTo',
    fieldKey: 'sizeAreaTo',
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isSortable: true,
    isNumber: true , 
    fieldType: 'number' ,

  },
  {
    id: 15,
    label: 'Budget From',
    input: 'budgetFrom',
    fieldKey: 'budgetFrom',
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isSortable: true,
    isNumber: true , 
    fieldType: 'number' ,

  },
  {
    id: 16,
    label: 'Budget To',
    input: 'budgetTo',
    fieldKey: 'budgetTo',
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isSortable: true,
    isNumber: true ,
    fieldType: 'number' ,

  },
  {
    id: 17,
    label: 'isBulk',
    input: 'bulkUpload',
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: false,
    component: (item) => <span>{item && item.bulkUpload ? 'Yes' : 'No'}</span> || '' , 
    fieldType: 'select' ,
    data : { enum :['Yes' , 'No'] } ,
  },
  {
    id: 18,
    label: 'CampaignID',
    input: 'PropertyCampaignId',
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: false,
    component: (item) => <span>{item && item.campaign_name && item.campaign_name.propertyCampaignId }</span> || '' , 
    fieldType: 'text' ,
  },
];
