import React, {
  useState, useCallback, useEffect, useRef
} from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Button, Step, StepLabel, Stepper, Tooltip,
  IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, ButtonBase
} from '@material-ui/core';
import { Inputs, SelectComponet, Spinner , PermissionsComponent } from '../../../Components';
import {UserAMLViewPermissions  } from '../../../Permissions' ; 
import {
  GetAllAMLTransactions, GetAllMyTrackedTransactions, GetAllTrackedTransactions, TransactionComplete, TransactionManualApprove, TransactionManualCancel
} from '../../../Services';
import {
  sideMenuComponentUpdate, sideMenuIsOpenUpdate, showError, showSuccess, 
} from '../../../Helper';
import { bottomBoxComponentUpdate } from '../../../Helper/Middleware.Helper';
import { PaginationComponent } from '../../../Components/PaginationComponent/PaginationComponent';
import { useTitle } from '../../../Hooks';
import { AMLReleaseType } from '../../../Enums';
const parentTranslationPath = 'AML';
const translationPath = '';

export const UserAMLView = () => {
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}AMLView`));
  const [IDS, setIDS] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleteAPILoading, setIsCompleteAPILoading] = useState(false);
  const [APIData, setAPIData] = useState({ result: [], totalCount: 0 });
  const [steps, setgetSteps] = React.useState([{ maintaitle: 'RequestCreated', value: '' }]);
  const searchTimer = useRef(null);
  const [data, setData] = React.useState(false);
  const [open, setOpen] = React.useState({ index: null, open: false });
  const [AMLTransactions, setAMLTransactions] = useState([]);
  const [AllAMLTransactions, setAllAMLTransactions] = useState([]);
  const [selected, setSelected] = useState({
    unitTransactionId: '',
    contactName: '',
    contactId: '',
    status: '',
    isSaleTransaction: null,
  });
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    unitTransactionId: '',
    contactName: '',
    contactId: '',
    status: '',
    isSaleTransaction: null,
  });
  const APIGetAllAMLTransactions = useCallback(async (unitTransactionId) => {
    if (unitTransactionId !== null) {
      setIsLoading(true);
      const result = await GetAllAMLTransactions(unitTransactionId, {
        pageIndex: 1,
        pageSize: 10000
      });
      if (!(result && result.status && result.status !== 200)) {
        setAMLTransactions(result && result.result && result.result[0] || []);
        setAllAMLTransactions(result && result.result && result.result || []);
      }
      else
        setAMLTransactions([]);
      setIsLoading(false);
    }
  });
  const APIGetAllTrackedTransactions = useCallback(async (filters, ISwitNotOpen) => {
    setIsLoading(true);
    const result = await GetAllMyTrackedTransactions(
      {
        ...filters,
        pageIndex: filters.pageIndex + 1
      },
    );
    if (!(result && result.status && result.status !== 200)) {
      if (!ISwitNotOpen) {
        setOpen({ index: null, open: false });
      }
      setAPIData(({ result: result.result, totalCount: result.totalCount || 0 }));
    }
    else
      setAPIData({ result: [], totalCount: 0 });
    setIsLoading(false);
  });

  const APITransactionManualApprove = useCallback(async (amlTransactionId, item, filter) => {
    setIsLoading(true);
    const result = await TransactionManualApprove(amlTransactionId);
    if (result) {
      APIGetAllTrackedTransactions(filter, true);
      showSuccess(t(`${translationPath}Approve-Job-successfully`));
      APIGetAllAMLTransactions(item);
    } else
      showError(t(`${translationPath}Approve-Job-Filed`));
    setIsLoading(false);
  }, []);

  const PushTOlink = useCallback(async (item, type, subtype) => {

    switch (type) {
      case 'Contacts':
        window.open(`/home/Contacts-CRM/contact-profile-edit?formType=${item.contactsType}&id=${item.contactId}`, '_blank').focus();
        break;
      case 'unit':
        if (item.isSaleTransaction) {
          window.open(`/home/units-sales/unit-profile-edit?formType=${1}&id=${item.unitId}`, '_blank').focus();
        } else {
          window.open(`/home/units-lease/unit-profile-edit?formType=${1}&id=${item.unitId}`, '_blank').focus();
        }
        break;
      case 'unitTransactionId':
        if (item.isSaleTransaction) {
          window.open(`/home/units-sales/unit-profile-reservation?id=${item.unitId}`, '_blank').focus();
        } else {
          window.open(`/home/units-lease/unit-profile-reservation?id=${item.unitId}`, '_blank').focus();
        }
        break;
      case 'Papayas':
        console.log('Mangoes and papayas are $2.79 a pound.');
        // Expected output: "Mangoes and papayas are $2.79 a pound."
        break;
      default:
        console.error(`Sorry, we are out of  type ${type}.`);
    }
  }, []);


  const APITransactionManualCancel = useCallback(async (amlTransactionId, item, filter) => {
    setIsLoading(true);
    const result = await TransactionManualCancel(amlTransactionId);
    if (result) {
      APIGetAllTrackedTransactions(filter, true);
      showSuccess(t(`${translationPath}Cancel-Job-successfully`));
      APIGetAllAMLTransactions(item);
    } else
      showError(t(`${translationPath}Cancel-Job-Filed`));
    setIsLoading(false);
  }, []);

  const APITransactionComplete = useCallback(async (unitTransactionId, filter) => {
    setIsCompleteAPILoading(true);
    const result = await TransactionComplete(unitTransactionId);
    if (result) {
      APIGetAllTrackedTransactions(filter, true);
      showSuccess(t(`${translationPath}Complete-Job-successfully`));
      APIGetAllAMLTransactions(unitTransactionId);
      APIGetAllAMLTransactions(IDS);
    } else

      showError(t(`${translationPath}Complete-Job-Filed`));
    setIsCompleteAPILoading(false);
  }, []);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    setOpen({ index: null, open: false });
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setOpen({ index: null, open: false });
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };

  const Row = (props) => {
    const { row } = props;
    const [activeStep, setActiveStep] = React.useState(2);

    useEffect(() => {
      if (AMLTransactions && AMLTransactions.completeActionDate !== null) setActiveStep(3);
      else if (AMLTransactions && AMLTransactions.responseReceiveDate !== null)
        setActiveStep(2);
      else setActiveStep(1);
    }, [AMLTransactions]);

    return (
      < >
        <TableRow className={`TableRow-TableBody-aml   ${''}   ${data.index === props.index ? 'isselect' : ''}`} index={props.index}>
          <TableCell component='th' scope='row'>{row.unitType}</TableCell>
          <TableCell align='right'><ButtonBase onClick={() => { PushTOlink(row, 'unitTransactionId'); }}>
            <div class="icon-container mx-1"

            >
              <span class="mdi mdi-open-in-new c-primary"></span>
            </div>
            {row.unitTransactionId}
          </ButtonBase></TableCell>

          <TableCell align='right'><ButtonBase onClick={() => { PushTOlink(row, 'unit'); }}>
            <div class="icon-container mx-1"

            >
              <span class="mdi mdi-open-in-new c-primary"></span>
            </div>
            {row.unitRefNumber}
          </ButtonBase></TableCell>
          <TableCell align='right'>{row.location}</TableCell>
          <TableCell align='right'>{moment(row.transactionDate).format('YYYY-M-DD - HH:mm:ss')}</TableCell>
          <TableCell align='right'>{row.isSaleTransaction && t(`${translationPath}${'Sale'}`) || t(`${translationPath}${'Lease'}`)}</TableCell>
          <TableCell align='right'>
            <div className='d-flex-center flex-h-center'>
              {row.status === 'Pass' && (
                <div className='pass-lapel'>
                  {' '}
                  {t(`${translationPath}${row.status}`)}
                  {' '}
                </div>
              ) ||
                row.status === 'Fail' && (
                  <div className='Fail-lapel'>
                    {' '}
                    {t(`${translationPath}${row.status}`)}
                    {' '}
                  </div>
                ) ||
                row.status === 'Pending' && (
                  <div className='Pending-lapel'>
                    {' '}
                    {t(`${translationPath}${row.status}`)}
                    {' '}
                  </div>
                ) ||
                'N/A'}
            </div>
          </TableCell>
          <TableCell align='right'>{row.amount}</TableCell>
          <TableCell align='right'>
            <IconButton aria-label='expand row' size='small'>
              {(open.index === props.index && open.open) ? <KeyboardArrowUpIcon
                id='KeyboardArrowUpIcon-ref'
                onClick={() => {
                  setOpen({ index: null, open: null });
                  setIDS(null); setData(false);
                }}
              /> : <KeyboardArrowDownIcon
                id='KeyboardArrowDownIcon-ref'
                onClick={() => {
                  setOpen({ index: props.index, open: true });
                  setIDS(props.row.unitTransactionId); setData(props);
                }}
              />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className='TableCell-BLOCK' style={{ paddingBottom: 0, paddingTop: 0 }} size="small" colSpan={7}>
            {/* <div />/in= timeout='auto' unmountOnExit */}
            <div className='claapse_wraper' style={open.index === props.index && open.open === true ? { display: 'block' } : { display: 'none' }}>
              <div className='stepaer-mian-wr'>
                <div className='stepaer-inner-container'>
                  {AMLTransactions && (
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label, index) => (
                        <Step key={label} key={index} index={index}>
                          <StepLabel>{label.maintaitle}</StepLabel>
                          <div className='d-flex-column-center'>{label.value}</div>
                        </Step>
                      ))}
                    </Stepper>
                  )}
                </div>
              </div>
              <div className='darkline-2' />
              <div>
                <div className='margin-titel'>
                  {' '}
                  {t(`${translationPath}ContactsInfo`)}
                  {' '}
                </div>
                <div className='row-item-wrap'>
                  {open.index === props.index && (
                    // eslint-disable-next-line no-return-assign
                    AllAMLTransactions.map((item, index) => (
                      <div key={index}>
                        <div className='row-wrper-line'>
                          <div className='row-item'>
                            <div className='row-item-sub-title'>
                              {t(`${translationPath}Name`)}
                              {' '}
                            </div>
                            <div className='row-item-sub-value d-flex'>
                              <ButtonBase onClick={() => { PushTOlink(item, 'Contacts'); }}>
                                <div class="icon-container mx-1"
                                >
                                  <span class="mdi mdi-open-in-new c-primary"></span>
                                </div>
                              </ButtonBase>
                              {item.contactName || 'N/A'}</div>
                          </div>
                          <div className='row-item'>
                            <div className='row-item-sub-title'>
                              {' '}
                              {t(`${translationPath}Location`)}
                              {' '}
                            </div>
                            <div className='row-item-sub-value'>{item.location || 'N/A'}</div>
                          </div>
                          <div className='row-item'>
                            <div className='row-item-sub-title'>
                              {' '}
                              {t(`${translationPath}Language`)}
                              {' '}
                            </div>
                            <div className='row-item-sub-value'>{item.language || 'N/A'}</div>
                          </div>
                          <div className='row-item  EMAIL-S'>
                            <div className='row-item-sub-title'>
                              {' '}
                              {' '}
                              {t(`${translationPath}Email`)}
                              {' '}
                            </div>
                            <Tooltip title={item.email}>
                              <div className='row-item-sub-value nowrap'> {item.email || 'N/A'}</div>
                            </Tooltip>
                          </div>
                          {item&&item.traxContactId  &&(<div className='row-item'>
                            <div className='row-item-sub-title'>
                              {' '}
                              {' '}
                              {t(`${translationPath}TRAX-ID`)}
                              {' '}
                            </div>
                            <Tooltip title={item&&item.traxContactId}>
                              <div className='row-item-sub-value nowrap'> {item&&item.traxContactId || 'N/A'}</div>
                            </Tooltip>
                          </div>) || ''}
                          <div className='row-item'>
                            <div className='row-item-sub-title'>
                              {' '}
                              {t(`${translationPath}nationality`)}
                              {' '}
                            </div>
                            <div className='row-item-sub-value'>{item.nationality || 'N/A'}</div>
                          </div>
                          <div className='row-item'>
                            <div className='row-item-sub-title'>
                              {' '}
                              {t(`${translationPath}status`)}
                              {' '}
                            </div>
                            <div className='row-item-sub-value'>
                              {item.status === 'Pass' && (
                                <div className='pass-lapel'>
                                  {' '}
                                  {t(`${translationPath}${item.status}`)}
                                  {' '}
                                </div>
                              ) ||
                                item.status === 'Fail' && (
                                  <div className='Fail-lapel'>
                                    {' '}
                                    {t(`${translationPath}${item.status}`)}
                                    {' '}
                                  </div>
                                ) ||
                                item.status === 'Pending' && (
                                  <div className='Pending-lapel'>
                                    {' '}
                                    {t(`${translationPath}${item.status}`)}
                                    {' '}
                                  </div>
                                ) ||
                                'N/A'}
                            </div>
                          </div>

                          <div className='row-item-Button  mx-2 '>
                            <div className='btns-can'>
                            <PermissionsComponent
                               permissionsList={Object.values(UserAMLViewPermissions)}
                               permissionsId={UserAMLViewPermissions.CancelACheckRequest.permissionsId}
                            >

                              {item.status === 'Pending' &&  (
                                <Button
                                  className='can'
                                  id='Cancel-act-bbt'
                                  onClick={() => { APITransactionManualCancel(item.amlTransactionId, props.row.unitTransactionId, filter); }}
                                >
                                  {t(`${translationPath}Cancel`)}
                                </Button>
                              ) || ''}
                              </PermissionsComponent>
                            </div>
                          </div>
                          <div className='row-item-Button  mx-2 '>
                            <div className='btns-can'>

                            </div>
                            <div className='btns-can-Approve'>

                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <div className='part-tow-secation'>
                  <div className='margin-titel'>{t(`${translationPath}RequestDetails`)}</div>
                  <div className='d-flex flx-v-been w-100'>
                    <div className='Request-Details'>
                      <div className='row-item'>
                        <div className='row-item-sub-title'>{t(`${translationPath}RequestCreated`)}</div>
                        <div className='row-item-sub-value mt-3'>{moment(AMLTransactions && AMLTransactions && AMLTransactions.requestCreated).format('YYYY-M-DD - HH:mm:ss') || 'N/A'}</div>
                      </div>
                      <div>
                        <div className='row-item-sub-title'>
                          {AMLTransactions &&
                            AMLTransactions.completeActionDate !== null &&
                            (AMLTransactions
                              && AMLTransactions.completeActionDate) && t(`${translationPath}TransactionComplete`) ||
                            AMLTransactions && AMLTransactions.responseReceiveDate !== null
                            && t(`${translationPath}Resultreceived`) ||
                            (AMLTransactions && AMLTransactions &&
                              AMLTransactions && AMLTransactions.requestSent) && t(`${translationPath}RequestSent`) || 'N/A'
                          }
                        </div>
                        <div className='row-item-sub-value mt-3'>
                          {AMLTransactions &&
                            AMLTransactions.completeActionDate !== null &&
                            moment(AMLTransactions
                              && AMLTransactions.completeActionDate).format('YYYY-M-DD - HH:mm:ss') ||
                            AMLTransactions && AMLTransactions.responseReceiveDate !== null
                            && moment(AMLTransactions
                              && AMLTransactions.responseReceiveDate).format('YYYY-M-DD - HH:mm:ss') ||
                            moment(
                              AMLTransactions && AMLTransactions.requestSent).format('YYYY-M-DD - HH:mm:ss') || 'N/A'
                          }
                        </div>
                      </div>
                      <div>
                        <div className='row-item-sub-title'>{t(`${translationPath}Createdby`)}</div>
                        <div className='row-item-sub-value mt-3'>
                          {AMLTransactions && AMLTransactions.createdByName || 'N/A'}
                        </div>
                      </div>
                      <div>
                        <div className='row-item-sub-title'>{t(`${translationPath}Release-Type`)}</div>
                        <div className='row-item-sub-value mt-3'>
                          {AMLTransactions && AMLTransactions.amlReleaseTypeDto
                            && AMLReleaseType[AMLTransactions && AMLTransactions.amlReleaseTypeDto.releaseType]
                            &&
                            t(`Shared:${AMLReleaseType[AMLTransactions && AMLTransactions.amlReleaseTypeDto.releaseType].name}`)
                            || 'N/A'}
                          {AMLTransactions && AMLTransactions.amlReleaseTypeDto && AMLTransactions.amlReleaseTypeDto.releaseType === AMLReleaseType[3].value &&

                            `:  ${AMLTransactions && AMLTransactions.amlReleaseTypeDto && AMLTransactions.amlReleaseTypeDto.manualApprovedByName}` || ' '}


                        </div>
                      </div>
                    </div>
                    {AMLTransactions && AMLTransactions.isComplete !== true &&
                      (AMLTransactions && [AMLTransactions].findIndex((item) => item.status === 'Pending') &&
                        AMLTransactions && [AMLTransactions].findIndex((item) => item.status === 'Pending') === -1) &&
                        !isLoading && 
                        (
                        <Button
                          className='Complete-bbt MuiButtonBase-root btns theme-solid mb-2'
                          onClick={() => { APITransactionComplete(row.unitTransactionId, filter); }}
                          id='Complete-AMLTransactions-REF'
                        >
                          <span>{t(`${translationPath}Complete`)}</span>
                        </Button>
                      ) || ''}
                  </div>
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>

      </>
    );
  };

  function createData(unitTransactionId, transactionDate, unitType, unitRefNumber, location, isSaleTransaction, status, amount, unitId) {
    return {
      unitTransactionId,
      transactionDate,
      unitType,
      unitRefNumber,
      location,
      isSaleTransaction,
      status,
      amount,
      unitId,
    };
  }
  const rows = APIData && APIData.result.map((row, index) => (createData(
    row.unitTransactionId,
    row.transactionDate,
    row.unitType,
    row.unitRefNumber,
    row.location,
    row.isSaleTransaction,
    row.status,
    row.amount,
    row.unitId,
  )));

  useEffect(() => {
    setAMLTransactions([]);
    if (data !== false)
      APIGetAllAMLTransactions(data && data.row && data.row.unitTransactionId);
  }, [data]);

  useEffect(() => () => {
    bottomBoxComponentUpdate(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  }, []);

  useEffect(() => { APIGetAllTrackedTransactions(filter); }, [filter]);

  useEffect(() => {
    setgetSteps(
      [
        { maintaitle: t(`${translationPath}RequestCreated`), value: moment(AMLTransactions && AMLTransactions.requestCreated).format('YYYY-M-DD - HH:mm:ss') || 'N/A' },
        { maintaitle: t(`${translationPath}RequestSent`), value: moment(AMLTransactions && AMLTransactions.requestSent).format('YYYY-M-DD - HH:mm:ss') || 'N/A' },
        { maintaitle: t(`${translationPath}Resultreceived`), value: AMLTransactions && AMLTransactions.responseReceiveDate !== null && moment(AMLTransactions && AMLTransactions.responseReceiveDate).format('YYYY-M-DD - HH:mm:ss') || 'N/A' },
        { maintaitle: t(`${translationPath}TransactionComplete`), value: AMLTransactions && AMLTransactions.completeActionDate !== null && moment(AMLTransactions && AMLTransactions.completeActionDate).format('YYYY-M-DD - HH:mm:ss') || 'N/A' },
      ]
    );
  }, [AMLTransactions])

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={APIData.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  return (
    <div className='view-wrapper AMLView-view-wrapper'>
      <Spinner isActive={(isLoading || isCompleteAPILoading)} isAbsolute />
      <div className='d-flex-column'>
        <div className='d-flex mx-2'>
          <div className='w-100 mr-1-reversed mx-2'>
            <Inputs
              idRef='fullNameFilterRef'
              fieldClasses='inputs theme-solid'
              label='filter-by-name'
              parentTranslationPath={parentTranslationPath}
              value={selected.contactName}
              onInputChanged={(event) => { setSelected((item) => ({ ...item, contactName: event?.target?.value || '' })); }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({ ...item, contactName: newValue }));
                }, 700);
              }}
              inputPlaceholder='filter-by-name'
            />
          </div>
          <div className='w-100 mr-1-reversed mx-2'>

            <Inputs
              idRef='userNameFilterRef'
              fieldClasses='inputs theme-solid'
              parentTranslationPath={parentTranslationPath}
              value={selected.contactId}
              onInputChanged={(event) => { setSelected((item) => ({ ...item, contactId: event?.target?.value || '' })); }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({ ...item, contactId: newValue }));
                }, 700);
              }}
              inputPlaceholder='type-contactId'
              label='filter-by-contactId'
            />
          </div>
          <div className='w-100 mr-1-reversed mx-2'>
            <Inputs
              idRef='staffCodeRef'
              fieldClasses='inputs theme-solid'
              label='filter-by-unitTransactionId'
              parentTranslationPath={parentTranslationPath}
              value={selected.unitTransactionId}
              onInputChanged={(event) => { setSelected((item) => ({ ...item, unitTransactionId: event?.target?.value || '' })); }}
              translationPath={translationPath}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({ ...item, unitTransactionId: newValue }));
                }, 700);
              }}
              inputPlaceholder='filter-by-unitTransactionId'
            />
          </div>
          <div className='d-flex'>
            <div className='mx-2 w-100'>
              <SelectComponet
                data={[
                  { status: 1, statusText: t(`${translationPath}Pass`) },
                  { status: 2, statusText: t(`${translationPath}Fail`) },
                  { status: 3, statusText: t(`${translationPath}Pending`) },
                ]}
                value={filter.status || 0}
                emptyItem={{
                  value: 0,
                  text: t(`${translationPath}status`),
                  isDisabled: false,
                }}
                valueInput='status'
                textInput='statusText'
                onSelectChanged={(newValue) => {
                  setFilter((item) => ({ ...item, status: newValue, pageIndex: 0 }));
                  setSelected((item) => ({ ...item, status: newValue }));
                }}
              />
            </div>
          </div>
          <div className='d-flex'>
            <div className='mx-2 w-100'>
              <SelectComponet
                data={[
                  { status: 1, statusText: t(`${translationPath}Lease`) },
                  { status: 2, statusText: t(`${translationPath}Sale`) },
                ]}
                value={
                  filter.isSaleTransaction === false && 1 ||
                  filter.isSaleTransaction === true && 2 ||
                  null
                }
                emptyItem={{
                  value: null,
                  text: t(`${translationPath}TransactionType`),
                  isDisabled: false,
                }}
                valueInput='status'
                textInput='statusText'
                onSelectChanged={(newValue) => {
                  let newValueEdit = (newValue === 1 && false || newValue === 2 && (true) || newValue === null && (null))
                  setFilter((item) => ({ ...item, isSaleTransaction: newValueEdit, pageIndex: 0 }));
                  setSelected((item) => ({ ...item, isSaleTransaction: newValueEdit }));
                }}
              />
            </div>
          </div>
          <div className='row-item-Button alia'>
            <div className='btns-can'>
              <Button
                className='can'
                onClick={() => {
                  setFilter((item) => ({
                    ...item,
                    unitTransactionId: '',
                    contactName: '',
                    contactId: '',
                    status: '',
                  }));
                  setSelected({
                    unitTransactionId: '',
                    contactName: '',
                    contactId: '',
                    status: '',
                  });
                }}
              >
                {t(`${translationPath}Clear`)}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='AML-TABEL d-flex-column'>
        <TableContainer component={Paper}>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow className='TableRow-heed-aml'>
                <TableCell align='left'>
                  {t(`${translationPath}UnitType`)}
                </TableCell>
                <TableCell align='left'>
                  {t(`${translationPath}UnitTransactionId`)}
                </TableCell>
                <TableCell align='left'>
                  {t(`${translationPath}UnitRefNumber`)}
                </TableCell>
                <TableCell align='left'>
                  {t(`${translationPath}Location`)}
                </TableCell>
                <TableCell align='left'>
                  {t(`${translationPath}Date`)}
                </TableCell>
                <TableCell align='left'>
                  {t(`${translationPath}TransactionType`)}
                </TableCell>
                <TableCell align='left'>
                  {t(`${translationPath}status`)}
                </TableCell>
                <TableCell align='left'>
                  {t(`${translationPath}amount`)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex, column, columnIndex) => (
                <Row key={row.name} row={row} column={column} icolumnIndex={columnIndex} index={rowIndex} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
