import React , { useState , useEffect  , useRef , useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent  } from '../../../../../../../Components' ; 

    
export const UnitStatus  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState , 
  checkAtLeastOneSelected  , 
 
}) => {
    const { t } = useTranslation(parentTranslationPath);
    
    const checkIsAllStatusSelected = (unitStatus)=> {
      if (unitStatus) {
        const unCheckedBoxes = Object.values(unitStatus).slice(1).find(
          (statusValue) => statusValue === false
        ); 
        const isAllStatusSelected = unCheckedBoxes === undefined? true : false;

        return isAllStatusSelected;
      }
    }

  return (
    <div className='content'>
      <div className='w-100 px-2 mt-2 checkBoxsPart'>
      <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusAllRef'
          label={t(`${translationPath}All`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.all}
          onSelectedCheckboxClicked={(e)=> {  

            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  all : e.target.checked } );  
             if(!checkIfCanSelected)
               return ;
              
              const unitStatus = {
                all: (e.target.checked),
                available: false,
                reservedLeased: false,
                leased: false,
                reservedSale: false,
                sale: false,
                draft: false,
                pendingReservedLease: false,
                pendingLeased: false,
                pendingReservedSale: false,
                pendingSale: false,
              };
              setState({ id: 'units', value: { ...state.units, unitStatus: unitStatus } });

              

         }}
         />
        </div>
      <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusAvailableRef'
          label={t(`${translationPath}available`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.available}
          onSelectedCheckboxClicked={(e)=> {

          const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  available : e.target.checked } );  
             if(!checkIfCanSelected)
               return ;

               const isAllStatusSelected = checkIsAllStatusSelected({
                ...state.units.unitStatus,
                available: (e.target.checked),
              });
  
              const unitStatus = isAllStatusSelected ? {
                all: true,
                available: false,
                reservedLeased: false,
                leased: false,
                reservedSale: false,
                sale: false,
                draft: false,
                pendingReservedLease: false,
                pendingLeased: false,
                pendingReservedSale: false,
                pendingSale: false,
              } : {
                ...state.units.unitStatus,
                all: false,
                available: (e.target.checked),
              };
            setState({id : 'units' , value : { ...state.units , unitStatus :   unitStatus  } });
              
              
         }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusReservedLeasedRef'
          label={t(`${translationPath}reservedLeased`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.reservedLeased}
          onSelectedCheckboxClicked={(e)=> 
         {
          const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  reservedLeased : e.target.checked } );  
             if(!checkIfCanSelected)
               return ;

               const isAllStatusSelected = checkIsAllStatusSelected({
                ...state.units.unitStatus,
                reservedLeased: (e.target.checked),
              });
  
              const unitStatus = isAllStatusSelected ? {
                all: true,
                available: false,
                reservedLeased: false,
                leased: false,
                reservedSale: false,
                sale: false,
                draft: false,
                pendingReservedLease: false,
                pendingLeased: false,
                pendingReservedSale: false,
                pendingSale: false,
              } : {
                ...state.units.unitStatus,
                all: false,
                reservedLeased : (e.target.checked),
              };
               setState({id : 'units' , value : { ...state.units , unitStatus :   unitStatus  } });

         }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusLeasedRef'
          label={t(`${translationPath}leased`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.leased}
          onSelectedCheckboxClicked={(e)=> 
         {
          const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  leased : e.target.checked } );  
          if(!checkIfCanSelected)
            return ;

            const isAllStatusSelected = checkIsAllStatusSelected({
             ...state.units.unitStatus,
             leased: (e.target.checked),
           });

           const unitStatus = isAllStatusSelected ? {
             all: true,
             available: false,
             reservedLeased: false,
             leased: false,
             reservedSale: false,
             sale: false,
             draft: false,
             pendingReservedLease: false,
             pendingLeased: false,
             pendingReservedSale: false,
             pendingSale: false,
           } : {
             ...state.units.unitStatus,
             all: false,
             leased : (e.target.checked),
           };
            setState({id : 'units' , value : { ...state.units , unitStatus :   unitStatus  } });
                     
         }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusReservedSaleRef'
          label={t(`${translationPath}reservedSale`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.reservedSale}
          onSelectedCheckboxClicked={(e)=> 
          {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  reservedSale : e.target.checked } );  
             if(!checkIfCanSelected)
               return ;

               const isAllStatusSelected = checkIsAllStatusSelected({
                ...state.units.unitStatus,
                reservedSale: (e.target.checked),
              });
  
              const unitStatus = isAllStatusSelected ? {
                all: true,
                available: false,
                reservedLeased: false,
                leased: false,
                reservedSale: false,
                sale: false,
                draft: false,
                pendingReservedLease: false,
                pendingLeased: false,
                pendingReservedSale: false,
                pendingSale: false,
              } : {
                ...state.units.unitStatus,
                all: false,
                reservedSale: (e.target.checked),
              };

            setState({id : 'units' , value : { ...state.units , unitStatus :   unitStatus  } });


                     
            

           }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusSaleRef'
          label={t(`${translationPath}sale`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.sale}
          onSelectedCheckboxClicked={(e)=> 
          {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  sale : e.target.checked } );  
             if(!checkIfCanSelected)
               return ;

               const isAllStatusSelected = checkIsAllStatusSelected({
                ...state.units.unitStatus,
                sale: (e.target.checked),
              });
  
              const unitStatus = isAllStatusSelected ? {
                all: true,
                available: false,
                reservedLeased: false,
                leased: false,
                reservedSale: false,
                sale: false,
                draft: false,
                pendingReservedLease: false,
                pendingLeased: false,
                pendingReservedSale: false,
                pendingSale: false,
              } : {
                ...state.units.unitStatus,
                all: false,
                sale: (e.target.checked),
              };
              
            setState({id : 'units' , value : { ...state.units , unitStatus :   unitStatus  } });
                     
           

         }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusDraftRef'
          label={t(`${translationPath}draft`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.draft}
          onSelectedCheckboxClicked={(e)=> 
          {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  draft  : e.target.checked } );  
             if(!checkIfCanSelected)
               return ;

               const isAllStatusSelected = checkIsAllStatusSelected({
                ...state.units.unitStatus,
                 draft : (e.target.checked),
              });
  
              const unitStatus = isAllStatusSelected ? {
                all: true,
                available: false,
                reservedLeased: false,
                leased: false,
                reservedSale: false,
                sale: false,
                draft: false,
                pendingReservedLease: false,
                pendingLeased: false,
                pendingReservedSale: false,
                pendingSale: false,
              } : {
                ...state.units.unitStatus,
                all: false,
                draft : (e.target.checked),
              };
              
            setState({id : 'units' , value : { ...state.units , unitStatus :   unitStatus  } });
                     
          

           }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusPendingReservedLeaseRef'
          label={t(`${translationPath}pendingReservedLease`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.pendingReservedLease}
          onSelectedCheckboxClicked={(e)=> 
          {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  pendingReservedLease : e.target.checked } );  
             if(!checkIfCanSelected)
               return ;

               const isAllStatusSelected = checkIsAllStatusSelected({
                ...state.units.unitStatus,
                pendingReservedLease: (e.target.checked),
              });
  
              const unitStatus = isAllStatusSelected ? {
                all: true,
                available: false,
                reservedLeased: false,
                leased: false,
                reservedSale: false,
                sale: false,
                draft: false,
                pendingReservedLease: false,
                pendingLeased: false,
                pendingReservedSale: false,
                pendingSale: false,
              } : {
                ...state.units.unitStatus,
                all: false,
                pendingReservedLease : (e.target.checked),
              };
              
            setState({id : 'units' , value : { ...state.units , unitStatus :   unitStatus  } });
                     
           

           }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusPendingLeasedRef'
          label={t(`${translationPath}pendingLeased`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.pendingLeased}
          onSelectedCheckboxClicked={(e)=> 
          {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  pendingLeased : e.target.checked } );  
             if(!checkIfCanSelected)
               return ;

               const isAllStatusSelected = checkIsAllStatusSelected({
                ...state.units.unitStatus,
                pendingLeased: (e.target.checked),
              });
  
              const unitStatus = isAllStatusSelected ? {
                all: true,
                available: false,
                reservedLeased: false,
                leased: false,
                reservedSale: false,
                sale: false,
                draft: false,
                pendingReservedLease: false,
                pendingLeased: false,
                pendingReservedSale: false,
                pendingSale: false,
              } : {
                ...state.units.unitStatus,
                all: false,
                pendingLeased : (e.target.checked),
              };
              
            setState({id : 'units' , value : { ...state.units , unitStatus :   unitStatus  } });
                     
           

          }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusPendingReservedSaleRef'
          label={t(`${translationPath}pendingReservedSale`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.pendingReservedSale}
          onSelectedCheckboxClicked={(e)=> 
         {
          const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  pendingReservedSale : e.target.checked } );  
             if(!checkIfCanSelected)
               return ;

               const isAllStatusSelected = checkIsAllStatusSelected({
                ...state.units.unitStatus,
                pendingReservedSale: (e.target.checked),
              });
  
              const unitStatus = isAllStatusSelected ? {
                all: true,
                available: false,
                reservedLeased: false,
                leased: false,
                reservedSale: false,
                sale: false,
                draft: false,
                pendingReservedLease: false,
                pendingLeased: false,
                pendingReservedSale: false,
                pendingSale: false,
              } : {
                ...state.units.unitStatus,
                all: false,
                pendingReservedSale: (e.target.checked),
              };
              
            setState({id : 'units' , value : { ...state.units , unitStatus :   unitStatus  } });
                     
                     
            
         }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='UnitStatusPendingSaleRef'
          label={t(`${translationPath}pendingSale`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.units.unitStatus.pendingSale}
          onSelectedCheckboxClicked={(e)=> 
          {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.units.unitStatus ,  pendingSale : e.target.checked } );  
             if(!checkIfCanSelected)
               return ;

               const isAllStatusSelected = checkIsAllStatusSelected({
                ...state.units.unitStatus,
                pendingSale: (e.target.checked),
              });
  
              const unitStatus = isAllStatusSelected ? {
                all: true,
                available: false,
                reservedLeased: false,
                leased: false,
                reservedSale: false,
                sale: false,
                draft: false,
                pendingReservedLease: false,
                pendingLeased: false,
                pendingReservedSale: false,
                pendingSale: false,
              } : {
                ...state.units.unitStatus,
                all: false,
                pendingSale: (e.target.checked),
              };
              
            setState({id : 'units' , value : { ...state.units , unitStatus :   unitStatus  } });
                     

           }}
         />
        </div>
     </div>
  </div>
  );
};

UnitStatus.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
};
