import React, {
  useState, useCallback, useEffect, useRef
} from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalHistory,
  bottomBoxComponentUpdate,
  WhatsAppMessage,
  showError,
  GlobalTranslate, showSuccess, getIsAllowedPermission,
  getSideMenuStatus,
  havePermissionToViewDetails,
  havePermissionToEditInformation,
  returnPropsByPermissions,
  getSideMenuComponent,
  CreateActivitiesWithContactPreference
} from '../../../Helper';

import { AdvanceSearchSecondVersion } from '../../../SharedComponents/AdvanceSearchSecondVersion';
import {
  ActionsButtonsComponent,
  ViewTypes,
  AutocompleteComponent,
  SelectComponet,
  Spinner,
  PermissionsComponent,
  Inputs
} from '../../../Components';
import {
  ActionsEnum,
  ViewTypesEnum,
  ContactTypeEnum,
  ActionsButtonsEnum,
  TableActions,
  LeadsPriorityEnum,
  LeadsTypesEnum,
  FormsIdsEnum,
  TableFilterOperatorsEnum,
  TableFilterTypesEnum,
  MediaEnum,
  LeadTab,
  AgentRoleEnum,
  Status,
  LeadsClassTypesEnum,
  DateFilterTypesEnum,
  SearchCriteriaEnum
} from '../../../Enums';
import {
  CardDetailsComponent,
  LeadsCardsComponent,
  LeadsSalesTableComponent,
} from './LeadsSalesUtilities';
import {
  ReassignLeads,
  LeadsAdvanceSearchTest,
  GetAdvanceSearchSaleLeads,
  GetAllSearchableFormFieldsByFormId,
  OrganizationUserSearch,
  lookupItemsGetId,
  CloseListOfLeads,
  GetAllContactLeadsAdvanceSearch,
  CloneLeads,
  GetAllFormFieldsByFormId
} from '../../../Services';
import { AdvanceSearch } from '../../../SharedComponents/AdvanceSearch/AdvanceSearch'
import { LeadsSalesTableHeaderData } from './LeadsSalesUtilities';
import { PaginationComponent } from '../../../Components/PaginationComponent/PaginationComponent';
import { LeadsImportDialog } from './LeadsSalesUtilities/Dialogs/LeadsImportDialog/LeadsImportDialog';
import { LeadsReassignDialog } from './LeadsSalesUtilities/Dialogs/LeadsReassignDialog/LeadsReassignDialog';
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { GlobalAdvanceSearchActions } from '../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions';
import { useTitle } from '../../../Hooks';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { LeadsSalesPermissions } from '../../../Permissions/Sales/LeadsSalesPermissions';
import { LeadsActionDialogsComponent } from '../LeadsView/LeadsUtilities/LeadsActionDialogsComponent/LeadsActionDialogsComponent';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { StaticLookupsIds } from '../../../assets/json/StaticLookupsIds';
import { CloseLeadsDialog } from '../LeadsView/LeadsUtilities/Dialogs/CloseLeadsDialog/CloseLeadsDialog';
import { QualifyLeadDialog } from '../LeadsView/LeadsUtilities/Dialogs/QualifyLeadDialog/QualifyLeadDialog';
import { CloneLeadsDialog } from '../LeadsView/LeadsUtilities/Dialogs/CloneLeadsDialog/CloneLeadsDialog';
import { SendToRoationDialog } from '../LeadsView/LeadsUtilities/Dialogs/SendToRoationDialog/SendToRoationDialog';
import { LeadsVerticalTabsData } from '../../Home/Common/OpenFileView/OpenFileUtilities/OpenFileData/LeadsVerticalTabsData';
import { ActivitiesManagementDialog } from '../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { QualificationFilter } from '../../DFMSharedComponents/QualificationFilter/QualificationFilter.View';
import { UnqualifiedLeadsDialog } from '../../DFMSharedComponents/Unqualified/UnqualifiedLeadsDialog';


const parentTranslationPath = 'LeadsView';
const translationPath = '';
export const LeadsSalesView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [addActivity, setAddActivity] = useState(false);
  const [selectFieldsOnAdvanceSearch , setSelectFieldsOnAdvanceSearch] = useState([]) ; 
  const [filterValuesFromAdvanceSearch , setFilterValuesFromAdvanceSearch ]= useState(null) ;  
  const [activeItem, setActiveItem] = useState(null);
  const [isCloseAction, setIsCloseAction] = useState(false);
  const [displyOpenFileButton, setDisplyOpenFileButton] = useState(false);
  const [displyMatchButton, setDisplyMatchButton] = useState(false);
  const [displyCloseLead, setDisplyCloseLead] = useState(false);
  const [QualificationFilterValue, setQualificationFilterValue] = useState(null);
  const [isOpenCloseLeadsDialog, setIsOpenCloseLeadsDialog] = useState(false);
  const [isOpenCloneLeadsDialog, setIsOpenCloneLeadsDialog] = useState(false);
  const [isOpenSendToRotationDialog, setIsOpenSendToRotationDialog] = useState(false);
  const [isOpenQualifyLeadDialog, setIsOpenQualifyLeadDialog] = useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const [allFormFields, setAllFormFields] = useState([]);
  const [isSearchAvite, setisSearchAvite] = useState(false);
  const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isAdvanceSearchCleared, setIsAdvanceSearchCleared] = useState(false);
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [UnqualifiedLeadsDialogDialog, setUnqualifiedLeadsDialogDialog] = useState(false);
  const [defaultValueisQualification, setdefaultValueisQualification] = useState(null);
  // const [isSaleListingAgent, setIsSaleListingAgent] = useState(false);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const advanceSearch = useSelector((state) => state.GlobalAdvanceSearchReducer);
  const [leadsTableFilter, setLeadsTableFilter] = useState(advanceSearch && advanceSearch['LeadsSaleFilter'] || null);

  const [referred, setReferred] = useState({
    by: orderFilter && orderFilter.ReferredByLeadTypeSaleFilter || null,
    to: orderFilter && orderFilter.ReferredToLeadTypeSaleFilter || null,
    mediaDetails: orderFilter && orderFilter.MediaDetailsLeadTypeSaleFilter || null

  });
  const [list, setList] = useState([
    {
      enum: TableActions.openFile.key,
    },
    {
      enum: TableActions.addActivity.key,
    },

  ]);

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: 1
  };
  const [dateFilter, setDateFilter] = useState(
    (orderFilter && orderFilter.LeadTypeSaleFilterSalesDate && orderFilter.LeadTypeSaleFilterSalesDate.startDate && orderFilter.LeadTypeSaleFilterSalesDate.endDat !== null) &&
    (
      {
        startDate: new Date(orderFilter && orderFilter.LeadTypeSaleFilterSalesDate && orderFilter.LeadTypeSaleFilterSalesDate.startDate || null),
        endDate: new Date(orderFilter && orderFilter.LeadTypeSaleFilterSalesDate && orderFilter.LeadTypeSaleFilterSalesDate.endDate || null),
        key: new Date(orderFilter && orderFilter.LeadTypeSaleFilterSalesDate && orderFilter.LeadTypeSaleFilterSalesDate.key || null),
        selectedDateType: orderFilter && orderFilter.LeadTypeSaleFilterSalesDate && orderFilter.LeadTypeSaleFilterSalesDate.selectedDateType || 1
      }) || dateRangeDefault
  );
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState({
    allLeads: false,
    referred: false,
    mediaDetails: false,
    allFormFields: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [isLoadingFormFilter, setIsLoadingFormFilter] = useState(false);
  const [isLoadingReassign, setIsLoadingReassign] = useState(false);
  const [isOpenleadsReassignDialog, setIsOpenleadsReassignDialog] = useState(false);
  const [allReferred, setAllReferred] = useState([]);
  const [allMediaName, setAllMediaName] = useState([]);
  const [activeSelectedAction, setActiveSelectedAction] = useState(ActionsButtonsEnum[1].id);
  const [leadsFiltersCriteria, setLeadsFiltersCriteria] = useState(null);
  const [isAllFormFieldsLoading, setIsAllFormFieldsLoading] = useState(false);
  const [isFirst, setFirst] = useState(false);
  const [isFirst1, setFirst1] = useState(false);
  const [checkedCards, setCheckedCards] = useState([]);
  const [checkedCardsIds, setCheckedCardsIds] = useState([]);
  const [isOpenContactsActionDialog, setisOpenContactsActionDialog] = useState(false);
  const [detailedCardAction, setdetailedCardAction] = useState(() => ({
    actionEnum: '',
    item: '',
  }));

  const loginResponse = useSelector((state) => state.login.loginResponse);


  const detailedCardActionClicked = useCallback(
    (actionEnum, item) => (event) => {
      event.stopPropagation();
      setisOpenContactsActionDialog(true);
      setdetailedCardAction({
        actionEnum,
        item,
      });
      if (actionEnum === 'whatsapp' || actionEnum === 'whatsappSolid') {
        const el = document.createElement('a');
        if (item?.contact_name?.mobile) {
          el.href = WhatsAppMessage(item.whatsAppMobile || item.contact_name.mobile);
          el.target = 'blank';
          el.click();
          CreateActivitiesWithContactPreference(actionEnum, item.id, loginResponse);
        } else
          showError(t(`${translationPath}Failure-Open-WhatsApp`));
      } else
        CreateActivitiesWithContactPreference(actionEnum, item.id, loginResponse);

      // eslint-disable-next-line no-console
    },
    [t]
  );
  const [activeActionType, setActiveActionType] = useState(
    localStorage.getItem('ViewType') &&
    JSON.parse(localStorage.getItem('ViewType')).leadsSaleView
    || ViewTypesEnum.cards.key);

  const [isExpanded, setIsExpanded] = useState(
    activeActionType === ViewTypesEnum.cardsExpanded.key
  );
  // const [isHidden, setIsHidden] = useState(false);
  const [orderByToggler, setOrderByToggler] = useState(false);
  const [leadType, setLeadType] = useState(orderFilter.ActiveLeadTypeSaleFilter || 0);
  const [status, setStatus] = useState(orderFilter.StatusTypeLeadTypeSaleFilter || 0);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  // const localStorageFilter = localStorage.getItem('GlobalFilter');
  const searchTimer = useRef(null);
  // isOpenImportDialog
  const [activeCard, setActiveCard] = useState(null);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter.leadsSaleFilter.filterBy,
    orderBy: orderFilter.leadsSaleFilter.orderBy,
  });
  const [orderBy, setOrderBy] = useState(selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: "createdOn", orderBy: 2 });
  const [detailsLeadsList, setDetailsLeadsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  // this one to change searchable form fields
  const [filterFormType] = useState(FormsIdsEnum.leadsOwner.id);
  // this one to change get contacts by type
  const [activeFormType] = useState(0);
  const [searchableFormFields, setSearchableFormFields] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [contactsFilterSearchDto, setContactsFilterSearchDto] = useState(null);
  const [searchedItem, setSearchedItem] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  // Start New Code states
  // const [leadsRes, setLeadsRes] = useState({
  //   result: [],
  //   totalCount: 0,
  // });
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',

  });
  // hi
  // End New Code
  useTitle(t(`${translationPath}leads`));

  const onTypeChanged = useCallback(
    (activeType) => {
      let ViewTypeData = JSON.parse(localStorage.getItem('ViewType'));
      if (ViewTypeData) {
        ViewTypeData.leadsSaleView = activeType;
        localStorage.setItem('ViewType', JSON.stringify(ViewTypeData));
      } else localStorage.setItem('ViewType', JSON.stringify({ leadsSaleView: activeType }));

      setActiveActionType(activeType);
      setIsExpanded(activeType === ViewTypesEnum.cardsExpanded.key);
      sideMenuIsOpenUpdate(false);
      sideMenuComponentUpdate(null);
    },
    [setActiveActionType]
  );
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const filterOnChange = (event, newValue) => {
    const emptyKeyIndex = newValue.findIndex((item) => !item.value);
    if (!searchInputValue && emptyKeyIndex !== -1) {
      newValue.splice(emptyKeyIndex, 1);
      return;
    }
    if (emptyKeyIndex !== -1) newValue[emptyKeyIndex].value = searchInputValue;
    if (filterSearchDto && Object.keys(filterSearchDto).length > 0 && newValue.length === 0) {
      onPageIndexChanged(0);
      setFilterSearchDto(null);
    }
    setSearchData([...newValue]);
  };
  // const onFilterFormTypeSelectChanged = () => {
  //   setFilterFormType(
  //     (item) =>
  //       (item === FormsIdsEnum.leadsOwner.id && FormsIdsEnum.leadsSeeker.id) ||
  //       FormsIdsEnum.leadsOwner.id
  //   );
  //   if (searchData.length > 0) {
  //     setSearchData([]);
  //     onPageIndexChanged(0);
  //     setFilterSearchDto(null);
  //   }
  // };
  // const changeActiveFormType = (value) => {
  //   setActiveFormType(value);
  //   setLeadType(0);
  // };
  const changeActiveLeadType = (value) => {
    setLeadType(value);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ActiveLeadTypeSaleFilter: value
      })
    );
  };
  const changeStatusType = (value) => {
    setStatus(value);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        StatusTypeLeadTypeSaleFilter: value
      })
    );
  };
  const changeReferredBy = (value) => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ReferredByLeadTypeSaleFilter: {
          id: (value && value.id) || '',
          fullName: (value && value.fullName) || ''
        } || ''
      })
    );
    setReferred((item) => ({ ...item, by: value || null }));
  };

  const changeReferredTo = (value) => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ReferredToLeadTypeSaleFilter: {
          id: (value && value.id) || '',
          fullName: (value && value.fullName) || ''
        } || ''
      })
    );
    setReferred((item) => ({ ...item, to: value || null }));
  };

  const changeMediaDetails = (value) => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        MediaDetailsLeadTypeSaleFilter: {
          lookupItemId: (value && value.lookupItemId) || '',
          lookupItemName: (value && value.lookupItemName) || ''
        } || ''
      })
    );
    setReferred((item) => ({ ...item, mediaDetails: value || null }));
  }


  const getAllSearchableFormFieldsByFormId = useCallback(async () => {
    setIsLoadingFormFilter(true);
    const result = await GetAllSearchableFormFieldsByFormId(filterFormType);
    if (!(result && result.status && result.status !== 200)) {
      const list = [];
      list.push({ key: 'Ids', title: 'Lead Id' });
      result.filter((item) => item.isSearchable)
        .map((item) => (list.push({
          key: item.searchableKey,
          title: item.formFieldTitle,
        })));
      setSearchableFormFields(
        list
      );
    } else setSearchableFormFields([]);
    setIsLoadingFormFilter(false);
  }, [filterFormType]);

  const getLeadsData = useCallback(async (f) => {
    if (returnPropsByPermissions(LeadsSalesPermissions.ViewAndSearchInSaleLeads.permissionsId)) {
      setIsLoading((item) => ({ ...item, allLeads: true }));
      // let saleListingAgent = false;
      // const saleListingAgentRoleIsExist = loginResponse && loginResponse.roles && loginResponse.roles.length &&
      //   loginResponse.roles.filter((x) => x.rolesName === 'Sale Listing Agent' || x.rolesName === 'admin');
      // if (saleListingAgentRoleIsExist.length === 1) {
      //   if (saleListingAgentRoleIsExist[0].rolesName === AgentRoleEnum.SaleListingAgent.name) {
      //     setIsSaleListingAgent(true);
      //     saleListingAgent = true;
      //     dispatch(
      //       GlobalOrderFilterActions.globalOrderFilterRequest({
      //         ...orderFilter,
      //         ActiveLeadTypeSaleFilter: 'Seller'
      //       })
      //     );
      //   }
      // }

      const localFilterDto = { ...f, ...filterSearchDto } || {};
      const filterLeadReferredToRole = loginResponse.roles.find((role) => role.rolesName === 'Filter Lead Referred To');
      if (filterLeadReferredToRole) {
        localFilterDto['leadReferredToId'] = [{
          searchType: TableFilterOperatorsEnum.equal.key,
          value: loginResponse.userId,
        }];
      }

      if (activeFormType) {
        localFilterDto.lead_type_id = [
          { searchType: TableFilterOperatorsEnum.equal.key, value: activeFormType },
        ];
      }
      if (leadType) {
        localFilterDto.leadClass = [
          { searchType: TableFilterOperatorsEnum.equal.key, value: (leadType || '').toLowerCase() },
        ];
      }

      if (filter.referredToId) {
        localFilterDto['referredto.name'] = [
          { searchType: TableFilterOperatorsEnum.equal.key, value: filter.referredToId },
        ];
      }
      if (filter.referredById) {
        localFilterDto['referredby.name'] = [
          { searchType: TableFilterOperatorsEnum.equal.key, value: filter.referredById },
        ];
      }
      if (status) {
        localFilterDto['status.lookupItemName'] = [
          { searchType: TableFilterOperatorsEnum.equal.key, value: (status || '').toLowerCase() },
        ];
      }
      if (referred.by && referred.by.id) {
        localFilterDto['referredbyid'] = [
          { searchType: TableFilterOperatorsEnum.equal.key, value: referred.by.id || '' },
        ];
      }
      if (referred.to && referred.to.id) {
        localFilterDto['referredtoid'] = [
          { searchType: TableFilterOperatorsEnum.equal.key, value: referred.to.id || '' },
        ];
      } if (QualificationFilterValue && QualificationFilterValue !== null) {
        localFilterDto.LeadQualificationId = [
          QualificationFilterValue,
        ];
      }
      if (referred.mediaDetails && referred.mediaDetails.lookupItemName) {
        localFilterDto['media_detail.lookupItemName'] = [
          { searchType: TableFilterOperatorsEnum.equal.key, value: (referred.mediaDetails.lookupItemName || '').toLowerCase() },
        ];
      }
      if (leadsTableFilter) {
        Object.values(leadsTableFilter)
          .filter((item) => item && item.searchableKey || item.displayPath)
          .map((item) => {
            if (localFilterDto[item && item.searchableKey || item.displayPath]) {
              localFilterDto[item && item.searchableKey || item.displayPath].push({
                searchType: item.operator,
                value: item.value,
              });
            } else if (item.value) {
              localFilterDto[item && item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: item.value,
                },
              ];
            } else if (!item.value && (item.operator === TableFilterOperatorsEnum.isNotBlank.key
              || item.operator === TableFilterOperatorsEnum.isBlank.key)) {
              localFilterDto[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: null,
                },
              ];
            }
            return undefined;
          });
      }
      setLeadsFiltersCriteria(localFilterDto);

      if (Object.values(localFilterDto).length) setIsAdvanceSearchActive(true);
      else setIsAdvanceSearchActive(false);

      const body = {
        criteria: localFilterDto,
        ...orderBy,
        OperationType: 'Sale',
      };
      setIsDatePickerChanged(false)
      if (dateFilter && dateFilter.startDate && dateFilter.endDate) {

        const fromDate = moment(dateFilter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const toDate = moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss');

        if (dateFilter.selectedDateType === DateFilterTypesEnum.CreatedOnDate.key) {
          body.fromDate = fromDate;
          body.toDate = toDate;
        } else if (dateFilter.selectedDateType === DateFilterTypesEnum.UpdatedOnDate.key) {
          body.updatedFromDate = fromDate;
          body.updatedToDate = toDate;
        } else if (dateFilter.selectedDateType === DateFilterTypesEnum.LastAssignDate.key) {
          body.assignedFromDate = fromDate;
          body.assignedToDate = toDate;
        }
      }

      if (leadsTableFilter && leadsTableFilter.lastActivtyTypeName && leadsTableFilter.lastActivtyTypeName.value !== null && leadsTableFilter.lastActivtyTypeName.value !== '')
        body.lastActivtyTypeName = leadsTableFilter.lastActivtyTypeName.value;

      if (leadsTableFilter && leadsTableFilter.lastActivityCreatedByName && leadsTableFilter.lastActivityCreatedByName.value !== null && leadsTableFilter.lastActivityCreatedByName.value !== '')
        body.lastActivityCreatedByName = leadsTableFilter.lastActivityCreatedByName.value;

      const res = await LeadsAdvanceSearchTest(filter, body);
      if (!(res && res.status && res.status !== 200)) {
        setDetailsLeadsList({
          result: ((res && res.result) || []).map((item) => {

            const lead = item.leadJson && JSON.parse(item.leadJson).lead;
            const leadTypeIndex = Object.values(LeadsClassTypesEnum).findIndex(
              (element) => element.key === item.leadClass
            );
            const leadClass =
              (leadTypeIndex !== -1 &&
                Object.values(LeadsClassTypesEnum)[leadTypeIndex].name) ||
              null;
            item.country = { lookupItemName: item.country }
            item.city = { lookupItemName: item.city }
            item.district = { lookupItemName: item.district }
            item.community = { lookupItemName: item.community }
            item.subCommunity = { lookupItemName: item.subCommunity }
            if (lead) {
              return {
                ...lead,
                leadClass,
                id: item.leadId,
                bulkUpload: item.bulkUpload,
                leadTypeId: lead.lead_type_id,
                imagePath: null,
                lastActivtyTypeName: item.lastActivtyTypeName,
                lastActivityComment: item.lastActivityComment,
                unitType: item.leadUnitType,
                country: item.country,
                city: item.city,
                district: item.district,
                community: item.community,
                subCommunity: item.subCommunity,
                createdBy: item.createdBy,
                updatedBy: item.updatedBy,
                budgetTo: lead.budgetTo || '',
                budgetFrom: lead.budgetFrom || '',
                sizeAreaTo: item.sizeAreaTo,
                sizeAreaFrom: item.sizeAreaFrom,
                lastActivityCreatedByName: item.lastActivityCreatedByName,
                assignDate: moment(item.assignDate).isValid() && item.assignDate || null,
                name: `${(lead.contact_name && lead.contact_name.name) || ''}`,
                contactPreferences: item.contactPreferences && item.contactPreferences.split(','),
                whatsAppMobile: item.whatsAppMobile,
                matchingUnits: lead.matching_units || [],
                matchingUnitsNumber: item.matchUnit || 0,
                createdOn: moment(item.createdOn).isValid() && item.createdOn || null,
                lastActivityDate: moment(item.lastActivityDate).isValid() && item.lastActivityDate || null,
                updateDate: item.updateOn,
                type: ContactTypeEnum.man.value,
                leadType:
                  (lead.lead_type_id === 1 &&
                    ((LeadsTypesEnum.Owner && LeadsTypesEnum.Owner.value) || 'N/A')) ||
                  (lead.lead_type_id === 2 &&
                    ((LeadsTypesEnum.Seeker && LeadsTypesEnum.Seeker.value) || 'N/A')) ||
                  'N/A',
                rating: lead.rating || '',
                fitting_and_fixtures:
                  lead.fitting_and_fixtures && lead.fitting_and_fixtures.length > 0 ?
                    lead.fitting_and_fixtures.map((el) => `${el.lookupItemName}, `) :
                    [],
                size_sqft: lead.size_sqft && lead.size_sqft.join(' * ') || '',

                budget: lead.budgetTo && lead.budgetFrom && `${lead.budgetFrom} - ${lead.budgetTo}` || '',

                view: lead.view && lead.view.map((el) => `${el.lookupItemName}, `) || '',
                developer: lead.developer && lead.developer.map((el) => `${el.name}, `) || '',

                progress:
                  lead.data_completed && typeof lead.data_completed === 'string' && lead.data_completed.includes('%') ?
                    +lead.data_completed.substr(0, lead.data_completed.length - 1) :
                    +lead.data_completed,
                progressWithPercentage:
                  lead.data_completed && typeof lead.data_completed !== 'string' ?
                    `${lead.data_completed}%` :
                    lead.data_completed,
                status: (lead && lead.status) || 'N/A',
                flatContent: lead.lead_type_id === 2 && [
                  {
                    iconClasses: 'mdi mdi-cash-multiple',
                    title: null,
                    value: lead.budget ?
                      lead.budget.map(
                        (element, index) =>
                          `${element}${(index < lead.budget.length - 1 && ',') || ''} `
                      ) :
                      'N/A',
                  },
                  {
                    iconClasses: 'mdi mdi-bed',
                    title: null,
                    value: lead && lead.bedrooms && lead.bedrooms.length === 0 ? GlobalTranslate.t('Shared:any') : ((lead.bedrooms &&
                      lead.bedrooms.map(
                        (element, index) =>
                          `${element}${(index < lead.bedrooms.length - 1 && ',') || ''} `
                      )) ||
                      GlobalTranslate.t('Shared:any')),
                  },
                  {
                    iconClasses: 'mdi mdi-shower',
                    title: null,
                    value: lead && lead.bathrooms && lead.bathrooms.length === 0 ? GlobalTranslate.t('Shared:any') : (lead.bathrooms &&
                      lead.bathrooms.map(
                        (element, index) =>
                          `${element}${(index < lead.bathrooms.length - 1 && ',') || ''} `
                      )) ||
                      GlobalTranslate.t('Shared:any'),

                  },
                  {
                    iconClasses: 'mdi mdi-ruler-square',
                    title: 'sqf',
                    value: lead.size_sqft ?
                      lead.size_sqft.map(
                        (element, index) =>
                          `${element}${(index < lead.size_sqft.length - 1 && ',') || ''} `
                      ) :
                      'N/A',
                  },
                ],
                details: [
                  {
                    iconClasses: 'mdi mdi-clipboard-account-outline',
                    title: 'lead-type',
                    value:
                      lead.lead_type_id === 1 ?
                        t(`${translationPath}owner`) :
                        t(`${translationPath}seeker`),
                  },
                  {
                    iconClasses: 'mdi mdi-account-circle',
                    title: 'stage',
                    value: lead.lead_stage ? lead.lead_stage.lookupItemName : 'N/A',
                  },
                  {
                    iconClasses: 'mdi mdi-account-box',
                    title: 'contact-name',
                    value: lead.contact_name ? lead.contact_name.name : 'N/A',
                  },

                  {
                    iconClasses: 'mdi mdi-table-furniture',
                    title: 'equipments-and-fixtures',
                    value:
                      (lead.fitting_and_fixtures &&
                        lead.fitting_and_fixtures.map(
                          (element, index) =>
                            `${element.lookupItemName}${(index < lead.fitting_and_fixtures.length - 1 && ',') || ''
                            } `
                        )) ||
                      'N/A',
                  },
                  {
                    iconClasses: 'mdi mdi-window-open-variant',
                    title: 'views',
                    value:
                      (lead.view &&
                        ((Array.isArray(lead.view) &&
                          lead.view.map(
                            (element, index) =>
                              `${element.lookupItemName}${(index < lead.view.length - 1 && ',') || ''} `
                          )) ||
                          (typeof lead.view === 'object' && lead.view.lookupItemName) ||
                          'N/A')) ||
                      'N/A',
                  },
                  {
                    iconClasses: 'mdi mdi-laptop-windows',
                    title: 'developers',
                    value:
                      (lead.developers &&
                        lead.developers.map(
                          (element, index) =>
                            `${element.lookupItemName}${(index < lead.developers.length - 1 && ',') || ''
                            } `
                        )) ||
                      'N/A',
                  },
                ],

              };
            }
          }),
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setDetailsLeadsList({
          result: [],
          totalCount: 0,
        });
      }

      setIsLoading((item) => ({ ...item, allLeads: false }));
    }
  }, [status, activeFormType, filter, filterSearchDto, leadType, leadsTableFilter, orderBy, t, referred, dateFilter]);

  const getContactLeadsData = useCallback(async () => {
    const body = {
      criteria: contactsFilterSearchDto,
      ...orderBy,
    };
    setIsLoading((item) => ({ ...item, allLeads: true }));
    const leadTab = LeadTab.Sale;
    const res = await GetAllContactLeadsAdvanceSearch(leadTab, filter, body);

    if (!(res && res.status && res.status !== 200)) {
      setDetailsLeadsList({
        result: ((res && res.result) || []).map((item) => {
          const { lead } = item;
          if (lead) {
            return {
              leadClass: (lead.leadClass && lead.leadClass) || 'N/A',
              id: item.leadId,
              leadTypeId: lead.lead_type_id,
              imagePath: null,
              lastActivtyTypeName: item.lastActivtyTypeName,
              lastActivityComment: item.lastActivityComment,
              unitType: item.unitType,
              activityCreatedBy: item.activityCreatedBy,
              name: `${(lead.contact_name && lead.contact_name.name) || 'N/A'}`,
              matchingUnits: (lead.matching_units && lead.matching_units) || [],
              matchingUnitsNumber: (lead.matching_units && lead.matching_units.length) || 0,
              creationDate: item.createdOn,
              updateDate: item.updateOn,
              type: ContactTypeEnum.man.value,
              leadType:
                (lead.lead_type_id === 1 &&
                  ((LeadsTypesEnum.Owner && LeadsTypesEnum.Owner.value) || 'N/A')) ||
                (lead.lead_type_id === 2 &&
                  ((LeadsTypesEnum.Seeker && LeadsTypesEnum.Seeker.value) || 'N/A')) ||
                'N/A',
              rating: lead.rating ?
                (lead.rating.lookupItemName &&
                  (LeadsPriorityEnum[lead.rating.lookupItemName] || '')) ||
                '' :
                '',
              progress:
                typeof lead.data_completed === 'string' && lead.data_completed.includes('%') ?
                  +lead.data_completed.substr(0, lead.data_completed.length - 1) :
                  +lead.data_completed,
              progressWithPercentage:
                typeof lead.data_completed !== 'string' ?
                  `${lead.data_completed}%` :
                  lead.data_completed,
              // price: '$2.200',
              status:
                (lead && lead.status &&
                  lead.status.lookupItemName) ||
                'N/A',
              flatContent: lead.lead_type_id === 2 && [
                {
                  iconClasses: 'mdi mdi-cash-multiple',
                  title: null,
                  value: lead.budget ?
                    lead.budget.map(
                      (element, index) =>
                        `${element}${(index < lead.budget.length - 1 && ',') || ''} `
                    ) :
                    'N/A',
                },
                {
                  iconClasses: 'mdi mdi-bed',
                  title: null,
                  value: lead && lead.bedrooms && lead.bedrooms.length === 0 ? GlobalTranslate.t('Shared:any') : ((lead.bedrooms &&
                    lead.bedrooms.map(
                      (element, index) =>
                        `${element}${(index < lead.bedrooms.length - 1 && ',') || ''} `
                    )) ||
                    GlobalTranslate.t('Shared:any')),
                },
                {
                  iconClasses: 'mdi mdi-shower',
                  title: null,
                  value: lead && lead.bathrooms && lead.bathrooms.length === 0 ? GlobalTranslate.t('Shared:any') : (lead.bathrooms &&
                    lead.bathrooms.map(
                      (element, index) =>
                        `${element}${(index < lead.bathrooms.length - 1 && ',') || ''} `
                    )) ||
                    GlobalTranslate.t('Shared:any'),

                },
                {
                  iconClasses: 'mdi mdi-ruler-square',
                  title: 'sqf',
                  value: lead.size_sqft ?
                    lead.size_sqft.map(
                      (element, index) =>
                        `${element}${(index < lead.size_sqft.length - 1 && ',') || ''} `
                    ) :
                    'N/A',
                },
              ],
              details: [
                {
                  iconClasses: 'mdi mdi-clipboard-account-outline',
                  title: 'lead-type',
                  value:
                    lead.lead_type_id === 1 ?
                      t(`${translationPath}owner`) :
                      t(`${translationPath}seeker`),
                },
                {
                  iconClasses: 'mdi mdi-account-circle',
                  title: 'stage',
                  value: lead.lead_stage ? lead.lead_stage.lookupItemName : 'N/A',
                },
                {
                  iconClasses: 'mdi mdi-account-box',
                  title: 'contact-name',
                  value: lead.contact_name ? lead.contact_name.name : 'N/A',
                },

                {
                  iconClasses: 'mdi mdi-table-furniture',
                  title: 'equipments-and-fixtures',
                  value:
                    (lead.fitting_and_fixtures &&
                      lead.fitting_and_fixtures.map(
                        (element, index) =>
                          `${element.lookupItemName}${(index < lead.fitting_and_fixtures.length - 1 && ',') || ''
                          } `
                      )) ||
                    'N/A',
                },
                {
                  iconClasses: 'mdi mdi-window-open-variant',
                  title: 'views',
                  value:
                    (lead.view &&
                      ((Array.isArray(lead.view) &&
                        lead.view.map(
                          (element, index) =>
                            `${element.lookupItemName}${(index < lead.view.length - 1 && ',') || ''} `
                        )) ||
                        (typeof lead.view === 'object' && lead.view.lookupItemName) ||
                        'N/A')) ||
                    'N/A',
                },
                {
                  iconClasses: 'mdi mdi-laptop-windows',
                  title: 'developers',
                  value:
                    (lead.developers &&
                      lead.developers.map(
                        (element, index) =>
                          `${element.lookupItemName}${(index < lead.developers.length - 1 && ',') || ''
                          } `
                      )) ||
                    'N/A',
                },
              ],
              ...lead,
            }
          };
        }),
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setDetailsLeadsList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((item) => ({ ...item, allLeads: false }));
  });

  const searchClicked = async () => {
    if (searchData.length === 0) return;
    localStorage.setItem('LeadsSalesFilter', JSON.stringify(searchData));
    setSearchedItem('');
    setFilterSearchDto(
      searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
    );
    onPageIndexChanged(0);
  };

  const searchchachedClickedWithoutFilter = async (data) => {
    if (data.length === 0) return;
    const oldfilter = data.reduce((total, item) => {
      if (total[item.key]) {
        total[item.key].push({
          searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
          value: item.value,
        });
      } else {
        total[item.key] = [
          {
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          },
        ];
      }
      return total;
    }, {});
    getLeadsData(oldfilter);
  };

  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      localStorage.setItem('leadStatus', JSON.stringify(item.status));
      if (actionEnum === TableActions.openFile.key) {
        GlobalHistory.push(
          `/home/lead-sales/lead-profile-edit?formType=${item.leadTypeId}&id=${item.id}&leadClass=${item.leadClass}`
        );
      } else if (actionEnum === TableActions.editText.key)
        GlobalHistory.push(`/home/lead-sales/edit?formType=${item.leadTypeId}&id=${item.id}&leadClass=${item.leadClass}`);

      else if (actionEnum === TableActions.addActivity.key) {
        setAddActivity(true);
        setActiveItem(item);
      }

    },
    [dispatch]
  );
  const displayedLabel = (option) => `${option.title}: ${searchInputValue}`;
  const disabledOptions = (option) => option.disabledOnSelect;
  const chipsLabel = (option) => `${option.title}: ${option.value}`;
  const inputValueChanged = (event, newInputValue) => {
    setSearchInputValue(newInputValue);
  };
  const onActionButtonChanged = (activeAction) => {
    setActiveSelectedAction(activeAction);
    if (activeAction === ActionsButtonsEnum[5].id) {
      changeActiveLeadType(0);
      changeStatusType('Open');

    }
    else if (activeAction === ActionsButtonsEnum[6].id ||
      activeAction === ActionsButtonsEnum[8].id ||
      activeAction === ActionsButtonsEnum[12].id) {
      changeActiveLeadType('Buyer');
      changeStatusType('Open');

    } else if (activeAction === ActionsButtonsEnum[14].id) {
      changeActiveLeadType('Seller');
      changeStatusType('Open');
    } else if (activeAction === ActionsButtonsEnum[7].id) {
      changeActiveLeadType('Buyer');
      changeStatusType('Closed');
    }
    setIsCloseAction(false);
    setCheckedCards([]);
    setCheckedCardsIds([]);
  };
  const onQualificationFilterButtonClicked = (activeAction) => {
    if (activeAction === null) {
      setQualificationFilterValue(null);
      const index = searchData.findIndex((item) => item.key === 'LeadQualificationId');
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem('LeadQualificationId');
      setQualificationFilterValue(null);
      getLeadsData();
    } else {
      setdefaultValueisQualification({
        searchType: 1,
        value: activeAction?.item?.lookupItemId || ''
      })
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          LeadQualificationId: {
            LeadQualificationId: [
              {
                searchType: 1,
                value: activeAction?.item?.lookupItemId || ''
              }
            ]
          }
        })
      );
      setFilterSearchDto(orderFilter.LeadQualificationId)
      setQualificationFilterValue(
        {
          searchType: 1,
          value: activeAction?.item?.lookupItemId || ''


        });
    }
  };
  const onActionsButtonClicked = useCallback(
    (activeAction) => {
      if (activeAction === ActionsButtonsEnum[3].id) setIsOpenImportDialog(true);
      if (activeAction === ActionsButtonsEnum[2].id) {
        GlobalHistory.push(
          `/home/lead-sales/merge?firstId=${checkedCardsIds[0]}&secondId=${checkedCardsIds[1]}&leadTypeId=${checkedCards[0].leadTypeId}`
        );
      }
      if (activeAction === ActionsButtonsEnum[5].id) setIsOpenCloseLeadsDialog(true);
      if (activeAction === ActionsButtonsEnum[6].id || activeAction === ActionsButtonsEnum[14].id) setIsOpenleadsReassignDialog(true);
      if (activeAction === ActionsButtonsEnum[7].id) setIsOpenCloneLeadsDialog(true);
      if (activeAction === ActionsButtonsEnum[8].id) setIsOpenSendToRotationDialog(true);
      if (activeAction === ActionsButtonsEnum[12].id) setIsOpenQualifyLeadDialog(true);
      if (activeAction === ActionsButtonsEnum[13].id) setUnqualifiedLeadsDialogDialog(true);

    },
    [checkedCards, checkedCardsIds]
  );

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      localStorage.setItem('leadStatus', JSON.stringify(activeData.status));

      dispatch(ActiveItemActions.activeItemRequest(activeData));
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        GlobalHistory.push(
          `/home/lead-sales/lead-profile-edit?formType=${activeData.leadTypeId}&id=${activeData.id}&leadClass=${activeData.leadClass}`
        );
      } else if (actionEnum === ActionsEnum.matching.key) {
        GlobalHistory.push(
          `/home/lead-sales/lead-profile-edit?formType=${activeData.leadTypeId}&id=${activeData.id}&matching=${true}&leadClass=${activeData.leadClass}`

        );
      }
    },
    [dispatch]
  );

  const searchHandler = (data) => {
    const newV = [{
      key: 'All',
      title: 'All',
      value: data
    }];

    if (searchTimer.current) clearTimeout(searchTimer.current);
    if (data === '') {
      const index = searchData.findIndex((item) => item.key === 'All');
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem('LeadsSalesFilter');
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newV]);
        setFilterSearchDto({
          All:
            [
              {
                value: data
              }
            ]
        });
      }, 800);
    }
  };

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        leadsSaleFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };
  const cardCheckboxClicked = useCallback((itemIndex, element) => {
    setCheckedCards((items) => {
      const index = items.findIndex((item) => item.id === element.id);
      if (index !== -1) items.splice(index, 1);
      else {
        const isSelectOneOnly = activeSelectedAction === ActionsButtonsEnum[12].id &&
          (items && items.length == 1);
        if (isSelectOneOnly) return [...items];
        items.push(element);
      }
      return [...items];
    });
    setCheckedCardsIds((items) => {
      const index = items.findIndex((item) => item === element.id);
      if (index !== -1) items.splice(index, 1);
      else {
        const isSelectOneOnly = activeSelectedAction === ActionsButtonsEnum[12].id &&
          (items && items.length == 1);
        if (isSelectOneOnly) return [...items];
        items.push(element.id);
      }
      return [...items];
    });
  }, [activeSelectedAction]);

  const reloadData = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    setActiveSelectedAction('');
    setCheckedCards([]);
    setCheckedCardsIds([]);
    getLeadsData();
  }, [getLeadsData]);

  const onCardClick = useCallback(
    (item, selectedIndex) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveCard(item);
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (getSideMenuStatus()) setAdvanceSearchBtn(true)
      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={detailsLeadsList.result[selectedIndex]}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          loginResponse={loginResponse}
          relodedata={reloadData}
          onActionClicked={detailedCardActionClicked}
          displyOpenFileButton={displyOpenFileButton}
          displyMatchButton={displyMatchButton}
          displyCloseLead={displyCloseLead}
          checkIfSensitiveField={checkIfSensitiveField}

        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [detailedCardActionClicked, detailedCardSideActionClicked, detailsLeadsList.result, dispatch, loginResponse, reloadData, advanceSearchBtn]
  );

  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    Promise.all([
      await GetAllFormFieldsByFormId(FormsIdsEnum.leadsOwner.id),
      await GetAllFormFieldsByFormId(FormsIdsEnum.leadsSeeker.id),
    ])
      .then((result) => {
        if (Array.isArray(result[0]) && Array.isArray(result[1])) {
          const concantinateFields = result[0]
            .concat(result[1])
            .filter(
              (field, index, array) =>
                array.findIndex((element) => element.formFieldKey === field.formFieldKey) === index
            );
          const list = concantinateFields.filter(e =>
            e.formFieldName !== 'fitting_and_fixtures' &&
            e.formFieldName !== 'view' &&
            e.formFieldName !== 'developer' &&
            e.formFieldName !== 'bathrooms' &&
            e.formFieldName !== 'bedrooms' &&
            e.formFieldName !== 'size_sqft' &&
            e.formFieldName !== 'budget' &&
            e.formFieldName !== 'propertyunit_type'
          ).map((field) => ({
            id: field.formFieldId || null,
            key: field.formFieldKey || null,
            isDate: field.uiWidgetType === 'alt-date' || false,
            label: (field.formFieldTitle === "Lead Auditor" ? field.formFieldTitle = "Lead Owner" : field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
            input: field.displayPath || '',
            isNumber:
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size'
            ,
            isSortable: true,
            searchableKey: field.searchableKey,
            data :JSON.parse(field.propertyJson).schema  ,
            fieldType : 
             (field.propertyJson && JSON.parse(field.propertyJson).schema.uiType) ||
             (field.propertyJson && JSON.parse(field.propertyJson).uiSchema['ui:widget'])  ||
             (field.uiWidgetType === null && 'text' ) ,
          }))
          setAllFormFields(list)
        } else setAllFormFields([]);
        setIsAllFormFieldsLoading(false);

      })
      .catch(() => {
        setAllFormFields([]);
        setIsAllFormFieldsLoading(false);
      });
  }, []);

  const GetSideMenuStatus = useCallback(() => {
    let isMenuOpen = getSideMenuStatus();
    let activeComponentOpen = getSideMenuComponent() && getSideMenuComponent().type;

    if (!isMenuOpen) setAdvanceSearchBtn(true);
    else if (activeComponentOpen === AdvanceSearch) setAdvanceSearchBtn(false);
  });

  const onAdvanceSearchClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();

    if (advanceSearchBtn) {
      sideMenuIsOpenUpdate(true);
      setAdvanceSearchBtn(false);
    } else {
      sideMenuIsOpenUpdate(false);
      setAdvanceSearchBtn(true);
    }
    const searchAdvanceSearch = (newFilter) => 
    {
      const searchObj =   newFilter ? {...newFilter} : null ; 
      if (!(newFilter &&  newFilter.length)) 
      localStorage.removeItem('LeadsSaleFilter');
  
  
        dispatch(
          GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
             ...advanceSearch,
             LeadsSaleFilter: searchObj || null   , 
          })
        );
        setLeadsTableFilter(searchObj);
       
    };

    sideMenuComponentUpdate(
      <AdvanceSearchSecondVersion
        filterData={[...LeadsSalesTableHeaderData, ...allFormFields]}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onFilterValuesChanged={onFilterValuesChanged}
        localStorageKey='LeadsSaleFilter'
        isAdvanceSearchCleared={isAdvanceSearchCleared}
        setIsAdvanceSearchCleared={setIsAdvanceSearchCleared}
        setOrderBy={setOrderBy}
        searchCriteriaTypeId={SearchCriteriaEnum.Lead.typeId}
        setIsAdvanceSearchActive={setIsAdvanceSearchActive}
        fields={[...LeadsSalesTableHeaderData, ...allFormFields]}
        selectFieldsOnAdvanceSearch= {selectFieldsOnAdvanceSearch}
        setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
        setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
        filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
        searchAdvanceSearch={searchAdvanceSearch}
      />

    );
  }, [isAllFormFieldsLoading, allFormFields, advanceSearchBtn, isAdvanceSearchCleared , selectFieldsOnAdvanceSearch]);


  const onClearedAllFiltersClick = useCallback(() => {
    setFilterSearchDto(null);
    localStorage.removeItem('LeadQualificationId');
    setQualificationFilterValue(null);
    setdefaultValueisQualification(null);
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: "createdOn", orderBy: 2 })
    setSearchData([]);
    setDateFilter(dateRangeDefault);
    setReferred((item) => ({ ...item, by: null, to: null, mediaDetails: null }));
    setLeadType(0);
    setStatus(0);
    setIsClearFiltersClicked(true);
    setIsAdvanceSearchCleared(true);
    setFilterValuesFromAdvanceSearch() ; 
    setSelectFieldsOnAdvanceSearch([]) ; 
    setLeadsTableFilter(null);
    setFilterSearchDto(null);
    sideMenuIsOpenUpdate(false);
    setIsAdvanceSearchActive(false);
    setActiveSelectedAction('add');

    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ReferredByLeadTypeSaleFilter: {
          id: '',
          fullName: ''
        },

        ReferredToLeadTypeSaleFilter: {
          id: '',
          fullName: ''
        },
        MediaDetailsLeadTypeSaleFilter: {
          lookupItemId: '',
          lookupItemName: ''
        },
        leadsSaleFilter: {
          filterBy: null,
          orderBy: null,
        },
        ActiveLeadTypeSaleFilter: 0,
        StatusTypeLeadTypeSaleFilter: 0
      })
    );

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        LeadsSaleFilter: null
      })
    );

  }, []);

  const focusedRowChanged = useCallback(
    (rowIndex, item) => {
      if (rowIndex !== -1) {
        sideMenuComponentUpdate(
          <CardDetailsComponent
            activeData={detailsLeadsList.result[rowIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            loginResponse={loginResponse}
            onActionClicked={detailedCardActionClicked}
            relodedata={reloadData}
            displyOpenFileButton={displyOpenFileButton}
            displyMatchButton={displyMatchButton}
            displyCloseLead={displyCloseLead}
            checkIfSensitiveField={checkIfSensitiveField}

          />
        );
        sideMenuIsOpenUpdate(true);
      } else {
        sideMenuComponentUpdate(<></>);
        sideMenuIsOpenUpdate(false);
      }

      let list1 = [];
      if (item && item.status && item.status.lookupItemId && displyOpenFileButton) {
        list1.push({
          enum: TableActions.openFile.key,
          isDisabled: false,
          externalComponent: null,
        });
      }
      if ((returnPropsByPermissions(LeadsSalesPermissions.AddNewActivity.permissionsId)) && item && item.status && item.status.lookupItemId !== StaticLookupsIds.Closed) {
        list1.push({
          enum: TableActions.addActivity.key,
          isDisabled: false,
          externalComponent: null,
        });
      }
      setList([...list1]);
    },
    [detailedCardActionClicked, detailedCardSideActionClicked, detailsLeadsList.result, loginResponse, reloadData, returnPropsByPermissions]
  );
  const onFormTypeSelectChanged = (formType) => {
    localStorage.removeItem('leadStatus');
    GlobalHistory.push(`/home/lead-sales/add?formType=${formType}`);
  };

  const resetAllValues = () => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    setCheckedCards([]);
    setCheckedCardsIds([]);
    setIsCloseAction(true);
    setActiveSelectedAction("add");
  };
  // const getIsSelected = useCallback(
  //   (row) => checkedCards && checkedCards.findIndex((item) => item.id === row.id) !== -1,
  //   [checkedCards]
  // );

  const getIsDisabled = useCallback((row) =>
    activeSelectedAction === ActionsButtonsEnum[6].id &&
    (row.status.lookupItemName === 'Closed' || row.leadClass === 'Landlord' || row.leadClass === 'Seller'),
    [checkedCards, checkedCardsIds]);

    
  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      const itemIndex = checkedCardsIds ? checkedCardsIds.findIndex((item) => item === row.id) : -1;
      if (itemIndex !== -1) {
        checkedCardsIds.splice(itemIndex, 1);
        setCheckedCards((items) => {
          const elementIndex = items.findIndex((item) => item.id === row.id);
          if (elementIndex !== -1) items.splice(elementIndex, 1);
          return [...items];
        });
      } else {
        const isSelectOneOnly = activeSelectedAction === ActionsButtonsEnum[12].id &&
          (checkedCardsIds && checkedCardsIds.length == 1);
        if (isSelectOneOnly) return;

        checkedCardsIds.push(row.id);
        setCheckedCards((items) => {
          items.push(row);
          return [...items];
        });
      }
      setCheckedCardsIds(checkedCardsIds);
    },
    [checkedCardsIds, activeSelectedAction]
  );
  const onFilterValuesChanged = (newValue) => {
    setLeadsTableFilter(newValue);
  };

  const checkIfSensitiveField = (contactPreferenceType, item, contact_type) => {
    if (+contact_type === 1) {
      if (contactPreferenceType === 'Email' && item && item.name && item.name === '********')
        return false;
      else if (contactPreferenceType === 'SMS' && item && item.name && item.name === '********')
        return false;
      else if (contactPreferenceType === 'WhatsApp' && (item && item.name && item.name === '********'))
        return false;
      else if (contactPreferenceType === 'Call' && (item && item.name && item.name === '********'))
        return false;
    }
    else if (+contact_type === 2) {
      if (contactPreferenceType === 'Email' && (item && item.name && item.name === '********'))
        return false;
      else if (contactPreferenceType === 'SMS' && ((item && item.name && item.name === '********')))
        return false;
      else if (contactPreferenceType === 'WhatsApp' && (item && item.name && item.name === '********'))
        return false;
      else if (contactPreferenceType === 'Call' && (item && item.name && item.name === '********'))
        return false;
    }
    return true;
  };



  const getAllReferred = useCallback(async (searchValue) => {
    //  setIsLoading(true);
    setIsLoading((item) => ({ ...item, referred: true }));
    const res = await OrganizationUserSearch({
      pageIndex: 0, pageSize: 10, name: searchValue, userStatusId: Status.Active.value
    });
    if (!(res && res.status && res.status !== 200)) {
      setAllReferred({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllReferred({
        result: [],
        totalCount: 0,
      });
    }
    // setIsLoading(false);
    setIsLoading((item) => ({ ...item, referred: false }));
  }, []);
  const getAllMediaName = useCallback(async () => {
    // setIsLoading(true);
    setIsLoading((item) => ({ ...item, mediaDetails: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: MediaEnum.MediaDetailsId.lookupTypeId
    });
    if (!(res && res.status && res.status !== 200))
      setAllMediaName(res);
    else
      setAllMediaName([]);

    // setIsLoading(false);
    setIsLoading((item) => ({ ...item, mediaDetails: false }));
  }, []);

  const checkBulkDesabled = (enums) => {
    if (enums === ActionsButtonsEnum[5].id ||
      enums === ActionsButtonsEnum[6].id ||
      enums === ActionsButtonsEnum[7].id ||
      enums === ActionsButtonsEnum[8].id ||
      enums === ActionsButtonsEnum[12].id ||
      enums === ActionsButtonsEnum[13].id ||
      enums === ActionsButtonsEnum[14].id
    )
      return !(checkedCards && checkedCards.length >= 1);
    return false;
  };

  const closeLeads = useCallback(async (item) => {
    resetAllValues();
    showSuccess(t(`${translationPath}close-lead-notification`));
    await CloseListOfLeads(item);
  }, []);

  const cloneLeads = useCallback(async (items) => {
    resetAllValues();
    const result = await CloneLeads(items);
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t(`${translationPath}clone-leads-notification`));
    } else { showError(t(`${translationPath}leads-clone-failed`)); }

  }, []);

  const getIsSelected = useCallback(
    (row) => checkedCardsIds && checkedCardsIds.findIndex((item) => item === row.id) !== -1,
    [checkedCardsIds]
  );

  const getIsSelectedAll = useCallback(
    () => {
      const returnSelect = (checkedCardsIds &&
        detailsLeadsList.result.findIndex((item) => !checkedCardsIds.includes(item.id)) === -1) || false;
      return returnSelect;
    }
  );

  const reassignHandler = async (reassignItem) => {

    setFilter((item) => ({ ...item, pageIndex: 0, leadStatus: null }));
    showSuccess(t(`${translationPath}leads-reassign-notification`));
    setIsOpenleadsReassignDialog(false);
    await ReassignLeads({ leadIds: checkedCardsIds, referredToId: reassignItem.referredToId, isCopyTo: reassignItem.isCopyTo });

  };
  const onSelectAllClicked = () => {
    const cardItmes = [];
    if (!getIsSelectedAll()) {
      detailsLeadsList.result.map((item) => {
        if (!getIsSelected(item)) {
          checkedCardsIds.push(item.id);
          cardItmes.push({ ...item });
        }
      });
    } else {
      detailsLeadsList.result.map((item) => {
        if (getIsSelected(item)) {
          const isSelectedIndex = checkedCardsIds.findIndex(
            (element) => element === item.id
          );
          if (isSelectedIndex !== -1) checkedCardsIds.splice(isSelectedIndex, 1);
        }
      });
    }
    setCheckedCards(cardItmes);
  };

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId])

  useEffect(() => {
    getAllReferred();
    getAllMediaName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (isFirst && isDatePickerChanged) {

      const searchDataFilter = searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
      getLeadsData(searchDataFilter);
    }
  }, [dateFilter, isDatePickerChanged, searchData]);

  useEffect(() => {
    getAllSearchableFormFieldsByFormId();
  }, [filterFormType, getAllSearchableFormFieldsByFormId]);

  useEffect(() => {
    if (returnPropsByPermissions(LeadsSalesPermissions.ViewAndSearchInSaleLeads.permissionsId)) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={detailsLeadsList.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  useEffect(() => {
    const data = localStorage.getItem('LeadsSalesFilter');

    if (data) {
      setSearchData(JSON.parse(data));
      searchchachedClickedWithoutFilter(JSON.parse(data));
    } else
      getLeadsData();
  }, []);

  useEffect(() => {
    if (isFirst1) {
      if (searchData && searchData.length === 0) {
        localStorage.removeItem('LeadsSalesFilter');
        setSearchedItem('');
      } else
        localStorage.setItem('LeadsSalesFilter', JSON.stringify(searchData));
    } else
      setFirst1(true);
  }, [searchData]);

  useEffect(() => {
    if (!isFirst) setFirst(true);
    else {
      const data = localStorage.getItem('LeadsSalesFilter');
      if (data)
        searchchachedClickedWithoutFilter(JSON.parse(data));
      else if (searchData && searchData.length === 0)
        getLeadsData();
    }
  }, [activeFormType, filter, filterSearchDto, leadType, status, leadsTableFilter, orderBy, referred, searchData]);

  useEffect(() => {
    if (isSearchAvite)
      getContactLeadsData();
  }, [contactsFilterSearchDto]);

  useEffect(() => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        LeadTypeSaleFilterSalesDate: dateFilter || dateRangeDefault
      })
    );
  }, [dateFilter]);

  useEffect(() => {
    GetSideMenuStatus();
  }, [GetSideMenuStatus]);

  useEffect(() => {
    setDisplyCloseLead(returnPropsByPermissions(LeadsSalesPermissions.CloseLead.permissionsId));
    setDisplyOpenFileButton(havePermissionToViewDetails(LeadsVerticalTabsData.sale));
    setDisplyMatchButton(havePermissionToEditInformation(LeadsSalesPermissions.ViewMatchingUnits.permissionsId));
  }, [isFirst]);

  return (
    <div className='view-wrapper leads leads-wrapper'>
      <Spinner isActive={isLoading.allLeads} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section'>
              <PermissionsComponent
                permissionsList={Object.values(LeadsSalesPermissions)}
                permissionsId={[
                  LeadsSalesPermissions.ImportLead.permissionsId,
                  LeadsSalesPermissions.CloseLead.permissionsId,
                  LeadsSalesPermissions.ReassignLead.permissionsId,
                  LeadsSalesPermissions.CloneLead.permissionsId,
                  LeadsSalesPermissions.AddNewLead.permissionsId,
                  LeadsSalesPermissions.SendToRotation.permissionsId , 
                  LeadsSalesPermissions.ReassignSellerLead.permissionsId , 

                ]}
              >
                <ActionsButtonsComponent
                  permissionsList={Object.values(LeadsSalesPermissions)}
                  addPermissionsId={LeadsSalesPermissions.AddNewLead.permissionsId}
                  selectPermissionsId={[
                    LeadsSalesPermissions.ImportLead.permissionsId,
                    LeadsSalesPermissions.CloseLead.permissionsId,
                    LeadsSalesPermissions.ReassignLead.permissionsId,
                    LeadsSalesPermissions.CloneLead.permissionsId,
                    LeadsSalesPermissions.AddNewLead.permissionsId,
                    LeadsSalesPermissions.SendToRotation.permissionsId , 
                    LeadsSalesPermissions.ReassignSellerLead.permissionsId , 

                  ]}
                  enableImport={getIsAllowedPermission(
                    Object.values(LeadsSalesPermissions),
                    loginResponse,
                    LeadsSalesPermissions.ImportLead.permissionsId
                  )}
                  enableCloseLeads={getIsAllowedPermission(
                    Object.values(LeadsSalesPermissions),
                    loginResponse,
                    LeadsSalesPermissions.CloseLead.permissionsId
                  )}
                  enablereassignSeekerLeads={getIsAllowedPermission(
                    Object.values(LeadsSalesPermissions),
                    loginResponse,
                    LeadsSalesPermissions.ReassignLead.permissionsId
                  )}
                  enableCloneLeads={getIsAllowedPermission(
                    Object.values(LeadsSalesPermissions),
                    loginResponse,
                    LeadsSalesPermissions.CloneLead.permissionsId
                  )}
                  enableToSendToRotation={getIsAllowedPermission(
                    Object.values(LeadsSalesPermissions),
                    loginResponse,
                    LeadsSalesPermissions.SendToRotation.permissionsId
                  )}
                  enablereassignOwnerLeads={returnPropsByPermissions(LeadsSalesPermissions.ReassignSellerLead.permissionsId)}     
                  enableQualifyLead={true}
                  enableUnQualifyLead={true}
                  checkDisable={checkBulkDesabled}
                  isDisabled={activeSelectedAction === 'merge' ? checkedCards.length < 2 : false}
                  withType
                  typeData={[
                    { id: '1', name: 'owner' },
                    { id: '2', name: 'seeker' },
                  ]}
                  onFormTypeSelectChanged={onFormTypeSelectChanged}
                  onActionsButtonClicked={onActionsButtonClicked}
                  onActionButtonChanged={onActionButtonChanged}
                  leadActiveAction={activeSelectedAction}
                  closeAction={isCloseAction}
                />
              </PermissionsComponent>
            </div>
            <QualificationFilter
              defaultValueis={defaultValueisQualification}
              onButtonClicked={onQualificationFilterButtonClicked}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
            <div className='section autocomplete-section'>
              <PermissionsComponent
                permissionsList={Object.values(LeadsSalesPermissions)}
                permissionsId={LeadsSalesPermissions.ViewAndSearchInSaleLeads.permissionsId}
              >
                <div className='d-flex-column px-2 w-100 p-relative'>
                  <div className='w-100 p-relative'>
                    <AutocompleteComponent
                      data={searchableFormFields.map((item) => ({
                        key: item.key,
                        title: item.title,
                      }))}
                      wrapperClasses='autocomplete-with-btn'
                      selectedValues={searchData}
                      parentTranslationPath='ContactsView'
                      displayLabel={displayedLabel}
                      disabledOptions={disabledOptions}
                      onChange={filterOnChange}
                      searchClicked={searchClicked}
                      chipsLabel={chipsLabel}
                      getOptionSelected={(option) =>
                        searchData.findIndex(
                          (item) => item.key === option.key && item.value === searchInputValue
                        ) !== -1}
                      tagValues={searchData}
                      inputValue={searchInputValue}
                      onInputChange={inputValueChanged}
                      inputLabel='filter'
                      inputPlaceholder='search-contacts'
                    />
                  </div>
                  <div className='d-flex-v-center-h-between pl-5-reversed '>
                    <div className='mr-1-reversed'>
                      <SelectComponet
                        data={[
                          { id: 'Buyer', name: 'buyer' },
                          { id: 'Seller', name: 'seller' }]}
                        emptyItem={{ value: 0, text: 'select-lead-type', isDisabled: false }}
                        value={leadType}
                        valueInput='id'
                        textInput='name'
                        id="Lead_Type"
                        onSelectChanged={(value) => {
                          changeActiveLeadType(value)
                          if (!(activeSelectedAction === ActionsButtonsEnum[5].id) || value === 0)
                            onActionButtonChanged(ActionsButtonsEnum[1].id)

                        }}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='Lead_Type'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>

                    <div className='mr-1-reversed'>
                      <SelectComponet
                        data={[
                          { id: 'Open', name: 'open' },
                          { id: 'Closed', name: 'closed' },
                        ]}
                        emptyItem={{ value: 0, text: 'select-status', isDisabled: false }}
                        value={status}
                        valueInput='id'
                        textInput='name'
                        onSelectChanged={(value) => {
                          changeStatusType(value);
                          onActionButtonChanged(ActionsButtonsEnum[1].id)
                        }}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='Lead_Status'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>

                    <div className='w-100 mt-2'>
                      <Inputs
                        value={searchedItem}
                        onKeyUp={(e) => searchHandler(e.target.value)}
                        idRef='activitiesSearchRef'
                        labelClasses='mt-4'
                        onInputChanged={(e) => setSearchedItem(e.target.value)}
                        inputPlaceholder={t(`${translationPath}search-Mobile-Email-ID`)}
                      />
                    </div>
                    <div className='w-100 customDatePicker'>
                      <div className='date-type-select'>
                        <SelectComponet
                          data={[
                            { key: 1, value: `${translationPath}created-date` },
                            { key: 2, value: `${translationPath}update-on-date` },
                            { key: 3, value: `${translationPath}last-assign-date` }
                          ]}
                          defaultValue={{ key: 1, value: `${translationPath}created-date` }}
                          value={dateFilter.selectedDateType || 1}
                          valueInput='key'
                          textInput='value'
                          isDisabled={isLoading.allLeads}
                          onSelectChanged={(value) => {
                            setIsDatePickerChanged(true)
                            setDateFilter((f) => ({ ...f, selectedDateType: value }));
                          }}
                          wrapperClasses='w-auto'
                          themeClass='theme-transparent'
                          idRef='Date_Select'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <DateRangePickerComponent
                        onClearClicked={() => {
                          setDateFilter(dateRangeDefault)
                          setIsDatePickerChanged(true)
                        }}
                        onDialogClose={() => {
                          setIsDatePickerChanged(true)
                        }}
                        ranges={[dateFilter]}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(selectedDate) => {
                          setDateFilter((item) => ({
                            ...item,
                            startDate: selectedDate.selection && selectedDate.selection.startDate,
                            endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                            key: 'selection',
                          }))
                        }}
                      />
                    </div>
                  </div>
                  <div className='agentSection pl-5-reversed '>
                    <div className='mr-1-reversed w-100'>
                      <AutocompleteComponent
                        idRef='ReferredByRef'
                        isLoading={isLoading.referred}
                        inputPlaceholder={t(`${translationPath}ReferredBy`)}
                        selectedValues={referred.by}
                        getOptionSelected={(option) => option.id === (orderFilter.ReferredByLeadTypeSaleFilter && orderFilter.ReferredByLeadTypeSaleFilter).id || ''}
                        data={(allReferred && allReferred.result) || []}
                        onInputChange={(e) => {
                          if (e && e.target && e.target.value)
                            getAllReferred(e.target.value || '');
                        }}
                        multiple={false}
                        displayLabel={(option) =>
                          (option && option.fullName) || ''}
                        chipsLabel={(option) => (option && option.fullName) || ''}
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          changeReferredBy(newValue)
                        }}
                      />

                    </div>
                    <div className='mr-1-reversed w-100'>
                      <AutocompleteComponent
                        idRef='ReferredToRef'
                        // isLoading={isLoading.referred}
                        inputPlaceholder={t(`${translationPath}ReferredTo`)}
                        data={(allReferred && allReferred.result) || []}
                        selectedValues={referred.to}
                        getOptionSelected={(option) => option.id === (orderFilter.ReferredToLeadTypeSaleFilter && orderFilter.ReferredToLeadTypeSaleFilter).id || ''}
                        onInputChange={(e) => {
                          if (e && e.target && e.target.value)
                            getAllReferred(e.target.value || '');
                        }}
                        multiple={false}
                        displayLabel={(option) =>
                          (option && option.fullName) || ''}
                        chipsLabel={(option) => (option && option.fullName) || ''}
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          changeReferredTo(newValue)
                        }}
                      />
                    </div>
                    <div className='w-100'>
                      <AutocompleteComponent
                        idRef='MediaDetailsRef'
                        isLoading={isLoading.mediaDetails}
                        inputPlaceholder={t(`${translationPath}MediaDetails`)}
                        selectedValues={referred.mediaDetails}
                        getOptionSelected={(option) => option.lookupItemId === (orderFilter.MediaDetailsLeadTypeSaleFilter && orderFilter.MediaDetailsLeadTypeSaleFilter).lookupItemId || ''}
                        data={(allMediaName) || []}
                        displayLabel={(option) =>
                          (option && option.lookupItemName) || ''}
                        chipsLabel={(option) => (option && option.lookupItemName) || ''}
                        multiple={false}
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          changeMediaDetails(newValue)
                        }}
                      />
                    </div>
                  </div>

                </div>

                <div className='view-search-wrapper'>
                  <ViewTypes onTypeChanged={onTypeChanged} initialActiveType={activeActionType} className='mb-3' />
                  <span className='advance-search'>
                    <ButtonBase
                      onClick={onAdvanceSearchClick} id='AdvanceSearch_bbt'
                      disabled={isAllFormFieldsLoading}
                      className={`btns theme-solid ${isAdvanceSearchActive && !isAllFormFieldsLoading && 'is-active-filter'}`}
                    >
                      <span className={`${advanceSearchBtn ? 'mdi mdi-arrow-expand-left' : 'mdi mdi-arrow-expand-right'} mr-1`} />
                      {t(`${translationPath}advance-search`)}
                    </ButtonBase>
                  </span>
                  <ButtonBase
                    onClick={onClearedAllFiltersClick} id='onClearedAllFiltersref'
                    disabled={isAllFormFieldsLoading}
                    className='btns theme-solid bg-danger clear-all-btn'
                  >
                    <span className='mdi mdi-filter-remove m-1' />
                    {t(`${translationPath}clear-filters`)}

                  </ButtonBase>
                </div>
              </PermissionsComponent>
            </div>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(LeadsSalesPermissions)}
            permissionsId={LeadsSalesPermissions.ViewAndSearchInSaleLeads.permissionsId}
          >
            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}leads`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}
                  :
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='filterByRef'
                    data={[
                      { id: 'createdOn', filterBy: 'created-on' },
                      { id: 'updateOn', filterBy: 'last-updated' },
                    ]}
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={filterByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='filterBy'
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    emptyItem={{ value: null, text: 'select-sort-by', isDisabled: false }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    id='action_apply'
                    onClick={orderBySubmitted}
                    disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>
        { }
        {/* loginResponse &&
          loginResponse.permissions
            .map((item) => item.permissionsId === LeadsPermissions.ReadLeads.permissionsId)
            .includes(true) && */}
        {activeActionType !== ViewTypesEnum.tableView.key && (
          <PermissionsComponent
            permissionsList={Object.values(LeadsSalesPermissions)}
            permissionsId={LeadsSalesPermissions.ViewAndSearchInSaleLeads.permissionsId}
          >
            <div className='body-section'>
              {/* {loginResponse &&
                loginResponse.permissions
                  .map((item) => item.permissionsId === LeadsPermissions.ReadLeads.permissionsId)
                  .includes(true) && ( */}
              <LeadsCardsComponent
                data={detailsLeadsList}
                isExpanded={isExpanded}
                onCardClicked={onCardClick}
                onFooterActionsClicked={detailedCardSideActionClicked}
                onActionClicked={detailedCardActionClicked}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                withCheckbox={activeSelectedAction === 'merge' ||
                  activeSelectedAction === ActionsButtonsEnum[5].id ||
                  activeSelectedAction === ActionsButtonsEnum[6].id ||
                  activeSelectedAction === ActionsButtonsEnum[7].id ||
                  activeSelectedAction === ActionsButtonsEnum[8].id ||
                  activeSelectedAction === ActionsButtonsEnum[12].id ||
                  activeSelectedAction === ActionsButtonsEnum[13].id ||
                  activeSelectedAction === ActionsButtonsEnum[14].id
                }
                onCardCheckboxClick={cardCheckboxClicked}
                activeCard={activeCard}
                notExpandedMax={3}
                relodedata={reloadData}
                selectedCards={checkedCards}
                activeSelectedAction={activeSelectedAction}
                displyOpenFileButton={displyOpenFileButton}
                displyMatchButton={displyMatchButton}
                checkIfSensitiveField={checkIfSensitiveField}
              />
              {/* )} */}
            </div>
          </PermissionsComponent>
        )}
        {/* loginResponse &&
          loginResponse.permissions
            .map((item) => item.permissionsId === LeadsPermissions.ReadLeads.permissionsId)
            .includes(true) && */}
        {activeActionType === ViewTypesEnum.tableView.key && (
          <PermissionsComponent
            permissionsList={Object.values(LeadsSalesPermissions)}
            permissionsId={LeadsSalesPermissions.ViewAndSearchInSaleLeads.permissionsId}
          >
            <LeadsSalesTableComponent
              detailsLeadsList={detailsLeadsList}
              tableActionClicked={tableActionClicked}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              filter={filter}
              parentTranslationPath={parentTranslationPath}
              focusedRowChanged={focusedRowChanged}
              leadsTableFilter={leadsTableFilter}
              leadsFiltersCriteria={leadsFiltersCriteria}
              onFilterValuesChanged={onFilterValuesChanged}
              checkedCardsIds={checkedCardsIds}
              getIsSelected={getIsSelected}
              getIsDisabled={getIsDisabled}
              onSelectClicked={onSelectClicked}
              activeSelectedAction={activeSelectedAction}
              defaultActions={list}
              setCheckedCards={setCheckedCards}
              setOrderBy={setOrderBy}
              isClearFiltersClicked={isClearFiltersClicked}
              setIsClearFiltersClicked={setIsClearFiltersClicked}
              displyOpenFileButton={displyOpenFileButton}
              displyMatchButton={displyMatchButton}
              localStorageKey='LeadsSaleFilter'
            />
          </PermissionsComponent>
        )}
        <LeadsActionDialogsComponent
          isOpen={isOpenContactsActionDialog}
          isOpenChanged={() => setisOpenContactsActionDialog(false)}
          actionEnum={detailedCardAction.actionEnum}
          item={detailedCardAction.item}
          translationPath={translationPath}
          parentTranslationPath='ContactsView'
        />
      </div>
      <LeadsImportDialog
        isOpen={isOpenImportDialog}
        isOpenChanged={() => setIsOpenImportDialog(false)}
      />
      {
        isOpenCloseLeadsDialog && (
          <CloseLeadsDialog
            isOpen={isOpenCloseLeadsDialog}
            setIsLoading={setIsLoading}
            onSave={(item) => {
              const closeLeadsBody = { leadsIds: checkedCardsIds, closeReasonId: item.closeReasonId, remarks: item.remarks };
              closeLeads(closeLeadsBody);
              setIsOpenCloseLeadsDialog(false);
            }}
            onClose={() => {
              setIsOpenCloseLeadsDialog(false);
              resetAllValues();
            }}
          />
        )
      }
      {isOpenleadsReassignDialog && (
        <LeadsReassignDialog
          isOpen={isOpenleadsReassignDialog}
          leadType={checkedCards && checkedCards[0] && checkedCards[0].leadClass}
          isLoadingReassign={isLoadingReassign}
          setIsLoadingReassign={setIsLoadingReassign}
          onSave={(reassignItem) => {
            reassignHandler(reassignItem);
            setCheckedCards([]);
            setCheckedCardsIds([]);
          }}
          parentTranslationPath='ContactProfileManagementView'
          translationPath=''
          onClose={() => {
            setIsOpenleadsReassignDialog(false);
          }}
        />
      )}

      {
        isOpenCloneLeadsDialog && (
          <CloneLeadsDialog
            isOpen={isOpenCloneLeadsDialog}
            checkedCardsIds={checkedCardsIds}
            checkedCards={checkedCards}
            totalCloneLeads={checkedCardsIds && checkedCardsIds.length}
            setIsLoading={setIsLoading}
            onSave={(item) => {
              cloneLeads(item);
              setIsOpenCloneLeadsDialog(false);
            }}
            onClose={() => {
              setIsOpenCloneLeadsDialog(false);
              resetAllValues();
            }}
          />
        )
      }
      {
        UnqualifiedLeadsDialogDialog && (
          <UnqualifiedLeadsDialog
            isOpen={UnqualifiedLeadsDialogDialog}
            checkedCardsIds={checkedCardsIds}
            checkedCards={checkedCards}
            totalCloneLeads={checkedCardsIds && checkedCardsIds.length}
            // setIsLoading={setIsLoading}
            onSave={(item) => {
              setUnqualifiedLeadsDialogDialog(false);
              setCheckedCards([]);
              setCheckedCardsIds([]);
              resetAllValues();
            }}
            onClose={() => {
              setUnqualifiedLeadsDialogDialog(false);
              resetAllValues();
            }}
          />
        )
      }
      {
        isOpenSendToRotationDialog && (
          <SendToRoationDialog
            isOpen={isOpenSendToRotationDialog}
            checkedCardsIds={checkedCardsIds}
            onClose={() => {
              setIsOpenSendToRotationDialog(false);
              resetAllValues();
              resetAllValues();
            }}
          />
        )
      }

      {
        addActivity && (
          <ActivitiesManagementDialog
            open={addActivity}
            onSave={() => {
              setAddActivity(false);
            }}
            close={() => {
              setAddActivity(false);

            }}
            actionItemId={activeItem?.id}
            translationPath={''}
            parentTranslationPath={'LeadsProfileManagementView'}

          />
        )
      }
      {
        isOpenQualifyLeadDialog && (
          <QualifyLeadDialog
            isOpen={isOpenQualifyLeadDialog}
            checkedLeadsIds={checkedCardsIds}
            onClose={() => {
              setIsOpenQualifyLeadDialog(false);
              resetAllValues();
            }}
          />
        )
      }
    </div>
  );
};
