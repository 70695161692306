import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  TabsComponent,
  RadiosGroupComponent,
  CompletedDataComponent,
  Spinner
} from '../../../../Components';
import { PermissionDeniedLayout } from '../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout';
import { GetParams, returnPropsByPermissions } from '../../../../Helper';
import { PropertyManagementListPermissions, PropertiesPermissionsCRM } from '../../../../Permissions';
import { PropertiesMapper } from '../PropertiesUtilities/PropertiesMapper';
import { PropertiesVerticalTabsData, PropertiesVerticalTabsData2 } from '../../Common/OpenFileView/OpenFileUtilities/OpenFileData';
import { PropertiesAdvanceSearchTest, GetSensitiveFieldsForUser } from '../../../../Services';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { FormsIdsEnum } from '../../../../Enums';

const parentTranslationPath = 'PropertiesProfileManagementView';
const translationPath = '';
export const PropertiesProfileManagementView = () => {
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const localActiveItem = localStorage.getItem('activeItem');
  const location = useLocation();
  const dispatch = useDispatch();
  const [displayDetailsToUserDependsOnPolicy, setDisplayDetailsToUserDependsOnPolicy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [SensitiveLoading , setSensitiveLoading ] = useState(false)  ; 

  const [isSensitiveLoading, setIsSensitiveLoading] = useState(0);

  const [activePropertyData, setActivePropertyData] = useState();
  const [propertyId, setPropertyId] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [viewType, setViewType] = useState(1);
  const [pathName, setPathName] = useState('');
  const [propertyWithPolicy, setPropertyWithPolicy] = useState(null);
  const [propertyEmptyFields , setPropertyEmptyFields] = useState(null) ; 
  const [sensitiveFieldsForUser, setSensitiveFieldsForUser] = useState([]);
  const [list, setList] = useState([]);
  const [isPropertyManagementView, setIsPropertyManagementView] = useState(false);
  const [filterBy, setFilterBy] = useState({
    id: +GetParams('id'),
    formType: GetParams('formType'),
  });

  const userHasPolicyToDisplayData = useCallback(async (propertyId) => {
    setIsLoading(true);
    const isForLog = true;

    const body = {
      criteria: {
        Ids:
          [{ searchType: 1, value: propertyId }]
      },
      filterBy: 'createdOn',
      orderBy: 2,
    };

    const res = await PropertiesAdvanceSearchTest({ pageIndex: 0, pageSize: 2, isForLog }, body);
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.totalCount > 0) {
        if (res && res.result && res.result.length && res.result[0].propertyJson) {
          const propertyJson = JSON.parse(res && res.result && res.result.length && res.result[0].propertyJson);
          setPropertyWithPolicy(propertyJson);      
          setPropertyEmptyFields(JSON.parse(res && res.result &&  res.result[0] && res.result[0].propertyJson)) ; 
          setActivePropertyData(propertyJson);
          setPropertyId(res.result[0].propertyId);
          setDisplayDetailsToUserDependsOnPolicy('allowed');
        }
      } else {
        setPropertyWithPolicy(null);
        setActivePropertyData(null);
        setPropertyId();
        setPropertyEmptyFields(null) ; 
        setDisplayDetailsToUserDependsOnPolicy('notAllowed');
      }
    } else {
      setActivePropertyData(null);
      setPropertyEmptyFields(null) ;
      setPropertyId() ; 
      setDisplayDetailsToUserDependsOnPolicy('notAllowed');
      setPropertyWithPolicy(null);
    }

    setIsLoading(false);
  });


  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };

  const reloadData = () => 
  {
    userHasPolicyToDisplayData(+GetParams('id')) ; 
    GetSensitiveFieldsForUserAPI(+GetParams('id')) ; 

  };

  useEffect(() => {
    setIsPropertyManagementView(pathName === 'properties' || pathName === 'properties/property-profile-edit');
  }, [pathName]);

  useEffect(() => {
    if (isPropertyManagementView)
      setList([...PropertiesVerticalTabsData2]);
    else
      setList([...PropertiesVerticalTabsData]);
  }, [isPropertyManagementView]);

  useEffect(() => {
    setFilterBy({
      formType: GetParams('formType'),
      id: (GetParams('id') && +GetParams('id')) || null,
    });
    setPathName(window.location.pathname.split('/home/')[1].split('/view')[0]);
  }, [location]);

  const GetSensitiveFieldsForUserAPI = useCallback(async (propertyId) => {
    setSensitiveLoading(true);
    const allSensitiveFields = await GetSensitiveFieldsForUser(FormsIdsEnum.properties.id , {key : 'property' , id : propertyId } );

    if (!(allSensitiveFields && allSensitiveFields.status && allSensitiveFields.status !== 200)) {
      setSensitiveFieldsForUser(allSensitiveFields);
      setIsSensitiveLoading(1);
    } else {
      setSensitiveFieldsForUser([]);
      setIsSensitiveLoading(2);
    }

    setSensitiveLoading(false);
  }, []);


  useEffect(() => {
    if (activePropertyData) {
      dispatch(ActiveItemActions.activeItemRequest(PropertiesMapper(activePropertyData, activePropertyData.property)));
      if (localStorage.getItem('localActiveItem')) localStorage.removeItem('localActiveItem');
      localStorage.setItem('localActiveItem', JSON.stringify(PropertiesMapper(activePropertyData, activePropertyData.property)));
    }
  }, [activePropertyData]);

  useEffect(() => {

    const propertyId = +GetParams('id');
    if (propertyId && displayDetailsToUserDependsOnPolicy === 'allowed'   && activeTab === 0 )
         GetSensitiveFieldsForUserAPI(propertyId);
  }, [GetSensitiveFieldsForUserAPI ,displayDetailsToUserDependsOnPolicy , activeTab ]);

  useEffect(() => {
    const propertyId = +GetParams('id');
    const propertyInfoTab = Object.values(list) ; 

    if (propertyId && activeTab === 0) 
      userHasPolicyToDisplayData(propertyId);
  }, [activeTab]);

  return (
    <div className='properties-profile-wrapper view-wrapper'>
      {
        displayDetailsToUserDependsOnPolicy === 'allowed' && activeTab === 0 && list && list.length > 0 && list[0].label === 'Property-info'
        && (isPropertyManagementView ?
          returnPropsByPermissions(PropertyManagementListPermissions.ViewPropertyDetails.permissionsId) :
          returnPropsByPermissions(PropertiesPermissionsCRM.ViewPropertyDetails.permissionsId)) && (
          <div className='d-flex'>
            <RadiosGroupComponent
              idRef='viewDataRef'
              data={[
                {
                  key: 1,
                  value: 'all-data',
                },
                {
                  key: 2,
                  value: 'missing-data',
                },
              ]}
              value={viewType}
              labelValue='view'
              labelInput='value'
              valueInput='key'
              themeClass='theme-line'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
              onSelectedRadioChanged={onViewTypeChangedHandler}
            />
            <CompletedDataComponent
              completedData={
                activeItem && activeItem.progress ?
                  activeItem.progress :
                  JSON.parse(localActiveItem) && JSON.parse(localActiveItem).progress
              }
              typeId='property'
              isPropertyManagementView={isPropertyManagementView}
              activeItem={activeItem}
            />
          </div>
        )
      }
      {
        displayDetailsToUserDependsOnPolicy === 'allowed' && (
          <TabsComponent
            data={list}
            labelInput='label'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            themeClasses='theme-solid'
            currentTab={activeTab}
            onTabChanged={onTabChanged}
            dynamicComponentProps={{
              propertyId: filterBy.id,
              viewType,
              parentTranslationPath,
              translationPath,
              setActiveTab,
              propertyWithPolicy,
              sensitiveFieldsForUser,
              isSensitiveLoading , 
              isLoading ,
              propertyEmptyFields , 
              reloadData , 
            }}
          />
        )
      }
      {
        displayDetailsToUserDependsOnPolicy === 'notAllowed' && (<PermissionDeniedLayout />)
      }
    </div>
  );
};
