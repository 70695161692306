import React, { useEffect, useCallback, useState, useReducer, useRef } from 'react';
import { Tables, Spinner, DatePickerComponent, AutocompleteComponent } from '../../../Components';
import { Inputs, SelectComponet } from '../../../Components/Controls';
import { GetAllUsersLoginHistory, GetAllBranches } from '../../../Services';
import { bottomBoxComponentUpdate } from '../../../Helper';
import { useTitle } from '../../../Hooks';
import { PaginationComponent } from '../../../Components/PaginationComponent/PaginationComponent';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const UsersLoginHistoryView = ({ }) => {
  const translationPath = '';
  const parentTranslationPath = 'UsersLoginHistoryView';
  const { t } = useTranslation(parentTranslationPath);

  useTitle(t(`${translationPath}users-login-history`));

  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState({
    allUserLogin: false,
    allBranches: false,
  });
  const [loginData, setLoginData] = useState({});
  const [branchList, setBranchList] = useState({
    result: [],
    totalCount: 0
  });
  const [sortBy, setSortBy] = useState({});

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [filter, setFilter] = useReducer(reducer, {
    pageIndex: 0,
    pageSize: 25,
    fromDate: null,
    toDate: null,
    userName: null,
    branchName: null,
    fullName: null,
    statusId: null,
    staffCode: null,
    source: null,
  });
  const [selected, setSelected] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
    userName: null,
    branchName: null,
    fullName: null,
    statusId: null,
    staffCode: null,
    source: null,
  });

  const onPageIndexChanged = (newValue) => {
    setFilter({
      id: 'pageIndex',
      value: newValue,
    });
  };
  const onPageSizeChanged = (newValue) => {
    setFilter({
      id: 'pageSize',
      value: newValue,
    });
  };

  const getAllUsersLogin = useCallback(async () => {
    setIsLoading((loading) => ({ ...loading, allUserLogin: true }));

    const body = {
      ...filter,
      filterBy: sortBy?.filterBy,
      orderBy: sortBy?.orderBy,
      pageIndex: filter.pageIndex + 1
    };
    const res = await GetAllUsersLoginHistory(body);
    if (!(res && res.status && res.status !== 200)) {
      setLoginData(res || {});
    } else setLoginData({});
    setIsLoading((loading) => ({ ...loading, allUserLogin: false }));
  }, [filter, sortBy]);

  const getBranch = async (branchName) => {
    setIsLoading((loading) => ({ ...loading, allBranches: true }));
    const res = await GetAllBranches({ pageSize: 25, pageIndex: 1, branchName: branchName });
    if (!(res && res.status && res.status !== 200)) setBranchList({
      result: res.result,
      totalCount: res.totalCount
    });
    else setBranchList({
      result: [],
      totalCount: 0
    });
    setIsLoading((loading) => ({ ...loading, allBranches: false }));
  };

  useEffect(() => {
    getAllUsersLogin();
  }, [getAllUsersLogin]);

  useEffect(() => {
    getBranch();
  }, []);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={loginData.totalCount || 0}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    }, []
  );

  return (
    <div>
      <Spinner isActive={isLoading.allUserLogin} />
      <div className='flex-v-end d-flex-column mx-5'>
        <div className='d-flex w-50'>
          <div className='w-50 mr-1-reversed'>
            <Inputs
              idRef='fullNameFilterRef'
              fieldClasses='inputs theme-solid'
              inputPlaceholder={'Name'}
              parentTranslationPath={parentTranslationPath}
              value={selected.fullName}
              onInputChanged={(event) => {
                const newValue = event.target.value;
                setSelected({
                  id: 'fullName',
                  value: newValue,
                });
              }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter({
                    id: 'fullName',
                    value: newValue,
                  });
                }, 700);
              }}
            />
          </div>

          <div className='w-50 mr-1-reversed'>
            <Inputs
              idRef='userNameFilterRef'
              fieldClasses='inputs theme-solid'
              parentTranslationPath={parentTranslationPath}
              value={selected.userName}
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: 'userName',
                  value: value,
                });
              }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter({
                    id: 'userName',
                    value: newValue,
                  });
                }, 700);
              }}
              inputPlaceholder={'Username'}
            />
          </div>
          <div className='w-50 mr-1'>
            <SelectComponet
              data={[
                { id: 1, statusId: t(`${translationPath}pending`) },
                { id: 2, statusId: t(`${translationPath}active`) },
                { id: 3, statusId: t(`${translationPath}deactive`) },
              ]}
              value={filter.statusId || 0}
              emptyItem={{
                value: 0,
                text: 'User Status',
                isDisabled: false,
              }}
              valueInput='id'
              textInput='statusId'
              onSelectChanged={(newValue) => {
                setFilter({
                  id: 'statusId',
                  value: newValue,
                });
                setSelected({
                  id: 'statusId',
                  value: newValue,
                });
              }}
            />
          </div>
        </div>
        <div className='d-flex w-50'>
          <div className='w-50 mr-1-reversed'>
            <AutocompleteComponent
              idRef='branchNameRef'
              multiple={false}
              isLoading={isLoading.allBranches}
              inputPlaceholder={`Branch Name`}
              data={branchList.result || []}
              chipsLabel={(option) => (option && option.branchName) || ''}
              displayLabel={(option) => (option && option.branchName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(event) => {
                const { value } = event.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getBranch(value)
                }, 700);
              }}
              onChange={(event, newValue) => {
                const branchName = newValue?.branchName;
                setSelected({
                  id: 'branchName',
                  value: newValue,
                });
                setFilter({
                  id: 'branchName',
                  value: branchName,
                });
              }}
            />
          </div>
          <div className='w-50 mr-1-reversed'>
            <SelectComponet
              idRef='sourceIdRef'
              data={[
                { id: 1, text: t(`${translationPath}CRM-Admin`) },
                { id: 2, text: t(`${translationPath}MyLead-Mobile`) },
              ]}
              value={filter.source || null}
              emptyItem={{
                value: null,
                text: 'Login Platform',
                isDisabled: false,
              }}
              valueInput='text'
              textInput='text'
              onSelectChanged={(newValue) => {
                setFilter({
                  id: 'source',
                  value: newValue,
                });
                setSelected({
                  id: 'source',
                  value: newValue,
                });
              }}
            />
          </div>
        </div>
        <div className='d-flex w-50 mt-2'>
          <div className='mr-1'>
            <DatePickerComponent
              idRef='historyFromDateRef'
              labelValue={`from-date`}
              placeholder='DD/MM/YYYY'
              format='YYYY/MM/DD'
              parentTranslationPath={parentTranslationPath}
              value={selected.fromDate}
              maxDate={moment().format('YYYY-MM-DDTHH:mm:ss')}
              onDateChanged={(newValue) => {
                setFilter({
                  id: 'fromDate',
                  value: newValue,
                });
                setSelected({
                  id: 'fromDate',
                  value: newValue,
                });
              }}
            />
          </div>
          <div className='mr-1'>
            <DatePickerComponent
              idRef='historyToDateRef'
              labelValue={`to-date`}
              placeholder='DD/MM/YYYY'
              format='YYYY/MM/DD'
              parentTranslationPath={parentTranslationPath}
              value={selected.toDate}
              maxDate={moment().format('YYYY-MM-DDTHH:mm:ss')}
              onDateChanged={(newValue) => {
                setFilter({
                  id: 'toDate',
                  value: newValue,
                });
                setSelected({
                  id: 'toDate',
                  value: newValue,
                });
              }}
            />
          </div>
          <div className='w-50 mr-1-reversed input-m-class'>
            <Inputs
              idRef='staffCodeRef'
              fieldClasses='inputs theme-solid'
              label={'staff-code'}
              parentTranslationPath={parentTranslationPath}
              value={selected.staffCode}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const newValue = event.target.value;
                setSelected({
                  id: 'staffCode',
                  value: newValue,
                });
              }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter({
                    id: 'staffCode',
                    value: newValue,
                  });
                }, 700);
              }}
              inputPlaceholder={'filter-by-staffCode'}
            />
          </div>
        </div>
      </div>
      <div className='m-4'>
        <Tables
          data={loginData?.result || []}
          headerData={[
            {
              id: 1,
              label: 'name',
              input: 'fullName',
              component: (item) => <span>{item.firstName && `${item.firstName} ${item.lastName} ` || ''}</span>,
              isSortable: true,
            },
            {
              id: 2,
              label: 'username',
              input: 'userName',
              isSortable: true,
            },
            {
              id: 3,
              label: 'login-Date',
              input: 'loginDate',
              isDate: true,
              dateFormat: 'DD/MM/YYYY',
              isSortable: true,
            },
            {
              id: 4,
              label: 'login-time',
              input: 'loginDate',
              isDate: true,
              dateFormat: 'LT',
              isSortable: false,
            },
            {
              id: 5,
              label: 'branch-Name',
              input: 'branchName',
              isSortable: true,
            },
            {
              id: 6,
              label: 'staffId',
              input: 'staffCode',
              isSortable: true,
            },
            {
              id: 7,
              label: 'source',
              input: 'loginPlatform',
              isSortable: true,
            },
            {
              id: 8,
              searchableKey: 'status',
              label: 'status',
              component: (item, index) => (
                (item && item.status === "Active" && <div className='globel-open-style' index={index}>{item.status}</div>) || item && item.status === "Pending" && <div className='globel-Yellow-style' index={index}>{item.status}</div> || <div className='globel-closed-style' index={index}>{item.status}</div>
              ),
              isSortable: true,
            },
          ]}
          setSortBy={setSortBy}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          defaultActions={[]}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          totalItems={loginData.totalCount || 0}
        />
      </div>
    </div>
  );
};
