export const ZeroMatchingSalePermissions =
{

  AddNewSaleInquiry: {
    permissionsId: '371baa02-f2c4-4124-a5d2-08db04f6cbfc',
    permissionsName: 'Add New Sale Inquiry',
    componentsId: null,
    components: null
  },
  EditInquiry: {
    permissionsId: '79ccb7ba-0330-4683-a5d3-08db04f6cbfc',
    permissionsName: 'Edit Inquiry',
    componentsId: null,
    components: null
  },
  DeleteInquiry: {
    permissionsId: '72cf9af3-bfd9-483a-a5d4-08db04f6cbfc',
    permissionsName: 'Delete Inquiry',
    componentsId: null,
    components: null
  },
  ViewInquiry: {
    permissionsId: 'db439b23-5aa8-44cd-a5d5-08db04f6cbfc',
    permissionsName: 'View Inquiry',
    componentsId: null,
    components: null
  },
  CancelInquiry: {
    permissionsId: '192e26fd-ba08-4a4a-a5df-08db04f6cbfc',
    permissionsName: 'Cancel Inquiry',
    componentsId: null,
    components: null
  },

  AssignInquiry: {
    permissionsId: '4739d477-883c-45b7-cb6e-08db7d42a751',
    permissionsName: 'Assign Inquiry',
    componentsId: null,
    components: null
  }

};
