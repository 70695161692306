
import { HttpServices } from '../../Helper';
import { config } from '../../config';



export const InquiryPost = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/Inquiry/CreateInquiry`, body
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const GetAllInquiriesServices = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/Inquiry/GetAllInquiries`, body
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};



export const AssignInquiryToAgentServices = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/Inquiry/AssignInquiryToAgent`, body
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const UpdateInquiryServices = async ( inquiryId , body) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/Inquiry/UpdateInquiry/${inquiryId}`, body
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};



export  const  GetInquiryDetailsByIdService = async (inquiryId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/Inquiry/GetInquiryDetailsById/${inquiryId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};



export const DeleteInquiryService = async (inquiryId) => {
    const result = await HttpServices.delete(
        `${config.server_address}/CrmDfm/Inquiry/DeleteInquiry/${inquiryId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const UnAssignInquiryFromAgentServices = async (body) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/Inquiry/UnAssignInquiryFromAgent`, body
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};



export const AssignInquiryToUnitServices = async (unitId  ,inquiryId   ) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/Inquiry/AssignInquiryToUnit/${unitId}/${inquiryId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};



export const SetInquiryStatusAsCompleteServices = async (inquiryId)  => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/Inquiry/SetInquiryStatusAsComplete/${inquiryId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const UnAssignInquiryFromUnitServices = async (unitId  ,inquiryId) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/Inquiry/UnAssignInquiryFromUnit/${unitId}/${inquiryId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export  const  GetAllUnitsByInquiryIdService = async (inquiryId ,pageIndex ,pageSize) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/Inquiry/GetAllUnitsByInquiryId/${inquiryId}/${pageIndex}/${pageSize}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const CancelInquiryServices = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/Inquiry/CancelInquiry` , body
    )
  .then((data) => data)
        .catch((error) => error.response);
    return result;
};



