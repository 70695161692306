import React, { useRef, useCallback } from 'react';
import {
  FormControl
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SelectComponet } from '../../../../Controls';

export const TableFilterSelectOptionsComponent = ({
  idRef,
  filterKey,
  displayPath,
  searchableKey,
  filterValues,
  onFilterValuesChanged,
  translationPath,
  parentTranslationPath,
  optionFilterName
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const handleChange = (value) => {
    if (onFilterValuesChanged) {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      searchTimer.current = setTimeout(() => {
        const localFilterValues = (filterValues && { ...filterValues }) || {};
        localFilterValues[filterKey] = {
          value,
          displayPath,
          searchableKey
        };
        onFilterValuesChanged(localFilterValues);
      }, 700);
    }
  };

  const onSelectChanged = useCallback(
    (selectedValue) => {
      handleChange(selectedValue);
    },
  );

  return (
    <div>
      <FormControl variant='outlined' className='reminder-select makeStyles-formControl-5'>
        <SelectComponet
          data={optionFilterName}
          emptyItem={{
            key: null,
            text: (`${t('Shared:select-filter-by')}`),
            isDisabled: false,
          }}
          onSelectChanged={onSelectChanged}
          themeClass='theme-action-buttons'
          idRef={idRef}
          valueInput='key'
          keyValue='actionsbuttons'
          translationPathForData={translationPath}
          textInput='value'
        />
      </FormControl>
    </div>
  );
};
