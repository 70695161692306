import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { GlobalTranslate, showError } from '../../../../Helper';
import { MicrosoftToken } from '../../../../Services';
import { useTitle } from '../../../../Hooks';

export const MainDashboard = ({ dynamicComponentProps }) => {
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  useTitle(GlobalTranslate.t('Dashboard:admin-dashboard'));

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport('');
      showError('Contact Your Bi Admin For Help');
    }
  };

  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className='dashboardMain-PowerBIEmbed'>
      {render && (
        <div className='dashboardMain'>
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id:
                dynamicComponentProps &&
                dynamicComponentProps.config &&
                dynamicComponentProps.config.reportId,
              embedUrl:
                dynamicComponentProps &&
                dynamicComponentProps.config &&
                dynamicComponentProps.config.Url,
              accessToken: report,
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  () => {
                    console.log('Report loaded');
                  },
                ],
                [
                  'rendered',
                  () => {
                    console.log('Report rendered');
                  },
                ],
                [
                  'error',
                  (event) => {
                    const error = event.detail;

                    if (
                      error.detailedMessage === 'Get report failed' ||
                      error.detailedMessage ===
                      'Access token has expired, resubmit with a new access token' ||
                      error.detailedMessage ===
                      'Fail to initialize - Could not resolve cluster'
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName='report-style-class'
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      )}
    </div>
  );
};
