import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { GetSystemReportCategoryTypesByCategoryId } from '../../../../Services/ReportsBuilderServices';
import { lookupItemsGetId } from '../../../../Services/LookupsServices';
import { showError , returnPropsByPermissions  } from '../../../../Helper';
import { config } from '../../../../config';
import { ReportBuilderPermissions } from '../../../../Permissions' ;  

export const ReportsType = ({ parentTranslationPath, translationPath, IdSdelected }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [Loading, setLoading] = useState(false);
  const [IsLoadingReportCategory, setIsLoadingReportCategory] = useState('');
  const [formsResponse, setFormsResponse] = React.useState([]);
  const [formsResponseWithPermissions , setFormsResponseWithPermissions] = useState([]) ; 
  const formsResponseLODE = [1, 2, 3, 4];
  const [active, setactive] = useState(null);
  // eslint-disable-next-line max-len
  const [SelectedsystemReportCategoryTypeId, setSelectedsystemReportCategoryTypeId] = useState(null);
  const [StemReportCategoryTypes, setStemReportCategoryTypes] = useState([]);
  const [activeformedById, setactiveformedById] = useState(null);
  const [filter] = useState(
    {
      pageIndex: 0,
      pageSize: 9999,
      searchedItem: ''
    },
  );

  const GetMyForms = useCallback(async () => {
    setLoading(true);
    const res = await lookupItemsGetId({ lookupTypeId: config.GetMyFormsReportsTypeReportsBuilder, });
    // GetForms(filter);
    if (!(res && res.status && res.status !== 200)) {
      setFormsResponse(res);
      setLoading(false);
    } else {
      showError(t`${translationPath}lode-forms-filed-failed`);
      setFormsResponse([]);
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const getAllContactUnitTransactions = useCallback(async (dara) => {
    setIsLoadingReportCategory(true);
    const res = await GetSystemReportCategoryTypesByCategoryId({
      PageIndex: 0,
      PageSize: 10,
      categoryId: dara
    });
    if (!(res && res.status && res.status !== 200)) {
      setStemReportCategoryTypes(res);
      setIsLoadingReportCategory(false);
    } else {
      setStemReportCategoryTypes(
        []
      );
    }
    setIsLoadingReportCategory(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (activeformedById !== null)
      getAllContactUnitTransactions(activeformedById);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeformedById]);

  useEffect(() => {
    GetMyForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  
  const getTableActionsByPermissions = () => {
    let list = [];
    formsResponse.map((item)=> {
      if(returnPropsByPermissions(ReportBuilderPermissions.AddContactsReport.permissionsId) && item.lookupItemName === 'Contacts')
        list.push(item) ; 
      else if(returnPropsByPermissions(ReportBuilderPermissions.AddPropertiesReport.permissionsId) && item.lookupItemName === 'Properties')
        list.push(item) ; 
      else if(returnPropsByPermissions(ReportBuilderPermissions.AddUnitsReport.permissionsId) && item.lookupItemName === 'Units')
        list.push(item) ; 
      else if(returnPropsByPermissions(ReportBuilderPermissions.AddLeadsReport.permissionsId) && item.lookupItemName === 'Leads')
        list.push(item) ; 
      else if(returnPropsByPermissions(ReportBuilderPermissions.AddActivitiesReport.permissionsId) && item.lookupItemName === 'Activities')
        list.push(item) ; 
      else if(returnPropsByPermissions(ReportBuilderPermissions.AddTransactioReport.permissionsId) && item.lookupItemName === 'Transactions')
        list.push(item) ;   
     else if (returnPropsByPermissions(ReportBuilderPermissions.AddUserLoginHistoryReport.permissionsId) && item.lookupItemName === 'Users' )
          list.push(item) ; 

     else if (returnPropsByPermissions(ReportBuilderPermissions.AddRotationSchemesReport.permissionsId) &&  item.lookupItemName === 'RotationScheme' )
          list.push(item) ; 

    
        
    });
    setFormsResponseWithPermissions(list);
  };

  useEffect(() => {
    if(formsResponse.length > 0)
      getTableActionsByPermissions() ; 
    
    
  }, [formsResponse]);

  return (
    <form
      noValidate
      //   onSubmit={saveHandler('form')}
      className='malek roles-management-wrapper view-wrapper'
    >
      <div className='roles-form-content-wrapper'>
        <div className='roles-sections-wrapper'>
          <div className='roles-modules-wrapper childs-wrapper pl-2-reversed pr-4-reversed'>

            <div className='roles-modules-header-wrapper'>
              <div className='roles-modules-title'>
                <span className='title-text'>{t(`${translationPath}select-report-type`)}</span>
              </div>
            </div>
            <div className='roles-module-items-wrapper'>
              {(Loading === false &&
                formsResponseWithPermissions &&
                formsResponseWithPermissions.map((item, index) => (
                  <ButtonBase
                    className={`btns theme-solid roles-module-item${(index + 1 &&
                      index + 1 === active &&
                      ' active-module') ||
                      ''
                      }`}
                    key={`rolesModuleItemKey${index + 1}`}
                    onClick={() => {
                      IdSdelected('');
                      setSelectedsystemReportCategoryTypeId(null);
                      setactive(index + 1);
                      setactiveformedById(item.lookupItemId);
                    }}
                  >
                    <span>{item.lookupItemName}</span>
                  </ButtonBase>
                ))) || formsResponseLODE &&
                formsResponseLODE.map((index) => (
                  <Skeleton index={index} variant='rect' />
                ))}

            </div>
          </div>
        </div>
      </div>
      <div className='cardmalek-contantr'>
        {IsLoadingReportCategory === true &&
          formsResponseLODE &&
          formsResponseLODE.map((index) => (
            <Skeleton index={index} variant='rect' />
          )) ||
          (StemReportCategoryTypes &&
            StemReportCategoryTypes).map((item, index) => (
              <>
                <ButtonBase
                  className={`cardmalek-body${(index + 1 &&
                    index + 1 === SelectedsystemReportCategoryTypeId &&
                    ' active') ||
                    ''
                    }`}
                  onClick={() => {
                    IdSdelected(item);
                    setSelectedsystemReportCategoryTypeId(index + 1);
                  }}
                >
                  <span className='con-text'>
                    {' '}
                    {item.systemReportCategoryTypeName}
                  </span>
                </ButtonBase>
              </>
       ))}

        <div />
      </div>
    </form>
  );
};
ReportsType.propTypes = {
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  IdSdelected: PropTypes.func,
};

ReportsType.defaultProps = {
  translationPath: '',
  parentTranslationPath: '',
  IdSdelected: () => { },
};
