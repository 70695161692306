import React, { useCallback, useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { MicrosoftToken } from "../../../../Services/DashboardService";
import { PowerBiEnum } from "../../../../Enums";
import { GetUserTeamsInfo } from "../../../../Services/userServices";
import { GlobalTranslate, showError } from "../../../../Helper";
import { useTitle } from "../../../../Hooks";
import Axios from "axios";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { PermissionDeniedLayout } from "../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout";
import { GetAllLeadAuditors } from "../../../../Services";

export const LeadOwner = () => {
  useTitle(GlobalTranslate.t("Dashboard:leadower-dashboard"));
  const [reporttoken, setReporttoken] = useState();
  const [teamid, setTeamid] = useState([]);
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [ShowOwner, setShowOwner] = useState(false);
  const [userLeadAuditor, setUserLeadAuditor] = useState(null);
  const [leadAuditorId, setLeadAuditorId] = useState(null);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex: 0,
  });
  const userid = JSON.parse(localStorage.getItem("session")).userId;

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReporttoken(res);

     
    } else {
      setReporttoken("");
      showError("Contact Your Bi Admin For Help");
    }
  };
  const getAllLeadAuditors = useCallback(async () => {
    const body = {
      usersId: userid,
    };
    const res = await GetAllLeadAuditors(body, filter.pageIndex + 1, 25);
    if (!(res && res.status && res.status !== 200)) {
      setUserLeadAuditor(res.result[0]);
      setLeadAuditorId(res.result[0] && res.result[0].leadAuditorId);
    setShowOwner(true);
      setRender(true);
    } else {
      setUserLeadAuditor(null);
      setLeadAuditorId(null);

    }
  }, []);
  const getUserTemasById = useCallback(async () => {
    const res = await GetUserTeamsInfo(userid, true);
    if (!(res && res.status && res.status !== 200))
      setTeamid(res && res.map((List) => List.teamsId || null));
    else setTeamid([]);
  }, [userid]);
  const Filter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadAuditors",
      column: "LeadAuditorId",
    },
    operator: "In",
    values: [leadAuditorId],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };
  useEffect(() => {
    getAllLeadAuditors();
  }, [getAllLeadAuditors]);

  useEffect(() => {
    getUserTemasById(userid);
  }, []);

  useEffect(() => {
    Microsoft(false);
  }, []);



  return (
    <div className="dashboardMain-PowerBIEmbed">
           <SpinnerDashboards isActive={!ShowOwner} isAbsolute />

      {render && leadAuditorId && leadAuditorId ? (
        <div className="dashboardMain">
          <SpinnerDashboards isActive={!ShowReport} isAbsolute />

          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: PowerBiEnum.LeadOwner.reportid,
              embedUrl: PowerBiEnum.LeadOwner.url,
              accessToken: reporttoken,
              filters: [Filter],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  () => {
                    setShowReport(true);

                    
                  },
                ],
                ["rendered", () => {}],
                [
                  "error",
                  (event) => {
                    const error = event.detail;

                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      ) : (
        <div>{render && <PermissionDeniedLayout />}</div>
      )}
    </div>
  );
};
